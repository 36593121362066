import {
	Box,
	Modal,
	Backdrop,
	Stack,
	Tab,
	Tabs,
	Grid,
	Typography,
	IconButton,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import theme from "../../assets/theme/theme";
import { store } from "../../redux/store";
import { useState } from "react";
import ActiveTier from "./ActiveTier";
import TierConfiguration from "./TierConfiguration";
import BookingSource from "../generalsettings/BookingSource";

type Props = {
	openTierSettings: boolean;
	handleCloseTierSettings: () => void;
};

const modalStyle = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "80%",
	border: 2,
	borderColor: theme.palette.primary.main,
};

function TierSettings({ openTierSettings, handleCloseTierSettings }: Props) {
	const token = store.getState().user.accessToken;
	const [tabStepperValue, setTabStepperValue] = useState(0);

	const handleStepperChange = (
		event: React.SyntheticEvent,
		newValue: number
	) => {
		setTabStepperValue(newValue);
	};

	return (
		<>
			<Modal
				open={openTierSettings}
				onClose={handleCloseTierSettings}
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						sx: {
							backgroundColor: "rgba(0, 0, 0, 0.8)",
						},
					},
				}}>
				<Box sx={modalStyle} bgcolor={theme.palette.background.default}>
					<Grid container>
						<Grid item xs={11}>
							<Stack
								display={"flex"}
								sx={{
									p: 2,
									border: 0,
									borderColor: "#232323",
									height: "50px",
									justifyContent: "center",
								}}>
								<Typography variant="h2" color={theme.palette.primary.main}>
									Tier Settings
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={1}>
							<Stack
								display={"flex"}
								sx={{
									p: 1,
									border: 0,
									borderColor: theme.palette.primary.main,
									height: "50px",
									justifyContent: "center",
								}}>
								<IconButton
									onClick={handleCloseTierSettings}
									sx={{ padding: 0 }}>
									<HighlightOffIcon
										fontSize="large"
										sx={{ color: "#ff4d56" }}
									/>
								</IconButton>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack
								sx={{
									borderTop: 2,
									borderColor: theme.palette.primary.main,
								}}></Stack>
						</Grid>
						<Grid
							item
							md={2}
							sm={2}
							xs={2}
							sx={{
								height: "100%",
								display: "flex",
								flexDirection: "column",
								overflowY: "auto",
								minHeight: "100%",
							}}>
							<Stack
								direction={"column"}
								textAlign={"start"}
								padding={2}
								spacing={2}
								sx={{ backgroundColor: theme.palette.background.default }}
								height={"70vh"}>
								<Tabs
									orientation="vertical"
									variant="scrollable"
									value={tabStepperValue}
									onChange={handleStepperChange}
									TabIndicatorProps={{
										style: { display: "none" },
									}}
									sx={{
										marginBottom: 5,
										"& .MuiTab-root": {
											textTransform: "none",
											fontWeight: 600,
											minWidth: "8.1vw",
											fontSize: "1rem",
											alignContent: "start",
											"&.Mui-selected": {
												color: theme.palette.primary.main,
											},
											"&:not(.Mui-selected)": {
												color: "white",
												borderBottom: "1px solid #0a443c",
											},
										},
									}}>
									<Tab value={0} label={"Active Tier"} />
									<Tab value={1} label={"Tiers Configuration"} />
                  <Tab value={2} label={"Booking Source Management"}/>
								</Tabs>
							</Stack>
						</Grid>

						<Grid item md={10} sm={10} xs={10}>
							<Stack
								direction={"column"}
								height={"100%"}
								width={"100%"}
								sx={{ backgroundColor: "#141414", overflow: "auto" }}
								maxHeight={"70vh"}>
								<Grid container width={"100%"} sx={{ padding: 5 }}>
									<Grid item md={12} sm={12} xs={12}>
										<Stack direction={"column"} height={"100%"}>
											{tabStepperValue === 0 ? (
												<ActiveTier />
											) : tabStepperValue === 1 ? (
												<TierConfiguration />
											) : tabStepperValue === 2 ? (
												<BookingSource />
											) : null}
										</Stack>
									</Grid>
								</Grid>
							</Stack>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</>
	);
}

export default TierSettings;
