import {
  AccountBalance,
  CardGiftcard,
  CreditCard,
  HighlightOff,
  KeyboardReturn,
  LocalAtm,
  MoreHoriz,
  QrCode,
  QuestionMark,
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  ButtonBase,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import {
  IPaymentMethod,
  IPayments,
} from "../../models/payment/PaymentInterface";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
interface RefundTypeFormProps {
  handlePaymentModalClose: VoidFunction;
  payment: IPayments;
  bookingId?: string;
  paymentMethods: IPaymentMethod[];
  isExpressBooking: boolean;
  isPOSGuestPayment: boolean;
  handleCompletePOSPayment?: VoidFunction;
  selectedPaymentMethod: (paymentMethod: IPayments | undefined) => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.default",
  border: 1,
  borderColor: "primary.main",
  boxShadow: 24,
};

const RefundTypeForm = ({
  handlePaymentModalClose,
  payment,
  paymentMethods,
  bookingId,
  isExpressBooking,
  isPOSGuestPayment,
  handleCompletePOSPayment,
  selectedPaymentMethod,
}: RefundTypeFormProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const initialValue = payment;
  const paymentMethodInitial: IPaymentMethod = {
    paymentMethod: "",
    paymentMethodDesc: "",
    paymentMethodId: 0,
    paymentTypes: [],
  };

  const paymentMethodsWithoutPayLater = paymentMethods.filter(
    (method) => method.paymentMethod !== "Paylater"
  );

  const [paymentState, setPaymentState] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<
    IPaymentMethod | undefined
  >(paymentMethodInitial);

  const token = useSelector((state: RootState) => state.user.accessToken);
  const accountId = useSelector((state: RootState) => state.user.accountId);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const formik = useFormik({
    initialValues: initialValue,

    onSubmit: (values) => {
      selectedPaymentMethod(values);
      handleModalClose();
    },
  });

  const handlePaymentProcess = (data: IPaymentMethod) => {
    if (data.paymentMethod === data.paymentTypes[0].paymentType) {
      formik.setFieldValue("paymentType", data.paymentTypes[0].paymentTypeId);
      formik.setFieldValue("paymentTypeName", data.paymentTypes[0].paymentType);
      formik.handleSubmit();
    } else {
      setPaymentMethod(data);
    }
  };

  const handleModalClose = () => {
    handlePaymentModalClose();
  };

  return (
    <Modal
      open={true}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={style}>
          <Box
            display={"flex"}
            height={"40px"}
            alignItems={"center"}
            paddingX={1}
            borderBottom={1}
            borderColor={theme.palette.primary.main}
          >
            <Stack
              width={"100%"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h4" color={"primary"}>
                Payment
              </Typography>
              <IconButton color="primary" onClick={handleModalClose}>
                <HighlightOff />
              </IconButton>
            </Stack>
          </Box>

          {/* {!paymentState ? ( */}
          <>
            <Box
              display={"flex"}
              alignItems={"center"}
              height={"150px"}
              paddingX={"30px"}
              sx={{ backgroundColor: "black" }}
            >
              <Typography variant="h4">
                Select any payment method to continue
              </Typography>
            </Box>
            <Grid container width={"100%"} height={"50%"}>
              {!paymentMethod?.paymentMethod ? (
                paymentMethodsWithoutPayLater.map((methods, index) => (
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} key={index}>
                    <ButtonBase
                      onClick={() => {
                        handlePaymentProcess(methods);
                      }}
                      disabled={formik.isSubmitting}
                      sx={{ width: "100%", height: "80px" }}
                    >
                      <SelectRefundTypeBox method={methods.paymentMethod} />
                    </ButtonBase>
                  </Grid>
                ))
              ) : (
                <>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <ButtonBase
                      key={"Back"}
                      onClick={() => {
                        setPaymentMethod(paymentMethodInitial);
                      }}
                      sx={{ width: "100%", height: "80px" }}
                    >
                      <SelectRefundTypeBox method={"Back"} />
                    </ButtonBase>
                  </Grid>
                  {paymentMethod.paymentTypes.map((types, index) => (
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} key={index}>
                      <ButtonBase
                        onClick={() => {
                          formik.setFieldValue(
                            "paymentType",
                            types.paymentTypeId
                          );
                          formik.setFieldValue(
                            "paymentTypeName",
                            types.paymentType
                          );
                          formik.handleSubmit();
                        }}
                        disabled={formik.isSubmitting}
                        sx={{ width: "100%", height: "80px" }}
                      >
                        <SelectRefundTypeBox
                          method={paymentMethod.paymentMethod}
                          type={types.paymentType}
                        />
                      </ButtonBase>
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          </>
          {/* ) :<></>} */}
        </Box>
      </form>
    </Modal>
  );
};

const SelectRefundTypeBox = ({
  method,
  type,
}: {
  method: string;
  type?: string;
}) => {
  const theme = useTheme();
  const text = !type ? method : type;
  const handlePaymentMethodIcon = (method: string) => {
    switch (method) {
      case "Cash":
        return <LocalAtm sx={{ fontSize: "50px" }} />;
      case "Card":
        return <CreditCard sx={{ fontSize: "50px" }} />;
      case "E-Wallet":
        return <QrCode sx={{ fontSize: "50px" }} />;
      case "Voucher":
        return <CardGiftcard sx={{ fontSize: "50px" }} />;
      case "Bank Transfer":
        return <AccountBalance sx={{ fontSize: "50px" }} />;
      case "Others":
        return <MoreHoriz sx={{ fontSize: "50px" }} />;
      // case "Others":
      //   return <AutoAwesome sx={{ fontSize: "50px" }} />;
      case "Back":
        return <KeyboardReturn sx={{ fontSize: "50px" }} />;
      default:
        return <QuestionMark sx={{ fontSize: "50px" }} />;
    }
  };
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      height={"100%"}
      border={1}
      borderColor={theme.palette.primary.main}
    >
      <Stack direction={"row"} width={"80%"} alignItems={"center"} spacing={2}>
        {handlePaymentMethodIcon(method)}{" "}
        <Typography variant="h4">{text}</Typography>
      </Stack>
    </Box>
  );
};

export default RefundTypeForm;
