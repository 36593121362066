import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  IconButton,
  Menu,
} from "@mui/material";
import useAuthorization from "../../hooks/useAuthorization";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import SettingsIcon from "@mui/icons-material/Settings";
import axiosInstance from "../../constants/axiosConfig";

// Constants
import { StyledTableCell, StyledTableRow } from "../../constants/tableStyle";

// Utils
import Notification from "../../utils/notificationConfig";
import { filterTableData } from "../../utils/functions";

// Components
import TableLoading from "../../components/global/loading/TableLoading";

// types and interfaces
import {
  RoomDataInterfaceV2,
  ZoneInterface,
} from "../../models/rooms/roomsInterface";
import theme from "../../assets/theme/theme";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import AddOrEditRoomModal from "../../components/roomlist/AddOrEditRoomModal";
import { FilterAltOutlined } from "@mui/icons-material";

interface IFirstFilterValue {
  roomZone: string[];
  roomType: string[];
  roomRemarks: string[];
  bedLevel: string[];
  quietZone: string[];
  isArchived: string[];
}

type Props = {
  roomData: RoomDataInterfaceV2[];
  setRoomData: React.Dispatch<React.SetStateAction<RoomDataInterfaceV2[]>>;
  searchBarValue: string;
  handleSearchBarChange: (event: string) => void;
  filterValue: IFirstFilterValue;
};

const roomDataInitial: RoomDataInterfaceV2 = {
  isArchived: false,
  colorCode: "",
  roomId: "",
  roomCode: "",
  quietZone: false,
  remarks: "",
  status: "",
  roomType: "",
  zone: "",
  roomTypeDetails: "",
  roomTypeName: "",
  zoneId: "",
  isUpper: false,
};

const RoomFilterBar = ({
  searchBarValue,
  handleSearchBarChange,
  filterValue,
  setFilteredRows,
  rows,
  filteredRows,
  token,
  roomIsArchived,
  setRoomIsArchived,
  setPage,
}: {
  searchBarValue: string;
  handleSearchBarChange: (event: string) => void;
  filterValue: IFirstFilterValue;
  setFilteredRows: React.Dispatch<React.SetStateAction<RoomDataInterfaceV2[]>>;
  rows: RoomDataInterfaceV2[];
  filteredRows: RoomDataInterfaceV2[];
  token: string;
  roomIsArchived: string;
  setRoomIsArchived: (event: string) => void;
  setPage: (event: number) => void;
}) => {
  const [selectedRoomType, setSelectedRoomType] = useState<string>("All");
  const [selectedRoomZone, setSelectedRoomZone] = useState<string>("All");
  const [selectedRoomRemarks, setSelectedRoomRemarks] = useState<string>("All");
  const [selectedRoomBedLevel, setSelectedRoomBedLevel] =
    useState<string>("All");
  const [selectedRoomQuietZone, setSelectedRoomQuietZone] =
    useState<string>("All");

  const [roomTypeData, setRoomTypeData] = useState<string[]>([]);
  const [roomZones, setRoomZones] = useState<string[]>([]);

  /* ---------------------------- filter functions ---------------------------- */
  const handleFilterRoomType = (data: RoomDataInterfaceV2[]) => {
    let newData: RoomDataInterfaceV2[] = [];
    if (selectedRoomType === "All") {
      newData = [...data];
      return newData;
    } else {
      newData = data.filter((item) => item.roomTypeName === selectedRoomType);
      return newData;
    }
  };

  const handleFilterRoomZone = (data: RoomDataInterfaceV2[]) => {
    let newData: RoomDataInterfaceV2[] = [];
    if (selectedRoomZone === "All") {
      newData = [...data];
      return newData;
    } else {
      newData = data.filter((item) => item.zone === selectedRoomZone);
      return newData;
    }
  };

  const handleFilterRoomRemarks = (data: RoomDataInterfaceV2[]) => {
    if (selectedRoomRemarks === "All") {
      return [...data];
    } else {
      //return data.filter((item) => item.remarks === selectedRoomRemarks);
      if (selectedRoomRemarks === "True") {
        return data.filter((item) => item.remarks);
      } else {
        return data.filter((item) => item.remarks === "");
      }
    }
  };

  const handleFilterBedLevel = (data: RoomDataInterfaceV2[]) => {
    if (selectedRoomBedLevel === "All") {
      return [...data];
    } else {
      return data.filter((item) => {
        if (selectedRoomBedLevel === "up") {
          return item.isUpper;
        }

        if (selectedRoomBedLevel === "down") {
          return !item.isUpper;
        }

        return item;
      });
    }
  };

  const handleFilterQuietZone = (data: RoomDataInterfaceV2[]) => {
    if (selectedRoomQuietZone === "All") {
      return [...data];
    } else {
      if (selectedRoomQuietZone === "True") {
        return data.filter((item) => item.quietZone === true);
      } else {
        return data.filter((item) => item.quietZone === false);
      }
    }
  };

  // const handleFilterIsArchived = (data: RoomDataInterfaceV2[]) => {
  //   if (selectedRoomIsArchived === "All") {
  //     return [...data];
  //   } else {
  //     if (selectedRoomIsArchived === "True") {
  //       return data.filter((item) => item.isArchived === true);
  //     } else {
  //       return data.filter((item) => item.isArchived === false);
  //     }
  //   }
  // };

  useEffect(() => {
    const getRoomType = () => {
      axiosInstance
        .get("/rooms/v1/types/type-name/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setRoomTypeData(res.data.data.roomTypeName);
          }
        })
        .catch((e) => {
          console.log(e);
          Notification.failed("something wrong, please try again");
        });
    };
    const getRoomZones = () => {
      const queryParams = {
        noDuplicate: true,
      };
      axiosInstance
        .get("/rooms/zone/get-all-zone/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: queryParams,
        })
        .then((res) => {
          if (res.status === 200) {
            let result = res.data.data.map(
              ({ zoneName }: ZoneInterface) => zoneName
            );
            setRoomZones(result);
          }
        })
        .catch((e) => {
          console.log(e);
          Notification.failed("something wrong, please try again");
        });
    };
    getRoomType();
    getRoomZones();
  }, [token]);

  /* ---------------------------- filter useEffect ---------------------------- */
  useEffect(() => {
    const unfilteredData = [...rows];
    const filterTypeData = handleFilterRoomType(unfilteredData);
    const filterZoneData = handleFilterRoomZone(filterTypeData);
    const filterBedLevel = handleFilterBedLevel(filterZoneData);
    const filterQuietZone = handleFilterQuietZone(filterBedLevel);
    const filterRemarksData = handleFilterRoomRemarks(filterQuietZone);
    //const filterIsArchived = handleFilterIsArchived(filterRemarksData);

    const searchedData = filterTableData(filterRemarksData, searchBarValue);
    setPage(0);
    setFilteredRows(searchedData);
  }, [
    selectedRoomType,
    selectedRoomZone,
    selectedRoomRemarks,
    searchBarValue,
    selectedRoomBedLevel,
    selectedRoomQuietZone,
    roomIsArchived,
    rows,
  ]);

  return (
    <Stack
      width={"100%"}
      padding={3}
      spacing={2}
      direction={"row"}
      alignItems={"center"}
      sx={{ backgroundColor: "#141414" }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <FilterAltOutlined fontSize={"large"} sx={{ color: "white" }} />
        <Typography variant={"h4"}>Room</Typography>
      </Stack>

      <TextField
        value={searchBarValue}
        onChange={(e) => handleSearchBarChange(e.target.value)}
        variant={"outlined"}
        label={"Search"}
        sx={{ width: "20%" }}
      />
      <FormControl sx={{ width: "10%" }}>
        <InputLabel variant="outlined" id="room-type-label">
          Type
        </InputLabel>
        <Select
          labelId="room-type-label"
          variant={"outlined"}
          value={selectedRoomType}
          onChange={(e) => setSelectedRoomType(e.target.value)}
        >
          <MenuItem value={"All"}>All</MenuItem>
          {roomTypeData.map((item: string, index: number) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: "10%" }}>
        <InputLabel variant="outlined" id="room-zone-label">
          Zone
        </InputLabel>
        <Select
          labelId="room-zone-label"
          variant={"outlined"}
          value={selectedRoomZone}
          onChange={(e) => setSelectedRoomZone(e.target.value)}
        >
          <MenuItem value={"All"}>All</MenuItem>
          {roomZones.map((item: string, index: number) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: "10%" }}>
        <InputLabel variant="outlined" id="bed-level-label">
          Bed Level
        </InputLabel>
        <Select
          variant={"outlined"}
          labelId={"bed-level-label"}
          value={selectedRoomBedLevel}
          onChange={(e) => setSelectedRoomBedLevel(e.target.value)}
        >
          <MenuItem value={"All"}>All</MenuItem>
          <MenuItem value={"up"}>Up</MenuItem>
          <MenuItem value={"down"}>Down</MenuItem>
          {/* {filterValue.bedLevel.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))} */}
        </Select>
      </FormControl>
      <FormControl sx={{ width: "10%" }}>
        <InputLabel variant="outlined" id="quiet-zone-label">
          Quiet Zone
        </InputLabel>
        <Select
          variant={"outlined"}
          labelId={"quiet-zone-label"}
          value={selectedRoomQuietZone}
          onChange={(e) => setSelectedRoomQuietZone(e.target.value)}
        >
          <MenuItem value={"All"}>All</MenuItem>
          <MenuItem value={"True"}>True</MenuItem>
          <MenuItem value={"False"}>False</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ width: "10%" }}>
        <InputLabel variant="outlined" id="remarks-label">
          Remarks
        </InputLabel>
        <Select
          variant={"outlined"}
          labelId={"remarks-label"}
          value={selectedRoomRemarks}
          onChange={(e) => setSelectedRoomRemarks(e.target.value)}
        >
          <MenuItem value={"All"}>All</MenuItem>
          <MenuItem value={"True"}>True</MenuItem>
          <MenuItem value={"False"}>False</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ width: "10%" }}>
        <InputLabel variant="outlined" id="archive-label">
          Archived
        </InputLabel>
        <Select
          variant={"outlined"}
          labelId="archive-label"
          value={roomIsArchived}
          // onChange={(e) => {
          //   setSelectedRoomIsArchived(e.target.value);
          //   setRoomIsArchived(e.target.value);
          // }}
          onChange={(e) => setRoomIsArchived(e.target.value)}
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"true"}>True</MenuItem>
          <MenuItem value={"false"}>False</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
};

export default function RoomList({
  roomData,
  setRoomData,
  searchBarValue,
  handleSearchBarChange,
  filterValue,
}: Props) {
  const { checkIsAdmin } = useAuthorization();
  const token = useSelector((state: RootState) => state.user.accessToken);

  /* -------------------------------- usestates ------------------------------- */
  const [page, setPage] = useState(0);
  const [filteredRows, setFilteredRows] = useState<RoomDataInterfaceV2[]>([]);

  const rowsPerPage = 8;

  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateTrigger, setUpdateTrigger] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const [openEditRoomModal, setOpenEditRoomModal] = useState(false);
  const [selectedRoom, setSelectedRoom] =
    useState<RoomDataInterfaceV2>(roomDataInitial);

  const [selectedRoomIsArchived, setSelectedRoomIsArchived] =
    useState<string>("all");

  const handleAddRoomModalOpen = () => {
    setOpenEditRoomModal(true);
  };

  const handleAddRoomModalClose = () => {
    setOpenEditRoomModal(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /* ------------------------- state change functions ------------------------- */
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  /* ------------------------------- useEffects ------------------------------- */
  useEffect(() => {
    document.title = "Capsule Transit | Room List";
    getPageData();
    if (checkIsAdmin()) setIsAdmin(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //if (updateTrigger) {
    getPageData();
    //}
  }, [updateTrigger, selectedRoomIsArchived]);

  /* -------------------------------- API fetch ------------------------------- */
  const getPageData = async () => {
    setFetchLoading(true);
    await axiosInstance
      .get(`/rooms/v1/rooms/?&archived=${selectedRoomIsArchived}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setRoomData(res.data);

          return;
        }
        Notification.failed("something wrong, please try again");
      })
      .catch((e) => {
        console.log(e);
        Notification.failed("something wrong, please try again");
      })
      .finally(() => {
        setFetchLoading(false);
        setUpdateTrigger(false);
      });
  };

  ///It will call to archive a room and update the changes in the database.
  ///It is using payload as a json body
  const handleSubmitArchive = async (row: RoomDataInterfaceV2) => {
    setLoading(true);
    const payload = {
      roomId: row.roomId,
      archive: !row.isArchived,
    };
    await axiosInstance
      .patch(`/rooms/v1/rooms/archive/`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          Notification.success(res.data.message);
        }
      })
      .catch((res) => {
        const errorsList = res.response.data;
        for (const errorKey in errorsList) {
          if (errorsList.hasOwnProperty(errorKey)) {
            Notification.failed(errorsList[errorKey][0]);
          }
        }
      })
      .finally(() => {
        getPageData();
        setLoading(false);
      });
  };

  return (
    <Box
      width={"100%"}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {/* {isAdmin && (
                <Box mb={'10px'} display={'flex'} alignItems={'center'}>
                    <Box mr={'20px'}>
                        <ModalPopper
                            text='Add new single room'
                            variant='contained'
                            endIcons={<AddIcon />}
                            popUpComponent={<AddSingleRoom setUpdateTrigger={setUpdateTrigger} />}
                        />
                    </Box>
                    <Box>
                        <ModalPopper
                            text='Add multiple rooms'
                            variant='contained'
                            endIcons={<AddIcon />}
                            popUpComponent={<AddMultipleRoomModal setUpdateTrigger={setUpdateTrigger} />}
                        />
                    </Box>
                </Box>
            )} */}
      {/* <Stack 
                flexDirection={'row'}
                justifyContent={'space-between'} 
                alignItems={'center'}
                mb={'15px'}
            >
                <Typography fontSize="1.3rem">Room List</Typography>
                <Box sx={{ width: '20rem' }}>
                    <TextField 
                        type="search"
                        id="search"
                        label="Filter Search" 
                        sx={{ width: '100%' }}
                        onChange={(e) => {
                            const newRow = filterTableData(rows, e.target.value)
                            setFilteredRows(newRow);
                            setPage(0);
                        }}
                    />
                </Box>
            </Stack> */}
      <RoomFilterBar
        searchBarValue={searchBarValue}
        handleSearchBarChange={handleSearchBarChange}
        filterValue={filterValue}
        setFilteredRows={setFilteredRows}
        rows={roomData}
        filteredRows={filteredRows}
        token={token}
        roomIsArchived={selectedRoomIsArchived}
        setRoomIsArchived={setSelectedRoomIsArchived}
        setPage={setPage}
      />
      <TableContainer
        component={Paper}
        sx={{ display: "flex", flex: 1, flexDirection: "column" }}
      >
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Room ID</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Zone</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Remarks</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {fetchLoading ? (
            <TableLoading />
          ) : (
            <>
              {filteredRows.length > 0 && (
                <TableBody
                  sx={{ width: "80%", minHeight: "80%", overflowY: "auto" }}
                >
                  {filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow
                        key={row.roomId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          opacity: `${row.isArchived ? 0.5 : 1}`,
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Typography
                            color={row.isArchived ? "grey" : "Inherited"}
                          >
                            {row.roomCode}
                          </Typography>{" "}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            color={row.isArchived ? "grey" : "Inherited"}
                          >
                            {row.roomTypeName}
                          </Typography>
                        </StyledTableCell>

                        <StyledTableCell>
                          {" "}
                          <Typography
                            color={row.isArchived ? "grey" : "Inherited"}
                          >
                            {row.zone}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          {" "}
                          <Typography
                            color={row.isArchived ? "grey" : "Inherited"}
                          >
                            {row.status}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            color={row.isArchived ? "grey" : "Inherited"}
                          >
                            {row.remarks !== "" ? row.remarks : "-"}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(event) => {
                              setSelectedRoom(row);
                              handleClick(event);
                            }}
                            aria-label="Open to show more"
                            title="Open to show more"
                          >
                            <SettingsIcon color="primary" />
                          </IconButton>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <MenuItem
                              key={1}
                              onClick={() => {
                                handleAddRoomModalOpen();
                                handleClose();
                              }}
                            >
                              Edit Room
                            </MenuItem>
                            <MenuItem
                              key={2}
                              onClick={() => {
                                handleSubmitArchive(selectedRoom);
                                handleClose();
                              }}
                            >
                              {selectedRoom.isArchived
                                ? "Unarchived"
                                : "Archived"}
                            </MenuItem>
                          </Menu>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              )}
              {filteredRows.length === 0 && (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>data not available</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              )}
            </>
          )}
        </Table>
        <TablePagination
          sx={{
            color: "white",
            backgroundColor: theme.palette.background.default,
          }}
          rowsPerPageOptions={[20]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <AddOrEditRoomModal
        setRoomData={setRoomData}
        addOrEditRoomModalOpen={openEditRoomModal}
        handleAddRoomModalClose={handleAddRoomModalClose}
        selectedRoom={selectedRoom}
      />
    </Box>
  );
}
