import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
  useTheme,
  ListSubheader,
} from '@mui/material';
import {
  IPaymentItem,
  IPaymentMethod,
  IPayments,
} from '../../models/payment/PaymentInterface';
import {
  ArrowBack,
  ArrowForward,
  Delete,
  Done,
  HighlightOff,
  Save,
} from '@mui/icons-material';
import PaymentTypeForm from './PaymentTypeForm';
import Status from '../global/status/Status';
import { useFormik } from 'formik';
import axiosInstance from '../../constants/axiosConfig';
import Notification from '../../utils/notificationConfig';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { handleCheckIsBooking } from '../../utils/functions';

import LayersIcon from '@mui/icons-material/Layers';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import { useEffect, useState } from 'react';
import { IPromotion } from '../../types/promotion';
import BASE_API from '../../constants/api';
import { addHours } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { convertTo2Decimals, roundUp } from '../../utils/paymentFunction';
import { extraCharges, transactionCategoryEnum } from '../../constants/enums';
import AdjustmentModal from './AdjustmentModal';
import { RoomTypeInterfaceV2 } from '../../models/rooms/roomsInterface';
import PosSkeletonLoading from '../common/PosSkeletonLoading';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.default',
  border: 1,
  borderColor: 'primary.main',
  boxShadow: 24,
};

const adjustmentPromotion: IPromotion = {
  archived: false,
  details: '-',
  memberOnly: false,
  promoCode: 'Adjustment',
  promoEndDatetime: '',
  promoStartDatetime: '',
  promotionId: 'adjustment',
  seats: 1000,
  stackable: true,
  visibleAtWebsite: false,
};

const roundingPromotion: IPromotion = {
  archived: false,
  details: '-',
  memberOnly: false,
  promoCode: 'Rounding',
  promoEndDatetime: '',
  promoStartDatetime: '',
  promotionId: 'rounding',
  seats: 1000,
  stackable: true,
  visibleAtWebsite: false,
};

interface PaymentBoxProps {
  data: IPayments;
  paymentMethods: IPaymentMethod[];
  guest: {
    guestName: string;
    isMember: boolean;
  };
  bookingId: string;
  handlePaymentModalOpen: (data: IPayments) => void;
  handleRefetch: VoidFunction;
  handleAddPromotion: (
    promotion: IPromotion,
    poaIndex: number,
    saved: boolean
  ) => void;
  index: number;
  isExpressBooking: boolean;
  disableSave: boolean;
  handleRemoveItem: (index: number, item: IPaymentItem, saved: boolean) => void;
  handleRemovePromotion: (
    poaIndex: number,
    promotionItemId: string,
    saved: boolean
  ) => void;
  isLoading?: boolean;
  loading?: boolean;
  isDeleting: boolean;
  itemLength: boolean;
  guestId: string;
}

const PaymentBoxUnsaved = ({
  data,
  paymentMethods,
  guest,
  bookingId,
  handlePaymentModalOpen,
  handleRefetch,
  handleAddPromotion,
  index,
  isExpressBooking,
  disableSave,
  handleRemoveItem,
  handleRemovePromotion,
  isLoading,
  loading,
  isDeleting,
  itemLength,
  guestId,
}: PaymentBoxProps) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const accountId = useSelector((state: RootState) => state.user.accountId);
  const navigate = useNavigate();

  const [promotions, setPromotions] = useState<IPromotion[]>([]);
  const [allPromotions, setAllPromotions] = useState<IPromotion[]>([]);
  const [selectedPromotion, setSelectedPromotion] = useState<string>('');
  const [individualPromotions, setIndividualPromotions] = useState<
    IPromotion[]
  >([]);
  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
  const [hideDeleteButton, setHideDeleteButton] = useState<boolean>(itemLength);

  const [isLoadingRoomType, setIsLoadingRoomType] = useState<boolean>(false);
  const [roomTypes, setRoomTypes] = useState<string[]>([]);

  const [openAdjustmentModal, setOpenAdjustmentModal] = useState<string>('');

  const handleAdjustmentModalOpen = (type: string) => {
    setOpenAdjustmentModal(type);
  };
  const handleAdjustmentModalClose = () => {
    setOpenAdjustmentModal('');
  };

  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
    handleRefetch();
  };
  const handleSuccessModalOpen = () => {
    setSuccessModalOpen(true);
  };

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleLookforCreditPayment = () => {
    return paymentMethods.find((method) => method.paymentMethod === 'Paylater')
      ?.paymentTypes[0].paymentTypeId;
  };

  useEffect(() => {
    const apiUrl = '/rooms/v1/room-type/list';
    setIsLoadingRoomType(true);

    axiosInstance
      .get(apiUrl, config)
      .then((res) =>
        setRoomTypes(res.data.map((data: RoomTypeInterfaceV2) => data.typeName))
      )
      .catch((res) => console.log(res.response.data))
      .finally(() => setIsLoadingRoomType(false));
  }, []);

  useEffect(() => {
    const isPromotionExist = data.items.some(
      (item) => item.category === transactionCategoryEnum.promotion
    );
    // const isOnlyOneItem = data.items.filter(
    //   (item) =>
    //     item.category === transactionCategoryEnum.lockerSales ||
    //     item.category === transactionCategoryEnum.merchSales ||
    //     item.category === transactionCategoryEnum.roomSales ||
    //     item.category === transactionCategoryEnum.showerSales
    // );

    // if (isOnlyOneItem.length === 1) {
    //   setHideDeleteButton(true);
    // } else {
    //   setHideDeleteButton(false);
    // }

    if (!isPromotionExist) {
      setSelectedPromotion('');
      setPromotions([roundingPromotion, adjustmentPromotion, ...allPromotions]);
    }
  }, [data]);

  const handleSubmit = () => {
    const apiUrl = '/transaction/';
    const taxes = data.items.filter(
      (item) => item.itemType === extraCharges.tax
    );

    const totalTaxes = roundUp(
      taxes.reduce((current, item) => current + item.price, 0),
      2
    );

    const serviceCharge = data.items.filter(
      (item) => item.itemType === extraCharges.serviceCharge
    );

    const totalServiceCharge = roundUp(
      serviceCharge.reduce((current, item) => current + item.price, 0),
      2
    );

    const addHourItem = data.items.filter(
      (item) =>
        item.itemType.includes('hrs Extend') || item.itemType === 'Add 1 hour'
    );

    let amountOfHoursAdded = 0;
    if (addHourItem.length > 0) {
      const roomNormal = addHourItem.filter((item) =>
        item.itemType.includes('hrs Extend')
      );

      const adjustmentPrice = addHourItem.filter(
        (item) => item.itemType === 'Add 1 hour'
      );
      if (roomNormal.length > 0 && adjustmentPrice.length > 0) {
        amountOfHoursAdded =
          roomNormal[0].duration! + adjustmentPrice[0].duration!;
      } else if (roomNormal.length > 0 && roomNormal[0].duration) {
        amountOfHoursAdded = roomNormal[0].duration;
      } else if (adjustmentPrice.length > 0 && adjustmentPrice[0].duration) {
        amountOfHoursAdded = adjustmentPrice[0].duration;
      }
    }

    const uniqueItemTypes = new Set<string>();
    const adjustmentItems = data.items
      .filter(
        ({ category }) => category === transactionCategoryEnum.adjustmentSales
      )
      .filter((item) => {
        if (
          !uniqueItemTypes.has(item.itemType) ||
          ['Updated Price', 'Adjustment'].includes(item.itemName)
        ) {
          uniqueItemTypes.add(item.itemType);
          return true;
        }
        return false;
      });

    const { extendDuration, nonExtendItems } = adjustmentItems.reduce(
      (acc, item) => {
        if (
          item.itemType.includes('hrs Extend') ||
          item.itemType.includes('Add 1 hour')
        ) {
          acc.extendDuration += item.duration || 0;
        } else {
          acc.nonExtendItems.push(item.itemName); // Assuming you need itemName here
        }
        return acc;
      },
      { extendDuration: 0, nonExtendItems: [] as string[] }
    );

    const adjustmentExtendTotalString =
      extendDuration > 0 ? `Add ${extendDuration} hours` : '';
    const adjustmentNonExtendString = nonExtendItems.join(', ');
    const adjustmentString =
      adjustmentNonExtendString && adjustmentExtendTotalString
        ? `${adjustmentNonExtendString}, ${adjustmentExtendTotalString}`
        : adjustmentNonExtendString
          ? adjustmentNonExtendString
          : adjustmentExtendTotalString;

    const formData = {
      accountId: accountId,
      booking: bookingId, //To Add Booking ID here
      paymentDetails: JSON.stringify(data.items),
      debitAmount: 0, //To change to integration with POS
      sum: data.sum,
      creditAmount: convertTo2Decimals(data.creditAmount),
      customer: data.customer,
      paymentType: handleLookforCreditPayment(),
      currency: '1',
      transactionStatus: 'Pending Payment',
      taxAmount: totalTaxes,
      serviceChargeAmount: totalServiceCharge,
      items: data.items,
      isRoomBooking: handleCheckIsBooking(data.items, roomTypes),
      adjustementsAmount: data.items
        .filter((item) => item.itemName === 'Adjustment')
        .reduce((acc, curr) => {
          if (curr.quantity) {
            return acc + curr.price * curr.quantity;
          } else {
            return acc + curr.price;
          }
        }, 0),
      adjustments: adjustmentString,
      settingsName: addHourItem.length > 0 ? 'Add 1 hour' : '',
      addedHour: amountOfHoursAdded ? amountOfHoursAdded : '',
      promotionAmount:
        data.items
          .filter((item) => item.itemType === 'Promotion')
          .reduce((acc, curr) => (acc = acc + curr.price), 0) * -1,
      guestChange: 0,
      guestGiven: 0,
      pan: '',
      paymentReference: '',
      paymentRemarks: '',
    };
    axiosInstance
      .post(apiUrl, formData, config)
      .then((response) => {
        Notification.success('Payment Successfully Registered');
        handleSuccessModalOpen();
      })
      .catch((res) => {
        Notification.failed(res.response.data.message);
      });
  };

  useEffect(() => {
    const apiUrl = `${BASE_API}/promotions/`;
    axiosInstance
      .get(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPromotions([
          roundingPromotion,
          adjustmentPromotion,
          ...response.data.promotions.filter(
            (promotion: IPromotion) => promotion.archived === false
          ),
        ]);
        setAllPromotions(
          response.data.promotions.filter(
            (promotion: IPromotion) => promotion.archived === false
          )
        );
      });
  }, []);

  useEffect(() => {
    const apiUrl = `/promotions/individual-promo/list`;
    axiosInstance
      .get(apiUrl, {
        params: {
          customerId: guestId,
          used: "false"
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status === 'failed') {
          return;
        }
        setIndividualPromotions(
          response.data.data.map((data: any) => ({
            ...data,
            promotionId: data.individualPromoId,
            visibleAtWebsite: true,
          }))
        );
      });
  }, [token]);

  const addPromotion = (adjustmentPromotionProps?: IPromotion) => {
    if (!adjustmentPromotionProps) {
      const isIndividualPromotion = individualPromotions.filter(
        (promotion) => promotion.promotionId === selectedPromotion
      );
      const applyPromotion =
        isIndividualPromotion.length > 0
          ? isIndividualPromotion[0]
          : promotions.filter(
              (promotion) => promotion.promotionId === selectedPromotion
            )[0];

      if (applyPromotion.promoCode === 'Adjustment') {
        handleAdjustmentModalOpen('Adjustment');
        return;
      }

      if (applyPromotion.promoCode === 'Rounding') {
        handleAdjustmentModalOpen('Rounding');
        return;
      }

      if (applyPromotion.seats < 1) {
        Notification.failed('Promotion have no seats left');
        return;
      }

      if (!guest.isMember && applyPromotion.memberOnly) {
        Notification.failed('Only member is elligible for this Promotion');
        return;
      }

      //Remove All promotion if selected is not stackable
      if (!applyPromotion.stackable) {
        setPromotions([roundingPromotion, adjustmentPromotion]);
      }

      //Remove Promotions that are not stackable
      if (applyPromotion.stackable) {
        setPromotions((prevPromotion) =>
          prevPromotion.filter(
            (promotion) =>
              promotion.stackable === true &&
              promotion.promotionId !== applyPromotion.promotionId
          )
        );
      }

      handleAddPromotion(applyPromotion, index, false);
    } else {
      handleAddPromotion(adjustmentPromotionProps, index, false);
    }
  };

  return (
    <>
      {loading && <PosSkeletonLoading />}
      {!loading && (
        <Box
          height={'100%'}
          width={'100%'}
          border={2}
          borderColor={theme.palette.primary.main}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            height={'30px'}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
            paddingX={1}
            sx={{ backgroundColor: theme.palette.background.default }}
          >
            <Typography color={theme.palette.primary.main}>
              {data.invoiceNo} - {guest.guestName}
            </Typography>
            <IconButton
              onClick={handleSubmit}
              color="primary"
              disabled={
                disableSave ||
                isLoading ||
                isLoadingRoomType ||
                roomTypes.length === 0
              }
            >
              <Save />
            </IconButton>
          </Box>
          <Box
            height={'140px'}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
          >
            <Grid container direction={'row'} width={'100%'} height={'100%'}>
              <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                <Box
                  width={'100%'}
                  height={'100%'}
                  borderRight={2}
                  borderColor={theme.palette.primary.main}
                  paddingTop={'30px'}
                  paddingBottom={'10px'}
                  paddingX={2}
                >
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Box>
                      <Typography variant="h5">Status</Typography>
                      <Status status="Pending Payment" />
                    </Box>
                    <Box>
                      <Typography textAlign={'end'} variant="h5">
                        Total
                      </Typography>
                      <Typography
                        textAlign={'end'}
                        marginTop={'10px'}
                        variant="h4"
                      >
                        MYR {convertTo2Decimals(data.debitAmount).toFixed(2)}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                <ButtonBase
                  onClick={() => handlePaymentModalOpen(data)}
                  disabled={
                    data.items.filter(
                      (item) =>
                        item.itemType === extraCharges.tax ||
                        item.itemType === extraCharges.serviceCharge
                    ).length < 1 ||
                    isLoading ||
                    isDeleting
                  }
                  sx={{ width: '100%', height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    width={'100%'}
                    height={'100%'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    border={1}
                    paddingY={'5px'}
                    borderColor={theme.palette.primary.main}
                    sx={{ backgroundColor: theme.palette.background.default }}
                  >
                    <Stack alignItems={'start'}>
                      <ArrowForward
                        sx={{
                          fontSize: '50px',
                          color:
                            data.items.filter(
                              (item) =>
                                item.itemType === extraCharges.tax ||
                                item.itemType === extraCharges.serviceCharge
                            ).length < 1 ||
                            isLoading ||
                            isDeleting
                              ? 'grey'
                              : 'white',
                        }}
                      />
                      <Typography
                        variant="h4"
                        color={
                          data.items.filter(
                            (item) =>
                              item.itemType === extraCharges.tax ||
                              item.itemType === extraCharges.serviceCharge
                          ).length < 1 ||
                          isLoading ||
                          isDeleting
                            ? 'grey'
                            : 'white'
                        }
                      >
                        Payment
                      </Typography>
                    </Stack>
                  </Box>
                </ButtonBase>
              </Grid>
            </Grid>
          </Box>
          <Box
            paddingX={'20px'}
            marginTop={{ xs: '30px', sm: '30px', md: '30px' }}
            height={'388px'}
            overflow={'auto'}
          >
            <Grid container direction={'column'} justifyContent={'center'}>
              <Grid
                item
                borderBottom={2}
                borderColor={theme.palette.primary.main}
              >
                <Grid container paddingY={'10px'} paddingLeft={'10px'}>
                  <Grid item md={6}>
                    <Typography
                      variant={'h5'}
                      color={theme.palette.primary.main}
                      fontWeight={600}
                    >
                      Item
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography
                      variant={'h5'}
                      color={theme.palette.primary.main}
                      fontWeight={600}
                    >
                      Qty
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography
                      variant={'h5'}
                      color={theme.palette.primary.main}
                      fontWeight={600}
                    >
                      Charges
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {data.items.map((item, i) => (
                <Grid
                  item
                  key={i}
                  borderBottom={2}
                  borderColor={'#043b33'}
                  height={'42px'}
                >
                  <Grid
                    container
                    paddingLeft={'10px'}
                    alignItems={'center'}
                    marginTop={'10px'}
                  >
                    <Grid item md={6}>
                      <Typography
                        variant={'h5'}
                        fontWeight={500}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '250px',
                        }}
                      >
                        {item.itemName} - {item.itemType}
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography variant={'h5'} fontWeight={500}>
                        {item.quantity}
                      </Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant={'h5'} fontWeight={500}>
                        {item.quantity
                          ? `RM ${convertTo2Decimals(
                              item.price * item.quantity
                            ).toFixed(2)}`
                          : `RM ${convertTo2Decimals(item.price).toFixed(2)}`}
                      </Typography>
                    </Grid>
                    <Grid item md={1}>
                      {item.category ===
                        transactionCategoryEnum.serviceCharge ||
                      item.category === transactionCategoryEnum.tax ||
                      item.category === transactionCategoryEnum.promotion ||
                      itemLength ? (
                        <></>
                      ) : (
                        <IconButton
                          onClick={() => handleRemoveItem(index, item, false)}
                          disabled={loading}
                          sx={{ padding: 0 }}
                        >
                          <Delete color="error" fontSize="small" />
                        </IconButton>
                      )}
                      {/* {(item.category ===
                        transactionCategoryEnum.adjustmentSales ||
                        item.category === transactionCategoryEnum.rounding) && (
                        <IconButton
                          onClick={() => handleRemoveItem(index, item, false)}
                          disabled={loading}
                          sx={{ padding: 0 }}
                        >
                          <Delete color="error" fontSize="small" />
                        </IconButton>
                      )} */}
                      {item.category === transactionCategoryEnum.promotion && (
                        <IconButton
                          onClick={() =>
                            item.category === transactionCategoryEnum.promotion
                              ? handleRemovePromotion(index, item.itemId, false)
                              : ''
                          }
                          disabled={loading}
                          sx={{ padding: 0 }}
                        >
                          <Delete color="error" fontSize="small" />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            width={'100%'}
            height={'42px'}
            borderTop={2}
            borderColor={theme.palette.primary.main}
          >
            <FormControl
              fullWidth
              sx={{
                '& > label': {
                  top: 23,
                  left: 0,
                  color: '#697586',
                  '&[data-shrink="false"]': {
                    top: -6,
                  },
                },
              }}
            >
              {!selectedPromotion && (
                <InputLabel htmlFor="select-promotion">
                  Select Promotion
                </InputLabel>
              )}
              <Select
                id="select-promotion"
                size="small"
                fullWidth
                value={selectedPromotion}
                onChange={(event) => setSelectedPromotion(event.target.value)}
              >
                <ListSubheader
                  sx={{
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >
                  Adjustments
                </ListSubheader>
                {promotions
                  .filter((promotion) =>
                    ['Rounding', 'Adjustment'].includes(promotion.promoCode)
                  )
                  .map((promotion, index) => (
                    <MenuItem key={index} value={promotion.promotionId}>
                      <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={'100%'}
                      >
                        <Typography
                          whiteSpace={'nowrap'}
                          overflow={'hidden'}
                          textOverflow={'ellipsis'}
                        >
                          {promotion.promoCode}
                        </Typography>
                        <Stack direction={'row'}>
                          <Box
                            display={'flex'}
                            bgcolor={'white'}
                            width={'80px'}
                            justifyContent={'end'}
                            alignItems={'center'}
                            paddingRight={1}
                          >
                            <Typography variant="h4" color={'black'}>
                              {promotion.details.includes('%')
                                ? promotion.details
                                : 'RM' + promotion.details}
                            </Typography>
                          </Box>
                          {promotion.stackable ? (
                            <LayersIcon sx={{ marginLeft: '20px' }} />
                          ) : (
                            <LayersClearIcon sx={{ marginLeft: '20px' }} />
                          )}
                        </Stack>
                      </Stack>
                    </MenuItem>
                  ))}
                <ListSubheader
                  sx={{
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >
                  General
                </ListSubheader>
                {promotions
                  .filter(
                    (promotion) =>
                      promotion.seats > 1 &&
                      new Date(promotion.promoEndDatetime) > new Date() &&
                      !['Rounding', 'Adjustment'].includes(promotion.promoCode)
                  )
                  .map((promotion, index) => (
                    <MenuItem key={index} value={promotion.promotionId}>
                      <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={'100%'}
                      >
                        <Typography
                          whiteSpace={'nowrap'}
                          overflow={'hidden'}
                          textOverflow={'ellipsis'}
                        >
                          {promotion.promoCode}
                        </Typography>
                        <Stack direction={'row'}>
                          <Box
                            display={'flex'}
                            bgcolor={'white'}
                            width={'80px'}
                            justifyContent={'end'}
                            alignItems={'center'}
                            paddingRight={1}
                          >
                            <Typography variant="h4" color={'black'}>
                              {promotion.details.includes('%')
                                ? promotion.details
                                : 'RM' + promotion.details}
                            </Typography>
                          </Box>
                          {promotion.stackable ? (
                            <LayersIcon sx={{ marginLeft: '20px' }} />
                          ) : (
                            <LayersClearIcon sx={{ marginLeft: '20px' }} />
                          )}
                        </Stack>
                      </Stack>
                    </MenuItem>
                  ))}
                <ListSubheader
                  sx={{
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >
                  Individual
                </ListSubheader>
                {individualPromotions
                  .filter(
                    (promotion) =>
                      promotion.seats > 1 &&
                      new Date(promotion.promoEndDatetime) > new Date()
                  )
                  .map((promotion, index) => (
                    <MenuItem key={index} value={promotion.promotionId}>
                      <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={'100%'}
                      >
                        <Typography
                          whiteSpace={'nowrap'}
                          overflow={'hidden'}
                          textOverflow={'ellipsis'}
                        >
                          {promotion.promoCode}
                        </Typography>
                        <Stack direction={'row'}>
                          <Box
                            display={'flex'}
                            bgcolor={'white'}
                            width={'80px'}
                            justifyContent={'end'}
                            alignItems={'center'}
                            paddingRight={1}
                          >
                            <Typography variant="h4" color={'black'}>
                              {promotion.details.includes('%')
                                ? promotion.details
                                : 'RM' + promotion.details}
                            </Typography>
                          </Box>
                          {promotion.stackable ? (
                            <LayersIcon sx={{ marginLeft: '20px' }} />
                          ) : (
                            <LayersClearIcon sx={{ marginLeft: '20px' }} />
                          )}
                        </Stack>
                      </Stack>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button
              variant={'outlined'}
              sx={{ width: '80px' }}
              onClick={() => addPromotion()}
              disabled={!selectedPromotion}
            >
              Apply
            </Button>
          </Box>
          <Modal
            open={successModalOpen}
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                sx: {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
              },
            }}
          >
            <Box sx={style}>
              <Box
                display={'flex'}
                height={'40px'}
                alignItems={'center'}
                paddingX={1}
                borderBottom={1}
                borderColor={theme.palette.primary.main}
              >
                <Stack
                  width={'100%'}
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Typography variant="h4" color={'primary'}>
                    Payment
                  </Typography>
                  <IconButton color="primary" onClick={handleSuccessModalClose}>
                    <HighlightOff />
                  </IconButton>
                </Stack>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                height={'230px'}
                paddingX={'30px'}
                sx={{ backgroundColor: 'black' }}
              >
                <Save
                  color="primary"
                  sx={{ fontSize: '50px', marginRight: '20px' }}
                />
                <Typography variant="h3">Payment Saved</Typography>
              </Box>
              <Stack direction={'row'} width={'100%'} height={'110px'}>
                <ButtonBase
                  href={`/upcoming-booking`}
                  sx={{
                    width: '50%',
                    height: '100%',
                  }}
                >
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={'100%'}
                    height={'100%'}
                    borderTop={1}
                    borderRight={1}
                    borderColor={theme.palette.primary.main}
                    paddingX={1}
                  >
                    <ArrowBack sx={{ fontSize: '50px' }} />
                    <Typography variant="h4">Go to Upcoming Booking</Typography>
                  </Box>
                </ButtonBase>
                <ButtonBase
                  onClick={() => {
                    navigate(`/upcoming-booking/${bookingId}`, {
                      state: { isDirected: true },
                    });
                  }}
                  sx={{
                    width: '50%',
                    height: '100%',
                  }}
                >
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={'100%'}
                    height={'100%'}
                    borderTop={1}
                    borderColor={theme.palette.primary.main}
                    paddingX={1}
                  >
                    <ArrowForward sx={{ fontSize: '50px' }} />
                    <Typography variant="h4">Go to Guest Profile</Typography>
                  </Box>
                </ButtonBase>
              </Stack>
            </Box>
          </Modal>

          <AdjustmentModal
            open={openAdjustmentModal}
            handleClose={handleAdjustmentModalClose}
            addPromotion={addPromotion}
          />
        </Box>
      )}
    </>
  );
};

export default PaymentBoxUnsaved;
