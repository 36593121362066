import { ArrowBackIos, Search } from "@mui/icons-material";
import {
	Box,
	Button,
	CircularProgress,
	InputAdornment,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import {
	INewStaffShift,
	ShiftSettings,
} from "../../models/accounts/ShiftInterface";
import Notification from "../../utils/notificationConfig";
import axiosInstance from "../../constants/axiosConfig";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
	ArrowDropDownIcon,
	LocalizationProvider,
	TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { INewAccountData } from "../../models/accounts/accountInterface";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import CustomTable from "../global/table/CustomTable";
import ICashier, {
	IPaymentTerminal,
} from "../../models/payment/PaymentInterface";
import ICashierPaymentTerminal from "../../models/payment/PaymentInterface";

interface AddOrEditPaymentTerminalProps {
	handleBack: VoidFunction;
	selectedPaymentTerminal: IPaymentTerminal;
	setPaymentTerminal: React.Dispatch<React.SetStateAction<IPaymentTerminal[]>>;
	cashierData?: ICashierPaymentTerminal[];
}
const initialPaymentTerminalData: IPaymentTerminal = {
	id: "",
	cashierPaymentTerminals: [],
	terminalId: "",
	serialNumber: "",
	createdDatetime: "",
	updatedDatetime: "",
	paymentTerminalIp: "",
	archived: false,
	lot: 0,
};

// const cashierDataTest: ICashierData[] = [
// 	{
// 		id: 1,
// 		cashierNumber: "PC Walk-In",
// 		createdDatetime: "2024-03-29T08:00:00Z",
// 		updatedDatetime: "2024-03-29T08:00:00Z",
// 		paymentTerminal: "Terminal-001",
// 	},
// 	{
// 		id: 2,
// 		cashierNumber: "PC ОТА",
// 		createdDatetime: "2024-03-29T08:00:00Z",
// 		updatedDatetime: "2024-03-29T08:00:00Z",
// 		paymentTerminal: "Terminal-001",
// 	},
// 	{
// 		id: 3,
// 		cashierNumber: "T2-Airside",
// 		createdDatetime: "2024-03-29T08:00:00Z",
// 		updatedDatetime: "2024-03-29T08:00:00Z",
// 		paymentTerminal: "Terminal-001",
// 	},
// 	{
// 		id: 4,
// 		cashierNumber: "T1",
// 		createdDatetime: "2024-03-29T08:00:00Z",
// 		updatedDatetime: "2024-03-29T08:00:00Z",
// 		paymentTerminal: "Terminal-001",
// 	}
// ];

const AddOrEditPaymentTerminal = (props: AddOrEditPaymentTerminalProps) => {
	const theme = useTheme();
	const token = useSelector((state: RootState) => state.user.accessToken);
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	const [selectedPaymentTerminal, setSelectedPaymentTerminal] =
		useState<IPaymentTerminal>(initialPaymentTerminalData);

	const [searchFilter, setSearchFilter] = useState<string>("");

	const [paymentTerminal, setPaymentTerminal] = useState<IPaymentTerminal[]>(
		[]
	);

	const [isFetchingAccount, setIsFetchingAccount] = useState<boolean>(false);

	const [isAssignCashier, setIsAssignCashier] = useState<boolean>(false);

	const [assignedCashier, setAssignedCashier] = useState<ICashier[]>([]);
	const [unassignedCashier, setUnassignedCashier] = useState<ICashier[]>([]);

	const [cashierData, setCashierData] = useState<ICashier[]>([]);

	useEffect(() => {
		if (props.selectedPaymentTerminal.id) {
			setSelectedPaymentTerminal(props.selectedPaymentTerminal);
		} else {
			setSelectedPaymentTerminal(initialPaymentTerminalData);
		}
	}, [props.selectedPaymentTerminal]);

	const getPaymentTerminal = () => {
		axiosInstance
			.get(`/payment-terminal/get/${selectedPaymentTerminal.id}`, config)
			.then((res) => {
				if (res.status === 200) {
					setPaymentTerminal(res.data.data);
					setAssignedCashier(
						res.data.data.cashierPaymentTerminals
							.map((item: { cashierTerminal: any }) => item.cashierTerminal)
							.filter(Boolean)
					);
					return;
				}
			})
			.catch((e) => {
				Notification.failed(e.response.data.msg);
			});
	};

	const getCashier = () => {
		axiosInstance
			.get(`/cashierterminal/`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
				setCashierData(
					res.data.data.sort(
						(
							a: { cashierTerminalId: string },
							b: { cashierTerminalId: string }
						) => (a.cashierTerminalId > b.cashierTerminalId ? 1 : -1)
					)
				);
			})
			.catch((res) => {
				Notification.failed("retrieve failed");
			});
	};
	useEffect(() => {
		getCashier();
	}, [props.cashierData, props.selectedPaymentTerminal]);
	// const getAccounts = () => {
	// 	setIsFetchingAccount(true);
	// 	axiosInstance
	// 		.get("/payment-terminal/", config)
	// 		.then((res) => {
	// 			if (res.status === 200) {
	// 				setAccounts(
	// 					res.data.data.map((data: any) => ({
	// 						...data,
	// 						roles: data.role.roleId,
	// 					}))
	// 				);
	// 				return;
	// 			}
	// 		})
	// 		.catch((e) => {
	// 			console.log(e.response);
	// 			Notification.failed(e.response.data.msg);
	// 		})
	// 		.finally(() => setIsFetchingAccount(false));
	// };

	// useEffect(() => {
	// 	getAccounts();
	// }, []);

	useEffect(() => {
		if (selectedPaymentTerminal.id) getPaymentTerminal();
	}, [selectedPaymentTerminal]);

	const formik = useFormik({
		initialValues: selectedPaymentTerminal,
		enableReinitialize: true,
		onSubmit: (values, actions) => {
			const apiUrl = selectedPaymentTerminal.id
				? "/payment-terminal/edit/"
				: "/payment-terminal/ ";

			if (selectedPaymentTerminal.id) {
				const formData = {
					payment_terminal_id: values.id,
					terminal_id: values.terminalId,
					serial_number: values.serialNumber,
					payment_terminal_ip: values.paymentTerminalIp,
				};
				axiosInstance
					.patch(apiUrl, formData, config)
					.then((res) => {
						Notification.success(res.data.message);
						props.handleBack();
						props.setPaymentTerminal((prevPaymentTerminal) => {
							const removedShift = prevPaymentTerminal.find(
								(paymentTerminal) =>
									paymentTerminal.id === props.selectedPaymentTerminal?.id
							);

							if (removedShift) {
								const newShift = prevPaymentTerminal.filter(
									(paymentTerminal) =>
										paymentTerminal.id !== props.selectedPaymentTerminal?.id
								);

								return [
									{
										...values,
										terminalId: values.terminalId,
									},
									...newShift,
								];
							}
							return prevPaymentTerminal;
						});
					})
					.catch((res) => {
						Notification.failed("Payment terminal data must be unique");
					})
					.finally(() => actions.setSubmitting(false));
				return;
			}

			const formData = {
				terminalId: values.terminalId,
				serialNumber: values.serialNumber,
				payment_terminal_ip: values.paymentTerminalIp,
			};

			axiosInstance
				.post(apiUrl, formData, config)
				.then((res) => {
					Notification.success(res.data.message);
					props.handleBack();
				})
				.catch((res) => {
					Notification.failed("Payment terminal data must be unique");
				})
				.finally(() => actions.setSubmitting(false));
		},
	});

	const handleArchivePaymentTerminal = () => {
		formik.setSubmitting(true);
		const apiUrl = `/payment-terminal/archive/ `;
		const formData = {
			payment_terminal_id: formik.values.id,
			archive: !formik.values.archived,
		};

		axiosInstance
			.put(apiUrl, formData, config)
			.then((res) => {
				Notification.success(res.data.message);
				props.handleBack();
				window.location.reload();
			})
			.catch((res) => {
				Notification.failed(res.response.data.msg);
			})
			.finally(() => formik.setSubmitting(false));
	};

	useEffect(() => {
		if (props.selectedPaymentTerminal.cashierPaymentTerminals.length > 0) {

			const unassignedCashier = cashierData.filter((cashier) => {
				return !assignedCashier.some(ac => ac.cashierTerminalId === cashier.cashierTerminalId);

			});

			setUnassignedCashier(unassignedCashier);
		} else {
			setUnassignedCashier(cashierData);
		}
	}, [cashierData,assignedCashier]);
	return (
		<form onSubmit={formik.handleSubmit}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Stack direction={"column"} spacing={4}>
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
						alignItems={"center"}>
						<Stack direction={"row"} alignItems={"center"} spacing={2}>
							<Button variant="text" onClick={props.handleBack}>
								<ArrowBackIos fontSize="small" />
								BACK
							</Button>
							<Typography variant="h3">
								{selectedPaymentTerminal.id !== ""
									? `Edit ${selectedPaymentTerminal.terminalId}`
									: "New Payment Terminal"}
							</Typography>
						</Stack>
						{selectedPaymentTerminal.id && formik.isSubmitting ? (
							<CircularProgress size={24} color="primary" />
						) : selectedPaymentTerminal.id ? (
							<Button variant="outlined" onClick={handleArchivePaymentTerminal}>
								{formik.values.archived ? "Unarchive" : "Archive"}
							</Button>
						) : null}
					</Stack>
					<Typography variant="h3">Payment Terminal Information</Typography>
					<Stack direction={"column"} spacing={2}>
						<Stack direction={"row"} alignItems={"center"} spacing={3}>
							<Typography variant="h4" width={"180px"}>
								Terminal ID
							</Typography>
							<TextField
								fullWidth
								variant="standard"
								name="terminalId"
								value={formik.values.terminalId}
								onChange={formik.handleChange}
							/>
						</Stack>
						<Stack direction={"row"} alignItems={"center"} spacing={3}>
							<Typography variant="h4" width={"180px"}>
								Serial Number
							</Typography>
							<TextField
								fullWidth
								variant="standard"
								name="serialNumber"
								value={formik.values.serialNumber}
								onChange={formik.handleChange}
							/>
						</Stack>
						<Stack direction={"row"} alignItems={"center"} spacing={3}>
							<Typography variant="h4" width={"180px"}>
								Payment Terminal IP
							</Typography>
							<TextField
								fullWidth
								variant="standard"
								name="paymentTerminalIp"
								value={formik.values.paymentTerminalIp}
								onChange={formik.handleChange}
							/>
						</Stack>
					</Stack>
					<Box display={"flex"} justifyContent={"end"}>
						<Button variant="outlined" type="submit" sx={{ width: "80px" }}>
							{formik.isSubmitting ? (
								<CircularProgress size={24} color="primary" />
							) : (
								"Save"
							)}
						</Button>
					</Box>
					{selectedPaymentTerminal.id && (
						<>
							<Typography variant="h3">Cashier List</Typography>
							<Stack direction={"column"} spacing={1}>
								<TextField
									fullWidth
									variant="standard"
									value={searchFilter}
									onChange={(event) => setSearchFilter(event.target.value)}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Search sx={{ color: "white" }} />
											</InputAdornment>
										),
									}}
									inputProps={{
										style: { textTransform: "none" },
									}}
								/>
							</Stack>
							{isAssignCashier ? (
								<CashierSection
									key={"assigning"}
									cashiers={unassignedCashier}
									isFetchingAccount={isFetchingAccount}
									selectedPaymentTerminalId={selectedPaymentTerminal.id}
									searchFilter={searchFilter}
									assignCashier={isAssignCashier}
									setAssignCashier={setIsAssignCashier}
									setAssignedCashier={setAssignedCashier}
									setUnassignedCashier={setUnassignedCashier}
								/>
							) : (
								<CashierSection
									key={"unassigning"}
									cashiers={assignedCashier}
									isFetchingAccount={isFetchingAccount}
									selectedPaymentTerminalId={selectedPaymentTerminal.id}
									searchFilter={searchFilter}
									assignCashier={isAssignCashier}
									setAssignCashier={setIsAssignCashier}
									setAssignedCashier={setAssignedCashier}
									setUnassignedCashier={setUnassignedCashier}
								/>
							)}
						</>
					)}
				</Stack>
			</LocalizationProvider>
		</form>
	);
};

const CashierSection = (props: {
	cashiers: ICashier[];
	isFetchingAccount: boolean;
	assignCashier: boolean;
	setAssignCashier: React.Dispatch<React.SetStateAction<boolean>>;
	selectedPaymentTerminalId: string;
	setUnassignedCashier: React.Dispatch<React.SetStateAction<ICashier[]>>;
	setAssignedCashier: React.Dispatch<React.SetStateAction<ICashier[]>>;
	searchFilter: string;
}) => {
	const theme = useTheme();
	const token = useSelector((state: RootState) => state.user.accessToken);
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	const [pagination, setPagination] = useState<MRT_PaginationState>({
		pageIndex: 0,
		pageSize: 5,
	});

	const handleAssignCashier = (cashier: ICashier) => {
		const formData = {
			paymentTerminalId: props.selectedPaymentTerminalId,
			cashierTerminalId: cashier.cashierTerminalId,
		};

		axiosInstance
			.post("/payment-terminal/assign-cashier/", formData, config)
			.then((res) => {
				Notification.success("Assign success");
				props.setAssignedCashier((prevValue) => [cashier, ...prevValue]);
				props.setUnassignedCashier((prevValue) =>
					prevValue.filter(
						(cashierData) =>
							cashierData.cashierTerminalId !== cashier.cashierTerminalId
					)
				);
			})
			.catch((e) => {
				Notification.failed(e.response.data.message);
			});
	};

	const handleUnAssignCashier = (cashier: ICashier) => {
		const formData = {
			paymentTerminalId: props.selectedPaymentTerminalId,
			cashierTerminalId: cashier.cashierTerminalId,
		};
		axiosInstance
			.put("/payment-terminal/unassign-cashier/", formData, config)
			.then((res) => {
				Notification.success("Unassign Success");
				props.setAssignedCashier((prevValue) =>
					prevValue.filter(
						(cashierData) =>
							cashierData.cashierTerminalId !== cashier.cashierTerminalId
					)
				);
				props.setUnassignedCashier((prevValue) => [cashier, ...prevValue]);
			})
			.catch((e) => {
				Notification.failed(e.response.data.message);
			});
	};

	const columns = useMemo<MRT_ColumnDef<ICashier>[]>(
		() => [
			{
				header: "Cashier Number",
				accessorKey: "cashierTerminal",
			},
			{
				header: "Action",
				size: 50,
				Cell: ({ row }) => (
					<Button
						variant="text"
						onClick={() => {
							if (props.assignCashier) {
								handleAssignCashier(row.original);
							} else {
								handleUnAssignCashier(row.original);
							}
						}}>
						{props.assignCashier ? "Assign" : "Remove"}
					</Button>
				),
			},
		],
		[]
	);

	return (
		<Box border={1} borderColor={theme.palette.primary.main}>
			<Stack
				direction={"row"}
				width={"100%"}
				justifyContent={"space-between"}
				alignItems={"center"}
				sx={{
					backgroundColor: theme.palette.background.paper,
					borderBottom: 1,
					borderColor: theme.palette.primary.main,
				}}>
				<Typography variant="h3" marginLeft={1} color={"primary"}>
					Cashier
				</Typography>
				<Button
					variant={"contained"}
					onClick={() => props.setAssignCashier(!props.assignCashier)}
					sx={{
						width: "150px",
						color: theme.palette.primary.main,
						border: 1,
						borderColor: theme.palette.primary.main,
						backgroundColor: theme.palette.background.paper,
					}}>
					{props.assignCashier ? "Remove Cashier" : "Add Cashier"}
				</Button>
			</Stack>
			<Box padding={2} bgcolor={"black"}>
				<CustomTable
					data={props.cashiers}
					columns={columns}
					isLoading={props.isFetchingAccount}
					handlePaginationChange={setPagination}
					pagination={pagination}
					rowCount={props.cashiers.length}
					oddEvenBackground={true}
					globalFilter={props.searchFilter}
				/>
			</Box>
		</Box>
	);
};

export default AddOrEditPaymentTerminal;
