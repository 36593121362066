import { FileDownload, FilterAlt, Flag } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ArrowDropDownIcon,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import XLSX from "sheetjs-style";
import { ReactComponent as ExcelIcon } from "../../assets/images/icons8-excel.svg";
import CustomTable from "../../components/global/table/CustomTable";
import ZReportFilters from "../../components/z-report/ZReportFilters";
import axiosInstance from "../../constants/axiosConfig";
import { transactionCategoryEnum } from "../../constants/enums";
import {
  IDetailedZReport,
  ILot,
  IPaymentIntegrationZReport,
  IPaymentOverview,
  ISalesZReport,
  ISummaryZReport,
} from "../../models/payment/PaymentInterface";
import { RootState } from "../../redux/store";
import {
  convertToReadableFormat,
  formatDocumentDateFormat,
  formatToPresentableNumber,
  isValidDate,
  stringDateToEpoch,
} from "../../utils/functions";
import Notification from "../../utils/notificationConfig";

const lotInitialValue: ILot = {
  airportCodeId: 0,
  lotDescription: "",
  lotId: 0,
  lotNumber: "",
};

const ZReport = () => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isDownloadingExcel, setIsDownloadingExcel] = useState<boolean>(false);
  const [isReporting, setIsReporting] = useState<boolean>(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleCloseDropdown = () => setAnchorEl(null);
  const handleOpenDropdown = ({
    currentTarget,
  }: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(currentTarget);
  };

  const today = new Date();

  const startOfDay = new Date(today);
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date(today);
  endOfDay.setHours(23, 59, 59, 999);

  const currentDate = new Date();
  const currentMonthName = format(currentDate, "MMMM");

  const [reportType, setReportType] = useState<string>("KLIA/KLIA2");
  const [version, setVersion] = useState<string>("salesFile");

  const [detailedData, setDetailedData] = useState<IDetailedZReport[]>([]);
  const [summaryData, setSummaryData] = useState<ISummaryZReport[]>([]);
  const [salesData, setSalesData] = useState<ISalesZReport[]>([]);
  const [paymentData, setPaymentData] = useState<IPaymentIntegrationZReport[]>(
    []
  );

  const [startDate, setStartDate] = useState<Date>(startOfDay);
  const [endDate, setEndDate] = useState<Date>(endOfDay);

  const [paymentOverview, setPaymentOverview] = useState<IPaymentOverview[]>(
    []
  );

  const [currentLot, setCurrentLot] = useState<ILot>(lotInitialValue);

  const totalDetailedSales = useMemo(
    () => detailedData.reduce((acc, curr) => acc + curr.sales, 0),
    [detailedData]
  );

  const totalSummaryRoomSales = useMemo(
    () => summaryData.reduce((acc, curr) => acc + curr.roomSales, 0),
    [summaryData]
  );

  const totalSummaryPOSSales = useMemo(
    () => summaryData.reduce((acc, curr) => acc + curr.posSales, 0),
    [summaryData]
  );

  const totalSummarySales = useMemo(
    () => summaryData.reduce((acc, curr) => acc + curr.total, 0),
    [summaryData]
  );

  const totalSalesReportSales = useMemo(
    () => salesData.reduce((acc, curr) => acc + curr.totalAmount, 0),
    [salesData]
  );

  const totalSalesReportServiceCharge = useMemo(
    () => salesData.reduce((acc, curr) => acc + curr.serviceChargeAmount, 0),
    [salesData]
  );

  const totalSalesReportTax = useMemo(
    () => salesData.reduce((acc, curr) => acc + curr.taxAmount, 0),
    [salesData]
  );

  const totalPaymentIntegrationReportSales = useMemo(
    () => paymentData.reduce((acc, curr) => acc + curr.amount, 0),
    [paymentData]
  );

  // ----------------------Un-comment Once API is Done------------------------------------------
  const fetchTransactions = () => {
    setIsLoading(true);

    const endDateTime = endDate;

    endDateTime.setHours(23, 59, 59, 999);

    axiosInstance
      .get(`/transaction/get-z-report`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          // startDateTime: startDate
          //   ? stringDateToEpoch(startDate.toString())
          //   : "",
          // endDateTime: endDate ? stringDateToEpoch(endDateTime.toString()) : "",
          startDateTime: startDate ? startDate : "",
          endDateTime: endDate ? endDateTime : "",
          reportType: reportType,
          versions: version,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const dataList = res.data.data;
          if (version === "detailed") {
            setDetailedData(
              dataList.map((data: any) => ({
                ...data,
                sales: parseFloat(data.sales),
                paymentDate: isValidDate(data.paymentDate)
                  ? format(new Date(data.paymentDate), "dd/MM/yyyy")
                  : data.paymentDate,
                checkIn: isValidDate(data.checkIn)
                  ? format(new Date(data.checkIn), "dd/MM/yyyy")
                  : data.checkIn,
                checkOut: isValidDate(data.checkOut)
                  ? format(new Date(data.checkOut), "dd/MM/yyyy")
                  : data.checkOut,
              }))
            );
            setSummaryData([]);
            setSalesData([]);
            setPaymentData([]);
          }
          if (version === "summary") {
            setSummaryData(
              dataList.map((data: any) => ({
                ...data,
                roomSales: parseFloat(data.roomSales),
                posSales: parseFloat(data.posSales),
                total: parseFloat(data.total),
              }))
            );
            setDetailedData([]);
            setSalesData([]);
            setPaymentData([]);
          }
          if (version === "salesFile") {
            setSalesData(
              dataList.map((data: any) => ({
                ...data,
                totalAmount: parseFloat(data.totalAmount),
                serviceChargeAmount: parseFloat(data.serviceChargeAmount),
                taxAmount: parseFloat(data.taxAmount),
                salesDate:
                  data.salesDate !== "-"
                    ? format(new Date(data.salesDate), "dd/MM/yyyy")
                    : data.salesDate,
                businessDate:
                  data.businessDate !== "-"
                    ? format(new Date(data.businessDate), "dd/MM/yyyy")
                    : data.businessDate,
              }))
            );
            setDetailedData([]);
            setSummaryData([]);
            setPaymentData([]);
          }
          if (version === "paymentFile") {
            setPaymentData(
              dataList.map((data: any) => ({
                ...data,
                amount: parseFloat(data.amount),
                paymentDate:
                  data.paymentDate !== "-"
                    ? format(new Date(data.paymentDate), "dd/MM/yyyy")
                    : data.paymentDate,
                businessDate:
                  data.businessDate !== "-"
                    ? format(new Date(data.businessDate), "dd/MM/yyyy")
                    : data.businessDate,
              }))
            );
            setDetailedData([]);
            setSummaryData([]);
            setSalesData([]);
          }
        }
      })
      .catch((e) => {
        Notification.failed("something wrong, please try again");
      })
      .finally(() => setIsLoading(false));
  };

  const fetchOverviewTransactions = () => {
    axiosInstance
      .get(`/transaction/report-overview`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDateTime: startDate
            ? stringDateToEpoch(startDate.toString())
            : "",
          endDateTime: endDate ? stringDateToEpoch(endDate.toString()) : "",
          reportType: reportType,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setPaymentOverview(res.data.data);
        }
      });
  };

  useEffect(() => {
    fetchTransactions();
    fetchOverviewTransactions();
  }, [startDate, endDate, reportType, version]);

  const fetchCurrentLot = () => {
    axiosInstance
      .get(`/lot/get-own-lot`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setCurrentLot(res.data);
          return;
        }
        Notification.failed(res.data.msg);
      })
      .catch((e) => {
        Notification.failed(e.response.data.msg);
      });
  };

  useEffect(() => {
    fetchCurrentLot();
  }, []);

  const detailedDataColumns = useMemo<MRT_ColumnDef<IDetailedZReport>[]>(
    () => [
      {
        header: "Item",
        accessorKey: "item",
      },

      {
        header: "Type",
        Cell: ({ row }) => <Typography>{row.original.type}</Typography>,
      },
      {
        header: "ID",
        Cell: ({ row }) => <Typography>{row.original.id}</Typography>,
      },

      {
        header: "Details",
        size: 200,
        Cell: ({ row }) => (
          <Stack direction={"column"}>
            {row.original.details && row.original.details.length > 0
              ? row.original.details
                  .filter(
                    (item) =>
                      item.category !== transactionCategoryEnum.serviceCharge &&
                      item.category !== transactionCategoryEnum.tax &&
                      item.category !== transactionCategoryEnum.promotion &&
                      item.category !== transactionCategoryEnum.rounding &&
                      item.category !== transactionCategoryEnum.adjustmentSales
                  )
                  .map((item) => (
                    <Typography>{`${item.quantity ? item.quantity + "x" : ""} ${
                      item.itemName
                    } ${
                      item.itemType && item.itemType !== "Merch"
                        ? item.itemType
                        : ""
                    }`}</Typography>
                  ))
              : ""}
          </Stack>
        ),
      },
      {
        header: "Payment Date",
        Cell: ({ row }) => <Typography>{row.original.paymentDate}</Typography>,
      },
      {
        header: "Check-In",
        Cell: ({ row }) => <Typography>{row.original.checkIn}</Typography>,
      },
      {
        header: "Check-Out",
        Cell: ({ row }) => <Typography>{row.original.checkOut}</Typography>,
      },
      {
        header: "Sales(RM)",
        Cell: ({ row }) => (
          <Typography>
            {row.original.sales
              ? formatToPresentableNumber(row.original.sales.toString())
              : ""}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Sales:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalDetailedSales?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
    ],
    [totalDetailedSales]
  );

  const summaryDataColumns = useMemo<MRT_ColumnDef<ISummaryZReport>[]>(
    () => [
      {
        header: "Date",
        Cell: ({ row }) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"200px"}
            height={"30px"}
            bgcolor={"black"}
            color={theme.palette.primary.main}
            border={1}
            borderRadius={1}
            borderColor={theme.palette.primary.main}
          >
            {row.original.date}
          </Box>
        ),
      },
      {
        header: "Room Sales",
        Cell: ({ row }) => (
          <Typography>
            {row.original.roomSales
              ? formatToPresentableNumber(row.original.roomSales.toString())
              : ""}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Room Sales:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalSummaryRoomSales?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "POS",
        Cell: ({ row }) => (
          <Typography>
            {row.original.posSales
              ? formatToPresentableNumber(row.original.posSales.toString())
              : ""}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total POS Sales:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalSummaryPOSSales?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Total",
        Cell: ({ row }) => (
          <Typography>
            {row.original.total
              ? formatToPresentableNumber(row.original.total.toString())
              : ""}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Transactions:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalSummarySales?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
    ],
    [totalSummaryPOSSales, totalSummaryRoomSales, totalSummarySales]
  );

  const salesDataColumns = useMemo<MRT_ColumnDef<ISalesZReport>[]>(
    () => [
      {
        header: "ID",
        Cell: ({ row }) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"200px"}
            height={"30px"}
            bgcolor={"black"}
            color={theme.palette.primary.main}
            border={1}
            borderRadius={1}
            borderColor={theme.palette.primary.main}
          >
            {row.original.transactionId}
          </Box>
        ),
      },

      {
        header: "Total Amount",
        Cell: ({ row }) => (
          <Typography>
            {row.original.totalAmount
              ? formatToPresentableNumber(row.original.totalAmount.toString())
              : ""}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Amount:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalSalesReportSales?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Service Charge Amount",
        Cell: ({ row }) => (
          <Typography>
            {row.original.serviceChargeAmount
              ? formatToPresentableNumber(
                  row.original.serviceChargeAmount.toString()
                )
              : "RM 0"}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Amount:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalSalesReportServiceCharge?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Tax",
        Cell: ({ row }) => (
          <Typography>
            {row.original.taxAmount
              ? formatToPresentableNumber(row.original.taxAmount.toString())
              : ""}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Amount:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalSalesReportTax?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },

      {
        header: "Status",
        Cell: ({ row }) => <Typography>{row.original.status}</Typography>,
      },

      {
        header: "Sales Date",
        Cell: ({ row }) => <Typography>{row.original.salesDate}</Typography>,
      },
      {
        header: "Business Date",
        Cell: ({ row }) => <Typography>{row.original.businessDate}</Typography>,
      },
      {
        header: "Need Update Sales",
        Cell: ({ row }) => <Checkbox checked={row.original.needUpdateSales} />,
      },
    ],
    [totalSalesReportSales, totalSalesReportServiceCharge, totalSalesReportTax]
  );

  const paymentDataColumns = useMemo<
    MRT_ColumnDef<IPaymentIntegrationZReport>[]
  >(
    () => [
      {
        header: "ID",
        Cell: ({ row }) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"200px"}
            height={"30px"}
            bgcolor={"black"}
            color={theme.palette.primary.main}
            border={1}
            borderRadius={1}
            borderColor={theme.palette.primary.main}
          >
            {row.original.transactionId}
          </Box>
        ),
      },

      {
        header: "Payment Amount",
        Cell: ({ row }) => (
          <Typography>
            {row.original.amount
              ? formatToPresentableNumber(row.original.amount.toString())
              : ""}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Amount:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalPaymentIntegrationReportSales?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Currency Code",
        Cell: ({ row }) => <Typography>{row.original.currencyCode}</Typography>,
      },
      {
        header: "Currency Amount",
        Cell: ({ row }) => (
          <Typography>
            {row.original.currencyAmount
              ? formatToPresentableNumber(
                  row.original.currencyAmount.toString()
                )
              : ""}
          </Typography>
        ),
      },

      {
        header: "Currency Rate",
        Cell: ({ row }) => (
          <Typography>
            {row.original.currencyExchangeRate
              ? formatToPresentableNumber(
                  row.original.currencyExchangeRate.toString()
                )
              : ""}
          </Typography>
        ),
      },

      {
        header: "Payment Date",
        Cell: ({ row }) => <Typography>{row.original.paymentDate}</Typography>,
      },
      {
        header: "Business Date",
        Cell: ({ row }) => <Typography>{row.original.businessDate}</Typography>,
      },
      {
        header: "Payment Method",
        Cell: ({ row }) => (
          <Typography>{row.original.paymentMethod}</Typography>
        ),
      },
      {
        header: "Payment Type",
        Cell: ({ row }) => <Typography>{row.original.paymentType}</Typography>,
      },
    ],
    [totalPaymentIntegrationReportSales]
  );

  const handleChangeReportType = (
    reportType: "KLIA/KLIA2" | "Gatewaymall"
  ): void => {
    setReportType(reportType);
    if (reportType === "KLIA/KLIA2") {
      setVersion("salesFile");
    }
    if (reportType === "Gatewaymall") {
      setVersion("detailed");
    }
  };

  const handleChangeVersion = (
    version: "summary" | "detailed" | "salesFile" | "paymentFile"
  ): void => {
    setVersion(version);
  };

  const handleStartDateChange = (startDate: Date) => {
    setStartDate(startDate);
  };

  const handleEndDateChange = (endDate: Date) => {
    endDate.setHours(23, 59, 59, 999);
    setEndDate(endDate);
  };

  const convertItemDetails = (unformattedDetailedData: IDetailedZReport[]) => {
    const footer = {
      item: "",
      type: "",
      id: "",
      details: "",
      paymentDate: "",
      checkIn: "",
      checkOut: "",
      sales: `Total Amount: ${totalDetailedSales.toFixed(2)}`,
    };
    const formattedDetailedData = unformattedDetailedData.map((data) => ({
      ...data,
      item: data.item.toString(),
      sales: data.sales.toString(),
      details: data.details
        .filter(
          (detail) =>
            detail.itemType !== "Promotion" &&
            detail.itemType !== "Tax" &&
            detail.itemType !== "Service Charge" &&
            detail.itemType !== "Rounding"
        )
        .map((detail) =>
          detail.quantity
            ? `${detail.quantity}x ${detail.itemName}`
            : detail.itemName
        )
        .join(", "),
    }));

    formattedDetailedData.push(footer);

    return formattedDetailedData;
  };

  const summaryAddFooter = (datas: ISummaryZReport[]) => {
    const footer = {
      item: "",
      date: "",
      roomSales: `Total Amount: ${totalSummaryRoomSales.toFixed(2)}`,
      posSales: `Total Amount: ${totalSummaryPOSSales.toFixed(2)}`,
      total: `Total Amount: ${totalSummarySales.toFixed(2)}`,
    };
    const formattedSalesData = datas.map((data) => ({
      ...data,
      item: data.item.toString(),
      roomSales: data.roomSales.toString(),
      posSales: data.posSales.toString(),
      total: data.total.toString(),
    }));

    formattedSalesData.push(footer);

    return formattedSalesData;
  };

  const salesFileAddFooter = (datas: any) => {
    const footer = {
      transactionId: "",
      totalAmount: `Total Amount: ${totalSalesReportSales.toFixed(2)}`,
      serviceChargeAmount: `Total Amount: ${totalSalesReportServiceCharge.toFixed(
        2
      )}`,
      taxAmount: `Total Amount: ${totalSalesReportTax.toFixed(2)}`,
      status: "",
      salesDate: "",
      businessDate: "",
      needUpdateSales: false,
    };
    const formattedSalesData = datas.map((data: any) => ({
      ...data,
      totalAmount: data.totalAmount.toString(),
      serviceChargeAmount: data.serviceChargeAmount.toString(),
      taxAmount: data.taxAmount.toString(),
    }));

    formattedSalesData.push(footer);

    return formattedSalesData;
  };

  const paymentFileAddFooter = (datas: any) => {
    const footer = {
      transactionId: "",
      amount: `Total Amount: ${totalPaymentIntegrationReportSales.toFixed(2)}`,
      currencyCode: "",
      currencyAmount: "",
      currencyExchangeRate: "",
      paymentDate: "",
      businessDate: "",
      paymentMethod: "",
      paymentType: "",
    };
    const formattedPaymentFileData = datas.map((data: any) => ({
      ...data,
      amount: data.amount.toString(),
      currencyAmount: data.currencyAmount.toString(),
      currencyExchangeRate: data.currencyExchangeRate.toString(),
    }));

    formattedPaymentFileData.push(footer);

    return formattedPaymentFileData;
  };

  const handleDownloadReport = () => {
    let dataReport: unknown = [];
    let apiUrl = `/transaction/generate-z-report/`;
    if (version === "detailed") {
      dataReport = convertItemDetails(detailedData);
    }
    if (version === "summary") {
      dataReport = summaryData;
      apiUrl = `/transaction/generate-z-report-txt/`;
    }
    if (version === "salesFile") {
      dataReport = salesData.map((data) => ({
        ...data,
        needUpdateSales: data.needUpdateSales ? "1" : "0",
        serviceChargeAmount: data.serviceChargeAmount.toFixed(2),
      }));
      apiUrl = `/transaction/generate-z-report-txt/`;
    }
    if (version === "paymentFile") {
      dataReport = paymentData.map((data) => ({
        ...data,
        amount: data.amount.toFixed(2),
        currencyAmount: (data.amount * 1).toFixed(2),
      }));
      apiUrl = `/transaction/generate-z-report-txt/`;
    }

    setIsDownloading(true);

    const formData = {
      data: dataReport,
      startDate: format(startDate, "dd MMMM yyyy"),
      endDate: format(endDate, "dd MMMM yyyy"),
      version: version,
      lotId: currentLot.lotId,
    };

    axiosInstance
      .post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          // Decode the base64 encoded PDF content
          const pdfContent = atob(res.data.pdf_base64);

          handleDownloadFile(pdfContent);
        }
      })
      .catch((res) => {
        Notification.failed(res.response.data.msg);
      })
      .finally(() => setIsDownloading(false));

    if (version === "paymentFile" || version === "salesFile") {
      apiUrl = `/transaction/generate-z-report/`;

      let dataToGenerate = dataReport;

      if (version === "paymentFile") {
        dataToGenerate = paymentFileAddFooter(dataToGenerate);
      } else {
        dataToGenerate = salesFileAddFooter(dataToGenerate);
      }

      const formData = {
        data: dataToGenerate,
        startDate: format(startDate, "dd MMMM yyyy"),
        endDate: format(endDate, "dd MMMM yyyy"),
      };
      axiosInstance
        .post(apiUrl, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            // Decode the base64 encoded PDF content
            const pdfContent = atob(res.data.pdf_base64);

            handleDownloadFile(pdfContent, true);
          }
        })
        .catch((res) => {
          Notification.failed(res.response.data.msg);
        })
        .finally(() => setIsDownloading(false));
    }
  };
  //Change to Report API
  const handleReportFile = () => {
    let dataReport: unknown = [];
    let apiUrl = `/transaction/generate-z-report/`;
    if (version === "detailed") {
      dataReport = convertItemDetails(detailedData);
    }
    if (version === "summary") {
      dataReport = summaryData;
      apiUrl = `/transaction/generate-z-report-txt/`;
    }
    if (version === "salesFile") {
      dataReport = salesData.map((data) => ({
        ...data,
        needUpdateSales: data.needUpdateSales ? "1" : "0",
        serviceChargeAmount: data.serviceChargeAmount.toFixed(2),
      }));
      apiUrl = `/transaction/generate-z-report-txt/`;
    }
    if (version === "paymentFile") {
      dataReport = paymentData.map((data) => ({
        ...data,
        amount: data.amount.toFixed(2),
        currencyAmount: (data.amount * 1).toFixed(2),
      }));
      apiUrl = `/transaction/generate-z-report-txt/`;
    }

    setIsReporting(true);

    const formData = {
      data: dataReport,
      startDate: format(startDate, "dd MMMM yyyy"),
      endDate: format(endDate, "dd MMMM yyyy"),
      version: version,
    };

    axiosInstance
      .post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          // Decode the base64 encoded PDF content
          const pdfContent = atob(res.data.pdf_base64);

          handleDownloadFile(pdfContent);
        }
      })
      .catch((res) => {
        Notification.failed(res.response.data.msg);
      })
      .finally(() => setIsReporting(false));

    if (version === "paymentFile" || version === "salesFile") {
      apiUrl = `/transaction/generate-z-report/`;

      let dataToGenerate = dataReport;

      if (version === "paymentFile") {
        dataToGenerate = paymentFileAddFooter(dataToGenerate);
      } else {
        dataToGenerate = salesFileAddFooter(dataToGenerate);
      }

      const formData = {
        data: dataToGenerate,
        startDate: format(startDate, "dd MMMM yyyy"),
        endDate: format(endDate, "dd MMMM yyyy"),
      };
      axiosInstance
        .post(apiUrl, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            // Decode the base64 encoded PDF content
            const pdfContent = atob(res.data.pdf_base64);

            handleDownloadFile(pdfContent, true);
          }
        })
        .catch((res) => {
          Notification.failed(res.response.data.msg);
        })
        .finally(() => setIsDownloading(false));
    }
  };

  const handleDownloadFile = (pdfContent: string, confirmPDF?: boolean) => {
    // Convert the decoded content to a Uint8Array
    const pdfData = new Uint8Array(pdfContent.length);
    for (let i = 0; i < pdfContent.length; i++) {
      pdfData[i] = pdfContent.charCodeAt(i);
    }

    const pdfBlob = new Blob([pdfData], { type: "application/pdf" });

    const textBlob = new Blob([pdfData], { type: "text/plain" });
    const url = URL.createObjectURL(
      version === "detailed" || confirmPDF ? pdfBlob : textBlob
    );
    const link = document.createElement("a");

    link.href = url;

    link.download = fileNaming(confirmPDF);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fileNaming = (confirmPDF?: boolean) => {
    let fileName = "";

    if (
      !currentLot.gateawaymallLot &&
      ["detailed", "summary"].includes(version)
    ) {
      Notification.warning(
        "This lot does not required Gatewaymall Z report, naming of downloaded report will be slightly off"
      );
    }

    if (!currentLot.mahbLot && ["salesFile", "paymentFile"].includes(version)) {
      Notification.warning(
        "This lot does not required MAHB Z report, naming of downloaded report will be slightly off"
      );
    }

    if (version === "detailed") {
      fileName = `${
        currentLot.gateawaymallLot ? currentLot.gateawaymallLot : ""
      }_${formatDocumentDateFormat(startDate)}_ZRPT.pdf`;
    }
    if (version === "summary") {
      fileName = `${
        currentLot.gateawaymallLot ? currentLot.gateawaymallLot : ""
      }_${formatDocumentDateFormat(startDate)}_A.txt`;
    }
    if (version === "salesFile") {
      fileName = `Sales_${
        currentLot.mahbLot ? currentLot.mahbLot : ""
      }_${formatDocumentDateFormat(new Date())}${confirmPDF ? ".pdf" : ".txt"}`;
    }
    if (version === "paymentFile") {
      fileName = `Payment_${
        currentLot.mahbLot ? currentLot.mahbLot : ""
      }_${formatDocumentDateFormat(new Date())}${confirmPDF ? ".pdf" : ".txt"}`;
    }

    return fileName;
  };

  function cleanObject(obj: any) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  const handleXLSXDownload = () => {
    const dataToExport =
      version === "detailed"
        ? detailedData.map((data) => ({
            ...data,
            details:
              data.details && data.details.length > 0
                ? data.details
                    .filter(
                      (item) =>
                        item.category !==
                          transactionCategoryEnum.serviceCharge &&
                        item.category !== transactionCategoryEnum.tax &&
                        item.category !== transactionCategoryEnum.promotion &&
                        item.category !== transactionCategoryEnum.rounding &&
                        item.category !==
                          transactionCategoryEnum.adjustmentSales
                    )
                    .map(
                      (item) =>
                        `${item.quantity ? item.quantity + "x " : ""}${
                          item.itemName
                        } ${
                          item.itemType && item.itemType !== "Merch"
                            ? item.itemType
                            : ""
                        }`
                    )
                    .join(", ")
                : "",
          }))
        : version === "summary"
        ? summaryData
        : version === "salesFile"
        ? salesData
        : paymentData;

    const companyNameHeader = `Company Name: ${currentLot.lotDescription}`;
    const reportNameHeader = `Report Name: ${convertToReadableFormat(
      reportType
    )} ${convertToReadableFormat(version)}`;
    const outletHeader = `Outlet: ${currentLot.lotNumber}`;
    const monthHeader = `Month: ${currentMonthName}`;
    const startAndEndDate = `Report Date from: ${format(
      startDate,
      "dd MMMM yyyy"
    )}        to: ${format(endDate, "dd MMMM yyyy")}`;
    const dateGenerated = `Date Generated: ${format(
      new Date(),
      "dd MMMM yyyy hh:mm"
    )}`;

    let Heading = [
      [companyNameHeader],
      [reportNameHeader],
      [outletHeader],
      [monthHeader],
      [startAndEndDate],
      [dateGenerated],
    ];

    // const ws = XLSX.utils.json_to_sheet(dataToExport);

    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.sheet_add_json(ws, dataToExport, {
      origin: "A8",
    });

    const borderStyle = {
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };

    ws["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: 7 } },
      { s: { r: 3, c: 0 }, e: { r: 3, c: 7 } },
      { s: { r: 4, c: 0 }, e: { r: 4, c: 7 } },
    ];

    Object.keys(ws).forEach((cell) => {
      if (cell !== "!ref") {
        ws[cell].s = borderStyle;
      }
    });

    ws["A1"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A2"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A3"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A4"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A5"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A6"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${fileNaming().split(".")[0]}.xlsx`);
  };

  return (
    <Box
      display={"flex"}
      bgcolor={theme.palette.background.default}
      maxWidth={"90vw"}
      border={2}
      borderColor={theme.palette.primary.main}
    >
      <Stack direction={"row"} width={"100%"}>
        <Stack
          direction={"column"}
          width={"18%"}
          borderRight={2}
          borderColor={theme.palette.primary.main}
        >
          <Box
            display={"flex"}
            bgcolor={theme.palette.background.default}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
            height={"100px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="h2">Overview</Typography>
          </Box>

          <Stack height={"100%"} spacing={1}>
            {paymentOverview.map((overview) => (
              <Box display={"flex"} flexDirection={"column"} padding={1}>
                <Box>
                  <Typography fontWeight={600}>
                    {overview.left.name} | {overview.right.name}
                  </Typography>
                  <Box
                    width={"100%"}
                    height={"30px"}
                    border={"2px solid white"}
                    display={"flex"}
                    marginTop={1}
                  >
                    <Box
                      width={"50%"}
                      display={"flex"}
                      alignItems={"center"}
                      sx={{
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        color={"black"}
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        fontWeight={600}
                      >
                        {overview.left.value}
                      </Typography>
                    </Box>
                    <Box
                      width={"50%"}
                      display={"flex"}
                      alignItems={"center"}
                      sx={{
                        backgroundColor: "black",
                      }}
                    >
                      <Typography
                        color={"white"}
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        fontWeight={600}
                      >
                        {overview.right.value}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Stack>
        </Stack>
        <Stack direction={"column"} width={"82%"}>
          <Box
            display={"flex"}
            width={"100%"}
            height={"100px"}
            justifyContent={"space-around"}
            alignItems={"center"}
            bgcolor={theme.palette.background.default}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <FilterAlt sx={{ fontSize: "32px" }} />
              <Typography variant="h3">Filter</Typography>
            </Stack>
            <ZReportFilters
              anchorEl={anchorEl}
              reportType={reportType}
              version={version}
              // groupings={groupings}
              // groupingsList={groupingsList}
              // bookingStatusFilter={bookingStatusFilter}
              // setGroupingsList={setGroupingsList}
              handleChangeReportType={handleChangeReportType}
              handleChangeVersion={handleChangeVersion}
              handleCloseDropdown={handleCloseDropdown}
              // handleGroupingsChange={handleGroupingsChange}
              handleOpenDropdown={handleOpenDropdown}
              // handleChangeBookingStatusFilter={handleChangeBookingStatusFilter}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(e) => handleStartDateChange(e as Date)}
                  format="dd MMM yyyy"
                  slots={{
                    openPickerIcon: ArrowDropDownIcon,
                  }}
                  slotProps={{
                    layout: {
                      sx: {
                        ".MuiDateCalendar-root": {
                          color: theme.palette.primary.main,
                          borderRadius: 2,
                          borderWidth: 1,
                          borderColor: theme.palette.primary.main,
                          border: "1px solid",
                          backgroundColor: theme.palette.background.paper,
                        },
                        ".MuiButtonBase-root": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiTypography-root": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiPickersCalendarHeader-root": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiPickersMonth-monthButton": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiPickersCalendarHeader-label": {
                          color: theme.palette.primary.main,
                        },
                        ".Mui-selected": {
                          color: "black",
                        },
                        ".MuiPickersYear-yearButton": {
                          color: theme.palette.primary.main,
                        },
                      },
                    },
                    textField: {
                      variant: "filled",
                      inputProps: {
                        style: {
                          color: "white",
                          borderRadius: 1,
                          border: 2,
                        },
                      },
                      sx: {
                        width: "150px",
                        backgroundColor: "#232323",
                        border: "1px solid #148978",
                        borderRadius: 1,
                      },
                    },
                    openPickerButton: {
                      sx: {
                        color: "white",
                        marginTop: "1.8px",
                        zIndex: 1,
                      },
                    },
                    openPickerIcon: {
                      sx: {
                        width: "1em",
                        height: "1em",
                        zIndex: 1,
                      },
                    },
                  }}
                />
                <Typography variant="h3" marginX={1}>
                  -
                </Typography>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(e) => handleEndDateChange(e as Date)}
                  format="dd MMM yyyy"
                  slots={{
                    openPickerIcon: ArrowDropDownIcon,
                  }}
                  slotProps={{
                    layout: {
                      sx: {
                        ".MuiDateCalendar-root": {
                          color: theme.palette.primary.main,
                          borderRadius: 2,
                          borderWidth: 1,
                          borderColor: theme.palette.primary.main,
                          border: "1px solid",
                          backgroundColor: theme.palette.background.paper,
                        },
                        ".MuiButtonBase-root": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiTypography-root": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiPickersCalendarHeader-root": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiPickersMonth-monthButton": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiPickersCalendarHeader-label": {
                          color: theme.palette.primary.main,
                        },
                        ".Mui-selected": {
                          color: "black",
                        },
                        ".MuiPickersYear-yearButton": {
                          color: theme.palette.primary.main,
                        },
                      },
                    },
                    textField: {
                      variant: "filled",
                      inputProps: {
                        style: {
                          color: "white",
                          borderRadius: 1,
                          border: 2,
                        },
                      },
                      sx: {
                        width: "150px",
                        backgroundColor: "#232323",
                        border: "1px solid #148978",
                        borderRadius: 1,
                      },
                    },
                    openPickerButton: {
                      sx: {
                        color: "white",
                        marginTop: "1.8px",
                        zIndex: 1,
                      },
                    },
                    openPickerIcon: {
                      sx: {
                        width: "1em",
                        height: "1em",
                        zIndex: 1,
                      },
                    },
                  }}
                />
              </Box>
              <Button
                id="file-download-button"
                variant="contained"
                disabled={isLoading}
                onClick={handleDownloadReport}
                sx={{ borderRadius: 1 }}
              >
                {isDownloading ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  <FileDownload />
                )}
              </Button>
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={handleXLSXDownload}
                sx={{ borderRadius: 1 }}
              >
                {isDownloadingExcel ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  <ExcelIcon width={24} height={24} />
                )}
              </Button>
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={handleReportFile}
                sx={{ borderRadius: 1 }}
              >
                {isReporting ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  <>
                    Report
                    <Flag />
                  </>
                )}
              </Button>
            </LocalizationProvider>
          </Box>
          {isLoading ? (
            <Box
              display={"flex"}
              height={"500px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box height={"100%"} width={"100%"}>
              {version === "detailed" ? (
                <CustomTable
                  key={"detailed"}
                  isLoading={isLoading}
                  columns={detailedDataColumns}
                  data={detailedData}
                  handlePaginationChange={setPagination}
                  pagination={pagination}
                  enableTopToolbar={false}
                  oddEvenBackground={true}
                />
              ) : version === "summary" ? (
                <CustomTable
                  key={"summary"}
                  isLoading={isLoading}
                  columns={summaryDataColumns}
                  data={summaryData}
                  handlePaginationChange={setPagination}
                  pagination={pagination}
                  enableTopToolbar={false}
                  oddEvenBackground={true}
                />
              ) : version === "salesFile" ? (
                <CustomTable
                  key={"salesFile"}
                  isLoading={isLoading}
                  columns={salesDataColumns}
                  data={salesData}
                  handlePaginationChange={setPagination}
                  pagination={pagination}
                  enableTopToolbar={false}
                  oddEvenBackground={true}
                />
              ) : (
                <CustomTable
                  key={"paymentFile"}
                  isLoading={isLoading}
                  columns={paymentDataColumns}
                  data={paymentData}
                  handlePaginationChange={setPagination}
                  pagination={pagination}
                  enableTopToolbar={false}
                  oddEvenBackground={true}
                />
              )}
            </Box>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default ZReport;
