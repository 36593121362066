import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import theme from '../../assets/theme/theme';
import CustomTable from '../../components/global/table/CustomTable';
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table';
import axiosInstance from '../../constants/axiosConfig';
import { GuestTrackingResult, NewAPIBookingResult } from '../booking/interface';
import { store } from '../../redux/store';
import Notification from '../../utils/notificationConfig';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { format } from 'date-fns';
import { convertToEpoch, filterTableData } from '../../utils/functions';
import useUpdate from '../../hooks/useUpdate';

type CheckinDataItem = {
  id: number;
  bookingId: string;
  bookingNumber: string;
  customer: string;
  customerId: string;
  platform: string;
  status: string;
  checkInTime: string;
  checkOutTime: string;
  actualCheckinDatetime: string | null;
  actualCheckoutDatetime: string | null;
  roomCode: string[];
  roomTypes: string[];
  bookingMadeDatetime: string | null | undefined;
  overstayDuration: string;
  isOverstay: boolean;
  otaCode: string;
};

function GuestTrackingPage() {
  const today = new Date();
  const startDateFilter = new Date(new Date().getFullYear(), 0, 1);

  // getMonth returns based on index, so 2 === March
  if (today.getMonth() <= 2) {
    startDateFilter.setMonth(11); // December
    startDateFilter.setDate(1);
    startDateFilter.setFullYear(startDateFilter.getFullYear() - 1);
  }

  const dayAfter = new Date(today);
  dayAfter.setDate(today.getDate() + 1);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState<number>(0);
  const [checkInData, setCheckInData] = useState<CheckinDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortByValue, setSortByValue] = useState('Time DSC');
  const [bookStatusFilter, setBookStatusFilter] = useState('All');
  const [bookPlatformFilter, setBookPlatformFilter] = useState('All');
  const [bookStatusValue, setBookStatusValue] = useState<string[]>([]);
  const [bookPlatformValue, setBookPlatformValue] = useState<string[]>([]);
  const [searchBarValue, setSearchBarValue] = useState('');
  const [displayTableData, setDisplayTableData] = useState<CheckinDataItem[]>(
    []
  );
  const [checkInTableData, setCheckInTableData] = useState<CheckinDataItem[]>(
    []
  );
  const [checkOutTableData, setCheckOutTableData] = useState<CheckinDataItem[]>(
    []
  );

  const [startTimeValue, setStartTimeValue] = useState<Date>(startDateFilter);
  const [endTimeValue, setEndTimeValue] = useState<Date>(dayAfter);

  const token = store.getState().user.accessToken;

  const navigate = useNavigate();

  const { updateExpiredBookingStatus } = useUpdate();

  const handleNavigateOnClick = (customerId: any, bookingId: string) => {
    if (customerId) {
      navigate(`${customerId}`);
    } else {
      navigate(`/upcoming-booking/${bookingId}`);
    }
  };

  const sorting = [{ id: 'checkOutTime', desc: true }];

  const checkInColumns = useMemo<MRT_ColumnDef<CheckinDataItem>[]>(
    () => [
      {
        header: 'IC/Pass',
        size: 50,
        Cell: ({ row }) => {
          return <Checkbox checked={Boolean(row.original.customerId)} />;
        },
      },
      {
        accessorKey: 'customer',
        header: 'Customer',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() =>
                handleNavigateOnClick(
                  row.original.customerId,
                  row.original.bookingId
                )
              }
              sx={{
                background: 'black',
                border: 1,
                borderColor: theme.palette.primary.main,
              }}
            >
              {row.original.customer}
            </Button>
          );
        },
      },
      {
        header: 'Latest Booking Date',
        accessorKey: 'bookingMadeDatetime',
      },
      {
        header: 'Latest Check-In',
        accessorKey: 'checkInTime',
      },
      {
        header: 'Latest Check-Out',
        accessorKey: 'checkOutTime',
      },
      {
        accessorKey: 'bookingNumber',
        header: 'Latest Booking No.',
      },
      {
        header: 'Latest Room Types',
        accessorKey: 'roomTypes',
      },
      {
        accessorKey: 'platform',
        header: 'Latest Booking Source',
      },
      {
        accessorKey: 'status',
        header: 'Latest Status',
      },
      {
        accessorKey: 'otaCode',
        header: 'OTA REF',
        Cell: ({ row }) => {
          return (
            <Typography>
              {row.original.otaCode ? row.original.otaCode : '-'}
            </Typography>
          );
        },
      },
    ],
    []
  );

  const handleSortByValueChange = (event: any) => {
    setSortByValue(event);
  };

  const handleBookStatusFilterOnChange = (event: any) => {
    setBookStatusFilter(event);
  };

  const handleSearchBarOnChange = (event: any) => {
    setSearchBarValue(event.target.value);
  };

  const handleBookPlatformFilterOnChange = (event: any) => {
    setBookPlatformFilter(event);
  };

  const handleStartTimeValueChange = (e: Date) => {
    setStartTimeValue(e);
  };

  const handleEndTimeValueChange = (e: Date) => {
    setEndTimeValue(e);
  };

  const fetchListData = () => {
    setIsLoading(true);

    const startDate = startTimeValue;
    const endDate = endTimeValue;
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 0);

    const startTime = convertToEpoch(startDate);
    const endTime = convertToEpoch(endDate);

    const queryParams = new URLSearchParams({
      startDateTime: startTime.toString(),
      endDateTime: endTime.toString(),
      eachCustomerLatestBooking: 'true',
      forGuestTracking: 'true',
      page: (pagination.pageIndex + 1).toString(),
      page_size: pagination.pageSize.toString(),
      globalSearch: searchBarValue,
    });

    const queryString = queryParams.toString();

    axiosInstance
      .get(`/bookings/list/v2?${queryString}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setRowCount(res.data.count);
        const results: GuestTrackingResult[] = res.data.results;

        const data = results.map((item, index) => {
          const checkInData: CheckinDataItem = {
            id: index,
            bookingNumber: item.bookingNo,
            bookingMadeDatetime: format(
              new Date(item.bookingMadeDatetime),
              'dd/MM/yyyy HH:mm'
            ),
            checkInTime: item.bookingStatus[0]
              ? format(
                  new Date(item.bookingStatus[0].checkInDatetime),
                  'dd/MM/yyyy HH:mm'
                )
              : '',
            checkOutTime: item.bookingStatus[0]
              ? format(
                  new Date(item.bookingStatus[0].checkOutDatetime),
                  'dd/MM/yyyy HH:mm'
                )
              : '',
            actualCheckinDatetime: item.roomBookings[0].actualCheckinDateTime,
            actualCheckoutDatetime: item.roomBookings[0].actualCheckoutDateTime,
            customer:
              item.customerStayingName === ' '
                ? item.customerBooked
                : item.customerStayingName,
            platform: item.platform.platform,
            status: item.bookingStatus[0]
              ? item.bookingStatus[0].bookingStatus
              : '',
            roomCode: item.roomBookings.map((item) => item.roomCode),
            roomTypes: Array.from(
              new Set(item.roomBookings.map((item) => item.roomType))
            ),
            bookingId: item.bookingId,
            overstayDuration:
              item.overstayDuration && item.isOverstay
                ? item.overstayDuration.toString()
                : '',
            isOverstay: item.isOverstay ? item.isOverstay : false,
            customerId: item.customerStayingId,
            otaCode: item.otaCode ? item.otaCode : '-',
          };
          return checkInData;
        });
        setCheckInData(handleSortTimeDSC(data));
      })
      // setTablePaginationData(results.count);
      .catch((error) => {
        Notification.failed(error.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTableDataAssignation = () => {
    const checkInDataFilter = checkInData.filter(
      (item) =>
        item.status === 'Booked' ||
        item.status === 'Confirm Booking' ||
        item.status === 'No Show' ||
        item.status === 'Check In' ||
        item.status === 'Overstayed' ||
        item.status === 'Transfer From Airside' ||
        item.status === 'Transfer From Landside' ||
        item.status === 'Check Out' ||
        item.status === 'Cancelled'
    );
    // const checkOutDataFilter = checkInData.filter(
    //   (item) => item.status === "Check In" || item.status === "Overstayed"
    // );

    setCheckInTableData(checkInDataFilter);
    //setCheckOutTableData(checkOutDataFilter);
  };

  const handleResetDisplayTable = () => {
    // if (isCheckInOpened) {
    //   setDisplayTableData(checkInTableData);
    // } else {
    //   setDisplayTableData(checkOutTableData);
    // }
    setDisplayTableData(checkInTableData);
  };

  useEffect(() => {
    updateExpiredBookingStatus(token);
  }, []);

  useEffect(() => {
    fetchListData();
  }, [startTimeValue, endTimeValue, pagination.pageIndex]);

  useEffect(() => {
    if (checkInTableData && checkOutTableData) {
      handleResetDisplayTable();
    }
  }, [checkInTableData, checkOutTableData]);

  useEffect(() => {
    const uniqueStatus = new Set(checkInData.map((item) => item.status));
    const uniquePlatform = new Set(checkInData.map((item) => item.platform));
    const uniqueStatusArray = Array.from(uniqueStatus);
    const uniquePlatformArray = Array.from(uniquePlatform);
    setBookStatusValue(uniqueStatusArray);
    setBookPlatformValue(uniquePlatformArray);
    if (checkInData) {
      handleTableDataAssignation();
    }
  }, [checkInData]);

  const handleSortTimeASC = (data: CheckinDataItem[]): CheckinDataItem[] => {
    const sortedData = data.slice().sort((a, b) => {
      const dateA = new Date(a.checkOutTime).getTime();
      const dateB = new Date(b.checkOutTime).getTime();

      return dateA - dateB;
    });
    return sortedData;
  };

  const handleSortTimeDSC = (data: CheckinDataItem[]): CheckinDataItem[] => {
    const sortedData = data.slice().sort((a, b) => {
      const dateA = new Date(a.checkOutTime).getTime();
      const dateB = new Date(b.checkOutTime).getTime();

      return dateB - dateA;
    });
    return sortedData;
  };

  const handleSortBookingNumASC = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let sortedData = data.slice();
    const charToRemove = 'CKLIA-';
    sortedData.sort((a, b) => {
      const aStripString = a.bookingNumber.replace(charToRemove, '');
      const bStripString = b.bookingNumber.replace(charToRemove, '');
      const numberedA = Number(aStripString);
      const numberedB = Number(bStripString);
      return numberedA - numberedB;
    });
    return sortedData;
  };

  const handleSortBookingNumDSC = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let sortedData = [...data];
    const charToRemove = 'CKLIA-';
    sortedData.sort((a, b) => {
      const aStripString = a.bookingNumber.replace(charToRemove, '');
      const bStripString = b.bookingNumber.replace(charToRemove, '');
      const numberedA = Number(aStripString);
      const numberedB = Number(bStripString);
      return numberedB - numberedA;
    });
    return sortedData;
  };

  const handleSortBy = (data: CheckinDataItem[]): CheckinDataItem[] => {
    let newData: CheckinDataItem[] = [];
    if (sortByValue === 'All') {
      newData = [...data];
    } else if (sortByValue === 'Time ASC') {
      newData = handleSortTimeASC(data);
    } else if (sortByValue === 'Time DSC') {
      newData = handleSortTimeDSC(data);
    } else if (sortByValue === 'Booking Number ASC') {
      newData = handleSortBookingNumASC(data);
    } else if (sortByValue === 'Booking Number DSC') {
      newData = handleSortBookingNumDSC(data);
    } else if (sortByValue === 'Overstay') {
    }
    return newData;
  };

  const handleBookStatusFilter = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let newData: CheckinDataItem[] = [];
    if (bookStatusFilter === 'All') {
      newData = [...data];
    } else {
      bookStatusValue.map((item) => {
        if (bookStatusFilter === item) {
          newData = data.filter((item2) => {
            return item2.status === item;
          });
        }
      });
    }
    return newData;
  };

  const handleBookPlatformFilter = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let newData: CheckinDataItem[] = [];
    if (bookPlatformFilter === 'All') {
      newData = [...data];
    } else {
      bookPlatformValue.map((item) => {
        if (bookPlatformFilter === item) {
          newData = data.filter((item2) => {
            return item2.platform === item;
          });
        }
      });
    }
    return newData;
  };

  const handleAllFilter = () => {
    const rawUnsortedData = true
      ? [...checkInTableData]
      : [...checkOutTableData];
    const sortedData = handleSortBy(rawUnsortedData);
    const filteredBookStatusData = handleBookStatusFilter(sortedData);
    const filteredBookPlatformData = handleBookPlatformFilter(
      filteredBookStatusData
    );
    setDisplayTableData(filteredBookPlatformData);
    return filteredBookPlatformData;
  };

  useEffect(() => {
    handleAllFilter();
  }, [sortByValue, bookStatusFilter, bookPlatformFilter]);

  return (
    <Box
      width={'85%'}
      border={1}
      borderColor={theme.palette.primary.main}
      margin={'auto'}
    >
      <Stack
        borderBottom={2}
        borderColor={theme.palette.primary.main}
        direction={'row'}
      ></Stack>
      <Stack
        paddingY={1}
        paddingX={3}
        borderBottom={2}
        borderColor={theme.palette.primary.main}
        sx={{ background: '#232323' }}
      >
        <Typography variant={'h3'} color={theme.palette.primary.main}>
          Guest Tracking
        </Typography>
      </Stack>
      <Grid container borderBottom={2} borderColor={theme.palette.primary.main}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            paddingY={4}
            paddingX={10}
            borderRight={1}
            borderColor={theme.palette.primary.main}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <DatePicker
              value={startTimeValue}
              onChange={(e) => handleStartTimeValueChange(e as Date)}
              slotProps={{
                layout: {
                  sx: {
                    '.MuiDateCalendar-root': {
                      color: theme.palette.primary.main,
                      borderRadius: 2,
                      borderWidth: 1,
                      borderColor: theme.palette.primary.main,
                      border: '1px solid',
                      backgroundColor: theme.palette.background.paper,
                    },
                    '.MuiButtonBase-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiTypography-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersCalendarHeader-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersMonth-monthButton': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersCalendarHeader-label': {
                      color: theme.palette.primary.main,
                    },
                    '.Mui-selected': {
                      color: 'black',
                    },
                    '.MuiPickersYear-yearButton': {
                      color: theme.palette.primary.main,
                    },
                  },
                },
                textField: {
                  InputProps: {
                    style: {
                      background: '#232323',
                    },
                  },
                  sx: {
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      width: '20em',
                      padding: '0',
                      borderRadius: '1em',
                    },
                  },
                },
                openPickerIcon: {
                  sx: {
                    color: theme.palette.primary.main,
                  },
                },
              }}
            />
            <ArrowRightAltIcon fontSize={'large'} />
            <DatePicker
              value={endTimeValue}
              onChange={(e) => handleEndTimeValueChange(e as Date)}
              slotProps={{
                layout: {
                  sx: {
                    '.MuiDateCalendar-root': {
                      color: theme.palette.primary.main,
                      borderRadius: 2,
                      borderWidth: 1,
                      borderColor: theme.palette.primary.main,
                      border: '1px solid',
                      backgroundColor: theme.palette.background.paper,
                    },
                    '.MuiButtonBase-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiTypography-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersCalendarHeader-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersMonth-monthButton': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersCalendarHeader-label': {
                      color: theme.palette.primary.main,
                    },
                    '.Mui-selected': {
                      color: 'black',
                    },
                    '.MuiPickersYear-yearButton': {
                      color: theme.palette.primary.main,
                    },
                  },
                },
                textField: {
                  InputProps: {
                    style: {
                      background: '#232323',
                    },
                  },
                  sx: {
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      width: '20em',
                      padding: '0',
                      borderRadius: '1em',
                    },
                  },
                },
                openPickerIcon: {
                  sx: {
                    color: theme.palette.primary.main,
                  },
                },
              }}
            />
          </Grid>
        </LocalizationProvider>
        <Grid item md={4} sm={12} xs={12}>
          <ButtonBase
            onClick={fetchListData}
            sx={{
              width: '100%',
              height: '100%',
              background: '#232323',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant={'h3'}>Change Date</Typography>
          </ButtonBase>
        </Grid>
      </Grid>
      <Stack borderBottom={2} borderColor={theme.palette.primary.main}>
        <Grid
          container
          padding={2}
          spacing={2}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Grid item md={11} sm={11} xs={11}>
            <TextField
              type={'search'}
              size={'small'}
              onChange={(e) => {
                handleSearchBarOnChange(e);
              }}
              sx={{
                width: '100%',
                borderRadius: 0,
                border: 1,
                borderColor: theme.palette.primary.main,
              }}
            />
          </Grid>
          <Grid item md={1} sm={1} xs={1}>
            <Button
              variant={'contained'}
              onClick={fetchListData}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress /> : 'Search'}
            </Button>
          </Grid>
        </Grid>
      </Stack>
      <Stack>
        <Grid
          container
          borderBottom={2}
          borderColor={theme.palette.primary.main}
          minHeight={'5.9em'}
        >
          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            display={'flex'}
            flexDirection={'row'}
          >
            <Stack
              width={'30%'}
              borderRight={2}
              padding={3}
              justifyContent={'center'}
              alignItems={'center'}
              borderColor={theme.palette.primary.main}
            >
              Sorter
            </Stack>
            <Stack
              width={'70%'}
              borderRight={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack
                height={'50%'}
                borderBottom={1}
                borderColor={theme.palette.primary.main}
                justifyContent={'center'}
                paddingLeft={2}
              >
                <Typography>Sort By</Typography>
              </Stack>
              <Stack height={'50%'}>
                <Select
                  size={'small'}
                  value={sortByValue}
                  onChange={(e) => handleSortByValueChange(e.target.value)}
                >
                  <MenuItem value={'All'}>None</MenuItem>
                  <MenuItem value={'Time ASC'}>Check-Out ASC</MenuItem>
                  <MenuItem value={'Time DSC'}>Check-Out DSC</MenuItem>
                  <MenuItem value={'Booking Number ASC'}>
                    Booking No. ASC
                  </MenuItem>
                  <MenuItem value={'Booking Number DSC'}>
                    Booking No. DSC
                  </MenuItem>
                  <MenuItem value={'Overstay'}>Overstay</MenuItem>
                </Select>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            display={'flex'}
            flexDirection={'row'}
          >
            <Stack
              width={'30%'}
              borderRight={2}
              padding={3}
              justifyContent={'center'}
              alignItems={'center'}
              borderColor={theme.palette.primary.main}
            >
              <Typography>Filter</Typography>
            </Stack>
            <Stack
              width={'35%'}
              borderRight={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack
                height={'50%'}
                borderBottom={1}
                borderColor={theme.palette.primary.main}
                justifyContent={'center'}
                paddingLeft={2}
              >
                <Typography>Book Status</Typography>
              </Stack>
              <Stack height={'50%'}>
                <Select
                  size={'small'}
                  value={bookStatusFilter}
                  onChange={(e) =>
                    handleBookStatusFilterOnChange(e.target.value)
                  }
                >
                  <MenuItem value={'All'}>None</MenuItem>
                  {bookStatusValue.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Stack>
            <Stack
              width={'35%'}
              borderRight={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack
                height={'50%'}
                borderBottom={1}
                borderColor={theme.palette.primary.main}
                justifyContent={'center'}
                paddingLeft={2}
              >
                <Typography>Book Platform</Typography>
              </Stack>
              <Stack height={'50%'}>
                <Select
                  size={'small'}
                  value={bookPlatformFilter}
                  onChange={(e) =>
                    handleBookPlatformFilterOnChange(e.target.value)
                  }
                >
                  <MenuItem value={'All'}>None</MenuItem>
                  {bookPlatformValue.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <CustomTable
        columns={checkInColumns}
        data={displayTableData}
        isLoading={isLoading}
        handlePaginationChange={setPagination}
        pagination={pagination}
        rowCount={rowCount}
        oddEvenBackground={true}
        // globalFilter={searchBarValue}
        manualPagination={true}
      />
    </Box>
  );
}

export default GuestTrackingPage;
