import {
  Grid,
  Typography,
  Divider,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import theme from "../../assets/theme/theme";
import { useEffect, useState } from "react";
import { string } from "yup";
import axiosInstance from "../../constants/axiosConfig";
import { store } from "../../redux/store";
import { Formik } from "formik";
import Notification from "../../utils/notificationConfig";

interface CheckInSlipProps {
  onCheckInSlipVisibilityChange: (isVisible: boolean) => void;
  wifiName?: string;
  wifiPassword?: string;
  wifiNameId?: number;
  wifiPasswordId?: number;
}
export default function CheckInSlipDetail({
  onCheckInSlipVisibilityChange,
  wifiName,
  wifiPassword,
  wifiNameId,
  wifiPasswordId,
}: CheckInSlipProps) {
  const [showCheckInSlipDetail, setShowCheckInSlipDetail] = useState(false);

  const [wifiNames, setWifiNames] = useState<string>();
  const [wifiPasswords, setWifiPasswords] = useState<string>();

  const handleButtonClick = () => {
    setShowCheckInSlipDetail(false);

    // Pass the boolean value to the parent component
    onCheckInSlipVisibilityChange(false);
  };

  const submitCheckInNameDetail = () => {
    const payload = {
      settingsId: wifiNameId,
      newDescription: wifiNames,
    };
    axiosInstance
      .put(`/lot-settings/edit-settings-description/`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success("Settings updated successfully");
        console.log("Settings updated successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error updating settings:", error);
      });
  };
  const token = store.getState().user.accessToken;

  const submitCheckInPasswordDetail = () => {
    const payload = {
      settingsId: wifiPasswordId,
      newDescription: wifiPasswords,
    };
    axiosInstance
      .put(`/lot-settings/edit-settings-description/`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success("Settings updated successfully");

        console.log("Settings updated successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error updating settings:", error);
      });
  };
  useEffect(() => {
    setWifiNames(wifiName);
    setWifiPasswords(wifiPassword);
  }, []);

  const handleWifiNameChange = (newValue?: string) => {
    setWifiNames(newValue || ""); // Use an empty string if newValue is undefined
  };

  const handleWifiPasswordChange = (newValue?: string) => {
    setWifiPasswords(newValue || ""); // Use an empty string if newValue is undefined
  };

  const handleSubmitButton = () => {
    submitCheckInNameDetail();
    submitCheckInPasswordDetail();
  };
  return (
    <>
      <Grid container width={"100%"} p={5} spacing={2}>
        <Grid item xs={1}>
          <Button onClick={handleButtonClick}>Back</Button>
        </Grid>
        <Grid item xs={11} py={3}>
          <Typography variant="h3">Check-In Slip</Typography>
        </Grid>
        <Grid item xs={12} paddingBottom={4}>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              borderBottomWidth: 3.5,
              borderColor: "#232323",
            }}
          />
        </Grid>
        <Grid item xs={3} px={2}>
          <Typography variant="h4" color={theme.palette.primary.main}>
            Wifi Name
          </Typography>
        </Grid>
        <Grid item xs={7} px={2}>
          <TextField
            size="small"
            fullWidth
            value={wifiNames}
            onChange={(e) => handleWifiNameChange(e.target.value)}
            inputProps={{
              style: { textTransform: "none" },
            }}
          />
        </Grid>

        <Grid item xs={3} px={2}>
          <Typography variant="h4" color={theme.palette.primary.main}>
            Wifi Password
          </Typography>
        </Grid>
        <Grid item xs={7} px={2}>
          <TextField
            size="small"
            fullWidth
            value={wifiPasswords}
            onChange={(e) => handleWifiPasswordChange(e.target.value)}
            inputProps={{
              style: { textTransform: "none" },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          alignItems="flex-end"
          py={2}
          padding={1}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitButton}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
