import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import axiosInstance from "../../constants/axiosConfig";

// Utils
import Notification from "../../utils/notificationConfig";
import { filterTableData } from "../../utils/functions";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

// types and interfaces
import {
  RoomTypeInterface,
  RoomTypeInterfaceV2,
  ZoneInterface,
} from "../../models/rooms/roomsInterface";
import theme from "../../assets/theme/theme";
import { FilterAltOutlined, Settings } from "@mui/icons-material";
import EditRoomTypeModal from "../../components/roomlist/EditRoomTypeModal";

// Function to filter duplicates by id
// Function to filter duplicates by id and combine zones
function filterDuplicatesAndCombineZones(
  array: RoomTypeInterfaceV2[]
): RoomTypeInterfaceV2[] {
  const seen = new Set<string>(); // Set to store unique ids

  return array.reduce((acc: RoomTypeInterfaceV2[], obj) => {
    if (!seen.has(obj.typeName)) {
      seen.add(obj.typeName);
      const roomZoneArray = array
        .filter((item) => item.typeName === obj.typeName)
        .map((item) => item.roomzone);
      acc.push({ ...obj, roomZoneArray });
    }
    return acc;
  }, []);
}

const roomTypeInitialValue: RoomTypeInterfaceV2 = {
  colorTags: "",
  maxPax: 0,
  typeId: "",
  typeName: "",
  RoomTypeDetails: "",
  archived: false,
  roomzone: {
    archived: false,
    lotId: 0,
    RoomZoneDetails: "",
    zoneId: "",
    zoneName: "",
  },
};
export default function TypeList(props: {
  roomTypes: RoomTypeInterfaceV2[];
  setRoomTypes: React.Dispatch<React.SetStateAction<RoomTypeInterfaceV2[]>>;
}) {
  const token = useSelector((state: RootState) => state.user.accessToken);

  /* -------------------------------- useStates ------------------------------- */
  const [rows, setRows] = useState<RoomTypeInterfaceV2[]>([]);

  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [updateTrigger, setUpdateTrigger] = useState<boolean>(false);

  const [archiveFilterValue, setArchiveFilterValue] = useState<string>("true");
  const [zoneFilterSelected, setZoneFilterSelected] = useState("");
  const [searchBarValue, setSearchBarValue] = useState("");
  const [roomZones, setRoomZones] = useState<string[]>([]);
  const [selectedRoomType, setSelectedRoomType] =
    useState<RoomTypeInterfaceV2>(roomTypeInitialValue);
  const [IsEditroomtypemodal, setEditRoomTypeModal] = useState<boolean>(false);

  /* -------------------------- state change function ------------------------- */
  const handleZoneFilterChange = (event: string) => {
    setZoneFilterSelected(event);
  };

  const handleChangeSearchValue = (event: string) => {
    setSearchBarValue(event);
  };

  const handleEditRoomTypeModalOpen = (
    selectedRoomTypeParams: RoomTypeInterfaceV2
  ) => {
    setSelectedRoomType(selectedRoomTypeParams);
    setEditRoomTypeModal(true);
  };

  const handleEditRoomTypeModalClose = () => {
    setSelectedRoomType(roomTypeInitialValue);
    setEditRoomTypeModal(false);
  };

  useEffect(() => {
    document.title = "Capsule Transit | Type List";
    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   setRoomTypeData(rows);
  // }, [rows]);

  useEffect(() => {
    getPageData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveFilterValue, zoneFilterSelected]);

  /* ------------------------------ data fetching ----------------------------- */
  const getPageData = () => {
    axiosInstance
      .get("/rooms/v1/types", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          notArchived: archiveFilterValue,
          zoneName: zoneFilterSelected,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const filteredArray: RoomTypeInterfaceV2[] =
            filterDuplicatesAndCombineZones(res.data.data);

          setRows(filteredArray);

          props.setRoomTypes(filteredArray);
          return;
        }
        Notification.failed("something wrong, please try again");
      })
      .catch((e) => {
        Notification.failed("something wrong, please try again");
      })
      .finally(() => {
        setFetchLoading(false);
        setUpdateTrigger(false);
      });
  };

  useEffect(() => {
    const getRoomZones = () => {
      const queryParams = {
        noDuplicate: true,
      };
      axiosInstance
        .get("/rooms/zone/get-all-zone/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: queryParams,
        })
        .then((res) => {
          if (res.status === 200) {
            let result = res.data.data.map(
              ({ zoneName }: ZoneInterface) => zoneName
            );
            setRoomZones(result);
          }
        })
        .catch((e) => {
          console.log(e);
          Notification.failed("something wrong, please try again");
        });
    };

    getRoomZones();
  }, [token]);

  /* ------------------------ function to handle filter ----------------------- */

  useEffect(() => {
    const newData = [...rows];

    const searchedData = filterTableData(newData, searchBarValue);

    props.setRoomTypes(searchedData);
  }, [searchBarValue]);

  /* ------------------------------------ - ----------------------------------- */

  // {
  //   <Box mb={"10px"} display={"flex"} alignItems={"center"}>
  //     <Box mr={"20px"}>
  //       <ModalPopper
  //         text="Add new type"
  //         variant="contained"
  //         endIcons={<AddIcon />}
  //         popUpComponent={<AddType setUpdateTrigger={setUpdateTrigger} />}
  //       />
  //     </Box>
  //   </Box>;
  // }

  interface RoomCapsuleProps {
    roomTypes: RoomTypeInterfaceV2[];
  }

  const RoomCapsule: React.FC<RoomCapsuleProps> = ({ roomTypes }) => {
    return (
      <Grid container columnSpacing={3} rowSpacing={4}>
        {roomTypes.map((room) => (
          <Grid key={room.typeId} item xs={12} sm={6} md={6} lg={4} xl={4}>
            <Box
              sx={{
                backgroundColor: "black",
              }}
            >
              <Stack
                flexDirection={"row"}
                borderBottom={1}
                borderColor={room.colorTags}
                width={"100%"}
                p={2}
                sx={{ backgroundColor: theme.palette.background.default }}
                alignItems={"center"}
              >
                <Box
                  sx={{
                    width: 50,
                    height: 50,
                    border: 1,
                    borderColor: room.colorTags,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mr: 2,
                  }}
                >
                  <Typography fontWeight={"bold"} fontSize={20}>
                    {room.RoomTypeDetails}
                  </Typography>
                </Box>
                <Typography variant="h2" sx={{ lineHeight: 1.5 }}>
                  {room.typeName}
                </Typography>
              </Stack>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
                p={3}
                sx={{ backgroundColor: "#141414" }}
              >
                <Stack direction={"column"} justifyContent={"space-between"}>
                  <Typography color={"gray"} fontWeight={"bold"} fontSize={15}>
                    Available in
                  </Typography>
                  <Typography fontWeight={"bold"} fontSize={15}>
                    {room.roomZoneArray
                      ?.map((zone) => zone.zoneName)
                      .sort()
                      .join(", ")}
                  </Typography>
                </Stack>

                <Stack direction={"column"} justifyContent={"space-between"}>
                  <Typography color={"gray"} fontWeight={"bold"} fontSize={15}>
                    Max Pax
                  </Typography>
                  <Typography fontWeight={"bold"} fontSize={15}>
                    {room.maxPax}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                p={3}
                sx={{ backgroundColor: "#141414" }}
              >
                <Button
                  onClick={() => handleEditRoomTypeModalOpen(room)}
                  variant="outlined"
                  sx={{
                    height: "50px",
                    width: "100px",
                    color: "white",
                    bgcolor: "#141414",
                    borderColor: room.colorTags,
                  }}
                >
                  <Settings sx={{ fontSize: "30px", mr: 1 }} />
                  Edit
                </Button>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Box minHeight={"70vh"}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        height={"15%"}
        bgcolor={"#141414"}
      >
        {/* <Box mb={"10px"} display={"flex"} alignItems={"center"}>
          <Box mr={"20px"}>
            <ModalPopper
              text="Add new type"
              variant="contained"
              endIcons={<AddIcon />}
              popUpComponent={<AddType setUpdateTrigger={setUpdateTrigger} />}
            />
          </Box>
        </Box> */}
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={2}
          px={3}
          sx={{
            width: "100%",
            borderBottom: 2,
            borderColor: theme.palette.primary.main,
          }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <FilterAltOutlined fontSize={"large"} sx={{ color: "white" }} />
            <Typography variant={"h4"}>RoomType</Typography>
          </Stack>
          <TextField
            type="search"
            id="search"
            size="medium"
            label="Filter Search"
            value={searchBarValue}
            sx={{
              width: "60%",
            }}
            onChange={(e) => {
              handleChangeSearchValue(e.target.value);
            }}
          />
          <FormControl
            variant={"outlined"}
            sx={{
              width: "30%",
            }}
          >
            <InputLabel id="zone-filter">Zone</InputLabel>
            <Select
              labelId="zone-filter"
              value={zoneFilterSelected}
              onChange={(e) => handleZoneFilterChange(e.target.value)}
            >
              <MenuItem value={""}>All</MenuItem>
              {roomZones.map((item: string, index: number) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant={"outlined"}
            sx={{
              width: "15%",
            }}
          >
            <InputLabel id="archive-filter">Archived</InputLabel>
            <Select
              value={archiveFilterValue}
              onChange={(e) => setArchiveFilterValue(e.target.value)}
            >
              <MenuItem value={"false"}>True</MenuItem>
              <MenuItem value={"true"}>False</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Box>
      <Stack height={"75vh"} sx={{ overflowY: "auto" }} p={5}>
        <Box display={"flex"} height={"75vh"} flexDirection={"row"}>
          <RoomCapsule roomTypes={props.roomTypes} />
        </Box>
      </Stack>
      <EditRoomTypeModal
        title={"Edit Room Type"}
        selectedRoomType={selectedRoomType}
        setRoomTypes={props.setRoomTypes}
        archiveFilterValue={archiveFilterValue === "true"}
        handleEditRoomTypeModalClose={handleEditRoomTypeModalClose}
        handleEditRoomTypeModalOpen={IsEditroomtypemodal}
      />
    </Box>
  );
}
