import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import CustomTable from "../../components/global/table/CustomTable";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useEffect, useMemo, useState } from "react";
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
} from "material-react-table";
import { format, parse, parseISO } from "date-fns";
import { formatToPresentableNumber } from "../../utils/functions";
import { useParams } from "react-router-dom";
import RoomSummaryTable from "../../components/end-shift-report-tables/RoomSummaryTable";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import { IPayments } from "../../models/payment/PaymentInterface";
import { convertTo2Decimals } from "../../utils/paymentFunction";

export interface DurationGroup {
  item_name: string;
  sales: number;
  count: number;
}

export interface IDetailed {
  room: IRoomSum;
  locker: ILockerSum[];
  shower: IShowerSum[];
  merch: IMerchSum[];
  collection: ICollectionSum[];
  transaction: IDetailListing[];
}

export const IDetailedInitialValue: IDetailed = {
  room: { columns: [], duration_groups: {} },
  locker: [],
  shower: [],
  merch: [],
  collection: [],
  transaction: [],
};
export interface IRoomSum {
  columns: string[];
  duration_groups: { [duration: string]: DurationGroup[] };
}

export interface ILockerSum {
  duration: string;
  totalTransaction: number;
  totalPosSales: number;
}
export interface IShowerSum {
  duration: string;
  totalTransaction: number;
  totalPosSales: number;
}
export interface IMerchSum {
  merchName: string;
  totalTransaction: number;
  totalPosSales: number;
}
export interface ICollectionSum {
  paymentType: string;
  total: number;
}

export interface IDetailListing {
  booking_id: string;
  booking_date: string;
  transaction_date: string;
  staff: string;
  payment_type: string;
  pan: string;
  payment_reference: string;
  payment_remarks: string;
  transaction_status: string;
  booking_status: string;
  check_in_date: string;
  check_out_date: string;
  ota_ref_code: string;
  booking_platform: string;
  guest: string;
  duration: string;
  sales: number;
  tax: number;
  promotion: number;
  adjustment: number;
  total: number;
  booking_remarks: string;
}

export default function EndShiftReportDetail(props: {
  shifts?: string[];
  dates?: string[];
  perShift?: boolean;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailed?: React.Dispatch<React.SetStateAction<IDetailed>>;
  isNotDetailedScreen?: boolean;
}) {
  const { id, name } = useParams();

  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const [isLoadingRoom, setIsLoadingRoom] = useState<boolean>(false);
  const [isLoadingLocker, setIsLoadingLocker] = useState<boolean>(false);
  const [isLoadingShower, setIsLoadingShower] = useState<boolean>(false);
  const [isLoadingMerch, setIsLoadingMerch] = useState<boolean>(false);
  const [isLoadingCollection, setIsLoadingCollection] =
    useState<boolean>(false);
  const [isLoadingTransaction, setIsLoadingTransaction] =
    useState<boolean>(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [roomSummaryData, setRoomSummaryData] = useState<IRoomSum>({
    columns: [],
    duration_groups: {},
  });

  const [lockerSumData, setLockerSumData] = useState<ILockerSum[]>([]);
  const [showerSumData, setShowerSumData] = useState<IShowerSum[]>([]);
  const [merchSumData, setMerchSumData] = useState<IMerchSum[]>([]);
  const [collectionSumData, setCollectionSumData] = useState<ICollectionSum[]>(
    []
  );
  const [transactionData, setTransactionData] = useState<IDetailListing[]>([]);
  useEffect(() => {
    const fetchRoomSummaryData = () => {
      setIsLoadingRoom(true);
      const apiUrl = "/queryservice/v1/sales-summary/shift-room-sales-report";

      axiosInstance
        .get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: {
            shift_id:
              id && id.split("=")[0] === "shift"
                ? [id.split("=")[1]]
                : props.shifts
                ? props.shifts
                : "",
            date:
              id && id.split("=")[0] === "date"
                ? [id.split("=")[1]]
                : props.dates
                ? props.dates
                : "",
            type: props.perShift
              ? "shift"
              : id && id.split("=")[0] === "shift"
              ? "shift"
              : "date",
          },
        })
        .then((res) => {
          const data = {
            columns: res.data.columns,
            duration_groups: res.data.duration_groups,
          };
          setRoomSummaryData(data);

          if (props.setDetailed) {
            props.setDetailed((prevValue) => {
              return {
                ...prevValue,
                room: data,
              };
            });
          }
        })
        .catch((res) => {
          Notification.failed(res.data.message);
        })
        .finally(() => setIsLoadingRoom(false));
    };

    const fetchLockerSummaryData = () => {
      setIsLoadingLocker(true);
      const apiUrl = "/queryservice/v1/sales-summary/locker-summary";

      axiosInstance
        .get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: {
            shift_id:
              id && id.split("=")[0] === "shift"
                ? [id.split("=")[1]]
                : props.shifts
                ? props.shifts
                : "",
            date:
              id && id.split("=")[0] === "date"
                ? [id.split("=")[1]]
                : props.dates
                ? props.dates
                : "",
          },
        })
        .then((res) => {
          const data = res.data.map((data: any) => ({
            duration: data.duration,
            totalTransaction: parseFloat(data.total_transaction),
            totalPosSales: parseFloat(data.total_pos_sales),
          }));
          setLockerSumData(data);

          if (props.setDetailed) {
            props.setDetailed((prevValue) => {
              return {
                ...prevValue,
                locker: data,
              };
            });
          }
        })
        .catch((res) => {
          Notification.failed(res.data.message);
        })
        .finally(() => setIsLoadingLocker(false));
    };

    const fetchShowerSummaryData = () => {
      setIsLoadingShower(true);
      const apiUrl = "/queryservice/v1/sales-summary/shower-summary";

      axiosInstance
        .get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: {
            shift_id:
              id && id.split("=")[0] === "shift"
                ? [id.split("=")[1]]
                : props.shifts
                ? props.shifts
                : "",
            date:
              id && id.split("=")[0] === "date"
                ? [id.split("=")[1]]
                : props.dates
                ? props.dates
                : "",
          },
        })
        .then((res) => {
          const data = res.data.map((data: any) => ({
            duration: data.duration,
            totalTransaction: parseFloat(data.total_transaction),
            totalPosSales: parseFloat(data.total_pos_sales),
          }));
          setShowerSumData(data);

          if (props.setDetailed) {
            props.setDetailed((prevValue) => {
              return {
                ...prevValue,
                shower: data,
              };
            });
          }
        })
        .catch((res) => {
          Notification.failed(res.data.message);
        })
        .finally(() => setIsLoadingShower(false));
    };

    const fetchMerchSummaryData = () => {
      setIsLoadingMerch(true);
      const apiUrl = "/queryservice/v1/sales-summary/merch-summary";

      axiosInstance
        .get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: {
            shift_id:
              id && id.split("=")[0] === "shift"
                ? [id.split("=")[1]]
                : props.shifts
                ? props.shifts
                : "",
            date:
              id && id.split("=")[0] === "date"
                ? [id.split("=")[1]]
                : props.dates
                ? props.dates
                : "",
          },
        })
        .then((res) => {
          const data = res.data.map((data: any) => ({
            merchName: data.item_name,
            totalTransaction: parseFloat(data.total_transaction),
            totalPosSales: parseFloat(data.total_pos_sales),
          }));
          setMerchSumData(data);
          if (props.setDetailed) {
            props.setDetailed((prevValue) => {
              return {
                ...prevValue,
                merch: data,
              };
            });
          }
        })
        .catch((res) => {
          Notification.failed(res.data.message);
        })
        .finally(() => setIsLoadingMerch(false));
    };

    const fetchCollectionSummaryData = () => {
      setIsLoadingCollection(true);
      const apiUrl = "/transaction/collection-summary-transaction/";

      axiosInstance
        .get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: {
            shift_id:
              id && id.split("=")[0] === "shift"
                ? [id.split("=")[1]]
                : props.shifts
                ? props.shifts
                : "",
            date:
              id && id.split("=")[0] === "date"
                ? [id.split("=")[1]]
                : props.dates
                ? props.dates
                : "",
          },
        })
        .then((res) => {
          const data = res.data.map((data: any) => ({
            paymentType: data.paymentTypeName,
            total: parseFloat(data.total),
          }));
          setCollectionSumData(data);
          if (props.setDetailed) {
            props.setDetailed((prevValue) => {
              return {
                ...prevValue,
                collection: data,
              };
            });
          }
        })
        .catch((res) => {
          Notification.failed(res.response.statusText);
        })
        .finally(() => setIsLoadingCollection(false));
    };

    const fetchTransactionData = () => {
      setIsLoadingTransaction(true);
      axiosInstance
        .get(`/queryservice/v1/sales-summary/transaction-summary`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: {
            shift_id:
              id && id.split("=")[0] === "shift"
                ? [id.split("=")[1]]
                : props.shifts
                ? props.shifts
                : "",
            date:
              id && id.split("=")[0] === "date"
                ? [id.split("=")[1]]
                : props.dates
                ? props.dates
                : "",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0) {
              const dataTransaction = res.data.map((data: any) => ({
                booking_id: data.booking_id,
                booking_date: data.booking_date,
                transaction_date: data.transaction_date,
                staff: data.staff,
                payment_type: data.payment_type,
                pan: data.pan,
                payment_reference: data.payment_reference,
                payment_remarks: data.payment_remarks,
                transaction_status: data.transaction_status,
                booking_status: data.booking_status,
                check_in_date: data.check_in_date,
                check_out_date: data.check_out_date,
                ota_ref_Code: data.ota_ref_code,
                booking_platform: data.booking_platform,
                guest: data.guest,
                duration: data.duration,
                sales: parseFloat(data.sales),
                tax: parseFloat(data.tax),
                promotion: parseFloat(data.promotion),
                adjustment: parseFloat(data.adjustment),
                total: parseFloat(data.total),
                booking_remarks: data.booking_remarks,
              }));

              const sortedTransaction = dataTransaction.sort(
                (a: IPayments, b: IPayments) => {
                  if (
                    a.transactionStatus === "Pending Payment" &&
                    b.transactionStatus !== "Pending Payment"
                  ) {
                    return 1;
                  } else if (
                    a.transactionStatus !== "Pending Payment" &&
                    b.transactionStatus === "Pending Payment"
                  ) {
                    return -1;
                  }
                  return 0;
                }
              );
              setTransactionData(sortedTransaction);

              if (props.setDetailed) {
                props.setDetailed((prevValue) => {
                  return {
                    ...prevValue,
                    transaction: sortedTransaction,
                  };
                });
              }
            }
          }
        })
        .catch((res) => Notification.failed(res.response.data))
        .finally(() => setIsLoadingTransaction(false));
    };

    fetchRoomSummaryData();
    fetchLockerSummaryData();
    fetchShowerSummaryData();
    fetchMerchSummaryData();
    fetchCollectionSummaryData();
    fetchTransactionData();
  }, [props.dates, props.shifts]);

  useEffect(() => {
    if (props.setIsLoading) {
      if (
        !isLoadingLocker &&
        !isLoadingMerch &&
        !isLoadingCollection &&
        !isLoadingRoom &&
        !isLoadingTransaction &&
        !isLoadingShower
      ) {
        props.setIsLoading(false);
      } else {
        props.setIsLoading(true);
      }
    }
  }, [
    isLoadingCollection,
    isLoadingMerch,
    isLoadingRoom,
    isLoadingShower,
    isLoadingTransaction,
    isLoadingLocker,
  ]);

  const lockerTotalTransaction = useMemo(
    () =>
      lockerSumData.reduce(
        (acc, curr) => acc + Number(curr.totalTransaction),
        0
      ),
    [lockerSumData]
  );

  const lockerTotalPOSSales = useMemo(
    () =>
      lockerSumData.reduce((acc, curr) => acc + Number(curr.totalPosSales), 0),
    [lockerSumData]
  );

  const showerTotalTransaction = useMemo(
    () =>
      showerSumData.reduce(
        (acc, curr) => acc + Number(curr.totalTransaction),
        0
      ),
    [showerSumData]
  );

  const showerTotalPOSSales = useMemo(
    () =>
      showerSumData.reduce((acc, curr) => acc + Number(curr.totalPosSales), 0),
    [showerSumData]
  );

  const merchaTotalTransaction = useMemo(
    () =>
      merchSumData.reduce(
        (acc, curr) => acc + Number(curr.totalTransaction),
        0
      ),
    [merchSumData]
  );

  const merchTotalPOSSales = useMemo(
    () =>
      merchSumData.reduce((acc, curr) => acc + Number(curr.totalPosSales), 0),
    [merchSumData]
  );

  const collectionTotalTransaction = useMemo(
    () => collectionSumData.reduce((acc, curr) => acc + Number(curr.total), 0),
    [collectionSumData]
  );

  const LockerSummaryColumns = useMemo<MRT_ColumnDef<ILockerSum>[]>(
    () => [
      {
        header: "Duration",
        Cell: ({ row }) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"200px"}
            height={"30px"}
            bgcolor={"black"}
            color={theme.palette.primary.main}
            border={1}
            borderRadius={1}
            borderColor={theme.palette.primary.main}
          >
            {row.original.duration}
          </Box>
        ),
      },

      {
        header: "Total Transaction",
        Cell: ({ row }) => (
          <Typography>
            {row.original.totalTransaction
              ? formatToPresentableNumber(
                  row.original.totalTransaction.toString()
                )
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Transactions :
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {lockerTotalTransaction?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Total POS Sales",
        Cell: ({ row }) => (
          <Typography>{row.original.totalPosSales}</Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total POS Sales :
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {lockerTotalPOSSales}
            </Box>
          </Stack>
        ),
      },
    ],
    [lockerTotalPOSSales, lockerTotalTransaction]
  );
  const ShowerSummaryColumns = useMemo<MRT_ColumnDef<IShowerSum>[]>(
    () => [
      {
        header: "Duration",
        Cell: ({ row }) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"200px"}
            height={"30px"}
            bgcolor={"black"}
            color={theme.palette.primary.main}
            border={1}
            borderRadius={1}
            borderColor={theme.palette.primary.main}
          >
            {row.original.duration}
          </Box>
        ),
      },

      {
        header: "Total Transaction",
        Cell: ({ row }) => (
          <Typography>
            {row.original.totalTransaction
              ? formatToPresentableNumber(
                  row.original.totalTransaction.toString()
                )
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Transactions :
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {showerTotalTransaction?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Total POS Sales",
        Cell: ({ row }) => (
          <Typography>{row.original.totalPosSales}</Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total POS Sales :
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {showerTotalPOSSales}
            </Box>
          </Stack>
        ),
      },
    ],
    [showerTotalTransaction, showerTotalPOSSales]
  );
  const MerchSummaryColumns = useMemo<MRT_ColumnDef<IMerchSum>[]>(
    () => [
      {
        header: "Duration",
        Cell: ({ row }) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"200px"}
            height={"30px"}
            bgcolor={"black"}
            color={theme.palette.primary.main}
            border={1}
            borderRadius={1}
            borderColor={theme.palette.primary.main}
          >
            {row.original.merchName}
          </Box>
        ),
      },

      {
        header: "Total Transaction",
        Cell: ({ row }) => (
          <Typography>
            {row.original.totalTransaction
              ? formatToPresentableNumber(
                  row.original.totalTransaction.toString()
                )
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Transactions :
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {merchaTotalTransaction?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Total POS Sales",
        Cell: ({ row }) => (
          <Typography>{row.original.totalPosSales}</Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total POS Sales :
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {merchTotalPOSSales}
            </Box>
          </Stack>
        ),
      },
    ],
    [merchaTotalTransaction, merchTotalPOSSales]
  );

  const CollectionSummaryColumns = useMemo<MRT_ColumnDef<ICollectionSum>[]>(
    () => [
      {
        header: "Payment Type",
        Cell: ({ row }) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"200px"}
            height={"30px"}
            bgcolor={"black"}
            color={theme.palette.primary.main}
            border={1}
            borderRadius={1}
            borderColor={theme.palette.primary.main}
          >
            {row.original.paymentType}
          </Box>
        ),
      },

      {
        header: "Total",
        Cell: ({ row }) => (
          <Typography>
            {row.original.total
              ? formatToPresentableNumber(row.original.total.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Transactions :
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {collectionTotalTransaction?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
    ],
    [collectionTotalTransaction]
  );

  const transactionSalesColumns = useMemo<MRT_ColumnDef<IDetailListing>[]>(
    () => [
      {
        header: "Booking ID",
        accessorKey: "booking_id",
        Cell: ({ row }) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"200px"}
            height={"30px"}
            bgcolor={"black"}
            color={theme.palette.primary.main}
            border={1}
            borderRadius={1}
            borderColor={theme.palette.primary.main}
          >
            <Typography> {row.original.booking_id}</Typography>
          </Box>
        ),
      },
      {
        header: "Booking Date",
        Cell: ({ row }) => (
          <Typography>
            {row.original.booking_date
              ? format(new Date(row.original.booking_date), "dd/MM/yyyy")
              : "-"}{" "}
            {/* {row.original.booking_date} */}
          </Typography>
        ),
      },
      {
        header: "Transaction Datetime",
        Cell: ({ row }) => (
          <Typography>
            {row.original.transaction_date &&
            row.original.transaction_date !== "-"
              ? format(new Date(row.original.transaction_date), "dd/MM/yyyy")
              : "-"}
          </Typography>
        ),
      },
      {
        header: "Staff",
        Cell: ({ row }) => (
          <Typography>
            {row.original.staff ? row.original.staff : "-"}
          </Typography>
        ),
      },
      {
        header: "Payment Type",
        Cell: ({ row }) => (
          <Typography>
            {row.original.payment_type ? row.original.payment_type : "-"}
          </Typography>
        ),
      },
      {
        header: "PAN",
        Cell: ({ row }) => (
          <Typography>{row.original.pan ? row.original.pan : "-"}</Typography>
        ),
      },
      {
        header: "Payment Reference",
        Cell: ({ row }) => (
          <Typography>
            {row.original.payment_reference
              ? row.original.payment_reference
              : "-"}
          </Typography>
        ),
      },
      {
        header: "Payment Remarks",
        Cell: ({ row }) => (
          <Typography>
            {row.original.payment_remarks ? row.original.payment_remarks : "-"}
          </Typography>
        ),
      },
      {
        header: "Transaction Status",
        Cell: ({ row }) => (
          <Typography>
            {row.original.transaction_status
              ? row.original.transaction_status
              : "-"}
          </Typography>
        ),
      },
      {
        header: "Booking Status",
        Cell: ({ row }) => (
          <Typography>
            {row.original.booking_status ? row.original.booking_status : "-"}
          </Typography>
        ),
      },
      {
        header: "Check-in Date",
        Cell: ({ row }) => (
          <Typography>
            {row.original.check_out_date
              ? format(new Date(row.original.check_in_date), "dd/MM/yyyy HH:mm")
              : "-"}
          </Typography>
        ),
      },
      {
        header: "Check-out Date",
        Cell: ({ row }) => (
          <Typography>
            {row.original.check_out_date
              ? format(
                  new Date(row.original.check_out_date),
                  "dd/MM/yyyy HH:mm"
                )
              : "-"}
          </Typography>
        ),
      },
      {
        header: "OTA Ref Code",
        Cell: ({ row }) => (
          <Typography>
            {row.original.ota_ref_code ? row.original.ota_ref_code : "-"}
          </Typography>
        ),
      },
      {
        header: "Booking Platform",
        Cell: ({ row }) => (
          <Typography>
            {row.original.booking_platform
              ? row.original.booking_platform
              : "-"}
          </Typography>
        ),
      },
      {
        header: "Guest",
        Cell: ({ row }) => (
          <Typography>
            {row.original.guest ? row.original.guest : "-"}
          </Typography>
        ),
      },
      {
        header: "Duration",
        Cell: ({ row }) => (
          <Typography>
            {row.original.duration ? row.original.duration : "-"}
          </Typography>
        ),
      },
      {
        header: "Sales",
        Cell: ({ row }) => (
          <Typography>
            {row.original.sales ? row.original.sales : "-"}
          </Typography>
        ),
      },
      {
        header: "Tax",
        Cell: ({ row }) => (
          <Typography>
            {row.original.tax
              ? formatToPresentableNumber(row.original.tax.toString())
              : 0}
          </Typography>
        ),
      },
      {
        header: "Promotion",
        Cell: ({ row }) => (
          <Typography>
            {row.original.promotion
              ? formatToPresentableNumber(row.original.promotion.toString())
              : 0}
          </Typography>
        ),
      },
      {
        header: "Adjustment",
        Cell: ({ row }) => (
          <Typography>
            {row.original.adjustment
              ? formatToPresentableNumber(row.original.adjustment.toString())
              : 0}
          </Typography>
        ),
      },
      {
        header: "Total",
        Cell: ({ row }) => (
          <Typography>
            {row.original.total
              ? formatToPresentableNumber(row.original.total.toString())
              : 0}
          </Typography>
        ),
      },
      {
        header: "Booking Remarks",
        Cell: ({ row }) => (
          <Typography>
            {row.original.booking_remarks ? row.original.booking_remarks : "-"}
          </Typography>
        ),
      },
    ],
    []
  );

  return (
    <Stack spacing={4}>
      <Typography variant="h1"> {name}</Typography>
      {/* Room Summary Table */}
      <RoomSummaryTable
        fullWidth={Boolean(props.dates || props.perShift)}
        isLoading={isLoadingRoom}
        roomData={roomSummaryData}
      />
      {/* Locker Summary Table */}
      <Box
        bgcolor={theme.palette.background.default}
        maxWidth={props.dates || props.perShift ? "100vw" : "90vw"}
        border={2}
        borderColor={theme.palette.primary.main}
      >
        <Typography variant="h3" color="primary" padding={2}>
          Locker Summary
        </Typography>
        <CustomTable
          key={"lockerSumTable"}
          isLoading={isLoadingLocker}
          columns={LockerSummaryColumns}
          data={lockerSumData}
          handlePaginationChange={setPagination}
          pagination={
            props.isNotDetailedScreen
              ? { pageSize: lockerSumData.length, pageIndex: 0 }
              : pagination
          }
          oddEvenBackground={true}
        />
      </Box>
      {/* Shower Summary Table */}
      <Box
        bgcolor={theme.palette.background.default}
        maxWidth={props.dates || props.perShift ? "100vw" : "90vw"}
        border={2}
        borderColor={theme.palette.primary.main}
      >
        <Typography variant="h3" color="primary" padding={2}>
          Shower Summary
        </Typography>
        <CustomTable
          key={"showerSumTable"}
          isLoading={isLoadingShower}
          columns={ShowerSummaryColumns}
          data={showerSumData}
          handlePaginationChange={setPagination}
          pagination={
            props.isNotDetailedScreen
              ? { pageSize: showerSumData.length, pageIndex: 0 }
              : pagination
          }
          enableTopToolbar={false}
          oddEvenBackground={true}
        />
      </Box>
      {/* Merchandise Summary Table */}
      <Box
        bgcolor={theme.palette.background.default}
        maxWidth={props.dates || props.perShift ? "100vw" : "90vw"}
        border={2}
        borderColor={theme.palette.primary.main}
      >
        <Typography variant="h3" color="primary" padding={2}>
          Merchandise Summary
        </Typography>
        <CustomTable
          key={"merchSumTable"}
          isLoading={isLoadingMerch}
          columns={MerchSummaryColumns}
          data={merchSumData}
          handlePaginationChange={setPagination}
          pagination={
            props.isNotDetailedScreen
              ? { pageSize: merchSumData.length, pageIndex: 0 }
              : pagination
          }
          enableTopToolbar={false}
          oddEvenBackground={true}
        />
      </Box>
      {/* Collection Summary Table */}
      <Box
        bgcolor={theme.palette.background.default}
        maxWidth={props.dates || props.perShift ? "100vw" : "90vw"}
        border={2}
        borderColor={theme.palette.primary.main}
      >
        <Typography variant="h3" color="primary" padding={2}>
          Collection Summary
        </Typography>
        <CustomTable
          key={"collectionSumTable"}
          isLoading={isLoadingCollection}
          columns={CollectionSummaryColumns}
          data={collectionSumData}
          handlePaginationChange={setPagination}
          pagination={
            props.isNotDetailedScreen
              ? { pageSize: collectionSumData.length, pageIndex: 0 }
              : pagination
          }
          enableTopToolbar={false}
          oddEvenBackground={true}
        />
      </Box>
      <Box
        bgcolor={theme.palette.background.default}
        maxWidth={props.dates || props.perShift ? "100%" : "90vw"}
        border={2}
        borderColor={theme.palette.primary.main}
      >
        <Typography variant="h3" color="primary" padding={2}>
          Transaction Summary
        </Typography>
        <CustomTable
          key={"transactionSumTable"}
          isLoading={isLoadingTransaction}
          columns={transactionSalesColumns}
          data={transactionData}
          handlePaginationChange={setPagination}
          pagination={
            props.isNotDetailedScreen
              ? { pageSize: transactionData.length, pageIndex: 0 }
              : pagination
          }
          enableTopToolbar={false}
          oddEvenBackground={true}
        />
      </Box>
    </Stack>
  );
}
