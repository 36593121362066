import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AdditionalHoursInterface } from "../../models/rooms/roomsInterface";
import axiosInstance from "../../constants/axiosConfig";
import { store } from "../../redux/store";
import Notification from "../../utils/notificationConfig";

const AdditionalHours = () => {
  const accountId = store.getState().user.accountId;
  const token = store.getState().user.accessToken;
  const [loading, setLoading] = useState<boolean>(false);
  const [additionalHoursData, setAdditionalHoursData] = useState<AdditionalHoursInterface[]>(
    []
  );

  useEffect(() => {
    const fetchAdditionalHours = async () => {
      setLoading(true);
      await axiosInstance
        .get(`/lot-settings/get-settings/${accountId}?add1HourSettings=true`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const dataWithLoading = res.data.data.map(
              (item: AdditionalHoursInterface) => ({
                ...item,
                isLoading: false, // Set initial value to false
              })
            );
            setAdditionalHoursData(dataWithLoading);
          }
        });
      setLoading(false);
    };
    fetchAdditionalHours();
  }, [token, accountId]);

  const updateState = (data: AdditionalHoursInterface) => (e: any) => {
    const newArray = additionalHoursData.map((item, i) => {
      if (data.settingsId === item.settingsId) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setAdditionalHoursData(newArray);
  };

  // Function to update isLoading property for a specific item
  const updateLoadingState = (index: number, isLoading: boolean) => {
    setAdditionalHoursData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        isLoading,
      };
      return newData;
    });
  };

  const changeAdditionalHours = async (
    data: AdditionalHoursInterface,
    index: number
  ) => {
    updateLoadingState(index, true);
    const payload = {
      settingsId: data.settingsId,
      settingsName: data.settingsName,
      newDescription: data.settingsDescription,
    };
    await axiosInstance
      .put(`/lot-settings/edit-settings-description/`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success(response.data.message);
      })
      .catch((error) => {
        Notification.failed(`Error updating additional hours: ${error}`);
      })
      .finally(() => {
        updateLoadingState(index, false);
      });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2">Additional Hours</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              pt: 5,
              mb: 5,
              borderBottomWidth: 3.5,
              borderColor: "#232323",
            }}
          />
        </Grid>

        {additionalHoursData.map((item, i) => (
          <React.Fragment key={i}>
            <Grid item xs={6} p={2} key={item.settingsId}>
              <Typography variant="h4">{item.settingsName}</Typography>
            </Grid>
            <Grid item xs={4} p={2}>
              <TextField
                label="Prices (MYR)"
                size="medium"
                name="settingsDescription"
                fullWidth
                value={item.settingsDescription}
                onChange={updateState(item)}
                type="number"
              ></TextField>
            </Grid>
            <Grid item xs={2} p={3} borderRadius={"5px"}>
              <Button
                variant="outlined"
                onClick={() => changeAdditionalHours(item, i)}
              >
                {!item.isLoading ? (
                  "Save"
                ) : (
                  <CircularProgress size="24px" sx={{ color: "white" }} />
                )}
              </Button>
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={12}>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              pt: 1,
              mb: 1,
              borderBottomWidth: 3.5,
              borderColor: "#232323",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionalHours;
