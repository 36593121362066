import { Box, Stack, Typography, useTheme } from "@mui/material";
import {
  bookingStatusEnum,
  transactionStatusEnum,
} from "../../../constants/enums";

const Status = ({ status }: { status: string | undefined }) => {
  const theme = useTheme();

  if (
    status === transactionStatusEnum.paid ||
    status === transactionStatusEnum.paidAirside ||
    status === transactionStatusEnum.paidLandside
  ) {
    return (
      <Stack direction={"row"} spacing={1} marginTop={"10px"}>
        <Box
          border={2}
          borderRadius={"50%"}
          borderColor={theme.palette.primary.main}
          height={"20px"}
          width={"20px"}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        />
        <Typography variant="h4" fontWeight={"600"}>
          Paid
        </Typography>
      </Stack>
    );
  }
  if (
    status === transactionStatusEnum.pending ||
    status === transactionStatusEnum.pendingAirside ||
    status === transactionStatusEnum.pendingLandside
  ) {
    return (
      <Stack direction={"row"} spacing={1} marginTop={"10px"}>
        <Box
          border={2}
          borderRadius={"50%"}
          borderColor={theme.palette.primary.main}
          height={"20px"}
          width={"20px"}
          sx={{
            backgroundColor: "transparent",
          }}
        />
        <Typography variant="h4" fontWeight={"600"}>
          Pending
        </Typography>
      </Stack>
    );
  }
  if (
    status === transactionStatusEnum.refund ||
    status === bookingStatusEnum.cancelled
  ) {
    return (
      <Stack direction={"row"} spacing={1} marginTop={"10px"}>
        <Box
          border={2}
          borderRadius={"50%"}
          borderColor={theme.palette.error.main}
          height={"20px"}
          width={"20px"}
          sx={{
            backgroundColor: theme.palette.error.main,
          }}
        />
        <Typography variant="h4" fontWeight={"600"}>
          {status}
        </Typography>
      </Stack>
    );
  }
  if (status === transactionStatusEnum.void) {
    return (
      <Stack direction={"row"} spacing={1} marginTop={"10px"}>
        <Box
          border={"2px dotted"}
          borderRadius={"50%"}
          borderColor={theme.palette.primary.main}
          height={"20px"}
          width={"20px"}
          sx={{
            backgroundColor: "transparent",
          }}
        />
        <Typography variant="h4" fontWeight={"600"}>
          Void
        </Typography>
      </Stack>
    );
  }
  if (status === bookingStatusEnum.overstay) {
    return (
      <Stack
        direction={"row"}
        spacing={1}
        marginTop={"10px"}
        alignItems={"center"}
      >
        <Box
          border={2}
          borderRadius={"50%"}
          borderColor={theme.palette.error.main}
          height={"20px"}
          width={"20px"}
          sx={{
            backgroundColor: theme.palette.error.main,
          }}
        />
        <Typography variant="h3" fontWeight={"600"}>
          {status}
        </Typography>
      </Stack>
    );
  }
  if (
    status === bookingStatusEnum.booked ||
    status === bookingStatusEnum.checkIn ||
    status === bookingStatusEnum.checkOut ||
    status === bookingStatusEnum.confirmBooking ||
    // status === bookingStatusEnum.transferFromAirSide ||
    // status === bookingStatusEnum.transferFromLandSide
    status?.includes(bookingStatusEnum.transferFrom)
  ) {
    return (
      <Stack
        direction={"row"}
        spacing={1}
        marginTop={"10px"}
        alignItems={"center"}
      >
        <Box
          border={2}
          borderRadius={"50%"}
          borderColor={theme.palette.primary.main}
          height={"20px"}
          width={"20px"}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        />
        <Typography variant="h3" fontWeight={"600"}>
          {status}
        </Typography>
      </Stack>
    );
  }
  if (
    status === bookingStatusEnum.noShow ||
    // status === bookingStatusEnum.transferToAirSide ||
    // status === bookingStatusEnum.transferToLandSide
    status?.includes(bookingStatusEnum.transferTo)
  ) {
    return (
      <Stack direction={"row"} spacing={1} marginTop={"10px"}>
        <Box
          border={2}
          borderRadius={"50%"}
          borderColor={theme.palette.error.main}
          height={"20px"}
          width={"20px"}
          sx={{
            backgroundColor: theme.palette.error.main,
          }}
        />
        <Typography variant="h3" fontWeight={"600"}>
          {status}
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack direction={"row"} spacing={1} marginTop={"10px"}>
      <Box
        border={2}
        borderRadius={"50%"}
        borderColor={"white"}
        height={"20px"}
        width={"20px"}
        sx={{
          backgroundColor: "black",
        }}
      />
      <Typography variant="h4" fontWeight={"600"}>
        Unknown
      </Typography>
    </Stack>
  );
};

export default Status;
