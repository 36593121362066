import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Modal,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import ManagementModalHeader from "../management/ManagementModalHeader";
import { useEffect, useState } from "react";
import axiosInstance from "../../constants/axiosConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import ICashier, {
  IPaymentTerminal,
} from "../../models/payment/PaymentInterface";
import Notification from "../../utils/notificationConfig";
import PaymentTerminal from "../../pages/payment/PaymentTerminal";

interface CashierSelection {
  name: string;
  value: string;
}

const CashierSelectionModal = (props: {
  onSubmit: () => Promise<void>;
  open: boolean;
  handleClose: VoidFunction;
  handleUponSelectCashier: (cashierId: number) => void;
}) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const userRole = useSelector((state: RootState) => state.user.role);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [paymentTerminals, setPaymentTerminals] = useState<IPaymentTerminal[]>(
    []
  );

  const [selectedCashier, setSelectedCashier] = useState<string>("");
  const [cashierData, setCashierData] = useState<ICashier[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!token || token === "") return;
    const fetchPaymentTerminals = () => {
      const apiUrl = "/payment-terminal";

      axiosInstance
        .get(apiUrl, config)
        .then((response) => {
          setPaymentTerminals(response.data.data);
        })
        .catch(() => Notification.failed("Something wrong with API"));
    };
    fetchPaymentTerminals();
  }, []);

  useEffect(() => {
    if (!token || token === "") return;
    const getCashier = () => {
      axiosInstance
        .get(`/cashierterminal/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setCashierData(
            res.data.data.sort(
              (
                a: { cashierTerminalId: string },
                b: { cashierTerminalId: string }
              ) => (a.cashierTerminalId > b.cashierTerminalId ? 1 : -1)
            )
          );
        })
        .catch((res) => {
          Notification.failed("retrieve failed");
        });
    };
    getCashier();
  }, [token]);

  const handleSubmit = async () => {
    if (selectedCashier === "") {
      Notification.failed("Select a cashier before submitting");
      return;
    }
    try {
      setIsLoading(true);
      await props.onSubmit();

      let defaultTerminal = "000000";

      if (selectedCashier !== "Admin") {
        try {
          const response = await axiosInstance.get(
            `/payment-terminal/default-payment-terminal?cashierNumber=${selectedCashier}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const resData = response.data;

          if (resData.data.length === 0) {
            throw new Error(
              "this cashier not assigned into any payment terminal"
            );
          }
          defaultTerminal = resData.data[0].serialNumber;
        } catch (err) {
          Notification.warning(`${err}`);
          defaultTerminal = "000000";
        }
      }

      localStorage.setItem("defaultCashier", selectedCashier);
      localStorage.setItem("defaultTerminal", defaultTerminal);

      setIsLoading(false);
      props.handleClose();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <Box
        width={"700px"}
        maxHeight={"600px"}
        border={`3px solid ${theme.palette.primary.main}`}
        alignItems={"center"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
          borderRadius: 1,
        }}
      >
        <ManagementModalHeader
          title={"Select Cashier"}
          handleModalClose={props.handleClose}
        />
        <Box
          display={"flex"}
          height={"150px"}
          bgcolor={"black"}
          justifyContent={"start"}
          alignItems={"center"}
          paddingX={2}
        >
          <Typography variant="h4" width={"30%"}>
            Please Select Cashier
          </Typography>
          <Select
            variant="standard"
            value={selectedCashier}
            onChange={(event) => {
              setSelectedCashier(event.target.value);
            }}
            sx={{ width: "70%" }}
          >
            {cashierData.map((cashier) => (
              <MenuItem
                key={cashier.cashierTerminalId}
                value={cashier.cashierTerminal}
                onClick={() => {
                  props.handleUponSelectCashier(
                    parseInt(cashier.cashierTerminalId)
                  );
                }}
              >
                {cashier.cashierTerminal}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          display={"flex"}
          height={"50px"}
          bgcolor={theme.palette.background.paper}
          justifyContent={"end"}
          padding={1}
          borderTop={2}
          borderColor={theme.palette.primary.main}
        >
          <Button
            variant={"outlined"}
            onClick={handleSubmit}
            disabled={!selectedCashier || isLoading}
          >
            {isLoading ? <CircularProgress /> : "Confirm"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CashierSelectionModal;
