import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { IStepper } from "../../models/bookings/Booking";
import { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import OTAOptimise from "../../components/ota/OTAOptimise";
import OTAReservationInfo from "../../components/ota/OTAReservationInfo";
import OTAComplete from "../../components/ota/OTAComplete";
import { IOTACompletedRooms } from "../../models/ota/OTAInterfaces";

const stepperValue: IStepper[] = [
  { step: 1, text: "Optimise" },
  { step: 2, text: "Reservation Info" },
  { step: 3, text: "Complete" },
];

const initialReservedRooms: IOTACompletedRooms = {
  totalRooms: 18,
  startDate: "2022-01-01T10:00:00Z",
  endDate: "2023-01-01T10:00:00Z",
  types: [],
};

const OTAReservationPage = () => {
  const theme = useTheme();

  const [stepper, setStepper] = useState<number>(1);

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const [reservedRooms, setReservedRooms] =
    useState<IOTACompletedRooms>(initialReservedRooms);

  const handleNextStep = (
    roomTypeResponse?: IOTACompletedRooms,
    date?: Date
  ) => {
    setStepper((prev) => (prev = prev + 1));
    if (date) {
      setSelectedDate(date);
    }
    if (roomTypeResponse) {
      setReservedRooms({
        ...roomTypeResponse,
      });
    }
  };

  const handleBackStep = () => {
    setStepper(stepper - 1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        position: "relative",
        overflow: "hidden",
        width: "100%",
        margin: "auto",
        // paddingX: "100px",
      }}
    >
      <Typography variant="h2" marginBottom={3}>
        OTA Reservation
      </Typography>
      <Stack
        direction={"row"}
        width={"100%"}
        justifyContent={"space-between"}
        spacing={2}
        marginBottom={3}
      >
        {stepperValue.map((item, index) => (
          <Box
            key={index}
            display={"flex"}
            width={"350px"}
            height={"50px"}
            alignItems={"center"}
            border={1}
            borderColor={theme.palette.primary.main}
            sx={{
              backgroundColor:
                stepper === item.step ? theme.palette.primary.main : "black",
            }}
          >
            <Box
              display={"flex"}
              width={"100px"}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              borderRight={1}
              borderColor={theme.palette.primary.main}
              sx={{ backgroundColor: "black" }}
            >
              <Typography variant="h3">{item.step}</Typography>
            </Box>
            <Box width={"70%"}>
              <Typography
                variant="h3"
                color={stepper === item.step ? "black" : "white"}
                textAlign={"center"}
              >
                {item.text}
              </Typography>
            </Box>
          </Box>
        ))}
      </Stack>
      {stepper === 1 ? (
        <OTAOptimise handleNextStep={handleNextStep} />
      ) : stepper === 2 ? (
        <OTAReservationInfo
          selectedDate={selectedDate}
          handleNextStep={handleNextStep}
          handleBackStep={handleBackStep}
        />
      ) : (
        stepper === 3 && (
          <OTAComplete
            handleBackStep={handleBackStep}
            reservedRooms={reservedRooms}
          />
        )
      )}
    </Box>
  );
};

export default OTAReservationPage;
