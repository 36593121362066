import { Typography, Divider, Box } from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import React from "react";
import theme from "../../assets/theme/theme";
import InventoryIcon from '@mui/icons-material/Inventory';
type Props<T> = {
	items?: T[];
	OverviewColumnItem?: React.FC<{ item: T }>;
	activeOverview?: boolean;
	activeNum?: number;
	inactiveNum?: number;
	activeStock?: number;
	inactiveStock?: number;
};

const OverviewColumn = <T,>({
	items,
	OverviewColumnItem,
	activeOverview,
	activeNum,
	inactiveNum,
	activeStock,
	inactiveStock,
}: Props<T>) => {
	return (
		<Box
			width={"18%"}
			height={"750px"}
			minHeight={"44em"}
			border={`3px solid ${theme.palette.primary.main}`}>
			<Box //overview header
				height={"6vh"}
				minHeight={"3em"}
				paddingY={1}
				paddingX={2}
				sx={{
					backgroundColor: theme.palette.background.default,
				}}
				borderBottom={`3px solid ${theme.palette.primary.main}`}>
				<Typography variant={"h2"} color={theme.palette.primary.main}>
					Overview
				</Typography>
			</Box>

			<Box padding={2} height={"74vh"} minHeight={"41em"}>
				{activeOverview && (
					<Box>
						<Typography fontWeight={600}>Active | Inactive</Typography>
						<Box
							width={"100%"}
							height={"30px"}
							border={"2px solid white"}
							display={"flex"}
							marginTop={1}>
							<Box
								width={"33%"}
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}
								sx={{
									backgroundColor: "black",
								}}>
								<WorkIcon />
							</Box>
							<Box
								width={"33%"}
								display={"flex"}
								alignItems={"center"}
								sx={{
									backgroundColor: "white",
								}}>
								<Typography
									color={"black"}
									width={"100%"}
									display={"flex"}
									justifyContent={"center"}
									fontWeight={600}>
									{activeOverview && activeNum && activeNum}
								</Typography>
							</Box>
							<Box
								width={"33%"}
								display={"flex"}
								alignItems={"center"}
								sx={{
									backgroundColor: "black",
								}}>
								<Typography
									color={"white"}
									width={"100%"}
									display={"flex"}
									justifyContent={"center"}
									fontWeight={600}>
									{activeOverview && inactiveNum && inactiveNum}
								</Typography>
							</Box>
						</Box>
						<Typography fontWeight={600}>Stock</Typography>
						<Box
							width={"100%"}
							height={"30px"}
							border={"2px solid white"}
							display={"flex"}
							marginTop={1}>
							<Box
								width={"33%"}
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}
								sx={{
									backgroundColor: "black",
								}}>
								<InventoryIcon/>
							</Box>
							<Box
								width={"33%"}
								display={"flex"}
								alignItems={"center"}
								sx={{
									backgroundColor: "white",
								}}>
								<Typography
									color={"black"}
									width={"100%"}
									display={"flex"}
									justifyContent={"center"}
									fontWeight={600}>
									{activeOverview && activeStock &&activeStock}
								</Typography>
							</Box>
							<Box
								width={"33%"}
								display={"flex"}
								alignItems={"center"}
								sx={{
									backgroundColor: "black",
								}}>
								<Typography
									color={"white"}
									width={"100%"}
									display={"flex"}
									justifyContent={"center"}
									fontWeight={600}>
									{activeOverview && inactiveStock && inactiveStock}
								</Typography>
							</Box>
						</Box>
					</Box>
				)}
				<Divider
					orientation={"horizontal"}
					flexItem
					sx={{
						backgroundColor: theme.palette.primary.main,
						borderWidth: "2px",
						marginY: 2,
					}}
				/>

				<Box
					sx={{
						height: "55vh",
						minHeight: "30em",
						overflowY: "auto",
					}}>
					{items &&
						OverviewColumnItem &&
						items.map((item, index) => (
							<OverviewColumnItem key={index} item={item} />
						))}
				</Box>

				<Divider
					orientation={"horizontal"}
					flexItem
					sx={{
						backgroundColor: theme.palette.primary.main,
						borderWidth: "2px",
						marginY: 2,
					}}
				/>
			</Box>
		</Box>
	);
};

export default OverviewColumn;
