import axios from "axios";
import BASE_API from "./api";
import { store } from "../redux/store";
import { removeUserData } from "../redux/authentication/userSlice";

const axiosInstance = axios.create({
    baseURL: BASE_API,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.request.status === 401) {
            localStorage.removeItem('refresh');
            store.dispatch(removeUserData());
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;