import { Grid, Icon, Stack, Typography } from "@mui/material";
import React from "react";
import { IBookingDraft } from "../../models/bookings/Booking";
import { format } from "date-fns";
import PersonIcon from "@mui/icons-material/Person";

type Props = {
  roomBookings: IBookingDraft;
  customerStayingName?: string;
  roomZone?:string;
};

const BookingInfoRooms = ({ roomBookings, customerStayingName,roomZone}: Props) => {
  const dateFormat = {
    year: "numeric" as const,
    month: "short" as const,
    day: "numeric" as const,
  };
  const timeFormat = {
    hour: "2-digit" as const,
    minute: "2-digit" as const,
    hour12: false,
  };

  const changeDateFormat = (fullDate: string) => {
    const unformattedDate = new Date(fullDate);

    return format(unformattedDate, "dd/MM/yyyy HH:mm");
  };

  return (
    <Grid
      container
      sx={{
        width: "100%",
        border: 8,
        borderColor: roomBookings.color_code,
        borderRadius: 4,
      }}
    >
      <Grid
        item
        md={3}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRight: 8,
          borderColor: roomBookings.color_code,
          padding: "1em",
        }}
      >
        <Typography variant={"h2"}>{roomBookings.room_type_details}</Typography>
      </Grid>
      <Grid
        item
        md={9}
        sx={{
          display: "flex",
          padding: "1.25em",
        }}
      >
        <Stack direction={"column"} width={"100%"}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ width: "100%", margin: "5px" }}
          >
            <Typography variant={"h4"} fontWeight={"bold"}>
              {roomBookings.room_code},{roomBookings.room_zone}
            </Typography>

            <PersonIcon fontSize="large" />

            <Typography variant={"h4"} fontWeight={"bold"}>
              Max Pax. {roomBookings.max_pax}
            </Typography>
          </Stack>
          {roomBookings.person_in_charge_id && roomBookings.person_in_charge_id.length > 0 && (
            <Stack
              direction={"row"}
              justifyContent={"center"}
              sx={{ width: "100%", margin: "5px" }}
            >
              <Typography variant={"h4"}>
                {roomBookings.person_in_charge_id && roomBookings.person_in_charge_id.length > 0
                  ? roomBookings.person_in_charge_name
                  : customerStayingName}
              </Typography>
            </Stack>
          )}
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ width: "100%", margin: "5px" }}
          >
            <Typography variant={"h4"}>Check In</Typography>
            <Typography variant={"h4"}>Check Out</Typography>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ width: "100%", margin: "5px" }}
          >
            <Typography variant={"h4"}>
              {changeDateFormat(roomBookings.check_in)}
            </Typography>
            <Typography variant={"h4"} sx={{ textAlign: "end" }}>
              {changeDateFormat(roomBookings.check_out)}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default BookingInfoRooms;
