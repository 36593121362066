import { FileDownload, FilterAlt, Print } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  IPaymentOverview,
  IPayments,
} from "../../models/payment/PaymentInterface";
import axiosInstance from "../../constants/axiosConfig";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import CustomTable from "../../components/global/table/CustomTable";
import {
  filterTableData,
  formatToPresentableNumber,
  stringDateToEpoch,
} from "../../utils/functions";
import {
  ArrowDropDownIcon,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { format, parse, parseISO } from "date-fns";
import Notification from "../../utils/notificationConfig";
import LoadingModal from "../../components/payment/LoadingModal";
import { transactionCategoryEnum } from "../../constants/enums";

const POSHistoryPage = () => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const today = new Date();

  const startOfDay = new Date(today);
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date(today);
  endOfDay.setHours(23, 59, 59, 999);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleCloseDropdown = () => setAnchorEl(null);
  const handleOpenDropdown = ({
    currentTarget,
  }: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(currentTarget);
  };

  const [posHistory, setPOSHistory] = useState<IPayments[]>([]);
  const [rows, setRows] = useState<IPayments[]>([]);
  const [startDate, setStartDate] = useState<Date>(startOfDay);
  const [endDate, setEndDate] = useState<Date>(endOfDay);
  const [searchValueFilter, setSearchValueFilter] = useState<string>("");

  const [paymentOverview, setPaymentOverview] = useState<IPaymentOverview[]>(
    []
  );

  const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(false);
  const handleCloseLoadingModal = () => {
    setLoadingModalOpen(false);
  };

  const fetchPOSHistory = () => {
    setIsLoading(true);

    axiosInstance
      .get(`/transaction`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDateTime: startDate
            ? stringDateToEpoch(startDate.toString())
            : "",
          endDateTime: endDate ? stringDateToEpoch(endDate.toString()) : "",
          isPOSHistory: true,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const filteredData = res.data.data.filter((data: IPayments) => {
            const transactionDate = data.transactionDatetime
              ? new Date(data.transactionDatetime)
              : null;
            return (
              (!startDate ||
                !transactionDate ||
                transactionDate >= startDate) &&
              (!endDate || !transactionDate || transactionDate <= endDate)
            );
          });
          const convertedData = filteredData
            // .filter(
            //   (data: IPayments) =>
            //     data.items.some(
            //       (item) =>
            //         item.category.includes(
            //           transactionCategoryEnum.lockerSales
            //         ) ||
            //         item.category.includes(
            //           transactionCategoryEnum.showerSales
            //         ) ||
            //         item.category.includes(transactionCategoryEnum.merchSales)
            //     ) && data.transactionDatetime
            // )
            .map((data: any) => ({
              ...data,
              transactionDatetime: data.transactionDatetime
                ? parseISO(data.transactionDatetime)
                : undefined,
              paymentTypeName: data.paymentType.paymentType,
              items: data.items.length > 0 ? data.items : [],
              debitAmount: Number(data.debitAmount),
              sum: Number(data.sum),
              taxAmount: data.taxAmount ? Number(data.taxAmount) : 0,
              serviceCharge: data.serviceCharge
                ? Number(data.serviceCharge)
                : 0,
              promotionAmount: data.promotionAmount
                ? Number(data.promotionAmount)
                : 0,
            }))
            .sort((a: IPayments, b: IPayments) => {
              if (a.transactionDatetime && b.transactionDatetime) {
                return (
                  b.transactionDatetime.getTime() -
                  a.transactionDatetime.getTime()
                );
              }
              return 0;
            });
          setPOSHistory(convertedData);
          setRows(convertedData);
        }
      })
      .catch((e) => {
        Notification.failed("something wrong, please try again");
      })
      .finally(() => setIsLoading(false));
  };

  const fetchOverviewTransactions = () => {
    axiosInstance
      .get(`/transaction/report-overview`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDateTime: startDate
            ? stringDateToEpoch(startDate.toString())
            : "",
          endDateTime: endDate ? stringDateToEpoch(endDate.toString()) : "",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setPaymentOverview(res.data.data);
        }
      });
  };

  useEffect(() => {
    fetchPOSHistory();
    fetchOverviewTransactions();
  }, [startDate, endDate]);

  const posSalesColumns = useMemo<MRT_ColumnDef<IPayments>[]>(
    () => [
      {
        header: "Invoice No.",
        Cell: ({ row }) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"200px"}
            height={"30px"}
            bgcolor={"black"}
            color={theme.palette.primary.main}
            border={1}
            borderRadius={1}
            borderColor={theme.palette.primary.main}
          >
            {row.original.invoiceNo}
          </Box>
        ),
      },
      {
        header: "Items",
        size: 200,
        Cell: ({ row }) => (
          <Stack direction={"column"}>
            {row.original.items && row.original.items.length > 0
              ? row.original.items
                  .filter(
                    (item) =>
                      item.itemType !== "Service Charge" &&
                      item.itemType !== "Tax" &&
                      item.itemType !== "Promotion" &&
                      item.itemType !== "Rounding"
                  )
                  .map((item) => (
                    <Typography>{`${item.quantity}x ${item.itemName}`}</Typography>
                  ))
              : ""}
          </Stack>
        ),
      },
      {
        header: "Action",
        Cell: ({ row }) => (
          <>
            <IconButton
              color="primary"
              onClick={() =>
                row.original.transactionId
                  ? generateReceipt(row.original.transactionId)
                  : {}
              }
              disabled={row.original.transactionStatus === "Pending Payment"}
              sx={{
                "&.Mui-disabled": {
                  color: theme.palette.primary.light,
                },
              }}
            >
              <Print />
            </IconButton>
          </>
        ),
      },
      {
        header: "Date",
        Cell: ({ row }) => (
          <Typography>
            {row.original.transactionDatetime
              ? format(row.original.transactionDatetime, "dd/MM/yyyy HH:mm")
              : ""}
          </Typography>
        ),
      },
      {
        header: "Method",
        Cell: ({ row }) => (
          <Typography>{row.original.paymentTypeName}</Typography>
        ),
      },
      {
        header: "Status",
        Cell: ({ row }) => (
          <Typography>{row.original.transactionStatus}</Typography>
        ),
      },

      {
        header: "Total",
        Cell: ({ row }) => (
          <Typography>
            {row.original.debitAmount
              ? formatToPresentableNumber(row.original.debitAmount.toString())
              : ""}
          </Typography>
        ),
      },

      {
        header: "Sales",
        Cell: ({ row }) => (
          <Typography>
            {row.original.sum
              ? formatToPresentableNumber(row.original.sum.toString())
              : ""}
          </Typography>
        ),
      },
      {
        header: "Tax",
        Cell: ({ row }) => (
          <Typography>
            {row.original.taxAmount
              ? formatToPresentableNumber(row.original.taxAmount.toString())
              : ""}
          </Typography>
        ),
      },
      {
        header: "Service Charge",
        Cell: ({ row }) => (
          <Typography>
            {row.original.serviceChargeAmount
              ? formatToPresentableNumber(row.original.serviceChargeAmount)
              : ""}
          </Typography>
        ),
      },
      {
        header: "Promotions",
        Cell: ({ row }) => (
          <Typography>
            {row.original.promotionAmount
              ? formatToPresentableNumber(row.original.promotionAmount)
              : ""}
          </Typography>
        ),
      },
    ],
    []
  );

  const handleStartDateChange = (startDate: Date) => {
    setStartDate(startDate);
  };

  const handleEndDateChange = (endDate: Date) => {
    endDate.setHours(23, 59, 59, 999);
    setEndDate(endDate);
  };

  useEffect(() => {
    const newRow = filterTableData(rows, searchValueFilter);
    setPOSHistory(newRow);
  }, [searchValueFilter, rows]);

  const generateReceipt = (transactionId: string) => {
    setLoadingModalOpen(true);
    const apiUrl = "/transaction/generate-receipt/";

    const formData = {
      transactionId: transactionId,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axiosInstance
      .post(apiUrl, formData, config)
      .then((res) => {
        const pdfContent = atob(res.data.pdf_base64);
        handleDownloadFile(pdfContent);
      })
      .catch((res) => {
        Notification.failed(res.response.data.message);
      })
      .finally(() => {
        setLoadingModalOpen(false);
      });
  };

  const handleDownloadFile = (pdfContent: string) => {
    // Convert the decoded content to a Uint8Array
    const pdfData = new Uint8Array(pdfContent.length);
    for (let i = 0; i < pdfContent.length; i++) {
      pdfData[i] = pdfContent.charCodeAt(i);
    }

    const pdfBlob = new Blob([pdfData], { type: "application/pdf" });

    // Create a download link
    const url = URL.createObjectURL(pdfBlob);

    // Create an anchor element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = "Receipt.pdf";

    // Click the link to start the download
    link.click();

    // Clean up by revoking the object URL
    URL.revokeObjectURL(url);
  };

  return (
    <Box
      display={"flex"}
      bgcolor={theme.palette.background.default}
      maxWidth={"90vw"}
      border={2}
      borderColor={theme.palette.primary.main}
    >
      <Stack direction={"row"} width={"100%"}>
        <Stack direction={"column"} width={"100%"}>
          <Box
            display={"flex"}
            width={"100%"}
            height={"100px"}
            justifyContent={"space-between"}
            paddingX={2}
            alignItems={"center"}
            bgcolor={theme.palette.background.default}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <FilterAlt sx={{ fontSize: "32px" }} />
              <Typography variant="h3" marginRight={3}>
                Filter
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <TextField
                    label="Search"
                    placeholder="Invoice No., Item"
                    value={searchValueFilter}
                    onChange={(event) =>
                      setSearchValueFilter(event.target.value)
                    }
                    variant="filled"
                    inputProps={{
                      style: { textTransform: "none" },
                    }}
                    sx={{ width: "100%" }}
                  />
                  <Typography variant="h3" marginX={1}>
                    -
                  </Typography>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(e) => handleStartDateChange(e as Date)}
                    format="dd MMM yyyy"
                    slots={{
                      openPickerIcon: ArrowDropDownIcon,
                    }}
                    slotProps={{
                      layout: {
                        sx: {
                          ".MuiDateCalendar-root": {
                            color: theme.palette.primary.main,
                            borderRadius: 2,
                            borderWidth: 1,
                            borderColor: theme.palette.primary.main,
                            border: "1px solid",
                            backgroundColor: theme.palette.background.paper,
                          },
                          ".MuiButtonBase-root": {
                            color: theme.palette.primary.main,
                          },
                          ".MuiTypography-root": {
                            color: theme.palette.primary.main,
                          },
                          ".MuiPickersCalendarHeader-root": {
                            color: theme.palette.primary.main,
                          },
                          ".MuiPickersMonth-monthButton": {
                            color: theme.palette.primary.main,
                          },
                          ".MuiPickersCalendarHeader-label": {
                            color: theme.palette.primary.main,
                          },
                          ".Mui-selected": {
                            color: "black",
                          },
                          ".MuiPickersYear-yearButton": {
                            color: theme.palette.primary.main,
                          },
                        },
                      },
                      textField: {
                        variant: "filled",
                        inputProps: {
                          style: {
                            color: "white",
                            borderRadius: 1,
                            border: 2,
                          },
                        },
                        sx: {
                          width: "250px",
                          backgroundColor: "#232323",
                          border: "1px solid #148978",
                          borderRadius: 1,
                        },
                      },
                      openPickerButton: {
                        sx: {
                          color: "white",
                          marginTop: "1.8px",
                          zIndex: 1,
                        },
                      },
                      openPickerIcon: {
                        sx: {
                          width: "1em",
                          height: "1em",
                          zIndex: 1,
                        },
                      },
                    }}
                  />
                  <Typography variant="h3" marginX={1}>
                    -
                  </Typography>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(e) => handleEndDateChange(e as Date)}
                    format="dd MMM yyyy"
                    slots={{
                      openPickerIcon: ArrowDropDownIcon,
                    }}
                    slotProps={{
                      layout: {
                        sx: {
                          ".MuiDateCalendar-root": {
                            color: theme.palette.primary.main,
                            borderRadius: 2,
                            borderWidth: 1,
                            borderColor: theme.palette.primary.main,
                            border: "1px solid",
                            backgroundColor: theme.palette.background.paper,
                          },
                          ".MuiButtonBase-root": {
                            color: theme.palette.primary.main,
                          },
                          ".MuiTypography-root": {
                            color: theme.palette.primary.main,
                          },
                          ".MuiPickersCalendarHeader-root": {
                            color: theme.palette.primary.main,
                          },
                          ".MuiPickersMonth-monthButton": {
                            color: theme.palette.primary.main,
                          },
                          ".MuiPickersCalendarHeader-label": {
                            color: theme.palette.primary.main,
                          },
                          ".Mui-selected": {
                            color: "black",
                          },
                          ".MuiPickersYear-yearButton": {
                            color: theme.palette.primary.main,
                          },
                        },
                      },
                      textField: {
                        variant: "filled",
                        inputProps: {
                          style: {
                            color: "white",
                            borderRadius: 1,
                            border: 2,
                          },
                        },
                        sx: {
                          width: "250px",
                          backgroundColor: "#232323",
                          border: "1px solid #148978",
                          borderRadius: 1,
                        },
                      },
                      openPickerButton: {
                        sx: {
                          color: "white",
                          marginTop: "1.8px",
                          zIndex: 1,
                        },
                      },
                      openPickerIcon: {
                        sx: {
                          width: "1em",
                          height: "1em",
                          zIndex: 1,
                        },
                      },
                    }}
                  />
                </Box>
              </LocalizationProvider>
            </Stack>
            {/* <Button
              id="file-download-button"
              variant="contained"
              // onClick={handleFileDownloadClick}
              sx={{ borderRadius: 1 }}
            >
              <FileDownload />
            </Button> */}
          </Box>
          <Box height={"100%"} width={"100%"}>
            <CustomTable
              isLoading={isLoading}
              columns={posSalesColumns}
              data={posHistory}
              handlePaginationChange={setPagination}
              pagination={pagination}
              enableTopToolbar={false}
              oddEvenBackground={true}
            />
          </Box>
        </Stack>
      </Stack>
      <LoadingModal
        handleCloseLoadingModal={handleCloseLoadingModal}
        isLoadingModalOpen={loadingModalOpen}
      />
    </Box>
  );
};

export default POSHistoryPage;
