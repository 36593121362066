import { Button, Grid } from '@mui/material'
import React from 'react'
import theme from '../../assets/theme/theme'

type Props<T> = {
  type: string;
  isSubmitting: boolean;
  values: T;
  initialValues: T;
}

const ManagementModalFormButton = <T,> ({
  type,
  isSubmitting,
  values,
  initialValues,
}: Props<T>) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      display={"flex"}
      justifyContent={"end"}
      marginTop={2}
    >
      {
        {
          "add": (
            <Button
              type={"submit"}
              sx={{
                height: "3em",
                borderRadius: "0",
                backgroundColor: "black",
                border: `3px solid ${theme.palette.primary.main}`,
                color: theme.palette.primary.main,
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  color: "black",
                }
              }}
            >
              Save
            </Button>
          ),
          "edit": (
            <Button
              disabled={
                isSubmitting
                || (JSON.stringify(values) === JSON.stringify(initialValues))
                // !values and selectedMerchObj key order needs to be the same for this comparison to work 
              }
              type={"submit"}
              sx={{
                height: "3em",
                borderRadius: "0",
                backgroundColor: "black",
                border: `3px solid ${theme.palette.primary.main}`,
                color: theme.palette.primary.main,
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  color: "black",
                },
                "&.MuiButton-root.Mui-disabled": {
                  backgroundColor: "black",
                  border: `3px solid ${theme.palette.primary.main}`,
                  color: theme.palette.primary.main,
                  opacity: 0.5,
                }
              }}
            >
              Save
            </Button>
          )
        }[type]
      }
    </Grid>
  )
}

export default ManagementModalFormButton