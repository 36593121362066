import {
  ArrowDropDown,
  ArrowDropUp,
  FilterAltOutlined,
  Sort,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../constants/axiosConfig";
import { store } from "../../redux/store";
import Notification from "../../utils/notificationConfig";

const statuses = [
  "occupied, dirty",
  "occupied, cleaned",
  "vacant, cleaned",
  "vacant, dirty",
  "maintenance",
];

interface IHouseKeepingProps {
  filterExpanded: boolean;
  setFilterExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  roomStatus: string[];
  setRoomStatus: React.Dispatch<React.SetStateAction<string[]>>;
  zone: string[];
  setZone: React.Dispatch<React.SetStateAction<string[]>>;
  sortBy: string;
  setSortBy: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit: () => void;
  showSortByFilter: boolean;
  filterTitle: string;
}

const HouseKeepingFilterBar = (props: IHouseKeepingProps) => {
  const [isRoomZoneChecked, setIsRoomZoneChecked] = useState<{
    [key: string]: boolean;
  }>({});
  const [roomZones, setRoomZones] = useState<
    { roomZoneId: string; roomZone: string }[]
  >([]);

  const handleRoomZoneFiltersOnChange = useCallback(
    (
      roomZoneToAdd: string | string[],
      isChecked: boolean,
      isAll: boolean
    ): void => {
      if (isAll && Array.isArray(roomZoneToAdd)) {
        isChecked && props.setZone(roomZoneToAdd);
        !isChecked && props.setZone([]);
        return;
      }
      if (!isChecked && !isAll && typeof roomZoneToAdd === "string") {
        const dummyArr = props.zone.slice(0);
        const index = props.zone.indexOf(roomZoneToAdd);
        index > -1 && dummyArr.splice(index, 1);
        props.setZone(dummyArr);
        return;
      }
      if (
        typeof roomZoneToAdd === "string" &&
        props.zone.includes(roomZoneToAdd)
      )
        return;
      if (typeof roomZoneToAdd === "string" && isChecked)
        props.setZone([...props.zone, roomZoneToAdd]);
    },
    [props.zone]
  );

  const fetchRoomZoneMenuItems = useCallback((): void => {
    const token = store.getState().user.accessToken;
    axiosInstance
      .get(`rooms/zone/get-all-zone`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        const formattedRoomZonesDatas = data.data.map(
          ({ zoneId, zoneName }: { zoneId: string; zoneName: string }) => ({
            roomZoneId: zoneId,
            roomZone: zoneName,
          })
        );
        setRoomZones(formattedRoomZonesDatas);
        const roomZones = formattedRoomZonesDatas.map(
          ({ roomZone }: { roomZone: string }) => roomZone
        );
        props.setZone(roomZones);
      })
      .catch((e: Error) => {
        Notification.failed(e.message);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchRoomZoneMenuItems();
  }, []);

  useEffect(() => {
    const roomZonesChecked: { [key: string]: boolean } = {};
    for (const { roomZone } of roomZones) {
      roomZonesChecked[roomZone] = props.zone.includes(roomZone) ? true : false;
    }
    roomZonesChecked["all"] = Object.keys(roomZonesChecked).every(
      (roomZone) => roomZonesChecked[roomZone]
    )
      ? true
      : false;
    setIsRoomZoneChecked(roomZonesChecked);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomZones]);

  const handleStatusChange = (
    event: SelectChangeEvent<typeof props.roomStatus>
  ) => {
    const {
      target: { value },
    } = event;

    // If "Select All" is selected and all statuses are already selected, deselect all.
    if (
      value.includes("Select All") &&
      props.roomStatus.length === statuses.length
    ) {
      props.setRoomStatus([]);
    }
    // If "Select All" is selected and not all statuses are selected, select all.
    else if (value.includes("Select All")) {
      props.setRoomStatus(statuses.filter((status) => status !== "Select All"));
    }
    // If "Select All" is not selected, update the statuses accordingly.
    else {
      props.setRoomStatus(typeof value === "string" ? value.split(",") : value);
    }
  };

  useEffect(() => {
    console.log(props.roomStatus);
  }, [props.roomStatus]);

  return (
    <Accordion
      expanded={props.filterExpanded}
      onChange={() => props.setFilterExpanded(!props.filterExpanded)}
      sx={{ width: "100%" }}
    >
      <AccordionSummary>
        <Box width={"100%"}>
          <Stack
            direction={{ lg: "row", sm: "column" }}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <FilterAltOutlined sx={{ color: "white", fontSize: "28px" }} />{" "}
              <Typography variant="h3">{props.filterTitle}</Typography>
            </Box>
            {props.filterExpanded ? (
              <ArrowDropUp sx={{ color: "white" }} />
            ) : (
              <ArrowDropDown sx={{ color: "white" }} />
            )}
          </Stack>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          direction={{ lg: "row", sm: "column" }}
          spacing={{ lg: 2, sm: 3, xs: 2 }}
          alignItems={"center"}
        >
          <TextField
            label="Search"
            placeholder="Name, Code, Prefix"
            value={props.searchValue}
            onChange={(event) => props.setSearchValue(event.target.value)}
            variant="filled"
            inputProps={{
              style: { textTransform: "none" },
            }}
            fullWidth
          />
          <FormControl variant="filled" fullWidth>
            <InputLabel id="room-status-label">Room Status</InputLabel>
            <Select
              labelId="room-status-label"
              multiple
              value={props.roomStatus}
              onChange={handleStatusChange}
              renderValue={(selected) =>
                selected.includes("Select All") ? "All" : selected.join(", ")
              }
            >
              <MenuItem value="Select All">
                <Checkbox
                  checked={props.roomStatus.length === statuses.length}
                />
                <ListItemText primary="Select All" />
              </MenuItem>
              {statuses.map((status, index) => (
                <MenuItem key={index} value={status}>
                  <Checkbox checked={props.roomStatus.indexOf(status) > -1} />
                  <ListItemText primary={status} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="zone-label">Zone</InputLabel>
            <Select
              labelId="zone-label"
              value={props.zone}
              multiple
              renderValue={() => {
                let displayValue: string[] = ["All Zones"];
                if (isRoomZoneChecked.all) return displayValue.join(" | ");
                displayValue = Object.keys(isRoomZoneChecked).filter(
                  (roomType) =>
                    roomType !== "all" && isRoomZoneChecked[roomType]
                );
                return displayValue.join(" | ");
              }}
            >
              <MenuItem value={"all"}>
                <FormControlLabel
                  label="All Zones"
                  control={
                    <Checkbox
                      checked={isRoomZoneChecked.all}
                      onChange={({ target: { checked } }) => {
                        setIsRoomZoneChecked({
                          all: checked,
                          ...roomZones.reduce(
                            (returned, roomZone) => ({
                              ...returned,
                              [roomZone.roomZone]: checked,
                            }),
                            {}
                          ),
                        });
                        handleRoomZoneFiltersOnChange(
                          Object.keys(isRoomZoneChecked),
                          checked,
                          true
                        );
                      }}
                    />
                  }
                />
              </MenuItem>
              {roomZones.map(({ roomZoneId, roomZone }) => (
                <MenuItem key={roomZoneId} value={roomZone}>
                  <FormControlLabel
                    label={roomZone}
                    control={
                      <Checkbox
                        checked={isRoomZoneChecked[roomZone]}
                        onChange={({ target: { checked } }) => {
                          const {
                            [roomZone]: _,
                            all,
                            ...rest
                          } = isRoomZoneChecked;
                          setIsRoomZoneChecked({
                            ...isRoomZoneChecked,
                            [roomZone]: checked,
                            all:
                              Object.values(rest).every(
                                (value) => value === true
                              ) && checked
                                ? true
                                : false,
                          });
                          handleRoomZoneFiltersOnChange(
                            roomZone,
                            checked,
                            false
                          );
                        }}
                      />
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {props.showSortByFilter ? (
            <>
              <Sort sx={{ color: "white", fontSize: "34px" }} />
              <FormControl variant="filled" sx={{ width: "15%" }}>
                <InputLabel id="sort-label">Sort By</InputLabel>
                <Select
                  labelId="sort-label"
                  value={props.sortBy}
                  onChange={(e) => props.setSortBy(e.target.value)}
                >
                  <MenuItem value="ASC">Ascending</MenuItem>
                  <MenuItem value="DESC">Descending</MenuItem>
                </Select>
              </FormControl>
            </>
          ) : (
            <></>
          )}

          <Button
            variant="contained"
            size="large"
            sx={{ borderRadius: 1 }}
            onClick={props.handleSubmit}
          >
            Filter
          </Button>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default HouseKeepingFilterBar;
