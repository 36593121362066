import {
  SelectChangeEvent,
  Grid,
  Typography,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Divider,
  TablePagination,
} from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import theme from "../../assets/theme/theme";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import FilterListIcon from "@mui/icons-material/FilterList";
import axiosInstance from "../../constants/axiosConfig";
import { store } from "../../redux/store";
import { parse } from "path";
import Notification from "../../utils/notificationConfig";
import { ICountry } from "../../models/guests/GuestsInterface";
import BASE_API from "../../constants/api";
import axios from "axios";

interface DropdownsProps {
  onDropdownVisibilityChange: (isVisible: boolean) => void;
}
export default function DropdownsDetail({
  onDropdownVisibilityChange,
}: DropdownsProps) {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortedColumn, setSortedColumn] = useState<
    "countryId" | "countryName" | "countryCode" | "prefix" | "favorite"
  >("countryName");
  const [showDropdownsDetail, setShowDropdownsDetail] = useState(false);

  const handleButtonClick = () => {
    setShowDropdownsDetail(false);

    // Pass the boolean value to the parent component
    onDropdownVisibilityChange(false);
  };

  //This state is used so that it doesn't loop for unchanged country
  const [initialData, setInitialData] = useState<ICountry[]>([]);
  const [data, setData] = useState<ICountry[]>([]);

  const [selectedItems, setSelectedItems] = useState<ICountry[]>([]);

  interface Column {
    id: "countryId" | "countryName" | "countryCode" | "prefix" | "favorite";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { id: "countryName", label: "Country Name", minWidth: 200 },
    { id: "countryCode", label: "Country Code", minWidth: 170 },
    { id: "prefix", label: "Prefix", minWidth: 170 },
    { id: "favorite", label: "Favorite", minWidth: 90 },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleCheckboxClick = (name: string) => {
    const updatedData = data.map((item: ICountry) =>
      item.countryName === name ? { ...item, favorite: !item.favorite } : item
    );
    setData(updatedData);
    const selectedItem = updatedData.find((item) => item.countryName === name);

    if (selectedItem) {
      setSelectedItems((prevSelectedItems) =>
        selectedItem.favorite
          ? [...prevSelectedItems, selectedItem]
          : prevSelectedItems.filter((item) => item.countryName !== name)
      );
    }
  };

  const [disableButton, setDisableButton] = useState(false);
  const handleSave = () => {
    setDisableButton(true);

    const changedData = data.filter((data, index) => {
      return data.favorite !== initialData[index].favorite;
    });

    const saveRequests = changedData.map((item) => {
      const payload = {
        countryId: item.countryId,
        favorite: item.favorite,
      };

      // Send PUT request for each selected item
      return axios
        .put(`${BASE_API}/guests/country/edit-favorite/`, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(
            `Save successful for countryId ${item.countryName}`,
            response.data
          );
        })
        .catch((error) => {
          console.error(`Error saving for countryId ${item.countryId}`, error);
        });
    });

    // Use Promise.all to wait for all requests to complete
    Promise.all(saveRequests)
      .then(() => {
        Notification.success("Edit Favorite Success");

        // Optionally, you can refresh the country list after the save operation
        FetchCountryList();
        setDisableButton(false);
      })
      .catch((error) => {
        console.error("Error in one or more save requests", error);
      });
  };

  const [filter, setFilter] = useState<"all" | "favorite" | "notFavorite">(
    "all"
  );
  const [searchInput, setSearchInput] = useState("");

  const filteredData = useMemo(() => {
    const lowerCaseSearch = searchInput.toLowerCase();
    const filteredBySearch = data.filter(
      (item) =>
        item.countryName.toLowerCase().includes(lowerCaseSearch) ||
        item.countryCode.toLowerCase().includes(lowerCaseSearch) ||
        item.prefix.toLowerCase().includes(lowerCaseSearch)
    );

    if (filter === "favorite") {
      return filteredBySearch.filter((item) => item.favorite);
    } else if (filter === "notFavorite") {
      return filteredBySearch.filter((item) => !item.favorite);
    } else {
      return filteredBySearch;
    }
  }, [data, searchInput, filter]);

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setFilter(event.target.value as "all" | "favorite" | "notFavorite");
  };

  const handleSortChange = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);

    setSortedColumn("countryName");
  };
  const sortedData = [...filteredData].sort((a, b) => {
    const aValue = a[sortedColumn];
    const bValue = b[sortedColumn];

    if (aValue > bValue) {
      return sortOrder === "asc" ? 1 : -1;
    }
    if (aValue < bValue) {
      return sortOrder === "asc" ? -1 : 1;
    }
    return 0;
  });
  const token = store.getState().user.accessToken;

  const FetchCountryList = () => {
    axiosInstance
      .get(`${BASE_API}/guests/country/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Use a Set to keep track of unique country names
        const uniqueCountryNames = new Set<string>();

        // Filter and sort the data
        const sortedCountry: ICountry[] = response.data.data
          .filter((country: ICountry) => {
            if (!uniqueCountryNames.has(country.countryName)) {
              uniqueCountryNames.add(country.countryName);
              return true;
            }
            return false;
          })
          .sort((prev: ICountry, curr: ICountry) =>
            prev.favorite === curr.favorite ? 0 : prev.favorite ? -1 : 1
          );

        // Set the sorted and filtered data in the state
        setInitialData(parseCountryData(sortedCountry));
        setData(parseCountryData(sortedCountry));
      });
  };

  const parseCountryData = (fetchedCountry: any[]): ICountry[] => {
    if (fetchedCountry) {
      return fetchedCountry.map((item: any) => ({
        countryId: item.countryId.toString(),
        countryName: item.countryName.toString(),
        countryCode: item.countryCode.toString(),
        prefix: item.prefix.toString(),
        favorite: item.favorite,
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    FetchCountryList();
  }, []);

  return (
    <Grid container width={"100%"} p={5} spacing={2}>
      <Grid item xs={1}>
        <Button onClick={handleButtonClick}>Back</Button>
      </Grid>
      <Grid item xs={11}>
        <Typography variant="h3" marginBottom={2}>
          Country Dropdown Setting
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider
          orientation="horizontal"
          flexItem
          sx={{
            borderBottomWidth: 3.5,
            borderColor: "#232323",
          }}
        />
      </Grid>
      <Grid xs={12} padding={5}>
        <Box>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item padding={1}>
              <FilterListIcon color="primary" />
            </Grid>
            <Grid item xs={1}>
              <Typography>Country</Typography>
            </Grid>
            <Grid item xs={4} padding={1}>
              <TextField
                label="Search"
                size="small"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder="Name, Code, Prefix"
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={{ marginTop: 2 }}
              />
            </Grid>
            <Grid xs={3} padding={1}>
              <FormControl fullWidth>
                <InputLabel shrink={!!selectedItems} sx={{ marginTop: 2 }}>
                  Filter
                </InputLabel>
                <Select
                  variant="standard"
                  label="Filter"
                  defaultValue={"all"}
                  onChange={handleFilterChange}
                  inputProps={{
                    name: "favorite",
                    id: "favorite-select",
                    shrink: true,
                  }}
                  autoWidth
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="favorite">Favorite</MenuItem>
                  <MenuItem value="notFavorite">Not Favorite</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={3} padding={1}>
              <FormControl fullWidth>
                <InputLabel shrink={!!selectedItems} sx={{ marginTop: 2 }}>
                  Sort By
                </InputLabel>
                <Select
                  label="Sort By"
                  variant="standard"
                  defaultValue={"asc"}
                  autoWidth
                  onChange={handleSortChange}
                >
                  <MenuItem value="asc">Ascending</MenuItem>
                  <MenuItem value="desc">Descending</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            color: theme.palette.primary.main,
                            cursor: "pointer", // Add cursor style to indicate clickable
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.countryCode}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ color: "white" }}
                                >
                                  {" "}
                                  {column.id === "favorite" ? (
                                    <IconButton
                                      onClick={() =>
                                        handleCheckboxClick(row.countryName)
                                      }
                                    >
                                      {value ? (
                                        <StarIcon color="primary" />
                                      ) : (
                                        <StarOutlineIcon color="primary" />
                                      )}
                                    </IconButton>
                                  ) : column.format &&
                                    typeof value === "number" ? (
                                    column.format(value)
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                <TablePagination
                  sx={{
                    color: "white",
                    backgroundColor: theme.palette.background.default,
                  }}
                  rowsPerPageOptions={[20]}
                  component="div"
                  count={sortedData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  //onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
              <Grid
                item
                xs={12}
                container
                justifyContent="flex-end"
                alignItems="flex-end"
                py={2}
                padding={1}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={disableButton}
                >
                  Save
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
