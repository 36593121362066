"use client";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { format } from "date-fns";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  getStaahReservations,
  getStaahRoomTypes,
  updateStaahReservation,
} from "../../actions/ota";
import theme from "../../assets/theme/theme";
import LoadableCheckbox from "../../components/global/checkbox/LoadableCheckbox";
import CustomTable from "../../components/global/table/CustomTable";
import ManagementModalHeader from "../../components/management/ManagementModalHeader";
import {
  StaahReservation,
  StaahRoomType,
} from "../../models/ota/OTAInterfaces";

const IsManualCreatedCheckbox = ({
  id,
  isManualCreated = false,
  setData,
}: {
  id: string;
  isManualCreated: boolean;
  setData: Dispatch<SetStateAction<StaahReservation[]>>;
}) => (
  <LoadableCheckbox
    checked={isManualCreated}
    onChange={async () => {
      const staahReservation = await updateStaahReservation(
        id,
        !isManualCreated
      );
      if (!staahReservation) return;

      setData((prevData) =>
        prevData.map((item) => (item.id === id ? staahReservation : item))
      );
    }}
    sx={{ color: theme.palette.primary.main }}
  />
);

const OTALogModal = ({
  id,
  setId,
  data,
  setData,
  roomTypes,
}: {
  id: string | null;
  setId: Dispatch<SetStateAction<string | null>>;
  data: StaahReservation[];
  setData: Dispatch<SetStateAction<StaahReservation[]>>;
  roomTypes: StaahRoomType[];
}) => {
  const row = data.find((item) => item.id === id);
  const columns: {
    Cell?: (row: StaahReservation) => ReactNode;
    accessorFn?: (row: StaahReservation) => string | null;
    accessorKey?: keyof StaahReservation;
    header: string;
  }[] = [
    {
      Cell: ({ id, isManualCreated }) => (
        <IsManualCreatedCheckbox
          id={id}
          isManualCreated={isManualCreated}
          setData={setData}
        />
      ),
      header: "Manually Created",
    },
    { accessorKey: "status", header: "Log Status" },
    {
      Cell: ({ json }) =>
        json?.reservations
          ?.flatMap((reservation: any) =>
            reservation?.rooms?.map((room: any) => room?.arrival_date)
          )
          .join(", "),
      header: "Arrival Date",
    },
    {
      accessorFn: ({ json }) =>
        json?.reservations
          ?.map((reservation: any) => reservation?.hotel_name)
          .join(", "),
      header: "Hotel Name",
    },
    {
      accessorFn: ({ json }) =>
        json?.reservations
          ?.flatMap((reservation: any) =>
            reservation?.rooms?.map(
              (room: any) =>
                roomTypes.find((roomType) => roomType.staahId === room?.id)
                  ?.name
            )
          )
          .join(", "),
      header: "Room Type",
    },
    {
      accessorFn: ({ json }) =>
        json?.reservations
          ?.map((reservation: any) => reservation?.affiliation?.source)
          .join(", "),
      header: "Platform",
    },
    {
      accessorFn: ({ json }) =>
        json?.reservations
          ?.map((reservation: any) => reservation?.channel_booking_id)
          .join(", "),
      header: "OTA Code",
    },
    {
      accessorFn: ({ json }) =>
        json?.reservations
          ?.map(
            (reservation: any) =>
              `${reservation?.customer?.first_name} ${reservation?.customer?.last_name}`
          )
          .join(", "),
      header: "Customer Name",
    },
    {
      accessorFn: ({ json }) =>
        json?.reservations
          ?.map((reservation: any) => reservation?.totalprice)
          .join(", "),
      header: "Total Price",
    },
    {
      accessorFn: ({ json }) =>
        json?.reservations
          ?.map((reservation: any) => reservation?.paymenttype)
          .join(", "),
      header: "Payment Type",
    },
    {
      accessorFn: ({ json }) =>
        json?.reservations
          ?.map((reservation: any) => reservation?.status)
          .join(", "),
      header: "Booking Status",
    },
  ];

  return (
    <Modal open={Boolean(id)} onClose={() => setId(null)}>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        border={`3px solid ${theme.palette.primary.main}`}
        sx={{ transform: "translate(-50%, -50%)" }}
      >
        <ManagementModalHeader
          title="OTA Log"
          handleModalClose={() => setId(null)}
        />

        <TableContainer sx={{ backgroundColor: "black" }}>
          <Table>
            <TableBody>
              {columns.map(
                ({ Cell, accessorFn, accessorKey, header }, index) => (
                  <TableRow key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      {header}
                    </TableCell>

                    <TableCell sx={{ color: theme.palette.primary.main }}>
                      {row && Cell?.(row)}
                      {row && accessorFn?.(row)}
                      {accessorKey && (row?.[accessorKey] as ReactNode)}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

const OTALoggingPage = () => {
  const [count, setCount] = useState(0);
  const [data, setData] = useState<StaahReservation[]>([]);
  const [id, setId] = useState<string | null>(null);
  const columns = useMemo<MRT_ColumnDef<StaahReservation>[]>(
    () => [
      {
        Cell: ({ row }) => (
          <Button
            onClick={() => setId(row.original.id)}
            sx={{
              background: "black",
              border: 1,
              borderColor: theme.palette.primary.main,
            }}
          >
            {row.original.id}
          </Button>
        ),
        header: "Log ID",
        size: 350,
      },
      {
        accessorFn: ({ createdDatetime }) =>
          createdDatetime
            ? format(new Date(createdDatetime), "dd/MM/yyyy HH:mm")
            : null,
        header: "Logged At",
        size: 150,
      },
      {
        accessorFn: ({ json }) =>
          json?.reservations
            ?.map((reservation: any) => reservation?.hotel_name)
            .join(", "),
        header: "Hotel Name",
        size: 250,
      },
      { accessorKey: "status", header: "Status", size: 300 },
      {
        Cell: ({ row }) => (
          <IsManualCreatedCheckbox
            isManualCreated={row.original.isManualCreated}
            id={row.original.id}
            setData={setData}
          />
        ),
        header: "Manual Created",
        size: 40,
      },
      {
        accessorFn: ({ updatedDatetime }) =>
          updatedDatetime
            ? format(new Date(updatedDatetime), "dd/MM/yyyy HH:mm")
            : null,
        header: "Last Updated At",
        size: 150,
      },
    ],
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [roomTypes, setRoomTypes] = useState<StaahRoomType[]>([]);

  useEffect(() => {
    const fetchStaahReservations = async () => {
      const data = await getStaahReservations(
        pagination.pageIndex + 1,
        pagination.pageSize
      );
      if (!data) return;

      setCount(data.count);
      setData(data.results);
      setIsLoading(false);
    };

    setIsLoading(true);
    fetchStaahReservations();
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    const fetchRoomTypes = async () => {
      const data = await getStaahRoomTypes();
      if (!data) return;

      setRoomTypes(data);
    };

    fetchRoomTypes();
  }, []);

  return (
    <>
      <CustomTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        pagination={pagination}
        handlePaginationChange={setPagination}
        manualPagination
        rowCount={count}
        oddEvenBackground
      />

      <OTALogModal
        id={id}
        setId={setId}
        data={data}
        setData={setData}
        roomTypes={roomTypes}
      />
    </>
  );
};

export default OTALoggingPage;
