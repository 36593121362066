import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BookingResult, RoomBooking } from "../../pages/booking/interface";

const initialState: BookingResult = {
  addedAt:"",
  bookingId: "",
  platform: {
    platformId: "",
    platform: "",
  },
  roomBookings: [],
  lockerBookings: [],
  showerBookings: [],
  bookingStatus: [],
  bookingNo: "",
  bookingMadeDatetime: "",
  customerBooked: "",
  adultPax: 0,
  childPax: 0,
  details: "",
  sum: "",
  otaCode: "",
  customerStaying: "",
};


export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setBookingAPI: (state, action: PayloadAction<BookingResult>) => {
      return action.payload;
    },
  },
});

export const { setBookingAPI } = bookingSlice.actions;
export default bookingSlice.reducer;
