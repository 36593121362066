import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import theme from "../../assets/theme/theme";
import SettingsIcon from "@mui/icons-material/Settings";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import axios, { AxiosError, AxiosResponse } from "axios";
import BASE_API from "../../constants/api";
import Notification from "../../utils/notificationConfig";
import { store } from "../../redux/store";
import ManagementFilterBar from "../../components/management/ManagementFilterBar";
import ManagementContentArea from "../../components/management/ManagementContentArea";
import ManagementActionMenu from "../../components/management/ManagementActionMenu";
import MerchModal from "../../components/merch/MerchModal";
import MerchOverviewColumnItem from "../../components/merch/MerchOverviewColumnItem";
import { IMerch, IAxiosMerchResponse } from "../../types/merch";
import PageTitle from "../../components/global/PageTitle";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

const MerchManagementPage = () => {
  const [merchs, setMerchs] = useState<IMerch[]>([]);
  const handleMerchChange = (merchs: IMerch[]) => {
    setMerchs(merchs);
  };

  const [excludeArchive, setExcludeArchive] = useState<boolean>(true);

  const [activeMerchNum, setActiveMerchNum] = useState<number>(0);
  const handleActiveMerchNumChange = (activeNum: number) => {
    setActiveMerchNum(activeNum);
  };

  const [inactiveMerchNum, setInactiveMerchNum] = useState<number>(0);
  const handleInactiveMerchNumChange = (inactiveNum: number) => {
    setInactiveMerchNum(inactiveNum);
  };

  const [activeStock, setActiveStock] = useState<number>(0);
  const [inactiveStock, setInactiveStock] = useState<number>(0);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 12,
  });

  const [searchQuery, setSearchQuery] = useState<string>("");
  const handleSearchQueryChange = (search: string) => {
    setSearchQuery(search);
  };

  const fetchAllMerch = async () => {
    await axios
      .get(`${BASE_API}/merch/`, {
        params: {
          search: searchQuery,
          no_archived: excludeArchive,
        },
        headers: {
          Authorization: `Bearer ${store.getState().user.accessToken}`,
        },
      })

      .then(
        ({
          data: {
            merchs,
            activeMerchNum,
            inactiveMerchNum,
            activeMerchStock,
            inactiveMerchStock,
          },
        }: any) => {
          const convertedMerchs = merchs.map((merch: any) => ({
            ...merch,
            nonGuestSum: Number(merch.nonGuestSum),
            sum: Number(merch.sum),
            cost: Number(merch.cost),
            stock: merch.stockInQuantity - merch.stockOutQuantity,
          }));

          handleMerchChange(convertedMerchs);
          handleActiveMerchNumChange(activeMerchNum);
          handleInactiveMerchNumChange(inactiveMerchNum);
          setActiveStock(activeMerchStock);
          setInactiveStock(inactiveMerchStock);
        }
      )
      .catch((err) => {
        Notification.failed(err.message);
      });
  };
  useEffect(() => {
    fetchAllMerch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludeArchive]);

  const actionMenuAnchorElRef = useRef<HTMLButtonElement | null>(null);

  const initialSelectedMerchObjValues: IMerch = {
    merchId: "",
    itemName: "",
    itemDetails: "",
    sum: 0,
    sku: "",
    qrCode: "",
    cost: 0,
    nonGuestSum: "",
    isArchived: false,
    stockInQuantity: 0,
    stockOutQuantity: 0,
  };

  const [selectedMerchObj, setSelectedMerchObj] = useState<IMerch>(
    initialSelectedMerchObjValues
  );
  const handleSelectedMerchObjChange = (merch: IMerch) => {
    setSelectedMerchObj(merch);
  };

  const [isActionMenuOpen, setIsActionMenuOpen] = useState<boolean>(false);
  const handleActionMenuOpen = (clickedButtonId: string) => {
    actionMenuAnchorElRef.current = document.getElementById(
      clickedButtonId
    ) as HTMLButtonElement;
    setIsActionMenuOpen(true);
  };
  const handleActionMenuClose = () => {
    actionMenuAnchorElRef.current = null;
    setSelectedMerchObj(initialSelectedMerchObjValues);
    setIsActionMenuOpen(false);
  };

  const [isEditMerchModalOpen, setIsEditMerchModalOpen] =
    useState<boolean>(false);
  const handleEditMerchModalOpen = () => {
    setIsEditMerchModalOpen(true);
  };
  const handleEditMerchModalClose = () => {
    setIsEditMerchModalOpen(false);
    handleActionMenuClose();
  };

  const handleArchiveMerch = async () => {
    const apiUrl = selectedMerchObj.isArchived
      ? `${BASE_API}/merch/un-archive/${selectedMerchObj.merchId}/`
      : `${BASE_API}/merch/archive/${selectedMerchObj.merchId}/`;
    await axios
      .put(apiUrl, {
        headers: {
          Authorization: `Bearer ${store.getState().user.accessToken}`,
        },
      })
      .then((res: AxiosResponse) => {
        Notification.success(res.data.msg);
        // fetchAllMerch(); use material react table crud feature to add the item to the table in the frontend instead of get it again in the backend
        handleActionMenuClose();

        const updatedMerch = merchs.filter(
          (merch) => merch.merchId !== selectedMerchObj.merchId
        );

        let changedSelected = selectedMerchObj;

        changedSelected.isArchived = !changedSelected.isArchived;

        setMerchs([...updatedMerch, changedSelected]);
      })
      .catch((err: AxiosError) => {
        Notification.failed(err.message);
        console.log(err);
      });
  };

  const [isAddMerchModalOpen, setIsAddMerchModalOpen] =
    useState<boolean>(false);
  const handleAddMerchModalOpen = () => {
    setIsAddMerchModalOpen(true);
  };
  const handleAddMerchModalClose = () => {
    setIsAddMerchModalOpen(false);
    handleActionMenuClose();
  };

  const columns = useMemo<MRT_ColumnDef<IMerch>[]>(
    () => [
      {
        accessorKey: "itemName",
        header: "Merch Name",
        size: 200,
      },
      {
        accessorKey: "itemDetails",
        header: "Merch Desc.",
        size: 200,
      },
      {
        accessorKey: "Stock",
        header: "Stock",
        Cell: ({
          row: {
            original: { stockInQuantity, stockOutQuantity },
          },
        }) => stockInQuantity - stockOutQuantity,
      },
      {
        accessorKey: "cost",
        header: "Cost",
        Cell: ({
          row: {
            original: { cost },
          },
        }) => `MYR ${cost.toFixed(2)}`,
      },
      {
        accessorKey: "nonGuestSum",
        header: "Non Guest Price",
        Cell: ({
          row: {
            original: { nonGuestSum },
          },
        }) => `MYR ${parseFloat(nonGuestSum).toFixed(2)}`,
      },
      {
        accessorKey: "sum",
        header: "Price",
        size: 200,
        Cell: ({
          row: {
            original: { sum },
          },
        }) => `MYR ${sum.toFixed(2)}`,
      },
      {
        header: "Actions",
        size: 100,
        Cell: ({
          row: {
            original: {
              merchId,
              itemName,
              itemDetails,
              sum,
              sku,
              qrCode,
              cost,
              nonGuestSum,
              isArchived,
              stockInQuantity,
              stockOutQuantity,
              stock,
            },
          },
        }) => (
          <IconButton
            id={merchId}
            onClick={() => {
              handleActionMenuOpen(merchId);
              handleSelectedMerchObjChange({
                merchId,
                itemName,
                itemDetails,
                sku,
                sum,
                qrCode,
                cost,
                nonGuestSum,
                isArchived,
                stockInQuantity,
                stockOutQuantity,
                stock,
              });
            }}
          >
            <SettingsIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          </IconButton>
        ),
      },
    ],
    []
  );

  return (
    <Box
      width={"100%"}
      height={"fit-content"}
      sx={{
        postition: "absolute",
        inset: "0",
        marginX: "auto",
      }}
    >
      <PageTitle title="Merch Management" />
      <Box width={"100%"} height={"11.5vh"} minHeight={"5em"} display={"flex"}>
        <Box
          width={"18%"}
          height={"100px"}
          border={`3px solid ${theme.palette.primary.main}`}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <FilterAltOutlinedIcon
            sx={{
              fontSize: "2rem",
              color: "white",
            }}
          />
          <Typography variant={"h3"}>Filter</Typography>
        </Box>
        <Stack width={"82%"} direction={"column"}>
          <Box
            width={"100%"}
            height={"50px"}
            border={`3px solid ${theme.palette.primary.main}`}
            borderLeft={"none"}
            borderBottom={"none"}
            display={"flex"}
            alignItems={"center"}
            padding={2}
          >
            <Typography>Archived</Typography>
          </Box>
          <Box
            width={"100%"}
            height={"50px"}
            border={`3px solid ${theme.palette.primary.main}`}
            borderLeft={"none"}
            display={"flex"}
            alignItems={"center"}
            bgcolor={theme.palette.background.default}
          >
            <Select
              size="small"
              value={excludeArchive}
              onChange={(event) =>
                setExcludeArchive(event.target.value === "true")
              }
              sx={{
                width: "100%",
                ".MuiSelect-select": {
                  border: 0,
                  borderColor: theme.palette.primary.main,
                  borderRadius: 0,
                  // borderBottom:0,
                  borderRight: 0,
                  borderLeft: 0,
                },
                ".MuiSelect-icon": {
                  fill: "white",
                },
              }}
            >
              <MenuItem value={"true"}>Exclude</MenuItem>
              <MenuItem value={"false"}>Include</MenuItem>
            </Select>
          </Box>
        </Stack>
      </Box>
      <ManagementContentArea<IMerch, IMerch>
        title={"Merch"}
        tableItems={merchs}
        handleAddItemModalOpen={handleAddMerchModalOpen}
        columns={columns}
        pagination={pagination}
        setPagination={setPagination}
        OverviewColumnItem={MerchOverviewColumnItem}
        activeOverview
        activeNum={activeMerchNum}
        inactiveNum={inactiveMerchNum}
        searchQuery={searchQuery}
        handleSearchQueryChange={handleSearchQueryChange}
        fetchAllItems={fetchAllMerch}
        activeStock={activeStock}
        inactiveStock={inactiveStock}
      />
      <ManagementActionMenu
        ref={actionMenuAnchorElRef}
        isActionMenuOpen={isActionMenuOpen}
        handleActionMenuClose={handleActionMenuClose}
        handleEditInfoModalOpen={handleEditMerchModalOpen}
        handleArchiveMerch={handleArchiveMerch}
        selectedMerch={selectedMerchObj}
      />
      <MerchModal //add item modal
        title={"Add Merch"}
        type={"add"}
        isMerchModalOpen={isAddMerchModalOpen}
        handleMerchModalClose={handleAddMerchModalClose}
        fetchAllMerch={fetchAllMerch}
      />
      <MerchModal //edit item modal
        title={"Edit Info"}
        type={"edit"}
        isMerchModalOpen={isEditMerchModalOpen}
        handleMerchModalClose={handleEditMerchModalClose}
        initialValues={selectedMerchObj}
        fetchAllMerch={fetchAllMerch}
      />
    </Box>
  );
};

export default MerchManagementPage;
