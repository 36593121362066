import {
  Backdrop,
  Box,
  ButtonBase,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import theme from "../../assets/theme/theme";
import {
  ArrowBack,
  ArrowForward,
  HighlightOff,
  Login,
} from "@mui/icons-material";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "55%",
  bgcolor: "black",
  border: 2,
  borderColor: theme.palette.primary.main,
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
};

const SuccessCheckInModal = ({
  isOpen,
  handleCloseModal,
}: {
  isOpen: boolean;
  handleCloseModal: VoidFunction;
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box sx={modalStyle}>
        <Stack
          height={"2.5rem"}
          direction={"row"}
          alignItems={"center"}
          paddingX={"1rem"}
          justifyContent={"space-between"}
          borderBottom={2}
          borderColor={theme.palette.primary.main}
          sx={{ background: "#232323" }}
        >
          <Typography
            variant={"h3"}
            color={theme.palette.primary.main}
            fontWeight={"700"}
            fontSize={"1.1rem"}
          >
            Scan Card
          </Typography>
          <IconButton onClick={handleCloseModal}>
            <HighlightOff
              fontSize={"medium"}
              sx={{ color: theme.palette.primary.main }}
            />
          </IconButton>
        </Stack>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          height={"230px"}
          paddingX={"30px"}
          sx={{ backgroundColor: "black" }}
        >
          <Login
            color="primary"
            sx={{ fontSize: "50px", marginRight: "20px" }}
          />
          <Typography variant="h3">Booking Status have updated to </Typography>
          <Typography variant={"h3"} color={"primary"} marginLeft={1}>
            Checked In
          </Typography>
        </Box>
        <Stack direction={"row"} width={"100%"} height={"110px"}>
          <ButtonBase
            href={`/current-inhouse-guest`}
            sx={{
              width: "50%",
              height: "100%",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
              height={"100%"}
              borderTop={1}
              borderRight={1}
              borderColor={theme.palette.primary.main}
              paddingX={1}
              bgcolor={theme.palette.background.default}
            >
              <ArrowBack sx={{ fontSize: "50px" }} />
              <Typography variant="h4">Go to Upcoming Booking</Typography>
            </Box>
          </ButtonBase>
          <ButtonBase
            onClick={handleCloseModal}
            sx={{
              width: "50%",
              height: "100%",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
              height={"100%"}
              borderTop={1}
              borderColor={theme.palette.primary.main}
              paddingX={1}
              bgcolor={theme.palette.background.default}
            >
              <ArrowForward sx={{ fontSize: "50px" }} />
              <Typography variant="h4">Go to Guest Profile</Typography>
            </Box>
          </ButtonBase>
        </Stack>
      </Box>
    </Modal>
  );
};
export default SuccessCheckInModal;
