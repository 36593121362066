import {
  Backdrop,
  Box,
  Button,
  FormControl,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import theme from "../../assets/theme/theme";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useEffect, useState } from "react";
import Notification from "../../utils/notificationConfig";
import { useSelector } from "react-redux";
import { RootState, store } from "../../redux/store";
import axiosInstance from "../../constants/axiosConfig";
import BASE_API from "../../constants/api";
import { spamClickText } from "../../constants/spam-click";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "black",
  border: 2,
  borderColor: theme.palette.primary.main,
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
};

const AuthModal = (props: {
  authenticationModalOpen: boolean;
  handleAuthenticationModalOnClose: () => void;
  functionToRun: VoidFunction;
  requireOtherAdmin: boolean;
}) => {
  const token = store.getState().user.accessToken;
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [verifyLoad, setVerifyLoad] = useState<boolean>(false);

  const accountUsername = useSelector(
    (state: RootState) => state.user.username
  );

  const handleSubmit = () => {
    if (verifyLoad) {
      Notification.warning(spamClickText);
      return;
    }

    if (username === "" && password === "") {
      Notification.failed("Please fill in credentials");
      return;
    }
    if (username === "") {
      Notification.failed("Please fill in Username");
      return;
    }
    if (password === "") {
      Notification.failed("Please fill in Password");
      return;
    }

    if (username !== accountUsername && !props.requireOtherAdmin) {
      Notification.failed("Credential does not match with Current Account");
      return;
    }

    setVerifyLoad(true);

    const apiUrlParams = new URLSearchParams();
    apiUrlParams.append("username", username);
    apiUrlParams.append("password", password);

    const formData = {
      username: username,
      password: password,
    };

    const apiUrl = props.requireOtherAdmin
      ? "/accounts/verify-action/"
      : "/login/";

    axiosInstance
      .post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          Notification.success("Account Verified");
          props.handleAuthenticationModalOnClose();
          props.functionToRun();
        }
      })
      .catch((e) => {
        try {
          if (props.requireOtherAdmin) {
            Notification.failed(e.response.data.msg);
          } else {
            Notification.failed(e.response.data.detail);
          }
        } catch (err) {
          alert(e);
        }
      })
      .finally(() => {
        setVerifyLoad(false);
      });
  };
  return (
    <Modal
      open={props.authenticationModalOpen}
      onClose={props.handleAuthenticationModalOnClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box sx={modalStyle}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          paddingX={"1rem"}
          justifyContent={"space-between"}
          borderBottom={2}
          borderColor={theme.palette.primary.main}
          sx={{ background: "#232323" }}
        >
          <Typography
            variant={"h3"}
            color={theme.palette.primary.main}
            fontWeight={"700"}
            fontSize={"1.1rem"}
          >
            Authentication
          </Typography>
          <IconButton onClick={props.handleAuthenticationModalOnClose}>
            <HighlightOffIcon
              fontSize={"medium"}
              sx={{ color: theme.palette.primary.main }}
            />
          </IconButton>
        </Stack>
        <Stack
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}
        >
          <Box padding={4}>
            <FormControl fullWidth>
              <Typography variant="h5">Enter Username</Typography>
              <TextField
                label={"Username"}
                variant="filled"
                name={"username"}
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                fullWidth
                sx={{
                  marginY: 1,
                  ".MuiInputBase-input": {
                    textTransform: "none",
                  },
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                Password
              </Typography>
              <TextField
                label={"Password"}
                variant="filled"
                type="password"
                name={"password"}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                fullWidth
                sx={{
                  borderStyle: "solid",
                  ".MuiInputBase-input": {
                    textTransform: "none",
                  },
                }}
              />
            </FormControl>
            <Button
              variant="outlined"
              type="submit"
              size="medium"
              fullWidth
              onClick={handleSubmit}
              sx={{
                backgroundColor: "black",
                color: "white",
                marginTop: "25px",
                borderColor: "#2bd3cb",
                borderRadius: "6px",
                "&:hover": {
                  backgroundColor: "#2bd3cb",
                  color: "#000",
                },
              }}
              // disabled={verifyLoad}
            >
              Login
            </Button>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AuthModal;
