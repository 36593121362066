import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_API from "../../constants/api";
import Notification from "../../utils/notificationConfig";
import CircularProgress from "@mui/material/CircularProgress";

// hooks
import useAuthentication from "../../hooks/useAuthentication";

// components
import PageLoading from "../../components/global/loading/PageLoading";
import { FormControl, FormHelperText, Grid, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="lightgray" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://infrontconsulting.asia/">
        Infront Consulting
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

interface ILogin {
  username: string;
  password: string;
}

export default function Login() {
  const navigate = useNavigate();
  const { isLoggedIn, setUserDataToSlice } = useAuthentication();

  const [loading, setLoading] = useState<boolean>(true);

  const initialValue: ILogin = { username: "", password: "" };

  useEffect(() => {
    document.title = "Login";
    const checkLoggedIn = async () => {
      if (await isLoggedIn()) {
        navigate("/");
      }
    };

    checkLoggedIn();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  const LabelStyle = {
    color: "grey",
  };
  return (
    <>
      <CssBaseline />
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={Yup.object().shape({
          username: Yup.string().required("Username is Required"),
          password: Yup.string().required("Password is Required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          const apiUrlParams = new URLSearchParams();
          apiUrlParams.append("username", values.username);
          apiUrlParams.append("password", values.password);

          axios
            .post(`${BASE_API}/login/`, apiUrlParams)
            .then((res) => {
              if (res.status === 200) {
                Notification.success("Login Success");
                setUserDataToSlice(res.data.access, res.data.refresh);
                navigate("/");
                return;
              }
            })
            .catch((e) => {
              try {
                Notification.failed(e.response.data.detail);
                console.log(e);
              } catch (err) {
                alert(e);
              }
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          values,
          touched,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid
              container
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ minHeight: "100vh", width: "75%", marginX: "auto" }}
            >
              <Grid
                item
                md={6}
                sx={{
                  minHeight: "100%",
                  boxSizing: "border-box",
                }}
              >
                <Stack direction="column" alignItems={"center"}>
                  <Box borderRadius={"10px"}>
                    <img
                      src={"/assets/images/logpic.webp"}
                      alt="CapsuleBook"
                      style={{
                        height: 300,
                        width: 400,
                        objectFit: "fill",
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                  <br />
                  <Typography variant="h3" sx={{ marginY: 1 }}>
                    Welcome to CapsuleBook!
                  </Typography>
                  <Typography variant="h5" sx={{ color: "lightgray" }}>
                    All the pods you need, all in once place.
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                md={6}
                style={{
                  minHeight: "100%",
                  boxSizing: "border-box",
                  backgroundColor: "",
                }}
              >
                <Grid
                  container
                  flexDirection={"column"}
                  display={"flex"}
                  height={"600px"}
                >
                  <Grid item md={2} minWidth={"100%"}>
                    <Stack alignItems={"end"} marginRight={"50px"}>
                      <Typography variant="h2">CapsuleBook</Typography>
                      <Typography variant="h5">Book a Pod Now</Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    md={10}
                    minWidth={"100%"}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Box minWidth="300px">
                      <FormControl fullWidth>
                        <Typography
                          variant="h3"
                          sx={{ marginTop: 5, marginBottom: 1 }}
                        >
                          Log in
                        </Typography>
                        <Typography variant="h5">Enter Username</Typography>
                        <TextField
                          label={"Username"}
                          variant="filled"
                          name={"username"}
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          sx={{
                            marginY: 1,
                            ".MuiInputBase-input": {
                              textTransform: "none",
                            },
                          }}
                          InputLabelProps={{
                            style: LabelStyle,
                          }}
                        />
                        {touched.username && errors.username && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-username-login"
                          >
                            {errors.username}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                    <Box minWidth="300px">
                      <FormControl fullWidth>
                        <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                          Password
                        </Typography>
                        <TextField
                          label={"Password"}
                          variant="filled"
                          type="password"
                          name={"password"}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          sx={{
                            borderStyle: "solid",
                            ".MuiInputBase-input": {
                              textTransform: "none",
                            },
                          }}
                          InputLabelProps={{
                            style: LabelStyle,
                          }}
                        />
                        {touched.password && errors.password && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-password-login"
                          >
                            {errors.password}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                    <Box minWidth="300px">
                      <Button
                        variant="outlined"
                        type="submit"
                        size="medium"
                        fullWidth
                        disabled={isSubmitting}
                        sx={{
                          backgroundColor: "black",
                          color: "white",
                          marginTop: "25px",
                          borderColor: "#2bd3cb",
                          borderRadius: "6px",
                          "&:hover": {
                            backgroundColor: "#2bd3cb",
                            color: "#000",
                          },
                        }}
                      >
                        {isSubmitting ? (
                          <CircularProgress color="secondary" size={"24px"} />
                        ) : (
                          "Login"
                        )}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {Copyright({})}
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
