import {
  Box,
  Button,
  ButtonBase,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import PageTitle from "../../components/global/PageTitle";
import { BookingResult } from "../booking/interface";
import BookingPaymentScreen from "./BookingPaymentScreen";
import GuestOwnPay from "./GuestOwnPay";
import { KeyboardReturn } from "@mui/icons-material";

interface ITransactionStats {
  count: number;
  sum: number;
}

const bookingResultEmpty: BookingResult = {
  addedAt: "",
  bookingId: "",
  platform: {
    platformId: "",
    platform: "",
  },
  roomBookings: [
    {
      actualCheckinDateTime: "",
      actualCheckoutDateTime: "",
      derivedCheckInDatetime: "",
      derivedCheckOutDatetime: "",
      booking: "",
      details: "",
      personInCharge: "",
      room: "",
      roomBookingId: "",
      roomCode: "",
      roomType: "",
      roomZone: "",
      status: "",
      sum: "",
      memberId: "",
      personInChargeCountry: "",
      personInChargeId: "",
      personInChargeLastname: "",
      roomStatus: "",
      roomTypeColorTags: "",
      roomTypeDetails: "",
      roomTypeId: "",
    },
  ],
  lockerBookings: [],
  bookingStatus: [
    {
      booking: "",
      bookingStatus: "",
      bookingStatusId: "",
      checkInDatetime: "",
      checkOutDatetime: "",
      isLatest: true,
      statusDatetime: "",
    },
  ],
  bookingNo: "",
  bookingMadeDatetime: "",
  customerBooked: "",
  adultPax: 0,
  childPax: 0,
  details: "",
  sum: "",
  otaCode: "",
  showerBookings: [],
  customerStaying: "",
};

const PaymentScreen = () => {
  const theme = useTheme();
  const { bookingId } = useParams();
  const navigate = useNavigate();

  const location: Location = useLocation();
  const { state } = location;

  const bookingData: BookingResult = state ? state.data : bookingResultEmpty;

  const [tabStepperValue, setTabStepperValue] = useState(0);

  const handleStepperChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabStepperValue(newValue);
  };
  if (bookingId?.toLowerCase() === bookingData.bookingId) {
    return (
      <Box>
        <Typography variant="h1" marginBottom={2}>
          <PageTitle title="Payment" />
        </Typography>
        <Stack
          width={"100%"}
          direction="column" // or 'row' based on your layout
          alignItems="center" // Center horizontally
          justifyContent="center" // Center vertically
        >
          <Tabs
            value={tabStepperValue}
            onChange={handleStepperChange}
            sx={{
              marginBottom: 5,
              "& .MuiTab-root": {
                minWidth: "42.15vw",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "1.3rem",
                "&.Mui-selected": {
                  color: theme.palette.primary.main,
                },
                "&:not(.Mui-selected)": {
                  color: "white",
                  borderBottom: "1px solid #0a443c",
                },
              },
              alignItems: "center", // Center horizontally
              justifyContent: "center", // Center vertically
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
              sx={{ marginTop: 1 }}
            >
              <KeyboardReturn /> Back
            </Button>
            <Tab value={0} label={"GUEST OWN PAY"} />
            <Tab value={1} label={"CITY LEDGER OTA"} />
          </Tabs>
          <Grid
            container
            width={"100%"}
            sx={{
              alignItems: "center", // Center horizontally
              justifyContent: "center",
            }}
          >
            <Grid item md={12} sm={12} xs={12}>
              <Stack direction={"column"} height={"100%"}>
                {tabStepperValue === 0 ? (
                  <GuestOwnPay key={"guestOwnPay"} />
                ) : tabStepperValue === 1 ? (
                  <BookingPaymentScreen key={"bookingpaymentscreen"} />
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        {/* <PaymentForm
            bookingId={bookingId.toLowerCase()}
            paymentItem={rooms}
            bookingGuest={customerStaying}
            getPastTransactions={true}
            isExpressBooking={false}
            refetchMerch={openPOAModal}
            getTransactionCompleted={setTransactionsCompleted}
            latestBookingStatus={latestBookingStatus}
          />
          <AddItemToPOAModal
            guestsListProp={customerStaying}
            isAddItemModalOpen={openPOAModal}
            paymentItemsProp={[]}
            bookingId={bookingId.toLowerCase()}
            handleAddItemModalOnClose={handleClosePOA}
            latestBookingStatus={latestBookingStatus}
          /> */}
      </Box>
    );
  } else {
    return (
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" sx={{ color: "white", fontSize: "10rem" }}>
          403
        </Typography>
        <Typography sx={{ color: "white", fontSize: "3rem" }}>
          PLEASE NAVIGATE FROM GUEST PROFILE / CHECK IN CHECK OUT
        </Typography>
        <Box sx={{ marginTop: "6rem" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigate("/bookings/check-in-out-list");
            }}
          >
            Check In Check Out
          </Button>
        </Box>
      </Stack>
    );
  }
};

export default PaymentScreen;
