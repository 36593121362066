import { useState, useEffect } from "react";
import { 
    Box,
    Typography,
    TablePagination,
    Stack,
    TextField,
    TableContainer,
    TableBody,
    TableHead,
    Paper,
    Table
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import axiosInstance from "../../constants/axiosConfig";

// Constants
import { StyledTableCell, StyledTableRow } from "../../constants/tableStyle";

// Utils
import Notification from "../../utils/notificationConfig";
import { filterTableData } from "../../utils/functions";

// components
import AddRoleModal from "../../components/rolelist/AddRoleModal";
import TableLoading from "../../components/global/loading/TableLoading";
import ModalPopper from "../../components/global/ModalPopper";
import RoleEditModal from "../../components/rolelist/RoleEditModal";
import RolePermissionModal from "../../components/rolelist/RolePermissionModal";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

// Types and interfaces
import { RoleDataInterface } from "../../models/accounts/roleInterface";



export default function RoleList() {

    const token = useSelector((state : RootState) => state.user.accessToken);

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const [rows, setRows] = useState<RoleDataInterface[]>([]);
    const [page, setPage] = useState(0);
    const [filteredRows, setFilteredRows] = useState<RoleDataInterface[]>([]);

    const rowsPerPage = 10;

    const [fetchLoading, setFetchLoading] = useState<boolean>(true);
    const [updateTrigger, setUpdateTrigger] = useState<boolean>(false);

    useEffect(() => {
        document.title = "Capsule Transit | Role List"
        getPageData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (updateTrigger) {
            getPageData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateTrigger])

    const getPageData = () => {
        axiosInstance.get('/accounts/role/get-all-roles', config).then(res => {
            if (res.status === 200) {
                setRows(res.data.data);
                setFilteredRows(res.data.data);
                return;
            }
        }).catch(e => {
            console.log(e);
            Notification.failed('something wrong, please try again')
        }).finally(() => {
            setFetchLoading(false);
            setUpdateTrigger(false);
        });
    }


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    return (
        <Box>
            <Typography variant="h1" mb={10} fontSize="2rem">
                Manage Role
            </Typography>
            <Box mb={'10px'} display={'flex'} alignItems={'center'}>
                <Box mr={'20px'}>
                    <ModalPopper
                        text='Add new role'
                        variant='contained'
                        endIcons={<AddIcon />}
                        popUpComponent={<AddRoleModal setUpdateTrigger={setUpdateTrigger} />}
                    />
                </Box>
            </Box>
            <Stack 
                flexDirection={'row'}
                justifyContent={'space-between'} 
                alignItems={'center'}
                mb={'15px'}
            >
                <Typography fontSize="1.3rem">Room List</Typography>
                <Box sx={{ width: '20rem' }}>
                    <TextField 
                        type="search"
                        id="search"
                        label="Filter Search" 
                        sx={{ width: '100%' }}
                        onChange={(e) => {
                            const newRow = filterTableData(rows, e.target.value)
                            setFilteredRows(newRow);
                            setPage(0);
                        }}
                    />
                </Box>
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Role ID</StyledTableCell>
                            <StyledTableCell>Role Name</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell>Action</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    {fetchLoading ? (
                        <TableLoading />
                    ) : (
                        <>
                            {filteredRows.length > 0 && (
                                <TableBody>
                                    {filteredRows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => (
                                        <StyledTableRow
                                            key={row.roleId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <StyledTableCell component="th" scope="row">
                                                {row.roleId}
                                            </StyledTableCell>
                                            <StyledTableCell>{row.roleName}</StyledTableCell>
                                            <StyledTableCell>
                                                <ModalPopper
                                                    text='Check Permission'
                                                    variant="outlined"
                                                    popUpComponent={<RolePermissionModal roleInfo={row} />}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {row.roleName === "Super Admin" || row.roleName === "Admin" ? (
                                                    <Typography>-</Typography>
                                                ) : (
                                                    <ModalPopper
                                                        text='Edit'
                                                        popUpComponent={<RoleEditModal roleInfo={row} setUpdateTrigger={setUpdateTrigger} />}
                                                    />
                                                )}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            )}
                            {filteredRows.length === 0 && (
                                <TableBody>
                                    <StyledTableRow><StyledTableCell>data not available</StyledTableCell></StyledTableRow>
                                </TableBody>
                            )}
                        </>
                    )}
                </Table>
                <TablePagination
                    sx={{  
                        color: 'white',
                        backgroundColor: '#192038'
                    }}
                    rowsPerPageOptions={[20]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Box>
    )
}