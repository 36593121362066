import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Grow,
  LinearProgress,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
  darken,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import PageTitle from "../../components/global/PageTitle";

import {
  ArrowBack,
  ArrowForward,
  DarkMode,
  DoorFront,
  WbSunny,
  WbTwilight,
} from "@mui/icons-material";
import BedIcon from "@mui/icons-material/Bed";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ShowerIcon from "@mui/icons-material/Shower";
import Weekend from "@mui/icons-material/Weekend";
import {
  ArcElement,
  CategoryScale,
  Chart,
  LineController,
  LineElement,
  LinearScale,
  PieController,
  RadialLinearScale,
  Title,
} from "chart.js/auto";
import {
  addDays,
  endOfDay,
  endOfMonth,
  addMonths,
  format,
  startOfDay,
  startOfMonth,
  subDays,
} from "date-fns";
import { Bar, Line, Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";
import MorningBackground from "../../assets/images/morning.png";
import NightBackground from "../../assets/images/night.png";
import NoonBackground from "../../assets/images/noon.png";
import AuthModal from "../../components/account/AuthModal";
import SkeletonLoading from "../../components/common/SkeletonLoading";
import ManagementModalHeader from "../../components/management/ManagementModalHeader";
import CashierSelectionModal from "../../components/payment/CashierSelectionModal";
import BASE_API from "../../constants/api";
import axiosInstance from "../../constants/axiosConfig";
import {
  RoomBookingRevenueResponse,
  RoomSales,
  RoomSalesByDateRangeResponseV2,
  initialRoomSalesByDateRangeResponseV2,
} from "../../hooks/useAnalyticsAPI";
import {
  OccupancySetState,
  PieChartDatasetState,
  RevenueBarState,
  useChartJSStatistics,
  useOccupancyStatistic,
  useRevenueStatistic,
  useSalesByDateStatisticsChart,
} from "../../hooks/useRoomBookingStatistics";
import { CurrentShift, StaffShift } from "../../models/accounts/ShiftInterface";
import { RootState, store } from "../../redux/store";
import {
  formatToPresentableNumber,
  getYesterdayAndTomorrow,
} from "../../utils/functions";
import Notification from "../../utils/notificationConfig";
import { ILot } from "../../models/payment/PaymentInterface";

Chart.register(ArcElement, PieController, CategoryScale, LinearScale, Title);
Chart.register(RadialLinearScale, LineController, LineElement);

enum revenueColorCode {
  room = "#06D6A0",
  shower = "#00AEFF",
  locker = "#FF75C6",
  pos = "#E9D758",
  lounge = "#06D6A0",
}

const PlatformStatisticsDetailsAccordianDetails = (props: {
  state: PieChartDatasetState;
}) => {
  let totalRoomBooking =
    props.state.chartState.data.datasets[0].data.length > 0
      ? props.state.chartState.data.datasets[0].data.reduce(
          (prev, current) => (prev += current)
        )
      : 0;
  return (
    <>
      {props.state.isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        <>
          {props.state.chartState.data.labels.map((item, index) => {
            return (
              <Grid
                container
                display="grid"
                gridTemplateColumns={"2fr 1fr 1fr"}
                gap={1}
                key={index}
                marginBottom={1}
              >
                <Grid
                  item
                  flexDirection={"row"}
                  gap={2}
                  alignItems={"center"}
                  display="flex"
                >
                  <Box
                    width={"15px"}
                    height={"15px"}
                    borderRadius={"50%"}
                    bgcolor={
                      props.state.chartState.data.datasets.length > 0
                        ? props.state.chartState.data.datasets[0]
                            .backgroundColor![index]
                        : "#ffffff"
                    }
                  />
                  <Typography variant="h5" fontWeight={"bold"}>
                    {item}{" "}
                  </Typography>
                </Grid>
                <Grid textAlign={"center"}>
                  <Typography variant="h5" fontWeight={"bold"}>
                    {props.state.chartState.data.datasets[0].data[index]}{" "}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="h5" fontWeight={"bold"}>
                    {totalRoomBooking > 0
                      ? (
                          (props.state.chartState.data.datasets[0].data[index] /
                            totalRoomBooking) *
                          100
                        ).toFixed(2) + "%"
                      : "0%"}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </>
      )}
    </>
  );
};

function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function getFirstDayOfYear(): string {
  const currentDate = new Date();
  const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1); // January is 0
  return formatDate(firstDayOfYear);
}

function generateProgressBar(
  color: string,
  available: number,
  occupied: number
) {
  let barScale = (occupied / available) * 100;

  if (occupied > available) {
    barScale = 100;
  }

  if (occupied === 0) {
    barScale = 0;
  }
  return barScale;
}

interface IChartData {
  emptyFeedback: string;
  filledFeedback: string;
  chartData: [
    {
      date: string;
      averageRate: string;
    }
  ];
}

const currentShiftInitialValue: CurrentShift = {
  endPersonalCashAmount: "",
  endShiftDatetime: "",
  shiftId: "",
  startPersonalCashAmount: "",
  startShiftDatetime: "",
};
export default function HomePage() {
  const theme = useTheme();
  const accountId = store.getState().user.accountId;
  const lot = store.getState().user.lotId;
  const token = useSelector((state: RootState) => state.user.accessToken);
  const [roomSummaryData, setRoomSummaryData] =
    useState<RoomSalesByDateRangeResponseV2>(
      initialRoomSalesByDateRangeResponseV2
    );
  const [dataPeriod, setDataPeriod] = useState<string>("daily");
  const [chartData, setChartData] = useState<IChartData>();
  const [currentLot, setCurrentLot] = useState<ILot>({
    airportCodeId: 0,
    lotDescription: "",
    lotId: 0,
    lotNumber: "",
  });

  ///Loading States for Tracking Progress Bar
  const [progress, setProgress] = useState(0);
  const [feedbackLoading, setFeedbackLoading] = useState<boolean>(false);
  const [assignedShiftLoading, setAssignedShiftLoading] =
    useState<boolean>(false);
  const [startShiftLoading, setStartShiftLoading] = useState<boolean>(false);
  const [summaryDataLoading, setSummaryDataLoading] = useState<boolean>(false);
  const [selectedCashierId, setSelectedCashierId] = useState<number>(0);

  useEffect(() => {
    setFeedbackLoading(true);
    axiosInstance
      .get(`/bookings/feedback/graph?period=${dataPeriod}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setChartData(res.data.data);
      })
      .catch((res) => Notification.failed("Something wrong"))
      .finally(() => {
        setFeedbackLoading(false);
      });
  }, [dataPeriod]);

  const fetchCurrentLot = () => {
    axiosInstance
      .get(`/lot/get-own-lot`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setCurrentLot(res.data);
          return;
        }
        Notification.failed(res.data.msg);
      })
      .catch((e) => {
        Notification.failed(e.response.data.msg);
      });
  };

  const showPieChartData = {
    labels: ["Filled Feedback", "Empty Feedback"],
    datasets: [
      {
        data: [chartData?.filledFeedback, chartData?.emptyFeedback],
        backgroundColor: ["#4CAF50", "#2196F3"],
      },
    ],
  };

  const labels = Array.from({ length: 5 }, (_, index) => {
    if (chartData?.chartData[index]) {
      return chartData.chartData[index].date;
    } else {
      return "No Data";
    }
  });
  const averageRate = chartData?.chartData
    .slice(0, 5)
    .map((dataPoint) => dataPoint.averageRate);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Average Rating",
        data: averageRate,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const today = new Date();

  // Use effect for Occupancy Data
  const yesterdayOccupancy = useOccupancyStatistic(
    lot,
    startOfDay(subDays(today, 1)),
    startOfDay(today)
  );

  const todayOccupancy = useOccupancyStatistic(
    lot,
    startOfDay(today),
    startOfDay(addDays(today, 1))
  );

  const tomorrowOccupancy = useOccupancyStatistic(
    lot,
    startOfDay(addDays(today, 1)),
    startOfDay(addDays(today, 2))
  );

  // Use effect for Pie chart statictics
  const yesterDayChart = useChartJSStatistics(
    lot,
    startOfDay(subDays(today, 1)),
    startOfDay(today)
  );
  const todayChart = useChartJSStatistics(
    lot,
    startOfDay(today),
    startOfDay(addDays(today, 1))
  );
  const tmrChart = useChartJSStatistics(
    lot,
    startOfDay(addDays(today, 1)),
    startOfDay(addDays(today, 2))
  );

  // Get Line Chart statistics chart data
  const lineChart = useSalesByDateStatisticsChart(
    lot,
    startOfMonth(today),
    startOfMonth(addMonths(today, 1))
  );

  // Get Progress Bar Revenue
  const yesterDayRevenue = useRevenueStatistic(
    lot,
    startOfDay(subDays(today, 1)),
    startOfDay(today)
  );

  const todayRevenue = useRevenueStatistic(
    lot,
    startOfDay(today),
    startOfDay(addDays(today, 1))
  );

  const tomorrowRevenue = useRevenueStatistic(
    lot,
    startOfDay(addDays(today, 1)),
    startOfDay(addDays(today, 2))
  );
  // Use effect for YTD chart statictics

  useEffect(() => {
    document.title = "Capsule Transit | Home ";
    fetchCurrentLot();
  }, []);

  const shiftInitialValue: StaffShift = {
    staffShiftId: "",
    shiftSettings: {
      shiftSettingsId: "",
      shiftName: "",
      startHour: "",
      endHour: "",
      isArchived: false,
    },
  };

  const [selectedShift, setSelectedShift] =
    useState<StaffShift>(shiftInitialValue);
  const [shifts, setShifts] = useState<StaffShift[]>([]);
  const [currentShift, setCurrentShift] = useState<CurrentShift>(
    currentShiftInitialValue
  );
  const [currentTime, setCurrentTime] = useState(new Date());

  const [authModalOpen, setAuthModalOpen] = useState<boolean>(false);
  const handleAuthModalOpen = () => {
    setAuthModalOpen(true);
  };
  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };
  const shiftImage =
    parseInt(selectedShift.shiftSettings.startHour.substring(0, 2)) >= 16
      ? `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${NoonBackground})`
      : parseInt(selectedShift.shiftSettings.startHour.substring(0, 2)) >= 8
      ? `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${MorningBackground})`
      : parseInt(selectedShift.shiftSettings.startHour.substring(0, 2)) >= 0
      ? `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${NightBackground})`
      : "";

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setAssignedShiftLoading(true);
    const fetchAssignedShift = () => {
      const apiUrl = `${BASE_API}/accounts/shift/${accountId}/get-shift-info-details/`;
      axiosInstance
        .get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const data = res.data.data;
          const convertedShift: StaffShift[] = data.map((data: any) => ({
            staffShiftId: data.staffshift_id,
            shiftSettings: {
              shiftSettingsId: data.shiftsettings.shiftsettings_id,
              shiftName: data.shiftsettings.shift_name,
              startHour: data.shiftsettings.start_hour,
              endHour: data.shiftsettings.end_hour,
            },
          }));
          setShifts(convertedShift);
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          setAssignedShiftLoading(false);
        });
    };
    const fetchCurrentShift = () => {
      setStartShiftLoading(true);
      const apiUrl = `${BASE_API}/accounts/shift/${accountId}/current-shift/`;
      axiosInstance
        .get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const data = res.data.data;
          setCurrentShift({
            shiftId: data.shift_id,
            startShiftDatetime: data.start_shift_datetime,
            endPersonalCashAmount: data.end_personal_cash_amount,
            endShiftDatetime: data.end_shift_datetime,
            startPersonalCashAmount: data.start_personal_cash_amount,
          });
          localStorage.setItem("defaultCashier", data.cashier_name);
        })
        .catch((res) => {})
        .finally(() => {
          setStartShiftLoading(false);
        });
    };
    fetchCurrentShift();
    fetchAssignedShift();
  }, []);

  useEffect(() => {
    if (shifts.length === 1) {
      setSelectedShift(shifts[0]);
      return;
    }

    if (currentShift.shiftId && shifts.length > 0) {
      const startTime = new Date(currentShift.startShiftDatetime);
      startTime.setFullYear(1970, 0, 1);
      const closestStartTimeShift = shifts.reduce(function (prev, curr) {
        let timeA = new Date(`1970-01-01T${prev.shiftSettings.startHour}`);
        let timeB = new Date(`1970-01-01T${curr.shiftSettings.startHour}`);

        // Handle the case when start hour is 0
        if (startTime.getHours() === 0) {
          timeA = new Date(`1970-01-02T${prev.shiftSettings.startHour}`);
          timeB = new Date(`1970-01-02T${curr.shiftSettings.startHour}`);
        }

        //There 2 IF are for detecting if the time is after startTime, if its after startTime then return the other one
        if (startTime.getTime() - timeA.getTime() < 0) {
          // return prev;
          return curr;
        }
        if (startTime.getTime() - timeB.getTime() < 0) {
          return prev;
        }
        const diffA = Math.abs(startTime.getTime() - timeA.getTime());
        const diffB = Math.abs(startTime.getTime() - timeB.getTime());

        return diffA < diffB ? prev : curr;
      });
      setSelectedShift(closestStartTimeShift);
      return;
    }

    if (shifts.length > 0) {
      const now = new Date();
      now.setFullYear(1970, 0, 1);
      const closestStartTimeShift = shifts.reduce(function (prev, curr) {
        let timeA = new Date(`1970-01-01T${prev.shiftSettings.startHour}`);
        let timeB = new Date(`1970-01-01T${curr.shiftSettings.startHour}`);

        // Handle the case when start hour is 0
        if (now.getHours() === 0) {
          timeA = new Date(`1970-01-02T${prev.shiftSettings.startHour}`);
          timeB = new Date(`1970-01-02T${curr.shiftSettings.startHour}`);
        }

        //There 2 IF are for detecting if the time is after now, if its after now then return the other one
        if (now.getTime() - timeA.getTime() < 0) {
          return curr;
        }
        if (now.getTime() - timeB.getTime() < 0) {
          return prev;
        }
        const diffA = Math.abs(now.getTime() - timeA.getTime());
        const diffB = Math.abs(now.getTime() - timeB.getTime());

        return diffA < diffB ? prev : curr;
      });
      setSelectedShift(closestStartTimeShift);
    }
  }, [shifts, currentShift]);

  const startShift = () => {
    const apiUrl = `${BASE_API}/accounts/shift/check-in-shift/`;

    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const timezoneOffset = getTimezoneOffset(timezone) / 1000;

    const formData = {
      staffshift_id: selectedShift.staffShiftId,
      timestamp: new Date(),
      // timestamp:
      //   timezone === "Asia/Jakarta"
      //     ? stringDateToEpoch(new Date().toString())! - 7 * 60 * 60
      //     : stringDateToEpoch(new Date().toString())! - 8 * 60 * 60,
      cash: "0",
      cashier_terminal_id: selectedCashierId,
    };

    axiosInstance
      .post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        Notification.success(res.data.msg);
        const data = res.data.data;
        setCurrentShift({
          shiftId: data.shift_id,
          startShiftDatetime: data.start_shift_datetime,
          endPersonalCashAmount: data.end_personal_cash_amount,
          endShiftDatetime: data.end_shift_datetime,
          startPersonalCashAmount: data.start_personal_cash_amount,
        });
      })
      .catch((res) =>
        Notification.failed(
          "No shift assigned to your account yet, proceed to Admin > General Settings or request it from management"
        )
      );
  };

  const getRoomSummaryData = () => {
    setSummaryDataLoading(true);
    // const currentDate = new Date();
    // const firstDay = new Date(
    //   currentDate.getFullYear(),
    //   currentDate.getMonth(),
    //   1
    // ).toISOString();
    // const lastDay = new Date(
    //   currentDate.getFullYear(),
    //   currentDate.getMonth() + 1,
    //   0
    // ).toISOString();

    const dateFilters = getYesterdayAndTomorrow();
    const queryParams = `start_date=${dateFilters.yesterday}&end_date=${dateFilters.tomorrow}&lot_id=${lot}`;

    const apiUrl = `${BASE_API}/queryservice/v1/sales-summary/room-sales-average-summary-by-date?${queryParams}`;

    axiosInstance
      .get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setRoomSummaryData(res.data);
      })
      .catch((res) => Notification.failed("Error Retreiving Summary Data"))
      .finally(() => {
        setSummaryDataLoading(false);
      });
  };

  useEffect(() => {
    getRoomSummaryData();
  }, [token]);

  const [endShiftModalOpen, setEndShiftModalOpen] = useState<boolean>(false);
  const handleOpenEndShiftModal = () => {
    setEndShiftModalOpen(true);
  };
  const handleCloseEndShiftModal = () => {
    setEndShiftModalOpen(false);
  };

  const handleUponSelectCashier = (cashierId: number = 0) => {
    setSelectedCashierId(cashierId);
  };

  const handleCheckInCheckOutValidation = async () => {
    return new Promise<void>((resolve, reject) => {
      try {
        if (
          !checkTime30Minutes(
            currentShift.startShiftDatetime,
            selectedShift.shiftSettings.startHour
          ) &&
          currentShift.shiftId
        ) {
          handleAuthModalOpen();
          resolve(); // Resolve the promise if modal is opened
        } else if (currentShift.shiftId) {
          handleOpenEndShiftModal();
          resolve(); // Resolve the promise after ending the shift
        } else {
          startShift();
          resolve(); // Resolve the promise after starting the shift
        }
      } catch (e) {
        reject(e); // Reject the promise if an error occurs
      }
    });
  };

  const [accordionStates, setAccordionStates] = useState([false, false, false]);

  const toggleAccordion = (index: number) => {
    const updatedStates = [...accordionStates];
    updatedStates[index] = !updatedStates[index];
    setAccordionStates(updatedStates);
  };

  const isMobileDevice = useMediaQuery("(max-width:900px)");

  const [selectedDate, setSelectedDate] = useState<string>("today");

  const [openCashierSelectionModal, setOpenCashierSelectionModal] =
    useState<boolean>(false);

  const handleOpenCashierSelectionModal = () => {
    setOpenCashierSelectionModal(true);
  };

  const handleCloseCashierSelectionModal = () => {
    setOpenCashierSelectionModal(false);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 80);
      });
    }, 200);

    if (
      feedbackLoading === false &&
      assignedShiftLoading === false &&
      startShiftLoading === false &&
      summaryDataLoading === false &&
      tomorrowRevenue.isLoading === false &&
      todayRevenue.isLoading === false &&
      yesterDayRevenue.isLoading === false
    ) {
      setProgress(100);
    }

    return () => {
      clearInterval(timer);
    };
  }, [
    feedbackLoading,
    assignedShiftLoading,
    startShiftLoading,
    summaryDataLoading,
    tomorrowRevenue.isLoading,
    todayRevenue.isLoading,
    yesterDayRevenue.isLoading,
  ]);

  return (
    <>
      <PageTitle title="Home Page" />
      {feedbackLoading ||
      assignedShiftLoading ||
      startShiftLoading ||
      summaryDataLoading ? (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          width={"100%"}
          maxWidth={isMobileDevice ? "80vw" : "100vw"}
        >
          <>
            <SkeletonLoading />

            <Box width={"77.78%"} paddingX={3} paddingBottom={5}>
              <Typography
                color={theme.palette.primary.main}
                fontWeight={"bold"}
                fontSize={18}
                paddingY={1}
              >
                {Number(progress).toFixed(0)}
              </Typography>

              <LinearProgress
                variant="determinate"
                value={progress}
                color="primary"
                sx={{ borderRadius: 10, background: "white" }}
              />
            </Box>
          </>
        </Box>
      ) : (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          maxWidth={isMobileDevice ? "80vw" : "100vw"}
          width={"100%"}
        >
          <Box
            width={isMobileDevice ? "100%" : "77.78%"}
            borderRadius={2}
            paddingX={3}
            paddingY={2}
            sx={{
              backgroundImage: shiftImage,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Stack
              direction={"column"}
              width={"100%"}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"space-between"}
            >
              <Stack direction="row" justifyContent={"center"} gap={1}>
                {parseInt(
                  selectedShift.shiftSettings.startHour.substring(0, 2)
                ) >= 16 ? (
                  <WbTwilight sx={{ color: "orange", fontSize: "28px" }} />
                ) : parseInt(
                    selectedShift.shiftSettings.startHour.substring(0, 2)
                  ) >= 8 ? (
                  <WbSunny sx={{ color: "yellow", fontSize: "28px" }} />
                ) : parseInt(
                    selectedShift.shiftSettings.startHour.substring(0, 2)
                  ) >= 0 ? (
                  <DarkMode sx={{ color: "#7c1ddb", fontSize: "28px" }} />
                ) : (
                  <></>
                )}
                <Typography variant="h4">
                  {selectedShift.shiftSettings.shiftName
                    ? selectedShift.shiftSettings.shiftName + " Shift"
                    : ""}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  justifyContent={{ xs: "flex-end", md: "space-between" }}
                  alignItems={{ xs: "flex-start", md: "center" }}
                  width="100%"
                >
                  <Stack direction="column" flex={1}>
                    <Typography variant="h4">
                      {format(currentTime, "dd MMMM yyyy")}
                    </Typography>
                  </Stack>
                  <Stack flex={1}>
                    <Typography variant="h3" fontSize={"22px"}>
                      {removeLastTwoSeconds(
                        selectedShift.shiftSettings.startHour
                      )}{" "}
                      -{" "}
                      {removeLastTwoSeconds(
                        selectedShift.shiftSettings.endHour
                      )}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack>
                  <Button
                    variant="contained"
                    onClick={
                      currentShift.shiftId
                        ? handleCheckInCheckOutValidation
                        : handleOpenCashierSelectionModal
                    }
                    sx={{ height: "50px", borderRadius: 2 }}
                  >
                    {currentShift.shiftId ? "End Shift" : "Start Shift"}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <CashierSelectionModal
            onSubmit={handleCheckInCheckOutValidation}
            handleClose={handleCloseCashierSelectionModal}
            open={openCashierSelectionModal}
            handleUponSelectCashier={handleUponSelectCashier}
          />
          <AuthModal
            authenticationModalOpen={authModalOpen}
            handleAuthenticationModalOnClose={handleAuthModalClose}
            functionToRun={
              currentShift.shiftId ? handleOpenEndShiftModal : startShift
            }
            requireOtherAdmin={false}
          />
          <Stack
            direction={"row"}
            spacing={4}
            paddingX={isMobileDevice ? 0 : 4}
            paddingY={2}
            width={isMobileDevice ? "100%" : "80%"}
            justifyContent={"space-between"}
          >
            {(!isMobileDevice ||
              (isMobileDevice && selectedDate === "yesterday")) && (
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                width={isMobileDevice ? "100%" : "33%"}
              >
                <Grid
                  container
                  bgcolor={"#1A1A1A"}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: { md: "116px" } }}
                >
                  <Grid item xs={6}>
                    <Typography padding={1} variant="h3">
                      Yesterday
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography padding={1} variant="h4">
                      {format(subDays(currentTime, 1), "dd MMMM yyyy")}
                    </Typography>
                  </Grid>
                </Grid>
                {isMobileDevice && selectedDate === "yesterday" && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setSelectedDate("today")}
                    sx={{ padding: 0, width: "20px" }}
                  >
                    <ArrowForward />
                  </Button>
                )}
              </Stack>
            )}

            {(!isMobileDevice ||
              (isMobileDevice && selectedDate === "today")) && (
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                width={isMobileDevice ? "100%" : "33%"}
              >
                {isMobileDevice && selectedDate === "today" && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setSelectedDate("yesterday")}
                    sx={{ padding: 0 }}
                  >
                    <ArrowBack />
                  </Button>
                )}
                <Grid
                  container
                  bgcolor={"#1A1A1A"}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: { md: "116px" } }}
                >
                  <Grid item xs={6}>
                    <Typography padding={1} variant="h3">
                      Today
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography padding={1} variant="h4">
                      {format(currentTime, "dd MMMM yyyy")}
                    </Typography>
                  </Grid>
                </Grid>
                {isMobileDevice && selectedDate === "today" && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setSelectedDate("tomorrow")}
                    sx={{ padding: 0, width: "20px" }}
                  >
                    <ArrowForward />
                  </Button>
                )}
              </Stack>
            )}
            {(!isMobileDevice ||
              (isMobileDevice && selectedDate === "tomorrow")) && (
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                width={isMobileDevice ? "100%" : "33%"}
              >
                {isMobileDevice && selectedDate === "tomorrow" && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setSelectedDate("today")}
                    sx={{ padding: 0 }}
                  >
                    <ArrowBack />
                  </Button>
                )}
                <Grid
                  container
                  bgcolor={"#1A1A1A"}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: { md: "116px" } }}
                >
                  <Grid item xs={6}>
                    <Typography variant="h3" padding={1}>
                      Tomorrow
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h4" padding={1}>
                      {format(addDays(currentTime, 1), "dd MMMM yyyy")}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            )}
          </Stack>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width={"100%"}
            rowGap={1}
          >
            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                width: isMobileDevice ? "100%" : "77.87%",
                color: "white",
                bgcolor: "transparent",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#FFFF" }} />}
                sx={{
                  borderRadius: 2,
                  overflow: "hidden",
                  backgroundColor: theme.palette.primary.dark,
                }}
              >
                <Typography fontSize={24} style={{ fontWeight: "bold" }}>
                  Revenues - {currentLot.lotNumber}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0, pb: 1 }}>
                <Grid container>
                  {(!isMobileDevice ||
                    (isMobileDevice && selectedDate === "yesterday")) && (
                    <RevenueOverviewComponent revenue={yesterDayRevenue} />
                  )}
                  {(!isMobileDevice ||
                    (isMobileDevice && selectedDate === "today")) && (
                    <RevenueOverviewComponent revenue={todayRevenue} />
                  )}
                  {(!isMobileDevice ||
                    (isMobileDevice && selectedDate === "tomorrow")) && (
                    <RevenueOverviewComponent revenue={tomorrowRevenue} />
                  )}
                  {(!isMobileDevice ||
                    (isMobileDevice && selectedDate === "yesterday")) && (
                    <RoomSalesComponent item={roomSummaryData.yesterday} />
                  )}
                  {(!isMobileDevice ||
                    (isMobileDevice && selectedDate === "today")) && (
                    <RoomSalesComponent item={roomSummaryData.today} />
                  )}
                  {(!isMobileDevice ||
                    (isMobileDevice && selectedDate === "tomorrow")) && (
                    <RoomSalesComponent item={roomSummaryData.tomorrow} />
                  )}
                </Grid>
                <Accordion
                  sx={{
                    mt: 1,
                    borderRadius: 2,
                    color: "white",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "#FFFF" }} />}
                    sx={{ borderRadius: 2, overflow: "hidden" }}
                  >
                    Total Sales of {format(currentTime, "MMMM")}
                  </AccordionSummary>
                  <AccordionDetails>
                    {lineChart.isLoading ? (
                      <Typography>Loading...</Typography>
                    ) : (
                      <>
                        <Line data={lineChart.chartState.data}></Line>
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
            <Accordion
              defaultExpanded
              disableGutters
              variant="outlined"
              sx={{
                width: isMobileDevice ? "100%" : "77.87%",
                color: "white",
                bgcolor: "transparent",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#FFFF" }} />}
                sx={{
                  backgroundColor: theme.palette.primary.dark,
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              >
                <Typography fontSize={24} style={{ fontWeight: "bold" }}>
                  Rooms
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <Grid container spacing={1} pt={2} pb={1}>
                  {(!isMobileDevice ||
                    (isMobileDevice && selectedDate === "yesterday")) && (
                    <RoomOccupiedComponent occupancy={yesterdayOccupancy} />
                  )}
                  {(!isMobileDevice ||
                    (isMobileDevice && selectedDate === "today")) && (
                    <RoomOccupiedComponent occupancy={todayOccupancy} />
                  )}
                  {(!isMobileDevice ||
                    (isMobileDevice && selectedDate === "tomorrow")) && (
                    <RoomOccupiedComponent occupancy={tomorrowOccupancy} />
                  )}

                  {(!isMobileDevice ||
                    (isMobileDevice && selectedDate === "yesterday")) && (
                    <RoomBookingComponent
                      occupancy={yesterdayOccupancy}
                      pieChartData={yesterDayChart}
                      pieDay="yesterday"
                    />
                  )}
                  {(!isMobileDevice ||
                    (isMobileDevice && selectedDate === "today")) && (
                    <RoomBookingComponent
                      occupancy={todayOccupancy}
                      pieChartData={todayChart}
                      pieDay="today"
                    />
                  )}
                  {(!isMobileDevice ||
                    (isMobileDevice && selectedDate === "tomorrow")) && (
                    <RoomBookingComponent
                      occupancy={tomorrowOccupancy}
                      pieChartData={tmrChart}
                      pieDay="tomorrow"
                    />
                  )}

                  {(!isMobileDevice ||
                    (isMobileDevice && selectedDate === "yesterday")) && (
                    <RoomDetailsComponent
                      accordionState={accordionStates[0]}
                      occupancy={yesterdayOccupancy}
                      pieChartData={yesterDayChart}
                      toggleAccordion={() => toggleAccordion(0)}
                    />
                  )}
                  {(!isMobileDevice ||
                    (isMobileDevice && selectedDate === "today")) && (
                    <RoomDetailsComponent
                      accordionState={accordionStates[1]}
                      occupancy={todayOccupancy}
                      pieChartData={todayChart}
                      toggleAccordion={() => toggleAccordion(1)}
                    />
                  )}
                  {(!isMobileDevice ||
                    (isMobileDevice && selectedDate === "tomorrow")) && (
                    <RoomDetailsComponent
                      accordionState={accordionStates[2]}
                      occupancy={tomorrowOccupancy}
                      pieChartData={tmrChart}
                      toggleAccordion={() => toggleAccordion(2)}
                    />
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: "#141414",
                width: isMobileDevice ? "100%" : "77.87%",
                color: "white",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#FFFF" }} />}
                sx={{
                  borderRadius: 2,
                  overflow: "hidden",
                  backgroundColor: theme.palette.primary.dark,
                }}
              >
                <Typography fontSize={24} style={{ fontWeight: "bold" }}>
                  Graphs
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingTop: 2, paddingBottom: 1 }}>
                <Stack
                  direction={"column"}
                  justifyContent={"flex-end"}
                  width={"100%"}
                >
                  {" "}
                  <ButtonGroup
                    variant="contained"
                    aria-label="toggle options"
                    sx={{ justifyContent: "flex-end", padding: "10px" }}
                  >
                    <Button
                      variant={
                        dataPeriod === "daily" ? "contained" : "outlined"
                      }
                      onClick={() => setDataPeriod("daily")}
                      style={{ borderRadius: "20px 0 0 20px" }}
                    >
                      Daily
                    </Button>
                    <Button
                      variant={
                        dataPeriod === "weekly" ? "contained" : "outlined"
                      }
                      onClick={() => setDataPeriod("weekly")}
                    >
                      Weekly
                    </Button>
                    <Button
                      variant={
                        dataPeriod === "monthly" ? "contained" : "outlined"
                      }
                      style={{ borderRadius: "0 20px 20px 0" }}
                      onClick={() => setDataPeriod("monthly")}
                    >
                      Monthly
                    </Button>
                  </ButtonGroup>
                  <Stack
                    flexDirection={"column"}
                    sx={{ flexDirection: { md: "row" } }}
                  >
                    <Box flex={1}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography>Feedback Ratio</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          justifyContent={"center"}
                          display={"flex"}
                        >
                          {" "}
                          <Grid item width={"70%"}>
                            <Pie data={showPieChartData} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box flex={1}>
                      <Typography>Rating</Typography>
                      <Bar data={data}></Bar>
                    </Box>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Box>
      )}
      {currentShift.shiftId !== "" && endShiftModalOpen && (
        <EndShiftModal
          open={endShiftModalOpen}
          currentShift={currentShift}
          handleCloseModal={handleCloseEndShiftModal}
          setCurrentShift={setCurrentShift}
        />
      )}
    </>
  );
}

const RoomOccupiedComponent = (props: { occupancy: OccupancySetState }) => {
  const theme = useTheme();
  return (
    <Grid item xs={12} sm={12} md={4}>
      <Accordion
        variant="outlined"
        disableGutters
        sx={{
          color: "white",
          width: "100%",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#FFFF" }} />}
          sx={{
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Typography variant="h4">Room Occupied</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            flex: 1,

            backgroundColor: theme.palette.background.default,
          }}
        >
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{ overflowX: "auto" }}
          >
            {props.occupancy.data.details.map((data, index) => {
              const occupiedValue = generateProgressBar(
                data.color_code,
                data.total_room,
                data.total_occupied_room
              );
              const bookedValue = generateProgressBar(
                data.color_code,
                data.total_room,
                data.total_room_booked
              );
              return (
                <Box
                  key={index}
                  display={"flex"}
                  flexDirection={"column"}
                  width={100 / props.occupancy.data.details.length + "%"}
                  justifyContent="center"
                  alignItems="center"
                  height={"200px"}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    width={"100%"}
                    height={"100%"}
                    position={"relative"}
                  >
                    <LinearProgress
                      variant="determinate"
                      value={occupiedValue}
                      color="primary"
                      sx={{
                        borderRadius: 2,
                        backgroundColor: darken(data.color_code, 0.7),
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: data.color_code,
                          transform: `translateY(${
                            100 - occupiedValue
                          }%)!important`,
                        },
                        height: "100%",
                        width: "50%",
                        position: "absolute",
                      }}
                    />
                    <Box
                      width={"7px"}
                      height={"7px"}
                      borderRadius={"50%"}
                      bgcolor={"white"}
                      position={"absolute"}
                      bottom={`${bookedValue - 3}%`}
                    />
                  </Box>
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    textAlign={"center"}
                    height={"10%"}
                    marginTop={2}
                  >
                    {data.room_type_name.split(" ")[0]}
                  </Typography>
                </Box>
              );
            })}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

const RoomBookingComponent = (props: {
  occupancy: OccupancySetState;
  pieChartData: PieChartDatasetState;
  pieDay: string;
}) => {
  const theme = useTheme();

  const totalRooms = props.occupancy.data.total_room;
  const occupiedRooms = props.occupancy.data.total_occupied_room;

  const availableRooms =
    totalRooms - occupiedRooms < 0 ? 0 : totalRooms - occupiedRooms;

  let occupiedPercentage = parseFloat(
    (
      (props.occupancy.data.total_occupied_room /
        props.occupancy.data.total_room) *
      100
    ).toFixed(2)
  );

  let availablePercentage =
    occupiedPercentage >= 100
      ? 0
      : parseFloat((100 - occupiedPercentage).toFixed(2));

  useEffect(() => {
    let occupiedLabel = document.createElement("div");
    let availableLabel = document.createElement("div");

    let labelStyle =
      "padding: 5px 10px; border: 2px solid white; border-radius: 5px; overflow: hidden; font-size: 0.75rem; position: absolute;";
    if (availablePercentage > 20) {
      availableLabel.style.cssText =
        labelStyle + "background: #3463cb; top: 40%; left: calc(50% + 4vw);";
    } else {
      availableLabel.style.cssText =
        labelStyle + "background: #3463cb;  top: 50%; left: 50%;";
    }

    if (occupiedPercentage > 20) {
      occupiedLabel.style.cssText =
        labelStyle + "background: #d33913; top: 70%; left: 5vw; ";
    } else {
      occupiedLabel.style.cssText =
        labelStyle + "background: #d33913;  top: 40%; left: 5vw;";
    }
    availableLabel.innerHTML = availablePercentage + "%";
    occupiedLabel.innerHTML = occupiedPercentage + "%";

    document.getElementById("pie" + props.pieDay)?.appendChild(availableLabel);
    document.getElementById("pie" + props.pieDay)?.appendChild(occupiedLabel);
  }, [availablePercentage, occupiedPercentage, props.pieDay]);

  return (
    <Grid item xs={12} sm={12} md={4}>
      <Accordion
        defaultExpanded
        disableGutters
        variant="outlined"
        sx={{
          color: "white",
          width: "100%",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#FFFF" }} />}
          sx={{
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Typography variant="h4">Room Booking</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            flex: 1,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
            padding={2}
          >
            <Grid item xs={12}>
              <Typography variant="h4">
                Total: {props.occupancy.data.total_occupied_room}
                {props.occupancy.data.total_room > 0 &&
                  ` (${occupiedPercentage}%)`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              maxHeight={"200px"}
              display="flex"
              justifyContent={"center"}
              position="relative"
              id={"pie" + props.pieDay}
            >
              <Pie
                data={{
                  labels: ["Available", "Occupied"],
                  datasets: [
                    {
                      data: [
                        availableRooms,
                        props.occupancy.data.total_occupied_room,
                      ],
                      backgroundColor: ["#3463cb", "#d33913"],
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      labels: {
                        color: "lightgrey",
                      },
                    },
                  },
                }}
              ></Pie>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

const RoomDetailsComponent = (props: {
  occupancy: OccupancySetState;
  pieChartData: PieChartDatasetState;
  accordionState: boolean;
  toggleAccordion: VoidFunction;
}) => {
  const theme = useTheme();
  return (
    <Grid item xs={12} sm={12} md={4}>
      <Accordion
        variant="outlined"
        disableGutters
        sx={{
          color: "white",
          width: "100%",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#FFFF" }} />}
          sx={{
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Typography variant="h4">Room Details</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            flex: 1,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
            padding={2}
          >
            <Grid item xs={12}>
              <Pie
                data={props.pieChartData.chartState.data}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              ></Pie>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} textAlign={"right"}>
              <Button
                variant="contained"
                sx={{ borderRadius: 2 }}
                onClick={props.toggleAccordion}
              >
                Details
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                expanded={props.accordionState}
                sx={{
                  color: "white",
                  backgroundColor: "#2a2a2a",
                  borderRadius: 2,
                }}
              >
                <AccordionSummary>
                  <Typography variant="h4">Room Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <PlatformStatisticsDetailsAccordianDetails
                    state={props.pieChartData}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h4">Stayed Guest</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h5"
                fontWeight={"bold"}
                sx={{
                  backgroundColor: "#2a2a2a",
                  padding: 1,
                  borderRadius: 2,
                }}
              >
                {props.occupancy.data.guest_stayed} Pax
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h5"
                fontWeight={"bold"}
                sx={{
                  backgroundColor: "#2a2a2a",
                  padding: 1,
                  borderRadius: 2,
                  width: "100%",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {formatToPresentableNumber(
                  props.occupancy.data.stayed_total_revenue.toString()
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h4">Lounge Guests</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h5"
                fontWeight={"bold"}
                sx={{
                  backgroundColor: "#2a2a2a",
                  padding: 1,
                  borderRadius: 2,
                }}
              >
                {props.occupancy.data.lounge_guests} Pax
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h5"
                fontWeight={"bold"}
                sx={{
                  backgroundColor: "#2a2a2a",
                  padding: 1,
                  borderRadius: 2,
                  width: "100%",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {formatToPresentableNumber(
                  props.occupancy.data.lounge_revenue.toString()
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h4">No Show</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h5"
                fontWeight={"bold"}
                sx={{
                  backgroundColor: "#2a2a2a",
                  padding: 1,
                  borderRadius: 2,
                }}
              >
                {props.occupancy.data.no_show_guests} Pax
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h5"
                fontWeight={"bold"}
                sx={{
                  backgroundColor: "#2a2a2a",
                  padding: 1,
                  borderRadius: 2,
                  width: "100%",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {formatToPresentableNumber(
                  props.occupancy.data.no_show_total.toString()
                )}
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

const RevenueOverviewComponent = (props: { revenue: RevenueBarState }) => {
  const theme = useTheme();
  return (
    <Grid item xs={12} sm={12} md={4} padding={1}>
      <OverviewGraph data={props.revenue.data} />
      <Accordion
        sx={{
          bgcolor: theme.palette.background.paper,
          color: "white",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#FFFF" }} />}
        >
          <Grid container gap={1}>
            <Stack direction="column" flex={1} spacing={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <BedIcon sx={{ color: revenueColorCode.room }} />
                <Typography variant="h5" fontWeight={"bold"} color={"grey"}>
                  Room
                </Typography>
              </Stack>
              <Typography variant="h5" fontWeight={"bold"}>
                {formatToPresentableNumber(props.revenue.data.room_revenue)}
              </Typography>
            </Stack>
            <Stack direction="column" flex={1} spacing={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Weekend sx={{ color: revenueColorCode.lounge }} />
                <Typography variant="h5" fontWeight={"bold"} color={"grey"}>
                  Lounge
                </Typography>
              </Stack>
              <Typography variant="h5" fontWeight={"bold"}>
                {formatToPresentableNumber(props.revenue.data.lounge_revenue)}
              </Typography>
            </Stack>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            <Grid item xs={1}>
              <ShowerIcon sx={{ color: revenueColorCode.shower }} />
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h5" fontWeight={"bold"} color={"grey"}>
                Shower
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <PointOfSaleIcon sx={{ color: revenueColorCode.pos }} />
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h5" fontWeight={"bold"} color={"grey"}>
                Merch
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <DoorFront sx={{ color: revenueColorCode.locker }} />
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h5" fontWeight={"bold"} color={"grey"}>
                Locker
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" fontWeight={"bold"}>
                {formatToPresentableNumber(props.revenue.data.shower_revenue)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" fontWeight={"bold"}>
                {formatToPresentableNumber(props.revenue.data.pos_revenue)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" fontWeight={"bold"}>
                {formatToPresentableNumber(props.revenue.data.locker_revenue)}
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

const RoomSalesComponent = (props: { item: RoomSales }) => {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={4}
      padding={1}
      // sx={{ flexShrink: 0, flexBasis: "auto" }}
    >
      <Stack
        direction={"column"}
        spacing={1}
        padding={2}
        bgcolor={theme.palette.background.paper}
        borderRadius={"10px"}
      >
        <Box>
          <Typography variant="h4" color={"grey"}>
            Total
          </Typography>
          <Typography variant="h1">
            {" "}
            {Number(props.item.total_sales).toLocaleString?.("en-MY", {
              style: "currency",
              currency: "MYR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5" fontWeight={"bold"} color={"grey"}>
            Month to Date
          </Typography>
          <Typography variant="h4">
            {" "}
            {Number(props.item.month_to_date_sales).toLocaleString?.("en-MY", {
              style: "currency",
              currency: "MYR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5" fontWeight={"bold"} color={"grey"}>
            Average Sales
          </Typography>
          <Typography variant="h4">
            {" "}
            {Number(props.item.average_sales).toLocaleString?.("en-MY", {
              style: "currency",
              currency: "MYR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Box>
      </Stack>
    </Grid>
  );
};

const OverviewGraph = (props: { data: RoomBookingRevenueResponse }) => {
  const theme = useTheme();
  const [convertedValue, setConvertedValue] = useState<
    { colorCode: string; value: string }[]
  >([]);

  useEffect(() => {
    const sumOfValue =
      parseFloat(props.data.room_revenue) +
      parseFloat(props.data.pos_revenue) +
      parseFloat(props.data.locker_revenue) +
      parseFloat(props.data.shower_revenue);

    if (sumOfValue > 0) {
      setConvertedValue([
        {
          colorCode: revenueColorCode.room,
          value:
            (
              (parseFloat(props.data.room_revenue) / sumOfValue) *
              100
            ).toString() + "%",
        },
        {
          colorCode: revenueColorCode.shower,
          value:
            (
              (parseFloat(props.data.shower_revenue) / sumOfValue) *
              100
            ).toString() + "%",
        },
        {
          colorCode: revenueColorCode.pos,
          value:
            (
              (parseFloat(props.data.pos_revenue) / sumOfValue) *
              100
            ).toString() + "%",
        },
        {
          colorCode: revenueColorCode.locker,
          value:
            (
              (parseFloat(props.data.locker_revenue) / sumOfValue) *
              100
            ).toString() + "%",
        },
      ]);
    }
  }, [props.data]);

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      width={"100%"}
      height={"15px"}
      bgcolor={theme.palette.background.default}
      borderRadius={"5px"}
      marginY={1}
    >
      {convertedValue.map((value, index) =>
        parseFloat(value.value) > 0 ? (
          <Grow
            key={index}
            in={convertedValue.length > 0}
            style={{ transformOrigin: "0 0 0" }}
            timeout={index * 1500 + 1000}
          >
            <Box
              flexGrow={1}
              flexShrink={0}
              width={value.value}
              height={"100%"}
              bgcolor={value.colorCode}
              marginRight={"-5px"}
              borderRadius={"5px"}
              zIndex={convertedValue.length - index}
            />
          </Grow>
        ) : null
      )}
    </Box>
  );
};

const EndShiftModal = (props: {
  open: boolean;
  handleCloseModal: VoidFunction;
  currentShift: CurrentShift;
  setCurrentShift: React.Dispatch<React.SetStateAction<CurrentShift>>;
}) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const account_id = useSelector((state: RootState) => state.user.accountId);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [debitAmount, setDebitAmount] = useState<number>(0);
  const [overflowShortageOption, setOverflowShortageOption] =
    useState<string>("None");

  const [overflowShortageAmount, setOverflowShortageAmount] =
    useState<number>();
  const [endShiftRemarks, setEndShiftRemarks] = useState<string>();

  const endShift = () => {
    if (overflowShortageOption !== "None" && !overflowShortageAmount) {
      Notification.failed("Please fill up Overflow / Shortage Amount");
      return;
    }

    if (overflowShortageAmount && !endShiftRemarks) {
      Notification.failed("Please fill up Remarks");
      return;
    }

    const apiUrl = `${BASE_API}/accounts/shift/check-out-shift/`;

    const formData = {
      shift_id: props.currentShift.shiftId,
      timestamp: new Date(),
      overflow_shortage:
        overflowShortageOption === "Overflow"
          ? overflowShortageAmount
          : overflowShortageOption === "Shortage" && overflowShortageAmount
          ? overflowShortageAmount * -1
          : 0,
      end_shift_remarks: endShiftRemarks,
      cash: "0",
    };

    axiosInstance
      .post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        Notification.success(res.data.msg);
        props.setCurrentShift(currentShiftInitialValue);
        props.handleCloseModal();
      })
      .catch((res) => Notification.failed("Staff Already Checked Out"));
  };

  useEffect(() => {
    if (!token) return;
    const fetchTeamCash = async () => {
      setIsLoading(true);
      await axiosInstance
        .get(`${BASE_API}/accounts/shift/cash-amount/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const data = response.data.data.filter(
            (staff: any) => staff.staff.account_id === account_id
          );
          setDebitAmount(
            data.length > 0
              ? parseFloat(parseFloat(data[0].total_cash).toFixed(2))
              : 0
          );
          setIsLoading(false);
        });
    };
    fetchTeamCash();
  }, [token]);

  return (
    <Modal open={props.open} onClose={props.handleCloseModal}>
      <Box
        width={"700px"}
        border={`3px solid ${theme.palette.primary.main}`}
        alignItems={"center"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
          borderRadius: 1,
        }}
      >
        <ManagementModalHeader
          title={"End Shift Cash Overflow/Shortage?"}
          handleModalClose={props.handleCloseModal}
        />
        <Stack
          width={"100%"}
          direction={"column"}
          paddingX={3}
          paddingY={5}
          spacing={2}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant="h4" width={"30%"}>
              End Shift Cash
            </Typography>
            <TextField
              type="number"
              variant="standard"
              value={debitAmount}
              sx={{
                width: "70%",
              }}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant="h4" width={"30%"}>
              Any Overflow/Shortage?
            </Typography>
            <Select
              variant="standard"
              value={overflowShortageOption}
              onChange={(event) => {
                setOverflowShortageOption(event.target.value);
                if (event.target.value === "None") {
                  setOverflowShortageAmount(0);
                  setEndShiftRemarks("");
                }
              }}
              sx={{
                width: "70%",
              }}
            >
              <MenuItem value={"None"}>None</MenuItem>
              <MenuItem value={"Overflow"}>Overflow</MenuItem>
              <MenuItem value={"Shortage"}>Shortage</MenuItem>
            </Select>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant="h4" width={"30%"}>
              Overflow/Shortage Amount
            </Typography>
            <TextField
              type="number"
              variant="standard"
              value={overflowShortageAmount}
              disabled={overflowShortageOption === "None"}
              onChange={(event) =>
                setOverflowShortageAmount(parseFloat(event.target.value))
              }
              inputProps={{ min: 0 }}
              sx={{
                width: "70%",
              }}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant="h4" width={"30%"}>
              End Shift Remarks
            </Typography>
            <TextField
              variant="standard"
              value={endShiftRemarks}
              disabled={overflowShortageOption === "None"}
              onChange={(event) => setEndShiftRemarks(event.target.value)}
              sx={{ width: "70%" }}
            />
          </Stack>
        </Stack>
        <Box
          display={"flex"}
          height={"50px"}
          bgcolor={theme.palette.background.paper}
          justifyContent={"end"}
          padding={1}
          borderTop={2}
          borderColor={theme.palette.primary.main}
        >
          <Button variant={"outlined"} onClick={endShift}>
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

function removeLastTwoSeconds(timeString: string): string {
  const timeArray = timeString.split(":");

  // Check if the timeString has seconds
  if (timeArray.length === 3) {
    // Remove the last two characters (seconds) from the third element in the array
    timeArray[2] = timeArray[2].slice(0, -2);
  }

  // Join the array back into a string
  return timeArray.join(":").replace(/:$/, "");
}

function checkTime30Minutes(timeString: string, comparedTime: string) {
  const inputTime = new Date(timeString);

  const currentTime = new Date(comparedTime);

  const timeDifference = currentTime.getTime() - inputTime.getTime();

  return timeDifference >= 30 * 60 * 1000;
}
