import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import BASE_API from "../constants/api";
import { useNavigate } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/store";
import { setUserData, removeUserData } from "../redux/authentication/userSlice";

// types and interfaces
import { UserState } from "../redux/authentication/userSlice";
import { Token } from "../models/authentication/tokenInterface";
import { decode } from "punycode";

export default function useAuthentication() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state: RootState) => state.user.accessToken);

  useEffect(() => {
    let interval = setInterval(() => {
      if (token) {
        updateToken();
      }
    }, 9 * 60 * 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const checkTokenExpired = (token: string): boolean => {
    const tokenData = JSON.parse(atob(token.split(".")[1]));
    const expirationTime = tokenData.exp * 1000;
    const currentTime = Date.now();

    return expirationTime < currentTime;
  };

  const updateToken = () => {
    const localStorageData = localStorage.getItem("refresh");
    const parsedData = localStorageData ? JSON.parse(localStorageData) : null;

    const apiUrlParams = new URLSearchParams();
    apiUrlParams.append("refresh", parsedData);

    axios
      .post(`${BASE_API}/refresh-token/`, apiUrlParams)
      .then((res) => {
        if (res.status !== 200) return;
        setUserDataToSlice(res.data.access, res.data.refresh);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const isLoggedIn = async (): Promise<boolean> => {
    if (token && !checkTokenExpired(token)) return true;

    const localStorageData = localStorage.getItem("refresh");
    const parsedData = localStorageData ? JSON.parse(localStorageData) : null;

    if (!parsedData) return false;

    const apiUrlParams = new URLSearchParams();
    apiUrlParams.append("refresh", parsedData);

    try {
      const res = await axios.post(`${BASE_API}/refresh-token/`, apiUrlParams);
      if (res.status !== 200) return false;

      setUserDataToSlice(res.data.access, res.data.refresh);
      return true;
    } catch (err) {
      // alert(err)
      console.log(err);
      return false;
    }
  };

  const setUserDataToSlice = (token: string, refresh: string) => {
    const decodedToken: Token = jwtDecode(token);

    const userData: UserState = {
      accountId: decodedToken.account_id,
      name: decodedToken.name,
      username: decodedToken.username,
      email: decodedToken.email,
      role: decodedToken.role,
      permissions: decodedToken.permissions,
      accessToken: token,
      lotId: decodedToken.lot,
    };
    dispatch(setUserData(userData));
    localStorage.setItem("refresh", JSON.stringify(refresh));
  };

  const logout = () => {
    localStorage.removeItem("refresh");
    dispatch(removeUserData());

    navigate("/login");
  };

  return {
    isLoggedIn,
    setUserDataToSlice,
    logout,
  };
}
