import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useEffect, useState } from "react";
import {
  INewAccountRole,
  INewPermission,
} from "../../../models/accounts/accountInterface";
import { useFormik } from "formik";
import axiosInstance from "../../../constants/axiosConfig";
import Notification from "../../../utils/notificationConfig";
import { ArrowBackIos, Search } from "@mui/icons-material";

const initialRolesPermissionData: INewAccountRole = {
  roleId: "",
  roleName: "",
  rolePermissions: [],
};

interface AddEditRolesPermissionProps {
  selectedRole: INewAccountRole;
  setRoles: React.Dispatch<React.SetStateAction<INewAccountRole[]>>;
  handleBack: VoidFunction;
}

const AddEditRolesPermission = (props: AddEditRolesPermissionProps) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [selectedRole, setSelectedRole] = useState<INewAccountRole>(
    initialRolesPermissionData
  );

  const [permissions, setPermissions] = useState<INewPermission[]>([]);

  const [searchFilter, setSearchFilter] = useState<string>("");

  useEffect(() => {
    if (props.selectedRole.roleId) {
      setSelectedRole(props.selectedRole);
    } else {
      setSelectedRole(initialRolesPermissionData);
    }
  }, [props.selectedRole]);

  useEffect(() => {
    const apiUrl = "/accounts/permission/get-all-permissions";

    axiosInstance
      .get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setPermissions(res.data.data);
        }
      })
      .catch((res) => {
        Notification.failed(res.response.data.msg);
      });
  }, []);

  const formik = useFormik({
    initialValues: selectedRole,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      const apiUrl = selectedRole.roleId
        ? "/accounts/role/update-role/"
        : "/accounts/role/create-role/";

      if (selectedRole.roleId) {
        const formData = {
          role_id: values.roleId,
          role_new_name: values.roleName,
          role_permission: values.rolePermissions,
        };
        axiosInstance
          .put(apiUrl, formData, config)
          .then((res) => {
            Notification.success(res.data.message);
            props.handleBack();
            props.setRoles((prevRoles) => {
              const removedRole = prevRoles.find(
                (role) => role.roleId === props.selectedRole?.roleId
              );

              if (removedRole) {
                const newRole = prevRoles.filter(
                  (account) => account.roleId !== props.selectedRole?.roleId
                );

                return [
                  {
                    ...values,
                  },
                  ...newRole,
                ];
              }
              return prevRoles;
            });
          })
          .catch((res) => {
            Notification.failed(res.response.data.msg);
          })
          .finally(() => actions.setSubmitting(false));
        return;
      }

      const formData = {
        role_id: values.roleId,
        role_name: values.roleName,
        role_permission: values.rolePermissions,
      };

      axiosInstance
        .post(apiUrl, formData, config)
        .then((res) => {
          Notification.success(res.data.message);
          props.handleBack();
          props.setRoles((prevRoles) => {
            const newRole: INewAccountRole = {
              ...values,
              roleId: res.data.id,
            };
            return [newRole, ...prevRoles];
          });
        })
        .catch((res) => {
          Notification.failed(res.response.data.msg);
        })
        .finally(() => actions.setSubmitting(false));
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction={"column"} spacing={4}>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Button variant="text" onClick={props.handleBack}>
            <ArrowBackIos fontSize="small" />
            BACK
          </Button>
          <Typography variant="h3">
            {selectedRole.roleId !== ""
              ? `Edit ${selectedRole.roleName}`
              : "New Role"}
          </Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={3}>
          <Typography variant="h4" width={"180px"}>
            Role Name
          </Typography>
          <TextField
            fullWidth
            variant="standard"
            name="roleName"
            value={formik.values.roleName}
            onChange={formik.handleChange}
            inputProps={{
              style: { textTransform: "none" },
            }}
          />
        </Stack>
        <Typography variant="h3">Permission Settings</Typography>
        <Stack direction={"column"} width={"100%"} spacing={1}>
          <TextField
            fullWidth
            variant="standard"
            value={searchFilter}
            onChange={(event) => setSearchFilter(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search sx={{ color: "white" }} />
                </InputAdornment>
              ),
            }}
            inputProps={{
              style: { textTransform: "none" },
            }}
          />
          <Grid container spacing={1}>
            {permissions
              .filter((permission) =>
                permission.permissionName
                  .toLowerCase()
                  .includes(searchFilter.toLowerCase())
              )
              .map((permission) => (
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    padding={2}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    bgcolor={theme.palette.background.default}
                  >
                    <Typography variant="h4">
                      {permission.permissionName}
                    </Typography>
                    <Switch
                      checked={formik.values.rolePermissions.includes(
                        permission.codeName
                      )}
                      onChange={(event) => {
                        if (event.target.checked) {
                          formik.setFieldValue("rolePermissions", [
                            ...formik.values.rolePermissions,
                            permission.codeName,
                          ]);
                        } else {
                          formik.setFieldValue(
                            "rolePermissions",
                            formik.values.rolePermissions.filter(
                              (rolePermission) =>
                                rolePermission !== permission.codeName
                            )
                          );
                        }
                      }}
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Stack>
        <Box display={"flex"} justifyContent={"end"}>
          <Button variant="outlined" type="submit" sx={{ width: "80px" }}>
            {formik.isSubmitting ? (
              <CircularProgress size={24} color="primary" />
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default AddEditRolesPermission;
