import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import axiosInstance from "../../../constants/axiosConfig";
import Notification from "../../../utils/notificationConfig";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { INewAccountRole } from "../../../models/accounts/accountInterface";
import { Settings } from "@mui/icons-material";
import AddEditRolesPermission from "./AddEditRolesPermission";

const initialRolesPermissionData: INewAccountRole = {
  roleId: "",
  roleName: "",
  rolePermissions: [],
};

const RolesAndPermissionTab = () => {
  const theme = useTheme();
  const user = useSelector((state: RootState) => state.user);
  const config = {
    headers: { Authorization: `Bearer ${user.accessToken}` },
  };

  const [roles, setRoles] = useState<INewAccountRole[]>([]);
  const [selectedRole, setSelectedRole] = useState<INewAccountRole>(
    initialRolesPermissionData
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isAddEditRolesPermissionOpen, setIsAddEditRolesPermissionOpen] =
    useState<boolean>(false);

  const handleOpenAddEditRoles = (role?: INewAccountRole) => {
    setIsAddEditRolesPermissionOpen(true);
    if (role) setSelectedRole(role);
  };

  const handleCloseAddEditRoles = () => {
    setSelectedRole(initialRolesPermissionData);
    setIsAddEditRolesPermissionOpen(false);
  };

  const getPageData = () => {
    setIsLoading(true);
    axiosInstance
      .get("/accounts/role/get-all-roles", config)
      .then((res) => {
        if (res.status === 200) {
          setRoles(res.data.data);
          return;
        }
      })
      .catch((e) => {
        Notification.failed(e.response.data.msg);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPageData();
  }, []);
  return (
    <Box padding={5}>
      {!isAddEditRolesPermissionOpen ? (
        <>
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"space-between"}
          >
            <Typography variant="h2">Roles & Permission</Typography>
            <Button
              variant={"contained"}
              onClick={() => handleOpenAddEditRoles()}
              sx={{
                width: "150px",
                backgroundColor: theme.palette.background.paper,
                color: "white",
                border: 1,
                borderColor: theme.palette.primary.main,
              }}
            >
              Create New
            </Button>
          </Stack>
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            padding={1}
            spacing={1}
          >
            {isLoading ? (
              <CircularProgress color="primary" />
            ) : (
              roles.map((role) => (
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                  bgcolor={theme.palette.background.default}
                  padding={2}
                >
                  <Stack direction={"column"}>
                    <Typography variant="h4">{role.roleName}</Typography>
                    <Typography variant="h5">
                      Access to {role.rolePermissions.length} modules
                    </Typography>
                  </Stack>
                  <IconButton onClick={() => handleOpenAddEditRoles(role)}>
                    <Settings color="primary" />
                  </IconButton>
                </Box>
              ))
            )}
          </Stack>
        </>
      ) : (
        <AddEditRolesPermission
          handleBack={handleCloseAddEditRoles}
          selectedRole={selectedRole}
          setRoles={setRoles}
        />
      )}
    </Box>
  );
};

export default RolesAndPermissionTab;
