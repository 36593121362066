import { Box, Button, Modal, Stack, Typography, useTheme } from "@mui/material";
import { BookingResult, BookingStatus } from "../../pages/booking/interface";
import ManagementModalHeader from "../management/ManagementModalHeader";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  DatePicker,
  DatePickerSlotsComponentsProps,
  LocalizationProvider,
  TimePicker,
  TimePickerSlotsComponentsProps,
} from "@mui/x-date-pickers";
import { format, isSameDay } from "date-fns";
import { ArrowRightAlt } from "@mui/icons-material";
import { useState } from "react";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import { store } from "../../redux/store";
import AuthModal from "../account/AuthModal";

const EditCheckInModal = (props: {
  open: boolean;
  handleClose: VoidFunction;
  guestFolioData: BookingResult;
}) => {
  const theme = useTheme();
  const token = store.getState().user.accessToken;

  const latestBookingStatusCheckIn = props.guestFolioData.bookingStatus.filter(
    (status) => status.isLatest === true
  )[0];
  const latestStatusCheckInDatetime = new Date(
    latestBookingStatusCheckIn.checkInDatetime
  );

  const [newCheckInTime, setNewCheckInTime] = useState<Date | null>(null);
  const [openValidationModal, setOpenValidationModal] =
    useState<boolean>(false);
  
  const [rescheduleLoad, setRescheduleLoad] = useState<boolean>(false);
  const handleDateOnChange = (date: Date): void => {
    setNewCheckInTime(date);
  };

  const handleOpenValidationModal = () => {
    setOpenValidationModal(true);
  };

  const handleCloseValidationModal = () => {
    setOpenValidationModal(false);
  };

  const handleChangeCheckSameDay = () => {
    if (newCheckInTime !== null) {
      if (isSameDay(latestStatusCheckInDatetime, newCheckInTime)) {
        handleChangeCheckInTime();
      } else {
        handleOpenValidationModal();
      }
    }
  };

  const handleLookupLatestStatus = (guestFolioData: BookingResult) => {
    const isLatestGuestFolioData = guestFolioData.bookingStatus.filter(
      (status: BookingStatus) => status.isLatest === true
    );

    if (isLatestGuestFolioData) {
      const status =
        isLatestGuestFolioData[isLatestGuestFolioData.length - 1].bookingStatus;
      return status;
    }

    return "";
  };

  const handleChangeCheckInTime = () => {
    setRescheduleLoad(true);

    const apiUrl = "/bookings/reschedule/";

    const formData = {
      bookingId: props.guestFolioData.bookingId,
      newCheckInDatetime: newCheckInTime,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axiosInstance
      .patch(apiUrl, formData, config)
      .then((res) => {
        Notification.success(res.data.message);
        window.location.reload();
      })
      .catch((res) => Notification.failed(res.response.data.message)).finally(() => {
        setRescheduleLoad(false);
      });
  };

  const datePickerSlotProps: DatePickerSlotsComponentsProps<Date> = {
    layout: {
      sx: {
        ".MuiDateCalendar-root": {
          color: theme.palette.primary.main,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: theme.palette.primary.main,
          border: "1px solid",
          backgroundColor: theme.palette.background.paper,
        },
        ".MuiButtonBase-root": {
          color: theme.palette.primary.main,
        },
        ".MuiTypography-root": {
          color: theme.palette.primary.main,
        },
        ".MuiPickersCalendarHeader-root": {
          color: theme.palette.primary.main,
        },
        ".MuiPickersMonth-monthButton": {
          color: theme.palette.primary.main,
        },
        ".MuiPickersCalendarHeader-label": {
          color: theme.palette.primary.main,
        },
        ".Mui-selected": {
          color: "black",
        },
        ".MuiPickersYear-yearButton": {
          color: theme.palette.primary.main,
        },
      },
    },
    textField: {
      variant: "filled",
      inputProps: {
        style: {
          color: "white",
          borderRadius: 0,
          border: 2,
        },
      },
      sx: {
        backgroundColor: "#232323",
        border: "1px solid #148978",
      },
    },
    openPickerButton: {
      sx: {
        color: "white",
        marginTop: "1.8px",
        zIndex: 1,
      },
    },
    openPickerIcon: {
      sx: {
        width: "1em",
        height: "1em",
        zIndex: 1,
      },
    },
  };

  const timePickerSlotProps: TimePickerSlotsComponentsProps<Date> = {
    textField: {
      variant: "filled",
      inputProps: {
        style: {
          borderRadius: 0,
          border: 1,
        },
      },
      sx: {
        borderRadius: 0,
        backgroundColor: "#232323",
        border: "1px solid #148978",
      },
    },
    openPickerButton: {
      sx: {
        color: "white",
        marginTop: "1.8px",
        zIndex: 1,
      },
    },
    openPickerIcon: {
      sx: {
        width: "1em",
        height: "1em",
        zIndex: 1,
      },
    },
  };

  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <Box
        width={"750px"}
        maxHeight={"600px"}
        border={`3px solid ${theme.palette.primary.main}`}
        alignItems={"center"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
          borderRadius: 1,
        }}
      >
        <ManagementModalHeader
          title={"Edit Check In Time"}
          handleModalClose={props.handleClose}
        />
        <Box
          display={"flex"}
          height={"200px"}
          bgcolor={"black"}
          justifyContent={"start"}
          alignItems={"center"}
          paddingX={2}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack direction={"row"} width={"100%"}>
              <Stack width={"45%"} flexDirection={"column"} spacing={2}>
                <Typography variant="h2">Initial Check In Time</Typography>
                <Stack direction={"row"} width={"100%"} spacing={1}>
                  <DatePicker
                    label="Date"
                    value={latestStatusCheckInDatetime}
                    // onChange={(e) => handleDateOnChange(e as Date)}
                    format="dd MMM yyyy"
                    readOnly={true}
                    slotProps={datePickerSlotProps}
                  />
                  <TimePicker
                    label="Time"
                    value={latestStatusCheckInDatetime}
                    format={"hh:mm a"}
                    ampm={true}
                    readOnly={true}
                    slotProps={timePickerSlotProps}
                  />
                </Stack>
                <Typography variant="h3">
                  {format(latestStatusCheckInDatetime, "dd/MM/yyyy hh:mm a")}
                </Typography>
              </Stack>
              <Box
                display={"flex"}
                width={"10%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <ArrowRightAlt color="primary" sx={{ fontSize: "36px" }} />
              </Box>
              <Stack
                width={"45%"}
                flexDirection={"column"}
                alignItems={"end"}
                spacing={2}
              >
                <Typography variant="h2">New Check In Time</Typography>
                <Stack direction={"row"} width={"100%"} spacing={1}>
                  <DatePicker
                    label="Date"
                    value={newCheckInTime}
                    onChange={(e) => handleDateOnChange(e as Date)}
                    format="dd MMM yyyy"
                    slotProps={datePickerSlotProps}
                    minDate={
                      handleLookupLatestStatus(props.guestFolioData) ===
                      "Booked"
                        ? undefined
                        : latestStatusCheckInDatetime
                    }
                  />
                  <TimePicker
                    label="Time"
                    value={newCheckInTime}
                    onChange={(e) => handleDateOnChange(e as Date)}
                    format={"hh:mm a"}
                    ampm={true}
                    minTime={
                      handleLookupLatestStatus(props.guestFolioData) ===
                      "Booked"
                        ? undefined
                        : latestStatusCheckInDatetime
                    }
                    slotProps={timePickerSlotProps}
                  />
                </Stack>
                {newCheckInTime && (
                  <Typography variant="h3">
                    {format(newCheckInTime, "dd/MM/yyyy hh:mm a")}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </LocalizationProvider>
        </Box>
        <Box
          display={"flex"}
          height={"50px"}
          bgcolor={theme.palette.background.paper}
          justifyContent={"end"}
          padding={1}
          borderTop={2}
          borderColor={theme.palette.primary.main}
        >
          <Stack direction={"row"} spacing={1}>
            <Button variant={"outlined"} onClick={props.handleClose}>
              Cancel
            </Button>
            <Button disabled={rescheduleLoad} variant={"outlined"} onClick={handleChangeCheckSameDay}>
              Confirm
            </Button>
          </Stack>
        </Box>
        <AuthModal
          authenticationModalOpen={openValidationModal}
          functionToRun={handleChangeCheckInTime}
          handleAuthenticationModalOnClose={handleCloseValidationModal}
          requireOtherAdmin={true}
        />
      </Box>
    </Modal>
  );
};

export default EditCheckInModal;
