import {
  Modal,
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Backdrop,
} from '@mui/material';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { Formik, Form } from 'formik';
import theme from '../../assets/theme/theme';
import BASE_API from '../../constants/api';
import { store } from '../../redux/store';
import Notification from '../../utils/notificationConfig';
import ManagementModalFormButton from '../management/ManagementModalFormButton';
import ManagementModalFormTextField from '../management/ManagementModalFormTextField';
import ManagementModalFormDatePicker from '../management/ManagementModalFormDatePicker';
import ManagementModalHeader from '../management/ManagementModalHeader';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { IPromotion, IParsedPromotionValues } from '../../types/promotion';
import { useEffect, useState } from 'react';
import ManagementModalRadioButton from '../management/ManagementModalRadioButton';
import ManagementModalFormNumberField from '../management/ManagementModalFormNumberField';
import ManagementFormSwitchButton from '../management/ManagementFormSwitchButton';

type Props = {
  title: string;
  type: 'add' | 'edit';
  isPromotionModalOpen: boolean;
  handlePromotionModalClose: () => void;
  initialValues?: IPromotion;
  fetchAllPromotions: () => Promise<void>;
};

const defaultInitialPromotionValues: IParsedPromotionValues = {
  promotionId: '',
  promoCode: '',
  details: {
    unit: '%',
    value: '0',
  },
  promoStartDatetime: new Date().toString(),
  promoEndDatetime: new Date().toString(),
  seats: 0,
  stackable: false,
  memberOnly: false,
  visibleAtWebsite: false,
};

const PromotionModal = ({
  title,
  type,
  isPromotionModalOpen,
  handlePromotionModalClose,
  initialValues,
  fetchAllPromotions,
}: Props) => {
  const [selectedPromotionValues, setSelectedPromotionValues] =
    useState<IParsedPromotionValues>(defaultInitialPromotionValues);

  const handleSelectedPromotionObjChange = (promotion: IPromotion) => {
    console.log(promotion);
    setSelectedPromotionValues({
      promotionId: promotion.promotionId,
      promoCode: promotion.promoCode,
      details: {
        // *parse the details string to get the unit and value
        unit: promotion.details.includes('%') ? '%' : 'RM',
        value: promotion.details.includes('%')
          ? Number(promotion.details.replace('%', '')).toString()
          : parseFloat(promotion.details).toFixed(2).toString(),
      },
      promoStartDatetime: promotion.promoStartDatetime,
      promoEndDatetime: promotion.promoEndDatetime,
      seats: promotion.seats,
      stackable: promotion.stackable,
      memberOnly: promotion.memberOnly,
      visibleAtWebsite: promotion.visibleAtWebsite,
    });
  };

  useEffect(() => {
    if (!initialValues || type === 'add') return;
    handleSelectedPromotionObjChange(initialValues);
  }, [initialValues, type]);

  const [databaseData, setDatabaseData] = useState([]);

  function dateIsValid(date: string) {
    return !Number.isNaN(new Date(date).getTime());
  }

  return (
    <Modal
      open={isPromotionModalOpen}
      onClose={() => handlePromotionModalClose()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
    >
      <Box
        width={'35vw'}
        height={'35vw'}
        minHeight={'38em'}
        minWidth={'38em'}
        border={`3px solid ${theme.palette.primary.main}`}
        position={'absolute'}
        margin={'auto'}
        sx={{
          inset: '0',
          backgroundColor: 'black',
        }}
      >
        <ManagementModalHeader
          title={title}
          handleModalClose={handlePromotionModalClose}
        />

        <Formik
          enableReinitialize
          initialValues={selectedPromotionValues}
          onSubmit={async ({
            promotionId,
            promoCode,
            details,
            promoStartDatetime,
            promoEndDatetime,
            seats,
            stackable,
            memberOnly,
            visibleAtWebsite,
          }) => {
            const formData = new FormData();

            formData.append('promoCode', promoCode);
            formData.append(
              'details',
              details.unit === 'RM'
                ? `${details.value}`
                : `${details.value}${details.unit}`
            ); // e.g. 10% or RM10
            if (dateIsValid(promoStartDatetime)) {
              const startDate = new Date(promoStartDatetime);
              startDate.setHours(0, 0, 0, 0);
              formData.append('promoStartDatetime', startDate.toISOString());
            }
            if (dateIsValid(promoEndDatetime)) {
              const endDate = new Date(promoEndDatetime);
              endDate.setHours(23, 59, 59, 0);
              formData.append('promoEndDatetime', endDate.toISOString());
            }
            formData.append('seats', parseInt(seats.toString()).toString());
            formData.append('stackable', stackable.toString());
            formData.append('memberOnly', memberOnly.toString());
            formData.append('visibleAtWebsite', visibleAtWebsite.toString());

            const token = store.getState().user.accessToken;

            if (type === 'add') {
              await axios
                .post(`${BASE_API}/promotions/`, formData, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((res: AxiosResponse) => {
                  Notification.success(res.data.message);
                  fetchAllPromotions();
                  handlePromotionModalClose();
                })
                .catch((err: AxiosError) => {
                  Notification.failed(err.message);
                });

              return;
            }

            if (type === 'edit') {
              await axios
                .put(`${BASE_API}/promotions/${promotionId}/`, formData, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((res: AxiosResponse) => {
                  Notification.success(res.data.message);
                  fetchAllPromotions();
                  // fetchAllMerch(); use material react table crud feature to add the item to the table in the frontend instead of get it again in the backend
                  handlePromotionModalClose();
                })
                .catch((err: AxiosError) => {
                  Notification.failed(err.message);
                });

              return;
            }
          }}
        >
          {({
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            setFieldValue,
          }) => (
            <Form
              onSubmit={handleSubmit}
              style={{
                height: '32vw',
                minHeight: '35em',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Grid container paddingX={3}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  marginBottom={3}
                >
                  <Typography variant={'h2'}>
                    {type === 'add' ? 'New Promotion' : 'Edit Promotion'}
                  </Typography>
                </Grid>
                <ManagementModalFormTextField
                  label={'Promo Code'}
                  value={values.promoCode}
                  name={'promoCode'}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <ManagementModalFormTextField
                  label={'Discount Amt'}
                  value={values.details.value}
                  name={'details.value'}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  textFieldType={'number'}
                  startAdornment={
                    <Typography fontWeight={600} paddingLeft={'1.5em'}>
                      {values.details.unit}
                    </Typography>
                  }
                  endAdornment={
                    <RadioGroup
                      sx={{ flexWrap: 'nowrap' }}
                      row
                      value={values.details.unit.includes('%') ? '%' : 'RM'}
                      name={'details.unit'}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value={'%'}
                        control={
                          <Radio size={'small'} sx={{ color: 'white' }} />
                        }
                        label={'%'}
                      />
                      <FormControlLabel
                        value={'RM'}
                        control={
                          <Radio size={'small'} sx={{ color: 'white' }} />
                        }
                        label={'MYR'}
                      />
                    </RadioGroup>
                  }
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <ManagementModalFormDatePicker
                    dateValue={values.promoStartDatetime}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    fieldName={'promoStartDatetime'}
                    label={'Start Date'}
                  />
                  <ManagementModalFormDatePicker
                    dateValue={values.promoEndDatetime}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    fieldName={'promoEndDatetime'}
                    label={'End Date'}
                  />
                </LocalizationProvider>

                <ManagementModalRadioButton
                  label={'Stackable'}
                  RadioGroupValue={values.stackable}
                  name={'stackable'}
                  RadioLabel1={'Stackable'}
                  RadioLabel2={'Non-Stackable'}
                  RadioValue1={true}
                  RadioValue2={false}
                  handleChange={handleChange}
                />
                <ManagementModalRadioButton
                  label={'Member-Only'}
                  RadioGroupValue={values.memberOnly}
                  name={'memberOnly'}
                  RadioLabel1={'Everyone'}
                  RadioLabel2={'Member-Only'}
                  RadioValue1={false}
                  RadioValue2={true}
                  handleChange={handleChange}
                />
                <ManagementModalFormNumberField<IParsedPromotionValues>
                  label={'Seat'}
                  value={values.seats ? values.seats : 0}
                  name={'seats'}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  textFieldType={'number'}
                  startAdornment={''}
                  setFieldValue={setFieldValue}
                />
                <ManagementFormSwitchButton
                  label={'Visible at Website'}
                  checked={values.visibleAtWebsite}
                  name={'visibleAtWebsite'}
                  handleChange={handleChange}
                />
                <ManagementModalFormButton<IParsedPromotionValues>
                  type={type}
                  isSubmitting={isSubmitting}
                  values={values}
                  initialValues={defaultInitialPromotionValues}
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default PromotionModal;
