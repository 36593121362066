import { useState, useEffect } from 'react';
import { Box, Typography, Stack, TextField, Button, CircularProgress, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import Notification from '../../utils/notificationConfig';
import axiosInstance from '../../constants/axiosConfig';

// Components
import ConfirmationModal from '../global/ConfirmationModal';
import ModalLoading from '../global/loading/ModalLoading';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { removeModalOpen } from '../../redux/modal/modalSlice';
import { RootState } from '../../redux/store';

// types and interfaces
import { RoleDataInterface } from "../../models/accounts/roleInterface";
import { PermissionDataInterface } from '../../models/accounts/permissionInterface';
interface Props {
    roleInfo : RoleDataInterface;
    setUpdateTrigger : (value: boolean) => void;
}

export default function RoleEditModal( { setUpdateTrigger, roleInfo } : Props ) {

    const dispatch = useDispatch();

    const [roleName, setRoleName] = useState<string>("");
    const [permissionData, setPermissionData] = useState<PermissionDataInterface[]>([]);
    const [selectedPermission, setSelectedPermission] = useState<string[]>([]);
    const [checkedPermissions, setCheckedPermissions] = useState<string[]>([]);

    const [fetchLoading, setFetchLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [onUpdate, setOnUpdate] = useState<boolean>(false);
    const [onDelete, setOnDelete] = useState<boolean>(false);

    const token = useSelector((state: RootState) => state.user.accessToken);
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    useEffect(() => {
        getPermissionData();
        setSelectedPermission(roleInfo.rolePermissions);
        setRoleName(roleInfo.roleName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCheckedPermissions(roleInfo.rolePermissions);
    }, [roleInfo.rolePermissions]);

    const getPermissionData = () => {
        axiosInstance.get('/accounts/permission/get-all-permissions', config).then(res => {
            if (res.status === 200) {
                setPermissionData(res.data.data)
                return;
            }
            Notification.failed(res.data.msg);
            dispatch(removeModalOpen());
        }).catch(e => {
            console.log(e);
            Notification.failed(e.response.data.msg);
            dispatch(removeModalOpen());
        }).finally(() => setIsLoading(false));
    }

    const handleCheckBox = (checked: boolean, data: PermissionDataInterface) => {
        const permissions = [...selectedPermission];

        if (checked) {
            setCheckedPermissions((prevChecked) => [...prevChecked, data.codeName]);
            permissions.push(data.codeName);
            setSelectedPermission(permissions);
        } else {
            setCheckedPermissions((prevChecked) =>
                prevChecked.filter((codeName) => codeName !== data.codeName)
            );
            const newList = permissions.filter(e => e !== data.codeName);
            setSelectedPermission(newList);
        }
    };

    const handleUpdate = () => {
        setFetchLoading(true);

        const putData = {
            'role_id' : roleInfo.roleId,
            'role_new_name' : roleName,
            'role_permission' : selectedPermission
        }

        axiosInstance.put('/accounts/role/update-role/', putData, config).then(res => {
            if (res.status === 200) {
                Notification.success('Role info updated')
                setUpdateTrigger(true);
                return;
            }
            Notification.failed(res.data.msg);
        }).catch(e => {
            console.log(e)
            Notification.failed(e.response.data.msg);
        }).finally(() => {
            setFetchLoading(false);
            dispatch(removeModalOpen());
        });
    }

    const handleDelete = () => {
        setFetchLoading(true);

        axiosInstance.delete(`/accounts/role/delete-role/?roleId=${roleInfo.roleId}`, config).then(res => {
            if (res.status === 200) {
                Notification.success('Role deleted');
                setUpdateTrigger(true);
                return;
            }
            Notification.failed(res.data.msg)
        }).catch(e => {
            console.log(e)
            Notification.failed(e.response.data.msg);
        }).finally(() => {
            setFetchLoading(false);
            dispatch(removeModalOpen());
        });
    }

    if (isLoading) {
        return (<ModalLoading />);
    }

    return (
        <Box width={'30rem'}>
            <Typography variant='h5' mb={'20px'}>
                Edit Role
            </Typography>
            <Stack sx={{ marginBottom: '40px' }}>
                <TextField
                    label='Role name'
                    required
                    value={roleName}
                    onChange={e => setRoleName(e.target.value)}
                    sx={{ marginBottom: '40px' }}
                />
                <Typography fontWeight={'bold'}>Permission</Typography>
                <FormGroup>
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap'
                    }}>
                        {permissionData.map((e : PermissionDataInterface, i : number) => {
                            return (
                                <FormControlLabel
                                    key={i}
                                    label={e.permissionName}
                                    control={
                                        <Checkbox
                                            checked={checkedPermissions.includes(e.codeName)}
                                            onChange={(el) => {handleCheckBox(el.target.checked, e)}}
                                        /> 
                                    }
                                />
                            )
                        })}
                    </Box>
                </FormGroup>
            </Stack>
            <Stack
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <Button
                    variant='contained'
                    sx={{ backgroundColor: 'red' }}
                    onClick={() => setOnDelete(true)}
                    disabled={fetchLoading}
                >
                    Delete
                </Button>
                <Box>
                    <Button
                        variant='text'
                        sx={{ marginRight: '10px' }}
                        onClick={() => dispatch(removeModalOpen())}
                        disabled={fetchLoading}
                    >Cancel</Button>
                    <Button
                        variant='contained'
                        onClick={() => {
                            if (!roleName) {
                                Notification.failed('Please input Role name')
                                return;
                            }
                    
                            if (selectedPermission.length === 0) {
                                Notification.failed('Select minimum one permission')
                                return;
                            }

                            setOnUpdate(true);
                        }}
                        disabled={fetchLoading}
                        sx={{ minWidth: '85px' }}
                    >
                        {fetchLoading ? 
                            (<CircularProgress size={24} color="inherit" />) : 
                            ('Update')
                        }
                    </Button>
                </Box>
            </Stack>
            <ConfirmationModal
                title='Are you sure want to delete this role?'
                text=''
                open={onDelete}
                onClose={setOnDelete}
                onAgree={handleDelete}
            />
            <ConfirmationModal
                title='Are you sure want to update role info?'
                text='make sure all inputed data is correct'
                open={onUpdate}
                onClose={setOnUpdate}
                onAgree={handleUpdate}
            />
        </Box>
    )
}