import { Grid, Typography, TextField, Stack, Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import theme from "../../assets/theme/theme";

type Props = {
  label: string;
  name: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  RadioLabel1: string;
  RadioLabel2: string;
  RadioValue1: boolean;
  RadioValue2: boolean;
  RadioGroupValue:boolean;
};

const ManagementModalRadioButton = ({
  label,
  name,
  handleChange,
  RadioLabel1,
  RadioLabel2,
  RadioValue1,
  RadioValue2,
  RadioGroupValue,
}: Props) => {
  return (
    <Stack direction="row" width={"100%"}>
        <Stack
        sx={{
            width:"232px",
            border: `3px solid ${theme.palette.primary.main}`,
            padding: "0.5rem    ",
            height: "3em",
        }}
        >
        <Typography
            variant={"h4"}
            fontWeight={600}
        >
            {label}
        </Typography>
        </Stack>
        <Stack
        sx={{
            height: "fit-content",
            width: "100%",
        }}
        >
        <RadioGroup
            sx={{
            width: "100%",
            height: "3em",
            flexWrap: "nowrap",
            border: `3px solid ${theme.palette.primary.main}`,
            borderLeft: "none",
            paddingX: "1.5em",
            backgroundColor: theme.palette.background.default,
            }}
            row
            value={RadioGroupValue}
            name={name}
            onChange={handleChange} 
        >
            <FormControlLabel value={RadioValue1} control={<Radio size={"small"} sx={{ color: "white" }} />} label={RadioLabel1} />
            <FormControlLabel value={RadioValue2} control={<Radio size={"small"} sx={{ color: "white" }} />} label={RadioLabel2} />
        </RadioGroup>
        </Stack>
    </Stack>
  );
};

export default ManagementModalRadioButton;
