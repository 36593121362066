import {
  Grid,
  Typography,
  TextField,
  Stack,
  Box,
  ButtonBase,
} from "@mui/material";
import React from "react";
import theme from "../../assets/theme/theme";
import { Add, MinimizeOutlined, Remove } from "@mui/icons-material";
import { FormikErrors } from "formik";
import { IMerch } from "../../types/merch";

type Props<T = IMerch> = {
  label: string;
  value: number;
  name: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: any) => void;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  textFieldType?: string;
  lastChild?: boolean;
  placeholder?: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<T>>;
  borderColor?: string;
  withoutLabel?: boolean;
};

const ManagementModalFormNumberField = <T = IMerch,>({
  label,
  value,
  name,
  handleChange,
  handleBlur,
  startAdornment,
  endAdornment,
  textFieldType = "text",
  lastChild = false,
  placeholder,
  setFieldValue,
  borderColor,
  withoutLabel,
}: Props<T>) => {
  return (
    <Stack direction={"row"} width={"100%"}>
      {!withoutLabel && (
        <Box
          display={"flex"}
          border={2}
          borderColor={borderColor ? borderColor : theme.palette.primary.main}
          width={"230px"}
          alignItems={"center"}
          paddingX={1}
        >
          <Typography variant={"h4"} fontWeight={600}>
            {label}
          </Typography>
        </Box>
      )}

      <Stack
        direction={"row"}
        border={2}
        borderColor={borderColor ? borderColor : theme.palette.primary.main}
        width={"100%"}
      >
        <ButtonBase
          onClick={() => {
            if (value > 0) {
              typeof value === "string"
                ? setFieldValue(name, parseFloat(value) - 1)
                : setFieldValue(name, value - 1);
            }
          }}
        >
          <Box
            display={"flex"}
            borderRight={2}
            borderColor={borderColor ? borderColor : theme.palette.primary.main}
            height={"3em"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ aspectRatio: 1 / 1 }}
          >
            <Remove />
          </Box>
        </ButtonBase>
        <TextField
          required
          name={name}
          value={value}
          onChange={({ target: { value } }) => {
            const regex = /^-?\d+(\.\d{0,2})?$/;

            //!value is to allow user to empty the value in number field (if not inputting number will be a bit hard)
            if (regex.test(value) || !value) {
              setFieldValue(name, value);
            }
          }}
          onBlur={handleBlur}
          type={textFieldType}
          fullWidth
          sx={{
            border: "none",
          }}
          InputProps={{
            sx: {
              height: "3em",
              padding: "0",
              backgroundColor: theme.palette.background.default,
              border: "none",
              borderRadius: "0",
              "& .MuiOutlinedInput-notchedOutline": {
                borderLeft: "none",
                borderBottom: lastChild
                  ? `3px solid ${
                      borderColor ? borderColor : theme.palette.primary.main
                    }`
                  : "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: `3px solid ${
                  borderColor ? borderColor : theme.palette.primary.main
                }`,
                borderLeft: "none",
                borderBottom: lastChild
                  ? `3px solid ${
                      borderColor ? borderColor : theme.palette.primary.main
                    }`
                  : "none",
              },

              ...(textFieldType === "number" && {
                "& .MuiInputBase-input.MuiOutlinedInput-input": {
                  MozAppearance: "textfield",
                  appearance: "textfield",
                  margin: 0,
                },
                "& .MuiInputBase-input.MuiOutlinedInput-input::-webkit-inner-spin-button, & .MuiInputBase-input.MuiOutlinedInput-input::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
              }),
            },
            startAdornment: startAdornment || null,
            endAdornment: endAdornment || null,
          }}
          inputProps={{
            style: {
              height: "3em",
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: startAdornment ? "0.5em" : "1.5em",
              paddingRight: endAdornment ? "0.5em" : "1.5em",
              border: "none",
              borderRadius: "0",
              fontWeight: 600,
            },

            placeholder: placeholder || "",
          }}
        />
        <ButtonBase
          onClick={() =>
            typeof value === "string"
              ? setFieldValue(name, parseFloat(value) + 1)
              : setFieldValue(name, value + 1)
          }
        >
          <Box
            display={"flex"}
            borderLeft={2}
            borderColor={borderColor ? borderColor : theme.palette.primary.main}
            height={"3em"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ aspectRatio: 1 / 1 }}
          >
            <Add />
          </Box>
        </ButtonBase>
      </Stack>
    </Stack>
  );
};

export default ManagementModalFormNumberField;
