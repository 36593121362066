import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ManagementModalHeader from "../management/ManagementModalHeader";
import { ArrowDownward, ArrowForward, MoveDown } from "@mui/icons-material";
import { Fragment, useEffect, useState } from "react";
import axiosInstance from "../../constants/axiosConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ILot } from "../../models/payment/PaymentInterface";
import Notification from "../../utils/notificationConfig";
import { RoomDataInterfaceV2 } from "../../models/rooms/roomsInterface";
import { useNavigate } from "react-router-dom";
import RoomBox from "../global/RoomBox";

interface ITransferRooms extends RoomDataInterfaceV2 {
  prevRoomId: string;
  prevRoomCode: string;
  prevRoomZone: string;
  prevRoomColorCode: string;
  prevRoomTypeDetails: string;
}

const BookingTransferModal = (props: {
  bookingTransferModalOpen: boolean;
  handleBookingTransferModalClose: VoidFunction;
  bookingId: string;
  lot?: number;
  handleFetchGuestFolio: VoidFunction;
}) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  // const associatedLot = useSelector((state: RootState) => state.user.lotId);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const navigate = useNavigate();

  const [stepper, setStepper] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [lots, setLots] = useState<ILot[]>([]);
  const [selectedLot, setSelectedLot] = useState<string>("");

  const [availableRooms, setAvailableRooms] = useState<ITransferRooms[]>([]);

  const fetchLots = () => {
    setIsLoading(true);
    axiosInstance
      .get("/lot", config)
      .then((res) => {
        setLots(res.data.filter((data: any) => data.lotId !== props.lot));
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching lots:", error);
      })
      .finally(() => setIsLoading(false));
  };

  //Get Service Charges
  useEffect(() => {
    fetchLots();
  }, []);

  const handleCheckAvailableRooms = () => {
    setIsLoading(true);
    axiosInstance
      .get(
        `/lot/get-lot-availability/${selectedLot}?bookingId=${props.bookingId}`,
        config
      )
      .then((res) => {
        setAvailableRooms(res.data.data);
      })
      .catch((res: any) => Notification.failed(res.response.data.error))
      .finally(() => setIsLoading(false));
  };

  const handleTransferBooking = () => {
    const formData = {
      booking_id: props.bookingId,
      new_lot: selectedLot,
      room_update: availableRooms.map((availableRoom) => ({
        prev_room_id: availableRoom.prevRoomId,
        new_room_ids: availableRooms
          .filter((room) => room.prevRoomId === availableRoom.prevRoomId)
          .map((room) => room.roomId),
      })),
    };
    setIsLoading(true);
    axiosInstance
      .post(`/bookings/transfer-booking/`, formData, config)
      .then((res) => {
        Notification.success(res.data.msg);
        props.handleFetchGuestFolio();
        // navigate(`/upcoming-booking/${res.data.data.bookingId}`);
        props.handleBookingTransferModalClose();
      })
      .catch((res: any) => Notification.failed(res.response.data.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      open={props.bookingTransferModalOpen}
      onClose={props.handleBookingTransferModalClose}
    >
      <Box
        width={"700px"}
        maxHeight={"600px"}
        border={`3px solid ${theme.palette.primary.main}`}
        alignItems={"center"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
          borderRadius: 1,
        }}
      >
        <ManagementModalHeader
          title={"Transfer Booking?"}
          handleModalClose={props.handleBookingTransferModalClose}
        />
        {stepper === 0 ? (
          <>
            <Box
              display={"flex"}
              height={"150px"}
              bgcolor={"black"}
              justifyContent={"start"}
              alignItems={"center"}
              paddingX={2}
            >
              <MoveDown
                fontSize="large"
                color="primary"
                sx={{ marginRight: 2 }}
              />
              <Typography variant="h4">
                Do you want to transfer booking?
              </Typography>
            </Box>
            <Box
              display={"flex"}
              height={"50px"}
              bgcolor={theme.palette.background.paper}
              justifyContent={"end"}
              padding={1}
              borderTop={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack direction={"row"} spacing={1}>
                <Button
                  variant={"outlined"}
                  onClick={props.handleBookingTransferModalClose}
                >
                  Cancel
                </Button>
                <Button variant={"outlined"} onClick={() => setStepper(1)}>
                  Confirm
                </Button>
              </Stack>
            </Box>
          </>
        ) : (
          <>
            <Box
              display={"flex"}
              height={availableRooms.length > 0 ? "100px" : "150px"}
              bgcolor={"black"}
              justifyContent={"space-between"}
              alignItems={"center"}
              paddingX={2}
            >
              <Typography variant="h4">Lot</Typography>
              <Select
                onChange={({ target: { value } }) => {
                  setSelectedLot(value as string);
                }}
                variant="standard"
                sx={{ width: "80%" }}
              >
                {lots.map((lot, index) => (
                  <MenuItem key={index} value={lot.lotId}>
                    {lot.lotNumber} - {lot.lotDescription}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            {availableRooms.length > 0 && (
              <>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography variant="h3" paddingX={2}>
                    Current Rooms:
                  </Typography>
                  <Typography variant="h3" paddingX={2}>
                    Available Rooms:
                  </Typography>
                </Stack>
                <Grid
                  container
                  columns={{ xs: 1, sm: 5, md: 5, lg: 5, xl: 5 }}
                  padding={2}
                  columnSpacing={1}
                  rowSpacing={2}
                  marginBottom={2}
                >
                  {availableRooms.map((room) => (
                    <Fragment key={room.roomId}>
                      <Grid item xs={1} sm={2} md={2} lg={2} xl={2}>
                        <RoomBox
                          colorCode={room.prevRoomColorCode}
                          roomCode={room.prevRoomCode}
                          roomDetails={room.prevRoomTypeDetails}
                          roomZone={room.prevRoomZone}
                        />
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <ArrowForward
                            color="secondary"
                            sx={{ fontSize: "36px" }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={1} sm={2} md={2} lg={2} xl={2}>
                        <RoomBox
                          colorCode={room.colorCode}
                          roomCode={room.roomCode}
                          roomDetails={room.roomTypeDetails}
                          roomZone={room.prevRoomZone}
                        />
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </>
            )}
            <Box
              display={"flex"}
              height={"50px"}
              bgcolor={theme.palette.background.paper}
              justifyContent={"end"}
              padding={1}
              borderTop={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack direction={"row"} spacing={1}>
                <Button
                  variant={"outlined"}
                  onClick={props.handleBookingTransferModalClose}
                >
                  Cancel
                </Button>
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    if (stepper === 1 && availableRooms.length > 0) {
                      handleTransferBooking();
                      return;
                    }
                    if (stepper === 1) {
                      handleCheckAvailableRooms();
                      return;
                    }
                    if (stepper === 0) {
                      setStepper(1);
                      return;
                    }
                  }}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="primary" />
                  ) : (
                    "Confirm"
                  )}
                </Button>
              </Stack>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default BookingTransferModal;
