import { IGuestInfo } from "../models/bookings/Booking";

export function convertScannedDataToIGuestInfo(
  inputString: string
): IGuestInfo {
  const lines = inputString.trim().split("\n");

  if (inputString.toUpperCase().includes("MYKAD")) {
    const name = lines[1];
    const splittedNames = name.split(" ");

    const guestInfo: IGuestInfo = {
      id: "",
      firstName: splittedNames[0],
      lastName: splittedNames.slice(1).join(" "),
      gender:
        lines[4] === "LELAKI"
          ? "Male"
          : lines[4] === "PEREMPUAN"
          ? "Female"
          : "Others",
      idType: "MyKad",
      idNumber: lines[2],
      phone: "",
      email: "",
      country: "MYS",
      dateOfBirth: lines[3],
    };
    return guestInfo;
  }

  if (inputString.toUpperCase().includes("PASSPORT")) {
    const surname = lines[1];
    const forename = lines[2];
    const idNumber = lines[7];
    const gender = lines[3].split(": ")[1];
    const nationality = lines[9].split(": ")[1];
    const dateOfBirth = lines[4].split(": ")[1];
    const splittedSurname = surname.split(": "); // Result : ["Surname", "Supriyadi"]
    const splittedForename = forename.split(": ");
    const splittedIdNumber = idNumber.split(": ");

    let firstName = splittedForename[1];
    let lastName = splittedSurname[1];

    if (
      splittedForename[1].split(" ").length > 1 &&
      splittedSurname[1].split(" ").length > 0
    ) {
      // If the forename has more than one word and the surname has at least one word, take the first word as the forename and the rest as the surname
      firstName = splittedForename[1].split(" ")[0];
      lastName =
        splittedForename[1].split(" ").slice(1).join(" ") + " " + lastName;
    } else if (splittedSurname[1].split(" ").length > 1) {
      // If the surname has more than one word, take the first word as the forename and the rest as the surname
      firstName = splittedSurname[1].split(" ")[0];
      lastName = splittedSurname[1].split(" ").slice(1).join(" ");
    }

    const guestInfo: IGuestInfo = {
      id: "",
      firstName: firstName,
      lastName: lastName,
      gender: gender,
      idType: "Passport",
      idNumber: splittedIdNumber[1],
      phone: "",
      email: "",
      country: nationality,
      dateOfBirth: dateOfBirth,
    };
    return guestInfo;
  }
  return {
    id: "",
    firstName: "",
    lastName: "",
    gender: "",
    idType: "",
    idNumber: "",
    phone: "",
    email: "",
    country: "",
  };
}
