import { Box, Stack, Typography, fabClasses } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IBookingDraft, IBookingRowInfo } from "../../types/ganttChart";
import LeftColumn from "./LeftColumn";
import TimeAxis from "./TimeAxis";
import ChartBox from "./ChartBox";
import Notification from "../../utils/notificationConfig";
import theme from "../../assets/theme/theme";
import { date } from "yup";
import { format, subDays } from "date-fns";
import { bookingStatusEnum } from "../../constants/enums";
/**
 * *Unit length of each chart column in em
 */
const pxPerEm = 14;
const emPerHour = 5.5;
const epochPerEM = (1 * 60 * 60 * 1000) / emPerHour;

/**
 * *Gantt Chart colors
 */
const evenNumColor = theme.palette.background.default;
const oddNumColor = "black";

const GanttChart = ({
  ganttChartInfos,
  displayDate,
  displayHour,
  duration,
  selectedDate,
  handleBookingDraft,
  // setPassBookingDraftDataPermission,
  // passBookingDraftDataPermission,
  bookingDraft,
  removeBookingByRoomId,
  otaDirected,
  isLoading,
}: {
  ganttChartInfos: IBookingRowInfo[];
  displayDate: Date;
  displayHour: number;
  duration: number;
  selectedDate: Date;
  handleBookingDraft: (data: IBookingDraft) => void;
  // setPassBookingDraftDataPermission: React.Dispatch<React.SetStateAction<boolean>>;
  // passBookingDraftDataPermission: boolean;
  bookingDraft: IBookingDraft[];
  removeBookingByRoomId: (selectedRoomId: string) => void;
  otaDirected: boolean;
  isLoading: boolean;
}) => {
  /**
   * *Gantt Chart specifications
   */
  const xAxisNumTicks = otaDirected ? 120 : 72;
  const xAxisHeight = "3em";
  const clockHours = Array.from(Array(xAxisNumTicks).keys());
  const scrollbarWidthInEm = "10px";
  const scrollBoxHeight = "59vh";
  const leftColumnWidth = 4 * emPerHour;
  const leftColumnItemWidth = 3 * emPerHour;
  const itemHeight = "4em";

  const newCheckInDateTime = new Date(selectedDate);
  const newCheckOutDateTime = newCheckInDateTime.setHours(
    newCheckInDateTime.getHours() + duration
  );
  const newCheckOutDateTimeString = new Date(newCheckOutDateTime).toString();
  let initialNewBookingPeriod = {
    roomBookingId: "add New Booking",
    checkInDatetime: selectedDate.toString(),
    checkOutDatetime: newCheckOutDateTimeString,
    currentBookingStatus: "Draft",
    customerFirstname: "",
    bookingNo: "",
    bookingPlatform: "",
  };
  const handleCheckOutTimeIfAnotherDraftExist = (date: string) => {
    const newCheckInDateTime = new Date(date);
    const newCheckOutDateTime = newCheckInDateTime.setHours(
      newCheckInDateTime.getHours() + duration
    );
    const newCheckOutDateTimeString = new Date(newCheckOutDateTime).toString();
    return newCheckOutDateTimeString;
  };
  let newBookingInfo = {
    roomBookingId: "add New Booking",
    checkInDatetime: selectedDate.toString(),
    checkOutDatetime: newCheckOutDateTimeString,
    currentBookingStatus: "Draft",
    customerFirstname: "",
    bookingNo: "",
    bookingPlatform: "",
  };
  // the state is used to allow manipulation (add) of booking rows and it's booking periods
  const [bookingRows, setBookingRows] = useState<IBookingRowInfo[]>([]);
  const [scrollToHour, setScrollToHour] = useState<number>(0);

  useEffect(() => {
    if (otaDirected && selectedDate) {
      setScrollToHour(selectedDate.getHours());
      return;
    }
    if (otaDirected) {
      setScrollToHour(displayDate.getHours());
      return;
    }
    if (selectedDate) {
      setScrollToHour(selectedDate.getHours() + 24);
    } else {
      setScrollToHour(displayDate.getHours() + 24);
    }
    // Before
    // setScrollToHour(displayDate.getHours());
  }, [displayDate, selectedDate]);

  const scrollBoxRef = useRef<null | HTMLElement>(null);
  const hourAxisRef = useRef<null | HTMLDivElement>(null);
  const dateBoxRef = useRef<null | HTMLDivElement>(null);
  const dateHeaderRef = useRef<null | HTMLDivElement>(null);
  const leftColumnRef = useRef<null | HTMLDivElement>(null);
  const blackBoxRef = useRef<null | HTMLDivElement>(null);
  const arrowDownRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollTo({
        //Before
        //Left: 0
        left: (scrollToHour - 1) * emPerHour * pxPerEm,
        top: 0,
        behavior: "smooth",
      });
    }
  }, [scrollToHour]);

  const handleScroll = useCallback(() => {
    hourAxisRef.current!.style.top = `${scrollBoxRef.current!.scrollTop}px`;
    dateHeaderRef.current!.style.top = `${scrollBoxRef.current!.scrollTop}px`;
    dateBoxRef.current!.style.top = `${scrollBoxRef.current!.scrollTop}px`;
    leftColumnRef.current!.style.left = `${scrollBoxRef.current!.scrollLeft}px`;
    blackBoxRef.current!.style.left = `${scrollBoxRef.current!.scrollLeft}px`;
    blackBoxRef.current!.style.top = `${scrollBoxRef.current!.scrollTop}px`;
    if (!otaDirected) {
      arrowDownRef.current!.style.top = `${scrollBoxRef.current!.scrollTop}px`;
    }
  }, []);

  useEffect(() => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollBoxRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        scrollBoxRef.current.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let passDraftDataPermission = false;
  const addBookingPeriodToRow = (leftColumnItemIndex: number) => {
    const duplicateBookingRows = JSON.parse(JSON.stringify(bookingRows));
    let bookingUpdatePermission = true;
    if (bookingDraft.length < 1) {
      initialNewBookingPeriod = {
        roomBookingId: "add New Booking",
        checkInDatetime: selectedDate.toString(),
        checkOutDatetime: newCheckOutDateTimeString,
        currentBookingStatus: "Draft",
        customerFirstname: "",
        bookingNo: "",
        bookingPlatform: "",
      };
    } else {
      initialNewBookingPeriod = {
        roomBookingId: "add New Booking",
        checkInDatetime: bookingDraft[0].check_in,
        checkOutDatetime: handleCheckOutTimeIfAnotherDraftExist(
          bookingDraft[0].check_in
        ),
        currentBookingStatus: "Draft",
        customerFirstname: "",
        bookingNo: "",
        bookingPlatform: "",
      };
    }

    duplicateBookingRows[leftColumnItemIndex].bookingPeriods.push(
      initialNewBookingPeriod
    );
    const newCheckInDate = new Date(initialNewBookingPeriod.checkInDatetime);
    const newCheckOutDate = new Date(initialNewBookingPeriod.checkOutDatetime);

    for (const item of bookingRows[leftColumnItemIndex].bookingPeriods) {
      const itemCheckInDate = new Date(item.checkInDatetime);
      const itemCheckOutDate = new Date(item.maxCheckOutDatetime);

      if (
        item.currentBookingStatus === bookingStatusEnum.noShow &&
        item.bookingPlatform === "Walk In"
      ) {
        break;
      }

      if (
        (itemCheckOutDate >= newCheckInDate &&
          newCheckOutDate >= itemCheckOutDate) ||
        (itemCheckInDate <= newCheckOutDate &&
          newCheckInDate <= itemCheckInDate) ||
        (itemCheckOutDate >= newCheckInDate &&
          itemCheckInDate <= newCheckOutDate)
      ) {
        bookingUpdatePermission = false;
        break;
      }
    }

    if (bookingUpdatePermission) {
      setBookingRows([...duplicateBookingRows]);
      passDraftDataPermission = true;
    } else {
      Notification.failed("A booking already existed!");
      passDraftDataPermission = false;
    }
  };

  const addBookingDraft = (
    roomCode: string,
    roomZone: string,
    roomTypeId: string,
    roomTypeName: string,
    roomTypeDetails: string,
    colorCode: string,
    roomID: string,
    maxPax: number
  ) => {
    const draftDetails = {
      room_code: roomCode,
      room_zone: roomZone,
      room_type_id: roomTypeId,
      room_type_name: roomTypeName,
      room_type_details: roomTypeDetails,
      check_in: selectedDate.toString(),
      check_out: newCheckOutDateTimeString,
      duration: duration,
      color_code: colorCode,
      room_id: roomID,
      max_pax: maxPax,
    };
    if (passDraftDataPermission) {
      handleBookingDraft(draftDetails);
    }
  };

  useEffect(() => {
    //Combining selected booking, and newly sorted
    const draftedRows = bookingRows.filter((row) =>
      row.bookingPeriods.some((period) => period.bookingNo === "")
    );
    const removedRooms = ganttChartInfos.filter(
      (infos) => !draftedRows.some((row) => row.roomCode === infos.roomCode)
    );
    setBookingRows([...draftedRows, ...removedRooms]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ganttChartInfos]);

  // useEffect(() => {
  //   setNewBookingPeriod(newBookingInfo);
  // }, [selectedDate, duration]);

  const removeBookingPeriod = (rowIndex: number, periodIndex: number) => {
    setBookingRows((prevState) =>
      prevState.map((row, i) => {
        if (i === rowIndex) {
          removeBookingByRoomId(row.roomId);
          return {
            ...row,
            bookingPeriods: row.bookingPeriods.filter(
              (_, j) => j !== periodIndex
            ),
          };
        }
        return row;
      })
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: oddNumColor,
        padding: "1em",
        position: "relative",
        height: "65vh",
        width: "90vw",
      }}
    >
      <Box
        ref={scrollBoxRef}
        sx={{
          width: "94%",
          height: scrollBoxHeight,
          display: "flex",
          position: "relative",
          backgroundColor: oddNumColor,
          borderRadius: "0.75em",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: scrollbarWidthInEm,
            height: scrollbarWidthInEm,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "transparent",
            borderRadius: "6px",
            border: "3px solid #15ffdc",
            marginBlock: "50px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
            borderRadius: "3px",
          },
          "&::-webkit-scrollbar-corner": {
            backgroundColor: oddNumColor,
          },
        }}
      >
        <Box
          ref={leftColumnRef}
          sx={{
            fontSize: `${pxPerEm}px`,
            width: `${leftColumnWidth}em`,
            position: "absolute",
            borderRight: "3px solid white",
            zIndex: 95,
          }}
        >
          <LeftColumn
            addBookingPeriodToRow={
              otaDirected ? () => {} : addBookingPeriodToRow
            }
            addBookingDraft={otaDirected ? () => {} : addBookingDraft}
            ref={dateHeaderRef}
            measurements={{
              leftColumnWidth,
              leftColumnItemWidth,
              itemHeight,
              xAxisHeight,
              oddNumColor,
            }}
            //Before
            //displayDate={displayDate}
            displayDate={selectedDate}
            ganttChartInfos={bookingRows}
            isLoading={isLoading}
          />
        </Box>

        <Box
          width={`${leftColumnWidth}em`}
          bgcolor={"black"}
          height={"30px"}
          position={"absolute"}
          zIndex={3}
          ref={blackBoxRef}
        />

        {!otaDirected ? (
          <Stack
            direction={"row"}
            marginLeft={`${leftColumnWidth}em`}
            height={"30px"}
            position={"absolute"}
            zIndex={97}
            width={`${xAxisNumTicks * emPerHour}em`}
            ref={dateBoxRef}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              height={"18px"}
              width={"33.3%"}
              alignItems={"center"}
              justifyContent={"start"}
              border={1}
              bgcolor={"black"}
              borderColor={theme.palette.primary.main}
            >
              <Typography fontWeight={"bold"}>
                {format(subDays(selectedDate, 1), "dd MMM yyyy")}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              height={"18px"}
              width={"33.3%"}
              alignItems={"center"}
              justifyContent={"start"}
              border={1}
              bgcolor={"black"}
              borderColor={theme.palette.primary.main}
            >
              <Typography fontWeight={"bold"}>
                {format(selectedDate, "dd MMM yyyy")}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              height={"18px"}
              width={"33.3%"}
              alignItems={"center"}
              justifyContent={"start"}
              border={1}
              bgcolor={"black"}
              borderColor={theme.palette.primary.main}
            >
              <Typography fontWeight={"bold"}>
                {format(subDays(selectedDate, -1), "dd MMM yyyy")}
              </Typography>
            </Box>
          </Stack>
        ) : (
          <Stack
            direction={"row"}
            marginLeft={`${leftColumnWidth}em`}
            height={"30px"}
            position={"absolute"}
            zIndex={90}
            width={`${xAxisNumTicks * emPerHour}em`}
            ref={dateBoxRef}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              height={"18px"}
              width={"20%"}
              alignItems={"center"}
              justifyContent={"start"}
              border={1}
              bgcolor={"black"}
              borderColor={theme.palette.primary.main}
            >
              <Typography fontWeight={"bold"}>
                {format(selectedDate, "dd MMM yyyy")}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              height={"18px"}
              width={"20%"}
              alignItems={"center"}
              justifyContent={"start"}
              border={1}
              bgcolor={"black"}
              borderColor={theme.palette.primary.main}
            >
              <Typography fontWeight={"bold"}>
                {format(subDays(selectedDate, -1), "dd MMM yyyy")}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              height={"18px"}
              width={"20%"}
              alignItems={"center"}
              justifyContent={"start"}
              border={1}
              bgcolor={"black"}
              borderColor={theme.palette.primary.main}
            >
              <Typography fontWeight={"bold"}>
                {format(subDays(selectedDate, -2), "dd MMM yyyy")}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              height={"18px"}
              width={"20%"}
              alignItems={"center"}
              justifyContent={"start"}
              border={1}
              bgcolor={"black"}
              borderColor={theme.palette.primary.main}
            >
              <Typography fontWeight={"bold"}>
                {format(subDays(selectedDate, -3), "dd MMM yyyy")}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              height={"18px"}
              width={"20%"}
              alignItems={"center"}
              justifyContent={"start"}
              border={1}
              bgcolor={"black"}
              borderColor={theme.palette.primary.main}
            >
              <Typography fontWeight={"bold"}>
                {format(subDays(selectedDate, -4), "dd MMM yyyy")}
              </Typography>
            </Box>
          </Stack>
        )}

        <Box
          position={"relative"}
          sx={{
            width: "fit-content",
            height: "fit-content",
            marginLeft: `${leftColumnWidth}em`,
            marginTop: 2,
          }}
        >
          <TimeAxis
            measurements={{
              xAxisNumTicks,
              xAxisHeight,
              emPerHour,
              oddNumColor,
              evenNumColor,
              epochPerEM,
            }}
            displayDate={selectedDate}
            displayHour={displayHour}
            clockHours={clockHours}
            ref={hourAxisRef}
            arrowDownRef={arrowDownRef}
            otaDirected={otaDirected}
          />
          <ChartBox
            measurements={{
              xAxisNumTicks,
              xAxisHeight,
              emPerHour,
              oddNumColor,
              evenNumColor,
              itemHeight,
              epochPerEM,
            }}
            bookingRows={bookingRows}
            //Before
            //displayDate = {displayDate}
            displayDate={selectedDate}
            displayHour={displayHour}
            removeBookingPeriod={removeBookingPeriod}
            setBookingRows={setBookingRows}
            otaDirected={otaDirected}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(GanttChart);
