import {
  Alert,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import theme from "../../assets/theme/theme";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import { store } from "../../redux/store";

interface SpecialPaymentProps {
  SpecialPaymentCategory?: string;
  SpecialPaymentName?: string;
  SpecialPaymentDescription?: string;
  SpecialPaymentId?: number;
  onSpecialPaymentVisibilityChange: (isVisible: boolean) => void;
}

export default function SpecialPaymentDetail({
  SpecialPaymentCategory,
  SpecialPaymentName,
  SpecialPaymentDescription,
  SpecialPaymentId,
  onSpecialPaymentVisibilityChange,
}: SpecialPaymentProps) {
  const [showSpecialPaymentDetail, setShowSpecialPaymentDetail] =
    useState(false);

  const handleButtonClick = () => {
    console.log(title);

    console.log(desc);
    setShowSpecialPaymentDetail(false);

    onSpecialPaymentVisibilityChange(false);
  };
  const token = store.getState().user.accessToken;

  const [title, setTitle] = useState<string>();
  const [desc, setDesc] = useState<string>();

  useEffect(() => {
    setTitle(SpecialPaymentName);
    setDesc(SpecialPaymentDescription);
  }, []);

  const submitSpecialPaymentTax = () => {
    if (SpecialPaymentId) {
      const payload = {
        settingsId: SpecialPaymentId,
        newDescription: desc,
      };

      axiosInstance
        .put(`/lot-settings/edit-settings-description/`, payload, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          Notification.success("Settings updated successfully");
          console.log("Settings updated successfully:", response.data);
        })
        .catch((error) => {
          console.error("Error updating settings:", error);
        });
    } else {
      const payload = {
        settingsCategory: "Special Payment",
        settingsName: title,
        settingsDescription: desc,
      };
      if (
        title &&
        ["complimentary", "recovery services"].includes(title?.toLowerCase())
      ) {
        Notification.failed("Name is not allowed, please enter another name");
      }
      axiosInstance
        .post(`/lot-settings/create-settings/`, payload, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          Notification.success("Settings Created successfully");
          console.log("Settings Created successfully:", response.data);
        })
        .catch((error) => {
          console.error("Error Created settings:", error);
        });
    }
  };
  const handleTitleChange = (newValue?: string) => {
    setTitle(newValue || ""); // Use an empty string if newValue is undefined
  };

  const handleDescChange = (newValue?: string) => {
    setDesc(newValue || ""); // Use an empty string if newValue is undefined
  };
  return (
    <>
      <Grid container padding={5} spacing={2}>
        <Grid item xs={1}>
          <Button onClick={handleButtonClick}>Back</Button>
        </Grid>
        <Grid item xs={11}>
          <Typography variant="h3">
            {SpecialPaymentId ? "Edit" : "New"} Special Payment
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              borderBottomWidth: 3.5,
              borderColor: "#232323",
            }}
          />
        </Grid>
        <Grid item xs={12} px={2} py={3}>
          <Alert severity="info" sx={{ color: "#014361" }}>
            {
              "This page only serves as a view-only page. Please contact developer for actual functionality to be implement"
            }
          </Alert>
        </Grid>
        <Grid item xs={3} px={2}>
          <Typography variant="h4">Title / Name</Typography>
        </Grid>
        <Grid item xs={9} px={2}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={title}
            onChange={(e) => handleTitleChange(e.target.value)}
            inputProps={{
              style: { textTransform: "none" },
            }}
          />
        </Grid>

        <Grid item xs={3} px={2}>
          <Typography variant="h4">Description</Typography>
        </Grid>
        <Grid item xs={9} px={2}>
          <TextField
            fullWidth
            size="medium"
            variant="outlined"
            value={desc}
            onChange={(e) => handleDescChange(e.target.value)}
            inputProps={{
              style: { textTransform: "none" },
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        justifyContent="flex-end"
        alignItems="flex-end"
        paddingRight={4}
        paddingBottom={4}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={submitSpecialPaymentTax}
          sx={{ width: "100px" }}
        >
          Save
        </Button>
      </Grid>
    </>
  );
}
