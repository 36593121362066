import {
  Box,
  ButtonBase,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ILockers,
  INewLockerZones,
} from "../../models/lockers/LockerInterface";
import { useEffect, useMemo, useState } from "react";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import {
  Add,
  FilterAlt,
  FilterAltOutlined,
  Search,
  Settings,
  SortTwoTone,
  CheckCircle,
  RemoveCircle,
  Engineering,
} from "@mui/icons-material";
import CustomTable from "../global/table/CustomTable";
import AddOrEditLockerModal from "./AddOrEditLockerModal";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { lockerStatusEnum } from "../../constants/enums";

const LockerTabContent = (props: {
  setLockerZones: React.Dispatch<React.SetStateAction<INewLockerZones[]>>;
}) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 12,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [lockers, setLockers] = useState<ILockers[]>([]);

  const [selectedLocker, setSelectedLocker] = useState<ILockers>({
    id: "",
    zone: {
      details: "",
      isArchive: false,
      name: "",
      zoneId: "",
    },
    code: "",
    details: "",
    rate: 0,
    isArchived: false,
    status: "",
    upDown: false,
  });
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [levelFilter, setLevelFilter] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<string>("Code");

  const [lockerStatuses, setLockerStatus] = useState<string[]>([]);

  const [openEditLockerModal, setOpenEditLockerModal] =
    useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEditLockerModal = () => {
    setOpenEditLockerModal(true);
  };
  const handleCloseEditLockerModal = () => {
    setOpenEditLockerModal(false);
    setSelectedLocker({
      id: "",
      zone: {
        details: "",
        isArchive: false,
        name: "",
        zoneId: "",
      },
      code: "",
      details: "",
      rate: 0,
      isArchived: false,
      status: "",
      upDown: false,
    });
  };

  const getLockerData = () => {
    setIsLoading(true);
    const apiUrlParams = new URLSearchParams();
    apiUrlParams.append("lockerLevel", levelFilter);
    apiUrlParams.append("status", statusFilter.join(":"));
    axiosInstance
      .get(`/lockers?${apiUrlParams.toString()}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const formattedLocker: ILockers[] = res.data.map(
            (data: any): ILockers => ({
              id: data.locker_id,
              code: data.code,
              details: data.details,
              isArchived: data.is_archive,
              rate: 0,
              status: data.status,
              upDown: data.up_down,
              zone: {
                details: data.zone.details,
                isArchive: data.zone.is_archive,
                name: data.zone.name,
                zoneId: data.zone.zone_id,
              },
            })
          );
          let sortedLocker = formattedLocker;
          if (sortBy === "Code") {
            sortedLocker = sortedLocker.sort((a, b) =>
              a.code.localeCompare(b.code)
            );
          }
          if (sortBy === "Zone") {
            sortedLocker = sortedLocker.sort((a, b) =>
              a.zone.name.localeCompare(b.zone.name)
            );
          }
          if (sortBy === "Level") {
            sortedLocker = sortedLocker.sort(
              (a, b) => (a.upDown ? 1 : 0) - (b.upDown ? 1 : 0)
            );
          }
          if (sortBy === "Status") {
            sortedLocker = sortedLocker.sort((a, b) =>
              a.status.localeCompare(b.status)
            );
          }
          sortedLocker = formattedLocker.sort((a, b) =>
            a.isArchived === b.isArchived ? 0 : a.isArchived ? 1 : -1
          );
          setLockers(sortedLocker);
          return;
        }
        Notification.failed(res.data.status);
      })
      .catch((e) => {
        console.log(e);
        Notification.failed("something wrong, please try again");
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getLockerData();
  }, []);

  useEffect(() => {
    const fetchStatus = () => {
      setIsLoading(true);
      axiosInstance
        .get(`/lockers/get-status-list`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            setLockerStatus(res.data.data);
          }
        })
        .catch((e) => {
          Notification.failed("something wrong, please try again");
        });
    };

    fetchStatus();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEditStatus = (locker: ILockers, newStatus: string) => {
    setLoading(true);
    axiosInstance
      .patch(
        "/lockers/edit-locker-status/",
        { locker_id: locker.id, new_status: newStatus },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setLockers((prevLockers) => {
            return prevLockers.map((lockerItem) => {
              if (lockerItem.id === locker.id) {
                return { ...lockerItem, status: newStatus };
              }
              return lockerItem;
            });
          });
          Notification.success("Locker status updated successfully");
        }
      })
      .catch((error) => {
        Notification.failed(
          error.response?.data?.error || "Failed to update locker status"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = useMemo<MRT_ColumnDef<ILockers>[]>(
    () => [
      {
        accessorKey: "code",
        header: "Locker Code",
        size: 50,
        Cell: ({ row }) => (
          <p style={{ color: row.original.isArchived ? "grey" : "inherit" }}>
            {row.original.code}
          </p>
        ),
      },
      {
        accessorKey: "zone.name",
        header: "Zone",
        size: 50,
        Cell: ({ row }) => (
          <p style={{ color: row.original.isArchived ? "grey" : "inherit" }}>
            {row.original.zone.name}
          </p>
        ),
      },
      {
        header: "Level",
        Cell: ({ row }) => (
          <p style={{ color: row.original.isArchived ? "grey" : "inherit" }}>
            {row.original.upDown ? "Upper" : "Lower"}
          </p>
        ),
        size: 50,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 50,
        Cell: ({ row }) => (
          <p style={{ color: row.original.isArchived ? "grey" : "inherit" }}>
            {row.original.status}
          </p>
        ),
      },
      {
        header: "Edit Status",
        size: 50,
        Cell: ({ row }) => (
          <Stack direction="row">
            {row.original.status !== lockerStatusEnum.available && (
              <IconButton
                onClick={() =>
                  handleEditStatus(row.original, lockerStatusEnum.available)
                }
              >
                <CheckCircle color="primary" />
              </IconButton>
            )}
            {row.original.status !== lockerStatusEnum.occupied && (
              <IconButton
                onClick={() =>
                  handleEditStatus(row.original, lockerStatusEnum.occupied)
                }
              >
                <RemoveCircle color="error" />
              </IconButton>
            )}
            {row.original.status !== lockerStatusEnum.maintenance && (
              <IconButton
                onClick={() =>
                  handleEditStatus(row.original, lockerStatusEnum.maintenance)
                }
              >
                <Engineering color="warning" />
              </IconButton>
            )}
          </Stack>
        ),
      },
      {
        accessorKey: "details",
        header: "Remarks",
        size: 350,
        Cell: ({ row }) => (
          <p style={{ color: row.original.isArchived ? "grey" : "inherit" }}>
            {row.original.details}
          </p>
        ),
      },
      {
        header: "Action",
        size: 50,
        Cell: ({ row }) => (
          <IconButton
            onClick={(event) => {
              setSelectedLocker(row.original);
              handleClick(event);
            }}
            // sx={{
            //   height: "10px",
            //   width: "10px",
            // }}
          >
            <Settings color="primary" />
          </IconButton>
        ),
      },
    ],
    [handleEditStatus]
  );

  const handleStatusFilterChange = (
    event: SelectChangeEvent<typeof statusFilter>
  ) => {
    const {
      target: { value },
    } = event;
    setStatusFilter(typeof value === "string" ? value.split(",") : value);
  };
  const [loading, setLoading] = useState<boolean>(false);

  const handleArchiveLocker = () => {
    setLoading(true);

    const apiUrl = "/lockers/archive/";

    const formData = {
      locker_id: selectedLocker.id,
      archive: !selectedLocker.isArchived,
    };
    axiosInstance
      .put(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        Notification.success(res.data.message);
        setLockers((prevLocker) => {
          const removedLocker = prevLocker.find(
            (locker) => locker.id === selectedLocker?.id
          );

          if (removedLocker) {
            const newLockers = prevLocker.filter(
              (locker) => locker.id !== selectedLocker?.id
            );

            // return [
            //   {
            //     ...removedLocker,
            //     isArchived: !selectedLocker.isArchived,
            //   },
            //   ...newLockers,
            // ];
            return newLockers;
          }
          return prevLocker;
        });
      })

      .catch((res) => {
        Notification.failed(res.response.data.error);
      })
      .finally(() => {
        handleClose();
        getLockerData();
        setLoading(false);
      });
  };

  return (
    <>
      <Stack
        direction={"row"}
        width={"100%"}
        minHeight={"3.5rem"}
        justifyContent={"space-between"}
        alignItems={"center"}
        borderBottom={2}
        paddingLeft={2}
        borderColor={theme.palette.primary.main}
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <Stack
          direction={"row"}
          width={"100%"}
          height={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant={"h3"} color={theme.palette.primary.main}>
            Lockers ({lockers.length})
          </Typography>
          <ButtonBase
            onClick={handleOpenEditLockerModal}
            sx={{
              height: "100%",
              width: "150px",
              borderLeft: 2,
              borderColor: theme.palette.primary.main,
            }}
          >
            <Stack
              direction={"row"}
              width={"100%"}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Add color="primary" />
              <Typography variant="h4" color={"primary"}>
                Add Locker
              </Typography>
            </Stack>
          </ButtonBase>
        </Stack>
      </Stack>
      <Box
        display={"flex"}
        height={"80px"}
        flexDirection={"row"}
        alignItems={"center"}
        bgcolor={theme.palette.background.default}
        padding={2}
      >
        <Stack
          direction={"row"}
          width={"100%"}
          height={"100%"}
          alignItems={"center"}
          spacing={2}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <FilterAltOutlined /> <Typography variant="h4">Locker</Typography>
          </Stack>
          <TextField
            label="Search"
            onChange={(event) => setSearchFilter(event.target.value)}
            inputProps={{ style: { textTransform: "none" } }}
            sx={{ width: "35%" }}
          />
          <FormControl sx={{ width: "15%" }}>
            <InputLabel id="filter-level">Level</InputLabel>
            <Select
              labelId="filter-level"
              input={
                <OutlinedInput
                  label="Level"
                  sx={{ "& label": { color: "white" } }}
                />
              }
              onChange={(event: any) => {
                setLevelFilter(event.target.value);
              }}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="upper">Upper</MenuItem>
              <MenuItem value="lower">Lower</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: "15%" }}>
            <InputLabel id="filter-status">Status</InputLabel>
            <Select
              labelId="filter-status"
              value={statusFilter}
              multiple
              onChange={handleStatusFilterChange}
              renderValue={(selected) => selected.join(",")}
            >
              {lockerStatuses.map((lockerStatus, index) => (
                <MenuItem key={index} value={lockerStatus}>
                  <Checkbox checked={statusFilter.indexOf(lockerStatus) > -1} />
                  <ListItemText primary={lockerStatus} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <SortTwoTone fontSize="large" sx={{ marginX: 0 }} />
          <FormControl sx={{ width: "15%" }}>
            <InputLabel id="sort">Sort By</InputLabel>
            <Select
              labelId="sort"
              input={
                <OutlinedInput
                  label="Sort By"
                  sx={{ "& label": { color: "white" } }}
                />
              }
              value={sortBy}
              onChange={(event: any) => {
                setSortBy(event.target.value);
              }}
            >
              <MenuItem value="Code">Locker Number</MenuItem>
              <MenuItem value="Zone">Zone</MenuItem>
              <MenuItem value="Level">Level</MenuItem>
              <MenuItem value="Status">Status</MenuItem>
            </Select>
          </FormControl>
          <IconButton
            sx={{
              height: "2em",
              // marginTop: "0.7em",
              color: "white",
              backgroundColor: "#195a56",
              borderRadius: "0.2em",
              marginLeft: "1em",
            }}
            onClick={getLockerData}
          >
            <Search />
          </IconButton>
        </Stack>
      </Box>
      {isLoading ? (
        <Box
          display={"flex"}
          height={"50vh"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box padding={2}>
          <CustomTable
            columns={columns}
            data={lockers}
            isLoading={isLoading}
            pagination={pagination}
            handlePaginationChange={setPagination}
            oddEvenBackground={false}
            globalFilter={searchFilter}
          />
          <Menu
            id={"basic-menu"}
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
              setSelectedLocker({
                id: "",
                zone: {
                  details: "",
                  isArchive: false,
                  name: "",
                  zoneId: "",
                },
                code: "",
                details: "",
                rate: 0,
                status: "",
                upDown: false,
                isArchived: false,
              });
              handleClose();
            }}
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "0",
                width: "10rem",
                border: `3px solid ${theme.palette.primary.main}`,
                backgroundColor: "black",
              },
              "& .MuiPaper-root .MuiList-root": {
                backgroundColor: "black",
                padding: "0",
              },
            }}
          >
            <MenuItem
              onClick={handleOpenEditLockerModal}
              sx={{
                height: "2rem",
                borderBottom: `3px solid ${theme.palette.primary.main}`,
                color: theme.palette.primary.main,
                backgroundColor: "black",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  color: "black",
                },
              }}
            >
              Edit Info
            </MenuItem>
            <MenuItem
              onClick={handleArchiveLocker}
              sx={{
                height: "2rem",
                color: theme.palette.primary.main,
                backgroundColor: "black",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  color: "black",
                },
              }}
            >
              {selectedLocker.isArchived ? "Unarchive" : "Archive"}
            </MenuItem>
          </Menu>
          {openEditLockerModal && (
            <AddOrEditLockerModal
              selectedLocker={selectedLocker}
              setLockers={setLockers}
              setLockerZones={props.setLockerZones}
              openEditLockerModal={openEditLockerModal}
              handleCloseEditLockerModal={handleCloseEditLockerModal}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default LockerTabContent;
