import { Typography, IconButton, Box } from "@mui/material";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import React from "react";
import theme from "../../assets/theme/theme";

type Props = {
  title: string;
  handleModalClose: () => void;
};

const ManagementModalHeader = ({ title, handleModalClose }: Props) => {
  return (
    <Box
      height={"40px"}
      paddingY={1}
      paddingX={2}
      sx={{
        backgroundColor: theme.palette.background.default,
      }}
      borderBottom={`3px solid ${theme.palette.primary.main}`}
      display={"flex"}
      justifyContent={"space-between"}
    >
      <Typography variant={"h3"} color={theme.palette.primary.main}>
        {title}
      </Typography>
      <IconButton onClick={() => handleModalClose()}>
        <HighlightOffRoundedIcon
          sx={{
            color: theme.palette.primary.main,
            fontSize: "24px",
          }}
        />
      </IconButton>
    </Box>
  );
};

export default ManagementModalHeader;
