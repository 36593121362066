import { Map } from "@mui/icons-material";
import {
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import { useState } from "react";
import HouseKeepingStatus from "../../components/housekeeping/HouseKeepingStatus";
import HouseKeepingLog from "../../components/housekeeping/HouseKeepingLog";
import HouseKeepingScoreboard from "../../components/housekeeping/HouseKeepingScoreboard";

const HouseKeepingPage = () => {
  const theme = useTheme();
  const isMobileDevice = useMediaQuery("(max-width:900px)");
  const [tabStepperValue, setTabStepperValue] = useState<number>(0);
  const handleStepperChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabStepperValue(newValue);
  };
  return (
    <Box
      display={"flex"}
      width={"80%"}
      flexDirection={"column"}
      marginX={"auto"}
    >
      <Typography variant="h1">HouseKeeping</Typography>
      <Stack direction={"row"} width={"100%"}>
        <Tabs
          orientation={isMobileDevice ? "vertical" : "horizontal"}
          value={tabStepperValue}
          onChange={handleStepperChange}
          sx={{
            width: "100%",
            marginBottom: 5,
            "& .MuiTab-root": {
              width: "100%",
              textTransform: "none",
              fontWeight: 600,
              fontSize: "1.3rem",
              "&.Mui-selected": {
                color: theme.palette.primary.main,
              },
              "&:not(.Mui-selected)": {
                color: "white",
                borderBottom: "1px solid #0a443c",
              },
            },
          }}
        >
          <Tab value={0} label={"STATUS"} />
          <Tab value={1} label={"SCOREBOARD"} />
          <Tab value={2} label={"LOG"} />
        </Tabs>
        {isMobileDevice ? (
          <></>
        ) : (
          <Button
            variant="outlined"
            startIcon={<Map />}
            sx={{ height: "50px", width: "20%", fontSize: "20px" }}
          >
            View Map
          </Button>
        )}
      </Stack>
      {tabStepperValue === 0 ? (
        <HouseKeepingStatus />
      ) : tabStepperValue === 1 ? (
        <HouseKeepingScoreboard />
      ) : (
        tabStepperValue === 2 && <HouseKeepingLog />
      )}
    </Box>
  );
};

export default HouseKeepingPage;
