import {
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import RoomList from "./RoomList";
import theme from "../../assets/theme/theme";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  RoomDataInterfaceV2,
  RoomTypeInterface,
  RoomTypeInterfaceV2,
  ZoneInterface,
  ZoneInterfaceV2,
} from "../../models/rooms/roomsInterface";
import RoomZone from "./RoomZone";
import TypeList from "./TypeList";
import ZoneList from "./ZoneList";
import AddIcon from "@mui/icons-material/Add";
import AddRoomModal from "../../components/roomlist/AddOrEditRoomModal";
import EditRoomZoneModal from "../../components/roomlist/EditRoomZone";
import EditRoomTypeModal from "../../components/roomlist/EditRoomTypeModal";
import { Add } from "@mui/icons-material";
import AddOrEditRoomModal from "../../components/roomlist/AddOrEditRoomModal";
import TierSettings from "../../components/room-management/TierSettings";
import OverstaySettings from "../../components/room-management/OverstaySettings";

type Props = {};

interface IFirstFilterValue {
  roomZone: string[];
  roomType: string[];
  roomRemarks: string[];
  bedLevel: string[];
  quietZone: string[];
  isArchived: string[];
}

interface RoomTypeOverview {
  [roomType: string]: { count: number; roomColor: string };
}

export interface RoomZoneData {
  zone: string;
  roomCount: number;
  roomType: string[];
  maxPax: string;
}

type RoomZoneComponentData = Record<string, RoomZoneData>;

/* -------------------------------------------------------------------------- */
/*                             Overview Component                             */
/* -------------------------------------------------------------------------- */
/* ----------------- might make a global component for this ----------------- */
const TypeOverview = ({
  data1,
  data2,
  color,
}: {
  data1: string;
  data2: number;
  color: string;
}) => (
  <Stack
    direction={"row"}
    width={"80%"}
    border={3}
    borderColor={color}
    margin={"auto"}
  >
    <Stack width={"50%"} paddingY={1} alignItems={"center"}>
      <Typography>{data1[0]}</Typography>
    </Stack>
    <Stack
      width={"50%"}
      paddingY={1}
      alignItems={"center"}
      sx={{ backgroundColor: color }}
    >
      <Typography color={"black"}>{data2}</Typography>
    </Stack>
  </Stack>
);

const OverviewCapsule = ({ roomTypes, roomZone }: any) => {
  return (
    <Stack width={"100%"} paddingX={3}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant={"h4"} fontWeight={500}>
          {roomZone}
        </Typography>
        <IconButton>
          <SettingsIcon sx={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Stack>
      {roomTypes.map((item: any, index: number) => (
        <Stack
          key={index}
          direction={"row"}
          width={"100%"}
          border={3}
          borderColor={item.roomColor}
          marginBottom={1}
        >
          <Stack width={"50%"} paddingY={1} alignItems={"center"}>
            <Typography>{item.roomTypeDetails}</Typography>
          </Stack>
          <Stack
            width={"50%"}
            paddingY={1}
            alignItems={"center"}
            sx={{ backgroundColor: item.roomColor }}
          >
            <Typography color={"black"}>{item.roomCount}</Typography>
          </Stack>
        </Stack>
      ))}
      <div
        style={{
          height: 2,
          width: "100%",
          backgroundColor: theme.palette.primary.main,
          marginTop: 15,
        }}
      />
    </Stack>
  );
};

/* -------------------------------------------------------------------------- */
/*                               main component                               */
/* -------------------------------------------------------------------------- */

export default function RoomManagement({}: Props) {
  /* -------------------------------- useStates ------------------------------- */

  const [roomData, setRoomData] = useState<RoomDataInterfaceV2[]>([]);
  const [roomTypeData, setRoomTypeData] = useState<RoomTypeInterfaceV2[]>([]);
  const [tabStepperValue, setTabStepperValue] = useState(0);
  const [overviewData, setOverviewData] = useState<
    Record<
      string,
      {
        roomTypeDetails: string;
        roomCount: number;
        rooms: RoomDataInterfaceV2[];
      }[]
    >
  >();
  const [typeOverviewData, setTypeOverviewData] = useState<RoomTypeOverview>();
  const [searchBarValue, setSearchBarValue] = useState("");
  const [defaultRoomFilterValue, setDefaultRoomFilterValue] =
    useState<IFirstFilterValue>();

  const [roomZoneData, setRoomZoneData] = useState<ZoneInterfaceV2[]>([]);

  /* ---------------------- function to count and group the roomData overview ---------------------- */
  const getOverviewData = () => {
    const groupedByZoneAndType: Record<
      string,
      Record<string, RoomDataInterfaceV2[]>
    > = roomData.reduce((acc, room) => {
      const { zone, roomTypeDetails } = room;

      if (!acc[zone]) {
        acc[zone] = {};
      }

      if (!acc[zone][roomTypeDetails]) {
        acc[zone][roomTypeDetails] = [];
      }

      acc[zone][roomTypeDetails].push(room);

      return acc;
    }, {} as Record<string, Record<string, RoomDataInterfaceV2[]>>);

    const result: Record<
      string,
      {
        roomTypeDetails: string;
        roomCount: number;
        rooms: RoomDataInterfaceV2[];
      }[]
    > = Object.entries(groupedByZoneAndType).reduce<
      Record<
        string,
        {
          roomTypeDetails: string;
          roomCount: number;
          rooms: RoomDataInterfaceV2[];
        }[]
      >
    >((acc, [zone, roomTypeDetails]) => {
      acc[zone] = Object.entries(roomTypeDetails).map(([type, rooms]) => ({
        roomTypeDetails: type,
        roomCount: rooms.length,
        rooms,
        roomColor: rooms[0].colorCode,
      }));

      return acc;
    }, {});

    setOverviewData(result);
  };

  /* ----------------- function to get roomType overview data ----------------- */
  const getTypeOverviewData = (): RoomTypeOverview => {
    const countRoomByType = roomData.reduce((acc, room) => {
      const { roomTypeDetails, colorCode } = room;

      // If roomType doesn't exist in acc, initialize it
      if (!acc[roomTypeDetails]) {
        acc[roomTypeDetails] = { count: 0, roomColor: colorCode };
      }

      // Increment the count for the current roomType
      acc[roomTypeDetails].count += 1;

      return acc;
    }, {} as RoomTypeOverview);

    return countRoomByType;
  };

  /* ------------ function to get the data of zone list components ------------ */
  // const getRoomZoneData = (): RoomZoneData[] => {
  //   const roomZoneData: RoomZoneComponentData = roomData.reduce((acc, room) => {
  //     const { zone, roomType } = room;

  //     if (!acc[zone]) {
  //       acc[zone] = { zone, roomCount: 0, roomType: [] };
  //     }

  //     acc[zone].roomCount += 1;

  //     if (!acc[zone].roomType.includes(roomType)) {
  //       acc[zone].roomType.push(roomType);
  //     }

  //     return acc;
  //   }, {} as RoomZoneComponentData);

  //   const roomZoneArray: RoomZoneData[] = Object.values(roomZoneData);

  //   return roomZoneArray;
  // };

  /* -------------------------- state function handle ------------------------- */
  const handleStepperChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabStepperValue(newValue);
  };

  const handleSearchBarChange = (event: string) => {
    setSearchBarValue(event);
  };

  /* ------------------------------- useEffects to get room list filters ------------------------------- */
  useEffect(() => {
    if (roomData) {
      getOverviewData();
    }
    const filterValues = {
      //get the default filter values
      roomType: Array.from(new Set(roomData.map((item) => item.roomType))),
      roomZone: Array.from(new Set(roomData.map((item) => item.zone))),
      roomRemarks: Array.from(new Set(roomData.map((item) => item.remarks))),
      bedLevel: Array.from(new Set(roomData.map((item) => item.roomCode))),
      quietZone: ["True", "False"],
      isArchived: ["True", "False"],
    };
    setDefaultRoomFilterValue(filterValues);
    setTypeOverviewData(getTypeOverviewData());
  }, [roomData]);

  const [openAddRoomModal, setOpenAddRoomModal] = useState(false);
  const [openEditRoomZoneModal, setOpenEditRoomZoneModal] = useState(false);
  const [openEditRoomTypeModal, setOpenEditRoomTypeModal] = useState(false);
  const [openTierSettingsModal, setOpenTierSettingsModal] =
    useState<boolean>(false);

  const [openOverstaySettingsModal, setOpenOverstaySettingsModal] =
    useState<boolean>(false);

  const roomTypeInitialValue: RoomTypeInterfaceV2 = {
    colorTags: "",
    maxPax: 0,
    typeId: "",
    typeName: "",
    RoomTypeDetails: "",
    archived: false,
    roomzone: {
      archived: false,
      lotId: 0,
      RoomZoneDetails: "",
      zoneId: "",
      zoneName: "",
    },
  };

  const handleAddRoomModalOpen = () => {
    setOpenAddRoomModal(true);
  };

  const handleAddRoomModalClose = () => {
    setOpenAddRoomModal(false);
  };

  const handleEditRoomZoneModalOpen = () => {
    setOpenEditRoomZoneModal(true);
  };

  const handleEditRoomZoneModalClose = () => {
    setOpenEditRoomZoneModal(false);
  };

  const handleEditRoomTypeModalOpen = (
    selectedRoomTypeParams: RoomTypeInterfaceV2
  ) => {
    setSelectedRoomType(selectedRoomTypeParams);
    setOpenEditRoomTypeModal(true);
  };

  const handleEditRoomTypeModalClose = () => {
    setSelectedRoomType(roomTypeInitialValue);
    setOpenEditRoomTypeModal(false);
  };

  const handleCloseTierSettings = () => {
    setOpenTierSettingsModal(false);
  };

  const handleCloseOverstaySettings = () => {
    setOpenOverstaySettingsModal(false);
  };

  const [selectedRoomType, setSelectedRoomType] =
    useState<RoomTypeInterfaceV2>(roomTypeInitialValue);

  return (
    <Stack width={"100%"} height={"100vh"} alignItems={"center"}>
      <Tabs
        value={tabStepperValue}
        onChange={handleStepperChange}
        sx={{
          marginBottom: 5,
          "& .MuiTab-root": {
            minWidth: "30.4vw",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "1.3rem",
            "&.Mui-selected": {
              color: theme.palette.primary.main,
            },
            "&:not(.Mui-selected)": {
              color: "white",
              borderBottom: "1px solid #0a443c",
            },
          },
        }}
      >
        <Tab value={0} label={"Room"} />
        <Tab value={1} label={"Room Type"} />
        <Tab value={2} label={"Room Zone"} />
      </Tabs>
      <Grid
        container
        width={"100%"}
        height={"100%"}
        border={2}
        borderColor={theme.palette.primary.main}
      >
        <Grid
          item
          md={2}
          sm={12}
          xs={12}
          sx={{
            borderRight: 2,
            borderColor: theme.palette.primary.main,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            minHeight: "100%",
          }}
        >
          <Stack
            direction={"row"}
            width={"100%"}
            minHeight={"3.5rem"}
            alignItems={"center"}
            paddingLeft={2}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
            sx={{
              backgroundColor: theme.palette.background.default,
              position: "sticky",
              zIndex: 100,
              top: 0,
            }}
          >
            <Typography variant={"h3"} color={theme.palette.primary.main}>
              Overview
            </Typography>
          </Stack>

          <Stack
            direction={"row"}
            width={"100%"}
            minHeight={"3.5rem"}
            alignItems={"start"}
            paddingLeft={2}
            sx={{
              backgroundColor: theme.palette.background.default,
              position: "sticky",
              zIndex: 100,
              top: 0,
            }}
          >
            <IconButton onClick={() => setOpenTierSettingsModal(true)}>
              <SettingsIcon color="primary" sx={{ mr: 2, mt: 1 }} />
              <Typography variant={"h4"} color={theme.palette.common.white}>
                Tier Settings
              </Typography>
            </IconButton>
          </Stack>
          <Stack
            direction={"row"}
            width={"100%"}
            minHeight={"3.5rem"}
            alignItems={"start"}
            paddingLeft={2}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
            sx={{
              backgroundColor: theme.palette.background.default,
              position: "sticky",
              zIndex: 100,
              top: 0,
            }}
          >
            <IconButton onClick={() => setOpenOverstaySettingsModal(true)}>
              <SettingsIcon color="primary" sx={{ mr: 2, mt: 1 }} />
              <Typography variant={"h4"} color={theme.palette.common.white}>
                Overstay/Add Hour
              </Typography>
            </IconButton>
          </Stack>

          {overviewData && tabStepperValue === 0 ? (
            Object.entries(overviewData).map(([zone, roomType], index) => (
              <OverviewCapsule
                key={index}
                roomTypes={roomType}
                roomZone={zone}
              />
            ))
          ) : typeOverviewData &&
            (tabStepperValue === 1 || tabStepperValue === 2) ? (
            <Stack spacing={2} alignItems={"center"} paddingY={3}>
              {Object.entries(typeOverviewData).map(
                ([roomType, { count, roomColor }], index) => (
                  <TypeOverview
                    key={index}
                    data1={roomType}
                    data2={count}
                    color={roomColor}
                  />
                )
              )}
            </Stack>
          ) : (
            <CircularProgress />
          )}
        </Grid>
        <Grid item md={10} sm={12} xs={12} sx={{ height: "100%" }}>
          <Stack
            direction={"row"}
            width={"100%"}
            height={"3.5rem"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom={2}
            paddingLeft={2}
            borderColor={theme.palette.primary.main}
            sx={{ backgroundColor: theme.palette.background.default }}
          >
            {tabStepperValue === 0 ? (
              <>
                <Typography variant={"h3"} color={theme.palette.primary.main}>
                  Room ({roomData.length})
                </Typography>
                <ButtonBase
                  onClick={handleAddRoomModalOpen}
                  sx={{
                    height: "100%",
                    width: "150px",
                    borderLeft: 2,
                    borderColor: theme.palette.primary.main,
                  }}
                >
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    height={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Add color="primary" />
                    <Typography variant="h4" color={"primary"}>
                      Add Room
                    </Typography>
                  </Stack>
                </ButtonBase>
              </>
            ) : tabStepperValue === 1 ? (
              <>
                <Typography variant={"h3"} color={theme.palette.primary.main}>
                  Room Type ({roomTypeData.length})
                </Typography>
                <ButtonBase
                  onClick={() =>
                    handleEditRoomTypeModalOpen(roomTypeInitialValue)
                  }
                  sx={{
                    height: "100%",
                    width: "150px",
                    borderLeft: 2,
                    borderColor: theme.palette.primary.main,
                  }}
                >
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    height={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Add color="primary" />
                    <Typography variant="h4" color={"primary"}>
                      Add Type
                    </Typography>
                  </Stack>
                </ButtonBase>
              </>
            ) : tabStepperValue === 2 ? (
              <>
                <Typography variant={"h3"} color={theme.palette.primary.main}>
                  Room Zone ({roomZoneData?.length})
                </Typography>
                <ButtonBase
                  onClick={handleEditRoomZoneModalOpen}
                  sx={{
                    height: "100%",
                    width: "150px",
                    borderLeft: 2,
                    borderColor: theme.palette.primary.main,
                  }}
                >
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    height={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Add color="primary" />
                    <Typography variant="h4" color={"primary"}>
                      Add Zone
                    </Typography>
                  </Stack>
                </ButtonBase>
              </>
            ) : null}
          </Stack>
          <Stack direction={"column"} height={"100vh"}>
            {tabStepperValue === 0 && defaultRoomFilterValue ? (
              <RoomList
                roomData={roomData}
                setRoomData={setRoomData}
                searchBarValue={searchBarValue}
                handleSearchBarChange={handleSearchBarChange}
                filterValue={defaultRoomFilterValue}
              />
            ) : tabStepperValue === 1 ? (
              <TypeList
                roomTypes={roomTypeData}
                setRoomTypes={setRoomTypeData}
              />
            ) : tabStepperValue === 2 ? (
              <ZoneList
                zoneData={roomZoneData}
                setRoomZoneData={setRoomZoneData}
              />
            ) : null}
          </Stack>
        </Grid>
      </Grid>
      <AddOrEditRoomModal
        setRoomData={setRoomData}
        addOrEditRoomModalOpen={openAddRoomModal}
        handleAddRoomModalClose={handleAddRoomModalClose}
      />
      <EditRoomZoneModal
        title={"Edit Room Zone"}
        handleEditRoomZoneModalClose={handleEditRoomZoneModalClose}
        openEditRoomZoneModal={openEditRoomZoneModal}
      />
      <EditRoomTypeModal
        title={"Add Room Type"}
        setRoomTypes={setRoomTypeData}
        handleEditRoomTypeModalClose={handleEditRoomTypeModalClose}
        handleEditRoomTypeModalOpen={openEditRoomTypeModal}
      />
      <TierSettings
        openTierSettings={openTierSettingsModal}
        handleCloseTierSettings={handleCloseTierSettings}
      />
      <OverstaySettings
        openOverstaySettings={openOverstaySettingsModal}
        handleCloseOverstaySettings={handleCloseOverstaySettings}
      />
    </Stack>
  );
}
