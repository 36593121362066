import {
  Box,
  Button,
  ButtonBase,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { IRoomType } from "../../models/bookings/Booking";
import { useEffect, useState } from "react";
import { Add, KeyboardReturn, Remove } from "@mui/icons-material";
import axios from "axios";
import Notification from "../../utils/notificationConfig";
import BASE_API from "../../constants/api";
import { store } from "../../redux/store";
import axiosInstance from "../../constants/axiosConfig";
import { IOTACompletedRooms } from "../../models/ota/OTAInterfaces";

interface OTAReservationInfoProps {
  handleNextStep: (roomTypeResponse?: IOTACompletedRooms, date?: Date) => void;
  handleBackStep: VoidFunction;
  selectedDate: Date;
}

export interface IRoomTypeReserve {
  roomTypeName: string;
  count: number;
}

const durations = [3, 6, 12, 24, 168];

const OTAReservationInfo = (props: OTAReservationInfoProps) => {
  const theme = useTheme();
  const token = store.getState().user.accessToken;

  const [roomTypeReservation, setRoomTypeReservation] = useState<
    IRoomTypeReserve[]
  >([]);

  const [selectedDuration, setSelectedDuration] = useState<number>(24);

  const { roomTypes } = useGetRoomTypesHook(
    `${BASE_API}/rooms/v1/room-type/list/`,
    token
  );

  const handleAddRoomTypeReservation = (
    roomTypeName: string,
    count: number
  ) => {
    setRoomTypeReservation((prev) => {
      const item = prev.find((item) => item.roomTypeName === roomTypeName);
      if (item !== undefined) {
        item.count = count;
        return [...prev];
      }

      const req: IRoomTypeReserve = {
        roomTypeName: roomTypeName,
        count: count,
      };
      prev.push(req);

      const arr = prev.filter((item) => item.count !== 0);
      return [...arr];
    });
  };

  const handleReserveRooms = () => {
    const apiUrl = "/bookings/v1/ota/reserve/";

    const formData = {
      checkInDatetime: props.selectedDate,
      duration: selectedDuration,
      rooms: roomTypeReservation,
    };

    axiosInstance
      .post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        Notification.success(res.data.message);
        props.handleNextStep(res.data.data);
      })
      .catch((res) => {
        if (res.response.data.message === "no available room") {
          Notification.failed("No Rooms Selected");
          return;
        }
        Notification.failed(res.response.data.message);
      });
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      bgcolor={theme.palette.background.default}
      padding={2}
    >
      <Button
        variant="outlined"
        onClick={props.handleBackStep}
        sx={{ marginBottom: 1, width: "90px" }}
      >
        <KeyboardReturn /> Back
      </Button>
      <Stack
        direction={"row"}
        height={"30px"}
        alignItems={"center"}
        spacing={2}
        marginBottom={1}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
          bgcolor={"white"}
          sx={{ aspectRatio: 1 / 1 }}
        >
          <Typography variant="h3" color={"black"}>
            2
          </Typography>
        </Box>
        <Typography variant="h2">Room</Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        marginBottom={2}
      >
        <Typography variant="h4" fontWeight={"normal"}>
          Select the duration
        </Typography>
        <Select
          size="small"
          value={selectedDuration}
          onChange={(event) => setSelectedDuration(Number(event.target.value))}
          sx={{
            marginBottom: 2,
            width: "150px",
            border: 1,
            borderColor: theme.palette.primary.main,
          }}
        >
          {durations.map((duration, index) => (
            <MenuItem key={index} value={duration}>
              {duration} hrs
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Typography variant="h4" fontWeight={"normal"} marginBottom={2}>
        Select the amount of room type
      </Typography>
      <Box display={"flex"} width={"100%"}>
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          columns={{ xs: 2, sm: 2, md: 2, lg: 4, xl: 4 }}
          spacing={2}
          width={"700px"}
        >
          {roomTypes.map((roomType, index) => (
            <Grid item key={index} xs={2} sm={2} md={2} lg={2} xl={2}>
              <RoomTypeCountComponent
                key={index}
                roomType={roomType}
                handleAddRoomTypeReservation={handleAddRoomTypeReservation}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"end"}
        alignItems={"end"}
        height={"100%"}
      >
        <Button
          variant="outlined"
          onClick={handleReserveRooms}
          sx={{ height: "50px", width: "160px" }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

const RoomTypeCountComponent = ({
  roomType,
  handleAddRoomTypeReservation,
}: {
  roomType: IRoomType;
  handleAddRoomTypeReservation: (roomTypeId: string, count: number) => void;
}) => {
  const theme = useTheme();
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    handleAddRoomTypeReservation(roomType.typeName, count);
    return () => {};
  }, [count]);

  return (
    <Stack direction={"row"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        bgcolor={roomType.colorTags}
        height={"50px"}
        width={"100%"}
        paddingX={2}
      >
        <Typography variant="h4" color={"black"}>
          {roomType.typeName}
        </Typography>
      </Box>
      <ButtonBase
        onClick={() => {
          if (count === 0) {
            return;
          }
          setCount(count - 1);
        }}
      >
        <Box
          display={"flex"}
          height={"50px"}
          bgcolor={"black"}
          border={1}
          borderColor={roomType.colorTags}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ aspectRatio: 1 / 1 }}
        >
          <Remove />
        </Box>
      </ButtonBase>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"50px"}
        width={"100px"}
        bgcolor={theme.palette.background.default}
        borderTop={1}
        borderBottom={1}
        borderColor={roomType.colorTags}
      >
        {count}
      </Box>
      <ButtonBase
        onClick={() => {
          setCount(count + 1);
        }}
      >
        <Box
          display={"flex"}
          height={"50px"}
          bgcolor={"black"}
          border={1}
          borderColor={roomType.colorTags}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ aspectRatio: 1 / 1 }}
        >
          <Add />
        </Box>
      </ButtonBase>
    </Stack>
  );
};

function useGetRoomTypesHook(url: string, token: string) {
  const [roomTypes, setRoomTypes] = useState<IRoomType[]>([]);
  const [isLoadingRoomTypeFetch, setIsLoadingRoomTypeFetch] =
    useState<boolean>(false);
  useEffect(() => {
    setIsLoadingRoomTypeFetch(true);

    const request = () => {
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.status === 200) {
            setRoomTypes(res.data);
          }
          if (res.status === 409) {
            alert("Try Again");
          }
        })
        .catch((e) => {
          Notification.failed(e.response.data.message);
        })
        .finally(() => {
          setIsLoadingRoomTypeFetch(false);
        });
    };
    request();
  }, []);
  return { roomTypes, isLoadingRoomTypeFetch };
}

export default OTAReservationInfo;
