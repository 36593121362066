import {
  Typography,
  Grid,
  Modal,
  Box,
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Backdrop,
  Button,
  TextField,
} from "@mui/material";
import axios, { AxiosResponse, AxiosError } from "axios";
import ManagementModalFormTextField from "../management/ManagementModalFormTextField";
import ManagementModalFormButton from "../management/ManagementModalFormButton";
import ManagementModalHeader from "../management/ManagementModalHeader";
import { Formik, Form, FormikErrors } from "formik";
import React, { useEffect, useRef, useState } from "react";
import theme from "../../assets/theme/theme";
import BASE_API from "../../constants/api";
import Notification from "../../utils/notificationConfig";
import { store } from "../../redux/store";
import { IMerch } from "../../types/merch";
import ManagementModalFormNumber from "../management/ManagementModalFormNumberField";
import ManagementModalFormNumberField from "../management/ManagementModalFormNumberField";
import ManagementModalFormChild from "../management/ManagementModalFormChild";
import { isNonNullChain } from "typescript";
import { string } from "yup";
import { v4 as uuidv4 } from "uuid";
import { QrCode } from "@mui/icons-material";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";

type Props = {
  title: string;
  type: "add" | "edit";
  isMerchModalOpen: boolean;
  handleMerchModalClose: () => void;
  initialValues?: IMerch;
  fetchAllMerch: () => Promise<void>;
};

const defaultInitialMerchValues: IMerch = {
  merchId: "",
  itemName: "",
  itemDetails: "",
  sum: 0, // price of the merch
  sku: "",
  qrCode: uuidv4(),
  cost: 0,
  nonGuestSum: "",
  isArchived: false,
  stockInQuantity: 0,
  stockOutQuantity: 0,
};

const MerchModal = ({
  title,
  type,
  isMerchModalOpen,
  handleMerchModalClose,
  initialValues,
  fetchAllMerch,
}: Props) => {
  const token = store.getState().user.accessToken;

  const lot = store.getState().user.lotId;
  const [isNotOriginalPrice, setIsNotOriginalPrice] = useState<boolean>(false);

  const [initialMerchValues, setDefaultInitialMerchValues] = useState<IMerch>(
    defaultInitialMerchValues
  );

  const qrCodeRef = useRef<any>(null);

  const handleChangePrice = () => {
    setIsNotOriginalPrice(!isNotOriginalPrice);
  };

  const downloadQRCode = () => {
    const qrCodeElement = qrCodeRef.current;

    html2canvas(qrCodeElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = initialValues!.qrCode;
      link.click();
    });
  };

  useEffect(() => {
    setDefaultInitialMerchValues((prevValue) => ({
      ...prevValue,
      qrCode: uuidv4(),
    }));
  }, [isMerchModalOpen]);

  return (
    <Modal
      open={isMerchModalOpen}
      onClose={() => handleMerchModalClose()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box
        width={"600px"}
        minWidth={"38em"}
        border={`3px solid ${theme.palette.primary.main}`}
        margin={"auto"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
        }}
      >
        <ManagementModalHeader
          title={title}
          handleModalClose={handleMerchModalClose}
        />

        <Formik
          initialValues={initialValues || initialMerchValues}
          enableReinitialize={true}
          onSubmit={({
            merchId,
            itemName,
            itemDetails,
            sum,
            sku,
            qrCode,
            nonGuestSum,
            stockInQuantity,
            stockOutQuantity,
            stock,
            cost,
          }) => {
            const formData = new FormData();
            formData.append("lot", lot);
            formData.append("itemName", itemName);
            formData.append("itemDetails", itemDetails);
            formData.append("sum", sum ? sum.toString() : "0");
            if (!isNotOriginalPrice) {
              formData.append("nonGuestSum", sum ? sum.toString() : "0");
            } else {
              formData.append("nonGuestSum", nonGuestSum);
            }
            formData.append("sku", sku.toUpperCase());
            formData.append("qrCode", qrCode);
            formData.append(
              "stockInQuantity",
              stock
                ? (
                    stock -
                    (stockInQuantity - stockOutQuantity) +
                    stockInQuantity
                  ).toString()
                : stockInQuantity.toString()
            );
            formData.append("cost", cost ? cost.toString() : "0");

            if (type === "add") {
              axios
                .post(`${BASE_API}/merch/`, formData, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((res: AxiosResponse) => {
                  Notification.success(res.data.message);
                  fetchAllMerch();
                  handleMerchModalClose();
                })
                .catch((err) => {
                  if (typeof err.response.data.message === "string") {
                    Notification.failed(err.response.data.message);
                    return;
                  }
                  if (err.response) {
                    const errorsList = err.response.data;
                    for (const errorKey in errorsList) {
                      if (errorsList.hasOwnProperty(errorKey)) {
                        Notification.failed(
                          `${errorKey} : ${errorsList[errorKey][0]}`
                        );
                      }
                    }
                  }
                });

              return;
            }

            if (type === "edit") {
              axios
                .put(`${BASE_API}/merch/${merchId}/`, formData, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((res: AxiosResponse) => {
                  Notification.success(res.data.message);
                  fetchAllMerch();
                  handleMerchModalClose();
                })
                .catch((err) => {
                  if (typeof err.response.data.message === "string") {
                    Notification.failed(err.response.data.message);
                    return;
                  }
                  if (err.response) {
                    const errorsList = err.response.data;
                    for (const errorKey in errorsList) {
                      if (errorsList.hasOwnProperty(errorKey)) {
                        Notification.failed(
                          `${errorKey} : ${errorsList[errorKey][0]}`
                        );
                      }
                    }
                  }
                });

              return;
            }
          }}
        >
          {({
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            setFieldValue,
          }) => (
            <Form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Stack width={"100%"} paddingX={3} paddingY={3}>
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant={"h2"}>
                    {type === "add" ? "New Merch" : "Edit Merch"}
                  </Typography>
                  {values.qrCode && values.merchId && (
                    <Button variant={"outlined"} onClick={downloadQRCode}>
                      Download QR
                    </Button>
                  )}
                </Stack>
                {values.qrCode && (
                  <Box
                    padding={1}
                    bgcolor={"white"}
                    marginBottom={2}
                    mx={"auto"}
                  >
                    <div ref={qrCodeRef}>
                      <QRCode value={values.qrCode} renderAs="svg" />
                    </div>
                  </Box>
                )}
                <ManagementModalFormTextField
                  label={"Merch Name"}
                  value={values.itemName}
                  name={"itemName"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <MerchDescModalFormTextField
                  label={"Merch Desc."}
                  value={values.itemDetails}
                  name={"itemDetails"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
                <ManagementModalFormNumberField
                  label={"Cost"}
                  value={values.cost}
                  name={"cost"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  textFieldType={"text"}
                  startAdornment={
                    <Typography fontWeight={600} paddingLeft={"1.5em"}>
                      MYR
                    </Typography>
                  }
                  setFieldValue={setFieldValue}
                />
                <ManagementModalFormNumberField
                  label={"Price"}
                  value={values.sum}
                  name={"sum"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  textFieldType={"text"}
                  startAdornment={
                    <Typography fontWeight={600} paddingLeft={"1.5em"}>
                      MYR
                    </Typography>
                  }
                  setFieldValue={setFieldValue}
                />
                <ManagementModalFormChild label="Non Guest Price">
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <FormControl>
                      <RadioGroup
                        row
                        value={isNotOriginalPrice}
                        onChange={() => {
                          if (isNotOriginalPrice === false) {
                            setFieldValue("nonGuestSum", values.sum);
                          }
                          handleChangePrice();
                        }}
                      >
                        <FormControlLabel
                          value={false}
                          control={<Radio sx={{ color: "white" }} />}
                          label="Same Price"
                        />
                        <FormControlLabel
                          value={true}
                          control={<Radio sx={{ color: "white" }} />}
                          label="Custom Price"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </ManagementModalFormChild>
                {isNotOriginalPrice && (
                  <ManagementModalFormNumberField
                    label={""}
                    value={
                      values.nonGuestSum ? parseFloat(values.nonGuestSum) : 0
                    }
                    name={"nonGuestSum"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    textFieldType={"text"}
                    startAdornment={
                      <Typography fontWeight={600} paddingLeft={"1.5em"}>
                        MYR
                      </Typography>
                    }
                    setFieldValue={setFieldValue}
                  />
                )}
                <ManagementModalFormTextField
                  label={"SKU"}
                  value={values.sku}
                  name={"sku"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <ManagementModalFormTextField
                  label={"QR Code"}
                  value={values.qrCode}
                  name={"qrCode"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  lastChild
                />
                <ManagementModalFormNumberField
                  label={"Stock"}
                  value={values.stock!}
                  name={"stock"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  textFieldType={"number"}
                  setFieldValue={setFieldValue}
                />
                <ManagementModalFormButton
                  type={type}
                  isSubmitting={isSubmitting}
                  values={values}
                  initialValues={initialValues}
                />
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

type MerchDescProps = {
  label: string;
  value: string | number;
  name: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: any) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<IMerch>>;
};

const MerchDescModalFormTextField = ({
  label,
  value,
  name,
  handleChange,
  handleBlur,
  setFieldValue,
}: MerchDescProps) => {
  return (
    <Stack direction={"row"} width={"100%"}>
      <Box
        display={"flex"}
        border={2}
        borderColor={theme.palette.primary.main}
        width={"230px"}
        alignItems={"center"}
        paddingX={1}
      >
        <Typography variant={"h4"} fontWeight={600}>
          {label}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        border={2}
        borderColor={theme.palette.primary.main}
        width={"100%"}
        // bgcolor={theme.palette.background.default}
      >
        <TextField
          required
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          sx={{
            border: "none",
            width: "50%",
            marginRight: "5px",
          }}
          InputProps={{
            sx: {
              height: "3em",
              padding: "0",
              backgroundColor: theme.palette.background.default,
              border: "none",
              borderRadius: "0",
              "& .MuiOutlinedInput-notchedOutline": {
                borderLeft: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: `3px solid ${theme.palette.primary.main}`,
                borderLeft: "none",
              },
            },
          }}
          inputProps={{
            style: {
              height: "3em",
              paddingTop: 0,
              paddingBottom: 0,
              border: "none",
              borderRadius: "0",
              fontWeight: 600,
            },
          }}
        />
        <FormControl>
          <RadioGroup
            row
            value={value}
            onChange={(event) => setFieldValue(name, event.target.value)}
          >
            <FormControlLabel
              value="Locker"
              control={<Radio sx={{ color: "white" }} />}
              label="Locker"
            />
            <FormControlLabel
              value="Shower"
              control={<Radio sx={{ color: "white" }} />}
              label="Shower"
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Stack>
  );
};

export default MerchModal;
