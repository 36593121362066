import { KeyboardReturn } from "@mui/icons-material";
import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import { store } from "../../redux/store";
import { useState } from "react";
import { format } from "date-fns";
import { IOTACompletedRooms } from "../../models/ota/OTAInterfaces";

interface OTACompleteProps {
  handleBackStep: VoidFunction;
  reservedRooms: IOTACompletedRooms;
}

const OTAComplete = (props: OTACompleteProps) => {
  const theme = useTheme();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      bgcolor={theme.palette.background.default}
      padding={2}
    >
      <Button
        variant="outlined"
        onClick={props.handleBackStep}
        sx={{ marginBottom: 1, width: "90px" }}
      >
        <KeyboardReturn /> Back
      </Button>

      <Stack
        direction={"row"}
        height={"30px"}
        alignItems={"center"}
        spacing={2}
        marginBottom={1}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
          bgcolor={"white"}
          sx={{ aspectRatio: 1 / 1 }}
        >
          <Typography variant="h3" color={"black"}>
            3
          </Typography>
        </Box>
        <Typography variant="h2">Completed</Typography>
      </Stack>
      <Typography variant="h4" fontWeight={"normal"} marginBottom={4}>
        The following changes have been made.
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"column"}
        bgcolor={"black"}
        padding={2}
        marginBottom={2}
      >
        <Stack
          direction={"row"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={2}
        >
          <Typography variant="h2">
            Total: {props.reservedRooms.totalRooms} Rooms
          </Typography>
          <Box height={"50px"}>
            <Stack direction={"row"} justifyContent={"end"} spacing={1}>
              <Typography variant="h4" fontWeight={"normal"}>
                Booked From:
              </Typography>
              <Typography variant="h4">
                {format(
                  new Date(props.reservedRooms.startDate),
                  "dd/MM/yyyy HH:mm"
                )}
              </Typography>
            </Stack>
            <Stack direction={"row"} justifyContent={"end"} spacing={1}>
              <Typography variant="h4" fontWeight={"normal"}>
                To:
              </Typography>
              <Typography variant="h4">
                {format(
                  new Date(props.reservedRooms.endDate),
                  "dd/MM/yyyy HH:mm"
                )}
              </Typography>
            </Stack>
          </Box>
        </Stack>
        <Grid container columns={{ xs: 3, sm: 3, md: 6, lg: 6, xl: 6 }}>
          {props.reservedRooms.types.map((roomType, index) => (
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <Typography
                variant="h4"
                sx={{ color: roomType.colorCode, marginBottom: 2 }}
              >
                {roomType.typeName}
              </Typography>
              <Grid container columns={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}>
                {roomType.zones.map((zone, index) => (
                  <Grid item key={index} xs={1} sm={1} md={1} lg={1} xl={1}>
                    <Typography variant="h4" sx={{ marginBottom: 1 }}>
                      {zone.zoneName}
                    </Typography>
                    <Stack direction={"column"} spacing={1}>
                      {zone.roomList.map((roomCode, index) => (
                        <Typography key={index} variant="h4">
                          {roomCode}
                        </Typography>
                      ))}
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box
        display={"flex"}
        justifyContent={"end"}
        alignItems={"end"}
        height={"100%"}
      >
        <Button
          href={`/`}
          variant="outlined"
          sx={{ height: "50px", width: "160px" }}
        >
          Complete
        </Button>
      </Box>
    </Box>
  );
};

export default OTAComplete;
