import {
  Box,
  Button,
  ButtonBase,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import theme from "../../assets/theme/theme";

//Components
import { Add, Remove } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { FormikErrors, useFormik } from "formik";
import { useEffect, useState } from "react";
import BookingInfoRooms from "../../components/ganttchart/BookingInfoRooms";
import axiosInstance from "../../constants/axiosConfig";
import { durationOptions, durationOptionsV2 } from "../../constants/durations";
import useRoomBookingSessionLock from "../../hooks/useBookingRoomSessionLock";
import {
  IBooking,
  IBookingDraft,
  IBookingInfo,
  IPlatform,
  IStepper,
} from "../../models/bookings/Booking";
import { store } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import { IRoomRates } from "../../models/payment/PaymentInterface";
import CustomerBookedModal from "./CustomerBookedModal";

const BookingInfoForm = ({
  setFieldValue,
  initialValues,
  handleChangeStep,
  roomBookingArray,
  allPlatforms,
  setStepper,
  setCustomerBooked,
  roomBookingInfo,
  setBookingId,
  setConfirmBookingData,
}: {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<IBooking>>;
  initialValues: IBookingInfo;
  handleChangeStep: (step: IStepper) => void;
  roomBookingArray: IBookingDraft[];
  allPlatforms: IPlatform[];
  setStepper?: React.Dispatch<React.SetStateAction<number>>;
  setCustomerBooked?: React.Dispatch<React.SetStateAction<string>>;
  roomBookingInfo?: IBooking;
  setBookingId: React.Dispatch<React.SetStateAction<string>>;
  setConfirmBookingData: React.Dispatch<React.SetStateAction<IBooking>>;
}) => {
  const token = store.getState().user.accessToken;
  const lot = store.getState().user.lotId;

  const [bookingDurationOptions, setBookingDurationOptions] = useState<any[]>(
    []
  );

  const [roomBookingMaxPax, setRoomBookingMaxPax] = useState<number>(1);

  const [openCustomerBookedModal, setOpenCustomerBookedModal] =
    useState<boolean>(false);
  const handleCloseCustomerBookedModal = () => {
    setOpenCustomerBookedModal(false);
  };

  useRoomBookingSessionLock(roomBookingArray.map((item) => item.room_id));

  useEffect(() => {
    if (Number(lot) === 2) {
      setBookingDurationOptions(durationOptionsV2);
    } else {
      setBookingDurationOptions(durationOptions);
    }
  }, [lot]);

  useEffect(() => {
    let maxPax = 0;
    roomBookingArray.forEach((e: IBookingDraft) => {
      maxPax += e.max_pax ? e.max_pax : 0;
    });
    setRoomBookingMaxPax(maxPax);
  }, [roomBookingArray]);

  useEffect(() => {
    const walkInPlatform = allPlatforms.find(
      (data) => data.platform === formik.values.platform
    );

    formik.setFieldValue(
      "platformId",
      walkInPlatform ? walkInPlatform.platformId : ""
    );
  }, [allPlatforms]);

  const checkBookingPlatformTier = async (
    platformId: string,
    token: string
  ): Promise<boolean> => {
    const apiUrl = `/rooms/platform-tier/get-by-platform/${platformId}`;

    try {
      const response = await axiosInstance.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data.status === "success";
    } catch (error) {
      console.error("Error checking platform tier:", error);
      return false;
    }
  };

  const [roomRates, setRoomRates] = useState<IRoomRates[]>([]);
  useEffect(() => {
    const fetchRates = () => {
      const apiUrlParams = new URLSearchParams();
      apiUrlParams.append("withAccountsLot", "true");
      axiosInstance
        .get(`/rooms/rate/?${apiUrlParams.toString()}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const updatedRoomRates = res.data.map((rate: any) => ({
            ...rate,
            roomRate: parseFloat(rate.roomRate),
          }));

          setRoomRates(updatedRoomRates);
        })
        .catch((res) => res.response.data.error);
    };
    fetchRates();
  }, []);

  const checkRoomRates = (duration: number) => {
    let canPass = true;
    roomBookingArray.forEach((roomType) => {
      const rateFound = roomRates.find(
        (roomRate) =>
          roomRate.roomType === roomType.room_type_id &&
          roomRate.hoursOfStay === duration
      );

      if (!rateFound) {
        Notification.failed(
          `Room Rate ${roomType.room_type_name} for ${duration} hrs has not been assigned.`
        );
        canPass = false;
      }
    });

    return canPass;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      adultPax: Yup.number().positive("Number must be positive"),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      if (values.platform.includes("OTA -") && !values.otaRefNo) {
        Notification.failed("Please provide OTA Ref No.");
        return;
      } else if (!values.platform.includes("OTA -")) {
        setFieldValue("bookingInfo", values);
        handleChangeStep({ step: 2, text: "Guest Assignation" });
      } else {
        setConfirmBookingData({
          roomBookings: roomBookingArray.map((booking) => ({
            ...booking,
            duration: values.duration,
          })),
          bookingInfo: {
            ...values,
            duration: values.duration,
          },
        });
        setOpenCustomerBookedModal(true);
      }

      if (!checkRoomRates(values.duration)) {
        return;
      }

      const bookingPlatformTierFound = await checkBookingPlatformTier(
        values.platformId,
        token
      );

      if (!bookingPlatformTierFound) {
        Notification.failed(
          "Tier not defined for this booking source, please go to Room management to register one"
        );
        return;
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ marginTop: "30px" }}
      >
        <Stack
          spacing={2}
          sx={{
            width: "80%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Typography
            variant="h2"
            sx={{ textAlign: "start", marginBottom: "10px" }}
          >
            Booking Info
          </Typography>
          <Stack
            direction={"row"}
            spacing={"100px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography width={"120px"} variant="h4">
              Duration
            </Typography>

            <Box display={"flex"} flexDirection={"row"} width={"100%"}>
              <Stack
                direction={"row"}
                spacing={2}
                width={"100%"}
                height={"40px"}
              >
                {bookingDurationOptions.map((option, index) => (
                  <ButtonBase
                    key={index}
                    onClick={() => {
                      formik.setFieldValue("duration", option.time);

                      const checkInDate = new Date(formik.values.checkInDate);

                      checkInDate.setHours(
                        checkInDate.getHours() + option.time
                      );

                      formik.setFieldValue("checkOutDate", checkInDate);

                      roomBookingArray.forEach((roomBooking, index) =>
                        formik.setFieldValue(
                          `roomBookings${index}.duration`,
                          option.time
                        )
                      );
                    }}
                    sx={{ height: "100%", width: "25%" }}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      width={"100%"}
                      height={"100%"}
                      bgcolor={
                        formik.values.duration === option.time
                          ? theme.palette.primary.main
                          : theme.palette.background.default
                      }
                      borderRadius={2}
                    >
                      <Typography
                        variant="h4"
                        color={
                          formik.values.duration === option.time
                            ? "black"
                            : "white"
                        }
                      >
                        {option.label}
                      </Typography>
                    </Box>
                  </ButtonBase>
                ))}
              </Stack>
            </Box>
          </Stack>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack
              direction={"row"}
              spacing={"100px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography width={"120px"} variant="h4">
                Check In
              </Typography>
              <Box
                display={"flex"}
                flexDirection={"row"}
                width={"100%"}
                border={2}
                borderColor={theme.palette.primary.main}
                borderRadius={"5px"}
              >
                <TextField
                  size={"small"}
                  value={format(
                    new Date(formik.values.checkInDate),
                    "dd MMMM yyyy"
                  )}
                  aria-readonly={true}
                  sx={{
                    color: "white",
                    width: "80%",
                  }}
                />

                <TextField
                  size={"small"}
                  value={format(new Date(formik.values.checkInDate), "HH:mm")}
                  sx={{
                    width: "20%",
                    borderLeft: 1,
                    borderColor: theme.palette.primary.main,
                  }}
                />
              </Box>
            </Stack>

            <Stack
              direction={"row"}
              spacing={"100px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography width={"120px"} variant="h4">
                Check Out
              </Typography>
              <Box
                display={"flex"}
                flexDirection={"row"}
                width={"100%"}
                border={2}
                borderColor={theme.palette.primary.main}
                borderRadius={"5px"}
              >
                <TextField
                  size={"small"}
                  value={format(
                    new Date(formik.values.checkOutDate),
                    "dd MMMM yyyy"
                  )}
                  sx={{
                    color: "white",
                    width: "80%",
                  }}
                />
                <TextField
                  size={"small"}
                  value={format(new Date(formik.values.checkOutDate), "HH:mm")}
                  sx={{
                    width: "20%",
                    borderLeft: 1,
                    borderColor: theme.palette.primary.main,
                  }}
                />
              </Box>
            </Stack>
            <Stack direction={"row"} width={"100%"} spacing={2}>
              <Stack
                direction={"row"}
                spacing={"118px"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"50%"}
              >
                <Typography width={"120px"} variant="h4">
                  Adult Pax
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  width={"100%"}
                  border={2}
                  borderColor={theme.palette.primary.main}
                  borderRadius={"5px"}
                >
                  <TextField
                    size={"small"}
                    type={"number"}
                    value={formik.values.adultPax}
                    name={"adultPax"}
                    onChange={formik.handleChange}
                    inputProps={{ min: 1, readOnly: true }}
                    sx={{
                      width: "100%",
                      borderColor: theme.palette.primary.main,
                      backgroundColor: "#232323",
                      ".MuiInputBase-input": {
                        border: "none",
                      },
                    }}
                  />
                </Box>
              </Stack>
              <Stack
                direction={"row"}
                spacing={"118px"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"50%"}
              >
                <Typography width={"120px"} variant="h4">
                  Child Pax
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  width={"100%"}
                  border={2}
                  borderColor={theme.palette.primary.main}
                  borderRadius={"5px"}
                >
                  <ButtonBase
                    onClick={() => {
                      formik.setFieldValue(
                        "childrenPax",
                        formik.values.childrenPax - 1
                      );
                      formik.setFieldValue(
                        "adultPax",
                        formik.values.adultPax + 1
                      );
                    }}
                    disabled={formik.values.childrenPax === 0}
                  >
                    <Box
                      display={"flex"}
                      height={"100%"}
                      width={"40px"}
                      bgcolor={"black"}
                      borderRight={1}
                      borderColor={theme.palette.primary.main}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Remove />
                    </Box>
                  </ButtonBase>
                  <TextField
                    size={"small"}
                    type={"number"}
                    name={"childrenPax"}
                    value={formik.values.childrenPax}
                    onChange={formik.handleChange}
                    sx={{
                      width: "100%",
                      borderColor: theme.palette.primary.main,
                      backgroundColor: "#232323",
                      ".MuiInputBase-input": {
                        border: "none",
                      },
                    }}
                  />
                  <ButtonBase
                    onClick={() => {
                      formik.setFieldValue(
                        "childrenPax",
                        formik.values.childrenPax + 1
                      );
                      formik.setFieldValue(
                        "adultPax",
                        formik.values.adultPax - 1
                      );
                    }}
                    disabled={formik.values.adultPax <= 1}
                  >
                    <Box
                      display={"flex"}
                      height={"100%"}
                      width={"40px"}
                      bgcolor={"black"}
                      borderLeft={1}
                      borderColor={theme.palette.primary.main}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Add />
                    </Box>
                  </ButtonBase>
                </Box>
              </Stack>
            </Stack>

            <Stack direction={"row"} width={"100%"} spacing={2}>
              <Stack
                direction={"row"}
                spacing={"118px"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"50%"}
              >
                <Typography width={"120px"} variant="h4">
                  Booking Source
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  width={"100%"}
                  border={2}
                  borderColor={theme.palette.primary.main}
                  borderRadius={"5px"}
                >
                  <Select
                    size={"small"}
                    value={formik.values.platform}
                    name={"platform"}
                    onChange={(event) => {
                      formik.setFieldValue("platform", event.target.value);
                      const selectedPlatform = allPlatforms.filter(
                        (platform) => platform.platform === event.target.value
                      );

                      formik.setFieldValue(
                        "platformId",
                        selectedPlatform[0].platformId
                      );
                      if (!event.target.value.includes("OTA -")) {
                        formik.setFieldValue("otaRefNo", null);
                      } else {
                      }
                    }}
                    sx={{
                      width: "100%",
                    }}
                  >
                    {allPlatforms.map((item) => (
                      <MenuItem key={item.platformId} value={item.platform}>
                        {item.platform}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Stack>
              <Stack
                direction={"row"}
                spacing={"118px"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"50%"}
              >
                <Typography width={"120px"} variant="h4">
                  OTA Ref No.
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  width={"100%"}
                  border={2}
                  borderColor={theme.palette.primary.main}
                  borderRadius={"5px"}
                >
                  <TextField
                    disabled={!formik.values.platform.includes("OTA -")}
                    size={"small"}
                    value={formik.values.otaRefNo}
                    onChange={formik.handleChange}
                    name={"otaRefNo"}
                    sx={{
                      width: "100%",
                      borderColor: theme.palette.primary.main,
                      color: "white",
                    }}
                  />
                </Box>
              </Stack>
            </Stack>
            <Typography variant="h2" sx={{ marginTop: "30px" }}>
              Rooms & Services
            </Typography>
            <Grid
              container
              alignItems={"center"}
              sx={{
                padding: "2em",
                border: 3,
                borderColor: theme.palette.primary.main,
                marginTop: "25px",
              }}
            >
              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                spacing={2}
                sx={{
                  margin: "auto",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                {roomBookingArray.map((item) => (
                  <Grid item key={item.room_id} md={6}>
                    <BookingInfoRooms roomBookings={item} />
                  </Grid>
                ))}
              </Grid>
              <Grid
                container
                sx={{
                  width: "40%",
                  marginTop: "20px",
                }}
              >
                <Grid item md={5} sx={{ marginRight: "10px" }}>
                  <Grid
                    container
                    sx={{
                      border: 1,
                      borderColor: theme.palette.primary.main,
                    }}
                  >
                    <Grid
                      item
                      md={7}
                      sx={{
                        borderRight: 3,
                        borderColor: theme.palette.primary.main,
                        padding: "0.5em",
                      }}
                    >
                      <Typography variant={"h5"}>Locker</Typography>
                    </Grid>
                    <Grid
                      item
                      md={5}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        padding: "0.5em",
                      }}
                    >
                      <Typography variant={"h5"}>
                        {roomBookingArray.length}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={5}>
                  <Grid
                    container
                    sx={{
                      border: 1,
                      borderColor: theme.palette.primary.main,
                    }}
                  >
                    <Grid
                      item
                      md={7}
                      sx={{
                        borderRight: 3,
                        borderColor: theme.palette.primary.main,
                        padding: "0.5em",
                      }}
                    >
                      <Typography variant={"h5"}>Shower</Typography>
                    </Grid>
                    <Grid
                      item
                      md={5}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        padding: "0.5em",
                      }}
                    >
                      <Typography variant={"h5"}>
                        {formik.values.adultPax}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Stack>
        <Button
          variant="outlined"
          type={"submit"}
          sx={{
            width: "12%",
            height: "60px",
            backgroundColor: "#232323",
            border: 1,
            borderColor: theme.palette.primary.main,
            borderRadius: 0,
          }}
        >
          Continue
        </Button>
        <CustomerBookedModal
          isOpen={openCustomerBookedModal}
          handleCloseModal={handleCloseCustomerBookedModal}
          setCustomerBooked={setCustomerBooked}
          setStepper={setStepper}
          roomBookingInfo={formik.values}
          roomBookingArray={roomBookingArray}
          setBookingId={setBookingId}
        />
      </Stack>
    </form>
  );
};

export default BookingInfoForm;
