import {
  Box,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LockerTabContent from "../../components/lockers/LockerTabContent";
import LockerZoneTabContent from "../../components/lockers/LockerZoneTabContent";
import LockerRateTabContent from "../../components/lockers/LockerRateTabContent";
import axiosInstance from "../../constants/axiosConfig";
import { INewLockerZones } from "../../models/lockers/LockerInterface";
import Notification from "../../utils/notificationConfig";
import { Luggage } from "@mui/icons-material";

const NewLockerPage = () => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);

  const [tabStepperValue, setTabStepperValue] = useState(0);

  const [lockerZones, setLockerZones] = useState<INewLockerZones[]>([]);

  const handleStepperChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabStepperValue(newValue);
  };

  const getLockerZoneData = () => {
    const apiUrlParams = new URLSearchParams();
    apiUrlParams.append("withLockerCount", true.toString());
    axiosInstance
      .get(`/lockers/zone?${apiUrlParams.toString()}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setLockerZones(
            res.data.filter((data: INewLockerZones) => data.isArchive === false)
          );
        }
      })
      .catch((res) => {
        Notification.failed(res.response.data.msg);
      });
  };

  useEffect(() => {
    getLockerZoneData();
  }, []);

  return (
    <Stack width={"100%"} height={"100vh"}>
      <Typography variant="h1" marginBottom={2}>
        Locker Management
      </Typography>
      <Tabs
        value={tabStepperValue}
        onChange={handleStepperChange}
        sx={{
          marginBottom: 5,
          "& .MuiTab-root": {
            minWidth: "30.4vw",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "1.3rem",
            "&.Mui-selected": {
              color: theme.palette.primary.main,
            },
            "&:not(.Mui-selected)": {
              color: "white",
              borderBottom: "1px solid #0a443c",
            },
          },
        }}
      >
        <Tab value={0} label={"LOCKER"} />
        <Tab value={1} label={"LOCKER ZONE"} />
        <Tab value={2} label={"LOCKER RATE"} />
      </Tabs>
      <Grid
        container
        width={"100%"}
        border={2}
        borderColor={theme.palette.primary.main}
      >
        <Grid
          item
          md={2}
          sm={12}
          xs={12}
          sx={{
            borderRight: 2,
            borderColor: theme.palette.primary.main,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            minHeight: "100%",
          }}
        >
          <Stack
            direction={"row"}
            width={"100%"}
            minHeight={"3.5rem"}
            alignItems={"center"}
            paddingLeft={2}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
            sx={{
              backgroundColor: theme.palette.background.default,
              position: "sticky",
              zIndex: 1,
              top: 0,
            }}
          >
            <Typography variant={"h3"} color={theme.palette.primary.main}>
              Overview
            </Typography>
          </Stack>
          <Stack direction={"column"} padding={2} spacing={2}>
            {lockerZones.map((lockerZone, index) => (
              <Stack direction={"column"} spacing={1}>
                <Typography variant="h4" color={"white"}>
                  {lockerZone.name}
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  bgcolor={"white"}
                  padding={"3px"}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    bgcolor={"black"}
                    width={"50%"}
                    padding={"2px"}
                  >
                    <Luggage />
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={"50%"}
                  >
                    <Typography fontWeight={"bold"} color={"black"}>
                      {lockerZone.lockerCount}
                    </Typography>
                  </Box>
                </Box>
                {lockerZones.length - 1 !== index && (
                  <Divider
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      borderBottomWidth: 2,
                    }}
                  />
                )}
              </Stack>
            ))}
          </Stack>
        </Grid>
        <Grid item md={10} sm={12} xs={12}>
          <Stack direction={"column"} height={"100%"}>
            {tabStepperValue === 0 ? (
              <LockerTabContent setLockerZones={setLockerZones} />
            ) : tabStepperValue === 1 ? (
              <LockerZoneTabContent />
            ) : tabStepperValue === 2 ? (
              <LockerRateTabContent />
            ) : null}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default NewLockerPage;
