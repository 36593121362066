import { useEffect, useState } from "react";
import { store } from "../../redux/store";
import axiosInstance from "../../constants/axiosConfig";
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import ManagementModalFormNumberField from "../management/ManagementModalFormNumberField";
import Notification from "../../utils/notificationConfig";

interface TaxChargesProps {
  ServiceChargesDesc?: string | undefined;
  onCompulsoryTaxVisibilityChange: (isVisible: boolean) => void;
  ServiceChargesId?: number;
  ServiceName?: string | undefined;
  SSTDesc?: string | undefined;
  SSTId?: number;
  SSTName?: string | undefined;
}

interface ILabelAndValue {
  name: string;
  value: number;
}

interface ICompulsoryTaxDetail {
  serviceCharge: ILabelAndValue;
  SST: ILabelAndValue;
}

export default function CompulsoryTax({
  ServiceChargesDesc,
  onCompulsoryTaxVisibilityChange,
  ServiceChargesId,
  ServiceName,
  SSTDesc,
  SSTId,
  SSTName,
}: TaxChargesProps) {
  const theme = useTheme();
  const handleButtonClick = () => {
    // Pass the boolean value to the parent component
    onCompulsoryTaxVisibilityChange(false);
  };

  const formikInitialValue: ICompulsoryTaxDetail = {
    serviceCharge: {
      name: ServiceName ? ServiceName : "",
      value: Number(ServiceChargesDesc?.replace("%", "")),
    },
    SST: {
      name: SSTName ? SSTName : "",
      value: Number(SSTDesc?.replace("%", "")),
    },
  };

  const token = store.getState().user.accessToken;

  const submitServiceChargeDetail = async (sc: ILabelAndValue) => {
    try {
      const payload = {
        settingsId: String(ServiceChargesId),
        settingsName: sc.name,
        newDescription: `${sc.value}%`,
      };

      const response = await axiosInstance.put(
        `/lot-settings/edit-settings-description/`,
        payload,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (sc.name !== ServiceName) {
        Notification.success(
          "New service charge name will be used across all POA"
        );
      }

      console.log("Settings updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  const submitSSTDetail = async (sst: ILabelAndValue) => {
    try {
      const payload = {
        settingsId: String(SSTId),
        settingsName: sst.name,
        newDescription: `${sst.value}%`,
      };

      const response = await axiosInstance.put(
        `/lot-settings/edit-settings-description/`,
        payload,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (sst.name !== ServiceName) {
        Notification.success("New tax name will be used across all POA");
      }

      console.log("Settings updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };
  const handleSubmit = (values: ICompulsoryTaxDetail) => {
    submitServiceChargeDetail(values.serviceCharge)
      .then(() => submitSSTDetail(values.SST))
      .then(() => {
        Notification.success("Edit Successful");
      });
  };
  return (
    <Grid container width={"100%"} padding={5} spacing={2}>
      {/**Room Setting Section */}
      <Grid item xs={1}>
        <Button onClick={handleButtonClick}>Back</Button>
      </Grid>
      <Grid item xs={11}>
        <Typography variant="h3">Edit Tax & Charges</Typography>
      </Grid>

      <Formik
        initialValues={formikInitialValue}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          handleSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Stack paddingX={3} paddingY={1} spacing={3} width={"100%"}>
              <Stack direction={"row"} alignItems={"center"} spacing={3}>
                <Typography variant="h4" width={"180px"}>
                  Name
                </Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  value={values.serviceCharge.name}
                  name="serviceCharge.name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  inputProps={{
                    style: { textTransform: "none" },
                  }}
                />
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={3}
                marginBottom={2}
              >
                <Typography variant="h4" width={"180px"}>
                  Value
                </Typography>
                <ManagementModalFormNumberField
                  label="Service Charges"
                  value={values.serviceCharge.value}
                  name={"serviceCharge.value"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  textFieldType={"number"}
                  endAdornment={
                    <Typography fontWeight={600} paddingLeft={"5em"}>
                      %
                    </Typography>
                  }
                  setFieldValue={setFieldValue}
                  withoutLabel={true}
                />
              </Stack>

              <Divider
                orientation="horizontal"
                flexItem
                sx={{
                  borderBottomWidth: 3.5,
                  borderColor: "#232323",
                }}
              />

              <Stack direction={"row"} alignItems={"center"} spacing={3}>
                <Typography variant="h4" width={"180px"}>
                  Name
                </Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  value={values.SST.name}
                  name="SST.name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  inputProps={{
                    style: { textTransform: "none" },
                  }}
                />
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={3}
                marginBottom={2}
              >
                <Typography variant="h4" width={"180px"}>
                  Value
                </Typography>
                <ManagementModalFormNumberField
                  label="Service Charges"
                  value={values.SST.value}
                  name={"SST.value"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  textFieldType={"number"}
                  endAdornment={
                    <Typography fontWeight={600} paddingLeft={"5em"}>
                      %
                    </Typography>
                  }
                  setFieldValue={setFieldValue}
                  withoutLabel={true}
                />
              </Stack>

              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
          </form>
        )}
      </Formik>
    </Grid>
  );
}
