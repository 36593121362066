import {
	Box,
	Button,
	ButtonBase,
	Checkbox,
	CssBaseline,
	Grid,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { ReactComponent as OnlyLogoSVG } from "../../assets/images/Logo.svg";
import { useFormik } from "formik";
import axiosInstance from "../../constants/axiosConfig";
import { useEffect, useRef, useState } from "react";
import Notification from "../../utils/notificationConfig";
import { Feedback } from "@mui/icons-material";
import ManagementModalHeader from "../../components/management/ManagementModalHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import theme from "../../assets/theme/theme";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const ratingBar = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const initialValues = {
	rating: "",
	feedback: "",
};

interface FeedbackInfo {
	id: number;
	bookingId: string;
	bookingNo: string;
	rating: string;
	feedback: string;
	roomSelected: string;
	booking: string;
}

type props = {
	handleDocumentModalClose: () => void;
	handleBack: () => void;
	bookingId: string;
};

const FeedbackFormPage = ({
	handleDocumentModalClose,
	handleBack,
	bookingId,
}: props) => {
	const formik = useFormik({
		initialValues: initialValues,

		onSubmit: (values) => {},
	});

	const [feedback, setFeedback] = useState<FeedbackInfo[]>([]);
	const handleFetchFeedback = () => {
		const apiUrl = `/bookings/get-feedback-by-booking/${bookingId}/`;
		axiosInstance
			.get(apiUrl)
			.then((response) => {
				console.log("print", response.data.data);
				setFeedback(response.data.data);
			})
			.catch((error) => {
				Notification.failed("There is no feedback available for this booking");
			})
			.finally(() => {});
	};
	useEffect(() => {
		console.log(bookingId);
		handleFetchFeedback();
	}, []);

	const [selectedFeedback, setSelectedFeedback] = useState<number>(0);

	const componentRef = useRef<any>([]);

	const handleDownload = () => {
		if (componentRef.current[selectedFeedback]) {
			const content = componentRef.current[selectedFeedback];

			html2canvas(content) // Adjust the scale for better quality if needed
				.then((canvas) => {
					const contentWidth = canvas.width * 0.2; // Adjust the width as needed
					const contentHeight = (canvas.height * contentWidth) / canvas.width;
					const pdf = new jsPDF("l", "mm", [contentWidth, contentHeight]);
					pdf.addImage(
						canvas.toDataURL("image/png"),
						"PNG",
						0,
						0,
						contentWidth,
						contentHeight
					);
					pdf.save(`FeedbackForm.pdf`);
				})
				.catch((error) => {
					console.error("Error converting to canvas:", error);
				});
		}
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current[selectedFeedback]!,
		pageStyle: `
    @page {
      size: auto; 
      margin: 0;
    }
    body {
      margin: 0;
    }
  `,
	});
	return (
		<>
			<Grid
				container
				width={"100%"}
				sx={{ p: 0, height: 500 }}
				direction={"row"}>
				<Grid item xs={12}>
					{" "}
					<ManagementModalHeader
						title={"Feedback Form"}
						handleModalClose={handleDocumentModalClose}
					/>
				</Grid>
				<Grid item xs={2} p={2}>
					<Button
						startIcon={<ArrowBackIcon />}
						onClick={handleBack}
						sx={{ m: 1 }}>
						<Typography variant="button">{"Back"}</Typography>
					</Button>
				</Grid>
				<Grid
					item
					xs={10}
					p={2}
					container
					direction={"row"}
					alignContent={"end"}
					justifyContent={"end"}>
					<Button
						startIcon={<FileDownloadIcon />}
						variant="outlined"
						color="primary"
						sx={{ m: 1 }}
						onClick={handleDownload}>
						<Typography variant="button">Export</Typography>
					</Button>
					<Button
						startIcon={<PrintIcon />}
						variant="outlined"
						color="primary"
						sx={{ m: 1 }}
						onClick={handlePrint}>
						<Typography variant="button">Print</Typography>
					</Button>
				</Grid>
				<Grid item xs={12}>
					{" "}
					<Stack direction={"column"} spacing={2} marginTop={"1px"}>
						{" "}
						{feedback.map((feedback, index) => (
							<Box
								key={index}
								padding={1}
								width={"100%"}
								sx={{
									border: selectedFeedback === index ? 2 : 0,
									borderColor: theme.palette.primary.main,
								}}>
								<ButtonBase
									ref={(el) => (componentRef.current[index] = el)}
									onClick={() => setSelectedFeedback(index)}>
									<ShowFeedbackForm key={feedback.id} feedback={feedback} />
								</ButtonBase>
							</Box>
						))}
					</Stack>
				</Grid>
			</Grid>
		</>
	);
};

const ShowFeedbackForm = ({ feedback }: { feedback: FeedbackInfo }) => {
	const formik = useFormik({
		initialValues: initialValues,

		onSubmit: (values) => {},
	});

	return (
		<>
			<CssBaseline />
			<Box
				width={"100%"}
				height={"100vh"}
				paddingY={4}
				paddingX={"180px"}
				bgcolor={"#fff"}>
				<Stack
					direction={"row"}
					spacing={2}
					alignItems={"center"}
					marginBottom={4}>
					<OnlyLogoSVG className="logo" width={60} height={60} />
					<Typography variant="h1" color={"black"}>
						Feedback Form
					</Typography>
				</Stack>
				<Typography variant="h2" color={"black"} marginBottom={4}>
					Thanks for staying with us!
				</Typography>
				<Typography variant="h3" color={"black"} marginBottom={2}>
					1. How likely is that you would recommend this service to a friend or
					colleague?
				</Typography>
				<Stack
					direction={"row"}
					justifyContent={"space-between"}
					marginBottom={1}>
					<Typography variant="h4" color={"black"}>
						Not Likely
					</Typography>
					<Typography variant="h4" color={"black"}>
						Extremely Likely
					</Typography>
				</Stack>
				<Grid
					container
					columns={{ xs: 11, sm: 11, md: 11, lg: 11, xl: 11 }}
					marginBottom={3}>
					{ratingBar.map((rating) => (
						<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
							<Box
								// onClick={() => {
								// 	handleRatingPress(rating);
								// }}
								sx={{ width: "100%" }}>
								<Box
									display={"flex"}
									height={"50px"}
									width={"100%"}
									justifyContent={"center"}
									alignItems={"center"}
									border={1}
									bgcolor={feedback.rating === rating ? "black" : "white"}
									borderColor={feedback.rating === rating ? "white" : "black"}>
									<Typography
										color={feedback.rating === rating ? "white" : "black"}
										variant="h4">
										{rating}
									</Typography>
								</Box>
							</Box>
						</Grid>
					))}
				</Grid>
				<Typography variant="h3" color={"black"} marginBottom={2}>
					2. Is there anything you would like to highlight to the management?
				</Typography>
				<TextField
					size="small"
					multiline
					rows={14}
					fullWidth
					value={feedback.feedback}
					sx={{
						marginBottom: 1,
						".MuiInputBase-input": {
							color: "black",
							backgroundColor: "white",
							"&:focus": {
								borderColor: "#fff", // Border color on focus
							},
						},
					}}>
					{feedback.feedback}
				</TextField>
				<Typography variant="h4" color={"black"} marginBottom={1}>
					This feedback applies to Room {feedback.roomSelected}
				</Typography>
			</Box>
		</>
	);
};

export default FeedbackFormPage;
