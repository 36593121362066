import React from "react";
import { Box, Skeleton, Stack, useTheme } from "@mui/material";

function PosSkeletonLoading() {
  const theme = useTheme();
  return (
    <Box
      height={"100%"}
      width={"100%"}
      border={2}
      borderColor={theme.palette.primary.main}
    >
      <Skeleton
        variant="rectangular"
        height={30}
        sx={{ backgroundColor: "rgba(255, 255, 255, 0.3)", my: 1 }}
      />
      <Stack direction={"row"} spacing={1}>
        <Skeleton
          variant="rectangular"
          height={"140px"}
          width={"60%"}
          sx={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}
        />
        <Skeleton
          variant="rectangular"
          height={"140px"}
          width={"40%"}
          sx={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}
        />
      </Stack>
      <Skeleton
        variant="rectangular"
        height={"438px"}
        sx={{ backgroundColor: "rgba(255, 255, 255, 0.3)", my: 1 }}
      />
    </Box>
  );
}

export default PosSkeletonLoading;
