import { mdiFilterOutline } from "@mdi/js";
import Icon from "@mdi/react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import AddItemToPOAModal from "../../components/merch/AddItemModalToPOA";
import PaymentForm from "../../components/payment/PaymentForm";
import { transactionCategoryEnum } from "../../constants/enums";
import {
  IPaymentGuests,
  IPaymentItem,
  IPayments,
} from "../../models/payment/PaymentInterface";
import { store } from "../../redux/store";
import {
  calculateTimeDifferenceInHours,
  consolidateItems,
} from "../../utils/functions";
import { BookingResult, RoomBooking } from "../booking/interface";

import AddIcon from "@mui/icons-material/Add";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import IosShareIcon from "@mui/icons-material/IosShare";
import LensIcon from "@mui/icons-material/Lens";
import PaymentsIcon from "@mui/icons-material/Payments";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";

import SortIcon from "@mui/icons-material/Sort";
interface ITransactionStats {
  count: number;
  sum: number;
}

const bookingResultEmpty: BookingResult = {
  addedAt: "",
  bookingId: "",
  platform: {
    platformId: "",
    platform: "",
  },
  roomBookings: [
    {
      actualCheckinDateTime: "",
      actualCheckoutDateTime: "",
      derivedCheckInDatetime: "",
      derivedCheckOutDatetime: "",
      booking: "",
      details: "",
      personInCharge: "",
      room: "",
      roomBookingId: "",
      roomCode: "",
      roomType: "",
      roomZone: "",
      status: "",
      sum: "",
      memberId: "",
      personInChargeCountry: "",
      personInChargeId: "",
      personInChargeLastname: "",
      roomStatus: "",
      roomTypeColorTags: "",
      roomTypeDetails: "",
      roomTypeId: "",
    },
  ],
  lockerBookings: [],
  bookingStatus: [
    {
      booking: "",
      bookingStatus: "",
      bookingStatusId: "",
      checkInDatetime: "",
      checkOutDatetime: "",
      isLatest: true,
      statusDatetime: "",
    },
  ],
  bookingNo: "",
  bookingMadeDatetime: "",
  customerBooked: "",
  adultPax: 0,
  childPax: 0,
  details: "",
  sum: "",
  otaCode: "",
  showerBookings: [],
  customerStaying: "",
};

export default function GuestOwnPay() {
  const theme = useTheme();
  const { bookingId } = useParams();
  const navigate = useNavigate();

  const location: Location = useLocation();
  const { state } = location;

  const bookingData: BookingResult = state ? state.data : bookingResultEmpty;

  const duration = calculateTimeDifferenceInHours(
    bookingData.bookingStatus[0].checkInDatetime,
    bookingData.bookingStatus[0].checkOutDatetime
  );

  const [searchQuery, setSearchQuery] = useState("");

  const latestBookingStatus = bookingData.bookingStatus.filter(
    (status) => status.isLatest === true
  )[0].bookingStatus;

  const rooms: IPaymentItem[] = bookingData.roomBookings.map((room) => ({
    itemId: room.room,
    itemName: room.roomCode,
    itemType: room.roomType,
    category: transactionCategoryEnum.roomSales,
    quantity: 1,
    duration: duration,
    price: parseFloat(room.sum),
  }));

  const lockers: IPaymentItem[] = bookingData.lockerBookings.map((locker) => ({
    itemId: locker.locker.lockerId,
    itemName: locker.locker.code,
    itemType: "Locker",
    category: transactionCategoryEnum.lockerSales,
    quantity: 1,
    duration: duration,
    price: parseFloat(locker.sum),
  }));

  const unconsolidatedShower = bookingData.showerBookings.map((shower) => ({
    itemId: shower.showerBookingId,
    itemName: "Shower Access",
    itemType: "Shower",
    category: transactionCategoryEnum.showerSales,
    quantity: 1,
    duration: duration,
    price: parseFloat(shower.sum),
  }));

  const showers: IPaymentItem[] = consolidateItems(unconsolidatedShower);

  const token = store.getState().user.accessToken;

  const customerStaying: IPaymentGuests = {
    customerStayingId: bookingData.customerStaying,
    customerStayingName: bookingData.customerBooked,
    guests: bookingData.roomBookings.map((room: RoomBooking) => ({
      guestId: room.personInChargeId!,
      guestName: room.personInCharge,
      isMember: room.memberId !== "None" ? true : false,
    })),
  };

  const [openPOAModal, setOpenPOAModal] = useState(false);

  const [transactionCompleted, setTransactionsCompleted] = useState<
    IPayments[]
  >([]);

  const handleOpenPOA = () => {
    setOpenPOAModal(true);
  };

  const handleClosePOA = () => {
    setOpenPOAModal(false);
  };

  const statInitialValues: ITransactionStats = {
    count: 0,
    sum: 0,
  };

  const [paidTransactionsStat, setPaidTransactions] =
    useState<ITransactionStats>(statInitialValues);
  const [pendingTransactionsStat, setPendingTransactions] =
    useState<ITransactionStats>(statInitialValues);
  const [voidTransactionsStat, setVoidTransactions] =
    useState<ITransactionStats>(statInitialValues);
  const [refundTransactionsStat, setRefundTransactions] =
    useState<ITransactionStats>(statInitialValues);

  useEffect(() => {
    const paidTransactions = transactionsData.filter(
      (transaction) => transaction.transactionStatus === "Paid"
    );

    const pendingTransactions = transactionsData.filter(
      (transaction) => transaction.transactionStatus === "Pending Payment"
    );

    const voidTransactions = transactionsData.filter(
      (transaction) => transaction.transactionStatus === "Void"
    );

    const refundTransactions = transactionsData.filter(
      (transaction) => transaction.transactionStatus === "Refund"
    );
    setPaidTransactions({
      count: paidTransactions.length,
      sum: getSum(paidTransactions, true),
    });
    setPendingTransactions({
      count: pendingTransactions.length,
      sum: getSum(pendingTransactions, false),
    });
    setVoidTransactions({
      count: voidTransactions.length,
      sum: getSum(voidTransactions, false),
    });
    setRefundTransactions({
      count: refundTransactions.length,
      sum: getSum(refundTransactions, true),
    });
  }, [transactionCompleted]);

  const getSum = (transaction: IPayments[], paid: boolean) => {
    let sum = 0;

    if (paid) {
      sum = transaction.reduce((acc, payment) => payment.debitAmount, 0);
    } else {
      sum = transaction.reduce((acc, payment) => payment.creditAmount, 0);
    }
    return sum;
  };
  const [transactionsData, setTransactions] = useState<IPayments[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filteredTransaction, setFilteredTransaction] = useState<IPayments[]>(
    []
  );
  const [status, setStatus] = useState<string>("all");
  const [sortingOrder, setSortingOrder] = useState("ascending"); // State to manage sorting order

  const fetchTransactions = () => {
    setIsLoading(true);
    axiosInstance
      .get(`/transaction/get-by-booking-id/${bookingId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            const dataTransaction: IPayments[] = res.data.map((data: any) => ({
              transactionId: data.transactionId,
              invoiceNo: data.invoiceNo,
              transactionStatus: data.transactionStatus,
              sum: parseFloat(data.sum).toFixed(2),
              amountPaid: parseFloat(data.debitAmount).toFixed(2),
              status: data.transactionStatus,
              paymentType: data.paymentType,
              customer: data.customer,
              transactionDatetime: data.transactionDatetime,
              creditAmount: parseFloat(data.creditAmount).toFixed(2),
              debitAmount: parseFloat(data.debitAmount).toFixed(2),
              items: data.items,
              isRoomBooking: data.isRoomBooking,
              paymentTypeName: data.paymentType.paymentType,
              paymentRemarks: data.paymentRemarks,
            }));

            const filterPaymentType = dataTransaction.filter(
              (transaction) =>
                !(
                  transaction.paymentTypeName === "Channel Collect" ||
                  transaction.paymentRemarks
                    ?.toLowerCase()
                    .includes("channel collect")
                )
            );
            setTransactions(filterPaymentType);

            const filteredTransactions = dataTransaction.filter(
              ({ transactionStatus }) =>
                ["all", status].includes(transactionStatus)
            );
            setFilteredTransaction(filteredTransactions);
          }
        }
      })
      .catch((e) => Notification.failed(e.response.data.msg))
      .finally(() => setIsLoading(false));
  };
  const generateInvoice = () => {
    const url = "/transaction/generate-invoices/";
    const data = {
      transactionIds: transactionsData
        .filter((transaction) =>
          transaction.items.some(
            (item) => item.category === transactionCategoryEnum.roomSales
          )
        )
        .map((transaction) => transaction.transactionId),
    };
    axiosInstance
      .post(url, data, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) =>
        response.data.pdfs.map(atob).forEach((pdf: string, index: number) => {
          // Convert decoded content to Uint8Array
          const bytes = new Uint8Array(pdf.length);
          for (let i = 0; i < pdf.length; i++) bytes[i] = pdf.charCodeAt(i);
          const pdfBlob = new Blob([bytes], { type: "application/pdf" });

          // Create anchor element to trigger download
          const link = document.createElement("a");
          link.href = URL.createObjectURL(pdfBlob); // Create download link
          link.download = `Invoice_${index + 1}.pdf`;
          link.click(); // Start downloading

          URL.revokeObjectURL(link.href); // Clean up by revoking the object URL
        })
      )
      .catch((reason) => Notification.failed(reason.response.data.message));
  };

  useEffect(() => {
    fetchTransactions();
  }, [status, sortingOrder]);

  const [showInvoiceModal, setShowInvoiceModal] = useState(false);

  const openInvoiceModal = () => {
    setShowInvoiceModal(true);
  };

  const closeInvoiceModal = () => {
    setShowInvoiceModal(false);
  };

  const handleDeleteRefresh = (paymentItem: IPaymentItem) => {
    let modifyBookingData = bookingData;
    if (paymentItem.category === transactionCategoryEnum.roomSales) {
      modifyBookingData = {
        ...modifyBookingData,
        roomBookings: bookingData.roomBookings.filter(
          (roomBooking) => roomBooking.room !== paymentItem.itemId
        ),
      };
    } else if (paymentItem.category === transactionCategoryEnum.lockerSales) {
      modifyBookingData = {
        ...modifyBookingData,
        lockerBookings: bookingData.lockerBookings.filter(
          (lockerBooking) =>
            lockerBooking.locker.lockerId !== paymentItem.itemId
        ),
      };
    } else if (paymentItem.category === transactionCategoryEnum.showerSales) {
      modifyBookingData = {
        ...modifyBookingData,
        showerBookings: bookingData.showerBookings.slice(0, -1),
      };
    }

    navigate(location.pathname, {
      state: { data: modifyBookingData },
    });
  };

  return (
    <Box
      width={"80%"}
      margin={"30px auto"}
      marginBottom={-4}
      display={"flex"}
      sx={{
        alignItems: "center",
        border: 1,
        borderColor: "#15ffdc",
        // p: 2,
      }}
      justifyContent={"center"}
    >
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box
            display={"flex"}
            sx={{
              alignItems: "center",
              p: 2,
            }}
            justifyContent={"center"}
          >
            <Stack direction="row" spacing={10} justifyContent="center">
              <Box sx={{ width: "150px" }}>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  margin={2}
                  alignContent="center"
                  alignItems="center"
                >
                  {" "}
                  <Grid item xs={6}>
                    <Typography variant="h4">Total Invoices</Typography>
                  </Grid>{" "}
                  <Grid item xs={6}>
                    <Typography variant="h4">
                      {paidTransactionsStat.count +
                        pendingTransactionsStat.count +
                        refundTransactionsStat.count +
                        voidTransactionsStat.count}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ width: "150px" }}>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  margin={2}
                  alignContent={"center"}
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <LensIcon color="primary" />
                  </Grid>
                  <Grid item xs={5}>
                    {" "}
                    <Typography variant="h4">Paid</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {" "}
                    <Typography variant="h4">
                      {paidTransactionsStat.count}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} justifyContent="center">
                    <Typography variant="h3">
                      MYR {paidTransactionsStat.sum}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ width: "150px" }}>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  margin={2}
                  alignContent="center"
                  alignItems="center"
                >
                  {" "}
                  <Grid item xs={2}>
                    {" "}
                    <RadioButtonUncheckedIcon color="primary" />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="h4">Pending</Typography>
                  </Grid>{" "}
                  <Grid item xs={5}>
                    <Typography variant="h4">
                      {pendingTransactionsStat.count}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <Typography variant="h3">
                      MYR {pendingTransactionsStat.sum}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ width: "150px" }}>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  margin={2}
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <DoDisturbIcon color="primary" />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="h4">Void</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="h4">
                      {voidTransactionsStat.count}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <Typography variant="h3">
                      MYR {voidTransactionsStat.sum}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ width: "150px" }}>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  margin={2}
                  alignContent="center"
                  alignItems="center"
                >
                  {" "}
                  <Grid item xs={2}>
                    {" "}
                    <LensIcon color="error" />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="h4">Refund</Typography>
                  </Grid>{" "}
                  <Grid item xs={5}>
                    <Typography variant="h4">
                      {refundTransactionsStat.count}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <Typography variant="h3">
                      MYR {refundTransactionsStat.sum}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} bgcolor={"#141414"}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            padding={2}
            borderBottom={2}
            borderColor={theme.palette.background.default}
          >
            <Button
              variant="outlined"
              sx={{ width: "300px", height: "50px" }}
              size="large"
              onClick={generateInvoice}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <IosShareIcon color="inherit" sx={{ fontSize: "1.3rem" }} />
                <Typography variant="h4" color={"primary"}>
                  Export Invoices
                </Typography>
              </Stack>
            </Button>
            <Button
              onClick={handleOpenPOA}
              variant="outlined"
              sx={{ width: "300px", height: "50px" }}
              size="large"
            >
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <AddIcon sx={{ fontSize: "1.3rem" }} />
                <Typography variant="h4" color={"primary"}>
                  Add Item
                </Typography>
              </Stack>
            </Button>
            <Button
              // onClick={() => {
              //   const pendingTransactions = transactionsData.filter(
              //     ({ transactionStatus }) =>
              //       transactionStatus === "Pending Payment"
              //   );
              //   console.log(pendingTransactions);
              // }}
              variant="contained"
              sx={{ width: "300px", height: "50px" }}
              size="large"
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <PaymentsIcon sx={{ fontSize: "1.3rem" }} />
                <Typography variant="h4" color="black">
                  Payment
                </Typography>
              </Stack>
            </Button>
          </Stack>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              id="panel1-header"
              sx={{ backgroundColor: "#141414" }} // Set black background color to summary
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                marginX={2}
              >
                <Icon path={mdiFilterOutline} size={1} color={"white"} />
                <Typography variant="h3">Invoice</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={4}>
                  <TextField
                    label="Search by Invoice Number"
                    value={searchQuery}
                    onChange={(event) =>
                      setSearchQuery(event.target.value as string)
                    }
                    fullWidth
                    variant="outlined"
                    margin="normal"
                  />

                  {/* <List>
										{transactionsData.map((transaction) => (
											<ListItem key={transaction.transactionId}>
												<ListItemText
													primary={`Invoice No: ${transaction.invoiceNo}`}>{`Invoice No: ${transaction.invoiceNo}`}</ListItemText>
											</ListItem>
										))}
									</List> */}
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="transaction-status-label">
                      Transaction Status
                    </InputLabel>
                    <Select
                      labelId="transaction-status-label"
                      id="transaction-status"
                      value={status}
                      onChange={(event) =>
                        setStatus(event.target.value as string)
                      }
                      defaultValue="all"
                      label="Transaction Status"
                    >
                      <MenuItem value="all">All</MenuItem>
                      {transactionsData.map((transaction) => (
                        <MenuItem
                          key={transaction.transactionId}
                          value={transaction.status}
                        >
                          {transaction.status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item justifyContent="center" alignItems="center">
                  <SortIcon color="primary" />
                </Grid>
                <Grid item xs={2}>
                  {" "}
                  <FormControl fullWidth>
                    <InputLabel id="Sort">Sort</InputLabel>
                    <Select
                      labelId="Sort"
                      id="Sort"
                      value={sortingOrder}
                      onChange={(event) =>
                        setSortingOrder(event.target.value as string)
                      }
                      label="Sort"
                    >
                      {" "}
                      <MenuItem value="ascending">Ascending</MenuItem>
                      <MenuItem value="descending">Descending</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          {bookingId && (
            <PaymentForm
              key={"GOPPaymentForm"}
              bookingId={bookingId.toLowerCase()}
              paymentItem={
                bookingData.roomBookings.length > 0
                  ? rooms
                  : [...lockers, ...showers]
              }
              bookingGuest={customerStaying}
              getPastTransactions={true}
              isExpressBooking={false}
              refetchMerch={openPOAModal}
              getTransactionCompleted={setTransactionsCompleted}
              latestBookingStatus={latestBookingStatus}
              payment={filteredTransaction}
              sorting={sortingOrder}
              searchQuery={searchQuery}
              isCityLedger={false}
              bookingData={bookingData}
              handleDeleteRefresh={handleDeleteRefresh}
            />
          )}
        </Grid>
        {/* <Grid>
					{showInvoiceModal && (
						<InvoiceModal
							invoiceModalClose={closeInvoiceModal}
							invoice={pdfContent}
						/>
					)}
				</Grid> */}
      </Grid>
      {bookingId && (
        <AddItemToPOAModal
          guestsListProp={customerStaying}
          isAddItemModalOpen={openPOAModal}
          paymentItemsProp={[]}
          bookingId={bookingId.toLowerCase()}
          handleAddItemModalOnClose={handleClosePOA}
          latestBookingStatus={latestBookingStatus}
          roomBooking={bookingData.roomBookings}
        />
      )}
    </Box>
  );
}
