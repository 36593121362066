import {
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Stack,
  TextField,
  Typography,
  darken,
} from "@mui/material";
import BookingInfoRooms from "../ganttchart/BookingInfoRooms";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import theme from "../../assets/theme/theme";
import { styled } from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  IBooking,
  IBookingDraft,
  IBookingInfo,
  IGuestInfo,
  IStepper,
} from "../../models/bookings/Booking";
import { FormikErrors } from "formik";
import axios from "axios";
import BASE_API from "../../constants/api";
import { Delete, KeyboardReturn, Person } from "@mui/icons-material";
import ExpressExistingCustomerTableModal from "./ExpressExistingCustomerTableModal";
import Notification from "../../utils/notificationConfig";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../redux/store";
import ExpressNewGuestForm from "./ExpressNewGuestForm";
import {
  DeleteCustomer,
  EmptyExpressBookingRedux,
  UpdatePIC,
} from "../../redux/booking/expressbooking";
import { convertScannedDataToIGuestInfo } from "../../utils/convertScannedDatatoIGuestInfo";
import { useDebounce } from "../../hooks/useDebounce";
import { checkUnderAge } from "../../utils/functions";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import SearchIcon from "@mui/icons-material/Search";

type Props = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<IBooking>>;
  handleChangeStep: (step: IStepper) => void;
  roomBookingArray: IBookingDraft[];
  initialValues: IBookingInfo;
  bookingState: IBooking;
  triggerSubmit: () => void;
  picError: boolean;
  isSubmitting: boolean;
  value: IBookingInfo;
  loading: boolean;
};

const itemMarginX = 70;
const itemWidth = 450;

const Carousel = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "activeIndex",
})<{ activeIndex: number; limit: number }>(({ theme, activeIndex, limit }) => ({
  paddingTop: "20px",
  paddingBottom: "20px",
  position: "absolute",
  display: "flex",
  flexDirection: "row",
  width: "fit-content",
  left: `calc(50% - ${itemWidth / 2 + itemMarginX}px - ${
    (activeIndex > limit ? activeIndex - 1 : activeIndex) *
    (itemWidth + 2 * itemMarginX)
  }px)`,
  transition: "left 0.5s ease-in-out",
}));

const ExpressPICAssignation = ({
  setFieldValue,
  handleChangeStep,
  roomBookingArray,
  initialValues,
  picError,
  triggerSubmit,
  isSubmitting,
  value,
  bookingState,
  loading,
}: Props) => {
  const dispatch = useDispatch();
  const token = store.getState().user.accessToken;
  const accountId = useSelector((state: RootState) => state.user.accountId);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [roomBookingCount, setRoomBookingCount] = useState<number>(0);
  useEffect(() => {
    setRoomBookingCount(roomBookingArray.length);
  }, [roomBookingArray]);

  // const hanldeNextButtonOnclick = () => {
  //   console.log(roomBookingCount);
  //   setActiveIndex((prevActiveIndex) => {
  //     if (prevActiveIndex < roomBookingCount) {
  //       return prevActiveIndex + 1;
  //     } else {
  //       return 0;
  //     }
  //   });
  // };

  const hanldeNextButtonOnclick = () => {
    setActiveIndex((prevActiveIndex) => prevActiveIndex + 1);
    if (isNewGuestFormOpen) {
      setNewIsGuestForm(false);
    }
  };

  // console.log(activeIndex);

  // useEffect(() => {
  //   if (activeIndex > roomBookingArray.length - 1)
  //     handleOnSubmit({
  //       bookingInfo: initialValues,
  //       roomBookings: roomBookingArray,
  //     });
  // }, [activeIndex, roomBookingArray]);

  const [idInfoInputExpress, setidInfoInputExpress] = useState<string>("");
  const debouncedValue = useDebounce(idInfoInputExpress, 500);

  const handleIdInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setidInfoInputExpress(event.target.value);
  };

  const scannedData = convertScannedDataToIGuestInfo(debouncedValue);

  useEffect(() => {
    if (debouncedValue !== "" && scannedData.idNumber !== "") {
      if (
        scannedData.dateOfBirth &&
        checkUnderAge(scannedData.dateOfBirth, "scan")
      ) {
        Notification.failed("Guest assigned is below 18 years old");
      }
      axios
        .get(
          `${BASE_API}/guests/get_customer_by_id_no/?id_no=${scannedData.idNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          dispatch(
            UpdatePIC({
              customerStayingId: response.data.customerId,
              customerStayingName:
                response.data.firstname + response.data.lastname,
              customerStayingIc: response.data.idNo,
              customerStayingCountry: response.data.country.countryName,
              customerStayingEmail: response.data.email,
              customerStayingPhone: response.data.phoneNumber,
              customerStayingMemberId: response.data.member.memberId,
              customerStayingMemberTier: response.data.member.memberTier,
              customerStayingMemberCondition:
                response.data.member.memberCondition,
              rententionCount: 0,
              counter: activeIndex,
            })
          );
          //   // UpdatePIC({
          //   //   customerStayingId: "",
          //   //   customerStayingName:
          //   //   "",
          //   //   customerStayingIc: "",
          //   //   customerStayingMemberId: "",
          //   //   customerStayingMemberTier: "",
          //   //   customerStayingMemberCondition:
          //   //   "",
          //   //   counter: activeIndex,
          //   // })
          // );

          Notification.success("Guest Found");
          hanldeNextButtonOnclick();
        })
        .catch((err) => {
          console.log(err);
          // Notification.warning(err.response.data.error);
          Notification.warning("Guest Not Found, Please Add New Guest");
          handleSetGuestFormToOpen();
        });
    }
  }, [scannedData.idNumber]);

  const [isNewGuestFormOpen, setNewIsGuestForm] = useState<boolean>(false);
  const handleSetGuestFormToOpen = () => {
    setNewIsGuestForm(true);
  };

  const handleSetGuestFormToClose = () => {
    setNewIsGuestForm(false);
  };

  const [isExistingGuestTableOpen, setIsExistingGuestTableOpen] =
    useState<boolean>(false);
  const handleExistingGuestTableOnOpen = () => {
    setIsExistingGuestTableOpen(true);
  };
  const handleExistingGuestTableOnClose = () => {
    setIsExistingGuestTableOpen(false);
  };

  const hasAnIcJustScannedRef = useRef<boolean>(false);
  const hasGottenCustomerStayingIdRef = useRef<boolean>(false);

  const [referchCount, setRefetchCount] = useState<number>(0);

  /**
   * *This function should be called after scanning IC
   */
  const getCustomerStayingId = async () => {
    if (hasGottenCustomerStayingIdRef.current)
      await axios
        .get(`${BASE_API}/guests/`, {
          params: {
            customer_staying_ic: initialValues.customerStayingIc,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => {
          const { customerId, firstname, lastname, idNo } = data;
          setFieldValue("bookingInfo.customer_staying_id", customerId);
          setFieldValue(
            "bookingInfo.customer_staying_name",
            firstname + " " + lastname
          );
          setFieldValue("bookingInfo.customer_staying_ic", idNo);
          for (let i = 0; i < roomBookingArray.length; i++) {
            setFieldValue(`roomBookings.${i}.person_in_charge_id`, customerId);
            setFieldValue(
              `roomBookings.${i}.person_in_charge_name`,
              firstname + " " + lastname
            );
            setFieldValue(`roomBookings.${i}.person_in_charge_ic`, idNo);
          }
          hasGottenCustomerStayingIdRef.current = true;
        });
  };
  // const handleGetPIN = () => {
  //   const apiUrl = `/bookings/v1/consent/get-pin/?accountId=${accountId}`;

  //   axiosInstance
  //     .get(apiUrl, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       setActivePIN(response.data.pin);
  //       setFieldValue("bookingInfo.pin", response.data.pin);
  //       Notification.success("PIN Successfully Retrieved");
  //     });
  // };

  return (
    <>
      <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
        <Button
          variant="outlined"
          onClick={() => {
            handleChangeStep({ step: 1, text: "PIC" });
            dispatch(EmptyExpressBookingRedux());
          }}
          sx={{ marginX: "10%", marginTop: 1 }}
        >
          <KeyboardReturn /> Back
        </Button>
        {/* {activePIN ? (
          <Box
            display={"flex"}
            border={1}
            justifyContent={"center"}
            alignItems={"center"}
            borderColor={theme.palette.secondary.main}
            bgcolor={theme.palette.background.default}
            sx={{ marginX: "10%", marginTop: 1, paddingX: 1 }}
          >
            <Typography variant="h4" marginRight={1}>
              PIN :
            </Typography>
            {activePIN
              .split("")
              .map((value, index) =>
                index !== activePIN.length - 1 ? (
                  <Typography variant="h4"> {value}.</Typography>
                ) : (
                  <Typography variant="h4">{value}</Typography>
                )
              )}
          </Box>
        ) : (
          <Button
            variant="outlined"
            onClick={handleGetPIN}
            sx={{ marginX: "10%", marginTop: 1 }}
          >
            Generate PIN
          </Button>
        )} */}
      </Stack>
      <Box
        color={"white"}
        marginTop={"30px"}
        paddingX={"10%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Stack alignItems={"center"} direction={"column"} width={"100%"}>
          <Typography variant={"h2"} width={"100%"} justifyContent={"start"}>
            Add Guest
          </Typography>
          <Typography
            variant={"h5"}
            width={"100%"}
            justifyContent={"start"}
            mb={2}
          >
            Assign a person in charge to the highlighted room
          </Typography>

          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              margin: "auto",
              alignItems: "center",
              width: "100%",
              borderStyle: "solid",
              borderColor: theme.palette.primary.main,
              borderWidth: "3px",
              padding: "20px",
            }}
            spacing={3}
          >
            {isSubmitting ? (
              <CircularProgress />
            ) : (
              roomBookingArray.map((item, index) => {
                return (
                  <Grid
                    item
                    key={index}
                    md={6}
                    sx={{
                      opacity: index === activeIndex ? 1 : 0.7,
                    }}
                  >
                    <BookingInfoRooms roomBookings={item} />
                  </Grid>
                );
              })
            )}
          </Grid>
          <Grid
            item
            md={12}
            sx={{
              borderLeftStyle: "solid",
              borderLeftColor: theme.palette.primary.main,
              borderLeftWidth: "3px",
              borderRightStyle: "solid",
              borderRightColor: theme.palette.primary.main,
              borderRightWidth: "3px",
              width: "100%",
            }}
          >
            {!isNewGuestFormOpen && (
              <>
                <Box
                  justifyContent={"space-between"}
                  flexDirection="row"
                  alignItems="center"
                  width={"100%"}
                >
                  <Stack
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"end"}
                    direction={"row"}
                    height={!initialValues.customerStayingId ? "50px" : "50px"}
                    marginBottom={1}
                    paddingX={2.5}
                  >
                    <Stack direction={"row"}>
                      <RssFeedIcon
                        sx={{
                          fontSize: !initialValues.customerStayingId
                            ? "30px"
                            : "30px",
                          marginRight: "5px",
                        }}
                      />
                      <Typography
                        variant={!initialValues.customerStayingId ? "h4" : "h4"}
                        mt={0.85}
                      >
                        Scan IC / Passport to assign a main Guest of this room
                      </Typography>
                    </Stack>

                    <ButtonGroup variant="outlined">
                      <Button
                        startIcon={<PersonAddAlt1Icon />}
                        type={"button"}
                        onClick={() => handleSetGuestFormToOpen()}
                        disabled={
                          activeIndex === roomBookingCount ? true : false
                        }
                        sx={{
                          color: "white",
                          border: `solid 2px ${theme.palette.primary.main}`,
                          borderRadius: 2,
                          width: "175px",
                          "&:disabled": {
                            backgroundColor: darken("#fff", 0.8),
                            color: darken("#fff", 0.3),
                            border: `solid 1px ${theme.palette.primary.main}`,
                            cursor: "not-allowed",
                          },
                        }}
                      >
                        New Guest
                      </Button>
                      <Button
                        startIcon={<SearchIcon />}
                        type={"button"}
                        onClick={() => handleExistingGuestTableOnOpen()}
                        disabled={
                          activeIndex === roomBookingCount ? true : false
                        }
                        sx={{
                          color: "white",
                          border: `solid 2px ${theme.palette.primary.main}`,
                          borderRadius: 2,
                          width: "175px",
                          "&:disabled": {
                            backgroundColor: darken("#fff", 0.8),
                            color: darken("#fff", 0.3),
                            border: `solid 1px ${theme.palette.primary.main}`,
                            cursor: "not-allowed",
                          },
                        }}
                      >
                        Search Guest
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </Box>
              </>
            )}
          </Grid>
        </Stack>
        <TextField
          multiline
          id={""}
          rows={8}
          autoFocus
          value={idInfoInputExpress}
          onChange={handleIdInputChange}
          fullWidth
          sx={{
            border: "1px solid grey",
            "& .MuiInputBase-root": {
              padding: 0,
            },
            "& .MuiOutlinedInput-root": {
              padding: 0,
            },
          }}
          inputProps={{
            style: { padding: "10px" },
          }}
        />
        {!isNewGuestFormOpen && initialValues.customerStayingId && (
          <Stack
            justifyContent={"center"}
            alignItems={"start"}
            direction={"column"}
            width={"100%"}
            height={"200px"}
            mt={"30px"}
          >
            <Typography variant={"h3"} mb={"30px"}>
              Guest List
            </Typography>
            <Box
              sx={{
                borderStyle: "solid",
                borderColor: theme.palette.primary.main,
                borderWidth: "3px",
                borderRadius: "10px",
                width: "inherit",
                height: "100%",
                paddingY: "20px",
                display: "flex",
                justifyContent: "start",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  height: "5px",
                },
              }}
            >
              {initialValues.customerStayingId && (
                <Stack
                  direction={"row"}
                  sx={{
                    borderStyle: "solid",
                    borderColor: theme.palette.primary.main,
                    borderWidth: "3px",
                    borderRadius: "10px",
                    width: "350px",
                    height: "100%",
                    marginX: "20px",
                  }}
                >
                  <Box
                    sx={{
                      borderRightStyle: "solid",
                      borderColor: theme.palette.primary.main,
                      borderWidth: "3px",
                      height: "100%",
                      aspectRatio: "1/1",
                    }}
                  >
                    <Person sx={{ fontSize: "95px" }} />
                  </Box>
                  <Stack
                    justifyContent={"space-between"}
                    alignItems={"start"}
                    direction={"column"}
                    height={"100%"}
                    width={"350px"}
                    padding={"10px"}
                  >
                    <Stack
                      width={"100%"}
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography variant="h4">
                        {initialValues.customerStayingCountry}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          dispatch(DeleteCustomer());
                          hasGottenCustomerStayingIdRef.current = false;
                          setActiveIndex(0);
                        }}
                        style={{ padding: 0 }}
                      >
                        <Delete fontSize="small" color="error" />
                      </IconButton>
                    </Stack>
                    <Typography variant={"h3"}>
                      {initialValues.customerStayingName}
                    </Typography>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      width={"100%"}
                    >
                      {!initialValues.customerStayingPhone ||
                      !initialValues.customerStayingEmail ? (
                        <Typography
                          fontSize="14px"
                          color="error"
                          fontWeight="bold"
                        >
                          Incomplete Profile
                        </Typography>
                      ) : initialValues.customerStayingMemberTier === "None" ? (
                        <Typography
                          fontSize="14px"
                          color="error"
                          fontWeight="bold"
                        >
                          None Member
                        </Typography>
                      ) : (
                        <Typography
                          fontSize="14px"
                          fontWeight="bold"
                          color="primary"
                        >
                          Membership
                        </Typography>
                      )}
                      <Typography>
                        {initialValues.customerStayingMemberCondition &&
                        initialValues.customerStayingMemberCondition !== "None"
                          ? initialValues.customerStayingMemberTier
                          : "--"}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      width={"100%"}
                    >
                      <Typography
                        fontSize="14px"
                        fontWeight="bold"
                        color="white"
                      >
                        Retention Count
                      </Typography>
                      <Typography
                        fontSize="18px"
                        fontWeight="bold"
                        color="primary"
                      >
                        {initialValues.rententionCount}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              )}
              {initialValues.customerStayingId &&
                roomBookingArray.some(
                  (roomBooking) => roomBooking.person_in_charge_id
                ) &&
                roomBookingArray
                  .filter(
                    // to make sure that pic and customer staying name only appear once
                    (roomBooking, index, array) =>
                      array.findIndex(
                        (other) =>
                          other.person_in_charge_id ===
                          roomBooking.person_in_charge_id
                      ) === index &&
                      roomBooking.person_in_charge_id !==
                        initialValues.customerStayingId
                  )
                  .map(
                    ({
                      person_in_charge_id,
                      person_in_charge_name,
                      member_condition,
                      member_tier,
                      person_in_charge_country,
                      person_in_charge_email,
                      person_in_charge_phone,
                    }) => {
                      return (
                        <Stack
                          key={person_in_charge_id}
                          direction={"row"}
                          sx={{
                            borderStyle: "solid",
                            borderColor: theme.palette.primary.main,
                            borderWidth: "3px",
                            width: "350px",
                            height: "100%",
                            marginX: "20px",
                          }}
                        >
                          <Box
                            sx={{
                              borderRightStyle: "solid",
                              borderColor: theme.palette.primary.main,
                              borderWidth: "3px",
                              height: "100%",
                              aspectRatio: "1/1",
                            }}
                          ></Box>
                          <Stack
                            justifyContent={"space-between"}
                            alignItems={"start"}
                            direction={"column"}
                            height={"100%"}
                            width={"350px"}
                            padding={"10px"}
                          >
                            <Typography variant="h4">
                              {person_in_charge_country}
                            </Typography>
                            <Typography variant={"h3"}>
                              {person_in_charge_name}
                            </Typography>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              width={"100%"}
                            >
                              {!person_in_charge_phone ||
                              !person_in_charge_email ? (
                                <Typography
                                  fontSize="14px"
                                  color="error"
                                  fontWeight="bold"
                                >
                                  Incomplete Profile
                                </Typography>
                              ) : member_tier === "None" ? (
                                <Typography
                                  fontSize="14px"
                                  color="error"
                                  fontWeight="bold"
                                >
                                  None Member
                                </Typography>
                              ) : (
                                <Typography
                                  fontSize="14px"
                                  fontWeight="bold"
                                  color="primary"
                                >
                                  Membership
                                </Typography>
                              )}
                              <Typography>
                                {member_condition && member_condition !== "None"
                                  ? member_tier
                                  : "--"}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      );
                    }
                  )}
            </Box>
            {picError && (
              <Typography>
                Customer staying must be Guest of at least one room
              </Typography>
            )}
          </Stack>
        )}

        <Button
          variant="outlined"
          onClick={() => {
            // alert("Please assign PIC for all rooms")
            triggerSubmit();
          }}
          disabled={loading}
          sx={{
            width: "12%",
            marginTop: 3,
            height: "60px",
            backgroundColor: "#232323",
            border: 1,
            borderColor: theme.palette.primary.main,
            borderRadius: 0,
          }}
        >
          {loading ? <CircularProgress /> : "Submit"}
        </Button>

        {isNewGuestFormOpen && (
          <ExpressNewGuestForm
            setFieldValue={setFieldValue}
            onNewGuestAdded={(guest: IGuestInfo) => {
              setRefetchCount((prev) => prev + 1);
            }}
            handleSetGuestFormToClose={handleSetGuestFormToClose}
            isScanned={hasAnIcJustScannedRef.current}
            roomBookings={[]}
            hanldeNextButtonOnclick={hanldeNextButtonOnclick}
            activeIndex={activeIndex}
            scannedData={scannedData}
          />
        )}
      </Box>
      {
        <ExpressExistingCustomerTableModal
          ref={hasGottenCustomerStayingIdRef}
          isExistingGuestTableOpen={isExistingGuestTableOpen}
          handleSetExistingGuestTableToClose={handleExistingGuestTableOnClose}
          setFieldValue={setFieldValue}
          refetchCount={referchCount}
          activeIndex={activeIndex}
          roomBookings={roomBookingArray}
          hanldeNextButtonOnclick={hanldeNextButtonOnclick}
          bookingState={bookingState}
        />
      }
    </>
  );
};

export default ExpressPICAssignation;
