import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  INewAccountData,
  INewAccountRole,
} from "../../../models/accounts/accountInterface";
import { ArrowBack, ArrowBackIos } from "@mui/icons-material";
import { useEffect, useState } from "react";
import Notification from "../../../utils/notificationConfig";
import axiosInstance from "../../../constants/axiosConfig";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import AuthModal from "../../account/AuthModal";

interface AddOrEditAccountProps {
  handleBack: VoidFunction;
  selectedAccount: INewAccountData;
  setAccounts: React.Dispatch<React.SetStateAction<INewAccountData[]>>;
}

const initialSelectedAccount: INewAccountData = {
  accountId: "",
  email: "",
  name: "",
  roles: "",
  username: "",
  isArchived: false,
};

const AddOrEditAccountModal = (props: AddOrEditAccountProps) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const userRole = useSelector((state: RootState) => state.user.role);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [selectedAccount, setSelectedAccount] = useState<INewAccountData>(
    initialSelectedAccount
  );

  const [roles, setRoles] = useState<INewAccountRole[]>([]);

  const [openDeleteAccountModal, setOpenDeleteAccountModal] =
    useState<boolean>(false);

  const handleOpenDeleteAccountModal = () => {
    setOpenDeleteAccountModal(true);
  };
  const handleCloseDeleteAccountModal = () => {
    setOpenDeleteAccountModal(false);
  };

  useEffect(() => {
    if (props.selectedAccount.accountId) {
      setSelectedAccount(props.selectedAccount);
    } else {
      setSelectedAccount(initialSelectedAccount);
    }
  }, [props.selectedAccount]);

  useEffect(() => {
    const apiUrl = "/accounts/role/get-all-roles";

    axiosInstance
      .get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setRoles(res.data.data);
        }
      })
      .catch((res) => {
        Notification.failed(res.response.data.msg);
      });
  }, []);

  const formik = useFormik({
    initialValues: selectedAccount,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      const apiUrl = selectedAccount.accountId
        ? "/accounts/edit-account/"
        : "/accounts/create-account/";

      if (values.password !== values.confirmPassword) {
        Notification.failed("Password Mismatch Detected");
        actions.setSubmitting(false);
        return;
      }

      if (selectedAccount.accountId) {
        const formData = {
          account_id: values.accountId,
          username: values.username,
          name: values.name,
          email: values.email,
          role_id: values.roles,
          password: values.password
        };
        axiosInstance
          .put(apiUrl, formData, config)
          .then((res) => {
            Notification.success(res.data.message);
            props.handleBack();
            props.setAccounts((prevAccounts) => {
              const removedAccount = prevAccounts.find(
                (account) =>
                  account.accountId === props.selectedAccount?.accountId
              );

              if (removedAccount) {
                const newAccount = prevAccounts.filter(
                  (account) =>
                    account.accountId !== props.selectedAccount?.accountId
                );

                return [
                  {
                    ...values,
                    role: {
                      roleId: Number(values.roles),
                      roleName: roles.filter(
                        (role) => role.roleId === values.roles
                      )[0].roleName,
                    },
                  },
                  ...newAccount,
                ];
              }
              return prevAccounts;
            });
          })
          .catch((res) => {
            Notification.failed(res.response.data.msg);
          })
          .finally(() => actions.setSubmitting(false));
        return;
      }
      const formData = {
        username: values.username,
        name: values.name,
        email: values.email,
        role_id: values.roles,
        password: values.password,
      };

      axiosInstance
        .post(apiUrl, formData, config)
        .then((res) => {
          Notification.success(res.data.message);
          props.handleBack();
          props.setAccounts((prevAccount) => {
            const newAccount: INewAccountData = {
              ...values,
              accountId: res.data.id,
              role: {
                roleId: Number(values.roles),
                roleName: roles.filter(
                  (role) => role.roleId === values.roles
                )[0].roleName,
              },
            };
            return [newAccount, ...prevAccount];
          });
        })
        .catch((res) => {
          Notification.failed(res.response.data.msg);
        })
        .finally(() => actions.setSubmitting(false));
    },
  });

  const handleDeleteAccount = () => {
    const apiUrl = `/accounts/archive-account/`;
    const formData = {
      account_id: selectedAccount.accountId,
      archive: !selectedAccount.isArchived,
    };

    axiosInstance
      .patch(apiUrl, formData, config)
      .then((res) => {
        Notification.success(res.data.message);
        props.handleBack();
        props.setAccounts((prevAccounts) => {
          const removedAccount = prevAccounts.find(
            (account) => account.accountId === props.selectedAccount?.accountId
          );

          if (removedAccount) {
            const newAccount = prevAccounts.filter(
              (account) =>
                account.accountId !== props.selectedAccount?.accountId
            );

            return [
              {
                ...formik.values,
                isArchived: !formik.values.isArchived,
              },
              ...newAccount,
            ];
          }
          return prevAccounts;
        });
      })
      .catch((res) => Notification.failed(res.response.data.msg));
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction={"column"} spacing={4}>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Button variant="text" onClick={props.handleBack}>
            <ArrowBackIos fontSize="small" />
            BACK
          </Button>
          <Typography variant="h3">
            {selectedAccount.accountId !== "" ? "Edit Account" : "New Account"}
          </Typography>
        </Stack>
        <Typography variant="h3">Account Credentials</Typography>
        <Stack direction={"column"} spacing={2}>
          <Stack direction={"row"} alignItems={"center"} spacing={3}>
            <Typography variant="h4" width={"180px"}>
              Username
            </Typography>
            <TextField
              fullWidth
              variant="standard"
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              inputProps={{
                style: { textTransform: "none" },
              }}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={3}>
            <Typography variant="h4" width={"180px"}>
              Password
            </Typography>
            <TextField
              fullWidth
              variant="standard"
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={3}>
            <Typography variant="h4" width={"180px"}>
              Confirm Password
            </Typography>
            <TextField
              fullWidth
              variant="standard"
              type="password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
            />
          </Stack>
          {/* <Stack direction={"row"} alignItems={"center"}>
          <Typography variant="h4" width={"180px"}>
            Super Admin
          </Typography>
          <Switch
            name="superadmin"
            checked={
              roles.length > 0 &&
              formik.values.roles.includes(
                roles.filter((role) => role.roleName === "Super Admin")[0]
                  .roleId
              )
                ? true
                : false
            }
            onChange={(event) => {
              const superAdminRole = roles.filter(
                (role) => role.roleName === "Super Admin"
              )[0];
              event.target.checked
                ? formik.setFieldValue("roles", [
                    ...formik.values.roles,
                    superAdminRole.roleId,
                  ])
                : formik.setFieldValue(
                    "roles",
                    formik.values.roles.filter(
                      (role) => role !== superAdminRole.roleId
                    )
                  );
            }}
          />
        </Stack> */}
        </Stack>
        <Typography variant="h3">User Information</Typography>
        <Stack direction={"column"} spacing={2}>
          <Stack direction={"row"} alignItems={"center"} spacing={3}>
            <Typography variant="h4" width={"180px"}>
              Name
            </Typography>
            <TextField
              fullWidth
              variant="standard"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={3}>
            <Typography variant="h4" width={"180px"}>
              Email
            </Typography>
            <TextField
              fullWidth
              variant="standard"
              name="email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={3}>
            <Typography variant="h4" width={"180px"}>
              Roles
            </Typography>
            <Select
              name="roles"
              variant="standard"
              onChange={formik.handleChange}
              value={formik.values.roles}
              fullWidth
            >
              {roles.map((role) => (
                <MenuItem value={role.roleId}>{role.roleName}</MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>
        {selectedAccount.accountId && userRole === "Super Admin" && (
          <Stack direction={"column"} spacing={2}>
            <Stack direction={"row"} alignItems={"center"} spacing={3}>
              <Typography variant="h4" width={"160px"}>
                {props.selectedAccount.isArchived ? "Activation" : "Deletion"}
              </Typography>
              <Button
                variant="contained"
                onClick={handleOpenDeleteAccountModal}
                sx={{
                  backgroundColor: props.selectedAccount.isArchived
                    ? theme.palette.secondary.main
                    : theme.palette.error.main,
                  color: "black",
                }}
              >
                {props.selectedAccount.isArchived
                  ? "Activate Account"
                  : "Delete Account"}
              </Button>
            </Stack>
          </Stack>
        )}
        <Box display={"flex"} justifyContent={"end"}>
          <Button variant="outlined" type="submit" sx={{ width: "80px" }}>
            {formik.isSubmitting ? (
              <CircularProgress size={24} color="primary" />
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      </Stack>

      <AuthModal
        authenticationModalOpen={openDeleteAccountModal}
        functionToRun={handleDeleteAccount}
        handleAuthenticationModalOnClose={handleCloseDeleteAccountModal}
        requireOtherAdmin={true}
      />
    </form>
  );
};

export default AddOrEditAccountModal;
