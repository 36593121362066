import { Add } from "@mui/icons-material";
import {
	Box,
	ButtonBase,
	CircularProgress,
	Grid,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import axiosInstance from "../../constants/axiosConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import { INewLockerZones } from "../../models/lockers/LockerInterface";
import SettingsIcon from "@mui/icons-material/Settings";
import AddOrEditLockerZoneModal from "./AddOrEditLockerZoneModal";

const LockerZoneInitial: INewLockerZones = {
	details: "",
	isArchive: false,
	name: "",
	zoneId: "",
};

const LockerTabZoneContent = () => {
	const theme = useTheme();
	const token = useSelector((state: RootState) => state.user.accessToken);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [lockerZones, setLockerZones] = useState<INewLockerZones[]>([]);

	const [selectedLockerZone, setSelectedLockerZone] =
		useState<INewLockerZones>(LockerZoneInitial);

	const [openEditLockerZoneModal, setOpenEditLockerZoneModal] =
		useState<boolean>(false);

	const handleOpenEditLockerZoneModal = () => {
		setOpenEditLockerZoneModal(true);
	};
	const handleCloseEditLockerZoneModal = () => {
		setOpenEditLockerZoneModal(false);
		setSelectedLockerZone(LockerZoneInitial);
	};

	const getLockerZoneData = () => {
		setIsLoading(true);
		const apiUrlParams = new URLSearchParams();
		apiUrlParams.append("withLockerCount", true.toString());
		axiosInstance
			.get(`/lockers/zone?${apiUrlParams.toString()}`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
				if (res.status === 200) {
					const formattedLockerZone = res.data
						.slice()

						.sort((a: INewLockerZones, b: INewLockerZones) => {
							const zoneNameA = a.name.toLowerCase();
							const zoneNameB = b.name.toLowerCase();

							if (zoneNameA < zoneNameB) {
								return -1;
							}
							if (zoneNameA > zoneNameB) {
								return 1;
							}
							return 0;
						})
						.sort((a: INewLockerZones, b: INewLockerZones) => {
							const aValue = a.isArchive ? 1 : 0;
							const bValue = b.isArchive ? 1 : 0;

							return aValue - bValue;
						});
					setLockerZones(formattedLockerZone);
				}
			})
			.catch((res) => {
				Notification.failed(res.response.data.msg);
			})
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		getLockerZoneData();
	}, []);

	return (
		<>
			<Stack
				direction={"row"}
				width={"100%"}
				minHeight={"3.5rem"}
				justifyContent={"space-between"}
				alignItems={"center"}
				borderBottom={2}
				paddingLeft={2}
				borderColor={theme.palette.primary.main}
				sx={{ backgroundColor: theme.palette.background.default }}>
				<Stack
					direction={"row"}
					width={"100%"}
					height={"100%"}
					justifyContent={"space-between"}
					alignItems={"center"}>
					<Typography variant={"h3"} color={theme.palette.primary.main}>
						Locker Zones ({lockerZones.length})
					</Typography>
					<ButtonBase
						onClick={handleOpenEditLockerZoneModal}
						sx={{
							height: "100%",
							width: "150px",
							borderLeft: 2,
							borderColor: theme.palette.primary.main,
						}}>
						<Stack
							direction={"row"}
							width={"100%"}
							height={"100%"}
							justifyContent={"center"}
							alignItems={"center"}>
							<Add color="primary" />
							<Typography variant="h4" color={"primary"}>
								Add Zone
							</Typography>
						</Stack>
					</ButtonBase>
				</Stack>
			</Stack>
			{isLoading ? (
				<Box
					display={"flex"}
					height={"50vh"}
					justifyContent={"center"}
					alignItems={"center"}>
					<CircularProgress />
				</Box>
			) : (
				<Grid
					container
					direction={"row"}
					width={"100%"}
					height={"80%"}
					padding={3}
					columns={{ xs: 5, sm: 5, md: 5, lg: 5, xl: 5 }}
					spacing={2}>
					{lockerZones.map((lockerZone, index) => (
						<Grid key={index} item xs={2} sm={2} md={2} lg={2} xl={2}>
							<Stack
								width={"100%"}
								height={"100%"}
								sx={{ background: "#141414" }}>
								<Stack
									height={"40%"}
									direction={"column"}
									alignItems={"start"}
									borderBottom={1}
									borderColor={"white"}
									p={2}
									sx={{ background: theme.palette.background.default }}>
									<Typography variant={"h2"}>
										<p
											style={{
												color: lockerZone.isArchive ? "grey" : "white",
											}}>
											{" "}
											{lockerZone.name}
										</p>
									</Typography>
									<Typography
										variant={"h4"}
										textAlign={"start"}
										fontWeight={500}>
										<p
											style={{
												color: lockerZone.isArchive ? "grey" : "white",
											}}>
											{" "}
											Locker Zone
										</p>
									</Typography>
								</Stack>
								<Stack height={"40%"} p={2}>
									<Stack
										height={"80%"}
										direction={"column"}
										alignItems={"start"}
										spacing={1}>
										<Typography variant={"h4"} sx={{ color: "grey" }}>
											Lockers
										</Typography>
										<Typography variant={"h3"}>
											<p
												style={{
													color: lockerZone.isArchive ? "grey" : "white",
												}}>
												{lockerZone.lockerCount}
											</p>
										</Typography>
									</Stack>
									<Stack
										direction={"row"}
										justifyContent={"end"}
										sx={{ marginTop: 2 }}>
										<IconButton
											onClick={() => {
												setSelectedLockerZone(lockerZone);
												handleOpenEditLockerZoneModal();
											}}
											sx={{
												width: "4em",
												borderRadius: 0,
												background: theme.palette.background.default,
											}}>
											<SettingsIcon sx={{ color: "white", marginRight: 1 }} />
											<Typography variant={"h4"}>Edit</Typography>
										</IconButton>
									</Stack>
								</Stack>
							</Stack>
						</Grid>
					))}
				</Grid>
			)}
			<AddOrEditLockerZoneModal
				handleCloseEditLockerZoneModal={handleCloseEditLockerZoneModal}
				openEditLockerZoneModal={openEditLockerZoneModal}
				selectedLockerZone={selectedLockerZone}
				setLockerZones={setLockerZones}
			/>
		</>
	);
};

export default LockerTabZoneContent;
