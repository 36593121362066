import {
  Grid,
  Grow,
  Stack,
  Tab,
  Tabs,
  Typography,
  CircularProgress,
} from "@mui/material";
import theme from "../../assets/theme/theme";
import { SetStateAction, useEffect, useState } from "react";
import RoomSettings from "./RoomSettings";
import CheckInSlip from "./CheckInSlip";
import Dropdowns from "./Dropdowns";
import DropdownsDetail from "./DropdownsDetail";
import RoomSettingsDetail from "./RoomSettingsDetail";
import CheckInSlipDetail from "./CheckInSlipDetail";
import BASE_API from "../../constants/api";
import { store } from "../../redux/store";
import axiosInstance from "../../constants/axiosConfig";

// Define an interface for the settings data
interface Setting {
  settingsId: number;
  settingsName: string;
  settingsCategory: string;
  settingsDescription: string;
  creationDatetime: string;
  lot: number;
}

export default function GeneralSettings() {
  const [tabStepperValue, setTabStepperValue] = useState("Room Settings");

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isRoomSettingsVisible, setIsRoomSettingsVisible] = useState(false);
  const [isCheckInSlipVisible, setIsCheckInSlipVisible] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  const handleRoomSettingsVisibilityChange = (
    isVisible: boolean | ((prevState: boolean) => boolean)
  ) => {
    setTabStepperValue("Room Settings");
    setIsRoomSettingsVisible(isVisible);
  };
  const handleCheckInSlipVisibilityChange = (
    isVisible: boolean | ((prevState: boolean) => boolean)
  ) => {
    setTabStepperValue("Check-In Slip");
    setIsCheckInSlipVisible(isVisible);
  };
  const handleDropdownVisibilityChange = (
    isVisible: boolean | ((prevState: boolean) => boolean)
  ) => {
    setTabStepperValue("Dropdowns");
    setIsDropdownVisible(isVisible);
  };

  const accountId = store.getState().user.accountId;
  const token = store.getState().user.accessToken;

  const [settingsData, setSettingsData] = useState<Setting[]>([]);
  const [tabComponents, setTabComponents] = useState<
    { name: string; component: JSX.Element }[]
  >([]);

  useEffect(() => {
    FetchSettings();
  }, [isDropdownVisible, isRoomSettingsVisible, isCheckInSlipVisible]);

  const findSettingByName = (Name: string, data: Setting[]) => {
    return data.find((setting) => setting.settingsName === Name);
  };

  const wifiNameSettingName = "Wifi Name";
  const wifiPasswordSettingName = "Wifi Password";

  const gracePeriodSettingName = "Grace Period";
  const noShowIntervalSettingName = "No Show Interval";

  const gracePeriodSetting = findSettingByName(
    gracePeriodSettingName,
    settingsData
  );
  const noShowIntervalSetting = findSettingByName(
    noShowIntervalSettingName,
    settingsData
  );

  const wifiNameSetting = findSettingByName(wifiNameSettingName, settingsData);
  const wifiPasswordSetting = findSettingByName(
    wifiPasswordSettingName,
    settingsData
  );

  const FetchSettings = async () => {
    setLoading(true);
    axiosInstance
      .get(`/lot-settings/get-settings/${accountId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setSettingsData(response.data.data);

        const gracePeriodSetting = findSettingByName(
          gracePeriodSettingName,
          response.data.data
        );
        const noShowIntervalSetting = findSettingByName(
          noShowIntervalSettingName,
          response.data.data
        );

        const wifiNameSetting = findSettingByName(
          wifiNameSettingName,
          response.data.data
        );
        const wifiPasswordSetting = findSettingByName(
          wifiPasswordSettingName,
          response.data.data
        );

        setTabComponents([
          {
            name: "Room Settings",
            component: (
              <Grow
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                {...(true ? { timeout: 1000 } : {})}
              >
                <div id="section-Room Settings">
                  <RoomSettings
                    gracePeriods={gracePeriodSetting?.settingsDescription}
                    onRoomSettingsVisibilityChange={
                      handleRoomSettingsVisibilityChange
                    }
                    settingsGracePeriodId={gracePeriodSetting?.settingsId}
                    settingsIntervalId={noShowIntervalSetting?.settingsId}
                    interval={noShowIntervalSetting?.settingsDescription}
                  />
                </div>
              </Grow>
            ),
          },
          {
            name: "Check-In Slip",
            component: (
              <Grow
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                {...(true ? { timeout: 1000 } : {})}
              >
                <div id="section-Check-In Slip">
                  <CheckInSlip
                    onCheckInSlipVisibilityChange={
                      handleCheckInSlipVisibilityChange
                    }
                    wifiName={wifiNameSetting?.settingsDescription}
                    wifiPassword={wifiPasswordSetting?.settingsDescription}
                  />
                </div>
              </Grow>
            ),
          },
          {
            name: "Dropdowns",
            component: (
              <Grow
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                {...(true ? { timeout: 1000 } : {})}
              >
                <div id="section-Dropdowns">
                  <Dropdowns
                    onDropdownVisibilityChange={handleDropdownVisibilityChange}
                  />
                </div>
              </Grow>
            ),
          },
        ]);
        moveTabToTop(tabStepperValue);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const sectionId = `section-${tabStepperValue}`;
    const sectionElement = document.getElementById(sectionId);

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [tabStepperValue]);

  const handleStepperChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setIsRoomSettingsVisible(false);
    setIsCheckInSlipVisible(false);
    setIsDropdownVisible(false);
    setTabStepperValue(newValue);
    moveTabToTop(newValue);
  };

  const moveTabToTop = (tabName: string) => {
    const index = tabComponents.findIndex((item) => item.name === tabName);
    if (index !== -1) {
      const updatedComponents = [...tabComponents];
      const tabToMove = updatedComponents.splice(index, 1)[0];
      updatedComponents.unshift(tabToMove);
      setTabComponents(updatedComponents);
    }
  };

  return (
    <Stack width={"100%"} height={"70vh"}>
      <Grid container>
        <Grid
          item
          md={2}
          sm={2}
          xs={2}
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            minHeight: "100%",
          }}
        >
          <Stack
            direction={"column"}
            textAlign={"start"}
            padding={2}
            spacing={2}
            sx={{ backgroundColor: theme.palette.background.default }}
            height={"70vh"}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabStepperValue}
              onChange={handleStepperChange}
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{
                marginBottom: 5,
                "& .MuiTab-root": {
                  textTransform: "none",
                  fontWeight: 600,
                  minWidth: "8.1vw",
                  fontSize: "1rem",
                  alignContent: "start",
                  "&.Mui-selected": {
                    color: theme.palette.primary.main,
                  },
                  "&:not(.Mui-selected)": {
                    color: "white",
                    borderBottom: "1px solid #0a443c",
                  },
                },
              }}
            >
              <Tab
                value={"Room Settings"}
                label={"Room Settings"}
                disabled={loading}
              />
              <Tab
                value={"Check-In Slip"}
                label={"Check-In Slip"}
                disabled={loading}
              />
              <Tab value={"Dropdowns"} label={"Dropdowns"} disabled={loading} />
            </Tabs>
          </Stack>
        </Grid>

        <Grid item md={10} sm={10} xs={10}>
          {loading ? (
            <Stack
              direction={"column"}
              height={"100%"}
              width={"100%"}
              sx={{ backgroundColor: "#141414", overflow: "auto" }}
              maxHeight={"70vh"}
            >
              <Grid
                item
                md={12}
                display={"flex"}
                justifyContent={"center"}
                alignContent={"center"}
                m={10}
              >
                <CircularProgress />
              </Grid>
            </Stack>
          ) : (
            <Stack
              direction={"column"}
              height={"100%"}
              width={"100%"}
              sx={{ backgroundColor: "#141414", overflow: "auto" }}
              maxHeight={"70vh"}
            >
              {!isDropdownVisible &&
                !isRoomSettingsVisible &&
                !isCheckInSlipVisible &&
                settingsData && (
                  <>
                    {tabComponents.map((tab, index) => (
                      <div key={index}>{tab.component}</div>
                    ))}
                  </>
                )}
              {isRoomSettingsVisible && (
                <RoomSettingsDetail
                  gracePeriods={gracePeriodSetting?.settingsDescription}
                  onRoomSettingsVisibilityChange={
                    handleRoomSettingsVisibilityChange
                  }
                  settingsGracePeriodId={gracePeriodSetting?.settingsId}
                  settingsIntervalId={noShowIntervalSetting?.settingsId}
                  interval={noShowIntervalSetting?.settingsDescription}
                />
              )}

              {isCheckInSlipVisible && (
                <CheckInSlipDetail
                  onCheckInSlipVisibilityChange={
                    handleCheckInSlipVisibilityChange
                  }
                  wifiName={wifiNameSetting?.settingsDescription}
                  wifiPassword={wifiPasswordSetting?.settingsDescription}
                  wifiNameId={wifiNameSetting?.settingsId}
                  wifiPasswordId={wifiPasswordSetting?.settingsId}
                />
              )}

              {isDropdownVisible && (
                <DropdownsDetail
                  onDropdownVisibilityChange={handleDropdownVisibilityChange}
                />
              )}
            </Stack>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
}
