import axiosInstance from "../constants/axiosConfig";
import { StaahReservation, StaahRoomType } from "../models/ota/OTAInterfaces";
import { store } from "../redux/store";
import { PaginatedResponse } from "../types/pagination";
import Notification from "../utils/notificationConfig";

export const getStaahRoomTypes = async () => {
  try {
    const response = await axiosInstance.get<StaahRoomType[]>(
      "staah/room-types/",
      {
        headers: {
          Authorization: `Bearer ${store.getState().user.accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    Notification.failed("Failed to fetch Staah room types");
  }
};

export const getStaahReservations = async (page: number, pageSize: number) => {
  try {
    const response = await axiosInstance.get<
      PaginatedResponse<StaahReservation>
    >(`staah/reservations/?page=${page}&page_size=${pageSize}`, {
      headers: { Authorization: `Bearer ${store.getState().user.accessToken}` },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    Notification.failed("Failed to fetch Staah reservations");
  }
};

export const updateStaahReservation = async (
  id: string,
  isManualCreated: boolean
) => {
  try {
    const response = await axiosInstance.patch<StaahReservation>(
      `staah/reservations/${id}/`,
      { isManualCreated },
      {
        headers: {
          Authorization: `Bearer ${store.getState().user.accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    Notification.failed("Failed to update Staah reservation");
  }
};
