import { Box, Typography, List, ListItem, Button } from "@mui/material";

// Redux
import { useDispatch } from "react-redux";
import { removeModalOpen } from '../../redux/modal/modalSlice';

// types and interfaces
import { RoleDataInterface } from "../../models/accounts/roleInterface"
interface Props {
    roleInfo : RoleDataInterface;
}


export default function RolePermissionModal({ roleInfo } : Props) {

    const dispatch = useDispatch();

    return (
        <Box width={'30rem'} maxHeight={'90vh'}>
            <Typography variant='h5' mb={'20px'}>
                {`${roleInfo.roleName}'s Permissions`}
            </Typography>
            <List sx={{ display: 'flex', flexWrap: 'wrap', maxHeight: '75vh', overflowY: 'auto' }}>
                {roleInfo.rolePermissions.map((e : string, i : number) => {
                    return (
                        <ListItem key={i}>
                            <Typography>{e}</Typography>
                        </ListItem>
                    )
                })}
            </List>
            <Box display={'flex'} justifyContent={'end'}>
                <Button
                    variant='text'
                    sx={{ marginRight: '10px' }}
                    onClick={() => dispatch(removeModalOpen())}
                >Close</Button>
            </Box>
        </Box>
    )
}