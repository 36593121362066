import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import {
  ILockerZones,
  ILockers,
  INewLockerZones,
} from "../../models/lockers/LockerInterface";
import { useFormik } from "formik";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import { QrCode } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { v4 as uuidv4 } from "uuid";

interface IEditLockerModal {
  selectedLocker: ILockers;
  openEditLockerModal: boolean;
  handleCloseEditLockerModal: VoidFunction;
  setLockers: React.Dispatch<React.SetStateAction<ILockers[]>>;
  setLockerZones: React.Dispatch<React.SetStateAction<INewLockerZones[]>>;
}

const formikInitialValue: ILockers = {
  id: uuidv4(),
  zone: {
    details: "",
    isArchive: false,
    name: "",
    zoneId: "",
  },
  code: "",
  details: "",
  rate: 0,
  isArchived: false,
  status: "",
  upDown: false,
};

function parseLockerZonesData(apiResponse: any[]): ILockerZones[] {
  return apiResponse.map((item) => ({
    zoneId: item.zone_id.toString(),
    name: item.name,
    availableLockers: item.available_lockers,
    occupiedLockers: item.occupied_lockers,
    rentalLockers: item.rental_lockers,
    maintenanceLockers: item.maintenance_lockers,
  }));
}

const AddOrEditLockerModal = (props: IEditLockerModal) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const [zoneLocker, setZoneLocker] = useState<INewLockerZones[]>([]);

  const qrCodeInputRef = useRef<HTMLInputElement | null>(null);

  const [defaultInitialValues, setDefaultInitialValues] =
    useState<ILockers>(formikInitialValue);

  useEffect(() => {
    setDefaultInitialValues((prevValue) => ({
      ...prevValue,
      id: uuidv4(),
    }));
  }, [props.openEditLockerModal]);

  const formik = useFormik({
    initialValues: props.selectedLocker.id
      ? props.selectedLocker
      : defaultInitialValues,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const apiUrl = "/lockers/";

      const formData = {
        locker_code: values.code.toUpperCase(),
        zone_id: values.zone.zoneId,
        remarks: values.details.toUpperCase(),
        up_down: values.upDown,
        qr_code: values.id,
      };

      if (props.selectedLocker.id) {
        axiosInstance
          .put(apiUrl + "edit/" + props.selectedLocker.id + "/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            Notification.success(res.data.message);
            props.handleCloseEditLockerModal();
            props.setLockers((prevLocker) => {
              const removedLocker = prevLocker.find(
                (locker) => locker.id === props.selectedLocker?.id
              );

              if (removedLocker) {
                const newLockers = prevLocker.filter(
                  (locker) => locker.id !== props.selectedLocker?.id
                );

                return [
                  {
                    ...values,
                    details: values.details.toUpperCase(),
                    code: values.code.toUpperCase(),
                  },
                  ...newLockers,
                ];
              }
              return prevLocker;
            });
          })
          .catch((res) => {
            Notification.failed(res.response.data.error);
          });
        return;
      }
      axiosInstance
        .post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          Notification.success(res.data.message);
          props.handleCloseEditLockerModal();
          props.setLockers((prevLocker) => {
            const selectedZone = zoneLocker.filter(
              (zone) => zone.zoneId === values.zone.zoneId
            )[0];
            const newLocker: ILockers = {
              ...values,
              zone: {
                name: selectedZone.name,
                zoneId: values.zone.zoneId,
                details: "",
                isArchive: false,
              },
              status: "Available",
              details: values.details.toUpperCase(),
              code: values.code.toUpperCase(),
            };
            return [newLocker, ...prevLocker];
          });
          props.setLockerZones((prevValue) => {
            const selectedZone = prevValue.find(
              (zone) => zone.zoneId === values.zone.zoneId
            );

            if (selectedZone) {
              const updatedZone = {
                ...selectedZone,
                lockerCount: (selectedZone.lockerCount || 0) + 1,
              };

              const updatedLockerZones = [
                ...prevValue.slice(0, prevValue.indexOf(selectedZone)), // Elements before the selected index
                updatedZone, // Updated element
                ...prevValue.slice(prevValue.indexOf(selectedZone) + 1), // Elements after the selected index
              ];

              return updatedLockerZones;
            }
            return prevValue;
          });
        })
        .catch((res) => {
          Notification.failed(res.response.data.error);
        });
    },
  });

  const getLockerZoneData = () => {
    const apiUrlParams = new URLSearchParams();
    axiosInstance
      .get(`/lockers/zone?${apiUrlParams.toString()}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setZoneLocker(
            res.data.filter((data: INewLockerZones) => data.isArchive === false)
          );
        }
      })
      .catch((res) => {
        Notification.failed(res.response.data.msg);
      });
  };
  useEffect(() => {
    getLockerZoneData();
  }, []);

  const handleScanQRCodeButtonPress = () => {
    formik.setFieldValue("id", "");
    if (qrCodeInputRef.current) qrCodeInputRef.current.focus();
  };

  return (
    <Modal
      open={props.openEditLockerModal}
      onClose={props.handleCloseEditLockerModal}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box
        width={"600px"}
        minWidth={"38em"}
        border={`3px solid ${theme.palette.primary.main}`}
        margin={"auto"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
        }}
      >
        <Box
          height={"40px"}
          paddingY={1}
          paddingX={2}
          sx={{
            backgroundColor: theme.palette.background.default,
          }}
          borderBottom={`3px solid ${theme.palette.primary.main}`}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Typography variant={"h3"} color={theme.palette.primary.main}>
            {props.selectedLocker.id ? "Edit Locker" : "New Locker"}
          </Typography>
          <IconButton onClick={() => props.handleCloseEditLockerModal()}>
            <HighlightOffRoundedIcon
              sx={{
                color: theme.palette.primary.main,
                fontSize: "24px",
              }}
            />
          </IconButton>
        </Box>
        <Box padding={3}>
          <Typography variant="h2" marginBottom={2}>
            {props.selectedLocker.id ? "Edit Locker" : "New Locker"}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Stack direction={"column"} spacing={2}>
              <Stack direction={"row"} width={"100%"} alignItems={"center"}>
                <Typography variant="h4" width={"30%"}>
                  Locker Code
                </Typography>
                <TextField
                  size="small"
                  name="code"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  sx={{
                    border: 2,
                    borderColor: theme.palette.primary.main,
                    borderRadius: "5px",
                    width: "70%",
                  }}
                />
              </Stack>
              <Stack direction={"row"} width={"100%"} alignItems={"center"}>
                <Typography variant="h4" width={"30%"}>
                  QR Code
                </Typography>
                <TextField
                  size="small"
                  name="id"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                  inputRef={qrCodeInputRef}
                  sx={{
                    border: 2,
                    borderColor: theme.palette.primary.main,
                    borderRadius: "5px",
                    width: "50%",
                  }}
                />
                <ButtonBase
                  onClick={handleScanQRCodeButtonPress}
                  sx={{ width: "20%", height: "100%" }}
                >
                  <Box
                    display={"flex"}
                    padding={1}
                    flexDirection={"row"}
                    border={2}
                    borderColor={theme.palette.primary.main}
                    borderRadius={"5px"}
                    width={"100%"}
                    height={"100%"}
                    alignItems={"center"}
                  >
                    <Typography variant="h4" width={"70%"} align="center">
                      Scan
                    </Typography>
                    <QrCode sx={{ width: "30%" }} />
                  </Box>
                </ButtonBase>
              </Stack>

              <Stack direction={"row"} width={"100%"} alignItems={"center"}>
                <Typography variant="h4" width={"30%"}>
                  Level
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formik.values.upDown}
                  onChange={(event) =>
                    formik.setFieldValue("upDown", event.target.value)
                  }
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio sx={{ color: "white" }} />}
                    label="Upper"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio sx={{ color: "white" }} />}
                    label="Lower"
                  />
                </RadioGroup>
              </Stack>
              <Stack direction={"row"} width={"100%"} alignItems={"center"}>
                <Typography variant="h4" width={"30%"}>
                  Zone
                </Typography>
                <Select
                  size="small"
                  name="zone.zoneId"
                  value={formik.values.zone.zoneId}
                  onChange={formik.handleChange}
                  sx={{
                    border: 2,
                    borderColor: theme.palette.primary.main,
                    borderRadius: "5px",
                    width: "70%",
                  }}
                >
                  {zoneLocker.map((zone, index) => (
                    <MenuItem key={index} value={zone.zoneId}>
                      {zone.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Stack direction={"row"} width={"100%"} alignItems={"center"}>
                <Typography variant="h4" width={"30%"}>
                  Remarks
                </Typography>
                <TextField
                  size="small"
                  name="details"
                  multiline
                  rows={7}
                  value={formik.values.details}
                  onChange={formik.handleChange}
                  inputProps={{
                    padding: 0,
                  }}
                  sx={{
                    border: 2,
                    borderColor: theme.palette.primary.main,
                    borderRadius: "5px",
                    width: "70%",
                    ".MuiInputBase-root": {
                      padding: 0,
                    },
                    ".MuiInputBase-input": {
                      padding: 1,
                    },
                  }}
                />
              </Stack>
              <Box alignSelf={"end"}>
                <Button
                  type={"submit"}
                  variant="outlined"
                  sx={{ width: "120px" }}
                >
                  Save
                </Button>
              </Box>
            </Stack>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddOrEditLockerModal;
