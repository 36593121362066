import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from '../../assets/theme/theme';
import CustomTable from '../../components/global/table/CustomTable';
import axiosInstance from '../../constants/axiosConfig';
import { bookingStatusEnum } from '../../constants/enums';
import useUpdate from '../../hooks/useUpdate';
import { store } from '../../redux/store';
import { convertToEpoch, getTimestamp } from '../../utils/functions';
import Notification from '../../utils/notificationConfig';
import { NewAPIBookingResult } from './interface';

type Props = {
  tableStatus: string;
};

type CheckinDataItem = {
  id: number;
  bookingId: string;
  bookingNumber: string;
  customer: string;
  platform: string;
  status: string;
  checkInTime: string | null;
  checkOutTime: string | null;
  actualCheckinDatetime: string | null;
  actualCheckoutDatetime: string | null;
  derivedCheckInDatetime: string;
  derivedCheckOutDatetime: string;
  roomCode: string[];
  roomTypes: string[];
  bookingMadeDatetime: string | null | undefined;
  overstayDuration: string;
  isOverstay: boolean;
  otaCode: string;

  // count: number;
  // next: string | null;
  // previous: string | null;
};

function NewCheckOutList() {
  const today = new Date();
  const minus1Date = new Date(today);
  minus1Date.setDate(today.getDate() - 1);

  const dayAfter = new Date(today);
  dayAfter.setDate(today.getDate());

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [checkInData, setCheckInData] = useState<CheckinDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tablePaginationData, setTablePaginationData] = useState<number>(0);
  const [isCheckInOpened, setIsCheckInOpened] = useState<boolean>();
  const [sortByValue, setSortByValue] = useState('All');
  const [bookStatusFilter, setBookStatusFilter] = useState('All');
  const [bookPlatformFilter, setBookPlatformFilter] = useState('All');
  const [bookStatusValue, setBookStatusValue] = useState<string[]>([]);
  const [bookPlatformValue, setBookPlatformValue] = useState<string[]>([]);
  const [searchBarValue, setSearchBarValue] = useState('');
  const [displayTableData, setDisplayTableData] = useState<CheckinDataItem[]>(
    []
  );
  const [checkInTableData, setCheckInTableData] = useState<CheckinDataItem[]>(
    []
  );
  const [checkOutTableData, setCheckOutTableData] = useState<CheckinDataItem[]>(
    []
  );
  const [paginatedTableData, setPaginatedTableData] = useState<
    CheckinDataItem[]
  >([]);

  const [startTimeValue, setStartTimeValue] = useState<Date>(minus1Date);
  const [endTimeValue, setEndTimeValue] = useState<Date>(dayAfter);

  const token = store.getState().user.accessToken;

  const navigate = useNavigate();

  const { updateExpiredBookingStatus, updateOverstayBookingStatus } =
    useUpdate();

  const handleNavigateOnClick = (bookingId: any) => {
    navigate(`${bookingId}`);
  };

  const checkOutColumns = useMemo<MRT_ColumnDef<CheckinDataItem>[]>(
    () => [
      {
        accessorKey: 'bookingNumber',
        header: 'Booking No.',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => handleNavigateOnClick(row.original.bookingId)}
              sx={{
                background: 'black',
                border: 1,
                borderColor: theme.palette.primary.main,
              }}
            >
              {row.original.bookingNumber}
            </Button>
          );
        },
      },
      {
        header: 'Room No.',
        Cell: ({ row }) => {
          if (Array.isArray(row.original.roomCode)) {
            return row.original.roomCode.map((code, index) => (
              <Typography key={index}>{code}</Typography>
            ));
          }
        },
      },
      {
        header: 'Check-In',
        accessorKey: 'derivedCheckInDatetime',
      },
      {
        header: 'Check-Out',
        accessorKey: 'derivedCheckOutDatetime',
      },
      {
        accessorKey: 'customer',
        header: 'Customer',
      },
      {
        header: 'Room Types',
        accessorKey: 'roomTypes',
      },
      {
        accessorKey: 'platform',
        header: 'Booking Source',
      },
      {
        accessorKey: 'otaCode',
        header: 'OTA REF',
      },
      {
        accessorKey: 'overstayDuration',
        header: 'Overstay',
      },
      {
        accessorKey: 'status',
        header: 'Status',
      },
    ],
    []
  );

  const handleOpenCheckIn = () => {
    setIsCheckInOpened(true);
  };

  const handleOpenCheckOut = () => {
    setIsCheckInOpened(false);
  };

  const handleSortByValueChange = (event: any) => {
    setSortByValue(event);
  };

  const handleBookStatusFilterOnChange = (event: any) => {
    setBookStatusFilter(event);
  };

  const handleBookPlatformFilterOnChange = (event: any) => {
    setBookPlatformFilter(event);
  };

  const handleSearchBarOnChange = (event: any) => {
    setSearchBarValue(event.target.value);
  };

  const handleStartTimeValueChange = (e: Date) => {
    setStartTimeValue(e);
  };

  const handleEndTimeValueChange = (e: Date) => {
    setEndTimeValue(e);
  };

  // const handleTableDataPagination = () => {
  //   const startingData = pagination.pageIndex * 10;
  //   const endingData = (pagination.pageIndex + 1) * 10 - 1;

  //   for (
  //     let i = startingData;
  //     i <= endingData && i < displayTableData.length;
  //     i++
  //   ) {
  //     setPaginatedTableData([...paginatedTableData, displayTableData[i]]);
  //   }
  // };
  const fetchListData = () => {
    setIsLoading(true);
    const startDate = startTimeValue;
    const endDate = endTimeValue;
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 0);

    const startTime = convertToEpoch(startDate);
    const endTime = convertToEpoch(endDate);

    // const startTime = startTimeValue.toISOString().slice(0, 16);
    // const endTime = endTimeValue.toISOString().slice(0, 16);

    const queryParams = `startDateTime=${startTime}&endDateTime=${endTime}&forInhouseGuest=true`;

    axiosInstance
      .get(`/bookings/list/?${queryParams}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const results: NewAPIBookingResult[] = res.data;

        const data = results
          .filter((item) => {
            const latestBookingStatus = item.bookingStatus.filter(
              (status) => status.isLatest === true
            )[0].bookingStatus;

            if (latestBookingStatus === bookingStatusEnum.checkOut) {
              return false;
            }
            return true;
          })
          .map((item, index) => {
            const checkInDatetime = item.bookingStatus[0].checkInDatetime;
            const checkOutDatetime = item.bookingStatus[0].checkOutDatetime;
            const actualCheckInTime =
              item.roomBookings[0].actualCheckinDateTime;
            const actualCheckOutTime =
              item.roomBookings[0].actualCheckoutDateTime;
            const derivedCheckInDatetime = actualCheckInTime || checkInDatetime;
            const derivedCheckOutDatetime =
              actualCheckOutTime ||
              (checkInDatetime && actualCheckInTime
                ? new Date(
                    new Date(actualCheckInTime).getTime() +
                      new Date(checkOutDatetime).getTime() -
                      new Date(checkInDatetime).getTime()
                  ).toISOString()
                : checkOutDatetime);

            const checkInData: CheckinDataItem = {
              id: index,
              bookingNumber: item.bookingNo,
              bookingMadeDatetime: format(
                new Date(item.bookingMadeDatetime),
                'dd/MM/yyyy HH:mm'
              ),
              checkInTime: checkInDatetime,
              checkOutTime: checkOutDatetime,
              actualCheckinDatetime: actualCheckInTime,
              actualCheckoutDatetime: actualCheckOutTime,
              derivedCheckInDatetime: derivedCheckInDatetime
                ? format(new Date(derivedCheckInDatetime), 'dd/MM/yyyy HH:mm')
                : '',
              derivedCheckOutDatetime: derivedCheckOutDatetime
                ? format(new Date(derivedCheckOutDatetime), 'dd/MM/yyyy HH:mm')
                : '',
              customer:
                item.customerStayingName === ' '
                  ? item.customerBooked
                  : item.customerStayingName,
              platform: item.platform.platform,
              status: item.bookingStatus[0]
                ? item.bookingStatus[0].bookingStatus
                : '',
              roomCode: item.roomBookings.map((item) => item.roomCode),
              roomTypes: Array.from(
                new Set(item.roomBookings.map((item) => item.roomType))
              ),
              bookingId: item.bookingId,
              overstayDuration:
                item.overstayDuration &&
                item.isOverstay &&
                item.bookingStatus[0].bookingStatus ===
                  bookingStatusEnum.overstay
                  ? item.overstayDuration.toString()
                  : '',
              isOverstay: item.isOverstay ? item.isOverstay : false,
              otaCode: item.otaCode ? item.otaCode : '-',
            };
            return checkInData;
          });
        setCheckInData(data);
      })
      // setTablePaginationData(results.count);
      .catch((error) => {
        Notification.failed(error.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTableDataAssignation = () => {
    const checkInDataFilter = checkInData.filter(
      (item) =>
        item.status === 'Booked' ||
        item.status === 'Confirm Booking' ||
        item.status === 'No Show'
    );
    const checkOutDataFilter = checkInData.filter(
      (item) => item.status === 'Check In' || item.status === 'Overstayed'
    );

    setCheckInTableData(checkInDataFilter);
    setCheckOutTableData(checkOutDataFilter);
  };

  const handleResetDisplayTable = () => {
    if (isCheckInOpened) {
      setDisplayTableData(checkInTableData);
    } else {
      setDisplayTableData(checkOutTableData);
    }
  };

  useEffect(() => {
    fetchListData();
    handleOpenCheckOut();
    updateExpiredBookingStatus(token);
    updateOverstayBookingStatus(token);
  }, [startTimeValue, endTimeValue]);

  useEffect(() => {
    if (checkInTableData && checkOutTableData) {
      handleResetDisplayTable();
    }
  }, [checkInTableData, checkOutTableData, isCheckInOpened]);

  useEffect(() => {
    const uniqueStatus = new Set(
      checkInData
        .filter(
          (item) => item.status === 'Check In' || item.status === 'Overstayed'
        )
        .map((item) => item.status)
    );
    const uniquePlatform = new Set(checkInData.map((item) => item.platform));
    const uniqueStatusArray = Array.from(uniqueStatus);
    const uniquePlatformArray = Array.from(uniquePlatform);
    setBookStatusValue(uniqueStatusArray);
    setBookPlatformValue(uniquePlatformArray);
    if (checkInData) {
      handleTableDataAssignation();
    }
  }, [checkInData]);

  const handleSortTimeASC = (data: CheckinDataItem[]): CheckinDataItem[] =>
    data.slice().sort((a, b) => {
      const dateA = getTimestamp(a.derivedCheckOutDatetime);
      const dateB = getTimestamp(b.derivedCheckOutDatetime);

      return dateA - dateB;
    });

  const handleSortTimeDSC = (data: CheckinDataItem[]): CheckinDataItem[] =>
    data.slice().sort((a, b) => {
      const dateA = getTimestamp(a.derivedCheckOutDatetime);
      const dateB = getTimestamp(b.derivedCheckOutDatetime);

      return dateB - dateA;
    });

  const handleSortBookingNumASC = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let sortedData = data.slice();
    const charToRemove = 'CKLIA-';
    sortedData.sort((a, b) => {
      const aStripString = a.bookingNumber.replace(charToRemove, '');
      const bStripString = b.bookingNumber.replace(charToRemove, '');
      const numberedA = Number(aStripString);
      const numberedB = Number(bStripString);
      return numberedA - numberedB;
    });
    return sortedData;
  };

  const handleSortBookingNumDSC = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let sortedData = [...data];
    const charToRemove = 'CKLIA-';
    sortedData.sort((a, b) => {
      const aStripString = a.bookingNumber.replace(charToRemove, '');
      const bStripString = b.bookingNumber.replace(charToRemove, '');
      const numberedA = Number(aStripString);
      const numberedB = Number(bStripString);
      return numberedB - numberedA;
    });
    // console.log(sortedData);
    return sortedData;
  };

  const handleSortOverstayASC = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let sortedData = [...data];
    sortedData = sortedData.sort((a, b) => {
      const aEpoch = overstayStringToEpoch(a.overstayDuration);
      const bEpoch = overstayStringToEpoch(b.overstayDuration);

      return aEpoch - bEpoch;
    });
    // console.log(sortedData);
    return sortedData;
  };

  const handleSortOverstayDSC = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let sortedData = [...data];
    sortedData = sortedData.sort((a, b) => {
      const aEpoch = overstayStringToEpoch(a.overstayDuration);
      const bEpoch = overstayStringToEpoch(b.overstayDuration);

      return bEpoch - aEpoch;
    });
    // console.log(sortedData);
    return sortedData;
  };

  const handleSortBy = (data: CheckinDataItem[]): CheckinDataItem[] => {
    let newData: CheckinDataItem[] = [];
    if (sortByValue === 'All') {
      newData = [...data];
    } else if (sortByValue === 'Time ASC') {
      newData = handleSortTimeASC(data);
    } else if (sortByValue === 'Time DSC') {
      newData = handleSortTimeDSC(data);
    } else if (sortByValue === 'Booking Number ASC') {
      newData = handleSortBookingNumASC(data);
    } else if (sortByValue === 'Booking Number DSC') {
      newData = handleSortBookingNumDSC(data);
    } else if (sortByValue === 'Overstay ASC') {
      newData = handleSortOverstayASC(data);
    } else if (sortByValue === 'Overstay DSC') {
      newData = handleSortOverstayDSC(data);
    }
    return newData;
  };

  const handleBookStatusFilter = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let newData: CheckinDataItem[] = [];
    if (bookStatusFilter === 'All') {
      newData = [...data];
    } else {
      bookStatusValue.map((item) => {
        if (bookStatusFilter === item) {
          newData = data.filter((item2) => {
            return item2.status === item;
          });
        }
      });
    }
    return newData;
  };

  const handleBookPlatformFilter = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let newData: CheckinDataItem[] = [];
    if (bookPlatformFilter === 'All') {
      newData = [...data];
    } else {
      bookPlatformValue.map((item) => {
        if (bookPlatformFilter === item) {
          newData = data.filter((item2) => {
            return item2.platform === item;
          });
        }
      });
    }
    return newData;
  };

  const handleSearchTableData = () => {
    const unsearchedData = handleAllFilter();
    if (searchBarValue) {
      const searchedData = unsearchedData.filter((item) => {
        const { customer, bookingNumber, roomCode } = item;
        return (
          customer.toLowerCase().includes(searchBarValue.toLowerCase()) ||
          bookingNumber.toLowerCase().includes(searchBarValue.toLowerCase()) ||
          roomCode.toString().includes(searchBarValue.toLowerCase())
        );
      });
      setDisplayTableData(searchedData);
    }
  };

  const handleAllFilter = () => {
    const rawUnsortedData = isCheckInOpened
      ? [...checkInTableData]
      : [...checkOutTableData];
    const sortedData = handleSortBy(rawUnsortedData);
    const filteredBookStatusData = handleBookStatusFilter(sortedData);
    const filteredBookPlatformData = handleBookPlatformFilter(
      filteredBookStatusData
    );
    setDisplayTableData(filteredBookPlatformData);
    return filteredBookPlatformData;
  };

  useEffect(() => {
    handleAllFilter();
  }, [sortByValue, bookStatusFilter, bookPlatformFilter]);

  function overstayStringToEpoch(durationString: string): number {
    const timeComponents = durationString.split(' ');
    let totalMilliseconds = 0;

    timeComponents.forEach((component, index) => {
      if (component.includes('days')) {
        const days = parseInt(timeComponents[index - 1]);
        totalMilliseconds += days * 24 * 60 * 60 * 1000;
      }
      if (component.includes('hours')) {
        const hours = parseInt(timeComponents[index - 1]);
        totalMilliseconds += hours * 60 * 60 * 1000;
      }
      if (component.includes('minutes')) {
        const minutes = parseInt(timeComponents[index - 1]);
        totalMilliseconds += minutes * 60 * 1000;
      }
    });

    // Calculate the epoch timestamp
    const epochTimestamp = totalMilliseconds;
    return epochTimestamp;
  }

  return (
    <Box
      width={'85%'}
      border={1}
      borderColor={theme.palette.primary.main}
      margin={'auto'}
    >
      <Stack
        borderBottom={2}
        borderColor={theme.palette.primary.main}
        direction={'row'}
      >
        {/* <Stack  
          borderRight={2}
          borderColor={theme.palette.primary.main}
          width={"50%"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <ButtonBase
            onClick={handleOpenCheckIn}
            sx={{
              backgroundColor: isCheckInOpened
                ? theme.palette.primary.main
                : "black",
              width: "100%",
              height: "100%",
              padding: 2,
              display: "flex",
              justifyContent: "space-between",
              transition: "background-color 0.3s ease",
            }}
          >
            <MeetingRoomIcon
              fontSize={"large"}
              sx={{ color: isCheckInOpened ? "black" : "white" }}
            />
            <Typography
              variant={"h4"}
              sx={{ color: isCheckInOpened ? "black" : "white" }}
            >
              Check In
            </Typography>
          </ButtonBase>
        </Stack>
        <Stack width={"50%"} direction={"row"} justifyContent={"space-between"}>
          <ButtonBase
            onClick={handleOpenCheckOut}
            sx={{
              backgroundColor: isCheckInOpened
                ? "black"
                : theme.palette.primary.main,
              width: "100%",
              height: "100%",
              padding: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <NoMeetingRoomIcon
              fontSize={"large"}
              sx={{ color: isCheckInOpened ? "white" : "black" }}
            />
            <Typography
              variant={"h4"}
              color={isCheckInOpened ? "white" : "black"}
            >
              Check Out
            </Typography>
          </ButtonBase> */}
        {/* </Stack> */}
      </Stack>
      <Stack
        paddingY={1}
        paddingX={3}
        borderBottom={2}
        borderColor={theme.palette.primary.main}
        sx={{ background: '#232323' }}
      >
        <Typography variant={'h3'} color={theme.palette.primary.main}>
          Current Inhouse Guest
        </Typography>
      </Stack>
      <Grid container borderBottom={2} borderColor={theme.palette.primary.main}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            paddingY={4}
            paddingX={10}
            borderRight={1}
            borderColor={theme.palette.primary.main}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            // sx={{ background: "white" }}
          >
            <DatePicker
              value={startTimeValue}
              onChange={(e) => handleStartTimeValueChange(e as Date)}
              slotProps={{
                layout: {
                  sx: {
                    '.MuiDateCalendar-root': {
                      color: theme.palette.primary.main,
                      borderRadius: 2,
                      borderWidth: 1,
                      borderColor: theme.palette.primary.main,
                      border: '1px solid',
                      backgroundColor: theme.palette.background.paper,
                    },
                    '.MuiButtonBase-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiTypography-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersCalendarHeader-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersMonth-monthButton': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersCalendarHeader-label': {
                      color: theme.palette.primary.main,
                    },
                    '.Mui-selected': {
                      color: 'black',
                    },
                    '.MuiPickersYear-yearButton': {
                      color: theme.palette.primary.main,
                    },
                  },
                },
                textField: {
                  InputProps: {
                    style: {
                      background: '#232323',
                    },
                  },
                  sx: {
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      width: '20em',
                      padding: '0',
                      borderRadius: '1em',
                    },
                  },
                },
                openPickerIcon: {
                  sx: {
                    color: theme.palette.primary.main,
                  },
                },
              }}
            />
            <ArrowRightAltIcon fontSize={'large'} />
            <DatePicker
              value={endTimeValue}
              onChange={(e) => handleEndTimeValueChange(e as Date)}
              slotProps={{
                layout: {
                  sx: {
                    '.MuiDateCalendar-root': {
                      color: theme.palette.primary.main,
                      borderRadius: 2,
                      borderWidth: 1,
                      borderColor: theme.palette.primary.main,
                      border: '1px solid',
                      backgroundColor: theme.palette.background.paper,
                    },
                    '.MuiButtonBase-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiTypography-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersCalendarHeader-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersMonth-monthButton': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersCalendarHeader-label': {
                      color: theme.palette.primary.main,
                    },
                    '.Mui-selected': {
                      color: 'black',
                    },
                    '.MuiPickersYear-yearButton': {
                      color: theme.palette.primary.main,
                    },
                  },
                },
                textField: {
                  InputProps: {
                    style: {
                      background: '#232323',
                    },
                  },
                  sx: {
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      width: '20em',
                      padding: '0',
                      borderRadius: '1em',
                    },
                  },
                },
                openPickerIcon: {
                  sx: {
                    color: theme.palette.primary.main,
                  },
                },
              }}
            />
          </Grid>
        </LocalizationProvider>
        <Grid item md={4} sm={12} xs={12}>
          <ButtonBase
            onClick={fetchListData}
            sx={{
              width: '100%',
              height: '100%',
              background: '#232323',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant={'h3'}>Change Date</Typography>
          </ButtonBase>
        </Grid>
      </Grid>
      <Stack borderBottom={2} borderColor={theme.palette.primary.main}>
        <Grid container padding={2} spacing={2} justifyContent={'center'}>
          <Grid item md={10} sm={12} xs={12}>
            <TextField
              size={'small'}
              onChange={(e) => {
                handleSearchBarOnChange(e);
              }}
              sx={{
                width: '100%',
                borderRadius: 0,
                border: 1,
                borderColor: theme.palette.primary.main,
              }}
            />
          </Grid>
          {/* <Grid item md={1} sm={12} xs={12}>
            <Button
              onClick={handleSearchTableData}
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: 0,
                border: 1,
                borderColor: theme.palette.primary.main,
                background: "#232323",
              }}
            >
              <Typography color={"white"} fontWeight={700}>
                Search
              </Typography>
            </Button>
          </Grid> */}
        </Grid>
      </Stack>
      <Stack>
        <Grid
          container
          borderBottom={2}
          borderColor={theme.palette.primary.main}
          minHeight={'5.9em'}
        >
          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            display={'flex'}
            flexDirection={'row'}
          >
            <Stack
              width={'30%'}
              borderRight={2}
              padding={3}
              justifyContent={'center'}
              alignItems={'center'}
              borderColor={theme.palette.primary.main}
            >
              Sorter
            </Stack>
            <Stack
              width={'70%'}
              borderRight={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack
                height={'50%'}
                borderBottom={1}
                borderColor={theme.palette.primary.main}
                justifyContent={'center'}
                paddingLeft={2}
              >
                <Typography>Sort By</Typography>
              </Stack>
              <Stack height={'50%'}>
                <Select
                  size={'small'}
                  value={sortByValue}
                  onChange={(e) => handleSortByValueChange(e.target.value)}
                >
                  <MenuItem value={'All'}>None</MenuItem>
                  <MenuItem value={'Time ASC'}>
                    {isCheckInOpened ? 'Check-In ASC' : 'Check-Out ASC'}
                  </MenuItem>
                  <MenuItem value={'Time DSC'}>
                    {isCheckInOpened ? 'Check-In DSC' : 'Check-Out DSC'}
                  </MenuItem>
                  <MenuItem value={'Booking Number ASC'}>
                    Booking No. ASC
                  </MenuItem>
                  <MenuItem value={'Booking Number DSC'}>
                    Booking No. DSC
                  </MenuItem>
                  <MenuItem value={'Overstay ASC'}>Overstay ASC</MenuItem>
                  <MenuItem value={'Overstay DSC'}>Overstay DSC</MenuItem>
                </Select>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            display={'flex'}
            flexDirection={'row'}
          >
            <Stack
              width={'30%'}
              borderRight={2}
              padding={3}
              justifyContent={'center'}
              alignItems={'center'}
              borderColor={theme.palette.primary.main}
            >
              <Typography>Filter</Typography>
            </Stack>
            <Stack
              width={'35%'}
              borderRight={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack
                height={'50%'}
                borderBottom={1}
                borderColor={theme.palette.primary.main}
                justifyContent={'center'}
                paddingLeft={2}
              >
                <Typography>Book Status</Typography>
              </Stack>
              <Stack height={'50%'}>
                <Select
                  size={'small'}
                  value={bookStatusFilter}
                  onChange={(e) =>
                    handleBookStatusFilterOnChange(e.target.value)
                  }
                >
                  <MenuItem value={'All'}>None</MenuItem>
                  {bookStatusValue.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Stack>
            <Stack
              width={'35%'}
              borderRight={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack
                height={'50%'}
                borderBottom={1}
                borderColor={theme.palette.primary.main}
                justifyContent={'center'}
                paddingLeft={2}
              >
                <Typography>Book Platform</Typography>
              </Stack>
              <Stack height={'50%'}>
                <Select
                  size={'small'}
                  value={bookPlatformFilter}
                  onChange={(e) =>
                    handleBookPlatformFilterOnChange(e.target.value)
                  }
                >
                  <MenuItem value={'All'}>None</MenuItem>
                  {bookPlatformValue.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <CustomTable
        columns={checkOutColumns}
        data={displayTableData}
        isLoading={isLoading}
        handlePaginationChange={setPagination}
        pagination={pagination}
        rowCount={displayTableData.length}
        oddEvenBackground={true}
        globalFilter={searchBarValue}
      />
    </Box>
  );
}

export default NewCheckOutList;
