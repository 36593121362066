import {
  Box,
  useTheme,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  TablePagination,
} from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import HouseKeepingFilterBar from "./HouseKeepingFilterBar";
import { StyledTableCell, StyledTableRow } from "../../constants/tableStyle";
import TableLoading from "../global/loading/TableLoading";
import axiosInstance from "../../constants/axiosConfig";
import { store } from "../../redux/store";
import { HouseKeepingLogInterface } from "../../models/supervisor/supervisorInterface";
import format from "date-fns/format";
import { ZoneInterface } from "../../models/rooms/roomsInterface";
import { filterTableData } from "../../utils/functions";

function HouseKeepingLog() {
  const theme = useTheme();
  const token = store.getState().user.accessToken;
  const showSortByFilter = false;
  const filterTitle = "Log";

  const [filterExpanded, setFilterExpanded] = useState<boolean>(false);
  const [searchValueFilter, setSearchValueFilter] = useState<string>("");
  const [roomStatusFilter, setRoomStatusFilter] = useState<string[]>([]);
  const [zoneFilter, setZoneFilter] = useState<string[]>([]);
  const [zoneData, setZoneData] = useState<ZoneInterface[]>([]);
  const [sortBy, setSortBy] = useState<string>("");

  const [rows, setRows] = useState<HouseKeepingLogInterface[]>([]);
  const [page, setPage] = useState<number>(0);
  const [filteredRows, setFilteredRows] = useState<HouseKeepingLogInterface[]>(
    []
  );

  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [fetchLoading, setFetchLoading] = useState<boolean>(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const fetchHousekeepingLog = async () => {
      setFetchLoading(true);
      await axiosInstance
        .get(`accounts/housekeeping/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setRows(res.data.data);
            setFilteredRows(res.data.data);
          }
        });
      setFetchLoading(false);
    };

    fetchHousekeepingLog();
  }, [token]);

  useEffect(() => {
    const fetchAllZones = async () => {
      await axiosInstance
        .get(`rooms/zone/get-all-zone`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setZoneData(res.data.data);
          }
        });
    };

    fetchAllZones();
  }, [token]);

  const handleFilterSubmit = async () => {
    const filteredZonesObject = zoneData.filter((item) =>
      zoneFilter.includes(item.zoneName)
    );
    const zoneIds = filteredZonesObject.map((item) => item.zoneId).join(":");
    setFetchLoading(true);
    await axiosInstance
      .get(`accounts/housekeeping/`, {
        params: {
          zoneId: zoneIds,
          roomStatus: roomStatusFilter.join(":"),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setRows(res.data.data);
          setFilteredRows(res.data.data);
          setPage(0);
        }
      });
    setFetchLoading(false);
  };

  useEffect(() => {
    setPage(0);
    const newRow = filterTableData(rows, searchValueFilter);
    setFilteredRows(newRow);
  }, [searchValueFilter, rows]);

  const visibleRows = useMemo(
    () =>
      filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredRows, page, rowsPerPage]
  );

  return (
    <Box
      width={"100%"}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <HouseKeepingFilterBar
        filterExpanded={filterExpanded}
        setFilterExpanded={setFilterExpanded}
        searchValue={searchValueFilter}
        roomStatus={roomStatusFilter}
        zone={zoneFilter}
        sortBy={sortBy}
        setSearchValue={setSearchValueFilter}
        setRoomStatus={setRoomStatusFilter}
        setZone={setZoneFilter}
        setSortBy={setSortBy}
        handleSubmit={handleFilterSubmit}
        showSortByFilter={showSortByFilter}
        filterTitle={filterTitle}
      />
      <TableContainer
        component={Paper}
        sx={{ display: "flex", flex: 1, flexDirection: "column" }}
      >
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Housekeeper</StyledTableCell>
              <StyledTableCell>Room</StyledTableCell>
              <StyledTableCell>Zone</StyledTableCell>
              <StyledTableCell>DateTime</StyledTableCell>
              <StyledTableCell>Initial Status</StyledTableCell>
              <StyledTableCell>Changed Status</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {fetchLoading ? (
            <TableLoading />
          ) : (
            <>
              {filteredRows.length > 0 && (
                <TableBody
                  sx={{ width: "80%", minHeight: "80%", overflowY: "auto" }}
                >
                  {visibleRows.map((row) => (
                    <StyledTableRow
                      key={row.housekeepingId}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <StyledTableCell>{row.staffName}</StyledTableCell>
                      <StyledTableCell>{row.roomCode}</StyledTableCell>
                      <StyledTableCell>{row.roomZone}</StyledTableCell>
                      <StyledTableCell>
                        {format(
                          new Date(row.housekeepingDatetime),
                          "dd MMM yyyy HH:mm:ss"
                        )}
                      </StyledTableCell>
                      <StyledTableCell>{row.initialStatus}</StyledTableCell>
                      <StyledTableCell>{row.changedStatus}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
              {filteredRows.length === 0 && (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>data not available</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              )}
            </>
          )}
        </Table>
        <TablePagination
          sx={{
            color: "white",
            backgroundColor: theme.palette.background.default,
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}

export default HouseKeepingLog;
