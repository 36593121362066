import { useEffect, useState } from "react";
import { Box, Typography, TextField, MenuItem } from "@mui/material";
import axiosInstance from "../../constants/axiosConfig";

// utils
import Notification from "../../utils/notificationConfig";

// components
import ModalLoading from "../../components/global/loading/ModalLoading";

// types and interfaces
import { DropdownInterface } from "../../models/globalInterface";
import { RoomDataInterface } from "../../models/rooms/roomsInterface";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
interface DisplayedFlorPlan {
  roomZone: string;
  typeList: typeListInterface[];
}
interface typeListInterface {
  typeName: string;
  roomList: roomListInterface[];
}
interface roomListInterface {
  roomId: string;
  roomNo: string;
  roomStatus: string;
  roomRemarks: string;
}

export default function FloorPlan() {
  const token = useSelector((state: RootState) => state.user.accessToken);
  const [roomData, setRoomData] = useState<RoomDataInterface[]>([]);
  const [zoneDropdown, setZoneDropdown] = useState<DropdownInterface[]>([]);

  const [fetchLoading, setFetchLoading] = useState<boolean>();

  const [selectedZone, setSelectedZone] = useState<string>("All Zones");
  const [displayedData, setDisplayedData] = useState<DisplayedFlorPlan[]>([]);

  useEffect(() => {
    document.title = "Capsule Transit | Room Floor Plan";
    getRoomData();
  }, []);

  useEffect(() => {
    if (roomData) {
      const data: DropdownInterface[] = [];
      data.push({
        value: "All Zones",
        label: "All Zones",
      });
      roomData.forEach((e) => {
        data.push({
          value: e.roomZone,
          label: e.roomZone,
        });
      });
      const filteredData = data.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.value === item.value)
      );
      setZoneDropdown(filteredData);
    }
  }, [roomData]);

  useEffect(() => {
    if (!selectedZone) return;

    let filteredData = roomData;
    if (selectedZone && selectedZone !== "All Zones") {
      filteredData = roomData.filter((room) => room.roomZone === selectedZone);
    }

    const groupedData: { [key: string]: RoomDataInterface[] } = {};
    filteredData.forEach((room) => {
      if (!groupedData[room.roomZone]) {
        groupedData[room.roomZone] = [];
      }
      groupedData[room.roomZone].push(room);
    });

    const newDisplayedData: DisplayedFlorPlan[] = Object.keys(groupedData).map(
      (zone) => {
        return {
          roomZone: zone,
          typeList: Object.values(
            groupedData[zone].reduce((acc, room) => {
              if (!acc[room.roomType]) {
                acc[room.roomType] = {
                  typeName: room.roomType,
                  roomList: [],
                };
              }
              acc[room.roomType].roomList.push({
                roomId: room.roomId,
                roomNo: room.roomNo,
                roomStatus: room.roomStatus,
                roomRemarks: room.roomRemarks,
              });
              return acc;
            }, {} as { [key: string]: typeListInterface })
          ),
        };
      }
    );
    setDisplayedData(newDisplayedData);
  }, [selectedZone, roomData]);

  const getRoomData = () => {
    axiosInstance
      .get("/rooms/get-all-rooms", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setRoomData(res.data.data);
          return;
        }
        Notification.failed("something wrong, please try again");
      })
      .catch((e) => {
        console.log(e);
        Notification.failed("something wrong, please try again");
      })
      .finally(() => {
        setFetchLoading(false);
      });
  };

  return (
    <Box width={"100%"}>
      <Typography variant="h1" mb={10} fontSize="2rem">
        Room Floor Plan
      </Typography>
      {!fetchLoading && zoneDropdown.length > 0 ? (
        <>
          <TextField
            select
            label="Show Zone"
            value={selectedZone}
            onChange={(e) => setSelectedZone(e.target.value)}
            sx={{ width: "10rem" }}
          >
            {zoneDropdown.map((e: DropdownInterface, i) => {
              return (
                <MenuItem key={i} value={e.value}>
                  {e.label}
                </MenuItem>
              );
            })}
          </TextField>
          <Box mt={"4rem"}>
            {displayedData.map((e: DisplayedFlorPlan, i) => {
              return (
                <Box key={i} width={"100%"} mb={"3rem"}>
                  <Typography
                    mb={"1rem"}
                    variant="h3"
                    fontSize={"1.5rem"}
                    fontWeight={"bold"}
                  >
                    {e.roomZone}
                  </Typography>
                  {e.typeList.map((type: typeListInterface, i) => {
                    return (
                      <Box key={i} width={"100%"} mb={"2rem"}>
                        <Typography fontWeight={"bold"}>
                          {type.typeName}
                        </Typography>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {type.roomList.map((room: roomListInterface, i) => {
                            let color = "transparent";
                            if (room.roomStatus === "Available") {
                              color = "#26c281";
                            } else if (room.roomStatus === "Dirty") {
                              color = "#F7CA18";
                            } else if (room.roomStatus === "Occupied") {
                              color = "#E7505A";
                            } else if (
                              room.roomStatus === "Under Maintenance"
                            ) {
                              color = "#555555";
                            } else if (room.roomStatus === "Booked") {
                              color = "#E87E04";
                            }
                            return (
                              <Box
                                key={i}
                                sx={{
                                  width: "10%",
                                  aspectRatio: "1.2/1",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  backgroundColor: `${color}`,
                                  border: "1px solid white",
                                }}
                              >
                                {room.roomNo}
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        </>
      ) : (
        <Box
          width={"100%"}
          height={"50vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <ModalLoading />
        </Box>
      )}
    </Box>
  );
}
