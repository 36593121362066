import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as Yup from "yup";
import theme from "../../assets/theme/theme";

//Components
import { Add, Remove } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import { format } from "date-fns";
import { Form, Formik, FormikErrors, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BASE_API from "../../constants/api";
import axiosInstance from "../../constants/axiosConfig";
import { durationOptions, durationOptionsV2 } from "../../constants/durations";
import {
  IBooking,
  IBookingInfo,
  IPlatform,
  IRoomExpressBookingRequest,
  IRoomType,
  IStepper,
} from "../../models/bookings/Booking";
import { SetExpressBooking } from "../../redux/booking/expressbooking";
import { RootState, store } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import LockIcon from "@mui/icons-material/Lock";
import { IRoomRates } from "../../models/payment/PaymentInterface";

function useGetRoomTypesHook(url: string, token: string) {
  const [roomTypes, setRoomTypes] = useState<IRoomType[]>([]);
  const [isLoadingRoomTypeFetch, setIsLoadingRoomTypeFetch] =
    useState<boolean>(false);
  useEffect(() => {
    setIsLoadingRoomTypeFetch(true);

    const request = () => {
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.status === 200) {
            setRoomTypes(res.data);
          }
          if (res.status === 409) {
            alert("Try Again");
          }
        })
        .catch((e) => {
          Notification.failed(e.response.data.message);
        })
        .finally(() => {
          setIsLoadingRoomTypeFetch(false);
        });
    };
    request();
  }, []);

  return { roomTypes, isLoadingRoomTypeFetch };
}
const RoomTypeCountComponent = ({
  roomType,
  roomTypeReqAddCallback,
  setFieldValue,
  adultPax,
  childrenPax,
  handleAddMaxPax,
  setAddedRoomTypes,
}: {
  roomType: IRoomType;
  roomTypeReqAddCallback: (roomTypeId: string, count: number) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<IBookingInfo>>;
  adultPax: number;
  childrenPax: number;
  handleAddMaxPax: (roomMaxPax: number, type: string) => void;

  setAddedRoomTypes: React.Dispatch<React.SetStateAction<IRoomType[]>>;
}) => {
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    roomTypeReqAddCallback(roomType.typeName, count);
    if (count > 0) {
      setAddedRoomTypes((prevValue) => {
        const exist = prevValue.find(
          (value) => value.typeId === roomType.typeId
        );

        if (exist) {
          return prevValue;
        } else {
          return [...prevValue, roomType];
        }
      });
    } else {
      setAddedRoomTypes((prevValue) => {
        const exist = prevValue.find(
          (value) => value.typeId === roomType.typeId
        );

        if (exist) {
          return prevValue.filter((value) => value.typeId !== roomType.typeId);
        } else {
          return prevValue;
        }
      });
    }
    return () => {};
  }, [count]);

  return (
    <Stack display={"flex"} direction={"row"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        bgcolor={roomType.colorTags}
        height={"50px"}
        width={"100%"}
        paddingX={2}
      >
        <Typography variant="h4" color={"black"}>
          {roomType.typeName}
        </Typography>
      </Box>
      <ButtonBase
        onClick={() => {
          setCount(count - 1);

          if (adultPax < roomType.maxPax) {
            setFieldValue("adultPax", 0);
            setFieldValue(
              "childrenPax",
              childrenPax + adultPax - roomType.maxPax
            );
          } else {
            setFieldValue("adultPax", adultPax - roomType.maxPax);
          }

          handleAddMaxPax(roomType.maxPax, "Deduct");
        }}
        disabled={count === 0}
      >
        <Box
          display={"flex"}
          height={"50px"}
          bgcolor={"black"}
          border={1}
          borderColor={roomType.colorTags}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ aspectRatio: 1 / 1 }}
        >
          <Remove />
        </Box>
      </ButtonBase>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"50px"}
        width={"100px"}
        bgcolor={theme.palette.background.default}
        borderTop={1}
        borderBottom={1}
        borderColor={roomType.colorTags}
      >
        {count}
      </Box>
      <ButtonBase
        onClick={() => {
          setCount(count + 1);
          setFieldValue("adultPax", adultPax + roomType.maxPax);

          handleAddMaxPax(roomType.maxPax, "Add");
        }}
      >
        <Box
          display={"flex"}
          height={"50px"}
          bgcolor={"black"}
          border={1}
          borderColor={roomType.colorTags}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ aspectRatio: 1 / 1 }}
        >
          <Add />
        </Box>
      </ButtonBase>
    </Stack>
  );
};

const ExpressBookingForm = ({
  setFieldValue,
  initialValues,
  handleChangeStep,
  allPlatforms,
  setRoomTypeCountCallback,
  expressBookingReq,
}: {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<IBooking>>;
  initialValues: IBookingInfo;
  handleChangeStep: (step: IStepper) => void;
  allPlatforms: IPlatform[];
  expressBookingReq: IRoomExpressBookingRequest[];
  setRoomTypeCountCallback: (roomTypeId: string, count: number) => void;
}) => {
  const TableGridItems = styled(Grid)(() => ({}));
  const TableStyledLabel = styled(Typography)(() => ({
    padding: "0 20px",
  }));
  const token = store.getState().user.accessToken;
  const lot = store.getState().user.lotId;

  const [bookingDurationOptions, setBookingDurationOptions] = useState<any[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { roomTypes } = useGetRoomTypesHook(
    `${BASE_API}/rooms/v1/room-type/list/`,
    token
  );

  const [maxPax, setMaxPax] = useState<number>(0);

  const [walkIn, setWalkIn] = useState<string>("");

  useEffect(() => {
    const walkInId = allPlatforms.find(
      (item) => item.platform === "Walk In"
    )?.platformId;

    if (walkInId) {
      setWalkIn(walkInId);
    }
  }, [allPlatforms]);

  useEffect(() => {
    if (Number(lot) === 2) {
      setBookingDurationOptions(durationOptionsV2);
    } else {
      setBookingDurationOptions(durationOptions);
    }
  }, [lot]);

  const bookingReduxState = useSelector(
    (state: RootState) => state.expressbooking
  );

  const disableOTANo = (platformIdParams: string) => {
    const foundPlatform = allPlatforms.find(
      (platform) => platform.platformId === platformIdParams
    );

    if (!foundPlatform?.platform.includes("OTA -")) {
      return true;
    }
    return false;
  };

  const handleAddMaxPax = (roomMaxPax: number, type: string) => {
    if (type === "Add") {
      setMaxPax((prevMax) => prevMax + roomMaxPax);
    } else if (type === "Deduct") {
      setMaxPax((prevMax) => prevMax - roomMaxPax);
    }
  };

  const checkBookingPlatformTier = async (
    platformId: string,
    token: string
  ): Promise<boolean> => {
    setLoading(true);
    const apiUrl = `/rooms/platform-tier/get-by-platform/${platformId}`;

    try {
      const response = await axiosInstance.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.status === "success";
    } catch (error) {
      console.error("Error checking platform tier:", error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const [roomRates, setRoomRates] = useState<IRoomRates[]>([]);
  const [addedRoomTypes, setAddedRoomTypes] = useState<IRoomType[]>([]);

  useEffect(() => {
    const fetchRates = () => {
      const apiUrlParams = new URLSearchParams();
      apiUrlParams.append("withAccountsLot", "true");
      axiosInstance
        .get(`/rooms/rate/?${apiUrlParams.toString()}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const updatedRoomRates = res.data.map((rate: any) => ({
            ...rate,
            roomRate: parseFloat(rate.roomRate),
          }));

          setRoomRates(updatedRoomRates);
        })
        .catch((res) => res.response.data.error);
    };
    fetchRates();
  }, []);

  const checkRoomRates = (duration: number) => {
    let canPass = true;
    addedRoomTypes.forEach((roomType) => {
      const rateFound = roomRates.find(
        (roomRate) =>
          roomRate.roomType === roomType.typeId &&
          roomRate.hoursOfStay === duration
      );

      if (!rateFound) {
        Notification.failed(
          `Room Rate ${roomType.typeName} for ${duration} hrs has not been assigned.`
        );
        canPass = false;
      }
    });

    return canPass;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues.platformId
      ? initialValues
      : { ...initialValues, platformId: walkIn },
    validationSchema: Yup.object().shape({
      adultPax: Yup.number().positive("Number must be positive"),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      if (values.platform.includes("OTA -") && !values.otaRefNo) {
        Notification.failed("Please provide OTA Ref No.");
        return;
      }

      if (expressBookingReq.length === 0) {
        Notification.failed("Please select at least a room first");
        return;
      }

      if (!checkRoomRates(values.duration)) {
        return;
      }
      if (values.adultPax + values.childrenPax > maxPax) {
        Notification.failed(
          "Guest Pax exceeded selected rooms, please add more rooms or deduct pax"
        );
        return;
      }

      const bookingPlatformTierFound = await checkBookingPlatformTier(
        values.platformId,
        token
      );

      if (!bookingPlatformTierFound) {
        Notification.failed(
          "Tier not defined for this booking source, please go to Room management to register one"
        );
        return;
      }

      dispatch(
        SetExpressBooking({
          bookingInfo: values,
          roomBookings: bookingReduxState.roomBookings,
        })
      );
      setFieldValue("bookingInfo", values);
      handleChangeStep({ step: 2, text: "Guest Assignation" });
    },
  });

  useEffect(() => {
    const updateDate = () => {
      const checkInDate = new Date();
      const checkOutDate = new Date(checkInDate.getTime());

      formik.setFieldValue("checkInDate", checkInDate);

      checkOutDate.setHours(checkInDate.getHours() + formik.values.duration);
      formik.setFieldValue("checkOutDate", checkOutDate);
    };

    // Set an interval to update the date every 5 seconds
    const intervalId = setInterval(updateDate, 5000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [formik]);

  if (walkIn) {
    return (
      <Form onSubmit={formik.handleSubmit}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ marginTop: "30px" }}
        >
          <Stack
            spacing={2}
            sx={{
              width: "80%",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Typography
              variant="h2"
              sx={{ textAlign: "start", marginBottom: "10px" }}
            >
              Booking Info
            </Typography>
            <Stack
              direction={"row"}
              spacing={"100px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography width={"120px"} variant="h4">
                Duration
              </Typography>

              <Box display={"flex"} flexDirection={"row"} width={"100%"}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  width={"100%"}
                  height={"40px"}
                >
                  {bookingDurationOptions.map((option, index) => (
                    <ButtonBase
                      key={index}
                      onClick={() => {
                        formik.setFieldValue("duration", option.time);

                        const checkInDate = new Date(formik.values.checkInDate);

                        checkInDate.setHours(
                          checkInDate.getHours() + option.time
                        );

                        formik.setFieldValue("checkOutDate", checkInDate);
                      }}
                      sx={{ height: "100%", width: "25%" }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        width={"100%"}
                        height={"100%"}
                        bgcolor={
                          formik.values.duration === option.time
                            ? theme.palette.primary.main
                            : theme.palette.background.default
                        }
                        borderRadius={2}
                      >
                        <Typography
                          variant="h4"
                          color={
                            formik.values.duration === option.time
                              ? "black"
                              : "white"
                          }
                        >
                          {option.label}
                        </Typography>
                      </Box>
                    </ButtonBase>
                  ))}
                </Stack>
              </Box>
            </Stack>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack
                direction={"row"}
                spacing={"100px"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography width={"120px"} variant="h4">
                  Check In
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  width={"100%"}
                  border={2}
                  borderColor={theme.palette.primary.main}
                  borderRadius={"5px"}
                >
                  <TextField
                    size={"small"}
                    value={format(
                      new Date(formik.values.checkInDate),
                      "dd MMMM yyyy"
                    )}
                    aria-readonly={true}
                    sx={{
                      color: "white",
                      width: "80%",
                    }}
                  />

                  <TextField
                    size={"small"}
                    value={format(new Date(formik.values.checkInDate), "HH:mm")}
                    sx={{
                      width: "20%",
                      borderLeft: 1,
                      borderColor: theme.palette.primary.main,
                    }}
                  />
                </Box>
              </Stack>

              <Stack
                direction={"row"}
                spacing={"100px"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography width={"120px"} variant="h4">
                  Check Out
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  width={"100%"}
                  border={2}
                  borderColor={theme.palette.primary.main}
                  borderRadius={"5px"}
                >
                  <TextField
                    size={"small"}
                    value={format(
                      new Date(formik.values.checkOutDate),
                      "dd MMMM yyyy"
                    )}
                    sx={{
                      color: "white",
                      width: "80%",
                    }}
                  />
                  <TextField
                    size={"small"}
                    value={format(
                      new Date(formik.values.checkOutDate),
                      "HH:mm"
                    )}
                    sx={{
                      width: "20%",
                      borderLeft: 1,
                      borderColor: theme.palette.primary.main,
                    }}
                  />
                </Box>
              </Stack>
              <Stack direction={"row"} width={"100%"} spacing={2}>
                <Stack
                  direction={"row"}
                  spacing={"118px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"50%"}
                >
                  <Typography width={"120px"} variant="h4">
                    Adult Pax
                  </Typography>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    width={"100%"}
                    border={2}
                    borderColor={theme.palette.primary.main}
                    borderRadius={"5px"}
                  >
                    <TextField
                      size={"small"}
                      type={"number"}
                      value={formik.values.adultPax}
                      name={"adultPax"}
                      onChange={formik.handleChange}
                      inputProps={{ min: 1, readOnly: true }}
                      sx={{
                        width: "100%",
                        borderColor: theme.palette.primary.main,
                        backgroundColor: "#232323",
                        ".MuiInputBase-input": {
                          border: "none",
                        },
                      }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={"118px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"50%"}
                >
                  <Typography width={"120px"} variant="h4">
                    Child Pax
                  </Typography>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    width={"100%"}
                    border={2}
                    borderColor={theme.palette.primary.main}
                    borderRadius={"5px"}
                  >
                    <ButtonBase
                      onClick={() => {
                        formik.setFieldValue(
                          "childrenPax",
                          formik.values.childrenPax - 1
                        );
                        formik.setFieldValue(
                          "adultPax",
                          formik.values.adultPax + 1
                        );
                      }}
                      disabled={formik.values.childrenPax === 0}
                    >
                      <Box
                        display={"flex"}
                        height={"100%"}
                        width={"40px"}
                        bgcolor={"black"}
                        borderRight={1}
                        borderColor={theme.palette.primary.main}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Remove />
                      </Box>
                    </ButtonBase>
                    <TextField
                      size={"small"}
                      type={"number"}
                      name={"childrenPax"}
                      value={formik.values.childrenPax}
                      onChange={formik.handleChange}
                      sx={{
                        width: "100%",
                        borderColor: theme.palette.primary.main,
                        backgroundColor: "#232323",
                        ".MuiInputBase-input": {
                          border: "none",
                        },
                      }}
                    />
                    <ButtonBase
                      onClick={() => {
                        formik.setFieldValue(
                          "childrenPax",
                          formik.values.childrenPax + 1
                        );
                        formik.setFieldValue(
                          "adultPax",
                          formik.values.adultPax - 1
                        );
                      }}
                      disabled={formik.values.adultPax <= 1}
                    >
                      <Box
                        display={"flex"}
                        height={"100%"}
                        width={"40px"}
                        bgcolor={"black"}
                        borderLeft={1}
                        borderColor={theme.palette.primary.main}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Add />
                      </Box>
                    </ButtonBase>
                  </Box>
                </Stack>
              </Stack>

              <Stack direction={"row"} width={"100%"} spacing={2}>
                <Stack
                  direction={"row"}
                  spacing={"118px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"50%"}
                >
                  <Typography width={"120px"} variant="h4">
                    Booking Source
                  </Typography>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    width={"100%"}
                    border={2}
                    borderColor={theme.palette.primary.main}
                    borderRadius={"5px"}
                  >
                    <Select
                      size={"small"}
                      value={formik.values.platform}
                      name={"platform"}
                      onChange={(event) => {
                        formik.setFieldValue("platform", event.target.value);
                        const selectedPlatform = allPlatforms.filter(
                          (platform) => platform.platform === event.target.value
                        );
                        formik.setFieldValue(
                          "platformId",
                          selectedPlatform[0].platformId
                        );
                        if (!event.target.value.includes("OTA -")) {
                          formik.setFieldValue("otaRefNo", null);
                        }
                      }}
                      sx={{
                        width: "100%",
                      }}
                    >
                      {allPlatforms.map((item) => (
                        <MenuItem key={item.platformId} value={item.platform}>
                          {item.platform}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={"118px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"50%"}
                >
                  <Typography width={"120px"} variant="h4">
                    OTA Ref No.
                  </Typography>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    width={"100%"}
                    border={2}
                    borderColor={theme.palette.primary.main}
                    borderRadius={"5px"}
                  >
                    <TextField
                      disabled={!formik.values.platform.includes("OTA -")}
                      size={"small"}
                      value={formik.values.otaRefNo}
                      onChange={formik.handleChange}
                      name={"otaRefNo"}
                      sx={{
                        width: "100%",
                        borderColor: theme.palette.primary.main,
                        color: "white",
                      }}
                      InputProps={{
                        endAdornment: (
                          <LockIcon color="primary" sx={{ ml: 1.5 }} />
                        ),
                      }}
                    />
                  </Box>
                </Stack>
              </Stack>
              <Typography variant="h2" sx={{ marginTop: "30px" }}>
                Rooms & Services
              </Typography>

              <Grid
                container
                alignItems={"center"}
                sx={{
                  padding: 4,
                  border: 3,
                  borderColor: theme.palette.primary.main,
                  marginTop: "25px",
                }}
              >
                <Box
                  display={"flex"}
                  width={"100%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    columns={{ xs: 2, sm: 2, md: 2, lg: 4, xl: 4 }}
                    columnSpacing={"100px"}
                    rowSpacing={"20px"}
                    width={"60vw"}
                  >
                    {roomTypes.map((roomType, index) => {
                      return (
                        <Grid
                          item
                          key={index}
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                        >
                          <RoomTypeCountComponent
                            roomType={roomType}
                            roomTypeReqAddCallback={setRoomTypeCountCallback}
                            setFieldValue={formik.setFieldValue}
                            adultPax={formik.values.adultPax}
                            childrenPax={formik.values.childrenPax}
                            handleAddMaxPax={handleAddMaxPax}
                            setAddedRoomTypes={setAddedRoomTypes}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
                <Grid
                  container
                  sx={{
                    width: "40%",
                    marginTop: "20px",
                  }}
                >
                  <Grid item md={5} sx={{ marginRight: "10px" }}>
                    <Grid
                      container
                      sx={{
                        border: 1,
                        borderColor: theme.palette.primary.main,
                      }}
                    >
                      <Grid
                        item
                        md={7}
                        sx={{
                          borderRight: 3,
                          borderColor: theme.palette.primary.main,
                          padding: "0.5em",
                        }}
                      >
                        <Typography variant={"h5"}>Locker</Typography>
                      </Grid>
                      <Grid
                        item
                        md={5}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          padding: "0.5em",
                        }}
                      >
                        <Typography variant={"h5"}>
                          {expressBookingReq.reduce(
                            (acc, room) => acc + room.count,
                            0
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={5}>
                    <Grid
                      container
                      sx={{
                        border: 1,
                        borderColor: theme.palette.primary.main,
                      }}
                    >
                      <Grid
                        item
                        md={7}
                        sx={{
                          borderRight: 3,
                          borderColor: theme.palette.primary.main,
                          padding: "0.5em",
                        }}
                      >
                        <Typography variant={"h5"}>Shower</Typography>
                      </Grid>
                      <Grid
                        item
                        md={5}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          padding: "0.5em",
                        }}
                      >
                        <Typography variant={"h5"}>
                          {formik.values.adultPax}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Stack>

          <Button
            variant="outlined"
            type={"submit"}
            sx={{
              width: "12%",
              height: "60px",
              backgroundColor: "#232323",
              border: 1,
              borderColor: theme.palette.primary.main,
              borderRadius: 0,
            }}
            disabled={loading}
          >
            {loading ? <CircularProgress /> : "Continue"}
          </Button>
        </Stack>
      </Form>
    );
  } else {
    return <></>;
  }
};

export default ExpressBookingForm;
