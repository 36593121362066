import { useMemo, useState } from "react";
import {
  DurationGroup,
  IRoomSum,
} from "../../pages/reports/EndShiftReportDetail";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  styled,
  CircularProgress,
} from "@mui/material";
import CustomTable from "../global/table/CustomTable";
import { formatToPresentableNumber } from "../../utils/functions";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  "&:nth-of-type(even)": {
    backgroundColor: "black",
  },
  ".MuiTableCell-root": {
    color: theme.palette.primary.main,
  },
}));

interface Data {
  [key: string]: DurationGroup[];
}

const RoomSummaryTable = (props: {
  roomData: IRoomSum;
  isLoading: boolean;
  fullWidth: boolean;
}) => {
  const theme = useTheme();

  const calculateSalesSum = (data: DurationGroup[]) => {
    return data.reduce((acc, item) => acc + item.sales, 0);
  };

  const calculateTotalSales = () => {
    let totalSales = 0;
    // Iterate over each entry in the data object
    for (const key in props.roomData.duration_groups) {
      if (props.roomData.duration_groups.hasOwnProperty(key)) {
        // Iterate over each item in the array
        props.roomData.duration_groups[key].forEach((item) => {
          // Add the sales value to the total sales
          totalSales += item.sales;
        });
      }
    }

    return totalSales;
  };

  function calculateTotalSalesByItem(data: Data): Record<string, number> {
    const totalSalesByItem: Record<string, number> = {};

    // Iterate over each entry in the data object
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        // Iterate over each item in the array
        data[key].forEach((item) => {
          // Check if the item_name already exists in the totalSalesByItem object
          if (!totalSalesByItem.hasOwnProperty(item.item_name)) {
            totalSalesByItem[item.item_name] = 0;
          }
          // Add the sales value to the corresponding item_name
          totalSalesByItem[item.item_name] += item.sales;
        });
      }
    }

    return totalSalesByItem;
  }

  function mapTotalSales(
    totalSalesByItem: Record<string, number>
  ): { item_name: string; total_sales: number }[] {
    return Object.entries(totalSalesByItem).map(([item_name, total_sales]) => ({
      item_name,
      total_sales,
    }));
  }

  const totalSales = calculateTotalSalesByItem(props.roomData.duration_groups);
  const mappedTotalSales = mapTotalSales(totalSales);

  return (
    <Box
      bgcolor={theme.palette.background.default}
      maxWidth={props.fullWidth ? "100vw" : "90vw"}
      border={2}
      borderColor={theme.palette.primary.main}
    >
      <Typography variant="h3" color="primary" padding={2}>
        Room Summary
      </Typography>
      {props.isLoading ? (
        <Box
          display={"flex"}
          bgcolor={"black"}
          justifyContent={"center"}
          alignItems={"center"}
          padding={3}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Table sx={{ width: "100%" }}>
          <TableHead sx={{ background: "black" }}>
            <TableRow
              sx={{
                ".MuiTableCell-root": {
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                },
              }}
            >
              <TableCell>Duration</TableCell>
              {props.roomData.columns
                .sort((a, b) => a.localeCompare(b))
                .map((column) => (
                  <TableCell>{column}</TableCell>
                ))}
              <TableCell>Sales</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(props.roomData.duration_groups).length > 0 ? (
              Object.entries(props.roomData.duration_groups).map(
                ([duration, data], index) => (
                  <>
                    <StyledTableRow
                      key={duration}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          width={"200px"}
                          height={"30px"}
                          bgcolor={"black"}
                          color={theme.palette.primary.main}
                          border={1}
                          borderRadius={1}
                          borderColor={theme.palette.primary.main}
                        >
                          {duration}
                        </Box>
                      </TableCell>
                      {data
                        .sort((a, b) => a.item_name.localeCompare(b.item_name))
                        .map((item) => (
                          <TableCell component="th" scope="row">
                            {item.sales
                              ? formatToPresentableNumber(item.sales.toString())
                              : formatToPresentableNumber("0")}
                          </TableCell>
                        ))}
                      <TableCell component="th" scope="row">
                        {props.roomData
                          ? formatToPresentableNumber(
                              calculateSalesSum(
                                props.roomData.duration_groups[duration]
                              ).toString()
                            )
                          : formatToPresentableNumber("0")}
                      </TableCell>
                    </StyledTableRow>
                    {index + 1 ===
                      Object.entries(props.roomData.duration_groups).length && (
                      <>
                        <StyledTableRow>
                          <TableCell>Total Sales</TableCell>
                          {mappedTotalSales.map((item, index) => (
                            <TableCell key={index}>
                              <Typography
                                color={theme.palette.primary.main}
                                sx={{ fontWeight: "bold" }}
                              >
                                {item.total_sales.toLocaleString?.("en-MY", {
                                  style: "currency",
                                  currency: "MYR",
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            </TableCell>
                          ))}
                          <TableCell>
                            <Typography
                              color={theme.palette.primary.main}
                              sx={{ fontWeight: "bold" }}
                            >
                              {calculateTotalSales().toLocaleString?.("en-MY", {
                                style: "currency",
                                currency: "MYR",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          </TableCell>
                        </StyledTableRow>
                      </>
                    )}
                  </>
                )
              )
            ) : (
              <TableRow>
                <TableCell colSpan={props.roomData.columns.length + 2}>
                  <Box
                    display={"flex"}
                    width={"100%"}
                    height={"100px"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography fontStyle={"italic"} color={"primary"}>
                      No records to display
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default RoomSummaryTable;
