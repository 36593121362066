import {
  ArrowDownward,
  ArrowRightAlt,
  ArrowUpward,
  FilterAltOutlined,
  MeetingRoomOutlined,
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Grid,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import axiosInstance from "../../constants/axiosConfig";
import {
  RoomDataInterface,
  RoomTypes,
  ZoneInterface,
  ZoneInterfaceV2,
} from "../../models/rooms/roomsInterface";
import { IBookingDraft } from "../../models/bookings/Booking";
import BookingInfoRooms from "../ganttchart/BookingInfoRooms";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import { useNavigate } from "react-router-dom";
import { bookingStatusEnum } from "../../constants/enums";

interface GuestTransferModalProp {
  open: boolean;
  onClose: () => void;
  roomBookings: IBookingDraft;
  roomTypeId: string;
  roomBookingId: string;
  bookingStatus: string;
  bookingLot?: number;
}

const GuestTransferModal: React.FC<GuestTransferModalProp> = (props) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const navigate = useNavigate();

  const [stepper, setStepper] = useState<number>(1);
  const [roomTypes, setRoomTypes] = useState<RoomTypes[]>([]);
  const [distinctRoomTypes, setDistinctRoomTypes] = useState<RoomTypes[]>([]);
  const [selectedRoomTypes, setSelectedRoomTypes] = useState<string>(
    props.roomTypeId
  );
  const [roomOption, setRoomOption] = useState<RoomDataInterface[]>([]);
  const [indexRoom, setIndexRoom] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isHighestTier, setIsHighestTier] = useState<boolean>(false);

  const [roomZone, setRoomZone] = useState<ZoneInterface[]>([]);

  const [selectedRoomZone, setSelectedRoomZone] = useState<string>("");
  const [selectedRoomCode, setSelectedRoomCode] = useState<string>("");
  const [roomZoneName, setRoomZoneName] = useState<string>("");
  const getUniqueRoomTypes = () => {
    axiosInstance
      .get(
        `/rooms/type/get-all-type/?noDistinct=true&getRoomLot=${props.bookingLot}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setRoomTypes(res.data.data);
          return;
        }
      })
      .catch((e) => {
        console.log(e);
        Notification.failed("something wrong, please try again");
      });
  };
  useEffect(() => {
    getUniqueRoomTypes();
  }, []);

  useEffect(() => {
    if (roomTypes.length === 0) return;
    setIndexRoom(0);

    if (roomTypes.filter((e) => e.typeId === selectedRoomTypes).length > 0) {
      const roomTypeName = roomTypes.filter(
        (e) => e.typeId === selectedRoomTypes
      )[0].typeName;

      axiosInstance
        .get(
          `/rooms/zone/get-all-zone/?specificTypeName=${roomTypeName}&getRoomLot=${props.bookingLot}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res && res.data && res.data.data) {
            setRoomZone(res.data.data);

            const selectedRoomTypeZone = res.data.data.find(
              (zone: { zoneId: string }) => zone.zoneId === selectedRoomZone
            );

            if (selectedRoomTypeZone) {
              setSelectedRoomZone(selectedRoomTypeZone.zoneId);
            } else {
              setSelectedRoomZone(res.data.data[0]?.zoneId);
            }
          }
        })
        .catch((error) => {
          Notification.failed("Unable to fetch room zone");
        });
    }
  }, [roomTypes, selectedRoomTypes]);

  useEffect(() => {
    const getSelectedRoomAvailable = () => {
      setIsLoading(true);
      if (roomTypes.length === 0) return;
      setIndexRoom(0);

      if (roomTypes.filter((e) => e.typeId === selectedRoomTypes).length > 0) {
        const roomTypeName = roomTypes.filter(
          (e) => e.typeId === selectedRoomTypes
        )[0].typeName;

        // if (roomZone.filter((e) => e.zoneId === selectedRoomZone).length > 0) {
        // 	const selectedRoomZoneName = roomZone.filter(
        // 		(e) => e.zoneId === selectedRoomZone
        // 	)[0].zoneName;

        const startDate = props.roomBookings.check_in;
        const endDate = props.roomBookings.check_out;
        axiosInstance
          .get(
            `/rooms/v1/rooms/generate-available?roomTypeName=${roomTypeName}&startDate=${startDate}&endDate=${endDate}&zoneId=${selectedRoomZone}&bookingLot=${props.bookingLot}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              const processedData = res.data
                .filter(
                  (room: any) => room.roomCode !== props.roomBookings.room_code
                )
                .map((room: any) => ({
                  ...room,
                  roomNo: room.roomCode,
                  roomType: room.roomTypeName,
                  roomZone: room.zone,
                }));

              processedData.sort(
                (a: { roomNo: string }, b: { roomNo: string }) =>
                  a.roomNo.localeCompare(b.roomNo)
              );

              setRoomOption(processedData);
            }
          })
          .catch((e) => {
            console.log(e);
            Notification.failed("something wrong, please try again");
          })
          .finally(() => setIsLoading(false));
        // }
      }
    };

    getSelectedRoomAvailable();
  }, [selectedRoomTypes, selectedRoomZone]);

  useEffect(() => {
    if (stepper === 1 && roomTypes) {
      getUniqueRoomTypes();
    }
    if (stepper === 2 && roomTypes) {
      const currentRoomType = roomTypes.filter(
        (roomType) => roomType.typeId === props.roomTypeId
      );

      if (currentRoomType.length > 0) {
        const roomUpgrades = roomTypes
          .filter((roomType) => roomType.ranking < currentRoomType[0].ranking)
          .sort((current, compared) => current.ranking + compared.ranking);

        setRoomTypes([...currentRoomType, ...roomUpgrades]);

        if (roomUpgrades.length > 0) {
          setSelectedRoomTypes(currentRoomType[0].typeId);
        } else {
          setIsHighestTier(true);
        }
      }
    }
  }, [stepper]);

  useEffect(() => {
    if (roomTypes.length === 0) return;

    // Filter out duplicates based on typeName
    const distinctRoomTypes: RoomTypes[] = roomTypes.reduce(
      (accumulator: RoomTypes[], current: RoomTypes) => {
        const existingType = accumulator.find(
          (type) => type.typeName === current.typeName
        );
        if (!existingType) {
          accumulator.push(current);
        }
        return accumulator;
      },
      []
    );

    setDistinctRoomTypes(distinctRoomTypes);
  }, [roomTypes, selectedRoomZone]);

  const changeRoomTypeFilter = (typeId: string) => {
    setSelectedRoomTypes(typeId);
  };

  const handleChangeRoom = () => {
    // if (props.bookingStatus === bookingStatusEnum.booked) {
    //   Notification.failed(
    //     "Payment needs to be done before Swapping / Upgrading Room"
    //   );
    //   return;
    // }

    const newBookingId = roomOption[indexRoom].roomId;
    const newRoomCode = roomOption[indexRoom].roomNo;

    if (props.roomBookings.room_code !== newRoomCode) {
      const apiUrl =
        stepper === 1
          ? "/bookings/v1/bookingroom/room-transfer/"
          : "/bookings/v1/bookingroom/upgrade-room/";

      const formData = {
        room_booking_id: props.roomBookingId,
        new_room_id: newBookingId,
      };

      axiosInstance
        .post(apiUrl, formData, config)
        .then((response) => {
          props.onClose();
          navigate(0);
          Notification.success(response.data.message);
        })
        .catch((res) => Notification.failed(res.response.data.message));
    } else {
      Notification.failed(
        "This room is not available. Please select another room."
      );
    }
  };

  const onlyLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleSelectRoomZone = (value: string) => {
    const roomZoneName = roomZone.filter((e) => e.zoneId === value)[0].zoneName;
    setSelectedRoomZone(value);
    setRoomZoneName(roomZoneName);
  };

  const handleSelectRoomCode = (value: string) => {
    setSelectedRoomCode(value);
    const roomIndex = roomOption.findIndex((room) => room.roomId === value);

    setIndexRoom(roomIndex);
  };
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box
        width={"70%"}
        border={2}
        borderColor={theme.palette.primary.main}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box
          display={"flex"}
          width={"100%"}
          height={"40px"}
          paddingX={2}
          borderBottom={2}
          borderColor={theme.palette.primary.main}
          alignItems={"center"}
        >
          <Typography variant="h3" color={"primary"}>
            Room Actions
          </Typography>
        </Box>
        <Box
          display={"flex"}
          height={"70px"}
          borderBottom={2}
          borderColor={theme.palette.primary.main}
        >
          <Stack width={"100%"} direction={"row"}>
            <ButtonBase
              disabled={isLoading}
              onClick={() => setStepper(1)}
              sx={{
                width: "50%",
                justifyContent: "start",
                paddingX: 3,
                borderRight: 2,
                borderColor: theme.palette.primary.main,
                backgroundColor:
                  stepper === 1 ? theme.palette.primary.main : "transparent",
              }}
            >
              <Typography
                variant="h4"
                color={stepper === 1 ? "black" : "white"}
              >
                Swap
              </Typography>
            </ButtonBase>
            <ButtonBase
              disabled={isLoading}
              onClick={() => setStepper(2)}
              sx={{
                width: "50%",
                justifyContent: "start",
                paddingX: 3,
                borderRight: 2,
                borderColor: theme.palette.primary.main,
                backgroundColor:
                  stepper === 2 ? theme.palette.primary.main : "transparent",
              }}
            >
              <Typography
                variant="h4"
                color={stepper === 2 ? "black" : "white"}
              >
                Upgrade
              </Typography>
            </ButtonBase>
          </Stack>
        </Box>
        <Stack
          direction={"row"}
          height={"160px"}
          sx={{
            backgroundColor: "black",
            borderBottom: 2,
            borderColor: theme.palette.primary.main,
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"180px"}
            borderRight={2}
            borderColor={theme.palette.primary.main}
          >
            <FilterAltOutlined />
            <Typography variant="h4">Filter</Typography>
          </Box>
          <Stack
            direction={"row"}
            width={"100%"}
            height={"100%"}
            spacing={"10px"}
          >
            <Stack direction={"column"} width={"100%"} height={"100%"}>
              <Box
                display={"flex"}
                justifyContent={"start"}
                alignItems={"center"}
                height={"50%"}
                borderBottom={2}
                borderColor={theme.palette.primary.main}
                paddingX={1}
              >
                <Typography variant="h4">Room Type</Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"start"}
                alignItems={"center"}
                height={"100%"}
                borderColor={theme.palette.primary.main}
              >
                <Select
                  value={selectedRoomTypes}
                  onChange={(event) => changeRoomTypeFilter(event.target.value)}
                  size={"small"}
                  sx={{
                    width: "100%",
                    backgroundColor: "black",
                  }}
                >
                  {distinctRoomTypes.map((roomType, index) => (
                    <MenuItem key={index} value={roomType.typeId}>
                      {roomType.typeName}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Stack>
            <Stack direction={"column"} width={"100%"} height={"100%"}>
              <Box
                display={"flex"}
                justifyContent={"start"}
                alignItems={"center"}
                height={"50%"}
                borderBottom={2}
                borderColor={theme.palette.primary.main}
                paddingX={1}
              >
                <Typography variant="h4">Room Zone</Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"start"}
                alignItems={"center"}
                height={"100%"}
                borderColor={theme.palette.primary.main}
              >
                <Select
                  value={selectedRoomZone}
                  onChange={(event) => handleSelectRoomZone(event.target.value)}
                  size={"small"}
                  sx={{
                    width: "100%",
                    backgroundColor: "black",
                  }}
                >
                  {roomZone.map((roomZone, index) => (
                    <MenuItem key={index} value={roomZone.zoneId}>
                      {roomZone.zoneName}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Stack>
            <Stack direction={"column"} width={"100%"} height={"100%"}>
              <Box
                display={"flex"}
                justifyContent={"start"}
                alignItems={"center"}
                height={"50%"}
                borderBottom={2}
                borderColor={theme.palette.primary.main}
                paddingX={1}
              >
                <Typography variant="h4">Room Code</Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"start"}
                alignItems={"center"}
                height={"100%"}
                borderColor={theme.palette.primary.main}
              >
                <Select
                  value={selectedRoomCode}
                  onChange={(event) => handleSelectRoomCode(event.target.value)}
                  size={"small"}
                  sx={{
                    width: "100%",
                    backgroundColor: "black",
                  }}
                >
                  {roomOption.map((room, index) => (
                    <MenuItem key={index} value={room.roomId}>
                      {room.roomNo}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Box
          display={"flex"}
          bgcolor={"black"}
          padding={4}
          borderBottom={2}
          borderColor={theme.palette.primary.main}
        >
          {isHighestTier && stepper === 2 ? (
            <Box display={"flex"} height={"150px"} alignItems={"center"}>
              <MeetingRoomOutlined
                color="primary"
                sx={{ fontSize: "50px", marginRight: "20px" }}
              />
              <Typography variant="h3">
                Current Room Type is The Highest Tier
              </Typography>
            </Box>
          ) : (
            <Grid
              container
              direction={"row"}
              width={"100%"}
              columns={{ md: 9, lg: 9, xl: 9 }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item xs={12} sm={12} md={9} lg={4} xl={4}>
                {indexRoom > 0 && (
                  //To Give Space
                  <Box height={"30px"} />
                )}
                <BookingInfoRooms
                  roomBookings={props.roomBookings}
                  customerStayingName={props.roomBookings.person_in_charge_name}
                />
                {roomOption.length - indexRoom !== 1 && (
                  //To Give Space
                  <Box height={"30px"} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={1} xl={1}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  marginY={3}
                >
                  {onlyLargeScreen ? (
                    <ArrowDownward color="primary" sx={{ fontSize: "36px" }} />
                  ) : (
                    <ArrowRightAlt color="primary" sx={{ fontSize: "36px" }} />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={4} xl={4}>
                {isLoading ? (
                  <Box
                    display={"flex"}
                    height={"100%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <CircularProgress />
                  </Box>
                ) : roomOption.length > 0 ? (
                  <Stack>
                    {indexRoom > 0 && (
                      <Button
                        variant="outlined"
                        onClick={() => setIndexRoom((index) => index - 1)}
                      >
                        <ArrowUpward
                          color="secondary"
                          sx={{ fontSize: "30px" }}
                        />
                      </Button>
                    )}
                    <BookingInfoRooms
                      roomBookings={{
                        ...props.roomBookings,
                        room_code: roomOption[indexRoom].roomNo,
                        room_id: roomOption[indexRoom].roomId,
                        room_type_details:
                          roomOption[indexRoom].roomTypeDetails!,
                        room_type_name: roomOption[indexRoom].roomType,
                        color_code: roomOption[indexRoom].colorCode!,
                        room_zone: roomOption[indexRoom].roomZone!,
                      }}
                      customerStayingName={
                        props.roomBookings.person_in_charge_name
                      }
                      roomZone={roomZoneName}
                    />
                    {roomOption.length - indexRoom !== 1 && (
                      <Button
                        variant="outlined"
                        onClick={() => setIndexRoom((index) => index + 1)}
                      >
                        <ArrowDownward
                          color="secondary"
                          sx={{ fontSize: "30px" }}
                        />
                      </Button>
                    )}
                  </Stack>
                ) : (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    height={"100%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography variant="h3" color={"grey"}>
                      No Available Room
                    </Typography>
                    <Typography variant="h4" color={"grey"}>
                      Try Different Searching Criteria.
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          )}
        </Box>
        <Box
          display={"flex"}
          width={"100%"}
          height={"80px"}
          paddingX={2}
          justifyContent={"end"}
          alignItems={"center"}
        >
          <Button
            onClick={() => handleChangeRoom()}
            variant="outlined"
            disabled={isHighestTier && stepper === 2}
            sx={{ color: "white" }}
          >
            Change to this room
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default GuestTransferModal;
