import {
  Box,
  ButtonBase,
  CircularProgress,
  Grid,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ManagementModalHeader from "../management/ManagementModalHeader";
import { useEffect, useState } from "react";
import axiosInstance from "../../constants/axiosConfig";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import Notification from "../../utils/notificationConfig";
import { BorderColor, DoNotDisturb, PointOfSale } from "@mui/icons-material";
import axios, { AxiosInstance, CancelToken, CancelTokenSource } from "axios";
import { IPaymentTerminal } from "../../models/payment/PaymentInterface";
import { useNavigate } from "react-router-dom";

const PaymentTerminalModal = (props: {
  open: boolean;
  handleClose: VoidFunction;
  openManual: VoidFunction;
  transactionId: string;
}) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const navigate = useNavigate();

  const [paymentTerminals, setPaymentTerminals] = useState<IPaymentTerminal[]>(
    []
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(
    null
  );

  const [hasRunSubmit, setHasRunSubmit] = useState<boolean>(false);

  useEffect(() => {
    if (!token || token === "") return;
    const fetchPaymentTerminals = () => {
      const apiUrl = "/payment-terminal";

      axiosInstance
        .get(apiUrl, config)
        .then((response) => {
          setPaymentTerminals(response.data.data);
        })
        .catch(() => Notification.failed("Something wrong with API"));
    };
    fetchPaymentTerminals();
  }, []);

  async function axiosPolling<T>(
    axiosInstance: AxiosInstance,
    url: string,
    retries: number,
    timeoutBetweenRetries: number,
    cancelToken: CancelToken
  ): Promise<T> {
    try {
      const response = await axiosInstance.get<T>(url, {
        cancelToken,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 204) {
        throw new Error("On Process");
      }
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        setIsLoading(false);
        handleCancelPaymentTerminal();
        throw new Error("Request cancelled by user");
      }
      if (retries > 0) {
        await new Promise((resolve) =>
          setTimeout(resolve, timeoutBetweenRetries)
        );
        console.log(`Retrying... ${retries} retries left`);
        return axiosPolling<T>(
          axiosInstance,
          url,
          retries - 1,
          timeoutBetweenRetries,
          cancelToken
        );
      } else {
        throw new Error("Max retries exceeded");
      }
    }
  }

  async function checkTransaction() {
    try {
      setIsLoading(true);

      const source = axios.CancelToken.source();
      setCancelToken(source);

      const data = await axiosPolling<{
        status: string;
        message: string;
        data: {
          status: string;
        };
      }>(
        axiosInstance,
        `/payment-terminal/get-result/${props.transactionId}`,
        150, // Number of retries
        2000, // Timeout between retries in milliseconds
        source.token
      );

      setIsLoading(false);
      if (data.data.status === "Failed") {
        Notification.failed("payment failed");
        return;
      }

      if (data.data.status === "Success") {
        navigate("/pos");
        Notification.success("payment success");
        return;
      }
    } catch (error: any) {
      Notification.failed(error.message);
    }
  }

  function handleCancel() {
    if (cancelToken) {
      cancelToken.cancel("Operation cancelled by user");
    }
  }

  const handleSubmit = (paymentSerialNumber: string) => {
    const apiUrl = "/payment-terminal/create-command/";

    const defaultCashier = localStorage.getItem("defaultCashier");
    const formData = {
      cashierNumber: defaultCashier,
      transactionId: props.transactionId,
      paymentTerminalSerialNumber: paymentSerialNumber,
    };

    axiosInstance
      .post(apiUrl, formData, config)
      .then((response) => {
        setHasRunSubmit(true);
        checkTransaction();
      })
      .catch((response) => {
        Notification.failed(response.response.data.message);
      });
  };

  const handleCancelPaymentTerminal = () => {
    const apiUrl = `/payment-terminal/stop-command/${props.transactionId}/`;

    axiosInstance
      .patch(apiUrl, {}, config)
      .then((res) => {
        Notification.success(res.data.message);
      })
      .catch((res) => Notification.failed(res.response.data.msg));
  };

  return (
    <Modal open={props.open}>
      <Box
        width={"700px"}
        maxHeight={"600px"}
        border={`3px solid ${theme.palette.primary.main}`}
        alignItems={"center"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
          borderRadius: 1,
        }}
      >
        <ManagementModalHeader
          title={"Pay with Payment Terminal"}
          handleModalClose={() => {
            isLoading ? handleCancel() : props.handleClose();
          }}
        />
        {!isLoading ? (
          <>
            <Box
              display={"flex"}
              width={"100%"}
              paddingX={3}
              paddingY={8}
              alignItems={"center"}
            >
              <PointOfSale
                color="primary"
                sx={{ fontSize: "36px", marginRight: 2 }}
              />
              <Typography variant="h3">
                Please select a Payment Terminal
              </Typography>
            </Box>
            <Grid
              container
              width={"100%"}
              height={"50%"}
              bgcolor={theme.palette.background.default}
            >
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <ButtonBase
                  onClick={() => {
                    const defaultPaymentTerminal =
                      localStorage.getItem("defaultTerminal");
                    if (defaultPaymentTerminal) {
                      handleSubmit(defaultPaymentTerminal);
                    } else {
                      Notification.warning("No Default Payment Terminal Set");
                    }
                  }}
                  sx={{ width: "100%", height: "80px" }}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={"100%"}
                    height={"100%"}
                    border={1}
                    borderColor={theme.palette.primary.main}
                  >
                    <Stack
                      direction={"row"}
                      width={"80%"}
                      alignItems={"center"}
                      spacing={2}
                    >
                      <PointOfSale />
                      <Typography variant="h4">Default</Typography>
                    </Stack>
                  </Box>
                </ButtonBase>
              </Grid>
              {paymentTerminals.map((paymentTerminal, index) => (
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} key={index}>
                  <ButtonBase
                    onClick={() => {
                      handleSubmit(paymentTerminal.serialNumber);
                    }}
                    sx={{ width: "100%", height: "80px" }}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      width={"100%"}
                      height={"100%"}
                      border={1}
                      borderColor={theme.palette.primary.main}
                    >
                      <Stack
                        direction={"row"}
                        width={"80%"}
                        alignItems={"center"}
                        spacing={2}
                      >
                        <PointOfSale />
                        <Typography variant="h4">
                          {paymentTerminal.serialNumber}
                        </Typography>
                      </Stack>
                    </Box>
                  </ButtonBase>
                </Grid>
              ))}
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <ButtonBase
                  onClick={() => {
                    props.openManual();
                  }}
                  sx={{ width: "100%", height: "80px" }}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={"100%"}
                    height={"100%"}
                    border={1}
                    borderColor={theme.palette.primary.main}
                  >
                    <Stack
                      direction={"row"}
                      width={"80%"}
                      alignItems={"center"}
                      spacing={2}
                    >
                      <BorderColor />
                      <Typography variant="h4">Manual</Typography>
                    </Stack>
                  </Box>
                </ButtonBase>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Box
              display={"flex"}
              width={"100%"}
              paddingX={3}
              paddingY={8}
              alignItems={"center"}
            >
              <CircularProgress />
              <Typography variant="h3" marginLeft={2}>
                Waiting for Transaction
              </Typography>
            </Box>
            <ButtonBase
              onClick={() => {
                handleCancel();
              }}
              sx={{ width: "100%" }}
            >
              <Box
                display={"flex"}
                height={"80px"}
                width={"100%"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                borderTop={1}
                borderColor={theme.palette.primary.main}
                bgcolor={theme.palette.background.default}
                paddingX={1}
              >
                <DoNotDisturb sx={{ fontSize: "50px" }} />
                <Typography variant="h4">Cancel Transaction</Typography>
              </Box>
            </ButtonBase>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default PaymentTerminalModal;
