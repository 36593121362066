import { ArrowBackIos, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  INewStaffShift,
  ShiftSettings,
} from "../../../models/accounts/ShiftInterface";
import Notification from "../../../utils/notificationConfig";
import axiosInstance from "../../../constants/axiosConfig";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  ArrowDropDownIcon,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { INewAccountData } from "../../../models/accounts/accountInterface";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import CustomTable from "../../global/table/CustomTable";

interface AddOrEditShiftProps {
  handleBack: VoidFunction;
  selectedShift: ShiftSettings;
  setShifts: React.Dispatch<React.SetStateAction<ShiftSettings[]>>;
}

const initialShiftData: ShiftSettings = {
  endHour: "",
  shiftName: "",
  shiftSettingsId: "",
  startHour: "",
  isArchived: false,
};

const AddOrEditShift = (props: AddOrEditShiftProps) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [selectedShift, setSelectedShift] =
    useState<ShiftSettings>(initialShiftData);

  const [searchFilter, setSearchFilter] = useState<string>("");

  const [accounts, setAccounts] = useState<INewAccountData[]>([]);
  const [staffShifts, setStaffShifts] = useState<INewStaffShift[]>([]);

  const [assignedStaff, setAssignedStaff] = useState<INewAccountData[]>([]);
  const [unassignedStaff, setUnassignedStaff] = useState<INewAccountData[]>([]);

  const [isFetchingAccount, setIsFetchingAccount] = useState<boolean>(false);

  const [isAssignStaff, setIsAssignStaff] = useState<boolean>(false);

  useEffect(() => {
    if (props.selectedShift.shiftSettingsId) {
      setSelectedShift(props.selectedShift);
    } else {
      setSelectedShift(initialShiftData);
    }
  }, [props.selectedShift]);

  const getStaffShifts = () => {
    axiosInstance
      .get(
        `/accounts/shift-settings/get/${selectedShift.shiftSettingsId}`,
        config
      )
      .then((res) => {
        if (res.status === 200) {
          setStaffShifts(
            res.data.data.staffshifts.filter(
              (data: any) => data.isArchived === false
            )
          );
          return;
        }
      })
      .catch((e) => {
        Notification.failed(e.response.data.msg);
      });
  };

  const getAccounts = () => {
    setIsFetchingAccount(true);
    axiosInstance
      .get("/accounts/get-all-accounts", config)
      .then((res) => {
        if (res.status === 200) {
          setAccounts(
            res.data.data.map((data: any) => ({
              ...data,
              roles: data.role.roleId,
            }))
          );
          return;
        }
      })
      .catch((e) => {
        console.log(e.response);
        Notification.failed(e.response.data.msg);
      })
      .finally(() => setIsFetchingAccount(false));
  };

  useEffect(() => {
    getAccounts();
  }, []);

  useEffect(() => {
    if (selectedShift.shiftSettingsId) getStaffShifts();
  }, [selectedShift]);

  useEffect(() => {
    if (accounts.length > 0 && staffShifts.length > 0) {
      const staffsAssignedInShifts = staffShifts.map(
        (staffShift) => staffShift.staff
      );
      setAssignedStaff(
        accounts.filter((account) =>
          staffsAssignedInShifts.includes(account.accountId)
        )
      );
      setUnassignedStaff(
        accounts.filter(
          (account) => !staffsAssignedInShifts.includes(account.accountId)
        )
      );
      return;
    }
    if (accounts.length > 0) {
      setUnassignedStaff(accounts);
    }
  }, [accounts, staffShifts]);

  const formik = useFormik({
    initialValues: selectedShift,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      if (values.startHour === "") {
        Notification.failed("Please Fill Up Start Hour");
        actions.setSubmitting(false);
        return;
      }
      if (values.endHour === "") {
        Notification.failed("Please Fill Up End Hour");
        actions.setSubmitting(false);
        return;
      }

      const apiUrl = selectedShift.shiftSettingsId
        ? "/accounts/shift-settings/edit/"
        : "/accounts/shift-settings/";

      if (selectedShift.shiftSettingsId) {
        const formData = {
          shiftsettings_id: values.shiftSettingsId,
          shiftName: values.shiftName.toUpperCase(),
          startHour: values.startHour,
          endHour: values.endHour,
        };
        axiosInstance
          .put(apiUrl, formData, config)
          .then((res) => {
            Notification.success(res.data.message);
            props.handleBack();
            props.setShifts((prevShifts) => {
              const removedShift = prevShifts.find(
                (shift) =>
                  shift.shiftSettingsId === props.selectedShift?.shiftSettingsId
              );

              if (removedShift) {
                const newShift = prevShifts.filter(
                  (shift) =>
                    shift.shiftSettingsId !==
                    props.selectedShift?.shiftSettingsId
                );

                return [
                  {
                    ...values,
                    shiftName: values.shiftName.toUpperCase(),
                  },
                  ...newShift,
                ];
              }
              return prevShifts;
            });
          })
          .catch((res) => {
            Notification.failed(res.response.data.msg);
          })
          .finally(() => actions.setSubmitting(false));
        return;
      }

      const formData = {
        shiftName: values.shiftName.toUpperCase(),
        startHour: values.startHour,
        endHour: values.endHour,
      };

      axiosInstance
        .post(apiUrl, formData, config)
        .then((res) => {
          Notification.success(res.data.message);
          props.handleBack();
          props.setShifts((prevShifts) => {
            const newShift: ShiftSettings = {
              ...values,
              shiftSettingsId: res.data.data.shiftsettingsId,
              shiftName: values.shiftName.toUpperCase(),
            };
            return [newShift, ...prevShifts];
          });
        })
        .catch((res) => {
          Notification.failed(res.response.data.msg);
        })
        .finally(() => actions.setSubmitting(false));
    },
  });

  const handleArchiveShift = () => {
    formik.setSubmitting(true);
    const apiUrl = `/accounts/shift-settings/archive/`;
    const formData = {
      shiftsettings_id: formik.values.shiftSettingsId,
      archive: !formik.values.isArchived,
    };
    axiosInstance
      .patch(apiUrl, formData, config)
      .then((res) => {
        Notification.success(res.data.message);
        props.handleBack();
        props.setShifts((prevShifts) => {
          const removedShift = prevShifts.find(
            (shift) =>
              shift.shiftSettingsId === props.selectedShift?.shiftSettingsId
          );

          if (removedShift) {
            const newShift = prevShifts.filter(
              (shift) =>
                shift.shiftSettingsId !== props.selectedShift?.shiftSettingsId
            );

            return [
              {
                ...formik.values,
                isArchived: !formik.values.isArchived,
              },
              ...newShift,
            ];
          }
          return prevShifts;
        });
      })
      .catch((res) => {
        Notification.failed(res.response.data.msg);
      })
      .finally(() => formik.setSubmitting(false));
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack direction={"column"} spacing={4}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Button variant="text" onClick={props.handleBack}>
                <ArrowBackIos fontSize="small" />
                BACK
              </Button>
              <Typography variant="h3">
                {selectedShift.shiftSettingsId !== ""
                  ? `Edit ${selectedShift.shiftName}`
                  : "New Shift"}
              </Typography>
            </Stack>
            {selectedShift.shiftSettingsId && formik.isSubmitting ? (
              <CircularProgress size={24} color="primary" />
            ) : selectedShift.shiftSettingsId ? (
              <Button variant="outlined" onClick={handleArchiveShift}>
                {formik.values.isArchived ? "Unarchive" : "Archive"}
              </Button>
            ) : null}
          </Stack>
          <Typography variant="h3">Shift Information</Typography>
          <Stack direction={"column"} spacing={2}>
            <Stack direction={"row"} alignItems={"center"} spacing={3}>
              <Typography variant="h4" width={"180px"}>
                Shift Name
              </Typography>
              <TextField
                fullWidth
                variant="standard"
                name="shiftName"
                value={formik.values.shiftName}
                onChange={formik.handleChange}
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={3}>
              <Typography variant="h4" width={"160px"}>
                Start Hour
              </Typography>
              <TimePicker
                value={new Date(`1970-01-01T${formik.values.startHour}`)}
                format={"HH:mm"}
                ampm={false}
                onChange={(e) => {
                  const formattedTime = format(e as Date, "HH:mm:ss");
                  formik.setFieldValue("startHour", formattedTime);
                }}
                slots={{
                  openPickerIcon: ArrowDropDownIcon,
                }}
                slotProps={{
                  textField: {
                    variant: "standard",
                    inputProps: {
                      style: {
                        borderRadius: 1,
                        border: 1,
                        height: "20px",
                      },
                    },
                    sx: {
                      width: "400px",
                      borderRadius: "5px",
                    },
                  },
                  openPickerButton: {
                    sx: {
                      color: "white",
                      marginTop: "1.8px",
                      marginRight: "3px",
                      zIndex: 1,
                    },
                  },
                  openPickerIcon: {
                    sx: {
                      width: "1em",
                      height: "1em",
                      zIndex: 1,
                    },
                  },
                }}
              />
              <Typography variant="h4" width={"180px"}>
                End Hour
              </Typography>
              <TimePicker
                value={new Date(`1970-01-01T${formik.values.endHour}`)}
                format={"HH:mm"}
                ampm={false}
                onChange={(e) => {
                  const formattedTime = format(e as Date, "HH:mm:ss");
                  formik.setFieldValue("endHour", formattedTime);
                }}
                slots={{
                  openPickerIcon: ArrowDropDownIcon,
                }}
                slotProps={{
                  textField: {
                    variant: "standard",
                    inputProps: {
                      style: {
                        borderRadius: 1,
                        border: 1,
                        height: "20px",
                      },
                    },
                    sx: {
                      width: "400px",
                      borderRadius: "5px",
                    },
                  },
                  openPickerButton: {
                    sx: {
                      color: "white",
                      marginTop: "1.8px",
                      marginRight: "3px",
                      zIndex: 1,
                    },
                  },
                  openPickerIcon: {
                    sx: {
                      width: "1em",
                      height: "1em",
                      zIndex: 1,
                    },
                  },
                }}
              />
            </Stack>
          </Stack>
          <Box display={"flex"} justifyContent={"end"}>
            <Button variant="outlined" type="submit" sx={{ width: "80px" }}>
              {formik.isSubmitting ? (
                <CircularProgress size={24} color="primary" />
              ) : (
                "Save"
              )}
            </Button>
          </Box>
          {selectedShift.shiftSettingsId && (
            <>
              <Typography variant="h3">Staff List</Typography>
              <Stack direction={"column"} spacing={1}>
                <TextField
                  fullWidth
                  variant="standard"
                  value={searchFilter}
                  onChange={(event) => setSearchFilter(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search sx={{ color: "white" }} />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    style: { textTransform: "none" },
                  }}
                />
              </Stack>
              {isAssignStaff ? (
                <StaffSection
                  key={"assigning"}
                  accounts={unassignedStaff}
                  isFetchingAccount={isFetchingAccount}
                  assignStaff={isAssignStaff}
                  setAssignStaff={setIsAssignStaff}
                  selectedShiftId={selectedShift.shiftSettingsId}
                  setUnassignedStaff={setUnassignedStaff}
                  setAssignedStaff={setAssignedStaff}
                  searchFilter={searchFilter}
                />
              ) : (
                <StaffSection
                  key={"unassigning"}
                  accounts={assignedStaff}
                  isFetchingAccount={isFetchingAccount}
                  assignStaff={isAssignStaff}
                  setAssignStaff={setIsAssignStaff}
                  selectedShiftId={selectedShift.shiftSettingsId}
                  setUnassignedStaff={setUnassignedStaff}
                  setAssignedStaff={setAssignedStaff}
                  searchFilter={searchFilter}
                />
              )}
            </>
          )}
        </Stack>
      </LocalizationProvider>
    </form>
  );
};

const StaffSection = (props: {
  accounts: INewAccountData[];
  isFetchingAccount: boolean;
  assignStaff: boolean;
  setAssignStaff: React.Dispatch<React.SetStateAction<boolean>>;
  selectedShiftId: string;
  setUnassignedStaff: React.Dispatch<React.SetStateAction<INewAccountData[]>>;
  setAssignedStaff: React.Dispatch<React.SetStateAction<INewAccountData[]>>;
  searchFilter: string;
}) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });

  const handleAssignStaff = (staff: INewAccountData) => {
    const formData = {
      accountId: staff.accountId,
      shiftsettingsId: props.selectedShiftId,
    };
    axiosInstance
      .post("/accounts/staff-shift/", formData, config)
      .then((res) => {
        if (res.status === 200) {
          Notification.success(res.data.msg);
          props.setAssignedStaff((prevValue) => [staff, ...prevValue]);
          props.setUnassignedStaff((prevValue) =>
            prevValue.filter((account) => account.accountId !== staff.accountId)
          );
          return;
        }
      })
      .catch((e) => {
        console.log(e.response);
        Notification.failed(e.response.data.msg);
      });
  };

  const handleUnAssignStaff = (staff: INewAccountData) => {
    const formData = {
      shiftsettingsId: props.selectedShiftId,
      accountId: staff.accountId,
    };
    axiosInstance
      .patch(`/accounts/staff-shift/archive/`, formData, config)
      .then((res) => {
        if (res.status === 200) {
          Notification.success(res.data.msg);
          props.setAssignedStaff((prevValue) =>
            prevValue.filter((account) => account.accountId !== staff.accountId)
          );
          props.setUnassignedStaff((prevValue) => [staff, ...prevValue]);
          return;
        }
      })
      .catch((e) => {
        console.log(e.response);
        Notification.failed(e.response.data.msg);
      });
  };

  const columns = useMemo<MRT_ColumnDef<INewAccountData>[]>(
    () => [
      {
        header: "Name",
        accessorKey: "name",
      },
      {
        header: "Username",
        accessorKey: "username",
      },
      {
        header: "Role",
        accessorKey: "role.roleName",
      },
      {
        header: "Action",
        size: 50,
        Cell: ({ row }) => (
          <Button
            variant="text"
            onClick={() => {
              if (props.assignStaff) {
                handleAssignStaff(row.original);
              } else {
                handleUnAssignStaff(row.original);
              }
            }}
          >
            {props.assignStaff ? "Assign" : "Remove"}
          </Button>
        ),
      },
    ],
    []
  );
  return (
    <Box border={1} borderColor={theme.palette.primary.main}>
      <Stack
        direction={"row"}
        width={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderBottom: 1,
          borderColor: theme.palette.primary.main,
        }}
      >
        <Typography variant="h3" marginLeft={1} color={"primary"}>
          Staffs
        </Typography>
        <Button
          variant={"contained"}
          onClick={() => props.setAssignStaff(!props.assignStaff)}
          sx={{
            width: "150px",
            color: theme.palette.primary.main,
            border: 1,
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {props.assignStaff ? "Remove Staff" : "Add Staff"}
        </Button>
      </Stack>
      <Box padding={2} bgcolor={"black"}>
        <CustomTable
          data={props.accounts}
          columns={columns}
          isLoading={props.isFetchingAccount}
          handlePaginationChange={setPagination}
          pagination={pagination}
          rowCount={props.accounts.length}
          oddEvenBackground={true}
          globalFilter={props.searchFilter}
        />
      </Box>
    </Box>
  );
};

export default AddOrEditShift;
