import { useEffect, useState } from 'react';
import useAuthentication from "../../hooks/useAuthentication";
import { Outlet } from "react-router-dom";

// components
import PageLoading from './loading/PageLoading';



export default function PortectedRoutes() {
    
    const { isLoggedIn, logout } = useAuthentication();

    const [loading, setLoading] = useState<boolean>(true);
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    
    useEffect(() => {
        const checkLoggedIn = async () => {
            if (await isLoggedIn() === false) {
                logout();
                setLoggedIn(false);
            } else {
                setLoggedIn(true);
            }
            setLoading(false);
        };
        checkLoggedIn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loading) {
        return <PageLoading />
    }

    return loggedIn && !loading ? (
        <Outlet />
    ) : <></>
}