import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ManagementModalHeader from "../management/ManagementModalHeader";
import { useState } from "react";
import { IPromotion } from "../../types/promotion";
import Notification from "../../utils/notificationConfig";

const AdjustmentModal = (props: {
  open: string;
  handleClose: VoidFunction;
  addPromotion: (adjustmentPromotion?: IPromotion) => void;
}) => {
  const theme = useTheme();

  const [adjustmentAmount, setAdjustmentAmount] = useState<string>("");

  const handleSubmit = () => {
    if (isNaN(parseFloat(adjustmentAmount))) {
      Notification.warning("Please enter a valid number");
      return;
    }

    if (
      props.open === "Rounding" &&
      (parseFloat(adjustmentAmount) < -1 || parseFloat(adjustmentAmount) > 1)
    ) {
      Notification.warning(
        "Roundings cannot be more than RM1 and less than -RM1"
      );
      return;
    }

    const adjustmentPromotion: IPromotion = {
      archived: false,
      details: adjustmentAmount,
      memberOnly: false,
      promoCode: props.open === "Rounding" ? "Rounding" : "Adjustment",
      promoEndDatetime: "",
      promoStartDatetime: "",
      promotionId: props.open === "Rounding" ? "Rounding" : "Adjustment",
      seats: 1000,
      stackable: true,
      visibleAtWebsite: false,
    };

    props.addPromotion(adjustmentPromotion);

    setAdjustmentAmount("");
    props.handleClose();
  };

  return (
    <Modal open={Boolean(props.open)} onClose={props.handleClose}>
      <Box
        width={"700px"}
        border={`3px solid ${theme.palette.primary.main}`}
        alignItems={"center"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
          borderRadius: 1,
        }}
      >
        <ManagementModalHeader
          title={`Input ${props.open} Amount`}
          handleModalClose={props.handleClose}
        />
        <Stack
          width={"100%"}
          direction={"column"}
          paddingX={3}
          paddingY={5}
          spacing={2}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant="h4" width={"30%"}>
              {props.open} Amount
            </Typography>
            <TextField
              variant="standard"
              value={adjustmentAmount}
              onChange={(event) => setAdjustmentAmount(event.target.value)}
              sx={{ width: "70%" }}
            />
          </Stack>
        </Stack>
        <Box
          display={"flex"}
          height={"50px"}
          bgcolor={theme.palette.background.paper}
          justifyContent={"end"}
          padding={1}
          borderTop={2}
          borderColor={theme.palette.primary.main}
        >
          <Button variant={"outlined"} onClick={handleSubmit}>
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AdjustmentModal;
