import {
  Box,
  Grid,
  Stack,
  Typography,
  ButtonBase,
  useTheme,
  LinearProgress,
  darken,
} from "@mui/material";
import {
  receivedRoomTypePerZone,
  AvailableDataInterface,
} from "../../models/rooms/roomsInterface";
import { createSearchParams, useNavigate } from "react-router-dom";

interface props {
  typeZone: receivedRoomTypePerZone[];
  zoneID: string;
  zoneName: string;
  rooms?: AvailableDataInterface[];
  occRooms?: AvailableDataInterface[];
  duration: number;
  bedType: string;
  selectedDate: Date;
  quietZone: string;
  bookingId?: string;
}

export default function CapsuleZoneButton({
  typeZone,
  zoneID,
  zoneName,
  rooms,
  occRooms,
  duration,
  bedType,
  selectedDate,
  quietZone,
  bookingId,
}: props) {
  const navigate = useNavigate();
  const theme = useTheme();

  function generateProgressBar(
    color: string,
    available: number,
    occupied: number
  ) {
    let barScale = Math.floor(
      Math.round((available / (available + occupied)) * 100)
    );
    return barScale;
  }

  let typeForThisZone: receivedRoomTypePerZone[] = [];
  let mdForTypeLength = 0;
  typeZone.forEach((item) => {
    if (item.zone_id === zoneID) {
      typeForThisZone.push(item);
    }
  });
  let typeLength = typeForThisZone.length;
  if (typeLength < 5) {
    mdForTypeLength = 11.2 / typeLength;
  } else {
    mdForTypeLength = 2.8;
  }

  const findAvailableRoom = (item: receivedRoomTypePerZone) => {
    const available = rooms?.find((room) => room.type_id === item.room_type_id);
    if (available) {
      return available.countedRooms;
    } else {
      return 0;
    }
  };

  const findOccupiedRoom = (item: receivedRoomTypePerZone) => {
    const occupied = occRooms?.find(
      (room) => room.type_id === item.room_type_id
    );
    if (occupied) {
      return occupied.countedRooms;
    } else {
      return 0;
    }
  };

  return (
    <>
      <Grid
        item
        sm={12}
        xs={12}
        md={3 * typeLength}
        sx={{
          border: 3,
          borderColor: theme.palette.primary.main,
          backgroundColor: "inherit",
        }}
      >
        <Box
          alignSelf={"center"}
          paddingX={3}
          paddingY={2}
          sx={{
            borderBottom: 3,
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Typography variant="h3" textAlign={"start"}>
            {zoneName}
          </Typography>
        </Box>
        <ButtonBase
          sx={{
            width: "100%",
            height: "100%",
            ":hover": {
              backgroundColor: theme.palette.secondary.main,
            },
          }}
          onClick={() => {
            if (!bookingId) {
              //This is for walk in Booking Process whereby Booking Id is not needed
              navigate({
                pathname: "/room-list/chart",
                search: createSearchParams({
                  zones: zoneName,
                  roomTypes: typeForThisZone.map((item) => item.type_name),
                  duration: duration.toString(),
                  bedType: bedType,
                  date: selectedDate.toString(),
                  quietZone: quietZone,
                }).toString(),
              });
            } else {
              //This is for adding rooms to existing booking
              navigate({
                pathname: "/room-list/chart",
                search: createSearchParams({
                  zones: zoneName,
                  roomTypes: typeForThisZone.map((item) => item.type_name),
                  duration: duration.toString(),
                  bedType: bedType,
                  date: selectedDate.toString(),
                  quietZone: quietZone,
                  bookingId: bookingId,
                }).toString(),
              });
            }
          }}
        >
          <Stack
            sx={{
              width: "inherit",
              height: "inherit",
              paddingX: 2,
              paddingY: 5,
            }}
          >
            <Grid
              container
              width={"100%"}
              justifyContent={"space-around"}
              spacing={typeForThisZone.length > 1 ? 3 : 0}
            >
              {typeForThisZone.map((item) => {
                const available_number = findAvailableRoom(item);
                const occupied_number = findOccupiedRoom(item);
                return (
                  <Grid item md={mdForTypeLength} key={item.room_type_id}>
                    <Typography
                      variant="h4"
                      textAlign={"start"}
                      sx={{ marginBottom: "20px" }}
                    >
                      {item.type_name}
                    </Typography>
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress
                        variant="determinate"
                        value={generateProgressBar(
                          item.colorTags,
                          available_number,
                          occupied_number
                        )}
                        valueBuffer={80}
                        color="primary"
                        sx={{
                          backgroundColor: darken(item.colorTags, 0.7),
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: item.colorTags,
                          },
                        }}
                      />
                    </Box>
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      sx={{ width: "100%" }}
                    >
                      <Grid item md={3} sx={{ marginRight: "20px" }}>
                        <Box
                          width="100%"
                          minWidth="30px"
                          height="34px"
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                          borderColor={item.colorTags}
                          sx={{
                            marginTop: "10px",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                        >
                          <Typography variant="h4" textAlign={"start"}>
                            {occupied_number}
                          </Typography>
                        </Box>
                        <Typography variant="h5" sx={{ textAlign: "center" }}>
                          Occupied
                        </Typography>
                      </Grid>
                      <Grid item md={3} sx={{ marginRight: "20px" }}>
                        <Box
                          width="100%"
                          minWidth="30px"
                          height="34px"
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                          borderColor={item.colorTags}
                          sx={{
                            marginTop: "10px",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                        >
                          <Typography variant="h4" textAlign={"start"}>
                            {item.num_dirty_rooms}
                          </Typography>
                        </Box>
                        <Typography variant="h5" sx={{ textAlign: "center" }}>
                          Needs Cleaning
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Box
                          width="100%"
                          minWidth="30px"
                          height="34px"
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            marginTop: "10px",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                        >
                          <Typography variant="h4">
                            {available_number}
                          </Typography>
                        </Box>
                        <Typography variant="h5" sx={{ textAlign: "center" }}>
                          Available
                        </Typography>
                      </Grid>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </ButtonBase>
      </Grid>
    </>
  );
}
