import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { camelToNormal } from "../../utils/functions";

const bookingStatuses = [
  "Check In",
  "Check Out",
  "Cancelled",
  "Booked",
  "Confirm Booking",
  "No Show",
  "Reservation",
  "Transfer from Airside",
  "Transfer from Landslide",
];

const ZReportFilters = (props: {
  handleOpenDropdown: ({
    currentTarget,
  }: React.MouseEvent<HTMLElement>) => void;
  handleCloseDropdown: VoidFunction;
  anchorEl: HTMLElement | null;
  reportType: string;
  handleChangeReportType: (reportType: "KLIA/KLIA2" | "Gatewaymall") => void;
  version: string;
  handleChangeVersion: (
    version: "summary" | "detailed" | "salesFile" | "paymentFile"
  ) => void;
  // handleGroupingsChange: (groupingsToAdd: string, isChecked: boolean) => void;
  // groupingsList: {
  //   allDetailed: boolean;
  //   transactionId: boolean;
  //   bookingId: boolean;
  //   allSummary: boolean;
  //   paymentType: boolean;
  //   bookingPlatform: boolean;
  //   duration: boolean;
  //   roomType: boolean;
  // };
  // groupings: string[];
  // setGroupingsList: React.Dispatch<
  //   React.SetStateAction<{
  //     allDetailed: boolean;
  //     transactionId: boolean;
  //     bookingId: boolean;
  //     allSummary: boolean;
  //     paymentType: boolean;
  //     bookingPlatform: boolean;
  //     duration: boolean;
  //     roomType: boolean;
  //   }>
  // >;
  // bookingStatusFilter: string[];
  // handleChangeBookingStatusFilter: (event: SelectChangeEvent<string[]>) => void;
}) => {
  return (
    <>
      <FormControl>
        <InputLabel variant="filled">Report Type</InputLabel>
        <Select
          variant="filled"
          value={"Report Type"}
          renderValue={(value) =>
            props.reportType ? camelToNormal(props.reportType) : value
          }
          SelectDisplayProps={{
            onClick: props.handleOpenDropdown,
          }}
          MenuProps={{
            keepMounted: true,
            sx: {
              ".MuiMenu-list": {
                color: "black",
              },
            },
          }}
          sx={{
            width: "150px",
            ".MuiSelect-select": {
              border: "1px solid #148978",
            },
            ".MuiSelect-icon": {
              fill: "white",
            },
          }}
        >
          <MenuItem
            value={"Report Type"}
            disabled
            sx={{
              display: "none",
            }}
          >
            Report Type
          </MenuItem>
          <MenuItem>
            <RadioGroup
              name={"report-type"}
              value={props.reportType}
              onChange={({ target: { value } }) =>
                props.handleChangeReportType(
                  value as "KLIA/KLIA2" | "Gatewaymall"
                )
              }
            >
              <FormControlLabel
                value="KLIA/KLIA2"
                control={<Radio />}
                label="KLIA/KLIA2"
              />
              <FormControlLabel
                value="Gatewaymall"
                control={<Radio />}
                label="Gatewaymall"
              />
            </RadioGroup>
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel variant="filled">Versions</InputLabel>
        <Select
          variant="filled"
          value={"Versions"}
          renderValue={(value) =>
            props.version ? camelToNormal(props.version) : value
          }
          SelectDisplayProps={{
            onClick: props.handleOpenDropdown,
          }}
          MenuProps={{
            keepMounted: true,
            sx: {
              ".MuiMenu-list": {
                color: "black",
              },
            },
          }}
          sx={{
            width: "150px",
            ".MuiSelect-select": {
              border: "1px solid #148978",
            },
            ".MuiSelect-icon": {
              fill: "white",
            },
          }}
        >
          <MenuItem
            value={"Versions"}
            disabled
            sx={{
              display: "none",
            }}
          >
            Versions
          </MenuItem>
          <MenuItem>
            <RadioGroup
              name={"versions"}
              value={props.version}
              onChange={({ target: { value } }) =>
                props.handleChangeVersion(
                  value as "summary" | "detailed" | "salesFile" | "paymentFile"
                )
              }
            >
              {props.reportType === "KLIA/KLIA2" ? (
                <>
                  <FormControlLabel
                    value="salesFile"
                    control={<Radio />}
                    label="Sales Interface File"
                  />
                  <FormControlLabel
                    value="paymentFile"
                    control={<Radio />}
                    label="Payment Integration File"
                  />
                </>
              ) : (
                <>
                  <FormControlLabel
                    value="detailed"
                    control={<Radio />}
                    label="Detailed"
                  />
                  <FormControlLabel
                    value="summary"
                    control={<Radio />}
                    label="Summary"
                  />
                </>
              )}
            </RadioGroup>
          </MenuItem>
        </Select>
      </FormControl>
      {/* <FormControl>
        <InputLabel variant="filled">Groupings</InputLabel>
        <Select
          variant="filled"
          id={"status-select-id"}
          value={props.groupings}
          multiple
          renderValue={() => {
            return props.groupings
              .map((grouping) => camelToNormal(grouping))
              .join(",");
          }}
          sx={{
            width: "200px",
            ".MuiSelect-select": {
              border: "1px solid #148978",
            },
            ".MuiSelect-icon": {
              fill: "white",
            },
          }}
        >
          {props.version === "detailed" ? (
            <Box>
              <MenuItem value={"transactionId"}>
                <FormControlLabel
                  label="Transaction ID"
                  control={
                    <Checkbox
                      checked={props.groupingsList.transactionId}
                      onChange={({ target: { checked } }) => {
                        props.setGroupingsList({
                          allDetailed: false,
                          transactionId: checked,
                          bookingId: false,
                          allSummary: false,
                          paymentType: false,
                          bookingPlatform: false,
                          duration: false,
                          roomType: false,
                        });
                        props.handleGroupingsChange("transactionId", checked);
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={"bookingId"}>
                <FormControlLabel
                  label="Booking ID"
                  control={
                    <Checkbox
                      checked={props.groupingsList.bookingId}
                      onChange={({ target: { checked } }) => {
                        props.setGroupingsList({
                          allDetailed: false,
                          bookingId: checked,
                          transactionId: false,
                          allSummary: false,
                          paymentType: false,
                          bookingPlatform: false,
                          duration: false,
                          roomType: false,
                        });
                        props.handleGroupingsChange("booking", checked);
                      }}
                    />
                  }
                />
              </MenuItem>
            </Box>
          ) : props.version === "summary" ? (
            <Box>
              <MenuItem value={"allSummary"}>
                <FormControlLabel
                  label="All Summary"
                  control={
                    <Checkbox
                      checked={props.groupingsList.allSummary}
                      onChange={({ target: { checked } }) => {
                        props.setGroupingsList({
                          allDetailed: false,
                          bookingId: checked,
                          transactionId: false,
                          allSummary: checked,
                          paymentType: checked,
                          bookingPlatform: checked,
                          duration: checked,
                          roomType: checked,
                        });
                        props.handleGroupingsChange("allSummary", checked);
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={"bookingId"}>
                <FormControlLabel
                  label="Booking ID"
                  control={
                    <Checkbox
                      checked={props.groupingsList.bookingId}
                      onChange={({ target: { checked } }) => {
                        props.setGroupingsList({
                          ...props.groupingsList,
                          allDetailed: false,
                          transactionId: false,
                          allSummary: checked && props.groupingsList.bookingId,
                          bookingId: checked,
                        });
                        props.handleGroupingsChange("booking", checked);
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={"paymentType"}>
                <FormControlLabel
                  label="Payment Type"
                  control={
                    <Checkbox
                      checked={props.groupingsList.paymentType}
                      onChange={({ target: { checked } }) => {
                        props.setGroupingsList({
                          ...props.groupingsList,
                          allDetailed: false,
                          transactionId: false,
                          allSummary:
                            checked && props.groupingsList.paymentType,
                          paymentType: checked,
                        });
                        props.handleGroupingsChange("payment-type", checked);
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={"bookingPlatform"}>
                <FormControlLabel
                  label="Booking Platform"
                  control={
                    <Checkbox
                      checked={props.groupingsList.bookingPlatform}
                      onChange={({ target: { checked } }) => {
                        props.setGroupingsList({
                          ...props.groupingsList,
                          allDetailed: false,
                          transactionId: false,
                          allSummary:
                            checked && props.groupingsList.bookingPlatform,
                          bookingPlatform: checked,
                        });
                        props.handleGroupingsChange(
                          "booking-platform",
                          checked
                        );
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={"duration"}>
                <FormControlLabel
                  label="Duration"
                  control={
                    <Checkbox
                      checked={props.groupingsList.duration}
                      onChange={({ target: { checked } }) => {
                        props.setGroupingsList({
                          ...props.groupingsList,
                          allDetailed: false,
                          transactionId: false,
                          allSummary: checked && props.groupingsList.duration,
                          duration: checked,
                        });
                        props.handleGroupingsChange(
                          "booking-duration",
                          checked
                        );
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={"roomType"}>
                <FormControlLabel
                  label="Room Type"
                  control={
                    <Checkbox
                      checked={props.groupingsList.roomType}
                      onChange={({ target: { checked } }) => {
                        props.setGroupingsList({
                          ...props.groupingsList,
                          allDetailed: false,
                          transactionId: false,
                          allSummary: checked && props.groupingsList.roomType,
                          roomType: checked,
                        });
                        props.handleGroupingsChange("room-type", checked);
                      }}
                    />
                  }
                />
              </MenuItem>
            </Box>
          ) : (
            <MenuItem value={"roomType"} disabled>
              Please Select a Version
            </MenuItem>
          )}
        </Select>
      </FormControl> */}
      {/* <FormControl>
        <InputLabel variant="filled">Booking Status</InputLabel>
        <Select
          variant="filled"
          id={"booking-status-select-id"}
          multiple
          value={props.bookingStatusFilter}
          onChange={props.handleChangeBookingStatusFilter}
          renderValue={(selected) => selected.join(",")}
          sx={{
            width: "150px",
            ".MuiSelect-select": {
              border: "1px solid #148978",
            },
            ".MuiSelect-icon": {
              fill: "white",
            },
          }}
        >
          {bookingStatuses.map((status, index) => (
            <MenuItem key={index} value={status}>
              <Checkbox
                checked={props.bookingStatusFilter.indexOf(status) > -1}
              />
              <ListItemText primary={status} />
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
    </>
  );
};

export default ZReportFilters;
