import { FileDownload, FilterAlt, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  Menu,
  MenuItem,
  MenuProps,
  Select,
  Stack,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import axiosInstance from "../../constants/axiosConfig";
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
} from "material-react-table";
import CustomTable from "../../components/global/table/CustomTable";
import {
  camelToNormal,
  formatDocumentDateFormat,
  formatToPresentableNumber,
} from "../../utils/functions";
import {
  ArrowDropDownIcon,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { CSVLink } from "react-csv";
import XLSX from "sheetjs-style";
import { ReactComponent as ExcelIcon } from "../../assets/images/icons8-excel.svg";
import { ReactComponent as PDFIcon } from "../../assets/images/pdf-svgrepo-com.svg";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import EndShiftReportDetail, {
  IDetailListing,
  IDetailed,
  IDetailedInitialValue,
} from "./EndShiftReportDetail";
import { format } from "date-fns";
import Notification from "../../utils/notificationConfig";
import { ILot } from "../../models/payment/PaymentInterface";

export interface IShiftOverview {
  left: {
    name: string;
    value: string;
  };
  right: {
    name: string;
    value: string;
  };
}

export interface IPerShift {
  staffshiftId: string;
  accountId: string;
  name: string;
  shiftName: string;
  startHour: string;
  endHour: string;
  totalItemPrice: number;
  totalServiceCharge: number;
  totalTaxValue: number;
}

interface IPerDay {
  date: string;
  shiftCount: number;
  totalItemPrice: number;
  totalServiceCharge: number;
  totalTaxValue: number;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const lotInitialValue: ILot = {
  airportCodeId: 0,
  lotDescription: "",
  lotId: 0,
  lotNumber: "",
};

const EndShiftReport = () => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);

  const currentDate = new Date();
  const currentMonthName = format(currentDate, "MMMM");

  const startOfDay = new Date(currentDate);
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date(currentDate);
  endOfDay.setHours(23, 59, 59, 999);

  const perShiftDataRef = useRef<any>(null);
  const perDayDataRef = useRef<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [perDayReport, setPerDayReports] = useState<IPerDay[]>([]);
  const [perShiftReport, setPerShiftReports] = useState<IPerShift[]>([]);

  const [detailed, setDetailed] = useState<IDetailed>(IDetailedInitialValue);

  const [isDetailLoading, setIsDetailLoading] = useState<boolean>(false);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleCloseDropdown = () => setAnchorEl(null);
  const handleOpenDropdown = ({
    currentTarget,
  }: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(currentTarget);
  };

  const [groupingsList, setGroupingsList] = useState<{
    IPerShiftStat: boolean;
    IPerDayStat: boolean;
  }>({
    IPerShiftStat: true,
    IPerDayStat: false,
  });
  const [groupings, setGroupings] = useState<string[]>(["perShift"]);
  //For Table

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [startDate, setStartDate] = useState<Date>(startOfDay);
  const [endDate, setEndDate] = useState<Date>(endOfDay);

  const [shiftOverview, setShiftOverview] = useState<IShiftOverview[]>([]);

  const [currentLot, setCurrentLot] = useState<ILot>(lotInitialValue);

  useEffect(() => {
    const keys = Object.keys(rowSelection);
    if (keys.length === 0) {
      if (perShiftReport.length > 0) {
        setSelectedIds(
          perShiftReport.map((shifts: IPerShift) => shifts.staffshiftId)
        );
      } else {
        setSelectedIds(perDayReport.map((dates: IPerDay) => dates.date));
      }
      return;
    }

    let ids: string[] = [];

    keys.forEach((key) => {
      const index = parseInt(key);
      if (perShiftReport.length > 0) {
        ids.push(perShiftReport[index].staffshiftId);
      } else {
        ids.push(perDayReport[index].date);
      }
    });

    setSelectedIds(ids);
  }, [rowSelection]);

  const fetchShiftData = () => {
    setIsLoading(true);
    const apiUrl = groupings.includes("perShift")
      ? `/queryservice/v1/sales/sales-summary-by-shift-settings`
      : `/queryservice/v1/sales/sales-summary-by-day`;
    axiosInstance
      .get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (groupings.includes("perShift")) {
            const data = res.data;
            setPerShiftReports(
              data.data.map((data: any) => ({
                ...data,
                startHour: format(new Date(data.startHour), "dd-MM-yyyy HH:mm"),
                endHour: data.endHour
                  ? format(new Date(data.endHour), "dd-MM-yyyy HH:mm")
                  : "-",
                totalItemPrice: parseFloat(data.totalItemPrice),
                totalServiceCharge: parseFloat(data.totalServiceCharge),
                totalTaxValue: parseFloat(data.totalTaxValue),
                staffshiftId: data.shiftId,
              }))
            );
            setShiftOverview(data.overview);
            setPerDayReports([]);

            // setTransactionData(handleConvertTransaction(res));
            setSelectedIds(data.data.map((shifts: any) => shifts.shiftId));
          } else if (groupings.includes("perDay")) {
            const data = res.data;
            const convertedData: IPerDay[] = data.data.map((data: any) => ({
              date: data.date,
              shiftCount: data.shiftCount,
              totalItemPrice: parseFloat(data.totalItemPrice),
              totalServiceCharge: parseFloat(data.totalServiceCharge),
              totalTaxValue: parseFloat(data.totalTaxValue),
            }));
            setPerDayReports(convertedData);
            setShiftOverview(data.overview);
            setPerShiftReports([]);

            // setTransactionData(handleConvertTransaction(res));
            setSelectedIds(data.data.map((dates: any) => dates.date));
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  const fetchCurrentLot = () => {
    axiosInstance
      .get(`/lot/get-own-lot`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setCurrentLot(res.data);
          return;
        }
      })
      .catch((e) => {
        Notification.failed(e.response.data.msg);
      });
  };

  useEffect(() => {
    fetchShiftData();
    fetchCurrentLot();
  }, [startDate, endDate, groupings]);

  const navigate = useNavigate();

  const PerShiftColumns = useMemo<MRT_ColumnDef<IPerShift>[]>(
    () => [
      {
        header: "Shift",
        Cell: ({ row }) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"200px"}
            height={"30px"}
            bgcolor={"black"}
            color={theme.palette.primary.main}
            border={1}
            borderRadius={1}
            borderColor={theme.palette.primary.main}
          >
            <Button
              fullWidth
              onClick={() =>
                navigate(
                  `shift=${row.original.staffshiftId}/${
                    row.original.name + " " + row.original.startHour
                  }`
                )
              }
            >
              {row.original.shiftName}
            </Button>
          </Box>
        ),
      },
      {
        header: "Shift Staff",
        accessorKey: "name",
      },
      {
        header: "Shift Start",
        Cell: ({ row }) => (
          <Typography>
            {row.original.startHour ? row.original.startHour : "-"}
          </Typography>
        ),
      },
      {
        header: "Shift End",
        Cell: ({ row }) => (
          <Typography>
            {row.original.endHour ? row.original.endHour : "-"}
          </Typography>
        ),
      },
      {
        header: "Total Item Price",
        Cell: ({ row }) => (
          <Typography>
            {row.original.totalItemPrice
              ? formatToPresentableNumber(
                  row.original.totalItemPrice.toString()
                )
              : 0}
          </Typography>
        ),
      },
    ],
    []
  );
  const PerDayColumns = useMemo<MRT_ColumnDef<IPerDay>[]>(
    () => [
      {
        header: "Shift Date",
        Cell: ({ row }) => {
          return (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              width={"200px"}
              height={"30px"}
              bgcolor={"black"}
              color={theme.palette.primary.main}
              border={1}
              borderRadius={1}
              borderColor={theme.palette.primary.main}
            >
              <Button
                fullWidth
                onClick={() =>
                  navigate(`date=${row.original.date}/${row.original.date}`)
                }
              >
                {row.original.date}
              </Button>
            </Box>
          );
        },
      },
      {
        header: "Total Shift",
        Cell: ({ row }) => <Typography>{row.original.shiftCount}</Typography>,
      },
      {
        header: "Total Item Price",
        Cell: ({ row }) => (
          <Typography>
            {row.original.totalItemPrice
              ? formatToPresentableNumber(
                  row.original.totalItemPrice.toString()
                )
              : 0}
          </Typography>
        ),
      },
    ],
    []
  );

  const handleGroupingsChange = useCallback(
    (groupingsToAdd: string, isChecked: boolean): void => {
      if (groupingsToAdd === "perShift" && isChecked) {
        setGroupings(["perShift"]);
        setRowSelection({});
        return;
      }
      if (groupingsToAdd === "perDay" && isChecked) {
        setGroupings(["perDay"]);
        setRowSelection({});
        return;
      }
      if (!isChecked) {
        const dummyArr = groupings.slice(0);
        const index = groupings.indexOf(groupingsToAdd);
        index > -1 && dummyArr.splice(index, 1);
        setGroupings(dummyArr);
        return;
      }
      if (groupings.includes(groupingsToAdd)) return;
      if (isChecked) setGroupings([...groupings, groupingsToAdd]);
    },
    [groupings]
  );

  const handleStartDateChange = (startDate: Date) => {
    setStartDate(startDate);
  };

  const handleEndDateChange = (endDate: Date) => {
    endDate.setHours(23, 59, 59, 999);
    setEndDate(endDate);
  };

  const fileNaming = () => {
    if (startDate || endDate) {
      return `$${formatDocumentDateFormat(
        startDate
      )}_$${formatDocumentDateFormat(startDate)}_${groupings[0]}_shift_report`;
    } else {
      return `${groupings[0]}_shift_report`;
    }
  };

  const findMinMonth = () => {
    let minStartHour: any = null;
    if (perShiftReport.length > 0) {
      perShiftReport
        .filter((shift) => selectedIds.includes(shift.staffshiftId))
        .forEach((shift) => {
          // For min startHour
          if (
            minStartHour === null ||
            new Date(shift.startHour) < new Date(minStartHour)
          ) {
            minStartHour = shift.startHour;
          }
        });
    } else {
      perDayReport
        .filter((day) => selectedIds.includes(day.date))
        .forEach((day) => {
          // For min date
          if (minStartHour === null || day.date < minStartHour) {
            minStartHour = day.date;
          }
        });
    }

    return minStartHour;
  };

  const findMaxMonth = () => {
    let maxEndHour: any = null;
    if (perShiftReport.length > 0) {
      perShiftReport
        .filter((shift) => selectedIds.includes(shift.staffshiftId))
        .forEach((shift) => {
          // For max endHour
          if (
            shift.endHour !== null &&
            (maxEndHour === null ||
              new Date(shift.endHour) > new Date(maxEndHour))
          ) {
            maxEndHour = shift.endHour;
          }
        });
    } else {
      perDayReport
        .filter((day) => selectedIds.includes(day.date))
        .forEach((day) => {
          // For max date
          if (maxEndHour === null || day.date > maxEndHour) {
            maxEndHour = day.date;
          }
        });
    }

    return maxEndHour;
  };

  const handleXLSXDownload = () => {
    const dataToExport = groupings.includes("perShift")
      ? perShiftReport.filter((shift) =>
          selectedIds.includes(shift.staffshiftId)
        )
      : perDayReport.filter((day) => selectedIds.includes(day.date));

    const wb = XLSX.utils.book_new();

    const companyNameHeader = `Company Name: ${currentLot.lotDescription}`;
    const reportNameHeader = `Report Name: End Shift Report between ${findMinMonth()} - ${findMaxMonth()}`;
    const outletHeader = `Outlet: ${currentLot.lotNumber}`;
    const monthHeader = `Month: ${currentMonthName}`;
    const dateGenerated = `Date Generated: ${format(
      new Date(),
      "dd MMMM yyyy hh:mm"
    )}`;

    let Heading = [
      [companyNameHeader],
      [reportNameHeader],
      [outletHeader],
      [monthHeader],
      [dateGenerated],
    ];

    const shiftsData = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(shiftsData, Heading);
    XLSX.utils.sheet_add_json(shiftsData, dataToExport, {
      origin: "A8",
    });

    const borderStyle = {
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };

    shiftsData["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 12 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 12 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: 12 } },
      { s: { r: 3, c: 0 }, e: { r: 3, c: 12 } },
      { s: { r: 4, c: 0 }, e: { r: 4, c: 12 } },
    ];

    Object.keys(shiftsData).forEach((cell) => {
      if (cell !== "!ref") {
        shiftsData[cell].s = borderStyle;
      }
    });

    shiftsData["A1"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    shiftsData["A2"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    shiftsData["A3"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    shiftsData["A4"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    shiftsData["A5"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };

    const roomData = XLSX.utils.aoa_to_sheet([
      ["Duration", "Item Name", "Sales", "Count"],
    ]);

    Object.entries(detailed.room.duration_groups).forEach(
      ([duration, data]) => {
        data.forEach((row) => {
          XLSX.utils.sheet_add_aoa(
            roomData,
            [[duration, row.item_name, row.sales, row.count]],
            { origin: -1 }
          );
        });
      }
    );
    Object.keys(roomData).forEach((cell) => {
      if (cell !== "!ref") {
        roomData[cell].s = borderStyle;
      }
    });

    const lockerData = XLSX.utils.json_to_sheet(detailed.locker);
    Object.keys(lockerData).forEach((cell) => {
      if (cell !== "!ref") {
        lockerData[cell].s = borderStyle;
      }
    });

    const merchData = XLSX.utils.json_to_sheet(detailed.merch);
    Object.keys(merchData).forEach((cell) => {
      if (cell !== "!ref") {
        merchData[cell].s = borderStyle;
      }
    });

    const showerData = XLSX.utils.json_to_sheet(detailed.shower);
    Object.keys(showerData).forEach((cell) => {
      if (cell !== "!ref") {
        showerData[cell].s = borderStyle;
      }
    });

    const collectionData = XLSX.utils.json_to_sheet(detailed.collection);
    Object.keys(collectionData).forEach((cell) => {
      if (cell !== "!ref") {
        collectionData[cell].s = borderStyle;
      }
    });

    const transactionData = XLSX.utils.json_to_sheet(detailed.transaction);
    Object.keys(transactionData).forEach((cell) => {
      if (cell !== "!ref") {
        transactionData[cell].s = borderStyle;
      }
    });

    XLSX.utils.book_append_sheet(wb, shiftsData, "Shifts");
    XLSX.utils.book_append_sheet(wb, roomData, "Rooms");
    XLSX.utils.book_append_sheet(wb, lockerData, "Lockers");
    XLSX.utils.book_append_sheet(wb, showerData, "Showers");
    XLSX.utils.book_append_sheet(wb, merchData, "Merchs");
    XLSX.utils.book_append_sheet(wb, collectionData, "Collections");
    XLSX.utils.book_append_sheet(wb, transactionData, "Transactions");
    XLSX.writeFile(wb, `${fileNaming()}.xlsx`);
  };

  const handleDownloadPDF = () => {
    let ref = perDayDataRef.current;

    if (perShiftReport.length > 0) {
      ref = perShiftDataRef.current;
    }
    html2canvas(ref)
      .then((canvas) => {
        const contentWidth = canvas.width * 0.8;
        const contentHeight = canvas.height * 0.8;
        const pdf = new jsPDF(contentWidth > contentHeight ? "l" : "p", "mm", [
          contentWidth,
          contentHeight,
        ]);
        pdf.addImage(
          canvas.toDataURL("image/png"),
          "PNG",
          0,
          0,
          contentWidth,
          contentHeight
        ); // Adjust the size as needed

        pdf.save(`${fileNaming()}.pdf`);
      })
      .catch((error) => {
        console.error("Error converting to canvas:", error);
      });
  };

  //File Download Menu Stuffs

  const [fileDownloadAnchorEl, setFileDownloadAnchorEl] =
    useState<null | HTMLElement>(null);
  const openFileDownloadMenu = Boolean(fileDownloadAnchorEl);
  const handleFileDownloadClick = (event: React.MouseEvent<HTMLElement>) => {
    setFileDownloadAnchorEl(event.currentTarget);
  };
  const handleFileDownloadClose = () => {
    setFileDownloadAnchorEl(null);
  };

  return (
    <>
      <Box
        display={"flex"}
        bgcolor={theme.palette.background.default}
        maxWidth={"90vw"}
        border={2}
        borderColor={theme.palette.primary.main}
      >
        <Stack direction={"row"} width={"100%"}>
          <Stack
            direction={"column"}
            width={"18%"}
            borderRight={2}
            borderColor={theme.palette.primary.main}
          >
            <Box
              display={"flex"}
              bgcolor={theme.palette.background.default}
              borderBottom={2}
              borderColor={theme.palette.primary.main}
              height={"100px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography variant="h2">Overview</Typography>
            </Box>

            <Stack height={"100%"} spacing={1}>
              {shiftOverview.map((overview, i) => (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  padding={1}
                  key={i}
                >
                  <Box>
                    <Typography fontWeight={600}>
                      {overview.left.name} | {overview.right.name}
                    </Typography>
                    <Box
                      width={"100%"}
                      height={"30px"}
                      border={"2px solid white"}
                      display={"flex"}
                      marginTop={1}
                    >
                      <Box
                        width={"50%"}
                        display={"flex"}
                        alignItems={"center"}
                        sx={{
                          backgroundColor: "white",
                        }}
                      >
                        <Typography
                          color={"black"}
                          width={"100%"}
                          display={"flex"}
                          justifyContent={"center"}
                          fontWeight={600}
                        >
                          {overview.left.value}
                        </Typography>
                      </Box>
                      <Box
                        width={"50%"}
                        display={"flex"}
                        alignItems={"center"}
                        sx={{
                          backgroundColor: "black",
                        }}
                      >
                        <Typography
                          color={"white"}
                          width={"100%"}
                          display={"flex"}
                          justifyContent={"center"}
                          fontWeight={600}
                        >
                          {overview.right.value}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Stack>
          </Stack>
          <Stack direction={"column"} width={"82%"}>
            <Box
              display={"flex"}
              width={"100%"}
              height={"100px"}
              justifyContent={"space-around"}
              alignItems={"center"}
              bgcolor={theme.palette.background.default}
              borderBottom={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack direction={"row"} alignItems={"center"}>
                <FilterAlt sx={{ fontSize: "32px" }} />
                <Typography variant="h3">Filter</Typography>
              </Stack>
              <EndShiftFilter
                anchorEl={anchorEl}
                groupings={groupings}
                groupingsList={groupingsList}
                setGroupingsList={setGroupingsList}
                handleCloseDropdown={handleCloseDropdown}
                handleGroupingsChange={handleGroupingsChange}
                handleOpenDropdown={handleOpenDropdown}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(e) => handleStartDateChange(e as Date)}
                    format="dd MMM yyyy"
                    slots={{
                      openPickerIcon: ArrowDropDownIcon,
                    }}
                    slotProps={{
                      layout: {
                        sx: {
                          ".MuiDateCalendar-root": {
                            color: theme.palette.primary.main,
                            borderRadius: 2,
                            borderWidth: 1,
                            borderColor: theme.palette.primary.main,
                            border: "1px solid",
                            backgroundColor: theme.palette.background.paper,
                          },
                          ".MuiButtonBase-root": {
                            color: theme.palette.primary.main,
                          },
                          ".MuiTypography-root": {
                            color: theme.palette.primary.main,
                          },
                          ".MuiPickersCalendarHeader-root": {
                            color: theme.palette.primary.main,
                          },
                          ".MuiPickersMonth-monthButton": {
                            color: theme.palette.primary.main,
                          },
                          ".MuiPickersCalendarHeader-label": {
                            color: theme.palette.primary.main,
                          },
                          ".Mui-selected": {
                            color: "black",
                          },
                          ".MuiPickersYear-yearButton": {
                            color: theme.palette.primary.main,
                          },
                        },
                      },
                      textField: {
                        variant: "filled",
                        inputProps: {
                          style: {
                            color: "white",
                            borderRadius: 1,
                            border: 2,
                          },
                        },
                        sx: {
                          width: "150px",
                          backgroundColor: "#232323",
                          border: "1px solid #148978",
                          borderRadius: 1,
                        },
                      },
                      openPickerButton: {
                        sx: {
                          color: "white",
                          marginTop: "1.8px",
                          zIndex: 1,
                        },
                      },
                      openPickerIcon: {
                        sx: {
                          width: "1em",
                          height: "1em",
                          zIndex: 1,
                        },
                      },
                    }}
                  />
                  <Typography variant="h3" marginX={1}>
                    -
                  </Typography>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(e) => handleEndDateChange(e as Date)}
                    format="dd MMM yyyy"
                    slots={{
                      openPickerIcon: ArrowDropDownIcon,
                    }}
                    slotProps={{
                      layout: {
                        sx: {
                          ".MuiDateCalendar-root": {
                            color: theme.palette.primary.main,
                            borderRadius: 2,
                            borderWidth: 1,
                            borderColor: theme.palette.primary.main,
                            border: "1px solid",
                            backgroundColor: theme.palette.background.paper,
                          },
                          ".MuiButtonBase-root": {
                            color: theme.palette.primary.main,
                          },
                          ".MuiTypography-root": {
                            color: theme.palette.primary.main,
                          },
                          ".MuiPickersCalendarHeader-root": {
                            color: theme.palette.primary.main,
                          },
                          ".MuiPickersMonth-monthButton": {
                            color: theme.palette.primary.main,
                          },
                          ".MuiPickersCalendarHeader-label": {
                            color: theme.palette.primary.main,
                          },
                          ".Mui-selected": {
                            color: "black",
                          },
                          ".MuiPickersYear-yearButton": {
                            color: theme.palette.primary.main,
                          },
                        },
                      },
                      textField: {
                        variant: "filled",
                        inputProps: {
                          style: {
                            color: "white",
                            borderRadius: 1,
                            border: 2,
                          },
                        },
                        sx: {
                          width: "150px",
                          backgroundColor: "#232323",
                          border: "1px solid #148978",
                          borderRadius: 1,
                        },
                      },
                      openPickerButton: {
                        sx: {
                          color: "white",
                          marginTop: "1.8px",
                          zIndex: 1,
                        },
                      },
                      openPickerIcon: {
                        sx: {
                          width: "1em",
                          height: "1em",
                          zIndex: 1,
                        },
                      },
                    }}
                  />
                </Box>
              </LocalizationProvider>
              <Button
                variant="contained"
                onClick={fetchShiftData}
                sx={{ borderRadius: 1 }}
              >
                <Search />
              </Button>
              <Button
                id="file-download-button"
                aria-controls={
                  openFileDownloadMenu ? "file-download-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openFileDownloadMenu ? "true" : undefined}
                variant="contained"
                onClick={handleFileDownloadClick}
                sx={{ borderRadius: 1 }}
              >
                <FileDownload />
              </Button>
              <StyledMenu
                id="file-download-menu"
                MenuListProps={{
                  "aria-labelledby": "file-download-button",
                }}
                anchorEl={fileDownloadAnchorEl}
                open={openFileDownloadMenu}
                onClose={handleFileDownloadClose}
              >
                <MenuItem
                  onClick={handleDownloadPDF}
                  disableRipple
                  disabled={isDetailLoading || isLoading}
                >
                  <PDFIcon
                    width={"35px"}
                    height={"35px"}
                    style={{ marginRight: "10px" }}
                  />
                  PDF
                </MenuItem>
                <MenuItem
                  onClick={handleXLSXDownload}
                  disableRipple
                  disabled={isDetailLoading || isLoading}
                >
                  <ExcelIcon
                    width={"35px"}
                    height={"35px"}
                    style={{ marginRight: "10px" }}
                  />
                  Xlsx
                </MenuItem>
                <CSVLink
                  data={
                    groupings.includes("perShift")
                      ? perShiftReport
                      : perDayReport
                  }
                  filename={`${fileNaming()}.csv`}
                >
                  <MenuItem
                    onClick={handleFileDownloadClose}
                    disableRipple
                    disabled={isLoading}
                  >
                    <ExcelIcon
                      width={"35px"}
                      height={"35px"}
                      style={{ marginRight: "10px" }}
                    />
                    CSV Shifts
                  </MenuItem>
                </CSVLink>
                {/* <CSVLink
                  data={transactionData}
                  filename={`${fileNaming()}_detailed.csv`}
                >
                  <MenuItem
                    onClick={handleFileDownloadClose}
                    disableRipple
                    disabled={isDetailLoading || isLoading}
                  >
                    <ExcelIcon
                      width={"35px"}
                      height={"35px"}
                      style={{ marginRight: "10px" }}
                    />
                    CSV Detailed
                  </MenuItem>
                </CSVLink> */}
              </StyledMenu>
            </Box>
            {isLoading ? (
              <Box
                display={"flex"}
                height={"500px"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box height={"100%"} width={"100%"}>
                {perShiftReport.length > 0 ? (
                  <>
                    <CustomTable
                      key={"perShiftTable"}
                      isLoading={isLoading}
                      columns={PerShiftColumns}
                      data={perShiftReport}
                      handlePaginationChange={setPagination}
                      pagination={pagination}
                      enableRowSelection={true}
                      enableTopToolbar={false}
                      onRowSelect={setRowSelection}
                      rowSelection={rowSelection}
                      oddEvenBackground={true}
                    />
                    <div
                      ref={perShiftDataRef}
                      style={{
                        position: "absolute",
                        left: "-9999px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box width={"1000px"}>
                        <CustomTable
                          key={"perShiftPrint"}
                          isLoading={isLoading}
                          data={perShiftReport.filter((shift) =>
                            selectedIds.includes(shift.staffshiftId)
                          )}
                          columns={PerShiftColumns}
                          enableTopToolbar={false}
                          rowCount={perShiftReport.length}
                          pagination={{
                            pageIndex: 0,
                            pageSize: perShiftReport.length,
                          }}
                          oddEvenBackground={true}
                        />
                      </Box>
                      <EndShiftReportDetail
                        key={"perShiftDetail"}
                        shifts={selectedIds}
                        perShift={true}
                        setIsLoading={setIsDetailLoading}
                        setDetailed={setDetailed}
                        isNotDetailedScreen={true}
                      />
                    </div>
                  </>
                ) : perDayReport.length > 0 ? (
                  <>
                    <CustomTable
                      key={"perDayTable"}
                      isLoading={isLoading}
                      columns={PerDayColumns}
                      data={perDayReport}
                      handlePaginationChange={setPagination}
                      pagination={pagination}
                      enableRowSelection={true}
                      enableTopToolbar={false}
                      onRowSelect={setRowSelection}
                      rowSelection={rowSelection}
                      oddEvenBackground={true}
                    />
                    <div
                      ref={perDayDataRef}
                      style={{
                        position: "absolute",
                        left: "-9999px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {/* Your component content goes here */}
                      <Box width={"1000px"}>
                        <CustomTable
                          key={"perDayPrint"}
                          isLoading={isLoading}
                          data={perDayReport}
                          columns={PerDayColumns}
                          enableTopToolbar={false}
                          rowCount={perDayReport.length}
                          pagination={{
                            pageIndex: 0,
                            pageSize: perDayReport.length,
                          }}
                          oddEvenBackground={true}
                        />
                      </Box>
                      <EndShiftReportDetail
                        key={"perDayDetail"}
                        dates={selectedIds}
                        perShift={false}
                        setIsLoading={setIsDetailLoading}
                        setDetailed={setDetailed}
                        isNotDetailedScreen={true}
                      />
                    </div>
                  </>
                ) : (
                  //Another Custom Table is added for loading
                  <CustomTable
                    key={"perShiftTable"}
                    isLoading={isLoading}
                    columns={PerShiftColumns}
                    data={perShiftReport}
                    handlePaginationChange={setPagination}
                    pagination={pagination}
                    enableRowSelection={true}
                    enableTopToolbar={false}
                    onRowSelect={setRowSelection}
                    rowSelection={rowSelection}
                    oddEvenBackground={true}
                  />
                )}
              </Box>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default EndShiftReport;

const EndShiftFilter = (props: {
  handleOpenDropdown: ({
    currentTarget,
  }: React.MouseEvent<HTMLElement>) => void;
  handleCloseDropdown: VoidFunction;
  anchorEl: HTMLElement | null;
  handleGroupingsChange: (groupingsToAdd: string, isChecked: boolean) => void;
  groupingsList: {
    IPerShiftStat: boolean;
    IPerDayStat: boolean;
  };
  groupings: string[];
  setGroupingsList: React.Dispatch<
    React.SetStateAction<{
      IPerShiftStat: boolean;
      IPerDayStat: boolean;
    }>
  >;
}) => {
  return (
    <>
      <FormControl>
        <InputLabel variant="filled">Groupings</InputLabel>
        <Select
          variant="filled"
          id={"status-select-id"}
          value={props.groupings}
          multiple
          renderValue={() => {
            return props.groupings
              .map((grouping) => camelToNormal(grouping))
              .join(",");
          }}
          sx={{
            width: "200px",
            ".MuiSelect-select": {
              border: "1px solid #148978",
            },
            ".MuiSelect-icon": {
              fill: "white",
            },
          }}
        >
          <Box>
            <MenuItem value={"perShift"}>
              <FormControlLabel
                label="Per Shift"
                control={
                  <Checkbox
                    checked={props.groupingsList.IPerShiftStat}
                    onChange={({ target: { checked } }) => {
                      props.setGroupingsList({
                        IPerShiftStat: checked,
                        IPerDayStat: false,
                      });
                      props.handleGroupingsChange("perShift", checked);
                    }}
                  />
                }
              />
            </MenuItem>
            <MenuItem value={"perDay"}>
              <FormControlLabel
                label="Per Day"
                control={
                  <Checkbox
                    checked={props.groupingsList.IPerDayStat}
                    onChange={({ target: { checked } }) => {
                      props.setGroupingsList({
                        IPerShiftStat: false,
                        IPerDayStat: checked,
                      });
                      props.handleGroupingsChange("perDay", checked);
                    }}
                  />
                }
              />
            </MenuItem>
          </Box>
        </Select>
      </FormControl>
    </>
  );
};

const handleConvertTransaction = (res: any) => {
  const dataTransaction = res.data.details.map((data: any) => ({
    booking_id: data.bookingId,
    booking_date: data.bookingDate,
    transaction_date: data.transactionDate,
    staff: data.staff,
    payment_type: data.paymentType,
    pan: data.pan,
    payment_reference: data.payment_reference,
    payment_remarks: data.paymentRemarks,
    transaction_status: data.transactionStatus,
    booking_status: data.bookingStatus,
    check_in_date: data.checkInDate,
    check_out_date: data.checkOutDate,
    ota_ref_Code: data.otaRefCode,
    booking_platform: data.bookingPlatform,
    guest: data.guest,
    duration: data.duration,
    sales: data.sales,
    tax: data.tax,
    total: data.total,
    booking_remarks: data.bookingRemarks,
  }));

  return dataTransaction;
};

function formatNumber(number: string) {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
