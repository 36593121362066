import { Grid, Typography, Divider, IconButton } from "@mui/material";
import theme from "../../assets/theme/theme";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useState } from "react";
interface CheckInSlipProps {
	wifiName?: string;
	wifiPassword?: string;
	onCheckInSlipVisibilityChange: (isVisible: boolean) => void;
}
export default function CheckInSlip({
	wifiName,
	wifiPassword,
	onCheckInSlipVisibilityChange,
}: CheckInSlipProps) {
	const [showCheckInSlipDetail, setShowCheckInSlipDetail] = useState(false);

	const handleButtonClick = () => {
		setShowCheckInSlipDetail(true);

		// Pass the boolean value to the parent component
		onCheckInSlipVisibilityChange(true);
	};
	return (
		<>
			<Grid container padding={5} spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h3">Check-In Slip</Typography>
				</Grid>
				<Grid item xs={12}>
					<Divider
						orientation="horizontal"
						flexItem
						sx={{
							borderBottomWidth: 3.5,
							borderColor: "#232323",
						}}
					/>
				</Grid>
				<Grid item xs={3} px={2}>
					<Typography variant="h4" color={theme.palette.primary.main}>
						Wifi Name
					</Typography>
				</Grid>
				<Grid item xs={8} px={2}>
					<Typography variant="h4">
						{wifiName}
					</Typography>
				</Grid>
				<Grid item xs={1} px={2}>
					<IconButton onClick={handleButtonClick}>
						<SettingsOutlinedIcon color="primary" />
					</IconButton>
				</Grid>
				<Grid item xs={3} px={2}>
					<Typography variant="h4" color={theme.palette.primary.main}>
						Wifi Password
					</Typography>
				</Grid>
				<Grid item xs={9} px={2}>
					<Typography variant="h4">{wifiPassword}</Typography>
				</Grid>
			</Grid>
		</>
	);
}
