import { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Backdrop,
  Stack,
  Tab,
  Tabs,
  Grid,
  Typography,
  IconButton,
  Button,
  Divider,
  TextField,
  CircularProgress,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import theme from "../../assets/theme/theme";
import { store } from "../../redux/store";

import axiosInstance from "../../constants/axiosConfig";
import { OverstayChargesInterface } from "../../models/rooms/roomsInterface";
import Notification from "../../utils/notificationConfig";
import React from "react";
import AdditionalHours from "./AdditionalHours";

type Props = {
  openOverstaySettings: boolean;
  handleCloseOverstaySettings: () => void;
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  border: 2,
  borderColor: theme.palette.primary.main,
};

function OverstaySettings({
  openOverstaySettings,
  handleCloseOverstaySettings,
}: Props) {
  const token = store.getState().user.accessToken;
  const accountId = store.getState().user.accountId;
  const [tabStepperValue, setTabStepperValue] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [overstayData, setOverstayData] = useState<OverstayChargesInterface[]>(
    []
  );

  const handleStepperChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabStepperValue(newValue);
  };

  useEffect(() => {
    const fetchOverstaySettings = async () => {
      setLoading(true);
      await axiosInstance
        .get(`/lot-settings/get-settings/${accountId}?overstaySettings=true`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const dataWithLoading = res.data.data.map(
              (item: OverstayChargesInterface) => ({
                ...item,
                isLoading: false, // Set initial value to false
              })
            );
            setOverstayData(dataWithLoading);
          }
        });
      setLoading(false);
    };

    fetchOverstaySettings();
  }, [token, accountId]);

  const updateState = (data: OverstayChargesInterface) => (e: any) => {
    const newArray = overstayData.map((item, i) => {
      if (data.settingsId === item.settingsId) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setOverstayData(newArray);
  };

  // Function to update isLoading property for a specific item
  const updateLoadingState = (index: number, isLoading: boolean) => {
    setOverstayData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        isLoading,
      };
      return newData;
    });
  };

  const changeSettingRate = async (
    data: OverstayChargesInterface,
    index: number
  ) => {
    updateLoadingState(index, true);
    const payload = {
      settingsId: data.settingsId,
      newDescription: data.settingsDescription,
    };
    await axiosInstance
      .put(`/lot-settings/edit-settings-description/`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success(response.data.message);
      })
      .catch((error) => {
        Notification.failed(`Error updating setting rate: ${error}`);
      })
      .finally(() => {
        updateLoadingState(index, false);
      });
  };

  return (
    <>
      <Modal
        open={openOverstaySettings}
        onClose={handleCloseOverstaySettings}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          },
        }}
      >
        <Box sx={modalStyle} bgcolor={theme.palette.background.default}>
          <Grid container>
            <Grid item xs={11}>
              <Stack
                display={"flex"}
                sx={{
                  p: 2,
                  border: 0,
                  borderColor: "#232323",
                  height: "50px",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h2" color={theme.palette.primary.main}>
                  Overstay/Add Hour
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack
                display={"flex"}
                sx={{
                  p: 1,
                  border: 0,
                  borderColor: theme.palette.primary.main,
                  height: "50px",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  onClick={handleCloseOverstaySettings}
                  sx={{ padding: 0 }}
                >
                  <HighlightOffIcon
                    fontSize="large"
                    sx={{ color: "#ff4d56" }}
                  />
                </IconButton>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                sx={{
                  borderTop: 2,
                  borderColor: theme.palette.primary.main,
                }}
              ></Stack>
            </Grid>
            <Grid
              item
              md={2}
              sm={2}
              xs={2}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                minHeight: "100%",
              }}
            >
              <Stack
                direction={"column"}
                textAlign={"start"}
                padding={2}
                spacing={2}
                sx={{ backgroundColor: theme.palette.background.default }}
                height={"70vh"}
              >
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={tabStepperValue}
                  onChange={handleStepperChange}
                  TabIndicatorProps={{
                    style: { display: "none" },
                  }}
                  sx={{
                    marginBottom: 5,
                    "& .MuiTab-root": {
                      textTransform: "none",
                      fontWeight: 600,
                      minWidth: "8.1vw",
                      fontSize: "1rem",
                      alignContent: "start",
                      "&.Mui-selected": {
                        color: theme.palette.primary.main,
                      },
                      "&:not(.Mui-selected)": {
                        color: "white",
                        borderBottom: "1px solid #0a443c",
                      },
                    },
                  }}
                >
                  <Tab value={0} label={"Overstay Charges"} />
                  <Tab value={1} label={"Additional Hours"} />
                </Tabs>
              </Stack>
            </Grid>

            <Grid item md={10} sm={10} xs={10}>
              <Stack
                direction={"column"}
                height={"100%"}
                width={"100%"}
                sx={{ backgroundColor: "#141414", overflow: "auto" }}
                maxHeight={"70vh"}
              >
                <Grid container width={"100%"} sx={{ padding: 5 }}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Stack direction={"column"} height={"100%"}>
                      {tabStepperValue === 0 ? (
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant="h2">
                              Overstay Charges
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider
                              orientation="horizontal"
                              flexItem
                              sx={{
                                pt: 5,
                                mb: 5,
                                borderBottomWidth: 3.5,
                                borderColor: "#232323",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="h4"
                              color={theme.palette.primary.main}
                            >
                              Room Type
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="h4"
                              color={theme.palette.primary.main}
                            >
                              Overstay Charges (MYR)
                            </Typography>
                          </Grid>

                          {overstayData.map((item, i) => (
                            <React.Fragment key={i}>
                              <Grid item xs={6} p={2} key={item.settingsId}>
                                <Typography variant="h4">
                                  {item.settingsName}
                                </Typography>
                              </Grid>
                              <Grid item xs={4} p={2}>
                                <TextField
                                  label="Tiers"
                                  size="medium"
                                  name="settingsDescription"
                                  fullWidth
                                  value={item.settingsDescription}
                                  onChange={updateState(item)}
                                  type="number"
                                ></TextField>
                              </Grid>
                              <Grid item xs={2} p={3} borderRadius={"5px"}>
                                <Button
                                  variant="outlined"
                                  onClick={() => changeSettingRate(item, i)}
                                >
                                  {!item.isLoading ? (
                                    "Save"
                                  ) : (
                                    <CircularProgress
                                      size="24px"
                                      sx={{ color: "white" }}
                                    />
                                  )}
                                </Button>
                              </Grid>
                            </React.Fragment>
                          ))}
                          <Grid item xs={12}>
                            <Divider
                              orientation="horizontal"
                              flexItem
                              sx={{
                                pt: 1,
                                mb: 1,
                                borderBottomWidth: 3.5,
                                borderColor: "#232323",
                              }}
                            />
                          </Grid>
                        </Grid>
                      ) : tabStepperValue === 1 ? (
                        <AdditionalHours />
                      ) : null}
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
          <Box
            display={"flex"}
            justifyContent="flex-end"
            alignItems="center"
            width={"100%"}
            border={`1.5px solid ${theme.palette.primary.main}`}
          >
            <Button
              variant="outlined"
              size="large"
              onClick={handleCloseOverstaySettings}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default OverstaySettings;
