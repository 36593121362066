import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import theme from "../../assets/theme/theme";

type Props = {
  isLoadingModalOpen: boolean;
  handleCloseLoadingModal: () => void;
};

const LoadingModal = ({
  isLoadingModalOpen,
  handleCloseLoadingModal,
}: Props) => {
  return (
    <Modal
      open={isLoadingModalOpen}
      onClose={handleCloseLoadingModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box
        width={"400px"}
        minWidth={"38em"}
        border={`3px solid ${theme.palette.primary.main}`}
        margin={"auto"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
        }}
      >
        <Box
          height={"40px"}
          paddingY={1}
          paddingX={2}
          sx={{
            backgroundColor: theme.palette.background.default,
          }}
          borderBottom={`3px solid ${theme.palette.primary.main}`}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Typography variant={"h3"} color={theme.palette.primary.main}>
            Generating Invoice
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          padding={5}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default LoadingModal;
