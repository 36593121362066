import { ArrowBackIos } from "@mui/icons-material";
import {
	Stack,
	Button,
	Typography,
	CircularProgress,
	TextField,
	Box,
} from "@mui/material";
import { SetStateAction, useEffect, useState } from "react";
import axiosInstance from "../../constants/axiosConfig";
import { store } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import ICashier from "../../models/payment/PaymentInterface";

type props = {
	selectedCashierData?: ICashier;
	onClose: VoidFunction;
	setCashierTerminal:React.Dispatch<React.SetStateAction<ICashier[]>>
};
const AddorEditCashierModal = (props: props) => {
	const token = store.getState().user.accessToken;

	const [isArchive, setIsArchive] = useState<boolean>(
		props.selectedCashierData?.isArchive
			? props.selectedCashierData?.isArchive
			: false
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [updatedKcNumber, setUpdatedKcNumber] = useState(
		props.selectedCashierData?.kcNumber || ""
	);
	const [updatedCashierTerminal, setUpdatedCashierTerminal] = useState(
		props.selectedCashierData?.cashierTerminal || ""
	);
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	const handleArchive = () => {
		setIsArchive(!isArchive);
		const formData = {
			cashierTerminalId: props.selectedCashierData?.cashierTerminalId,
			archive: isArchive,
		};

		setIsLoading(true);
		axiosInstance
			.patch("/cashierterminal/archive/", formData, config)
			.then((res) => {
				Notification.success(res.data.message);
			})
			.catch((response) => {
				Notification.failed(response.message);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleCashierTerminalChange = (event: {
		target: { value: SetStateAction<string> };
	}) => {
		setUpdatedCashierTerminal(event.target.value);
	};

	// Handler function to update the value of kcNumber
	const handleKcNumberChange = (event: {
		target: { value: SetStateAction<string> };
	}) => {
		setUpdatedKcNumber(event.target.value);
	};

	const handleSubmit = () => {
		if (props.selectedCashierData) {
			const formData = {
				cashierTerminalId: props.selectedCashierData.cashierTerminalId,
				cashierTerminalNumber: updatedCashierTerminal,
				kcNumber: updatedKcNumber,
			};

			axiosInstance
				.put(`/cashierterminal/edit/`, formData, config)
				.then((res) => {
					Notification.success(res.data.message);
					props.onClose();
					props.setCashierTerminal((prevCashierTerminals) => {
					  const editedIndex = prevCashierTerminals.findIndex(
						(cashierTerminal) => cashierTerminal.cashierTerminalId === props.selectedCashierData?.cashierTerminalId
					  );
					  if (editedIndex !== -1) {
						const newTerminals = [...prevCashierTerminals];
						// Update the edited terminal
						newTerminals[editedIndex] = {
						  ...newTerminals[editedIndex],
						  cashierTerminal: updatedCashierTerminal,
						  kcNumber: updatedKcNumber,
						};
						return newTerminals;
					  }
					  return prevCashierTerminals;
					});
				  })
				.catch((res) => {

                    if (res.response.data.message) {
						Notification.failed(res.response.data.message);
					} else {
						Notification.failed(res.message);
					}
				});
		} else {
			const formData = {
				cashierTerminalNumber: updatedCashierTerminal,
				kcNumber: updatedKcNumber,
			};

            axiosInstance
				.post(`/cashierterminal/`, formData, config)
				.then((res) => {
					Notification.success(res.data.data.message);
					props.onClose();
				})
				.catch((res) => {
					if (res.response.data.message) {
						Notification.failed(res.response.data.message);
					} else {
						Notification.failed(res.message);
					}
				});
		}
	};
	return (
		<>
			<Stack direction={"column"} spacing={4} padding={5}>
				<Stack
					direction={"row"}
					justifyContent={"space-between"}
					alignItems={"center"}>
					<Stack direction={"row"} alignItems={"center"} spacing={2}>
						<Button variant="text" onClick={props.onClose}>
							<ArrowBackIos fontSize="small" />
							BACK
						</Button>
						<Typography variant="h3">
							{props.selectedCashierData
								? `Edit ${props.selectedCashierData?.cashierTerminal}`
								: "New Payment Terminal"}
						</Typography>
					</Stack>
					{props.selectedCashierData ? (
						<Button variant="outlined" onClick={handleArchive}>
							{isArchive ? "Unarchive" : "Archive"}
						</Button>
					) : null}
				</Stack>
				<Typography variant="h3">Cashier Information</Typography>
				<Stack direction={"column"} spacing={2}>
					<Stack direction={"row"} alignItems={"center"} spacing={3}>
						<Typography variant="h4" width={"180px"}>
							Cashier Terminal ID
						</Typography>
						<TextField
							fullWidth
							variant="standard"
							name="terminalId"
							value={updatedCashierTerminal}
							onChange={handleCashierTerminalChange}
						/>
					</Stack>
					<Stack direction={"row"} alignItems={"center"} spacing={3}>
						<Typography variant="h4" width={"180px"}>
							KC Number
						</Typography>
						<TextField
							fullWidth
							variant="standard"
							name="serialNumber"
							value={updatedKcNumber}
							onChange={handleKcNumberChange}
						/>
					</Stack>
				</Stack>
				<Box display={"flex"} justifyContent={"end"}>
					<Button
						variant="outlined"
						type="submit"
						sx={{ width: "80px" }}
						onClick={handleSubmit}>
						{isLoading ? (
							<CircularProgress size={24} color="primary" />
						) : (
							"Save"
						)}
					</Button>
				</Box>
			</Stack>
		</>
	);
};

export default AddorEditCashierModal;
