import {
  Box,
  useTheme,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  TablePagination,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { useState, useEffect } from "react";
import HouseKeepingFilterBar from "./HouseKeepingFilterBar";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { StyledTableCell, StyledTableRow } from "../../constants/tableStyle";
import TableLoading from "../global/loading/TableLoading";
import { RoomDataInterface } from "../../models/rooms/roomsInterface";
import axiosInstance from "../../constants/axiosConfig";
import { store } from "../../redux/store";
import BASE_API from "../../constants/api";
import {
  StaffScoreInterface,
  RoomTypeListInterface,
} from "../../models/supervisor/supervisorInterface";
import Notification from "../../utils/notificationConfig";

function HouseKeepingScoreboard() {
  const theme = useTheme();
  const token = store.getState().user.accessToken;

  const [filterExpanded, setFilterExpanded] = useState<boolean>(false);
  const [searchValueFilter, setSearchValueFilter] = useState<string>("");
  const [roomStatusFilter, setRoomStatusFilter] = useState<string[]>([]);
  const [zoneFilter, setZoneFilter] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<string>("");

  const [columns, setColumns] = useState<RoomTypeListInterface[]>([]);
  const [rows, setRows] = useState<StaffScoreInterface[]>([]);
  const [page, setPage] = useState(0);
  const [filteredRows, setFilteredRows] = useState<StaffScoreInterface[]>([]);
  const [roomStatus, setRoomStatus] = useState<string[]>([]);

  const rowsPerPage = 10;

  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [statusResponse, setStatusResponse] = useState<string>("");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    const fetchHousekeepingScoreboard = async () => {
      setFetchLoading(true);
      await axiosInstance
        .get(`accounts/housekeeping/scoreboard`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setRows(res.data);
            setFilteredRows(res.data.data.staffScoreList);
            setColumns(res.data.data.roomTypeList);
          }
        });
      setFetchLoading(false);
    };

    fetchHousekeepingScoreboard();
  }, [token]);

  return (
    <Box
      width={"100%"}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <TableContainer
        component={Paper}
        sx={{ display: "flex", flex: 1, flexDirection: "column" }}
      >
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Housekeeper</StyledTableCell>
              {columns.map((row, index) => (
                <StyledTableCell key={index}>
                  <Typography variant="h3" color={row.roomTypeColor}>
                    {row.roomTypeName}
                  </Typography>
                </StyledTableCell>
              ))}
              <StyledTableCell>Total</StyledTableCell>
            </StyledTableRow>
          </TableHead>

          {fetchLoading ? (
            <TableLoading />
          ) : (
            <>
              {/* {filteredRows?.data?.staffScoreList?.length > 0 && (
                <TableBody
                  sx={{ width: "80%", minHeight: "80%", overflowY: "auto" }}
                >
                  {filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      ></StyledTableRow>
                    ))}
                </TableBody>
              )} */}

              <TableBody
                sx={{ width: "80%", minHeight: "80%", overflowY: "auto" }}
              >
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <StyledTableRow>
                      <StyledTableCell>{row.staffName}</StyledTableCell>
                      {row.roomTypeScore.map((room) => (
                        <StyledTableCell>{room.score}</StyledTableCell>
                      ))}
                      <StyledTableCell>{row.total}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
              {filteredRows.length === 0 && (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>data not available</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              )}
            </>
          )}
        </Table>
        <TablePagination
          sx={{
            color: "white",
            backgroundColor: theme.palette.background.default,
          }}
          rowsPerPageOptions={[20]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          //onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}

export default HouseKeepingScoreboard;
