import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { alpha, styled } from "@mui/material/styles";

const ODD_OPACITY = 0.4;

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  borderRadius: 1,
  "& .MuiDataGrid-cell": {
    color: "white",
    border: 0,
  },
  "& .MuiDataGrid-footerContainer": {
    color: "#FFDF08",
  },
  "& .MuiDataGrid-columnHeader": {
    color: theme.palette.primary.main,
    borderBottom: 0,
    fontSize: "1rem",
    fontWeight: "700"
  },
  "& .MuiTablePagination-root": {
    color: theme.palette.primary.main,
    font: "bold",
  },
  "& .MuiTablePagination-root .MuiTablePagination-spacer": {
    color: theme.palette.primary.main,
  },
  "& .MuiTablePagination-root .MuiTablePagination-actions": {
    backgroundColor: "#232323",
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[800],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.dark,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

export default CustomDataGrid;
