
// Redux
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";



export default function useAuthorization() {

    const userPermissions = useSelector((state : RootState) => state.user.permissions);

    const checkIsAdmin = () : boolean => {
        if (userPermissions.includes('ADMINACCESS')) {
            return true;
        }
        return false;
    }

    const checkIsSuperadmin = () : boolean => {
        if (userPermissions.includes('SUPERADMIN')) {
            return true;
        }
        return false;
    }

    return {
        checkIsAdmin,
        checkIsSuperadmin,
    }
}