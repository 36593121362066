import { useCallback, useEffect, useState } from "react";
import { Box, Typography, Grid, Select } from "@mui/material";
import styled from "@emotion/styled";
import CapsuleZoneButton from "../../components/zonelist/CapsuleZoneButton";
import ZoneMapOverview from "../../components/zonelist/ZoneMapOverview";
import FilterBar from "../../components/ganttchart/FilterBar";

//import utils
import Notification from "../../utils/notificationConfig";

// import interface
import {
  AvailableDataInterface,
  ZoneInterface,
  receivedRoomTypePerZone,
  RoomTypes,
} from "../../models/rooms/roomsInterface";
import axiosInstance from "../../constants/axiosConfig";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  IFormattedRoomTypes,
  IFormattedRoomZones,
} from "../../models/filterbar/Filterbar";
import PageTitle from "../../components/global/PageTitle";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

export interface IFormattedRoomZone {
  roomZoneId: string;
  roomZone: string;
}

export default function RoomZone() {
  const [roomZone, setRoomZone] = useState<ZoneInterface[]>([]);
  const [typeZone, setTypeZone] = useState<receivedRoomTypePerZone[]>([]);
  const [roomData, setRoomData] = useState<AvailableDataInterface[]>([]);
  const [occData, setOccData] = useState<AvailableDataInterface[]>([]);
  // const [uniqueRoomTypes, setUniqueRoomTypes] = useState<RoomTypes[]>([]);
  const token = useSelector((state: RootState) => state.user.accessToken);

  /* ------------------------------- Parameters for Add Room ------------------------------- */
  const [searchParams] = useSearchParams();

  const paramsDate = searchParams.get("date");
  const paramsDuration = searchParams.get("duration");

  const { bookingId } = useParams();

  /* ------------------------------- filter fields ------------------------------- */

  const [selectedDate, setSelectedDate] = useState<Date>(
    paramsDate ? new Date(paramsDate) : new Date()
  );
  const handleDateOnChange = (date: Date): void => {
    setSelectedDate(date);
  };

  const [duration, setDuration] = useState<number>(
    paramsDuration ? parseInt(paramsDuration) : 3
  );
  const handleDurationOnChange = (duration: number): void => {
    setDuration(duration);
  };

  const [bedType, setBedType] = useState<string>("all");
  const handleBedTypeOnChange = (bedType: string): void => {
    setBedType(bedType);
  };

  const [quietZone, setQuietZone] = useState<string>("all");
  const handleQuietZoneChange = (quietZone: string): void => {
    setQuietZone(quietZone);
  };

  /* ------------------------------- fetch data from DB ------------------------------- */
  const getZoneData = () => {
    axiosInstance
      .get("/rooms/zone/get-all-filtered-zone", {
        params: {
          zone_filter: roomZoneFilters,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setRoomZone(res.data.data);
          return;
        }
      })
      .catch((e) => {
        console.log(e);
        Notification.failed("something wrong, please try again");
      });
  };

  const getRoomZoneMenuItems = () => {
    axiosInstance
      .get("/rooms/zone/get-all-zone", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setRoomZoneFilters(res.data.data.map((data: any) => data.zoneName));
        }
        if (res.status === 200) {
          const formattedRoomZoneDatas = res.data.data.map(
            (data: any): IFormattedRoomZones => ({
              roomZoneId: data.zoneId,
              roomZone: data.zoneName,
            })
          );
          setRoomZoneMenuItems(formattedRoomZoneDatas);
          const roomZones = formattedRoomZoneDatas.map(
            ({ roomZone }: { roomZone: string }) => roomZone
          );
          setRoomZoneFilters(roomZones);
        }
      })
      .catch((e) => {
        console.log(e);
        Notification.failed("something wrong, please try again");
      })
      .finally(() => {
        setHasFetchRoomZoneMenuItems(true);
      });
  };

  const getTypeData = () => {
    axiosInstance
      .get("/rooms/zone/get-zone-distinct-room-type", {
        params: {
          zone_roomtype_filter: roomTypeFilters,
          zone_filter: roomZoneFilters,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setTypeZone(parseTypeData(res.data.data));
          return;
        }
      })
      .catch((e) => {
        console.log(e);
        Notification.failed("something wrong, please try again");
      });
  };

  // const getUniqueRoomTypes = () => {
  //   axiosInstance
  //     .get("/rooms/type/get-all-unique-type", {
  //       params: {
  //         zone_roomtype_filter: roomTypeFilters,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setUniqueRoomTypes(res.data.data);
  //         return;
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       Notification.failed("something wrong, please try again");
  //     });
  // };

  const getRoomTypesMenuItems = () => {
    axiosInstance
      .get("/rooms/type/get-all-unique-type", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const formattedRoomTypesDatas = res.data.data.map(
            (data: any): IFormattedRoomTypes => ({
              roomTypeId: data.typeId,
              roomType: data.typeName,
            })
          );
          setRoomTypesMenuItems(formattedRoomTypesDatas);
          const roomTypes = formattedRoomTypesDatas.map(
            ({ roomType }: { roomType: string }) => roomType
          );
          setRoomTypeFilters(roomTypes);
        }
      })
      .catch((e) => {
        console.log(e);
        Notification.failed("something wrong, please try again");
      })
      .finally(() => {
        setHasFetchRoomTypeMenuItems(true);
      });
  };

  const getRoomData = () => {
    axiosInstance
      .get(`/rooms/zone/get-available-data-details`, {
        params: {
          year: parseInt(selectedDate.toISOString().split(/[-T]/)[0]),
          month: parseInt(selectedDate.toISOString().split(/[-T]/)[1]),
          day: parseInt(selectedDate.toISOString().split(/[-T]/)[2]),
          hour: parseInt(selectedDate.toISOString().split(/[T:]/)[1]),
          minute: parseInt(selectedDate.toISOString().split(/[T:]/)[2]),
          duration: duration,
          bed_type: bedType,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setRoomData(parseAvailableData(res.data.data));
          return;
        }
      })
      .catch((e) => {
        console.log(e);
        Notification.failed("something wrong, please try again");
      });
  };

  const getOccData = () => {
    axiosInstance
      .get(`/rooms/zone/get-occupied-data-details`, {
        params: {
          year: parseInt(selectedDate.toISOString().split(/[-T]/)[0]),
          month: parseInt(selectedDate.toISOString().split(/[-T]/)[1]),
          day: parseInt(selectedDate.toISOString().split(/[-T]/)[2]),
          hour: parseInt(selectedDate.toISOString().split(/[T:]/)[1]),
          minute: parseInt(selectedDate.toISOString().split(/[T:]/)[2]),
          bed_type: bedType,
          duration: duration,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setOccData(parseAvailableData(res.data.data));
          return;
        }
      })
      .catch((e) => {
        console.log(e);
        Notification.failed("something wrong, please try again");
      });
  };

  /* ------------------------------- FILTER Room Types ------------------------------- */

  const [roomTypeFilters, setRoomTypeFilters] = useState<string[]>([]);
  const handleRoomTypeFiltersOnChange = useCallback(
    (
      roomTypeToAdd: string | string[],
      isChecked: boolean,
      isAll: boolean
    ): void => {
      if (isAll && Array.isArray(roomTypeToAdd)) {
        isChecked && setRoomTypeFilters(roomTypeToAdd);
        !isChecked && setRoomTypeFilters([]);
        return;
      }
      if (!isChecked && !isAll && typeof roomTypeToAdd === "string") {
        const dummyArr = roomTypeFilters.slice(0);
        const index = roomTypeFilters.indexOf(roomTypeToAdd);
        index > -1 && dummyArr.splice(index, 1);
        setRoomTypeFilters(dummyArr);
        return;
      }
      if (
        typeof roomTypeToAdd === "string" &&
        roomTypeFilters.includes(roomTypeToAdd)
      )
        return;
      if (typeof roomTypeToAdd === "string" && isChecked)
        setRoomTypeFilters([...roomTypeFilters, roomTypeToAdd]);
    },
    [roomTypeFilters]
  );

  const [hasFetchRoomTypeMenuItems, setHasFetchRoomTypeMenuItems] =
    useState<boolean>(false);

  const [roomTypesMenuItems, setRoomTypesMenuItems] = useState<
    IFormattedRoomTypes[]
  >([]);

  // const fetchRoomTypes = () => {
  //   const formattedRoomType: IFormattedRoomTypes[] = uniqueRoomTypes.map(
  //     ({ zoneId, typeName, typeId, colorTags }) => {
  //       const formattedObject = {
  //         typeId: typeId,
  //         roomType: typeName,
  //       };

  //       return formattedObject;
  //     }
  //   );

  //   Promise.all(formattedRoomType).then((roomTypes) => {
  //     setHasFetchRoomTypeMenuItems(true);
  //   });
  // };

  /* ------------------------------- FILTER Room Zones ------------------------------- */

  const [roomZoneFilters, setRoomZoneFilters] = useState<string[]>([]);

  const handleRoomZoneFiltersOnChange = useCallback(
    (
      roomZoneToAdd: string | string[],
      isChecked: boolean,
      isAll: boolean
    ): void => {
      if (isAll && Array.isArray(roomZoneToAdd)) {
        isChecked && setRoomZoneFilters(roomZoneToAdd);
        !isChecked && setRoomZoneFilters([]);
        return;
      }
      if (!isChecked && !isAll && typeof roomZoneToAdd === "string") {
        const dummyArr = roomZoneFilters.slice(0);
        const index = roomZoneFilters.indexOf(roomZoneToAdd);
        index > -1 && dummyArr.splice(index, 1);
        setRoomZoneFilters(dummyArr);
        return;
      }
      if (
        typeof roomZoneToAdd === "string" &&
        roomZoneFilters.includes(roomZoneToAdd)
      )
        return;
      if (typeof roomZoneToAdd === "string" && isChecked)
        setRoomZoneFilters([...roomZoneFilters, roomZoneToAdd]);
    },
    [roomZoneFilters]
  );

  const [hasFetchRoomZoneMenuItems, setHasFetchRoomZoneMenuItems] =
    useState<boolean>(false);

  const [roomZoneMenuItems, setRoomZoneMenuItems] = useState<
    IFormattedRoomZone[]
  >([]);

  /* -------------------------------------------------------------------------- */
  /*                        Styling (might remove later)                        */
  /* -------------------------------------------------------------------------- */

  // const StyledTypography = styled(Typography)`
  //   color: white;
  //   display: inline-block;
  //   font-size: 20px;
  // `;
  /* --------------------- Override the theme for dropdown -------------------- */
  // const StyledSelect = styled(Select)(({}) => ({
  //   "& .MuiInputBase-input": {
  //     backgroundColor: "transparent",
  //     paddingLeft: "20px",
  //     height: "30px",
  //     borderRadius: "15px",
  //     border: "none",
  //   },
  // }));

  /* --------------------------- parse data from DB --------------------------- */

  function parseTypeData(apiResponse: any[]): receivedRoomTypePerZone[] {
    return apiResponse.map((item) => ({
      zone_id: item.roomzone__zone_id.toString(),
      zone_name: item.roomzone__zone_name,
      room_type_id: item.type_id,
      type_name: item.type_name,
      colorTags: item.color_tags,
      num_dirty_rooms: item.num_dirty_rooms,
    }));
  }
  function parseAvailableData(apiResponse: any[]): AvailableDataInterface[] {
    return apiResponse.map((item) => ({
      type_id: item.type_id.toString(),
      type_name: item.type_name,
      zone_id: item.zone_id,
      countedRooms: item.room_count,
    }));
  }

  useEffect(() => {
    getRoomTypesMenuItems();
    getRoomZoneMenuItems();
  }, []);

  const handleZoneMapChangeOnclick = () => {
    getZoneData();
    // getUniqueRoomTypes();
    getTypeData();
    getRoomData();
    getOccData();
  };

  useEffect(() => {
    if (
      selectedDate &&
      hasFetchRoomZoneMenuItems &&
      hasFetchRoomTypeMenuItems
    ) {
      handleZoneMapChangeOnclick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchRoomZoneMenuItems, hasFetchRoomTypeMenuItems]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <PageTitle title={bookingId ? "Add Room" : "Walk In Booking"} />
      <Box display="flex" flexDirection="column" justifyContent="center">
        <FilterBar
          roomTypes={roomTypesMenuItems}
          roomTypeFilters={roomTypeFilters}
          handleRoomTypeFiltersOnChange={handleRoomTypeFiltersOnChange}
          date={selectedDate}
          handleDateOnChange={handleDateOnChange}
          duration={duration}
          handleDurationOnChange={handleDurationOnChange}
          roomZones={roomZoneMenuItems}
          roomZoneFilters={roomZoneFilters}
          handleRoomZoneFiltersOnChange={handleRoomZoneFiltersOnChange}
          bedType={bedType}
          handleBedTypeOnChange={handleBedTypeOnChange}
          quietZone={quietZone}
          handleQuietZoneOnChange={handleQuietZoneChange}
          handleZoneMapChangeOnclick={handleZoneMapChangeOnclick}
          bookingId={bookingId}
        />
        {/* 
        <ZoneMapOverview
          uniqueRoomTypes={uniqueRoomTypes}
          roomData={roomData}
          occData={occData}
        /> */}
        <Grid
          container
          alignSelf={"center"}
          justifyContent="flex-start"
          alignItems="center"
          sx={{ width: "94%", marginTop: "25px" }}
          rowGap={0}
          padding={0}
        >
          {roomZone.map((item) => {
            const av_rooms = roomData.filter(
              (room) => room.zone_id === item.zoneId
            );
            const occ_rooms = occData.filter(
              (room) => room.zone_id === item.zoneId
            );
            return (
              <CapsuleZoneButton
                key={item.zoneId}
                typeZone={typeZone}
                zoneID={item.zoneId}
                zoneName={item.zoneName}
                rooms={av_rooms}
                occRooms={occ_rooms}
                duration={duration}
                bedType={bedType}
                selectedDate={selectedDate}
                quietZone={quietZone}
                bookingId={bookingId}
              />
            );
          })}
        </Grid>
      </Box>
    </LocalizationProvider>
  );
}
