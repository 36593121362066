import { Breadcrumbs, Link, LinkProps, Typography } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} color={"#fff"} />;
}

const BreadcrumbTrail = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  function removeHyphensAndCapitalize(text: string) {
    const words = text
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1));

    const result = words.join(" ");

    return result;
  }

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        marginBottom: "10px",
        "& .MuiBreadcrumbs-separator": { color: "white" },
      }}
    >
      <LinkRouter to="/">Home</LinkRouter>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;

        return isLast ? (
          <Typography key={name} color="white">
            {removeHyphensAndCapitalize(name)}
          </Typography>
        ) : (
          <LinkRouter key={name} to={routeTo}>
            {removeHyphensAndCapitalize(name)}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbTrail;
