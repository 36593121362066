import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../assets/theme/theme";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LuggageIcon from "@mui/icons-material/Luggage";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Locker, LockerBooking, LockerV1 } from "../../pages/booking/interface";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import { store } from "../../redux/store";

type Props = {
  isLockerTransferModalOpen: boolean;
  handleCloseLockerTransferModal: () => void;
  lockerBookings: LockerBooking;
  bookingID: string;
};

const LockerCapsules = (
  lockerCode: string,
  lockerZone: string,
  roomCode: string,
  checkIn: string,
  checkOut: string
) => {
  return (
    <>
      <Grid container border={2} borderColor={"white"}>
        <Grid
          item
          md={3}
          sm={3}
          xs={3}
          padding={3}
          borderRight={2}
          borderColor={"white"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LuggageIcon fontSize={"medium"} />
        </Grid>
        <Grid
          item
          md={9}
          sm={9}
          xs={9}
          padding={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Stack width={"50%"}>
            <Typography fontWeight={700}>
              {lockerCode}, {lockerZone}
            </Typography>
            <Typography>Check In</Typography>
            <Typography fontSize={"0.8rem"}>{checkIn}</Typography>
          </Stack>
          <Stack width={"50%"}>
            <Typography fontWeight={700} textAlign={"end"}>
              {roomCode}
            </Typography>
            <Typography textAlign={"end"}>Check Out</Typography>
            <Typography textAlign={"end"} fontSize={"0.8rem"}>
              {checkOut}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "black",
  border: 2,
  borderColor: theme.palette.primary.main,
};

function LockerTransferModal({
  isLockerTransferModalOpen,
  handleCloseLockerTransferModal,
  lockerBookings,
  bookingID,
}: Props) {
  const token = store.getState().user.accessToken;
  const [isLoading, setIsLoading] = useState(false);
  const [lockerOptions, setLockerOptions] = useState<LockerV1[]>([]);
  const [lockerIndex, setLockerIndex] = useState(0);

  const fetchAvailableLockers = () => {
    setIsLoading(true);
    axiosInstance
      .get(`/lockers/?amount=10`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setLockerOptions(res.data);
        }
      })
      .catch((error) => {
        Notification.failed("error fetching locker, try again later");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTransferLocker = () => {
    if (lockerOptions) {
      const payload = {
        lockerBookingId: lockerBookings.lockerBookingId,
        newLockerId: lockerOptions[lockerIndex].locker_id,
      };
      const endpoint = "/bookings/v1/locker/locker-transfer/";
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axiosInstance
        .put(endpoint, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            Notification.success("locker successfully transfered");
          }
        })
        .catch((error) => {
          Notification.failed(error.toString());
        })
        .finally(() => {
          handleCloseLockerTransferModal();
        });
    }
  };

  useEffect(() => {
    fetchAvailableLockers();
  }, []);

  const handleIncrementOnClick = () => {
    setLockerIndex(lockerIndex + 1);
  };

  const handleDecrementOnClick = () => {
    setLockerIndex(lockerIndex - 1);
  };

  return (
    <Modal
      open={isLockerTransferModalOpen}
      onClose={handleCloseLockerTransferModal}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box sx={modalStyle}>
        <Stack
          height={"10%"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          borderBottom={2}
          padding={1}
          borderColor={theme.palette.primary.main}
          sx={{ background: "#232323" }}
        >
          <Typography variant={"h3"} color={theme.palette.primary.main}>
            Transfer Locker
          </Typography>
          <HighlightOffIcon
            fontSize={"medium"}
            sx={{ color: theme.palette.primary.main }}
          />
        </Stack>
        <Grid
          container
          height={"70%"}
          width={"100%"}
          paddingX={6}
          paddingY={3}
          justifyContent={"space-between"}
          borderBottom={2}
          borderColor={theme.palette.primary.main}
        >
          <Grid item md={5.5} sm={12} xs={12} height={"100%"}>
            <Typography marginBottom={2} variant={"h4"}>
              Current Locker
            </Typography>
            <Button
              fullWidth
              disabled={true}
              sx={{
                height: "20%",
                background: "transparent",
                borderRadius: 0,
              }}
            ></Button>
            {LockerCapsules(
              lockerBookings.locker.code,
              lockerBookings.locker.zone.details,
              lockerBookings.roomCode,
              lockerBookings.lockerStartRentDatetime,
              lockerBookings.lockerEndRentDatetime
            )}
            <Button
              fullWidth
              disabled={true}
              sx={{
                height: "20%",
                background: "transparent",
                borderRadius: 0,
              }}
            ></Button>
          </Grid>
          <Grid item md={5.5} sm={12} xs={12}>
            <Typography
              marginBottom={2}
              variant={"h4"}
              textAlign={"end"}
              sx={{ width: "100%" }}
            >
              New Locker
            </Typography>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                {lockerOptions && lockerOptions.length > 0 ? (
                  <>
                    {lockerIndex === lockerOptions.length - 1 ? (
                      <></>
                    ) : (
                      <>
                        <Button
                          fullWidth
                          onClick={handleIncrementOnClick}
                          sx={{
                            height: "20%",
                            background: "#232323",
                            borderRadius: 0,
                            border: 2,
                            borderColor: theme.palette.primary.main,
                          }}
                        >
                          <ArrowUpwardIcon />
                        </Button>
                      </>
                    )}
                    {LockerCapsules(
                      lockerOptions[lockerIndex].code,
                      lockerOptions[lockerIndex].zone.details,
                      lockerBookings.roomCode,
                      lockerBookings.lockerStartRentDatetime,
                      lockerBookings.lockerEndRentDatetime
                    )}
                    {lockerIndex === 0 ? (
                      <> </>
                    ) : (
                      <>
                        <Button
                          fullWidth
                          onClick={handleDecrementOnClick}
                          sx={{
                            height: "20%",
                            background: "#232323",
                            borderRadius: 0,
                            border: 2,
                            borderColor: theme.palette.primary.main,
                          }}
                        >
                          <ArrowDownwardIcon />
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <Typography>No lockers available</Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Stack
          height={"20%"}
          width={"100%"}
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          padding={2}
          sx={{ background: "#232323" }}
        >
          <Button onClick={handleTransferLocker}>Change to This Locker</Button>
        </Stack>
      </Box>
    </Modal>
  );
}

export default LockerTransferModal;
