import {
  RoomTypeInterface,
  ZoneInterface,
  ZoneInterfaceV2,
} from "../../models/rooms/roomsInterface";
import { store } from "../../redux/store";
import {
  Modal,
  Box,
  useTheme,
  Typography,
  TextField,
  Stack,
  Button,
  MenuItem,
  CircularProgress,
  Switch,
} from "@mui/material";
import ManagementModalHeader from "../management/ManagementModalHeader";
import { useEffect, useState } from "react";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";

type Props = {
  title: String;
  handleEditRoomZoneModalClose: () => void;
  openEditRoomZoneModal: boolean;
  selectedZone?: ZoneInterfaceV2;
};

const EditRoomZoneModal = ({
  title,
  handleEditRoomZoneModalClose,
  openEditRoomZoneModal,
  selectedZone,
}: Props) => {
  const theme = useTheme();
  const token = store.getState().user.accessToken;

  const [zoneNameField, setZoneNameField] = useState<string>("");
  const [isZoneArchived, setIsZoneArchived] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedZone) {
      setZoneNameField(selectedZone.zoneName);
      setIsZoneArchived(selectedZone.archived);
    }
  }, [selectedZone]);

  const saveZone = async () => {
    setLoading(true);
    const payload = {
      zoneName: zoneNameField,
    };
    await axiosInstance
      .post(`/rooms/v1/zone/`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success("Zone created successfully");
        handleEditRoomZoneModalClose();
      })
      .catch((error) => {
        Notification.failed(`Error creating zone: ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editZone = async () => {
    setLoading(true);
    const payload = {
      zoneId: selectedZone?.zoneId,
      zoneName: zoneNameField,
    };
    await axiosInstance
      .put(`/rooms/v1/zone/edit/`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success("Zone updated successfully");
        handleEditRoomZoneModalClose();
      })
      .catch((error) => {
        Notification.failed(`Error updating zone: ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeZoneStatus = async (status: boolean) => {
    setLoading(true);
    setIsZoneArchived(status);
    const payload = {
      roomZone: selectedZone?.zoneId,
      archive: status,
    };
    await axiosInstance
      .patch(`/rooms/v1/zone/archive/ `, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success(response.data.message);
      })
      .catch((error) => {
        setIsZoneArchived(false);
        Notification.failed(`Error updating zone: ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal open={openEditRoomZoneModal} onClose={handleEditRoomZoneModalClose}>
      <Box
        width={"400px"}
        border={`1.5px solid ${theme.palette.primary.main}`}
        alignItems={"center"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
          borderRadius: 1,
        }}
      >
        <ManagementModalHeader
          title={selectedZone ? "Edit Zone" : "New Zone"}
          handleModalClose={handleEditRoomZoneModalClose}
        />
        <Stack padding={2} spacing={2} justifyItems={"center"}>
          <Typography variant="h2" marginBottom={2}>
            {selectedZone ? "Edit Zone" : "New Zone"}
          </Typography>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <TextField
              label="Zone Name"
              size={"medium"}
              fullWidth
              value={zoneNameField}
              onChange={(event) => setZoneNameField(event.target.value)}
              sx={{
                border: 2,
                borderColor: theme.palette.primary.main,
                borderRadius: "5px",
              }}
            />
          </Stack>
          {selectedZone ? (
            <Stack direction={"row"} alignItems="center">
              <Typography variant="h4" width={"30%"}>
                Acrhive status
              </Typography>
              <Switch
                checked={isZoneArchived}
                onChange={({ target: { checked } }) =>
                  changeZoneStatus(checked)
                }
              ></Switch>
            </Stack>
          ) : (
            <></>
          )}
          <Stack
            width={"100%"}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
          >
            <Box padding={1}>
              <Button
                variant="outlined"
                onClick={selectedZone ? editZone : saveZone}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress color="primary" size={"24px"} />
                ) : (
                  "Save"
                )}
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default EditRoomZoneModal;
