import {
  Backdrop,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../assets/theme/theme";
import Notification from "../../utils/notificationConfig";

//Icons
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LuggageIcon from "@mui/icons-material/Luggage";
import EastIcon from "@mui/icons-material/East";
import CustomDataGrid from "../global/grid/CustomDataGrid";
import { GridColDef } from "@mui/x-data-grid";
import { LockerBooking, RoomBooking } from "../../pages/booking/interface";
import axiosInstance from "../../constants/axiosConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const dataGridColumns: GridColDef[] = [
  {
    field: "id",
  },
  {
    field: "Room ID",
    headerName: "Room ID",
    flex: 1,
  },
  {
    field: "Type",
    headerName: "Type",
    flex: 1,
  },
  {
    field: "Zone",
    headerName: "Zone",
    flex: 1,
  },
];

type Props = {
  isRebindLockerModalOpen: boolean;
  handleCloseRebindLockerModal: () => void;
  lockerBooking: LockerBooking;
  roomBookings: RoomBooking[];
};

const LockerContainer = (lockerBooking: LockerBooking) => {
  return (
    <>
      <Grid
        container
        border={4}
        borderColor={theme.palette.primary.main}
        height={"auto"}
        width={"auto"}
        flexWrap={"nowrap"}
      >
        <Grid
          item
          padding={4}
          borderRight={4}
          borderColor={theme.palette.primary.main}
          md={3}
          sm={3}
          xs={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LuggageIcon fontSize={"large"} sx={{ color: "white" }} />
        </Grid>
        <Grid
          item
          md={9}
          xs={9}
          sm={9}
          padding={1}
          overflow={"hidden"}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack width={"50%"}>
            <Typography>
              {lockerBooking.locker.code}, {lockerBooking.locker.zone.details}
            </Typography>
            <Typography>Check In</Typography>
            <Typography fontSize={"0.8rem"}>
              {lockerBooking.lockerStartRentDatetime}
            </Typography>
          </Stack>
          <Stack width={"50%"}>
            <Typography textAlign={"end"}>{lockerBooking.roomCode}</Typography>
            <Typography textAlign={"end"}>Check Out</Typography>
            <Typography textAlign={"end"} fontSize={"0.8rem"}>
              {lockerBooking.lockerEndRentDatetime}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

const modalStyle = {
  width: "60vw",
  backgroundColor: "#000",
  border: 2,
  borderColor: theme.palette.primary.main,
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

function RebindLockerModal({
  isRebindLockerModalOpen,
  handleCloseRebindLockerModal,
  lockerBooking,
  roomBookings,
}: Props) {
  const token = useSelector((state: RootState) => state.user.accessToken);
  const [dataGridSelection, setDataGridSelection] = useState<string>("");
  const [selectedRoom, setSelectedRoom] = useState<RoomBooking>();

  const handleSelectionOnChange = (newSelection: any) => {
    setDataGridSelection(newSelection);
  };

  useEffect(() => {
    const selectedRoomBooking = roomBookings?.find(
      (item) => item.roomBookingId === dataGridSelection[0]
    );
    setSelectedRoom(selectedRoomBooking);
  }, [dataGridSelection]);

  const handleRebindLocker = () => {
    if (selectedRoom) {
      const payload = {
        lockerBookingId: lockerBooking.lockerBookingId,
        newRoomCode: selectedRoom?.roomCode,
      };
      axiosInstance
        .put("/bookings/v1/locker/locker-rebind/", payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            Notification.success("Locker Rebinded Succesfully");
            handleCloseRebindLockerModal();
          }
        })
        .catch((error) => {
          Notification.failed(error.toString());
        });
    } else {
      Notification.failed("please select a room");
    }
  };

  return (
    <Modal
      open={isRebindLockerModalOpen}
      onClose={handleCloseRebindLockerModal}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box sx={modalStyle}>
        <Stack
          height={"10%"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          paddingX={2}
          borderBottom={2}
          borderColor={theme.palette.primary.main}
          sx={{ background: "#232323" }}
        >
          <Typography variant={"h3"} color={theme.palette.primary.main}>
            Rebind Room
          </Typography>
          <IconButton onClick={handleCloseRebindLockerModal}>
            <HighlightOffIcon
              fontSize={"medium"}
              sx={{ color: theme.palette.primary.main }}
            />
          </IconButton>
        </Stack>
        <Grid container height={"90%"}>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            paddingX={4}
            paddingY={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography textAlign={"start"} marginBottom={2} variant={"h4"}>
              B-1234567
            </Typography>
            {LockerContainer(lockerBooking)}
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            padding={4}
            sx={{
              background: "#141414",
              height: "100%",
              borderLeft: 2,
              borderColor: theme.palette.primary.main,
            }}
          >
            <Grid container height={"15%"} marginBottom={2}>
              <Grid
                item
                md={4}
                sm={4}
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Current Room</Typography>
                <Typography sx={{ textDecoration: "line-through" }}>
                  {lockerBooking.roomCode}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                sm={4}
                xs={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <EastIcon fontSize={"large"} sx={{ color: "white" }} />
              </Grid>
              <Grid
                item
                md={4}
                sm={4}
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography textAlign={"end"}>New Room</Typography>
                <Typography textAlign={"end"}>
                  {selectedRoom?.roomCode}, {selectedRoom?.roomZone}
                </Typography>
              </Grid>
            </Grid>
            <Stack
              height={"70%"}
              border={2}
              borderColor={theme.palette.primary.main}
              sx={{ background: "black" }}
            >
              <CustomDataGrid
                columns={dataGridColumns}
                rows={
                  roomBookings?.map((item) => {
                    return {
                      id: item.roomBookingId,
                      "Room ID": item.roomCode,
                      Type: item.roomType,
                      Zone: item.roomZone,
                    };
                  }) ?? []
                }
                rowSelectionModel={dataGridSelection}
                onRowSelectionModelChange={handleSelectionOnChange}
                columnVisibilityModel={{
                  id: false,
                }}
              />
            </Stack>
            <Stack
              height={"15%"}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              marginTop={2}
            >
              <Button
                onClick={handleRebindLocker}
                sx={{
                  background: "black",
                  border: 2,
                  borderColor: theme.palette.primary.main,
                }}
              >
                Change to this Room
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default RebindLockerModal;
