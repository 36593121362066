import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { BookingResult } from "../../pages/booking/interface";
import {
  IPaymentMethod,
  IPayments,
} from "../../models/payment/PaymentInterface";
import ManagementModalHeader from "../management/ManagementModalHeader";
import theme from "../../assets/theme/theme";
import ManagementModalFormTextField from "../management/ManagementModalFormTextField";
import { ChangeEvent, useEffect, useState } from "react";
import axiosInstance from "../../constants/axiosConfig";
import { RootState } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import { useSelector } from "react-redux";
import RefundTypeForm from "./RefundTypeForm";

type Props = {
  isRefundModalOpen: boolean;
  handleCloseRefundModal: () => void;
  invoiceData: IPayments;
  bookingId: string;
};

const RefundModal = ({
  isRefundModalOpen,
  handleCloseRefundModal,
  invoiceData,
  bookingId,
}: Props) => {
  const token = useSelector((state: RootState) => state.user.accessToken);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [selectedPaymentMethods, setSelectedPaymentMethods] =
    useState<IPayments>();

  const [isPaymentTypeModal, setIsPaymentTypeModal] =
    useState<IPayments | null>(null);
  const handleOpenPaymentTypeModal = (data: IPayments) => {
    setIsPaymentTypeModal(data);
  };
  const handleClosePaymentTypeModal = () => {
    setIsPaymentTypeModal(null);
  };

  const [reason, setReason] = useState<string>();

  const handleSubmit = () => {
    if (!selectedPaymentMethods) {
      Notification.failed("Please Select Return Type before Submitting");
      return;
    }
    if (!selectedFile) {
      Notification.failed("Please Submit a file for Proof of refund");
      return;
    }
    setIsLoading(true);
    const apiUrl = "/transaction/refund-transaction/";
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    const formData = new FormData();
    formData.append(
      "transaction_id",
      selectedPaymentMethods.transactionId
        ? selectedPaymentMethods.transactionId
        : ""
    );
    formData.append(
      "payment_type_id",
      selectedPaymentMethods.paymentType
        ? selectedPaymentMethods.paymentType
        : ""
    );
    formData.append("reason", reason ? reason : "");
    formData.append("refund_form", selectedFile);

    axiosInstance
      .post(apiUrl, formData, config)
      .then((response) => {
        Notification.success("Refund Success");
        handleCloseRefundModal();
        fetchPaymentMethod();
      })
      .catch((res) => {
        Notification.failed(res.response.data.message);
        Notification.failed("Please make sure all field are filled");
      })
      .finally(() => setIsLoading(false));
  };
  const handleReasonChange = (newValue?: string) => {
    setReason(newValue || ""); // Use an empty string if newValue is undefined
  };
  const fetchPaymentMethod = () => {
    axiosInstance
      .get("/payment/method", config)
      .then((res) => {
        if (res.status === 200) {
          setPaymentMethods(res.data);
          return;
        }
        Notification.failed(res.data.msg);
      })
      .catch((e) => {
        Notification.failed(e.response.data.msg);
      });
  };
  useEffect(() => {
    fetchPaymentMethod();
  }, [token]);

  // Function to handle file input change
  const [selectedFile, setSelectedFile] = useState<File | undefined>();

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    // Do something with the selected file, such as displaying a preview
    setSelectedFile(file);
  };
  return (
    <Modal
      open={isRefundModalOpen}
      onClose={handleCloseRefundModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box
        width={"600px"}
        minWidth={"38em"}
        border={`3px solid ${theme.palette.primary.main}`}
        margin={"auto"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
        }}
      >
        <ManagementModalHeader
          title="Refund"
          handleModalClose={handleCloseRefundModal}
        />
        {!isLoading ? (
          <Grid
            container
            spacing={2}
            padding={5}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {" "}
            <Grid item xs={4}>
              {" "}
              <Typography>Refund Reason</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="Refund Reason"
                fullWidth
                value={reason}
                onChange={(e) => handleReasonChange(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              {" "}
              <Typography>Select Payment Type</Typography>
            </Grid>
            <Grid item xs={8}>
              <Button
                onClick={() => {
                  handleOpenPaymentTypeModal(invoiceData);
                }}
              >
                {selectedPaymentMethods
                  ? selectedPaymentMethods?.paymentTypeName
                  : "Select Payment"}
              </Button>
            </Grid>
            {/* <Grid item xs={4}>
          {" "}
          <Typography>Payment Remarks</Typography>
        </Grid> */}
            {/* <Grid item xs={8}>
        <TextField label="Payment Remarks" fullWidth />
      </Grid> */}
            <Grid item xs={4}>
              {" "}
              <Typography>Form (PDF)</Typography>
            </Grid>
            <Grid item xs={8}>
              <input
                type="file"
                accept="application/pdf"
                onChange={handleImageChange}
              />
              {/* {selectedImage && (
              <div>
                <p>Preview:</p>
                <a
                  href={selectedImage}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View PDF
                </a>
              </div>
            )} */}
            </Grid>
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <Button onClick={handleSubmit}>Submit</Button>
            </Grid>
          </Grid>
        ) : (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"200px"}
          >
            <CircularProgress />
          </Box>
        )}
        {isPaymentTypeModal && (
          <RefundTypeForm
            handlePaymentModalClose={handleClosePaymentTypeModal}
            payment={isPaymentTypeModal}
            paymentMethods={paymentMethods}
            isExpressBooking={false}
            isPOSGuestPayment={false}
            bookingId={bookingId}
            selectedPaymentMethod={setSelectedPaymentMethods}
          />
        )}
      </Box>
    </Modal>
  );
};

export default RefundModal;
