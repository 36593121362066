import { Box, Stack, Typography } from "@mui/material";
import { ReactComponent as OnlyLogoSVG } from "../../assets/images/LogoWhite.svg";

const PageTitle = ({
  title,
  marginLeft,
  width,
}: {
  title: string;
  marginLeft?: string;
  width?: string;
}) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      spacing={2}
      marginLeft={marginLeft}
      marginBottom={1}
    >
      <Box sx={{display: {xs: "none", md: "block"}}}>
        <OnlyLogoSVG width={50} height={50} />
      </Box>
      <Typography variant="h2" sx={{fontSize: {xs: "medium", md: "large"}}}>{title}</Typography>
    </Stack>
  );
};

export default PageTitle;
