import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IBookingPeriod, IBookingRowInfo } from "../../types/ganttChart";
import BookingRowItem from "./BookingRowItem";
import { ItemTypes } from "../../constants/react-dnd/ItemType";
import { bookingItemDrop } from "../../models/react-dnd/DragAndDropInterface";
import { useDrop } from "react-dnd";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type Props = {
  measurements: {
    xAxisNumTicks: number;
    emPerHour: number;
    itemHeight: string;
    epochPerEM: number;
  };
  bookingPeriods: IBookingPeriod[];
  todayDateEpoch: number;
  roomStatus: string;
  removeBookingPeriod: (rowIndex: number, periodIndex: number) => void;
  bookingIndex: number;
  roomId: string;
  setBookingRows: React.Dispatch<React.SetStateAction<IBookingRowInfo[]>>;
};

const BookingRow = ({
  measurements: { xAxisNumTicks, epochPerEM, itemHeight, emPerHour },
  bookingPeriods,
  todayDateEpoch,
  roomStatus,
  removeBookingPeriod,
  bookingIndex,
  roomId,
  setBookingRows,
}: Props) => {
  // const [bookingItems, setBookingItems] =
  //   useState<IBookingPeriod[]>(bookingPeriods);
  const token = useSelector((state: RootState) => state.user.accessToken);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOOKING,
      drop: (item: bookingItemDrop) => {
        if (item.roomBookingId !== "add New Booking") {
          handleChangeRoom(item.roomBookingId);
        }
        return {
          ...item,
        };
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    []
  );

  const handleChangeRoom = (roomBookingId: string) => {
    const apiUrl = "/bookings/v1/bookingroom/room-transfer/";

    const formData = {
      room_booking_id: roomBookingId,
      new_room_id: roomId,
    };

    axiosInstance
      .post(apiUrl, formData, config)
      .then((response) => {
        if (response.data.status !== "error") {
          Notification.success(response.data.message);
          setBookingRows((prevBookingRows) =>
            updateBookingRows(prevBookingRows, roomBookingId, roomId)
          );
        } else {
          Notification.failed(response.data.error);
        }
      })
      .catch((res) => Notification.failed(res.response.data.message));
  };

  return (
    <Grid
      item
      ref={drop}
      xs={xAxisNumTicks}
      sm={xAxisNumTicks}
      md={xAxisNumTicks}
      lg={xAxisNumTicks}
      xl={xAxisNumTicks}
      position={"relative"}
      sx={{
        height: itemHeight,
        width: `${xAxisNumTicks * emPerHour}em`,
        paddingY: "0.5em",
        overflow: "hidden",
      }}
    >
      {bookingPeriods.map(
        (
          {
            roomBookingId,
            checkInDatetime,
            checkOutDatetime,
            currentBookingStatus,
            customerFirstname,
            customerLastname,
            bookingNo,
            bookingPlatform,
            bookingId,
            maxCheckOutDatetime,
          },
          index
        ) =>
          currentBookingStatus !== "Cancelled" && (
            <BookingRowItem
              key={index}
              measurements={{
                epochPerEM,
              }}
              todayDateEpoch={todayDateEpoch}
              checkInDatetime={checkInDatetime}
              checkOutDatetime={checkOutDatetime}
              maxCheckOutDatetime={maxCheckOutDatetime}
              currentBookingStatus={currentBookingStatus}
              customerFirstname={customerFirstname}
              customerLastname={customerLastname}
              bookingNo={bookingNo}
              bookingPlatform={bookingPlatform}
              roomStatus={roomStatus}
              removeBookingPeriod={removeBookingPeriod}
              index={index}
              bookingIndex={bookingIndex}
              bookingId={bookingId}
              roomBookingId={roomBookingId}
            />
          )
      )}
    </Grid>
  );
};

const updateBookingRows = (
  prevBookingRows: IBookingRowInfo[],
  roomBookingId: string,
  roomId: string
): IBookingRowInfo[] => {
  const removedBookingRows = prevBookingRows.map((bookingRow) => ({
    ...bookingRow,
    bookingPeriods: bookingRow.bookingPeriods.filter(
      (bookingPeriod) => bookingPeriod.roomBookingId !== roomBookingId
    ),
  }));

  const removedPeriod = findAndRemoveBookingPeriod(
    prevBookingRows,
    roomBookingId
  );

  if (removedPeriod) {
    return addBookingPeriod(removedBookingRows, removedPeriod, roomId);
  }

  return prevBookingRows;
};

const findAndRemoveBookingPeriod = (
  bookingRows: IBookingRowInfo[],
  roomBookingId: string
): IBookingPeriod | undefined => {
  let result: IBookingPeriod | undefined;

  bookingRows.some((bookingRow) => {
    const foundBookingPeriod = bookingRow.bookingPeriods.find(
      (bookingPeriod) => bookingPeriod.roomBookingId === roomBookingId
    );

    if (foundBookingPeriod) {
      result = foundBookingPeriod;
      return true;
    }

    return false;
  });

  return result;
};

const addBookingPeriod = (
  bookingRows: IBookingRowInfo[],
  newBookingPeriod: IBookingPeriod,
  targetRoomId: string
): IBookingRowInfo[] => {
  return bookingRows.map((bookingRow) => {
    if (bookingRow.roomId === targetRoomId) {
      return {
        ...bookingRow,
        bookingPeriods: [...bookingRow.bookingPeriods, newBookingPeriod],
      };
    }
    return bookingRow;
  });
};

export default BookingRow;
