import { Settings } from "@mui/icons-material";
import {
  Box,
  Stack,
  Typography,
  Button,
  IconButton,
  useTheme,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../constants/axiosConfig";
import { RootState } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import AddOrEditPaymentTerminal from "../../components/payment/AddOrEditPaymentTerminal";
import { IPaymentTerminal } from "../../models/payment/PaymentInterface";

const initialPaymentTerminalData: IPaymentTerminal = {
  id: "",
  cashierPaymentTerminals: [],
  terminalId: "",
  serialNumber: "",
  createdDatetime: "",
  updatedDatetime: "",
  paymentTerminalIp: "",
  archived: false,
  lot: 0,
};

const PaymentTerminal = () => {
  const theme = useTheme();
  const user = useSelector((state: RootState) => state.user);
  const config = {
    headers: { Authorization: `Bearer ${user.accessToken}` },
  };
  const [isAddEditPaymentTerminalOpen, setIsAddEditPaymentTerminalOpen] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [paymentTerminal, setPaymentTerminal] = useState<IPaymentTerminal[]>(
    []
  );
  const [selectedPaymentTerminal, setSelectedPaymentTerminal] =
    useState<IPaymentTerminal>(initialPaymentTerminalData);

  const getPageData = () => {
    setIsLoading(true);
    axiosInstance
      .get("/payment-terminal/", config)
      .then((res) => {
        if (res.status === 200) {
          setPaymentTerminal(res.data.data);
          return;
        }
      })
      .catch((e) => {
        Notification.failed(e.response.data.msg);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPageData();
  }, []);

  const handleAddEditPaymentTerminalOpen = (
    PaymentTerminal?: IPaymentTerminal
  ) => {
    setIsAddEditPaymentTerminalOpen(true);
    if (PaymentTerminal) setSelectedPaymentTerminal(PaymentTerminal);
  };
  const handleAddEditPaymentTerminalClose = () => {
    setIsAddEditPaymentTerminalOpen(false);
    setSelectedPaymentTerminal(initialPaymentTerminalData);
  };
  const cashierPaymentTerminals = paymentTerminal.flatMap(
    (terminal) => terminal.cashierPaymentTerminals
  );
  return (
    <Stack
      width={"100%"}
      sx={{ backgroundColor: theme.palette.background.paper }}
    >
      <Box padding={5}>
        {!isAddEditPaymentTerminalOpen ? (
          <>
            <Stack
              direction={"row"}
              width={"100%"}
              justifyContent={"space-between"}
              marginBottom={1}
            >
              <Typography variant="h2">Payment Terminal</Typography>
              <Button
                variant={"contained"}
                onClick={() => handleAddEditPaymentTerminalOpen()}
                sx={{
                  width: "150px",
                  backgroundColor: theme.palette.background.paper,
                  color: "white",
                  border: 1,
                  borderColor: theme.palette.primary.main,
                }}
              >
                Create New
              </Button>
            </Stack>
            <Stack direction={"column"} spacing={1}>
              {paymentTerminal.map((paymentTerminal) => (
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                  bgcolor={theme.palette.background.default}
                  padding={2}
                >
                  <Stack direction={"column"}>
                    <Typography
                      variant="h4"
                      color={paymentTerminal.archived ? "grey" : "white"}
                    >
                      {paymentTerminal.terminalId}
                    </Typography>
                    <Typography
                      variant="h5"
                      color={paymentTerminal.archived ? "grey" : "white"}
                    >
                      {paymentTerminal.serialNumber} -{" "}
                      {paymentTerminal.paymentTerminalIp}
                    </Typography>
                  </Stack>
                  <IconButton
                    onClick={() =>
                      handleAddEditPaymentTerminalOpen(paymentTerminal)
                    }
                  >
                    <Settings color="primary" />
                  </IconButton>
                </Box>
              ))}
            </Stack>
          </>
        ) : (
          <AddOrEditPaymentTerminal
            handleBack={handleAddEditPaymentTerminalClose}
            selectedPaymentTerminal={selectedPaymentTerminal}
            setPaymentTerminal={setPaymentTerminal}
          />
        )}
      </Box>
    </Stack>
  );
};

export default PaymentTerminal;
