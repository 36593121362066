import { Box, Stack, Typography } from "@mui/material";

const RoomBox = (props: {
  colorCode: string;
  roomCode: string;
  roomZone: string;
  roomDetails: string;
}) => {
  return (
    <Stack
      direction={"row"}
      border={2}
      borderColor={props.colorCode}
      width={"100%"}
      height={"50px"}
    >
      <Box
        display={"flex"}
        width={"20%"}
        height={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        borderRight={2}
        borderColor={props.colorCode}
      >
        <Typography variant="h4">{props.roomDetails}</Typography>
      </Box>
      <Box
        display={"flex"}
        width={"80%"}
        justifyContent={"start"}
        alignItems={"center"}
        paddingX={1}
      >
        <Typography variant="h4">
          {props.roomCode}, {props.roomZone}
        </Typography>
      </Box>
    </Stack>
  );
};

export default RoomBox;
