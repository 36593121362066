import { ButtonBase, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { IBookingDraft } from "../../types/ganttChart";
import theme from "../../assets/theme/theme";
import { createSearchParams, useNavigate } from "react-router-dom";
import Notification from "../../utils/notificationConfig";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type Props = {
  bookingDraft: IBookingDraft[];
};

const AddedBooking = ({ bookingDraft }: Props) => {
  /* -------------------------- test roomtype initial ------------------------- */
  // const testString = "halohalooooo";
  // const stringLength = testString.length;
  // const initial = testString[1] + testString[Math.floor(stringLength/2)]
  // console.log(initial);
  // const queryParams = createSearchParamsFromArrayObjects('bookingDraft', bookingDraft)

  const dateFormat = {
    year: "numeric" as const,
    month: "short" as const,
    day: "numeric" as const,
  };
  const timeFormat = {
    hour: "2-digit" as const,
    minute: "2-digit" as const,
    hour12: false,
  };
  const changeDateFormat = (fullDate: string) => {
    const unformattedDate = new Date(fullDate);
    const onlyDate = unformattedDate.toLocaleDateString(undefined, dateFormat);
    const onlyTime = unformattedDate.toLocaleTimeString(undefined, timeFormat);
    const fullFormattedDate = {
      date: onlyDate,
      time: onlyTime,
    };
    return fullFormattedDate;
  };

  const [isAddBookingModalUp, setIsAddBookingModalUp] =
    useState<boolean>(false);
  const handleIsAddBookingModal = () => {
    setIsAddBookingModalUp(!isAddBookingModalUp);
  };

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          height: "fit-content",  
          marginX: "auto",
          display: "flex",
          backgroundColor: "#141414",
          flexDirection: "column", 
          border: 2,
          borderColor: theme.palette.primary.main             
        }}
      >
        <Stack
          sx={{
            padding: "10px",
          }}
        >
          <Typography variant="h4" color={theme.palette.primary.main}>
            Added Rooms
          </Typography>
        </Stack>
        <Stack
          spacing={3}
          direction={"row"}
          sx={{
            backgroundColor: "#141414",
            width: "100%",
            height: "10em",
          }}
        >
          <Stack
            spacing={3}
            direction={"row"}
            justifyContent={"flex-start"}
            sx={{
              overflowX: "auto",
              maxWidth: "100%",
              background: "#141414",
              minWidth: "100%",
              display: "flex",
              padding: "20px",
              wrap: "nowrap",
              // styled scroll bar
              "&::-webkit-scrollbar": {
                width: "10px",
                height: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "transparent",
                borderRadius: "6px",
                border: "3px solid #15ffdc",
                marginBlock: "50px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
                borderRadius: "3px",
              },
              "&::-webkit-scrollbar-corner": {
                backgroundColor: "black",
              },
            }}
          >
            {bookingDraft.map((item: any, index) => {
              const formattedCheckInDraftDateTime = changeDateFormat(
                item.check_in
              );
              const formattedCheckOutDraftDateTime = changeDateFormat(
                item.check_out
              );
              return (
                <Grid
                  key={index}
                  container
                  sx={{
                    border: 4,
                    borderColor: item.color_code,
                    maxWidth: "450px",
                    midWidth: "450px",
                    height: "5.5em",
                    flexShrink: 0,
                  }}
                >
                  <Grid
                    item
                    md={2}
                    xs={2}
                    sm={2}
                    sx={{
                      borderRight: 4,
                      borderColor: item.color_code,
                      height: "100%",
                      padding: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant={"h3"}>
                      {item.room_type_name[0]}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={10}
                    xs={10}
                    sm={10}
                    sx={{
                      padding: "20px",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      height={"100%"}
                    >
                      <Stack
                        sx={{
                          maxWidth: "50%",
                        }}
                      >
                        <Typography variant={"h5"}>{item.room_code}</Typography>
                        <Typography variant={"h6"}>
                          {formattedCheckInDraftDateTime.date},{" "}
                          {formattedCheckInDraftDateTime.time}
                        </Typography>
                      </Stack>
                      <Stack
                        justifyContent={"flex-end"}
                        sx={{
                          maxWidth: "50%",
                        }}
                      >
                        <Typography
                          variant={"h5"}
                          sx={{
                            textAlign: "end",
                          }}
                        >
                          {item.room_type_details}
                        </Typography>
                        <Typography
                          variant={"h6"}
                          sx={{
                            textAlign: "end",
                          }}
                        >
                          {formattedCheckOutDraftDateTime.date},{" "}
                          {formattedCheckOutDraftDateTime.time}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              );
            })}
          </Stack>
          {/* <Stack
            direction={"column"}
            sx={{
              width: "100%",
              borderLeft: 2,
              borderColor: theme.palette.primary.main,
            }}
          >
             <ButtonBase "#141414",
              onClick={handleBookingClick}
              sx={{
                backgroundColor: "#232323",
                maxWidth: "100%",
                minWidth: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant={"h4"}>Booking</Typography>
            </ButtonBase> 
          </Stack> */}
        </Stack>
      </Stack>
    </>
  );
};

export default AddedBooking;
