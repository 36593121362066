import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import {
  IBooking,
  IBookingInfo,
  IStepper,
  IBookingDraft,
  IPlatform,
} from "../../models/bookings/Booking";
import BookingInfoForm from "../../components/booking/BookingInfoForm";

import * as Yup from "yup";
import PICAssignation from "../../components/booking/PICAssignation";

//Components
import { Formik, useFormik } from "formik";
import { useLocation, Location, useNavigate } from "react-router-dom";
import PaymentForm from "../../components/payment/PaymentForm";
import {
  IPaymentGuests,
  IPaymentItem,
} from "../../models/payment/PaymentInterface";
import axios from "axios";
import BASE_API from "../../constants/api";
import { store } from "../../redux/store";
import axiosInstance from "../../constants/axiosConfig";

//utils
import Notification from "../../utils/notificationConfig";
import PageTitle from "../../components/global/PageTitle";
import { stringDateToEpoch } from "../../utils/functions";
import { transactionCategoryEnum } from "../../constants/enums";

export default function GanttChartBookingInfo() {
  const token = store.getState().user.accessToken;
  const navigate = useNavigate();
  const [allPlatforms, setAllPlatforms] = useState<IPlatform[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const location: Location = useLocation();
  const { state } = location;

  const rawRoomBookingArray = state.data;

  const bookingId = state.bookingId;

  const [customerBooked, setCustomerBooked] = useState<string>("");
  const formattedBookingDraftArray: IBookingDraft[] = rawRoomBookingArray.map(
    (item: any) => ({
      ...item,
      person_in_charge_ic: "",
      person_in_charge_id: "",
      person_in_charge_name: "",
    })
  );

  let defaultDuration = 3;
  let defaultCheckOutDate = formattedBookingDraftArray[0].check_out;

  for (const item of formattedBookingDraftArray) {
    if (item.duration > defaultDuration) {
      defaultDuration = item.duration;
      defaultCheckOutDate = item.check_out;
    }
  }

  const parsePlatformData = (fetchedPlatform: any[]): IPlatform[] => {
    if (fetchedPlatform) {
      return fetchedPlatform.map((item: any) => ({
        platformId: item.platform_id.toString(),
        platform: item.platform,
      }));
    } else {
      return [];
    }
  };

  const fetchAllAvailablePlatform = () => {
    axiosInstance
      .get("/bookings/platforms/get-all-platforms", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setAllPlatforms(parsePlatformData(res.data.datas));
        }
      })
      .catch((e) => {
        console.log(e);
        Notification.failed("something wrong, please try again");
      });
  };

  useEffect(() => {
    fetchAllAvailablePlatform();
  }, []);

  const maxAdultPax = formattedBookingDraftArray.reduce((acc, current) => {
    if (current.max_pax) {
      return (acc = acc + current.max_pax);
    }
    return (acc = acc + 0);
  }, 0);

  const initialValue: IBookingInfo = {
    id: "",
    checkInDate: `${
      new Date(formattedBookingDraftArray[0].check_in)
        .toISOString()
        .split(".")[0]
    }Z`,
    checkOutDate: `${
      new Date(defaultCheckOutDate).toISOString().split(".")[0]
    }Z`,
    duration: formattedBookingDraftArray[0].duration,
    adultPax: maxAdultPax,
    childrenPax: 0,
    platform: "Walk In",
    platformId: "",
    otaRefNo: "",
    customerStayingIc: "",
    customerStayingId: "",
    customerStayingName: "",
    customerStayingCountry: "",
    customerStayingMemberId: "",
    customerStayingMemberTier: "",
    customerStayingMemberCondition: "",
    pin: "",
    rententionCount: 0,
  };

  const stepperValue: IStepper[] = [
    { step: 1, text: "Booking Info" },
    { step: 2, text: "Add Guest" },
    { step: 3, text: "Payment" },
  ];

  const bookingData: IBooking = {
    bookingInfo: initialValue,
    roomBookings: formattedBookingDraftArray,
  };

  const [stepper, setStepper] = useState<number>(1);

  const theme = useTheme();

  const handleChangeStep = (data: IStepper) => {
    setStepper(data.step);
  };

  const [picError, setPicError] = useState<boolean>(false);
  const handleSetPicError = (data: boolean) => {
    setPicError(data);
  };

  const [confirmBookingData, setConfirmBookingData] = useState<IBooking>({
    bookingInfo: initialValue,
    roomBookings: formattedBookingDraftArray,
  });
  const [registerBookingId, setRegisterBookingId] = useState<string>("");
  const [paymentItemFormat, setPaymentItemFormat] = useState<IPaymentItem[]>(
    []
  );

  useEffect(() => {
    // Update paymentItemFormat when confirmBookingData changes
    const updatedPaymentItemFormat = confirmBookingData.roomBookings.map(
      (booking) => ({
        itemId: booking.room_id,
        itemName: booking.room_code,
        itemType: booking.room_type_name,
        category: transactionCategoryEnum.roomSales,
        quantity: 1,
        price: 0,
        duration: booking.duration,
      })
    );

    setPaymentItemFormat(updatedPaymentItemFormat);
  }, [confirmBookingData]);

  const handleOnSubmit = (values: IBooking) => {
    if (
      values.roomBookings.every(
        (roomBooking) =>
          roomBooking.person_in_charge_id !==
          values.bookingInfo.customerStayingId
      )
    ) {
      handleSetPicError(true);
      return;
    }
    handleSetPicError(false);

    setIsLoading(true);

    if (bookingId) {
      //Add room request
      axiosInstance
        .post(
          `${BASE_API}/bookings/v1/bookingroom/add-room-booking/`,
          {
            start_time: stringDateToEpoch(values.bookingInfo.checkInDate),
            end_time: stringDateToEpoch(values.bookingInfo.checkOutDate),
            booking_id: bookingId,
            rooms: values.roomBookings.map((item) => ({
              remarks: "Added Room",
              pic_id: item.person_in_charge_id,
              room_id: item.room_id,
            })),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(({ data }) => {
          if (data.status === "failed") {
            Notification.failed(data.error);
            return;
          }
          navigate(`/upcoming-booking/${bookingId}`);
          Notification.success("Added Room Successfully");
        })
        .catch((res) => {
          Notification.failed(res.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      //For newly created bookings
      axios
        .post(
          `${BASE_API}/bookings/register/`,
          {
            checkInDatetime: `${new Date(values.bookingInfo.checkInDate)
              .toISOString()
              .slice(0, -5)}Z`,
            checkOutDatetime: `${new Date(values.bookingInfo.checkOutDate)
              .toISOString()
              .slice(0, -5)}Z`,
            adult: values.bookingInfo.adultPax,
            child: values.bookingInfo.childrenPax,

            platformId: values.bookingInfo.platformId,
            otaCode: values.bookingInfo.otaRefNo,
            details: "",
            rooms: values.roomBookings.map((item) => ({
              picId: item.person_in_charge_id,
              roomId: item.room_id,
              details: "",
            })),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(({ data }) => {
          setConfirmBookingData({
            roomBookings: values.roomBookings.map((booking) => ({
              ...booking,
              duration: values.bookingInfo.duration,
            })),
            bookingInfo: {
              ...values.bookingInfo,
              id: data.data.bookingId,
              duration: values.bookingInfo.duration,
            },
          });
          handleChangeStep({ step: 3, text: "Payment" });
          Notification.success("Booking Registered");
        })
        .catch((res) => {
          Notification.failed(res.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const guestsList: IPaymentGuests = {
    customerStayingId: confirmBookingData.bookingInfo.customerStayingId,
    customerStayingName: confirmBookingData.bookingInfo.customerStayingName,
    guests: confirmBookingData.roomBookings.map((data) => ({
      guestId: data.person_in_charge_id,
      guestName: data.person_in_charge_name,
      isMember: data.member_id !== "None" ? true : false,
    })),
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: bookingData,
    validationSchema: Yup.object().shape({}),
    onSubmit: (values, { setErrors, setStatus, setSubmitting }) => {
      handleOnSubmit(values);
    },
  });

  return (
    <>
      <PageTitle title="Walk In Booking" />
      <Stack direction={"row"} justifyContent={"center"} spacing={2}>
        {stepperValue.map((item, index) => (
          <Box
            key={index}
            display={"flex"}
            width={"300px"}
            height={"50px"}
            alignItems={"center"}
            border={1}
            borderColor={theme.palette.primary.main}
            sx={{
              backgroundColor:
                stepper === item.step ? theme.palette.primary.main : "black",
            }}
          >
            <Box
              display={"flex"}
              width={"100px"}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              borderRight={1}
              borderColor={theme.palette.primary.main}
              sx={{ backgroundColor: "black" }}
            >
              <Typography variant="h3">{item.step}</Typography>
            </Box>
            <Box width={"70%"}>
              <Typography
                variant="h3"
                color={stepper === item.step ? "black" : "white"}
                textAlign={"center"}
              >
                {item.text}
              </Typography>
            </Box>
          </Box>
        ))}
      </Stack>
      <>
        {stepper === 1 && (
          <BookingInfoForm
            initialValues={formik.values.bookingInfo}
            setFieldValue={formik.setFieldValue}
            handleChangeStep={handleChangeStep}
            roomBookingArray={formattedBookingDraftArray}
            allPlatforms={allPlatforms}
            setStepper={setStepper}
            setCustomerBooked={setCustomerBooked}
            setBookingId={setRegisterBookingId}
            setConfirmBookingData={setConfirmBookingData}
          />
        )}

        {stepper === 2 && (
          <PICAssignation
            setFieldValue={formik.setFieldValue}
            handleChangeStep={handleChangeStep}
            roomBookingArray={formik.values.roomBookings}
            initialValues={formik.values.bookingInfo}
            picError={picError}
            isSubmitting={isLoading}
            handleOnSubmit={handleOnSubmit}
          />
        )}
      </>
      {stepper === 3 && (
        <PaymentForm
          getPastTransactions={true}
          paymentItem={paymentItemFormat}
          bookingId={
            confirmBookingData.bookingInfo.id
              ? confirmBookingData.bookingInfo.id
              : registerBookingId
          }
          bookingGuest={guestsList}
          isExpressBooking={false}
          latestBookingStatus={"Booked"}
        />
      )}
    </>
  );
}
