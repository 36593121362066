import {
  Divider,
  Grid,
  TextField,
  Typography,
  MenuItem,
  CircularProgress,
  inputLabelClasses,
} from "@mui/material";
import theme from "../../assets/theme/theme";
import { useEffect, useState } from "react";
import axiosInstance from "../../constants/axiosConfig";
import { store } from "../../redux/store";
import { CurrentTierPlatform, Tiers } from "../../models/rooms/roomsInterface";
import Notification from "../../utils/notificationConfig";

const ActiveTier = () => {
  const token = store.getState().user.accessToken;
  const [tiers, setTiers] = useState<Tiers[]>([]);
  const [currentTierPlatformData, setCurrentTierPlatformData] = useState<
    CurrentTierPlatform[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchAllTiers = async () => {
      await axiosInstance
        .get(`/rooms/tiers?withAccountLot=true`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setTiers(res.data.data);
          }
        });
    };

    fetchAllTiers();
  }, [token]);

  useEffect(() => {
    const fetchAllActiveTiers = async () => {
      setLoading(true);
      await axiosInstance
        .get(`/bookings/v1/platforms/active-tier-list`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setCurrentTierPlatformData(res.data.data);
          }
        });
      setLoading(false);
    };

    fetchAllActiveTiers();
  }, [token]);

  const fetchAllActiveTiers = async () => {
    setLoading(true);
    setCurrentTierPlatformData([]);
    await axiosInstance
      .get(`/bookings/v1/platforms/active-tier-list`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setCurrentTierPlatformData(res.data.data);
        }
      });
    setLoading(false);
  };

  const assignTier = async (platformId?: string, tierId?: string) => {
    const payload = {
      platformId: platformId,
      newTierId: tierId,
    };
    await axiosInstance
      .put(`/rooms/platform-tier/assign-tier/`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success(response.data.message);
        fetchAllActiveTiers();
      })
      .catch((error) => {
        Notification.failed(`Error assigning tier: ${error}`);
      })
      .finally(() => {});
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2">Active Tier</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              pt: 5,
              mb: 5,
              borderBottomWidth: 3.5,
              borderColor: "#232323",
            }}
          />
        </Grid>
        {!loading ? (
          <>
            <Grid item xs={9} pb={2}>
              <Typography variant="h3" color={theme.palette.primary.main}>
                Platforms
              </Typography>
            </Grid>
            <Grid item xs={3} pb={2}>
              <Typography variant="h3" color={theme.palette.primary.main}>
                Tiers
              </Typography>
            </Grid>
          </>
        ) : (
          ""
        )}
        {loading ? (
          <Grid item xs={12} container justifyContent={"center"}>
            <CircularProgress color="primary" size={40} />
          </Grid>
        ) : (
          ""
        )}
        {currentTierPlatformData.map((item, i) => (
          <>
            <Grid item xs={8} p={2} key={i}>
              <Typography variant="h4">{item.platform?.platform}</Typography>
            </Grid>
            <Grid item xs={4} p={2}>
              <TextField
                select
                title="Select Tiers"
                name="tiersId"
                label={item.currentTier ? "Tiers" : "Tier not set yet"}
                InputLabelProps={{
                  sx: {
                    // set the color of the label when not shrinked
                    color: "red",
                    [`&.${inputLabelClasses.shrink}`]: {
                      // set the color of the label when shrinked (usually when the TextField is focused)
                      color: "white",
                    },
                  },
                }}
                size="medium"
                fullWidth
                value={item.currentTier?.tiersId ?? ""}
                onChange={(e) =>
                  assignTier(item.platform?.platformId, e.target.value)
                }
              >
                {tiers.map((option, j) => (
                  <MenuItem key={j} value={option.tiersId}>
                    {option.tier}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </>
        ))}
        <Grid item xs={12}>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              pt: 5,
              mb: 5,
              borderBottomWidth: 3.5,
              borderColor: "#232323",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ActiveTier;
