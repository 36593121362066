import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import theme from "../../assets/theme/theme";
import BASE_API from "../../constants/api";
import axiosInstance from "../../constants/axiosConfig";
import {
  ICountry,
  IEditGuestInfo,
  IGender,
  IIdType,
  IMembership,
} from "../../models/guests/GuestsInterface";
import { store } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import PhoneField from "../input/PhoneField";

type Props = {
  openEditGuestProfile: boolean;
  handleEditGuestProfileClose: () => void;
  guestId: string;
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  border: 2,
  borderColor: theme.palette.primary.main,
};

///This modal is to edit the guest profile information.
function EditGuestProfile({
  openEditGuestProfile,
  handleEditGuestProfileClose,
  guestId,
}: Props) {
  const [initialValues, setInitialValues] = useState<IEditGuestInfo>({
    id: "",
    firstname: "",
    lastname: "",
    gender: "",
    idType: "",
    id_no: "",
    phoneNumber: "",
    email: "",
    country: "",
    member: "",
  });

  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [idTypes, setIdTypes] = useState<IIdType[]>([]);
  const [genders, setGenders] = useState<IGender[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [prefixes, setPrefixes] = useState<string[]>([]);
  const [memberships, setMemberships] = useState<IMembership[]>([]);
  const token = store.getState().user.accessToken;

  ///This API is to get all ID types like Passport etc
  useEffect(() => {
    const fetchIdType = () => {
      axiosInstance
        .get(`${BASE_API}/guests/id-type`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIdTypes(response.data.data);
        });
    };

    ///This API is to get all genders
    const fetchGender = () => {
      axiosInstance
        .get(`${BASE_API}/guests/gender`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setGenders(response.data.data);
        });
    };

    ///This API is to get all countries list
    const fetchCountry = () => {
      axiosInstance
        .get(`${BASE_API}/guests/country`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const sortedCountry: ICountry[] = response.data.data.sort(
            (prev: ICountry, curr: ICountry) =>
              prev.favorite === curr.favorite ? 0 : prev.favorite ? -1 : 1
          );
          setCountries(sortedCountry);
          setPrefixes(sortedCountry.map((country) => country.prefix));
        });
    };

    ///This API is to get all membership types
    const fetchMemberships = () => {
      axiosInstance
        .get(`${BASE_API}/guests/member`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setMemberships(response.data.data);
        });
    };

    fetchIdType();
    fetchGender();
    fetchCountry();
    fetchMemberships();
  }, [token]);

  useEffect(() => {
    const fetchGuestById = () => {
      axiosInstance
        .get(`/guests/${guestId}/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const prefixAndPhoneNumber = res.data.phoneNumber.split(" ", 2);
            setInitialValues({
              id: res.data.idNo,
              firstname: res.data.firstname,
              lastname: res.data.lastname,
              gender: res.data.gender.genderId,
              idType: res.data.idType.idTypeId,
              id_no: res.data.idNo,
              phonePrefix: prefixAndPhoneNumber[0],
              phoneNumber:
                prefixAndPhoneNumber.length > 1 ? prefixAndPhoneNumber[1] : "",
              email: res.data.email,
              country: res.data.country.countryId,
              member: res.data.member.memberId ?? "",
            });
          }
        });
    };

    fetchGuestById();
  }, [guestId, token]);

  ///It will call the Guest Update API and update the changes in the database.
  ///It is using initialValues as a json body
  ///Then it is setting loading state, disable button state and closing the modal
  ///After successfull API call
  const handleSubmit = async () => {
    setLoading(true);

    const formData = {
      ...initialValues,
      phoneNumber: initialValues.phoneNumber
        ? [initialValues.phonePrefix, initialValues.phoneNumber].join(" ")
        : "",
    };
    await axiosInstance
      .put(`/guests/${guestId}/`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          Notification.success(res.data.message);
        }
        setDisableSaveButton(true);
        handleEditGuestProfileClose();
      })
      .catch((res) => {
        const errorsList = res.response.data;
        for (const errorKey in errorsList) {
          if (errorsList.hasOwnProperty(errorKey)) {
            Notification.failed(errorsList[errorKey][0]);
          }
        }
      })
      .finally(() => setLoading(false));
  };

  ///This is handling all the input textfields state updation.
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInitialValues((prevValues) => ({
      ...prevValues,
      [name]: value.toUpperCase(),
    }));
    setDisableSaveButton(false);
  };

  return (
    <>
      {/* -------------------------------------------------------------------------- */
      /*                   Modal to edit guest profile                              */
      /* -------------------------------------------------------------------------- */}
      <Modal
        open={openEditGuestProfile}
        onClose={handleEditGuestProfileClose}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          },
        }}
      >
        <Box sx={modalStyle} bgcolor={theme.palette.background.default}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
            sx={{ background: "#141414" }}
          >
            <Grid container spacing={0} sx={{ p: 0, width: "100%" }}>
              <Grid item xs={11}>
                <Stack
                  display={"flex"}
                  sx={{
                    p: 2,
                    border: 0,
                    borderColor: "#232323",
                    height: "50px",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h2" color={theme.palette.primary.main}>
                    Edit User Info
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <Stack
                  display={"flex"}
                  sx={{
                    p: 1,
                    border: 0,
                    borderColor: theme.palette.primary.main,
                    height: "50px",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    onClick={handleEditGuestProfileClose}
                    sx={{ padding: 0 }}
                  >
                    <HighlightOffIcon
                      fontSize="large"
                      sx={{ color: "#ff4d56" }}
                    />
                  </IconButton>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  sx={{
                    px: 3,
                    py: 1.5,
                    borderTop: 2,
                    borderColor: theme.palette.primary.main,
                    height: "50px",
                  }}
                >
                  <Typography variant="h2">Edit Info</Typography>
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  alignItems={"center"}
                  spacing={4}
                  px={3}
                  py={1.5}
                >
                  <Typography variant={"h4"} width={160}>
                    First Name
                  </Typography>
                  <Stack
                    direction={"row"}
                    height={"40px"}
                    width={"100%"}
                    alignItems={"center"}
                    spacing={1}
                  >
                    <TextField
                      sx={{
                        border: 2,
                        borderColor: theme.palette.primary.main,
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      size="small"
                      name="firstname"
                      value={initialValues.firstname}
                      onChange={handleInputChange}
                    />
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  alignItems={"center"}
                  spacing={4}
                  px={3}
                  py={1.5}
                >
                  <Typography variant={"h4"} width={160}>
                    Last Name
                  </Typography>
                  <Stack
                    direction={"row"}
                    height={"40px"}
                    width={"100%"}
                    alignItems={"center"}
                    spacing={1}
                  >
                    <TextField
                      sx={{
                        border: 2,
                        borderColor: theme.palette.primary.main,
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      size="small"
                      name="lastname"
                      value={initialValues.lastname}
                      onChange={handleInputChange}
                    />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  alignItems={"center"}
                  spacing={4}
                  px={3}
                  py={1.5}
                >
                  <Typography variant={"h4"} width={160}>
                    Gender
                  </Typography>
                  <Select
                    size={"small"}
                    name="gender"
                    value={initialValues.gender}
                    onChange={(e) => {
                      setInitialValues((prevValues) => ({
                        ...prevValues,
                        gender: e.target.value,
                      }));
                      setDisableSaveButton(false);
                    }}
                    sx={{
                      border: 2,
                      borderColor: theme.palette.primary.main,
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    {genders.map((gender) => (
                      <MenuItem key={gender.genderId} value={gender.genderId}>
                        {gender.gender}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  alignItems={"center"}
                  spacing={4}
                  px={3}
                  py={1.5}
                >
                  <Typography variant={"h4"} width={160}>
                    Country
                  </Typography>
                  <Select
                    size={"small"}
                    value={initialValues.country}
                    onChange={(e) => {
                      setInitialValues((prevValues) => ({
                        ...prevValues,
                        country: e.target.value,
                      }));
                      setDisableSaveButton(false);
                    }}
                    sx={{
                      border: 2,
                      borderColor: theme.palette.primary.main,
                      borderRadius: "5px",
                      width: "100%",
                      color: "white",
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                  >
                    {countries.map((country) => (
                      <MenuItem
                        key={country.countryId}
                        value={country.countryId}
                      >
                        {country.countryName}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  alignItems={"center"}
                  spacing={4}
                  px={3}
                  py={1.5}
                >
                  <Typography variant={"h4"} width={130}>
                    ID
                  </Typography>

                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    width={"100%"}
                    border={2}
                    borderColor={theme.palette.primary.main}
                    borderRadius="5px"
                  >
                    <Select
                      value={initialValues.idType}
                      onChange={(e) => {
                        setInitialValues((prevValues) => ({
                          ...prevValues,
                          idType: e.target.value,
                        }));
                        setDisableSaveButton(false);
                      }}
                      sx={{
                        width: "200px",
                      }}
                      size="small"
                    >
                      {idTypes.map((idType, index) => (
                        <MenuItem key={index} value={idType.idTypeId}>
                          {idType.idType}
                        </MenuItem>
                      ))}
                    </Select>
                    <TextField
                      name="id_no"
                      value={initialValues.id_no}
                      onChange={handleInputChange}
                      sx={{
                        width: "100%",
                        borderLeft: 1,
                        borderColor: theme.palette.primary.main,
                      }}
                      size="small"
                    />
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  alignItems={"center"}
                  spacing={4}
                  px={3}
                  py={1.5}
                >
                  <Typography variant={"h4"} width={130}>
                    Phone
                  </Typography>

                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    width={"100%"}
                    border={2}
                    borderColor={theme.palette.primary.main}
                    borderRadius="5px"
                  >
                    <Select
                      value={
                        initialValues.phonePrefix
                          ? initialValues.phonePrefix.replace(/\+/g, "")
                          : ""
                      }
                      onChange={(e) => {
                        setInitialValues((prevValues) => ({
                          ...prevValues,
                          phonePrefix: e.target.value,
                        }));
                        setDisableSaveButton(false);
                      }}
                      sx={{
                        width: "200px",
                      }}
                      size="small"
                    >
                      {prefixes.map((prefix, index) => (
                        <MenuItem key={index} value={prefix}>
                          {prefix}
                        </MenuItem>
                      ))}
                    </Select>

                    <PhoneField
                      value={initialValues.phoneNumber}
                      name="phoneNumber"
                      onChange={handleInputChange}
                      setFieldValue={(value) =>
                        setInitialValues((prevValues) => ({
                          ...prevValues,
                          phoneNumber: value,
                        }))
                      }
                      sx={{
                        borderLeft: 1,
                        borderColor: theme.palette.primary.main,
                      }}
                    />
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  alignItems={"center"}
                  spacing={4}
                  px={3}
                  py={1.5}
                >
                  <Typography variant={"h4"} width={130}>
                    Email
                  </Typography>
                  <Stack
                    direction={"row"}
                    height={"40px"}
                    width={"100%"}
                    alignItems={"center"}
                    spacing={1}
                  >
                    <TextField
                      sx={{
                        border: 2,
                        borderColor: theme.palette.primary.main,
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      size="small"
                      name="email"
                      value={initialValues.email}
                      onChange={handleInputChange}
                    />
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} p={3}>
                <Divider color="#232323" />
              </Grid>

              <Grid item xs={12}>
                <Stack
                  sx={{
                    p: 2,
                    height: "50px",
                  }}
                >
                  <Typography variant="h2">Membership Setting</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  alignItems={"center"}
                  spacing={4}
                  px={3}
                  py={1.5}
                >
                  <Typography variant={"h4"} width={130}>
                    Membership
                  </Typography>
                  <Select
                    size={"small"}
                    name="membership"
                    value={initialValues.member}
                    onChange={(e) => {
                      setInitialValues((prevValues) => ({
                        ...prevValues,
                        member: e.target.value,
                      }));
                      setDisableSaveButton(false);
                    }}
                    sx={{
                      border: 2,
                      borderColor: theme.palette.primary.main,
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    {memberships.map((membership) => (
                      <MenuItem
                        key={membership.memberId}
                        value={membership.memberId}
                      >
                        {membership.memberTier}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>

              <Grid item xs={12} p={3}>
                <Divider color="#232323" />
              </Grid>

              <Grid item xs={9}>
                <Stack
                  sx={{
                    p: 2,
                    alignItems: "start",
                    borderTop: 2,
                    borderColor: theme.palette.primary.main,
                    height: "80px",
                  }}
                >
                  <Button
                    sx={{
                      borderRadius: 0,
                      color: "white",
                      border: 2,
                      borderColor: "transparent",
                      background: "#232323",
                    }}
                    onClick={handleEditGuestProfileClose}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack
                  sx={{
                    p: 2,
                    alignItems: "end",
                    borderTop: 2,
                    borderColor: theme.palette.primary.main,
                    height: "80px",
                  }}
                >
                  <Button
                    sx={{
                      width: "40%",
                      borderRadius: 0,
                      color: !disableSaveButton ? "black" : "white !important",
                      fontWeight: "bold",
                      border: 2,
                      background: !disableSaveButton
                        ? theme.palette.primary.main
                        : "transparent",
                      borderColor: theme.palette.primary.main,
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                      },
                    }}
                    disabled={disableSaveButton || loading}
                    onClick={handleSubmit}
                  >
                    {loading ? (
                      <CircularProgress color="success" size="1.5rem" />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default EditGuestProfile;
