import React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SettingsIcon from "@mui/icons-material/Settings";
import { styled, useTheme } from "@mui/material/styles";

//utils
import { convertToCapitalFirstLetter } from "../../../utils/functions";

// Constant
import { drawerWidth, sidebarItemList } from "../../../constants/sidebarConfig";

// Components
import SidebarItem from "./SidebarItem";
import { ReactComponent as OnlyLogoSVG } from "../../../assets/images/LogoWhite.svg";

// redux
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

// Types and interfaces
import { SidebarListInterface } from "../../../models/sidebar/sidebarMenu";
import {
  Avatar,
  Box,
  ButtonBase,
  Container,
  Fade,
  ListItemButton,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import { KeyboardArrowRight, Logout } from "@mui/icons-material";
import useAuthentication from "../../../hooks/useAuthentication";
interface SidebarProps {
  open: boolean;
  handleDrawerClose: VoidFunction;
  handleDrawerOpen: VoidFunction;
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 12px;
  right: 12px;
  margin-bottom: 15px;
  color: black;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const StyledAvatarWrapper = styled("div")`
  position: relative;
  transition: opacity 0.3s ease-in-out;
  top: 0;
  right: 0;

  &:hover {
    opacity: 0.4;
  }
`;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Sidebar(props: SidebarProps) {
  const userPermission = useSelector(
    (state: RootState) => state.user.permissions
  );

  const themes = useTheme();
  const { logout } = useAuthentication();

  const [activeItem, setActiveItem] = React.useState<string>("-1");

  const user = useSelector((state: RootState) => state.user);

  const handleActiveItem = (index: string) => {
    setActiveItem(index);
  };

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "black",
            color: "#131313",
            borderRight: 2,
            borderColor: themes.palette.primary.main,
          },
        }}
        variant="persistent"
        anchor="left"
        open={props.open}
      >
        <DrawerHeader sx={{ padding: 2 }}>
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <OnlyLogoSVG className="logo" width={40} height={40} />
            <IconButton
              onClick={props.handleDrawerClose}
              sx={{
                color: themes.palette.primary.main,
                display: "block",
              }}
            >
              {props.open ? <CloseIcon /> : <ArrowRightIcon />}
            </IconButton>
          </Stack>
        </DrawerHeader>

        <List
          sx={{ paddingTop: 0, overflowX: "hidden", backgroundColor: "black" }}
        >
          {/* <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "20px",
            flexDirection: "column",
          }}
        >
          <StyledAvatarWrapper>
            <Avatar
              sx={{ width: "80px", height: "80px", marginBottom: "15px" }}
            >
              {convertToCapitalFirstLetter(user.username)}
            </Avatar>
            <StyledIconButton>
              <IconButton>
                <SettingsIcon />
              </IconButton>
            </StyledIconButton>
          </StyledAvatarWrapper>
          <Typography sx={{ color: "white", fontSize: "18px" }}>
            Hello {user.name}!
          </Typography>
        </Container> */}
          {sidebarItemList.map((e: SidebarListInterface, i) => {
            let haveAccess: boolean = true;

            if (e.visiblePermission) {
              for (let item of e.visiblePermission) {
                if (userPermission.includes(item)) {
                  haveAccess = true;
                  break;
                }
                haveAccess = false;
              }
            }
            if (!haveAccess) {
              return <div key={i} style={{ display: "none" }}></div>;
            }
            return (
              <React.Fragment key={i}>
                <Divider
                  sx={{ backgroundColor: themes.palette.primary.main }}
                />
                <ListItem disablePadding sx={{ display: "block" }} key={i}>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "h3",
                    }}
                    sx={{
                      paddingLeft: "18px",
                      paddingTop: "10px",
                    }}
                    primary={e.title}
                  />
                  <List sx={{ backgroundColor: "black" }}>
                    {e.items.map((e, idx) => {
                      let haveAccess: boolean = true;

                      if (e.visiblePermission) {
                        for (let item of e.visiblePermission) {
                          if (userPermission.includes(item)) {
                            haveAccess = true;
                            break;
                          }
                          haveAccess = false;
                        }
                      }
                      if (!haveAccess) {
                        return (
                          <div key={idx} style={{ display: "none" }}></div>
                        );
                      }
                      return (
                        <SidebarItem
                          key={idx}
                          haveDropdown={e.haveDropdown}
                          icon={e.icon}
                          text={e.text}
                          url={e.url}
                          dropdownItem={e.dropdownItem}
                          index={`${idx}${i}`}
                          handleActiveItem={handleActiveItem}
                          activeItem={activeItem}
                        />
                      );
                    })}
                  </List>
                </ListItem>
              </React.Fragment>
            );
          })}
        </List>
        <ListItemButton
          onClick={logout}
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: "50px",
            paddingX: 2,
            zIndex: 1,
            backgroundColor: themes.palette.background.default,
            borderTop: 1,
            borderColor: themes.palette.primary.main,
            "&:hover": {
              backgroundColor: themes.palette.primary.light,
              transition: "all 0.3s",
            },
          }}
        >
          <ListItemIcon sx={{ color: "white" }}>
            <Logout />
          </ListItemIcon>
          <ListItemText
            primary={"Logout"}
            primaryTypographyProps={{
              color: "white",
              variant: "h4",
            }}
          />
        </ListItemButton>
      </Drawer>
      <Fade in={!props.open}>
        <Box
          height={"100vh"}
          width={"10px"}
          sx={{
            backgroundColor: themes.palette.primary.main,
            position: "fixed",
            zIndex: 9999,
          }}
        >
          <ButtonBase
            onClick={props.handleDrawerOpen}
            sx={{ width: "50px", height: "100px", marginTop: "120px" }}
          >
            <Box
              display="flex"
              width={"50px"}
              height={"100px"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                backgroundColor: themes.palette.primary.main,
                position: "absolute",
                clipPath: "polygon(0% 0%,80% 10%,80% 89%,0% 100%)",
              }}
            >
              <KeyboardArrowRight sx={{ color: "black" }} />
            </Box>
          </ButtonBase>
        </Box>
      </Fade>
    </>
  );
}
