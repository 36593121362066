import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TablePagination,
  Stack,
  TextField,
  TableContainer,
  TableBody,
  TableHead,
  Paper,
  Table,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axiosInstance from "../../constants/axiosConfig";
import useAuthorization from "../../hooks/useAuthorization";
import { useNavigate } from "react-router-dom";

// Constants
import { StyledTableCell, StyledTableRow } from "../../constants/tableStyle";

// Utils
import Notification from "../../utils/notificationConfig";
import { filterTableData } from "../../utils/functions";

// components
import TableLoading from "../../components/global/loading/TableLoading";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

// types and interfaces
import { IAccountData } from "../../models/accounts/accountInterface";

export default function AccountList() {
  const { checkIsSuperadmin } = useAuthorization();
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user);
  const config = {
    headers: { Authorization: `Bearer ${user.accessToken}` },
  };

  const [rows, setRows] = useState<IAccountData[]>([]);
  const [page, setPage] = useState(0);
  const [filteredRows, setFilteredRows] = useState<IAccountData[]>([]);

  const rowsPerPage = 10;

  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [updateTrigger, setUpdateTrigger] = useState<boolean>(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Capsule Transit | Account List";
    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateTrigger) {
      getPageData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTrigger]);

  useEffect(() => {
    if (checkIsSuperadmin()) {
      setIsSuperAdmin(true);
    }
  }, [checkIsSuperadmin]);

  const getPageData = () => {
    axiosInstance
      .get("/accounts/get-all-accounts", config)
      .then((res) => {
        if (res.status === 200) {
          setRows(res.data.data);
          setFilteredRows(res.data.data);
          return;
        }
      })
      .catch((e) => {
        console.log(e);
        Notification.failed("something wrong, please try again");
      })
      .finally(() => {
        setFetchLoading(false);
        setUpdateTrigger(false);
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <Box>
      <Typography variant="h1" mb={10} fontSize="2rem">
        Manage Account
      </Typography>
      <Box mb={"10px"} display={"flex"} alignItems={"center"}>
        <Button
          onClick={() => navigate("/accounts/create")}
          variant={"contained"}
          endIcon={<AddIcon />}
        >
          Create Account
        </Button>
      </Box>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={"15px"}
      >
        <Typography fontSize="1.3rem">Account List</Typography>
        <Box sx={{ width: "20rem" }}>
          <TextField
            type="search"
            id="search"
            label="Filter Search"
            sx={{ width: "100%" }}
            onChange={(e) => {
              const newRow = filterTableData(rows, e.target.value);
              setFilteredRows(newRow);
              setPage(0);
            }}
          />
        </Box>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Username</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Role</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {fetchLoading ? (
            <TableLoading />
          ) : (
            <>
              {filteredRows.length > 0 && (
                <TableBody>
                  {filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow
                        key={row.accountId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {row.username}
                        </StyledTableCell>
                        <StyledTableCell>{row.name}</StyledTableCell>
                        <StyledTableCell>{row.email}</StyledTableCell>
                        <StyledTableCell>{row.role.roleName}</StyledTableCell>
                        <StyledTableCell>
                          {isSuperAdmin && (
                            <>
                              {row.role.roleName === "Super Admin" ? (
                                <Typography>-</Typography>
                              ) : (
                                <Button
                                  onClick={() =>
                                    navigate(`/accounts/edit/${row.accountId}`)
                                  }
                                  variant={"text"}
                                >
                                  Edit
                                </Button>
                              )}
                            </>
                          )}

                          {!isSuperAdmin && (
                            <>
                              {row.role.roleName === "Admin" ||
                              row.role.roleName === "Super Admin" ? (
                                <Typography>-</Typography>
                              ) : (
                                <Button
                                  onClick={() =>
                                    navigate(`/accounts/edit/${row.accountId}`)
                                  }
                                  variant={"text"}
                                >
                                  Edit
                                </Button>
                              )}
                            </>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              )}
              {filteredRows.length === 0 && (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>data not available</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              )}
            </>
          )}
        </Table>
        <TablePagination
          sx={{
            color: "white",
            backgroundColor: "#192038",
          }}
          rowsPerPageOptions={[20]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}
