import { Grid, Stack, Tab, Tabs, useTheme } from "@mui/material";
import { useState } from "react";
import AccountTab from "./AccountTab";
import RolesAndPermissionTab from "./RolesAndPermissionTab";
import ShiftTab from "./ShiftTab";

const PermissionSettings = () => {
  const theme = useTheme();

  const [tabStepperValue, setTabStepperValue] = useState(0);

  const handleStepperChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabStepperValue(newValue);
  };
  return (
    <Stack width={"100%"}>
      <Grid container>
        <Grid
          item
          md={2}
          sm={2}
          xs={2}
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          <Stack
            direction={"column"}
            textAlign={"start"}
            padding={2}
            spacing={2}
            height={"75vh"}
            sx={{ backgroundColor: theme.palette.background.default }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabStepperValue}
              onChange={handleStepperChange}
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{
                marginBottom: 5,
                "& .MuiTab-root": {
                  textTransform: "none",
                  fontWeight: 600,
                  minWidth: "8.1vw",
                  fontSize: "1rem",
                  alignContent: "start",
                  "&.Mui-selected": {
                    color: theme.palette.primary.main,
                  },
                  "&:not(.Mui-selected)": {
                    color: "white",
                    borderBottom: "1px solid #0a443c",
                  },
                },
              }}
            >
              <Tab value={0} label={"Accounts"} />
              <Tab value={1} label={"Roles & Permissions"} />
              <Tab value={2} label={"Shifts"} />
            </Tabs>
          </Stack>
        </Grid>

        <Grid item md={10} sm={10} xs={10}>
          <Stack
            direction={"column"}
            width={"100%"}
            height={"75vh"}
            sx={{ backgroundColor: "#141414", overflow: "auto" }}
          >
            {tabStepperValue === 0 ? (
              <AccountTab />
            ) : tabStepperValue === 1 ? (
              <RolesAndPermissionTab />
            ) : tabStepperValue === 2 ? (
              <ShiftTab />
            ) : null}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PermissionSettings;
