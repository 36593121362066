import {
  Backdrop,
  Box,
  ButtonBase,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import React from "react";
import theme from "../../assets/theme/theme";
import ManagementModalHeader from "../management/ManagementModalHeader";
import { IPromotion } from "../../types/promotion";

type Props = {
  isAccessCardRedirectModalOpen: boolean;
  handleAccessCardRedirectModalClose: () => void;
  archivedPromotion: IPromotion;
  title: string;
};

const AccessCardRedirectModal = ({
  isAccessCardRedirectModalOpen,
  handleAccessCardRedirectModalClose,
  archivedPromotion: { promoCode },
  title,
}: Props) => {
  return (
    <Modal
      open={isAccessCardRedirectModalOpen}
      onClose={() => handleAccessCardRedirectModalClose()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box
        width={"35vw"}
        height={"fit-content"}
        minWidth={"38em"}
        border={`3px solid ${theme.palette.primary.main}`}
        position={"absolute"}
        margin={"auto"}
        sx={{
          inset: "0",
          backgroundColor: "black",
        }}
      >
        <ManagementModalHeader
          title={title}
          handleModalClose={handleAccessCardRedirectModalClose}
        />
        <Stack
          direction={"row"}
          height={"20vh"}
          minHeight={"11em"}
          border={"none"}
          borderBottom={`3px solid ${theme.palette.primary.main}`}
          paddingX={"2em"}
          alignItems={"center"}
        >
          <DeleteOutlineSharpIcon
            sx={{
              fontSize: "5em",
            }}
          />
          <Typography fontSize={"1em"} fontWeight={600}>
            {`Promotion "${promoCode}" has been archived.`}
          </Typography>
        </Stack>
        <ButtonBase
          sx={{
            width: "100%",
            paddingX: "2em",
            backgroundColor: theme.palette.background.default,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <EastOutlinedIcon
            sx={{
              fontSize: "5em",
            }}
          />
          <Typography>Go to Access Card Management</Typography>
        </ButtonBase>
      </Box>
    </Modal>
  );
};

export default AccessCardRedirectModal;
