import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import ManagementModalHeader from "../management/ManagementModalHeader";
import { useFormik } from "formik";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import {
  RoomDataInterface,
  RoomDataInterfaceV2,
  ZoneInterface,
} from "../../models/rooms/roomsInterface";
import { useEffect, useState } from "react";
import { IRoomType } from "../../models/bookings/Booking";
import axios from "axios";
import BASE_API from "../../constants/api";
import { store } from "../../redux/store";

type Props = {
  setRoomData: React.Dispatch<React.SetStateAction<RoomDataInterfaceV2[]>>;
  addOrEditRoomModalOpen: boolean;
  handleAddRoomModalClose: () => void;
  selectedRoom?: RoomDataInterfaceV2;
};

const roomDataInitial: RoomDataInterfaceV2 = {
  isArchived: false,
  colorCode: "",
  roomId: "",
  roomCode: "",
  quietZone: false,
  remarks: "",
  status: "",
  roomType: "",
  zone: "",
  roomTypeDetails: "",
  roomTypeName: "",
  zoneId: "",
  isUpper:false,
};

const AddOrEditRoomModal = (props: Props) => {
  const theme = useTheme();
  const [roomData, setRoomData] = useState<RoomDataInterfaceV2>(
    props.selectedRoom ? props.selectedRoom : roomDataInitial
  );

  const [zoneDropdownList, setZoneDropdownList] = useState<ZoneInterface[]>([]);
  const [getZoneUrl, setGetZoneUrl] = useState<string>(
    "/rooms/zone/get-all-zone/"
  );

  const token = store.getState().user.accessToken;

  const { roomTypes } = useGetRoomTypesHook(`/rooms/v1/room-type/list/`, token);

  const { zones, isLoadingRoomZoneFetch } = useGetRoomZonesHook(
    getZoneUrl,
    token
  );

  const formik = useFormik({
    initialValues: props.selectedRoom?.roomId ? roomData : roomDataInitial,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (values.zoneId === "") {
        Notification.failed("Make sure to select Room Zone before submitting");
        return;
      }

      const apiUrl = "/rooms/v1/rooms/";

      const formData = {
        roomCode: values.roomCode,
        roomTypeName: values.roomTypeName,
        zoneId: values.zoneId,
        quietZone: values.quietZone,
        remarks: values.remarks,
        isUpper:values.isUpper
      };
      console.log(formData)
      if (props.selectedRoom) {
        axiosInstance
          .put(apiUrl + `edit/${props.selectedRoom.roomId}/`, formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            Notification.success(res.data.message);
            props.handleAddRoomModalClose();
            props.setRoomData((prevValue) => {
              const selectedZone = zoneDropdownList.filter(
                (zone) => zone.zoneId === values.zoneId
              )[0];

              const currentRoom = prevValue.find(
                (room) => room.roomId === props.selectedRoom?.roomId
              );

              if (currentRoom) {
                const newRoom: RoomDataInterfaceV2 = {
                  ...values,
                  roomCode: values.roomCode,
                  roomTypeName: values.roomTypeName,
                  zoneId: selectedZone.zoneId,
                  zone: selectedZone.zoneName,
                  remarks: values.remarks,
                };
                return [
                  ...prevValue.slice(0, prevValue.indexOf(currentRoom)), // Elements before the selected index
                  newRoom, // Updated element
                  ...prevValue.slice(prevValue.indexOf(currentRoom) + 1), // Elements after the selected index
                ];
              }
              return prevValue;
            });
          })
          .catch((res) => {
            Notification.failed(res.response.data.message);
          });
        return;
      }
      axiosInstance
        .post(apiUrl, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          Notification.success(res.data.message);
          props.handleAddRoomModalClose();
          props.setRoomData((prevValue) => {
            const selectedZone = zoneDropdownList.filter(
              (zone) => zone.zoneId === values.zoneId
            )[0];
            const newRoom: RoomDataInterfaceV2 = {
              ...values,
              roomCode: values.roomCode,
              roomTypeName: values.roomTypeName,
              zoneId: selectedZone.zoneId,
              zone: selectedZone.zoneName,
              remarks: values.remarks,
            };
            return [newRoom, ...prevValue];
          });
        })
        .catch((res) => {
          Notification.failed(res.response.data.message);
        });
    },
  });

  useEffect(() => {
    setZoneDropdownList([]);
  }, []);

  useEffect(() => {
    const roomTypeSelected = formik.values.roomTypeName;
    if (roomTypeSelected === "") {
      setGetZoneUrl("/rooms/zone/get-all-zone/");
      return;
    }
    setGetZoneUrl(
      `/rooms/zone/get-all-zone?specificTypeName=${roomTypeSelected}`
    );
  }, [formik.values.roomTypeName, formik.initialValues.roomTypeName]);

  useEffect(() => {
    if (getZoneUrl === "/rooms/zone/get-all-zone/") {
      setZoneDropdownList([]);
      return;
    }
    setZoneDropdownList(zones);
  }, [zones]);

  useEffect(() => {
    if (props.selectedRoom) setRoomData(props.selectedRoom);
  }, [props.selectedRoom]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    console.log(formik.values.quietZone);
  }, [formik.values.quietZone]);
  return (
    <Modal
      open={props.addOrEditRoomModalOpen}
      onClose={props.handleAddRoomModalClose}
    >
      <Box
        width={"350px"}
        maxHeight={"600px"}
        minWidth={"38em"}
        border={`3px solid ${theme.palette.primary.main}`}
        alignItems={"center"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
          borderRadius: 1,
        }}
      >
        <ManagementModalHeader
          title={props.selectedRoom ? "Edit Room" : "New Room"}
          handleModalClose={props.handleAddRoomModalClose}
        />
        <form onSubmit={formik.handleSubmit}>
          <Stack padding={3} spacing={2} width={"100%"}>
            <Typography variant="h2" marginBottom={2}>
              {props.selectedRoom ? "Edit Room" : "New Room"}
            </Typography>
            <Stack direction={"row"} alignItems="center">
              <Typography variant="h4" width={"30%"}>
                Room Code
              </Typography>
              <TextField
                size="small"
                name="roomCode"
                value={formik.values.roomCode}
                onChange={formik.handleChange}
                sx={{
                  border: 2,
                  borderColor: theme.palette.primary.main,
                  borderRadius: "5px",
                  width: "70%",
                }}
              />
            </Stack>
            <Stack direction={"row"} alignItems="center">
              <Typography variant="h4" width={"30%"}>
                Type
              </Typography>
              <Select
                size="small"
                name="roomTypeName"
                value={formik.values.roomTypeName}
                onChange={(event) => {
                  formik.setFieldValue("roomTypeName", event.target.value);
                  formik.setFieldValue("zoneId", "");
                }}
                sx={{
                  border: 2,
                  borderColor: theme.palette.primary.main,
                  borderRadius: "5px",
                  width: "70%",
                }}
              >
                {roomTypes.map((roomType, index) => (
                  <MenuItem key={index} value={roomType.typeName}>
                    {roomType.typeName}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Stack direction={"row"} alignItems="center">
              <Typography variant="h4" width={"30%"}>
                Zone
              </Typography>
              <Tooltip title="Select Room Type first before assigning Room Zone">
                <Select
                  size="small"
                  name="zoneId"
                  value={formik.values.zoneId}
                  onChange={formik.handleChange}
                  disabled={
                    isLoadingRoomZoneFetch || formik.values.roomTypeName === ""
                  }
                  sx={{
                    border: 2,
                    borderColor: theme.palette.primary.main,
                    borderRadius: "5px",
                    width: "70%",
                  }}
                >
                  {zoneDropdownList.map((zone, index) => (
                    <MenuItem value={zone.zoneId}>{zone.zoneName}</MenuItem>
                  ))}
                </Select>
              </Tooltip>
            </Stack>
            <Stack direction={"row"} alignItems="center">
              <Typography variant="h4" width={"30%"}>
                Quiet Zone
              </Typography>
              <Switch
                checked={formik.values.quietZone}
                onChange={(event) => {
                  formik.setFieldValue("quietZone", event.target.checked);
                }}
              />{" "}
            </Stack>
            <Stack direction={"row"} alignItems="center">
              <Typography variant="h4" width={"30%"}>
                Lower | Upper
              </Typography>
              <Switch
                checked={formik.values.isUpper}
                onChange={(event) => {
                  formik.setFieldValue("isUpper", event.target.checked);
                }}
              />{" "}
            </Stack>
            <Stack direction={"row"} alignItems="center">
              <Typography variant="h4" width={"30%"}>
                Remarks
              </Typography>
              <TextField
                size="medium"
                multiline
                rows={5}
                name="remarks"
                value={formik.values.remarks}
                onChange={formik.handleChange}
                sx={{
                  border: 2,
                  borderColor: theme.palette.primary.main,
                  borderRadius: "5px",
                  width: "70%",
                  ".MuiInputBase-root": {
                    padding: 0,
                  },
                  ".MuiInputBase-input": {
                    padding: 1,
                  },
                }}
              />
            </Stack>
            <Stack
              justifyContent="flex-end"
              alignItems="flex-end"
              paddingTop={1}
            >
              <Button variant="outlined" type={"submit"}>
                Save
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

function useGetRoomTypesHook(url: string, token: string) {
  const [roomTypes, setRoomTypes] = useState<IRoomType[]>([]);
  const [isLoadingRoomTypeFetch, setIsLoadingRoomTypeFetch] =
    useState<boolean>(false);
  useEffect(() => {
    setIsLoadingRoomTypeFetch(true);

    const request = () => {
      axiosInstance
        .get(url, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.status === 200) {
            setRoomTypes(res.data);
          }
          if (res.status === 409) {
            alert("Try Again");
          }
        })
        .catch((e) => {
          Notification.failed(e.response.data.message);
        })
        .finally(() => {
          setIsLoadingRoomTypeFetch(false);
        });
    };
    request();
  }, []);
  return { roomTypes, isLoadingRoomTypeFetch };
}

function useGetRoomZonesHook(url: string, token: string) {
  const [zones, setZones] = useState<ZoneInterface[]>([]);
  const [isLoadingRoomZoneFetch, setIsLoadingRoomZoneFetch] =
    useState<boolean>(false);
  useEffect(() => {
    setIsLoadingRoomZoneFetch(true);

    const request = () => {
      axiosInstance
        .get(url, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.status === 200) {
            setZones(res.data.data);
          }
          if (res.status === 409) {
            alert("Try Again");
          }
        })
        .catch((e) => {
          Notification.failed(e.response.data.message);
        })
        .finally(() => {
          setIsLoadingRoomZoneFetch(false);
        });
    };
    request();
  }, [url]);
  return { zones, isLoadingRoomZoneFetch };
}

export default AddOrEditRoomModal;
