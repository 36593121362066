import { Grid, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IBookingRowInfo } from "../../types/ganttChart";
import LeftColumnRoomItem from "./LeftColumnRoomItem";
import theme from "../../assets/theme/theme";

type Props = {
  measurements: {
    leftColumnWidth: number;
    leftColumnItemWidth: number;
    itemHeight: string;
    xAxisHeight: string;
    oddNumColor: string;
  };

  displayDate: Date;
  ganttChartInfos: IBookingRowInfo[];
  addBookingPeriodToRow: (
    leftColumnItemIndex: number,
    roomCode: string,
    roomZone: string,
    roomType: string
  ) => void;
  addBookingDraft: (
    roomCode: string,
    roomZone: string,
    roomTypeId: string,
    roomTypeName: string,
    roomTypeDetails: string,
    colorCode: string,
    roomID: string,
    maxPax: number
  ) => void;
  isLoading: boolean;
};

const LeftColumn = React.forwardRef(
  (
    {
      measurements: {
        leftColumnWidth,
        leftColumnItemWidth,
        itemHeight,
        xAxisHeight,
        oddNumColor,
      },

      displayDate,
      ganttChartInfos,
      addBookingPeriodToRow,
      addBookingDraft,
      isLoading,
    }: Props,
    leftColumnRef: React.ForwardedRef<HTMLDivElement | null>
  ) => {
    const [skeletonNumbers, setSkeletonNumber] = useState<number>(9);

    useEffect(() => {
      setSkeletonNumber(ganttChartInfos.length);
    }, [ganttChartInfos]);

    return (
      <>
        <Grid
          container
          ref={leftColumnRef}
          spacing={0}
          alignContent={"center"}
          sx={{
            width: `${leftColumnWidth}em`,
            height: xAxisHeight,
            backgroundColor: oddNumColor,
            paddingRight: "1em",
            position: "absolute",
            borderBottom: `3px solid ${theme.palette.primary.main}`,
          }}
          zIndex={100}
          marginTop={2}
          // for some reason the buttonbase in LeftColumnRoomItem is at the fron of the date
          // so i added this zIndex to make the date on top of the buttonbase
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              width: `${leftColumnItemWidth}em`,
              height: "100px",
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontWeight: 600,
              }}
            >
              {`${displayDate.toLocaleString("default", {
                month: "long",
              })} ${displayDate.getDate()} ${displayDate.getFullYear()}`}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          alignContent={"start"}
          sx={{
            width: `${leftColumnWidth}em`,
            minHeight: "42em",
            backgroundColor: oddNumColor,
            paddingTop: xAxisHeight,
            paddingRight: "1em",
            zIndex: 1,
            marginTop: 2,
          }}
        >
          {!isLoading
            ? ganttChartInfos.map(
                (
                  {
                    roomCode,
                    roomId,
                    roomZone,
                    roomType,
                    colorCode,
                    roomStatus,
                    roomTypeName,
                    roomTypeDetails,
                    maxPax,
                  },
                  index
                ) => (
                  <LeftColumnRoomItem
                    addBookingPeriodToRow={addBookingPeriodToRow}
                    addBookingDraft={addBookingDraft}
                    key={roomId}
                    measurements={{
                      oddNumColor,
                      leftColumnItemWidth,
                      itemHeight,
                    }}
                    roomID={roomId}
                    roomCode={roomCode}
                    roomZone={roomZone}
                    roomType={roomType}
                    roomStatus={roomStatus}
                    colorCode={colorCode}
                    index={index}
                    roomTypeName={roomTypeName}
                    roomTypeDetails={roomTypeDetails}
                    maxPax={maxPax}
                  />
                )
              )
            : (() => {
                const skeletons = [];

                for (let i = 0; i < skeletonNumbers; i++) {
                  skeletons.push(
                    <Grid
                      item
                      rowGap={0}
                      rowSpacing={0}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{
                        backgroundColor: oddNumColor,
                        height: itemHeight,
                        width: `${leftColumnItemWidth}em`,
                        padding: "0.5em",
                      }}
                      key={`skeleton-${i}`}
                    >
                      <Skeleton
                        variant="rounded"
                        sx={{
                          backgroundColor: "grey",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </Grid>
                  );
                }

                return <>{skeletons}</>;
              })()}
        </Grid>
      </>
    );
  }
);

export default LeftColumn;
