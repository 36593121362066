import {
  Grid,
  Typography,
  Divider,
  IconButton,
  Switch,
  Button,
  Box,
  TextField,
  Modal,
  Stack,
} from "@mui/material";
import theme from "../../assets/theme/theme";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { SetStateAction, useEffect, useMemo, useState } from "react";
import CustomTable from "../global/table/CustomTable";
import { SketchPicker } from "react-color";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Done } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import CreateBookingSource from "./CreateBookingSource";

interface IBookingSource {
  bookingSource: string;
  platformId: string;
  isOta: boolean;
  archived: boolean;
  colorTags: string;
  canLatePayment: boolean;
  otaCode: string;
}

const BookingSource = () => {
  const user = useSelector((state: RootState) => state.user);

  const config = {
    headers: { Authorization: `Bearer ${user.accessToken}` },
  };
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });

  const [newColor, setNewColor] = useState<string>("");
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] =
    useState<boolean>(false);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [field, setField] = useState<string>("");
  const [changes, setChanges] = useState<any>();
  const [platformData, setPlatformData] = useState<IBookingSource[]>([]);
  const [row, setRow] = useState<IBookingSource | null>(null);
  const [apiCallType, setApiCallType] = useState<string>("");

  const [bookingSource, setBookingSource] = useState<string>("");
  const [otaCode, setOTACode] = useState<string>("");

  const [openCreateBookingSource, setOpenCreateBookingSource] =
    useState<boolean>(false);

  const handleOTAPlatformChange = (row: IBookingSource) => {
    setField("OTA Platform");
    setChanges(row.isOta ? "disabled" : "enabled");
    setRow(row);
    setApiCallType("CHANGE_OTA_PLATFORM");
    openConfirmationModal();
  };
  useEffect(() => {
    if (row && isConfirm === true) {
      let apiURL = "";
      let formData = {};

      // Determine the API URL and form data based on the API call type
      switch (apiCallType) {
        case "CHANGE_OTA_PLATFORM":
          apiURL = "/bookings/v1/platforms/is-ota/";
          formData = {
            platformId: row.platformId,
            isOTA: !row.isOta,
          };
          break;
        case "CHANGE_NAME":
          apiURL = "/bookings/v1/platforms/change-name/";
          formData = {
            platformId: row.platformId,
            platformName: bookingSource,
          };
          break;
        case "CHANGE_OTA_CODE":
          apiURL = "/bookings/v1/platforms/ota-code/";
          formData = {
            platformId: row.platformId,
            otaCode: otaCode,
          };
          break;
        case "CHANGE_COLOR_TAG":
          apiURL = "/bookings/v1/platforms/color-tags/";
          formData = {
            platformId: "83116540-db86-11ee-b56c-9743eedad06b",
            colorTags: newColor,
          };
          break;
        case "CHANGE_LATE_PAYMENT":
          apiURL = "/bookings/v1/platforms/can-late-payment/";
          formData = {
            platformId: row.platformId,
            canLatePayment: !row.canLatePayment,
          };
          break;
        case "CHANGE_ARCHIVE":
          apiURL = "/bookings/v1/platforms/archive/ ";
          formData = {
            platformId: row.platformId,
            archived: !row.archived,
          };
          break;
        default:
          break;
      }

      axiosInstance
        .patch(apiURL, formData, config)
        .then((res) => {
          Notification.success(res.data.message);
          setIsConfirm(false);
          handleCloseConfirmationModal();
        })
        .catch((e) => {
          Notification.failed(e.response.statusText);
          setIsConfirm(false);
          handleCloseConfirmationModal();
        });
    } else if (isConfirm === false) {
      setIsConfirm(false);
      handleCloseConfirmationModal();
    }
  }, [isConfirm]);

  const handleBookingSourceChange = (
    textChange: string,
    row: IBookingSource
  ) => {
    setBookingSource(textChange);
    setField("Booking Source");
    setChanges(textChange);
    setRow(row);
    setApiCallType("CHANGE_NAME");
    openConfirmationModal();
  };
  const handleOTACodeChange = (textChange: string, row: IBookingSource) => {
    setOTACode(textChange);
    setField("OTA Code");
    setChanges(textChange);
    setRow(row);
    setApiCallType("CHANGE_OTA_CODE");
    openConfirmationModal();
  };

  const handleColorChange = (color: string, row: IBookingSource) => {
    setNewColor(color);
    setField("Color Change");
    setChanges(color);
    setRow(row);
    setApiCallType("CHANGE_COLOR_TAG");
    openConfirmationModal();
  };

  const handleArchiveChange = (row: IBookingSource) => {
    setField("Archive");
    setChanges(row.archived ? "Unarchive" : "Archive");
    setRow(row);
    setApiCallType("CHANGE_ARCHIVE");
    openConfirmationModal();
  };

  const handleLatePaymentChange = (row: IBookingSource) => {
    setField("Late Payment");
    setChanges(!row.canLatePayment ? "enabled" : "disabled");
    setRow(row);
    setApiCallType("CHANGE_LATE_PAYMENT");
    openConfirmationModal();
  };

  const openConfirmationModal = () => {
    setIsOpenConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setIsOpenConfirmationModal(false);
  };

  const fetchBookingSource = () => {
    axiosInstance
      .get("/bookings/v1/platforms/platform-list/", config)
      .then((res) => {
        const sortedData = res.data.data
          .sort((a: IBookingSource, b: IBookingSource) => {
            if (a.platformId < b.platformId) {
              return -1;
            }
            if (a.platformId > b.platformId) {
              return 1;
            }
            return 0;
          })
          .sort((a: IBookingSource, b: IBookingSource) => {
            // Sort by archived (true comes first)
            if (a.archived && !b.archived) {
              return 1;
            }
            if (!a.archived && b.archived) {
              return -1;
            }
            return 0;
          });
        setPlatformData(sortedData);
      })

      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchBookingSource();
  }, [isConfirm]);

  const columns = useMemo<MRT_ColumnDef<IBookingSource>[]>(
    () => [
      {
        header: "Booking Source",
        Cell: ({ row }) => (
          <>
            <BoxtoTextFieldProps
              row={row.original}
              text={row.original.bookingSource}
              handleTextChange={handleBookingSourceChange}
            />
          </>
        ),
      },
      {
        header: "OTA Platform",
        Cell: ({ row }) => (
          <>
            <Switch
              checked={row.original.isOta}
              onChange={() => {
                handleOTAPlatformChange(row.original);
              }}
            />
          </>
        ),
      },
      {
        header: "OTA Code",
        Cell: ({ row }) => (
          <>
            <BoxtoTextFieldProps
              row={row.original}
              text={row.original.otaCode}
              handleTextChange={handleOTACodeChange}
            />
          </>
        ),
      },
      {
        header: "Color Tags",
        Cell: ({ row }) => (
          <ColorTagCell
            row={row.original}
            handleColorChange={handleColorChange}
          />
        ),
      },
      {
        header: "Archive",
        Cell: ({ row }) => (
          <Button onClick={() => handleArchiveChange(row.original)}>
            {row.original.archived ? "Unarchive" : "Archive"}
          </Button>
        ),
      },
      {
        header: "canLatePayment",
        Cell: ({ row }) => (
          <Switch
            checked={row.original.canLatePayment}
            onChange={() => {
              handleLatePaymentChange(row.original);
            }}
          />
        ),
      },
    ],
    []
  );

  const handleCloseCreateBookingSource = () => {
    setOpenCreateBookingSource(false);
  };

  if (openCreateBookingSource) {
    return (
      <CreateBookingSource
        handleCloseCreateBookingSource={handleCloseCreateBookingSource}
        setPlatformData={setPlatformData}
      />
    );
  } else {
    return (
      <Box overflow="auto" maxHeight="80vh">
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h2">Booking Source Management</Typography>
          <Button
            variant="outlined"
            onClick={() => setOpenCreateBookingSource(true)}
          >
            Create
          </Button>
        </Stack>
        <Divider
          orientation="horizontal"
          flexItem
          sx={{
            pt: 5,
            mb: 5,
            borderBottomWidth: 3.5,
            borderColor: "#232323",
          }}
        />
        <Grid
          container
          spacing={2}
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
          style={{ position: "relative" }}
        >
          <Grid item xs={12}>
            <CustomTable
              data={platformData}
              columns={columns}
              isLoading={false}
              pagination={pagination}
              handlePaginationChange={setPagination}
            />
          </Grid>
        </Grid>
        {isOpenConfirmationModal && (
          <ConfirmationModal
            field={field}
            changes={changes}
            setConfirm={setIsConfirm}
            handleClose={handleCloseConfirmationModal}
            showConfirmationModal={isOpenConfirmationModal}
          />
        )}
      </Box>
    );
  }
};

interface ColorTagCellProps {
  row: IBookingSource;
  handleColorChange: (newColor: string, row: IBookingSource) => void;
}
const ColorTagCell = ({ row, handleColorChange }: ColorTagCellProps) => {
  const [showModal, setShowModal] = useState(false);
  const [color, setColor] = useState(row.colorTags);

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    handleColorChange(color, row);
  };

  const handleChange = (newColor: { hex: any }) => {
    setColor(newColor.hex);
  };

  return (
    <>
      <Box
        width={65}
        height={30}
        bgcolor={color}
        border={1}
        borderColor="grey.500"
        onClick={handleOpen}
      ></Box>
      <Modal open={showModal} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxWidth: 400,
            minWidth: 200,
            borderRadius: 4,
          }}
        >
          <SketchPicker color={color} onChange={handleChange} />
          <Button onClick={handleClose}>Close</Button>
        </Box>
      </Modal>
    </>
  );
};

interface ConfirmationModalProps {
  setConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  field: string;
  changes: string;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  showConfirmationModal: boolean;
}
const ConfirmationModal = (props: ConfirmationModalProps) => {
  const handleConfirmation = (status: boolean) => {
    props.setConfirm(status);
    props.handleClose(false);
  };
  return (
    <>
      <Box width={65} height={30} border={1} borderColor="grey.500"></Box>
      <Modal open={props.showConfirmationModal} onClose={props.handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxWidth: 400,
            minWidth: 200,
            borderRadius: 4,
          }}
        >
          <Typography>
            Are you sure want to edit {props.field} to be {props.changes}
          </Typography>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              onClick={() => {
                handleConfirmation(true);
              }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                handleConfirmation(false);
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

interface BoxtoTextFieldProps {
  row: IBookingSource;
  text: string;
  handleTextChange: (textChange: string, row: IBookingSource) => void;
}
const BoxtoTextFieldProps = (props: BoxtoTextFieldProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(props.text);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleSubmit = () => {
    // Handle submission logic here
    props.handleTextChange(text, props.row);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setText("");
  };

  return (
    <div>
      {isEditing ? (
        <form onSubmit={handleSubmit}>
          <TextField
            value={text}
            onChange={(e) => setText(e.target.value)}
            autoFocus
          />
          <IconButton type="submit">
            <Done color="primary" />
          </IconButton>
          <IconButton onClick={handleCancel}>
            <CloseIcon color="primary" />
          </IconButton>
        </form>
      ) : (
        <Box
          onClick={handleClick}
          padding={1}
          display="flex"
          alignItems="center"
        >
          <Typography>{props.text}</Typography>
        </Box>
      )}
    </div>
  );
};

export default BookingSource;
