import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ManagementContentArea from "../../components/management/ManagementContentArea";
import ManagementFilterBar from "../../components/management/ManagementFilterBar";
import { IPromotionUsage, IPromotionUsageCount } from "../../types/promotion";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import axios from "axios";
import { RootState, store } from "../../redux/store";
import BASE_API from "../../constants/api";
import PromotionUsageOverviewItem from "../../components/promotion/PromotionUsageOverviewItem";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import theme from "../../assets/theme/theme";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useSelector } from "react-redux";

type Props = {};

// const promotionUsageDummy: IPromotionUsage[] = [
//   {
//     promoUsageId: "abc123",
//     customer: {
//       customerId: "c001",
//       name: "John Doe",
//       email: "john.doe@example.com",
//       phone: "123-456-7890",
//       memberId: "6365f156-ef76-45fc-97f7-ba9533244679",
//       memberTier: "Member",
//     },
//     promotion: {
//       promotionId: "p001",
//       promoCode: "PROMO10",
//     },
//     transaction: {
//       transactionId: "t001",
//       transactionDatetime: "2023-10-13T15:30:00Z",
//     },
//   },
//   {
//     promoUsageId: "def456",
//     customer: {
//       customerId: "c002",
//       name: "Jane Smith",
//       email: "jane.smith@example.com",
//       phone: "987-654-3210",
//       memberId: "6365f156-ef76-45fc-97f7-ba9533244679",
//       memberTier: "None",
//     },
//     promotion: {
//       promotionId: "p002",
//       promoCode: "DISCOUNT20",
//     },
//     transaction: {
//       transactionId: "t002",
//       transactionDatetime: "2023-10-14T09:45:00Z",
//     },
//   },
// ];

const promotionCountDummy: IPromotionUsageCount[] = [
  {
    promotionId: "p001",
    promoCode: "PROMO10",
    seats: 5,
    usedSeats: 1,
  },
  {
    promotionId: "p002",
    promoCode: "DISCOUNT20",
    seats: 10,
    usedSeats: 2,
  },
];

const PromotionUsagePage = (props: Props) => {
  const token = useSelector((state: RootState) => state.user.accessToken);
  const columns = useMemo<MRT_ColumnDef<IPromotionUsage>[]>(
    () => [
      {
        accessorKey: "promotion.promoCode",
        header: "Code",
        size: 200,
      },
      {
        accessorKey: "transaction.invoiceNo",
        header: "Transaction ID",
        size: 200,
      },
      {
        accessorKey: "customer.name",
        header: "Customer Name",
        size: 200,
      },
      {
        accessorKey: "transaction.transactionDatetime",
        header: "Transaction Datetime",
        size: 200,
      },
      {
        accessorKey: "customer.memberTier",
        header: "Member Only",
        size: 200,
      },
    ],
    []
  );

  const [excludeArchive, setExcludeArchive] = useState<boolean>(true);

  const [promotionUsageData, setPromotionUsageData] = useState<
    IPromotionUsage[]
  >([]);
  const [displayPromotionUsage, setDisplayPromotionUsage] = useState<
    IPromotionUsage[]
  >([]);
  const [usageOverview, setUsageOverview] = useState<IPromotionUsageCount[]>(
    []
  );
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 12,
  });

  const [searchQuery, setSearchQuery] = useState<string>("");
  const handleSearchQueryChange = (search: string) => {
    setSearchQuery(search);
  };

  // const fetchAllPromotionUsage = async () => {
  //   await axios
  //     .get(`${BASE_API}/promotion/usage`, {
  //       params: {
  //         search: searchQuery,
  //       },
  //       headers: {
  //         Authorization: `Bearer ${store.getState().user.accessToken}`,
  //       },
  //     })
  //     .then((res) => {
  //       setPromotionUsageData(res.data);
  //     });
  // };

  const fetchAllPromotionUsage = () => {
    axiosInstance
      .get("/promotion-usage/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const newData = res.data.map((item: any, index: string) => {
          const individualData: IPromotionUsage = {
            promoUsageId: item.promoUsageId,
            customer: {
              customerId: index,
              name: item.customer.firstname + " " + item.customer.lastname,
              email: item.customer.email,
              phone: item.customer.phone,
              memberId: item.customer.memberId,
              memberTier: item.customer.member.memberTier,
            },
            promotion: {
              promotionId: item.promotion.promotionId,
              promoCode: item.promotion.promoCode,
              seats: item.promotion.seats,
            },
            transaction: {
              invoiceNo: item.transaction.invoiceNo,
              transactionDatetime: item.transaction.transactionDatetime,
            },
          };
          return individualData;
        });
        setPromotionUsageData(newData);
      })
      .catch((e) => {
        Notification.failed(e.toString());
      });
  };

  useEffect(() => {
    fetchAllPromotionUsage();
  }, []);

  const countInvalidPromotion = (property: string) => {
    return promotionUsageData.reduce((count, object) => {
      if (object.promotion.promotionId === property) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  useEffect(() => {
    const uniqueIds = new Set(
      promotionUsageData.map((item) => item.promotion.promoCode)
    );
    const uniqueObjects = Array.from(uniqueIds, (id) =>
      promotionUsageData.find((item) => item.promotion.promoCode === id)
    );
    const promoOverview = uniqueObjects.map((item: any) => {
      const newCount = {
        promotionId: item.promotion.promotionId,
        promoCode: item.promotion.promoCode,
        seats: item.promotion.seats,
        usedSeats: countInvalidPromotion(item.promotion.promotionId),
      };
      return newCount;
    });
    setUsageOverview(promoOverview);
    setDisplayPromotionUsage(promotionUsageData);
  }, [promotionUsageData]);

  return (
    <Box
      width={"80vw"}
      minWidth={"80em"}
      height={"fit-content"}
      sx={{
        postition: "absolute",
        inset: "0",
        marginX: "auto",
      }}
    >
      <Box width={"100%"} display={"flex"}>
        <Box
          width={"18%"}
          height={"100px"}
          border={`3px solid ${theme.palette.primary.main}`}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <FilterAltOutlinedIcon
            sx={{
              fontSize: "2rem",
              color: "white",
            }}
          />
          <Typography variant={"h3"}>Filter</Typography>
        </Box>
        <Stack width={"82%"} direction={"column"}>
          <Box
            width={"100%"}
            height={"50px"}
            border={`3px solid ${theme.palette.primary.main}`}
            borderLeft={"none"}
            borderBottom={"none"}
            display={"flex"}
            alignItems={"center"}
            padding={2}
          >
            <Typography>Archived</Typography>
          </Box>
          <Box
            width={"100%"}
            height={"50px"}
            border={`3px solid ${theme.palette.primary.main}`}
            borderLeft={"none"}
            display={"flex"}
            alignItems={"center"}
            bgcolor={theme.palette.background.default}
          >
            <Select
              size="small"
              value={excludeArchive}
              onChange={(event) => {
                setExcludeArchive(event.target.value === "true");
              }}
              sx={{
                width: "100%",
                ".MuiSelect-select": {
                  border: 0,
                  borderColor: theme.palette.primary.main,
                  borderRadius: 0,
                  // borderBottom:0,
                  borderRight: 0,
                  borderLeft: 0,
                },
                ".MuiSelect-icon": {
                  fill: "white",
                },
              }}
            >
              <MenuItem value={"true"}>Exclude</MenuItem>
              <MenuItem value={"false"}>Include</MenuItem>
            </Select>
          </Box>
        </Stack>
      </Box>
      <ManagementContentArea<IPromotionUsage, IPromotionUsageCount>
        title={"Promotion"}
        tableItems={displayPromotionUsage}
        overviewItems={usageOverview}
        OverviewColumnItem={PromotionUsageOverviewItem}
        columns={columns}
        pagination={pagination}
        setPagination={setPagination}
        searchQuery={searchQuery}
        handleSearchQueryChange={handleSearchQueryChange}
        fetchAllItems={fetchAllPromotionUsage}
      />
    </Box>
  );
};

export default PromotionUsagePage;
