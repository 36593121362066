import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrayHelpers, FieldArray, Form, Formik } from "formik";
import { Add } from "@mui/icons-material";
import {
  IPaymentGuests,
  IPaymentItem,
} from "../../models/payment/PaymentInterface";
import { IBookingDraft } from "../../models/bookings/Booking";
import { useEffect, useState } from "react";

interface POAFormProps {
  openPOAModal: boolean;
  handlePOAModalClose: VoidFunction;
  availableItem?: IPaymentItem[];
  handleAddPOA: (roomBookings: IPaymentItem[], customerId: string) => void;
  guests: IPaymentGuests;
}

const POAForm = ({
  openPOAModal,
  handlePOAModalClose,
  availableItem,
  handleAddPOA,
  guests,
}: POAFormProps) => {
  const initialValue: { items: string[]; customer: string } = {
    items:
      availableItem && availableItem.length ? [availableItem[0].itemName] : [],
    customer: guests.customerStayingId,
  };
  return (
    <Dialog open={openPOAModal} onClose={handlePOAModalClose}>
      <DialogTitle
        sx={{
          backgroundColor: "#232323",
        }}
      >
        Choose Item
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: "#232323",
          width: "300px",
        }}
      >
        <Formik
          initialValues={initialValue}
          onSubmit={(values) => {
            handleAddPOA(
              availableItem!.filter((room) =>
                values.items.includes(room.itemName)
              ),
              values.customer
            );
            handlePOAModalClose();
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Stack>
                <Typography variant="h5" sx={{ marginTop: 2, marginBottom: 1 }}>
                  Select Assigned Guest
                </Typography>
                <Select
                  fullWidth
                  value={values.customer}
                  name={"customer"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={{ marginBottom: "10px" }}
                >
                  {guests.guests.map((booking, index) => (
                    <MenuItem key={index} value={booking.guestId}>
                      {booking.guestName}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="h5" sx={{ marginTop: 2 }}>
                  Select Item
                </Typography>
                <FieldArray
                  name={"items"}
                  render={({ push, remove }: ArrayHelpers) => (
                    <>
                      {values.items.map((equipment, index) => (
                        <Select
                          fullWidth
                          key={index}
                          value={values.items[index]}
                          name={`items[${index}]`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          sx={{
                            marginBottom: "10px",
                          }}
                        >
                          {availableItem!.map((room, index) => (
                            <MenuItem key={index} value={room.itemName}>
                              {room.itemName} - {room.itemType}
                            </MenuItem>
                          ))}
                        </Select>
                      ))}
                      {values.items.length < availableItem!.length && (
                        <Tooltip title="Add Room Field">
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              push("");
                            }}
                            sx={{ height: "30px" }}
                          >
                            <Add />
                          </Button>
                        </Tooltip>
                      )}
                    </>
                  )}
                />
                <FormHelperText error>
                  Make sure items are different
                </FormHelperText>
                <Button type="submit" sx={{ marginLeft: "auto" }}>
                  Submit
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default POAForm;
