import {
  Grid,
  Typography,
  Divider,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import theme from "../../assets/theme/theme";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useState } from "react";
// Define an interface for the settings data
interface Setting {
  settingsId: number;
  settingsName: string;
  settingsCategory: string;
  settingsDescription: string;
  creationDatetime: string;
  lot: number;
}
interface SpecialPaymentProps {
  SpecialPaymentCategory: Setting[];
  SpecialPaymentName?: string;
  SpecialPaymentDescription?: string;
  onSpecialPaymentVisibilityChange: (
    isVisible: boolean,
    settingsId?: number
  ) => void;
}

export default function SpecialPayment({
  SpecialPaymentCategory,
  SpecialPaymentName,
  SpecialPaymentDescription,
  onSpecialPaymentVisibilityChange,
}: SpecialPaymentProps) {
  const [showSpecialPaymentDetail, setShowSpecialPaymentDetail] =
    useState(false);

  const handleButtonClick = (settingId?: number) => {
    setShowSpecialPaymentDetail(true);

    onSpecialPaymentVisibilityChange(true, settingId);
  };

  return (
    <>
      <Grid container padding={5}>
        <Grid item xs={10} padding={2}>
          <Typography variant="h3">Special Payment</Typography>
        </Grid>
        <Grid item xs={2} padding={2}>
          <Button variant="outlined" onClick={() => handleButtonClick(0)}>
            Create New
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              borderBottomWidth: 3.5,
              borderColor: "#232323",
            }}
          />
        </Grid>

        {SpecialPaymentCategory.map((specialPaymentSetting) => (
          <Grid
            container
            key={specialPaymentSetting.settingsId}
            padding={3}
            spacing={1}
          >
            <Grid item xs={11}>
              <Typography variant="h4">
                {specialPaymentSetting.settingsName}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                disabled={["Complimentary", "Recovery Services"].includes(
                  specialPaymentSetting.settingsName
                )}
                onClick={() =>
                  handleButtonClick(specialPaymentSetting.settingsId)
                }
              >
                <SettingsOutlinedIcon
                  sx={{
                    color: ["Complimentary", "Recovery Services"].includes(
                      specialPaymentSetting.settingsName
                    )
                      ? "grey"
                      : theme.palette.primary.main,
                  }}
                />
              </IconButton>
            </Grid>
            <Grid item xs={11}>
              {specialPaymentSetting.settingsDescription}
            </Grid>

            <Grid item xs={12}>
              <Divider
                orientation="horizontal"
                flexItem
                sx={{
                  borderBottomWidth: 3.5,
                  borderColor: "#232323",
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
