import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

// Removed the data array

interface MediaProps {
  loading?: boolean;
}

function Media(props: MediaProps) {
  const { loading = false } = props;

  return (
    <Grid container wrap="nowrap">
      {(loading ? Array.from(new Array(3)) : []).map((_, index) => (
        <Box
          key={index}
          sx={{ width: 360, marginRight: 0.5, my: 5, backgroundColor: "black" }}
        >
          <Skeleton
            variant="rectangular"
            width={360}
            height={236}
            sx={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}
          />
          <Box sx={{ pr: 2, color: "white" }}>
            <Skeleton sx={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }} />
            <Skeleton
              width="60%"
              sx={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}
            />
          </Box>
        </Box>
      ))}
    </Grid>
  );
}

export default function SkeletonLoading() {
  return (
    <Box sx={{ overflow: "hidden", backgroundColor: "black", padding: "20px" }}>
      <Media loading />
    </Box>
  );
}
