import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PrintIcon from "@mui/icons-material/Print";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import theme from "../../assets/theme/theme";
import CheckinSlip from "./CheckinSlip";
import { useReactToPrint } from "react-to-print";
import { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  BookingResult,
  BookingStatus,
  RoomBooking,
} from "../../pages/booking/interface";
import axiosInstance from "../../constants/axiosConfig";
import { store } from "../../redux/store";
import Notification from "../../utils/notificationConfig";

type Props = {
  handleDocumentModalClose: () => void;
  handleBack: () => void;
  bookingData: BookingResult;
};

export interface IWifi {
  name: string;
  password: string;
}

export default function CheckinSlipPrintModal({
  handleDocumentModalClose,
  handleBack,
  bookingData,
}: Props) {
  const token = store.getState().user.accessToken;
  const accountId = store.getState().user.accountId;
  const componentRef = useRef<any>([]);
  const [selectedSlipIndex, setSelectedSlipIndex] = useState<number>(0);
  const [wifiCreds, setWifiCreds] = useState<IWifi>({ name: "", password: "" });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current[selectedSlipIndex]!,
    pageStyle: `
    @page {
      size: 78mm auto; 
      margin: 0;
    }
    body {
      margin: 0;
    }
  `,
  });

  const handleDownload = () => {
    if (componentRef.current[selectedSlipIndex]) {
      const content = componentRef.current[selectedSlipIndex];

      html2canvas(content)
        .then((canvas) => {
          const contentWidth = 78;
          const contentHeight = (canvas.height * contentWidth) / canvas.width;
          const pdf = new jsPDF("p", "mm", [contentWidth, contentHeight]);
          pdf.addImage(
            canvas.toDataURL("image/png"),
            "PNG",
            0,
            0,
            contentWidth,
            contentHeight
          ); // Adjust the size as needed
          pdf.save(
            `${bookingData.roomBookings[selectedSlipIndex].roomCode}_Check_In_Slip.pdf`
          );
        })
        .catch((error) => {
          console.error("Error converting to canvas:", error);
        });
    }
  };

  const getDuration = (bookingStatus: BookingStatus[]) => {
    const lastBookingStatus = bookingStatus[bookingStatus.length - 1];
    const checkInDate = new Date(lastBookingStatus.checkInDatetime);
    const checkOutDate = new Date(lastBookingStatus.checkOutDatetime);
    const timeDifferenceMili = checkOutDate.getTime() - checkInDate.getTime();

    const hoursDifference = timeDifferenceMili / (1000 * 60 * 60);

    return hoursDifference;
  };

  const getCheckInOutTime = () => {
    const bookedStatus = bookingData.bookingStatus.filter(
      (status) => status.isLatest
    )[0];

    if (bookedStatus) {
      return [bookedStatus.checkInDatetime, bookedStatus.checkOutDatetime];
    }
    return ["-", "-"];
  };

  const fetchSettings = () => {
    axiosInstance
      .get(`/lot-settings/get-settings/${accountId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const wifiName = response.data.data.find(
          (data: any) => data.settingsName === "Wifi Name"
        ).settingsDescription;
        const wifiPassword = response.data.data.find(
          (data: any) => data.settingsName === "Wifi Password"
        ).settingsDescription;
        setWifiCreds({
          name: wifiName,
          password: wifiPassword,
        });
      })
      .catch((res) => {
        Notification.failed("Error in retrieving Wifi name and password");
      });
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <Grid container spacing={0} sx={{ p: 0, width: "100%", height: 700 }}>
      <Grid item xs={11}>
        <Stack
          display={"flex"}
          sx={{
            p: 2,
            border: 0,
            borderColor: "#232323",
            height: "50px",
            justifyContent: "center",
          }}
        >
          <Typography variant="h2" color={theme.palette.primary.main}>
            Documents
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={1}>
        <Stack
          display={"flex"}
          sx={{
            p: 1,
            border: 0,
            borderColor: theme.palette.primary.main,
            height: "50px",
            justifyContent: "center",
          }}
        >
          <IconButton onClick={handleDocumentModalClose} sx={{ padding: 0 }}>
            <HighlightOffIcon fontSize="large" sx={{ color: "#ff4d56" }} />
          </IconButton>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Divider
          orientation="horizontal"
          variant="fullWidth"
          flexItem
          sx={{
            borderBottomWidth: 2.5,
            borderColor: theme.palette.primary.main,
          }}
        />
      </Grid>

      <Grid item xs={2} p={2}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
          sx={{ m: 1 }}
        >
          <Typography variant="button">{"Back"}</Typography>
        </Button>
      </Grid>
      <Grid
        item
        xs={10}
        p={2}
        container
        direction={"row"}
        alignContent={"end"}
        justifyContent={"end"}
      >
        <Button
          startIcon={<FileDownloadIcon />}
          variant="outlined"
          color="primary"
          sx={{ m: 1 }}
          onClick={handleDownload}
        >
          <Typography variant="button">Export</Typography>
        </Button>
        <Button
          startIcon={<PrintIcon />}
          variant="outlined"
          color="primary"
          sx={{ m: 1 }}
          onClick={handlePrint}
        >
          <Typography variant="button">Print</Typography>
        </Button>
      </Grid>

      <Grid
        container
        direction={"row"}
        alignContent={"center"}
        justifyContent={"center"}
        spacing={2}
        sx={{ backgroundColor: "#232323", justifyContent: "center", p: 2 }}
      >
        {bookingData.roomBookings.map((roomBooking, index) => (
          <Grid item key={index} xs={12} sm={12} md={6} lg={6} xl={4}>
            <Box
              padding={1}
              sx={{
                border: selectedSlipIndex === index ? 2 : 0,
                width: "83mm",
                borderColor: theme.palette.primary.main,
              }}
            >
              <ButtonBase
                ref={(el) => (componentRef.current[index] = el)}
                onClick={() => setSelectedSlipIndex(index)}
              >
                <CheckinSlip
                  value={roomBooking}
                  bookingNo={bookingData.bookingNo}
                  duration={getDuration(bookingData.bookingStatus)}
                  checkInDatetime={getCheckInOutTime()[0]}
                  checkOutDatetime={getCheckInOutTime()[1]}
                  lockerCode={
                    bookingData.lockerBookings.length > 0
                      ? bookingData.lockerBookings[index].locker.code
                      : "-"
                  }
                  wifiCreds={wifiCreds}
                />
              </ButtonBase>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
