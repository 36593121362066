import {
  Typography,
  ButtonBase,
  Grid,
  TextField,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import TableContentArea from "./TableContentArea";
import React from "react";
import theme from "../../assets/theme/theme";

type Props<T extends Record<string, any>> = {
  title: string;
  items: T[];
  handleAddItemModalOpen?: () => void;
  columns: MRT_ColumnDef<T>[];
  pagination: MRT_PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<MRT_PaginationState>>;
  searchQuery: string;
  handleSearchQueryChange: (search: string) => void;
  fetchAllItems: () => void;
  onlyContent?: boolean;
};

const TableColumn = <T extends Record<string, any>>({
  title,
  items,
  handleAddItemModalOpen,
  columns,
  pagination,
  setPagination,
  searchQuery,
  handleSearchQueryChange,
  fetchAllItems,
  onlyContent,
}: Props<T>) => {
  return (
    <Box
      width={onlyContent ? "100%" : "82%"}
      height={"750px"}
      minHeight={"44em"}
      border={`3px solid ${theme.palette.primary.main}`}
      borderLeft={
        onlyContent ? `3px solid ${theme.palette.primary.main}` : "none"
      }
    >
      <Box //table header
        height={"6vh"}
        minHeight={"3em"}
        paddingY={1}
        paddingX={2}
        sx={{
          backgroundColor: theme.palette.background.default,
        }}
        borderBottom={`3px solid ${theme.palette.primary.main}`}
        position={"relative"}
      >
        <Typography variant={"h2"} color={theme.palette.primary.main}>
          {`${title} (${items.length})`}
        </Typography>
        {handleAddItemModalOpen && (
          <ButtonBase
            onClick={() => handleAddItemModalOpen()}
            style={{
              height: "100%",
              padding: "0 1rem",
              alignSelf: "center",
              justifySelf: "end",
              borderRadius: "0",
              position: "absolute",
              top: "0",
              right: "0",
              borderLeft: `3px solid ${theme.palette.primary.main}`,
            }}
          >
            <Typography variant={"h3"} color={theme.palette.primary.main}>
              {`+ Add ${title}`}
            </Typography>
          </ButtonBase>
        )}
      </Box>
      <Grid container height={"74vh"} minHeight={"41em"}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          borderBottom={`3px solid ${theme.palette.primary.main}`}
          height={"9vh"}
          paddingY={2}
          paddingX={2}
        >
          <TextField
            onChange={({ target: { value } }) => handleSearchQueryChange(value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") fetchAllItems();
            }}
            fullWidth
            sx={{
              height: "3em",
            }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => fetchAllItems()}>
                  <SearchRoundedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </IconButton>
              ),
              sx: {
                height: "3em",
                paddingRight: "0",
                backgroundColor: theme.palette.background.default,
                borderColor: theme.palette.primary.main,
              },
            }}
            inputProps={{
              style: {
                height: "3em",
                padding: "0 1em",
                border: "none",
              },
              placeholder: "Search Here",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} height={"100%"}>
          <TableContentArea<T>
            items={items}
            columns={columns}
            pagination={pagination}
            setPagination={setPagination}
            searchQuery={searchQuery}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TableColumn;
