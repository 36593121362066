import { useEffect, useState } from "react";
import { store } from "../../redux/store";
import axiosInstance from "../../constants/axiosConfig";
import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { Formik, useFormik } from "formik";
import ManagementModalFormNumberField from "../management/ManagementModalFormNumberField";
import Notification from "../../utils/notificationConfig";
import ManagementModalFormTextField from "../management/ManagementModalFormTextField";
import TaxCharges from "./TaxCharges";

interface TaxChargesProps {
  TaxCharges?: string | undefined;
  onTaxChargesVisibilityChange: (
    isVisible: boolean,
    settingsId?: number
  ) => void;
  TaxChargesId?: number;
  TaxName?: string | undefined;
}

export default function NewTaxChargesDetail({
  TaxCharges,
  onTaxChargesVisibilityChange,
  TaxChargesId,
  TaxName,
}: TaxChargesProps) {
  const [showRoomSettingsDetail, setShowRoomSettingsDetail] = useState(false);
  const [initialTaxName, setInitialTaxName] = useState<string | undefined>(
    TaxName
  );
  const [initialTaxCharge, setInitialTaxCharge] = useState<number>(
    Number(TaxCharges)
  );

  useEffect(() => {
    setInitialTaxName(TaxName);
    setInitialTaxCharge(Number(TaxCharges));
  }, [TaxName, TaxCharges]);
  const handleButtonClick = () => {
    setShowRoomSettingsDetail(false);

    // Pass the boolean value to the parent component
    onTaxChargesVisibilityChange(false);
  };

  const [TaxNames, setTaxName] = useState<string>();
  const [TaxCharge, setTaxCharge] = useState<number>(0);

  interface IRoomTaxChargesDetail {
    TaxName: string | undefined;
    TaxCharge: number;
  }

  const formikInitialValue: IRoomTaxChargesDetail = {
    TaxName: TaxName,
    TaxCharge: Number(TaxCharges),
  };

  useEffect(() => {
    setTaxName(TaxName);
    setTaxCharge(Number(TaxCharges));
  }, []);

  const token = store.getState().user.accessToken;

  const submitAdditionalTax = (tax: number, taxName: string | undefined) => {
    if (TaxChargesId) {
      const payload = {
        settingsId: TaxChargesId,
        newDescription: tax,
      };
      axiosInstance
        .put(`/lot-settings/edit-settings-description/`, payload, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          Notification.success("Settings updated successfully");
          console.log("Settings updated successfully:", response.data);
        })
        .catch((error) => {
          console.error("Error updating settings:", error);
        });
    } else {
      const payload = {
        settingsCategory: "Additional Tax",
        settingsName: taxName,
        settingsDescription: String(tax),
      };
      axiosInstance
        .post(`/lot-settings/create-settings/`, payload, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          Notification.success("Settings Created successfully");
          console.log("Settings Created successfully:", response.data);
        })
        .catch((error) => {
          console.error("Error Created settings:", error);
        });
    }
  };

  function handleSubmit(values: IRoomTaxChargesDetail) {
    submitAdditionalTax(values.TaxCharge, values.TaxName);
  }

  return (
    <>
      <Grid container padding={5} spacing={2}>
        <Grid item xs={1}>
          <Button onClick={handleButtonClick}>Back</Button>
        </Grid>
        <Grid item xs={11} marginBottom={2}>
          <Typography variant="h3">
            {TaxChargesId ? "Edit" : "New"} Tax
          </Typography>
        </Grid>
        <Formik
          initialValues={formikInitialValue}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid item xs={12} px={2}>
                <ManagementModalFormTextField
                  label="Tax Name"
                  value={values.TaxName || ""}
                  name={"TaxName"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  textFieldType={"string"}
                />
              </Grid>
              <Grid item xs={12} px={2}>
                <ManagementModalFormNumberField
                  label="Tax Percentage"
                  value={values.TaxCharge || 0}
                  name={"TaxCharge"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  textFieldType={"number"}
                  endAdornment={
                    <Typography fontWeight={600} paddingLeft={"5px"}>
                      %
                    </Typography>
                  }
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid
                item
                xs={12}
                container
                justifyContent="flex-end"
                alignItems="flex-end"
                py={2}
                padding={1}
              >
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </>
  );
}
