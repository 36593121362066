import {
  Box,
  Button,
  ButtonBase,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { statusEnum, handleRoomTypeCategory } from "../../constants/enums";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import HardwareIcon from "@mui/icons-material/Hardware";
import ColorCode from "../../utils/colorCodeConfig";
import Notification from "../../utils/notificationConfig";

type Props = {
  measurements: {
    oddNumColor: string;
    leftColumnItemWidth: number;
    itemHeight: string;
  };
  roomCode: string;
  roomZone: string;
  roomType: string;
  roomStatus: string;
  colorCode: string;
  addBookingPeriodToRow: (
    leftColumnItemIndex: number,
    roomCode: string,
    roomZone: string,
    roomType: string
  ) => void;
  addBookingDraft: (
    roomCode: string,
    roomZone: string,
    roomTypeId: string,
    roomTypeName: string,
    roomTypeDetails: string,
    colorCode: string,
    roomID: string,
    maxPax: number
  ) => void;
  roomID: string;
  index: number;
  roomTypeName: string;
  roomTypeDetails: string;
  maxPax: number;
};

const LeftColumnRoomItem = ({
  measurements: { oddNumColor, leftColumnItemWidth, itemHeight },
  roomCode,
  roomZone,
  roomType,
  roomStatus,
  colorCode,
  roomID,
  addBookingPeriodToRow,
  addBookingDraft,
  index,
  roomTypeName,
  roomTypeDetails,
  maxPax,
}: Props) => {
  const handleBackgroundColorRoomStatus = useCallback(
    (status: string, colorCode: string) => {
      return status === statusEnum.occupiedDirty ||
        status === statusEnum.occupiedClean ||
        status === statusEnum.overstayed
        ? colorCode
        : "black";
    },
    []
  );

  const handleFontColorRoomStatus = useCallback((status: string) => {
    return status.includes(statusEnum.available)
      ? "white"
      : status.includes(statusEnum.occupiedClean) ||
        status.includes(statusEnum.occupiedDirty) ||
        status.includes(statusEnum.overstayed)
      ? "black"
      : "grey";
  }, []);

  const handleRenderIconRoomStatus = useCallback((status: string) => {
    return status.includes(statusEnum.overstayed) ? (
      <AccessTimeFilledIcon sx={{ color: "black" }} />
    ) : status.includes(statusEnum.dirty) ? (
      <CleaningServicesIcon />
    ) : status.includes(statusEnum.maintenance) ? (
      <HardwareIcon />
    ) : (
      <></>
    );
  }, []);

  const handleAddBooking = () => {
    if (roomStatus === statusEnum.dirty) {
      Notification.warning("The assigned room is dirty");
    }
    addBookingPeriodToRow(index, roomCode, roomZone, roomType);
    addBookingDraft(
      roomCode,
      roomZone,
      roomType,
      roomTypeName,
      roomTypeDetails,
      colorCode,
      roomID,
      maxPax
    );
  };

  return (
    <Grid
      item
      rowGap={0}
      rowSpacing={0}
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{
        backgroundColor: oddNumColor,
        height: itemHeight,
        width: `${leftColumnItemWidth}em`,
        padding: "0.5em",
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        sx={{
          backgroundColor: handleBackgroundColorRoomStatus(
            roomStatus,
            colorCode
          ),
          border: 1,
          borderColor: colorCode,
          height: "100%",
        }}
      >
        {/* change bg color based on zone */}
        {/* {`${roomZone} Room ${roomCode}`} */}
        <ButtonBase
          onClick={handleAddBooking}
          disabled={roomStatus.toLowerCase() === statusEnum.maintenance}
          sx={{
            aspectRatio: 1 / 1,
            height: "100%",
            opacity:
              roomStatus.toLowerCase() === statusEnum.maintenance ? 0.5 : 1,
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"100%"}
            sx={{
              aspectRatio: 1 / 1,
              border: 1,
              borderRight: 2,
              borderColor: colorCode,
              color: colorCode,
              backgroundColor: "black",
              fontWeight: "bold",
            }}
          >
            <Typography sx={{ color: "white" }}>{roomTypeDetails}</Typography>

            {/* <Button onClick={handleAddBooking}>+</Button> */}
          </Box>
        </ButtonBase>
        <Stack
          direction={"row"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
          paddingLeft={1}
          paddingRight={2}
        >
          <Typography
            sx={{
              color: handleFontColorRoomStatus(roomStatus),
              fontWeight: "bold",
              width: "80px",
            }}
          >
            {roomCode}, {roomZone}
          </Typography>
          {handleRenderIconRoomStatus(roomStatus)}
        </Stack>
      </Box>
    </Grid>
  );
};

export default LeftColumnRoomItem;
