import { ArrowForward, MoreHoriz } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import { useState } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../constants/axiosConfig";
import { transactionStatusEnum } from "../../constants/enums";
import { IPayments } from "../../models/payment/PaymentInterface";
import { BookingResult } from "../../pages/booking/interface";
import { RootState } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import AuthModal from "../account/AuthModal";
import Status from "../global/status/Status";
import LoadingModal from "./LoadingModal";
import PaymentViewDetailsModal from "./PaymentViewDetailsModal";
import RefundModal from "./RefundModal";

interface PaymentCompletedBoxProps {
  data: IPayments;
  latestBookingStatus: string;
  setTransactionCompleted: React.Dispatch<React.SetStateAction<IPayments[]>>;
  bookingData?: BookingResult;
}

const PaymentCompletedBox = ({
  data,
  latestBookingStatus,
  setTransactionCompleted,
  bookingData,
}: PaymentCompletedBoxProps) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleOpenMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMoreMenu = () => {
    setAnchorEl(null);
  };

  const [authModalOpen, setAuthModalOpen] = useState<boolean>(false);
  const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(false);

  const handleAuthModalOpen = () => {
    // if (latestBookingStatus === bookingStatusEnum.noShow) {
    // 	Notification.failed("Only checked in bookings can proceed with refund");
    // 	return;
    // }
    setAuthModalOpen(true);
  };
  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };

  const handleGetDate = (transactionDate: Date) => {
    const date = new Date(transactionDate);

    return format(date, "dd/MM/yyyy", {
      locale: enUS,
    });
  };

  const handleGetTime = (transactionTime: Date) => {
    const time = new Date(transactionTime);

    return format(time, "HH':'mm'");
  };

  const handleRefundPOA = () => {
    const apiUrl = "/transaction/update-status/";

    const formData = {
      transactionId: data.transactionId,
      paymentTypeId: "-",
      status: transactionStatusEnum.refund,
    };

    axiosInstance
      .patch(apiUrl, formData, config)
      .then((response) => {
        Notification.success("Payment Successfully Refunded");
        setTransactionCompleted((prevTransaction) => {
          const removedTransaction = prevTransaction.find(
            (transaction) => transaction.transactionId === data.transactionId
          );

          if (removedTransaction) {
            const newTransactions = prevTransaction.filter(
              (transaction) => transaction.transactionId !== data.transactionId
            );

            return [
              {
                ...data,
                transactionStatus: transactionStatusEnum.refund,
              },
              ...newTransactions,
            ];
          }
          return prevTransaction;
        });
      })
      .catch((res) => {
        Notification.failed(res.response.data.message);
      });
  };
  const [isRefundModalOpen, setIsRefundModalOpen] = useState<boolean>(false);

  const handleOpenRefundModal = () => {
    setIsRefundModalOpen(true);
  };
  const handleCloseRefundModal = () => {
    setIsRefundModalOpen(false);
  };

  const handleCloseLoadingModal = () => {
    setLoadingModalOpen(false);
  };

  const [isPaymentDetailModalOpen, setIsPaymentDetailModalOpen] =
    useState<boolean>(false);
  const handleOpenPaymentDetailsModal = () => {
    setIsPaymentDetailModalOpen(true);
  };
  const handleClosePaymentDetailsModal = () => {
    setIsPaymentDetailModalOpen(false);
  };

  const generateInvoice = async () => {
    setLoadingModalOpen(true);
    const apiUrl = "/transaction/generate-invoice/";

    const formData = {
      transactionId: data.transactionId,
      booking: bookingData?.bookingNo,
      invoiceNo: data.invoiceNo,
      customer: data.customer,
      transactionDatetime: data.transactionDatetime,
      checkIn: bookingData?.bookingStatus[0].checkInDatetime,
      checkOut: bookingData?.bookingStatus[0].checkOutDatetime,
      totalCharges: bookingData?.sum,
      items: data.items,
      creditAmount: data.creditAmount,
      debitAmount: data.debitAmount,
      paymentType: data.paymentType,
      sum: data.sum,
      taxAmount: data.taxAmount ? data.taxAmount : 0,
      platform: bookingData?.platform,
    };
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await axiosInstance
      .post(apiUrl, formData, config)
      .then((res) => {
        const pdfContent = atob(res.data.pdf_base64);
        handleDownloadFile(pdfContent);
      })
      .catch((res) => {
        console.log(formData);
        Notification.failed(res.response.data.message);
      })
      .finally(() => {
        setLoadingModalOpen(false);
      });
  };

  const generateReceipt = async () => {
    setLoadingModalOpen(true);
    const apiUrl = "/transaction/generate-receipt/";

    const formData = {
      transactionId: data.transactionId,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await axiosInstance
      .post(apiUrl, formData, config)
      .then((res) => {
        const pdfContent = atob(res.data.pdf_base64);
        handleDownloadFile(pdfContent);
      })
      .catch((res) => {
        Notification.failed(res.response.data.message);
      })
      .finally(() => {
        setLoadingModalOpen(false);
      });
  };

  const handleDownloadFile = (pdfContent: string) => {
    // Convert the decoded content to a Uint8Array
    const pdfData = new Uint8Array(pdfContent.length);
    for (let i = 0; i < pdfContent.length; i++) {
      pdfData[i] = pdfContent.charCodeAt(i);
    }

    const pdfBlob = new Blob([pdfData], { type: "application/pdf" });

    // Create a download link
    const url = URL.createObjectURL(pdfBlob);

    // Create an anchor element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = "Receipt.pdf";

    // Click the link to start the download
    link.click();

    // Clean up by revoking the object URL
    URL.revokeObjectURL(url);
  };
  return (
    <Box
      height={"100%"}
      width={"100%"}
      border={2}
      borderColor={theme.palette.primary.main}
    >
      <Stack
        direction={"row"}
        width={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingX={1}
        borderBottom={2}
        borderColor={theme.palette.primary.main}
        height={"30px"}
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <Typography
          width={"80%"}
          color={theme.palette.primary.main}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {data.invoiceNo} - {data.customer}
        </Typography>
        {data.transactionStatus !== transactionStatusEnum.refund &&
          data.transactionStatus !== transactionStatusEnum.void && (
            <IconButton onClick={handleOpenMoreMenu}>
              <MoreHoriz color="primary" />
            </IconButton>
          )}
      </Stack>
      <Box
        minHeight={"180px"}
        maxHeight={"340px"}
        borderBottom={2}
        borderColor={theme.palette.primary.main}
      >
        <Grid
          container
          direction={"row"}
          width={"100%"}
          minHeight={"180px"}
          maxHeight={"340px"}
        >
          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
            <Box
              width={"100%"}
              height={"100%"}
              borderRight={2}
              borderColor={theme.palette.primary.main}
              paddingTop={"30px"}
              paddingBottom={"10px"}
              paddingX={2}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Box>
                  <Typography variant="h5">Status</Typography>
                  <Status status={data.transactionStatus} />
                </Box>
                {data.transactionDatetime && (
                  <Box>
                    <Typography textAlign={"end"} variant={"h5"}>
                      {handleGetDate(data.transactionDatetime)}
                    </Typography>
                    <Typography
                      textAlign={"end"}
                      marginTop={"10px"}
                      variant="h4"
                    >
                      {handleGetTime(data.transactionDatetime)}
                    </Typography>
                  </Box>
                )}
              </Stack>
              <Box marginTop={"10px"}>
                <Typography variant="h5">Total</Typography>
                <Typography marginTop={"10px"} variant="h4">
                  MYR {data.debitAmount.toFixed(2)}
                </Typography>
                <Button
                  size="small"
                  sx={{
                    mt: 1,
                    background:
                      (data.paymentReference === null ||
                        data.paymentReference === "") &&
                      (data.paymentRemarks === null ||
                        data.paymentRemarks === "") &&
                      (data.guestGiven === null ||
                        data.guestGiven === "0.00000000000000000000")
                        ? "red"
                        : `${theme.palette.primary.dark}`,
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                  onClick={handleOpenPaymentDetailsModal}
                >
                  View Payment Details
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
            <ButtonBase
              sx={{ width: "100%", height: "100%" }}
              onClick={generateReceipt}
            >
              <Box
                display={"flex"}
                width={"100%"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                border={1}
                paddingY={"5px"}
                borderColor={theme.palette.primary.main}
                sx={{ backgroundColor: theme.palette.background.default }}
              >
                <Stack justifyContent={"start"}>
                  <ArrowForward sx={{ fontSize: "60px", width: "80px" }} />
                  <Box width={"50px"}>
                    <Typography variant="h4" textAlign={"start"}>
                      Print Receipt
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </ButtonBase>
          </Grid>
        </Grid>
      </Box>
      <Box paddingX={"20px"}>
        <Grid container direction={"column"} justifyContent={"center"}>
          <Grid item borderBottom={2} borderColor={theme.palette.primary.main}>
            <Grid container paddingY={"10px"} paddingLeft={"10px"}>
              <Grid item md={7}>
                <Typography
                  variant={"h5"}
                  color={theme.palette.primary.main}
                  fontWeight={600}
                >
                  Item
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography
                  variant={"h5"}
                  color={theme.palette.primary.main}
                  fontWeight={600}
                >
                  Qty
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Typography
                  variant={"h5"}
                  color={theme.palette.primary.main}
                  fontWeight={600}
                >
                  Charges
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {data.items &&
            data.items.map((item, index) => (
              <Grid
                item
                key={index}
                borderBottom={2}
                borderColor={"#043b33"}
                height={"42px"}
              >
                <Grid
                  container
                  paddingLeft={"10px"}
                  alignItems={"center"}
                  marginTop={"10px"}
                >
                  <Grid item md={7}>
                    <Typography
                      variant={"h5"}
                      fontWeight={500}
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "250px",
                      }}
                    >
                      {item.itemName} - {item.itemType}
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography variant={"h5"} fontWeight={500}>
                      {item.quantity}
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography variant={"h5"} fontWeight={500}>
                      {item.quantity
                        ? `RM ${(item.price * item.quantity).toFixed(2)}`
                        : `RM ${item.price.toFixed(2)}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Box>
      <Menu
        id={"basic-menu"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMoreMenu}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "0",
            width: "10rem",
            border: `3px solid ${theme.palette.primary.main}`,
            backgroundColor: "black",
          },
          "& .MuiPaper-root .MuiList-root": {
            backgroundColor: "black",
            padding: "0",
          },
        }}
      >
        <MenuItem
          onClick={handleAuthModalOpen}
          sx={{
            height: "2rem",
            borderBottom: `3px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
            backgroundColor: "black",
            fontWeight: 600,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
              color: "black",
            },
          }}
        >
          Refund
        </MenuItem>
        <MenuItem
          onClick={() => {
            Notification.warning(
              "Please refund instead as the payment has been made"
            );
          }}
          sx={{
            height: "2rem",
            color: theme.palette.primary.main,
            backgroundColor: "black",
            fontWeight: 600,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
              color: "black",
            },
          }}
        >
          Void
        </MenuItem>
      </Menu>
      <AuthModal
        authenticationModalOpen={authModalOpen}
        handleAuthenticationModalOnClose={handleAuthModalClose}
        functionToRun={handleOpenRefundModal}
        requireOtherAdmin={true}
      />
      <RefundModal
        isRefundModalOpen={isRefundModalOpen}
        handleCloseRefundModal={handleCloseRefundModal}
        invoiceData={data}
        bookingId=""
      />
      <LoadingModal
        handleCloseLoadingModal={handleCloseLoadingModal}
        isLoadingModalOpen={loadingModalOpen}
      />
      {data && (
        <PaymentViewDetailsModal
          isPaymentDetailModalOpen={isPaymentDetailModalOpen}
          handleClosePaymentDetailsModal={handleClosePaymentDetailsModal}
          data={data}
        />
      )}
    </Box>
  );
};

export default PaymentCompletedBox;
