import { bookingStatusEnum } from "../constants/enums";

export default class ColorCode {
  static bookingStatus(bookingStatus: string, roomStatus?: string) {
    switch (bookingStatus) {
      case "Draft":
        return "#104E3E";
      case bookingStatusEnum.checkIn:
        return "#148978";
      case bookingStatusEnum.checkOut:
        return "#148978";
      case bookingStatusEnum.booked:
        return "#FF9F46";
      case bookingStatusEnum.confirmBooking:
        return "#148978";
      case bookingStatusEnum.overstay:
        return "#E81C40";
      case bookingStatusEnum.transferFromAirSide:
        return "#FF9F46";
      case bookingStatusEnum.transferFromLandSide:
        return "#FF9F46";
      case bookingStatusEnum.transferToLandSide:
        return "#148978";
      case bookingStatusEnum.transferToAirSide:
        return "#148978";
      case bookingStatusEnum.reservation:
        return "#0A443C";
      case bookingStatusEnum.noShow:
        return "#E81C40";
      case bookingStatusEnum.cancelled:
        return "#000000";
      default:
        return "#000000";
    }
  }
}
