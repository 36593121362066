import {
  Divider,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Stack,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import theme from "../../assets/theme/theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import axiosInstance from "../../constants/axiosConfig";
import { store } from "../../redux/store";
import { PlatformsInterface } from "../../models/rooms/roomsInterface";
import Notification from "../../utils/notificationConfig";

type Props = {
  setShowCreateTierSection: () => void;
};

const CreateTier = ({ setShowCreateTierSection }: Props) => {
  const token = store.getState().user.accessToken;
  const [platforms, setPlatforms] = useState<PlatformsInterface[]>([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState<string>("");
  const [tierValue, setTierValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchAllPlatforms = async () => {
      await axiosInstance
        .get(`/bookings/platforms/get-all-platforms/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setPlatforms(res.data.datas);
          }
        });
    };

    fetchAllPlatforms();
  }, [token]);

  const createNewTier = async () => {
    setLoading(true);
    const payload = {
      platformId: selectedPlatforms === "" ? null : selectedPlatforms,
      tier: tierValue,
    };
    await axiosInstance
      .post(`/rooms/tiers/`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success(response.data.message);
      })
      .catch((error) => {
        Notification.failed(`Error creating new tier: ${error.response.data.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2">Tier Settings</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              pt: 5,
              mb: 5,
              borderBottomWidth: 3.5,
              borderColor: "#232323",
            }}
          />
        </Grid>
        <Grid item xs={4} pt={1.5} pb={6}>
          <Typography variant="h4" color={theme.palette.primary.main}>
            Select Platform Type
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            select
            title="Platforms"
            label="Platforms"
            size="medium"
            fullWidth
            onChange={(e) => setSelectedPlatforms(e.target.value)}
          >
            {platforms.map((option) => (
              <MenuItem key={option.platform_id} value={option.platform_id}>
                {option.platform}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Stack sx={{ background: "#232323", p: 5, borderRadius: 5 }}>
        <Grid container sx={{ background: "#141414", borderRadius: 2, p: 3 }}>
          <Grid item xs={2}>
            <IconButton
              sx={{ color: "#fff" }}
              size="medium"
              onClick={setShowCreateTierSection}
            >
              <ArrowBackIcon fontSize="medium" />
              <Typography variant="h4">Back</Typography>
            </IconButton>
          </Grid>
          <Grid item xs={10} pt={1}>
            <Typography variant="h3" color={theme.palette.primary.main}>
              New Tier
            </Typography>
          </Grid>
          <Grid item xs={2} pt={2} mt={5}>
            <Typography variant="h4" color={theme.palette.primary.main}>
              Tier Name
            </Typography>
          </Grid>
          <Grid item xs={10} mt={5}>
            <TextField
              title="Tiers"
              label="Tiers"
              size="medium"
              fullWidth
              value={tierValue}
              type="number"
              onChange={(e) => setTierValue(e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={10}></Grid>
          <Grid item xs={2} mt={5} pl={4}>
            <Button variant="outlined" onClick={createNewTier}>
              {!loading ? (
                "Create"
              ) : (
                <CircularProgress color="primary" size={"24px"} />
              )}
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default CreateTier;
