import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ArrowBack, ArrowBackIos } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  INewAccountData,
  INewAccountRole,
} from "../../models/accounts/accountInterface";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import ManagementModalHeader from "../management/ManagementModalHeader";

interface EditOwnAccountProps {
  handleClose: VoidFunction;
  open: boolean;
}

const EditOwnAccountModal = (props: EditOwnAccountProps) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const accountId = useSelector((state: RootState) => state.user.accountId);
  const email = useSelector((state: RootState) => state.user.email);
  const name = useSelector((state: RootState) => state.user.name);
  const username = useSelector((state: RootState) => state.user.username);
  const role = useSelector((state: RootState) => state.user.role);

  const initialSelectedAccount: INewAccountData = {
    accountId: accountId,
    email: email,
    name: name,
    roles: role,
    username: username,
    isArchived: false,
  };

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const formik = useFormik({
    initialValues: initialSelectedAccount,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      const apiUrl = "/accounts/edit-account/";

      if (values.password !== values.confirmPassword) {
        Notification.failed("Password Mismatch Detected");
        actions.setSubmitting(false);
        return;
      }

      const formData = {
        account_id: values.accountId,
        username: values.username,
        name: values.name,
        email: values.email,
        password: values.password,
      };
      axiosInstance
        .put(apiUrl, formData, config)
        .then((res) => {
          Notification.success(res.data.message);
          props.handleClose();
        })
        .catch((res) => {
          Notification.failed(res.response.data.msg);
        })
        .finally(() => actions.setSubmitting(false));
      return;
    },
  });

  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <form onSubmit={formik.handleSubmit}>
        <Box
          width={"800px"}
          maxHeight={"800px"}
          border={`3px solid ${theme.palette.primary.main}`}
          alignItems={"center"}
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "black",
            borderRadius: 1,
          }}
        >
          <ManagementModalHeader
            handleModalClose={props.handleClose}
            title="Edit Own Account"
          />
          <Box paddingX={3} paddingY={3}>
            <Stack direction={"column"} spacing={4}>
              <Typography variant="h3">Account Credentials</Typography>
              <Stack direction={"column"} spacing={2}>
                <Stack direction={"row"} alignItems={"center"} spacing={3}>
                  <Typography variant="h4" width={"180px"}>
                    Username
                  </Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    inputProps={{
                      style: { textTransform: "none" },
                    }}
                  />
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={3}>
                  <Typography variant="h4" width={"180px"}>
                    Password
                  </Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={3}>
                  <Typography variant="h4" width={"180px"}>
                    Confirm Password
                  </Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="password"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                  />
                </Stack>
              </Stack>
              <Typography variant="h3">User Information</Typography>
              <Stack direction={"column"} spacing={2}>
                <Stack direction={"row"} alignItems={"center"} spacing={3}>
                  <Typography variant="h4" width={"180px"}>
                    Name
                  </Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={3}>
                  <Typography variant="h4" width={"180px"}>
                    Email
                  </Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    name="email"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                </Stack>
              </Stack>

              {/* <Box display={"flex"} justifyContent={"end"}>
                <Button variant="outlined" type="submit" sx={{ width: "80px" }}>
                  {formik.isSubmitting ? (
                    <CircularProgress size={24} color="primary" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Box> */}
            </Stack>
          </Box>
          <Box
            display={"flex"}
            height={"50px"}
            bgcolor={theme.palette.background.paper}
            justifyContent={"end"}
            padding={1}
            borderTop={2}
            borderColor={theme.palette.primary.main}
          >
            <Button variant={"outlined"} type="submit">
              {formik.isSubmitting ? (
                <CircularProgress size={24} color="primary" />
              ) : (
                "Confirm"
              )}
            </Button>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};

export default EditOwnAccountModal;
