import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import React, { useEffect, useMemo, useRef, useState } from "react";
import theme from "../../assets/theme/theme";
import ManagementActionMenu from "../../components/management/ManagementActionMenu";
import ManagementContentArea from "../../components/management/ManagementContentArea";
import ManagementFilterBar from "../../components/management/ManagementFilterBar";
import { IPromotion, IAxiosPromotionResponse } from "../../types/promotion";
import axios, { AxiosError, AxiosResponse } from "axios";
import BASE_API from "../../constants/api";
import LayersIcon from "@mui/icons-material/Layers";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import Notification from "../../utils/notificationConfig";
import { store } from "../../redux/store";
import PromotionModal from "../../components/promotion/PromotionModal";
import AccessCardRedirectModal from "../../components/promotion/AccessCardRedirectModal";
import { format } from "date-fns";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

/**
 * TODO: make overview active and inactive item optional and make the icon replaceable
 * TODO: add MultiLookUpField at the merch BE api
 */

type Props = {};

// const promotionDummy: IPromotion[] = [
//   {
//     promotionId: "1",
//     promoCode: "123456",
//     details: "RM10",
//     promoStartDatetime: "2023-10-10",
//     promoEndDatetime: "2023-10-15",
//     seats: 100,
//     stackable: true,
//   },
//   {
//     promotionId: "2",
//     promoCode: "123456",
//     details: "20%",
//     promoStartDatetime: "2023-10-10",
//     promoEndDatetime: "2023-10-15",
//     seats: 100,
//     stackable: false,
//   },
// ];

const PromotionManagementPage = (props: Props) => {
  const [promotions, setPromotions] = useState<IPromotion[]>([]);
  const [displayPromotions, setDisplayPromotions] = useState<IPromotion[]>([]);
  const handlePromotionChange = (promotions: IPromotion[]) => {
    setDisplayPromotions(
      promotions.sort((a, b) => {
        // Sort by archived status first (archived promotions always at the bottom)
        if (a.archived && !b.archived) return 1;
        if (!a.archived && b.archived) return -1;

        // Sort by isActive (based on promoEndDatetime and archived status)
        const isActiveA =
          new Date(a.promoEndDatetime) > new Date() && !a.archived;
        const isActiveB =
          new Date(b.promoEndDatetime) > new Date() && !b.archived;
        if (isActiveA && !isActiveB) return -1;
        if (!isActiveA && isActiveB) return 1;

        // Sort by promoEndDatetime in descending order
        const dateA = new Date(a.promoEndDatetime);
        const dateB = new Date(b.promoEndDatetime);
        return dateB.getTime() - dateA.getTime();
      })
    );
  };

  const [excludeArchive, setExcludeArchive] = useState<boolean>(false);

  const [activePromotionNumber, setActivePromotionNumber] = useState<number>(0);
  const handleActivePromotionNumberChange = (activeNum: number) => {
    setActivePromotionNumber(activeNum);
  };

  const [inactivePromotionNumber, setInactivePromotionNumber] =
    useState<number>(0);
  const handleInactivePromotionNumberChange = (inactiveNum: number) => {
    setInactivePromotionNumber(inactiveNum);
  };

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 12,
  });

  const [searchQuery, setSearchQuery] = useState<string>("");
  const handleSearchQueryChange = (search: string) => {
    setSearchQuery(search);
  };

  const fetchAllPromotions = async () => {
    await axios
      .get(`${BASE_API}/promotions/`, {
        params: {
          search: searchQuery,
        },
        headers: {
          Authorization: `Bearer ${store.getState().user.accessToken}`,
        },
      })
      .then(
        ({
          data: { promotions, activePromotionNum, inactivePromotionNum },
        }: AxiosResponse<IAxiosPromotionResponse>) => {
          setPromotions(promotions);
          handleActivePromotionNumberChange(activePromotionNum);
          handleInactivePromotionNumberChange(inactivePromotionNum);
        }
      )
      .catch((err) => {
        Notification.failed(err.message);
      });
  };

  useEffect(() => {
    fetchAllPromotions();
  }, []);

  useEffect(() => {
    if (excludeArchive) {
      const excludedArchive = promotions.filter(
        (item) => item.archived === false
      );
      handlePromotionChange(excludedArchive);
      return;
    }
    handlePromotionChange(promotions);
  }, [promotions]);

  const actionMenuAnchorElRef = useRef<HTMLButtonElement | null>(null);

  const initialSelectedPromotionObjValues: IPromotion = {
    promotionId: "",
    promoCode: "",
    details: "RM",
    promoStartDatetime: "",
    promoEndDatetime: "",
    seats: 0,
    stackable: false,
    memberOnly: false,
    archived: false,
    visibleAtWebsite: false,
  };

  const [selectedPromotionObj, setSelectedPromotionObj] = useState<IPromotion>(
    initialSelectedPromotionObjValues
  );
  const handleSelectedPromotionObjChange = (merch: IPromotion) => {
    setSelectedPromotionObj(merch);
  };

  const [isActionMenuOpen, setIsActionMenuOpen] = useState<boolean>(false);
  const handleActionMenuOpen = (clickedButtonId: string) => {
    actionMenuAnchorElRef.current = document.getElementById(
      clickedButtonId
    ) as HTMLButtonElement;
    setIsActionMenuOpen(true);
  };
  const handleActionMenuClose = () => {
    actionMenuAnchorElRef.current = null;
    setSelectedPromotionObj(initialSelectedPromotionObjValues);
    setIsActionMenuOpen(false);
  };

  const [isEditPromotionModalOpen, setIsEditPromotionModalOpen] =
    useState<boolean>(false);
  const handleEditPromotionModalOpen = () => {
    setIsEditPromotionModalOpen(true);
  };
  const handleEditPromotionModalClose = () => {
    setIsEditPromotionModalOpen(false);
    handleActionMenuClose();
  };

  const [isAddPromotionModalOpen, setIsAddPromotionModalOpen] =
    useState<boolean>(false);
  const handleAddPromotionModalOpen = () => {
    setIsAddPromotionModalOpen(true);
  };
  const handleAddPromotionModalClose = () => {
    setIsAddPromotionModalOpen(false);
  };

  const [isAccessCardRedirectModalOpen, setIsAccessCardRedirectModalOpen] =
    useState<boolean>(false);
  const handleAccessCardRedirectModalOpen = () => {
    setIsAccessCardRedirectModalOpen(true);
  };
  const handleAccessCardRedirectModalClose = () => {
    setIsAccessCardRedirectModalOpen(false);
  };

  const handleArchivePromotion = async () => {
    const apiUrl = selectedPromotionObj.archived
      ? `${BASE_API}/promotions/un-archive/${selectedPromotionObj.promotionId}/`
      : `${BASE_API}/promotions/archive/${selectedPromotionObj.promotionId}/`;
    await axios
      .put(apiUrl, {
        headers: {
          Authorization: `Bearer ${store.getState().user.accessToken}`,
        },
      })
      .then((res: AxiosResponse) => {
        Notification.success(res.data.msg);
        // fetchAllMerch(); use material react table crud feature to add the item to the table in the frontend instead of get it again in the backend
        handleActionMenuClose();
        // handleAccessCardRedirectModalOpen();

        const updatedPromotion = promotions.filter(
          (promotion) =>
            promotion.promotionId !== selectedPromotionObj.promotionId
        );

        let changedSelected = selectedPromotionObj;

        changedSelected.archived = !changedSelected.archived;

        setPromotions([...updatedPromotion, changedSelected]);
      })
      .catch((err: AxiosError) => {
        Notification.failed(err.message);
      });
  };

  const columns = useMemo<MRT_ColumnDef<IPromotion>[]>(
    () => [
      {
        accessorKey: "promoCode",
        header: "Code",
        size: 50,
        Cell: ({
          row: {
            original: { promoCode, archived },
          },
        }) => {
          return (
            <Typography
              sx={{
                color: `${archived ? "#b14e46" : "#62fcaf"}!important`,
              }}
            >
              {promoCode}
            </Typography>
          );
        },
      },
      {
        accessorKey: "details",
        header: "Details",
        size: 50,
        Cell: ({
          row: {
            original: { details, archived },
          },
        }) => {
          return (
            <Typography
              sx={{
                color: `${archived ? "#b14e46" : "#62fcaf"}!important`,
              }}
            >
              {details.includes("%")
                ? details
                : `RM ${Number(details).toFixed(2)}`}
            </Typography>
          );
        },
      },
      {
        accessorKey: "promoStartDatetime",
        header: "Start Date",
        size: 50,
        Cell: ({ row }) => {
          return (
            <Typography
              sx={{
                color: `${
                  row.original.archived ? "#b14e46" : "#62fcaf"
                }!important`,
              }}
            >
              {row.original.promoStartDatetime &&
                format(
                  new Date(row.original.promoStartDatetime),
                  "dd/MM/yyyy HH:mm"
                )}
            </Typography>
          );
        },
      },
      {
        accessorKey: "promoEndDatetime",
        header: "End Date",
        size: 50,
        Cell: ({ row }) => {
          return (
            <Typography
              sx={{
                color: `${
                  row.original.archived ? "#b14e46" : "#62fcaf"
                }!important`,
              }}
            >
              {row.original.promoEndDatetime &&
                format(
                  new Date(row.original.promoEndDatetime),
                  "dd/MM/yyyy HH:mm"
                )}
            </Typography>
          );
        },
      },
      {
        accessorKey: "seats",
        header: "Seat",
        size: 50,
        Cell: ({ row }) => {
          return (
            <Typography
              sx={{
                color: `${
                  row.original.archived ? "#b14e46" : "#62fcaf"
                }!important`,
              }}
            >
              {row.original.seats}
            </Typography>
          );
        },
      },
      {
        accessorKey: "stackable",
        header: "Stackable",
        size: 50,
        Cell: ({
          row: {
            original: { stackable, archived },
          },
        }) =>
          stackable ? (
            <LayersIcon
              sx={{
                marginLeft: "20px",
                color: `${archived ? "#b14e46" : "#62fcaf"}!important`,
              }}
            />
          ) : (
            <LayersClearIcon
              sx={{
                marginLeft: "20px",
                color: `${archived ? "#b14e46" : "#62fcaf"}!important`,
              }}
            />
          ),
      },
      {
        header: "Actions",
        size: 50,
        Cell: ({
          row: {
            original: {
              promotionId,
              promoCode,
              details,
              promoStartDatetime,
              promoEndDatetime,
              seats,
              stackable,
              memberOnly,
              archived,
              visibleAtWebsite,
            },
          },
        }) => (
          <IconButton
            id={promotionId}
            onClick={() => {
              handleActionMenuOpen(promotionId);
              handleSelectedPromotionObjChange({
                promotionId,
                promoCode,
                details,
                promoStartDatetime,
                promoEndDatetime,
                seats,
                stackable,
                memberOnly,
                archived,
                visibleAtWebsite,
              });
            }}
          >
            <SettingsIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          </IconButton>
        ),
      },
    ],
    []
  );

  return (
    <Box
      width={"80vw"}
      minWidth={"80em"}
      height={"fit-content"}
      sx={{
        postition: "absolute",
        inset: "0",
        marginX: "auto",
      }}
    >
      <Box width={"100%"} display={"flex"}>
        <Box
          width={"18%"}
          height={"100px"}
          border={`3px solid ${theme.palette.primary.main}`}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <FilterAltOutlinedIcon
            sx={{
              fontSize: "2rem",
              color: "white",
            }}
          />
          <Typography variant={"h3"}>Filter</Typography>
        </Box>
        <Stack width={"82%"} direction={"column"}>
          <Box
            width={"100%"}
            height={"50px"}
            border={`3px solid ${theme.palette.primary.main}`}
            borderLeft={"none"}
            borderBottom={"none"}
            display={"flex"}
            alignItems={"center"}
            padding={2}
          >
            <Typography>Archived</Typography>
          </Box>
          <Box
            width={"100%"}
            height={"50px"}
            border={`3px solid ${theme.palette.primary.main}`}
            borderLeft={"none"}
            display={"flex"}
            alignItems={"center"}
            bgcolor={theme.palette.background.default}
          >
            <Select
              size="small"
              value={excludeArchive}
              onChange={(event) => {
                setExcludeArchive(event.target.value === "true");
                if (event.target.value === "true") {
                  const newData = promotions?.filter(
                    (item) => item.archived === false
                  );
                  handlePromotionChange(newData);
                } else {
                  handlePromotionChange(promotions);
                }
              }}
              sx={{
                width: "100%",
                ".MuiSelect-select": {
                  border: 0,
                  borderColor: theme.palette.primary.main,
                  borderRadius: 0,
                  // borderBottom:0,
                  borderRight: 0,
                  borderLeft: 0,
                },
                ".MuiSelect-icon": {
                  fill: "white",
                },
              }}
            >
              <MenuItem value={"true"}>Exclude</MenuItem>
              <MenuItem value={"false"}>Include</MenuItem>
            </Select>
          </Box>
        </Stack>
      </Box>
      <ManagementContentArea<IPromotion>
        title={"Promotion"}
        tableItems={displayPromotions}
        handleAddItemModalOpen={handleAddPromotionModalOpen}
        columns={columns}
        pagination={pagination}
        setPagination={setPagination}
        activeOverview
        activeNum={activePromotionNumber}
        inactiveNum={inactivePromotionNumber}
        searchQuery={searchQuery}
        handleSearchQueryChange={handleSearchQueryChange}
        fetchAllItems={fetchAllPromotions}
      />
      <ManagementActionMenu
        ref={actionMenuAnchorElRef}
        isActionMenuOpen={isActionMenuOpen}
        handleActionMenuClose={handleActionMenuClose}
        handleEditInfoModalOpen={handleEditPromotionModalOpen}
        handleArchiveMerch={handleArchivePromotion}
        selectedPromotion={selectedPromotionObj}
      />
      <PromotionModal
        title={"Add Promotion"}
        type={"add"}
        isPromotionModalOpen={isAddPromotionModalOpen}
        handlePromotionModalClose={handleAddPromotionModalClose}
        fetchAllPromotions={fetchAllPromotions}
      />
      <PromotionModal
        title={"Edit Promotion"}
        type={"edit"}
        isPromotionModalOpen={isEditPromotionModalOpen}
        handlePromotionModalClose={handleEditPromotionModalClose}
        initialValues={selectedPromotionObj}
        fetchAllPromotions={fetchAllPromotions}
      />
      <AccessCardRedirectModal
        title={"Access Card Archive"}
        isAccessCardRedirectModalOpen={isAccessCardRedirectModalOpen}
        handleAccessCardRedirectModalClose={handleAccessCardRedirectModalClose}
        archivedPromotion={selectedPromotionObj}
      />
    </Box>
  );
};

export default PromotionManagementPage;
