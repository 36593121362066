import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import axiosInstance from "../../../constants/axiosConfig";
import Notification from "../../../utils/notificationConfig";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { INewAccountData } from "../../../models/accounts/accountInterface";
import CustomTable from "../../global/table/CustomTable";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import AddOrEditAccountModal from "./AddOrEditAccount";

const initialSelectedAccount: INewAccountData = {
  accountId: "",
  email: "",
  name: "",
  roles: "",
  username: "",
  isArchived: false,
};

const AccountTab = () => {
  const theme = useTheme();
  const user = useSelector((state: RootState) => state.user);
  const config = {
    headers: { Authorization: `Bearer ${user.accessToken}` },
  };

  const [accounts, setAccounts] = useState<INewAccountData[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<INewAccountData>(
    initialSelectedAccount
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });

  const [isAccountAddEditTabOpen, setIsAccountAddEditTabOpen] =
    useState<boolean>(false);

  const handleAccountTabOpen = (accountData?: INewAccountData) => {
    setIsAccountAddEditTabOpen(true);
    if (accountData) setSelectedAccount(accountData);
  };

  const handleAccountTabBack = () => {
    setIsAccountAddEditTabOpen(false);
    setSelectedAccount(initialSelectedAccount);
  };

  const getPageData = () => {
    setIsLoading(true);
    const apiUrlParams = new URLSearchParams();
    apiUrlParams.append("display_archive", "true");

    axiosInstance
      .get(`/accounts/get-all-accounts?${apiUrlParams.toString()}`, config)
      .then((res) => {
        if (res.status === 200) {
          setAccounts(
            res.data.data.map((data: any) => ({
              ...data,
              roles: data.role.roleId,
              isArchived: data.is_archive,
            }))
          );
          return;
        }
      })
      .catch((e) => {
        console.log(e.response);
        Notification.failed(e.response.data.msg);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPageData();
  }, []);

  const columns = useMemo<MRT_ColumnDef<INewAccountData>[]>(
    () => [
      {
        header: "Name",
        Cell: ({ row }) => (
          <p style={{ color: row.original.isArchived ? "grey" : "white" }}>
            {row.original.name}
          </p>
        ),
      },
      {
        header: "Role",
        Cell: ({ row }) => (
          <p style={{ color: row.original.isArchived ? "grey" : "white" }}>
            {row.original.role?.roleName}
          </p>
        ),
      },
      {
        header: "Username",
        Cell: ({ row }) => (
          <p style={{ color: row.original.isArchived ? "grey" : "white" }}>
            {row.original.username}
          </p>
        ),
      },
      {
        header: "Email",
        Cell: ({ row }) => (
          <p style={{ color: row.original.isArchived ? "grey" : "white" }}>
            {row.original.email}
          </p>
        ),
      },
      {
        header: "Action",
        size: 50,
        Cell: ({ row }) => (
          <Button
            variant="text"
            onClick={() => handleAccountTabOpen(row.original)}
          >
            Edit
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <Box padding={5}>
      {!isAccountAddEditTabOpen ? (
        <>
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"space-between"}
          >
            <Typography variant="h2">Accounts</Typography>
            <Button
              variant={"contained"}
              onClick={() => handleAccountTabOpen()}
              sx={{
                width: "150px",
                backgroundColor: theme.palette.background.paper,
                color: "white",
                border: 1,
                borderColor: theme.palette.primary.main,
              }}
            >
              Create New
            </Button>
          </Stack>
          <Box
            display={"flex"}
            flexDirection={"column"}
            marginTop={2}
            border={1}
            borderColor={theme.palette.primary.main}
          >
            <Box
              display={"flex"}
              width={"100%"}
              padding={1}
              paddingX={2}
              bgcolor={theme.palette.background.default}
              borderBottom={1}
              borderColor={theme.palette.primary.main}
            >
              <Typography variant="h3" color={"primary"}>
                Accounts
              </Typography>
            </Box>
            <Box padding={2}>
              <CustomTable
                data={accounts}
                columns={columns}
                isLoading={isLoading}
                handlePaginationChange={setPagination}
                pagination={pagination}
                rowCount={accounts.length}
                backgroundColor={"#141414"}
              />
            </Box>
          </Box>
        </>
      ) : (
        <AddOrEditAccountModal
          handleBack={handleAccountTabBack}
          selectedAccount={selectedAccount}
          setAccounts={setAccounts}
        />
      )}
    </Box>
  );
};

export default AccountTab;
