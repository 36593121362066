import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { IStepper } from "../../models/bookings/Booking";
import { useEffect, useState } from "react";
import { KeyboardReturn, QrCode } from "@mui/icons-material";
import POSItem from "../../components/payment/POSItem";
import { IMerch } from "../../types/merch";
import {
  ILotSetting,
  IPaymentItem,
} from "../../models/payment/PaymentInterface";
import axiosInstance from "../../constants/axiosConfig";
import { RootState, store } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import { format, parseISO } from "date-fns";
import PaymentForm from "../../components/payment/PaymentForm";
import { useSelector } from "react-redux";
import { transactionCategoryEnum } from "../../constants/enums";

import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import GuestAddAssignModal from "../../components/guests/GuestAddAssignModal";
import {
  IExistingCustomerRow,
  IPOSCustomerStaying,
} from "../../models/guests/GuestsInterface";
import {
  convertToEpoch,
  extractNumbersFromString,
  stringDateToEpoch,
} from "../../utils/functions";

const stepperValue: IStepper[] = [
  { step: 1, text: "POS" },
  { step: 2, text: "Payment" },
];

interface ICustomerFilter {
  customer: string;
  customerName: string;
  isMember: boolean;
  bookingId: string;
  bookingNo: string;
  bookingMade: Date;
  roomNo: string;
  roomType: string;
}

const initialCustomerFilter: ICustomerFilter = {
  bookingId: "",
  bookingMade: new Date(),
  bookingNo: "",
  customer: "Non-Guest",
  customerName: "Non-Guest",
  roomNo: "",
  roomType: "",
  isMember: false,
};

const initialCustomerStaying: IPOSCustomerStaying = {
  bookingId: "",
  customerStaying: "Non-Guest",
  customerStayingName: "Non-Guest",
  isMember: false,
};

const POSPage = () => {
  const theme = useTheme();
  const accountId = useSelector((state: RootState) => state.user.accountId);
  const token = useSelector((state: RootState) => state.user.accessToken);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [itemList, setItemList] = useState<{ [key: string]: IMerch[] }>({
    "": [],
  });

  const [selectedCategory, setSelectedCategory] = useState<string>("");

  const [shoppingCart, setShoppingCart] = useState<IPaymentItem[]>([]);
  const [posFeesList, setPOSFeesList] = useState<ILotSetting[]>([]);

  const [customerFilter, setCustomerFilter] = useState<ICustomerFilter[]>([]);
  const [selectedCustomerIndex, setSelectedCustomerIndex] =
    useState<number>(-1);

  const [customerStaying, setCustomerStaying] = useState<IPOSCustomerStaying>(
    initialCustomerStaying
  );

  const [stepper, setStepper] = useState<number>(1);

  const [isLockerLoading, setIsLockerLoading] = useState<boolean>(false);
  const [isShowerLoading, setIsShowerLoading] = useState<boolean>(false);

  const [openScanBarcodeModal, setOpenScanBarcodeModal] =
    useState<boolean>(false);
  const [openAssignGuestModal, setOpenAssignGuestModal] =
    useState<boolean>(false);

  const handleOpenScanBarcodeModal = () => {
    setOpenScanBarcodeModal(true);
  };

  const handleCloseScanBarcodeModal = () => {
    setOpenScanBarcodeModal(false);
  };

  const handleOpenAssignGuestModal = () => {
    setOpenAssignGuestModal(true);
  };

  const handleCloseAssignGuestModal = () => {
    setOpenAssignGuestModal(false);
  };

  useEffect(() => {
    if (customerFilter.length > 0) {
      setCustomerStaying({
        bookingId: customerFilter[selectedCustomerIndex].bookingId,
        customerStaying: customerFilter[selectedCustomerIndex].customer,
        customerStayingName: customerFilter[selectedCustomerIndex].customerName,
        isMember: customerFilter[selectedCustomerIndex].isMember,
      });
    }
  }, [selectedCustomerIndex, customerFilter]);

  useEffect(() => {
    const fetchItem = () => {
      axiosInstance
        .get(`/merch/`, {
          params: {
            no_archived: true,
          },
          headers: {
            Authorization: `Bearer ${store.getState().user.accessToken}`,
          },
        })
        .then(({ data: { merchs, activeMerchNum, inactiveMerchNum } }: any) => {
          const convertedMerchs = merchs.map((merch: any) => ({
            ...merch,
            nonGuestSum: Number(merch.nonGuestSum),
            sum: Number(merch.sum),
            cost: Number(merch.cost),
          }));
          // .filter((merch: any) => merch.itemDetails !== "Locker");
          setItemList(handleConvertData(convertedMerchs));
        })
        .catch((err) => {
          Notification.failed(err.message);
        });
    };
    fetchItem();
    setShoppingCart([]);
  }, []);

  useEffect(() => {
    const fetchGuestFilter = () => {
      axiosInstance
        .get(`/bookings/pos-booking-filter/get-all-guest-booking`, {
          headers: {
            Authorization: `Bearer ${store.getState().user.accessToken}`,
          },
        })
        .then((res: any) => {
          const formattedData: ICustomerFilter[] = res.data.data.map(
            (data: any) => ({
              bookingId: data.booking.bookingId,
              bookingNo: data.booking.bookingNo,
              bookingMade: data.booking.bookingMadeDatetime,
              customer: data.personInCharge.personInChargeId,
              customerName: data.personInCharge.personInChargeName,
              isMember: data.personInCharge.isMember,
              roomNo: data.roomNo,
              roomType: data.roomType,
            })
          );
          setCustomerFilter([initialCustomerFilter, ...formattedData]);
          setSelectedCustomerIndex(0);
        })
        .catch((err) => {
          Notification.failed(err.message);
        });
    };
    fetchGuestFilter();
  }, []);

  //Get Service Charges
  useEffect(() => {
    const fetchFees = () => {
      axiosInstance
        .get(`/lot-settings/get-settings/${accountId}`, config)
        .then((res) => {
          setPOSFeesList(res.data.data);
        });
    };
    fetchFees();
  }, []);

  const handleConvertData = (data: IMerch[]) => {
    return data.reduce((acc: { [key: string]: IMerch[] }, item: IMerch) => {
      const { itemDetails, ...rest } = item;

      if (!acc[itemDetails]) {
        acc[itemDetails] = [];
      }

      acc[itemDetails].push({ itemDetails, ...rest });

      return acc;
    }, {});
  };

  const handleAddToShoppingCart = (propsItem: IMerch, newQuantity: number) => {
    //Ignore if new Quantity is below zero
    if (newQuantity < 0) {
      return;
    }

    if (propsItem.itemDetails === "Locker") {
      const lockerInShoppingCart = shoppingCart.find(
        (cartItem) => cartItem.category === transactionCategoryEnum.lockerSales
      );

      if (
        lockerInShoppingCart &&
        lockerInShoppingCart.itemName !== propsItem.itemName
      ) {
        Notification.warning(
          "Please ensure to have the same Locker Duration in POS Payment"
        );
        return;
      }
    }

    const itemInShoppingCart = shoppingCart.find(
      (cartItem) => cartItem.itemId === propsItem.merchId
    );
    //Update Quantity and Price only
    if (itemInShoppingCart) {
      const removedShoppingCart = shoppingCart.filter(
        (cartItem) => cartItem.itemId !== propsItem.merchId
      );

      if (newQuantity !== 0) {
        setShoppingCart([
          ...removedShoppingCart,
          {
            ...itemInShoppingCart,
            quantity: newQuantity,
            // price:
            //   (Number(itemInShoppingCart.price) /
            //     itemInShoppingCart.quantity!) *
            //   newQuantity,
          },
        ]);
      } else {
        setShoppingCart([...removedShoppingCart]);
      }
      return;
    }
    //Add Item to Shopping Cart
    const category = propsItem.itemName.toLowerCase().includes("locker")
      ? transactionCategoryEnum.lockerSales
      : propsItem.itemName.toLowerCase().includes("shower")
      ? transactionCategoryEnum.showerSales
      : transactionCategoryEnum.merchSales;

    const type = propsItem.itemName.toLowerCase().includes("locker")
      ? "Locker"
      : propsItem.itemName.toLowerCase().includes("shower")
      ? "Shower"
      : "Merch";
    setShoppingCart([
      ...shoppingCart,
      {
        itemId: propsItem.merchId,
        itemName: propsItem.itemName,
        itemType: type,
        category: category,
        quantity: 1,
        price:
          selectedCustomerIndex < 1
            ? parseFloat(propsItem.nonGuestSum)
            : propsItem.sum!,
      },
    ]);
    return;
  };

  const handleCompletePOSPayment = () => {
    setShoppingCart([]);
    setStepper(1);
  };

  const handleScanBarcode = (value: string) => {
    axiosInstance
      .get(`/merch/${value}`, config)
      .then((res) => {
        Notification.success("Merch Found");
        const selectedItem = shoppingCart.find(
          (item) => item.itemId === res.data.merchId
        );
        handleAddToShoppingCart(
          res.data,
          selectedItem && selectedItem.quantity ? selectedItem.quantity + 1 : 1
        );
      })
      .catch((e) => Notification.failed(e.response.data.msg));
  };

  const handleCompletePOSSelection = (customerStaying: IPOSCustomerStaying) => {
    const lockerSales =
      shoppingCart.filter(
        (item) => item.category === transactionCategoryEnum.lockerSales
      ).length > 0;

    const showerSales =
      shoppingCart.filter(
        (item) => item.category === transactionCategoryEnum.showerSales
      ).length > 0;
    if (
      (lockerSales || showerSales) &&
      customerStaying.customerStaying === "Non-Guest"
    ) {
      handleOpenAssignGuestModal();
      return;
    }

    const lockerInfo = shoppingCart.find(
      (item) => item.category === transactionCategoryEnum.lockerSales
    );

    if (lockerInfo) {
      submitLocker(lockerInfo, customerStaying);
    }

    const showerInfo = shoppingCart.find(
      (item) => item.category === transactionCategoryEnum.showerSales
    );

    if (showerInfo) {
      submitShowerCard(showerInfo, customerStaying);
    }
    setStepper(2);
  };

  const handleSetCustomerStaying = (guest: IExistingCustomerRow) => {
    const convertedGuest: IPOSCustomerStaying = {
      bookingId: "",
      customerStaying: guest.customerId,
      customerStayingName: guest.fullName,
      isMember: guest.memberTier !== "None",
    };

    setCustomerStaying(convertedGuest);
    handleCloseAssignGuestModal();
    handleCompletePOSSelection(convertedGuest);
  };

  const submitLocker = (
    item: IPaymentItem,
    customerStaying: IPOSCustomerStaying
  ) => {
    const duration = extractNumbersFromString(item.itemName);
    const startDate = new Date();
    const payload = {
      lockerCount: item?.quantity,
      guestId: customerStaying.customerStaying,
      startDatetime: stringDateToEpoch(startDate.toString()),
      duration: duration[0],
      lockerRate: item.price,
    };
    setIsLockerLoading(true);
    axiosInstance
      .post(`/bookings/v1/locker/locker-pos/`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const bookingId = response.data.data.bookingId;
        const lockers = response.data.data.lockers;
        setCustomerStaying((prevValue) => ({
          ...prevValue,
          bookingId: bookingId,
        }));
        setShoppingCart((prevValue) => {
          const filteredCart = prevValue.filter(
            (item) => item.category !== transactionCategoryEnum.lockerSales
          );

          const price = item.price;

          const assignedLockers: IPaymentItem[] = lockers.map(
            (locker: any) => ({
              itemId: locker.lockerId,
              itemName: locker.lockerCode,
              itemType: "Locker",
              category: transactionCategoryEnum.lockerSales,
              quantity: 1,
              price: price,
            })
          );

          return [...assignedLockers, ...filteredCart];
        });
      })
      .catch((error) => {

        if (
          error.response.status === 403 ||
          error.response.status === 400
        ) {
          Notification.failed(error.response.data.message);
        } else {
          Notification.failed("Something wrong. Failed Generating Locker, item has been deleted");
        }

        setShoppingCart((prevValue) => {
          const filteredCart = prevValue.filter(
            (item) => item.category !== transactionCategoryEnum.lockerSales
          );
          return filteredCart;
        });
      })
      .finally(() => setIsLockerLoading(false));
  };

  const submitShowerCard = (
    item: IPaymentItem,
    customerStaying: IPOSCustomerStaying
  ) => {
    // const duration = extractNumbersFromString(item.itemName);
    const duration = 1;
    const startDate = new Date();
    const payload = {
      showerCount: item?.quantity,
      guestId: customerStaying.customerStaying,
      startDatetime: stringDateToEpoch(startDate.toString()),
      duration: duration,
      showerRate: item.price,
    };
    setIsShowerLoading(true);
    axiosInstance
      .post(`/bookings/v1/locker/shower-pos/`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const bookingId = response.data.data.bookingId;
        // const lockers = response.data.data.lockers;
        setCustomerStaying((prevValue) => ({
          ...prevValue,
          bookingId: bookingId,
        }));
        // setShoppingCart((prevValue) => {
        //   const filteredCart = prevValue.filter(
        //     (item) => item.category !== transactionCategoryEnum.lockerSales
        //   );

        //   const price = item.price;

        //   const assignedLockers: IPaymentItem[] = lockers.map(
        //     (locker: any) => ({
        //       itemId: locker.lockerId,
        //       itemName: locker.lockerCode,
        //       itemType: "Locker",
        //       category: transactionCategoryEnum.lockerSales,
        //       quantity: 1,
        //       price: price,
        //     })
        //   );

        //   return [...assignedLockers, ...filteredCart];
        // });
      })
      .catch((error) => {
        Notification.failed("Failed Generating Locker, Item has been deleted");

        setShoppingCart((prevValue) => {
          const filteredCart = prevValue.filter(
            (item) => item.category !== transactionCategoryEnum.lockerSales
          );
          return filteredCart;
        });
      })
      .finally(() => setIsShowerLoading(false));
  };

  return (
    <Box width={"100%"} padding={2}>
      <Typography variant="h2">POS</Typography>
      <Stack direction={"row"} justifyContent={"center"} spacing={2}>
        {stepperValue.map((item, index) => (
          <Box
            key={index}
            display={"flex"}
            width={"300px"}
            height={"50px"}
            alignItems={"center"}
            border={1}
            borderColor={theme.palette.primary.main}
            sx={{
              backgroundColor:
                stepper === item.step ? theme.palette.primary.main : "black",
            }}
          >
            <Box
              display={"flex"}
              width={"100px"}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              borderRight={1}
              borderColor={theme.palette.primary.main}
              sx={{ backgroundColor: "black" }}
            >
              <Typography variant="h3">{item.step}</Typography>
            </Box>
            <Box width={"70%"}>
              <Typography
                variant="h3"
                color={stepper === item.step ? "black" : "white"}
                textAlign={"center"}
              >
                {item.text}
              </Typography>
            </Box>
          </Box>
        ))}
      </Stack>
      {stepper === 1 ? (
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
          <Box
            display={"flex"}
            alignItems={"center"}
            padding={2}
            bgcolor={theme.palette.background.default}
            marginTop={3}
            width={"1400px"}
            border={2}
            borderBottom={0}
            borderColor={theme.palette.primary.main}
            height={"45px"}
          >
            <Typography variant={"h3"} color={"primary"}>
              POS Menu
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"1400px"}
            border={2}
            borderColor={theme.palette.primary.main}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              padding={2}
              height={"65px"}
              bgcolor={theme.palette.background.paper}
              alignItems={"center"}
            >
              <Typography fontSize={"18px"} fontWeight={"bold"} width={"20%"}>
                Customer Info
              </Typography>
              <Select
                size="small"
                value={selectedCustomerIndex}
                onChange={(event) => {
                  setSelectedCustomerIndex(Number(event.target.value));
                  setShoppingCart([]);
                }}
                renderValue={(value) => {
                  if (value >= 0) {
                    const selectedCustomer = customerFilter[value];
                    return (
                      <CustomerFilterBox
                        data={selectedCustomer}
                        emptyDate={!selectedCustomer.bookingMade}
                      />
                    );
                  }
                  return (
                    <Typography color={"grey"}>
                      Please select a Customer
                    </Typography>
                  );
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
                sx={{ width: "80%" }}
              >
                <MenuItem value={""} sx={{ pointerEvents: "none" }}>
                  <Stack width={"100%"} direction={"row"}>
                    <Typography width={"45%"} color={"primary"}>
                      Name
                    </Typography>
                    <Typography width={"15%"} color={"primary"}>
                      Booking No.
                    </Typography>
                    <Typography width={"20%"} color={"primary"}>
                      Booking Datetime
                    </Typography>
                    <Typography width={"10%"} color={"primary"}>
                      Room No.
                    </Typography>
                    <Typography width={"10%"} color={"primary"}>
                      Room Type
                    </Typography>
                  </Stack>
                </MenuItem>
                {customerFilter.map((data, index) => (
                  <MenuItem value={index} key={index}>
                    <CustomerFilterBox data={data} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Stack direction={"row"} width={"100%"}>
              <Box width={"15%"} bgcolor={theme.palette.background.default}>
                <Box
                  display={"flex"}
                  borderBottom={2}
                  borderColor={theme.palette.primary.main}
                  justifyContent={"center"}
                  alignItems={"center"}
                  padding={2}
                >
                  <ButtonBase
                    onClick={handleOpenScanBarcodeModal}
                    sx={{ width: "100%" }}
                  >
                    <Box
                      display={"flex"}
                      width={"100%"}
                      height={"50px"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      bgcolor={theme.palette.background.paper}
                    >
                      <QrCode color="primary" />
                      <Typography variant="h4" marginLeft={1}>
                        Scan QR
                      </Typography>
                    </Box>
                  </ButtonBase>
                </Box>
                <Typography variant="h3" margin={2}>
                  Categories
                </Typography>
                <ButtonBase
                  onClick={() => {
                    setSelectedCategory("");
                  }}
                  sx={{ width: "100%" }}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    padding={2}
                    bgcolor={
                      selectedCategory === "" ? theme.palette.primary.main : ""
                    }
                    sx={{ height: "50px", width: "100%" }}
                  >
                    <Typography
                      variant="h4"
                      color={selectedCategory === "" ? "black" : "primary"}
                    >
                      {"All"}
                    </Typography>
                  </Box>
                </ButtonBase>
                {Object.keys(itemList).map((key) => (
                  <ButtonBase
                    key={key}
                    onClick={() => {
                      setSelectedCategory(key);
                    }}
                    sx={{ width: "100%" }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      padding={2}
                      bgcolor={
                        selectedCategory === key
                          ? theme.palette.primary.main
                          : ""
                      }
                      sx={{ height: "50px", width: "100%" }}
                    >
                      <Typography
                        variant="h4"
                        color={selectedCategory === key ? "black" : "primary"}
                      >
                        {key}
                      </Typography>
                    </Box>
                  </ButtonBase>
                ))}
              </Box>
              <Box
                width={"85%"}
                height={"59vh"}
                paddingX={2}
                paddingY={3}
                overflow={"scroll"}
              >
                {Object.keys(itemList)
                  .filter(
                    (category) =>
                      selectedCategory === "" ||
                      (selectedCategory !== "" && category === selectedCategory)
                  )
                  .map((key) => (
                    <Box key={key}>
                      <Typography variant="h3" marginBottom={2}>
                        {key}
                      </Typography>
                      <Grid
                        container
                        direction={"row"}
                        width={"90%"}
                        spacing={2}
                        columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }}
                        marginBottom={2}
                      >
                        {itemList[key].map((item, index) => (
                          <Grid
                            item
                            key={index}
                            xs={1}
                            sm={1}
                            md={1}
                            lg={1}
                            xl={1}
                          >
                            <POSItem
                              merch={item}
                              shoppingCart={shoppingCart}
                              handleAddToShoppingCart={handleAddToShoppingCart}
                            />
                          </Grid>
                        ))}
                      </Grid>
                      <Divider
                        orientation={"horizontal"}
                        flexItem
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          borderWidth: "1px",
                          marginY: 2,
                        }}
                      />
                    </Box>
                  ))}
              </Box>
            </Stack>
          </Box>
          <Box
            display={"flex"}
            width={"1400px"}
            height={"60px"}
            bgcolor={theme.palette.background.paper}
            border={2}
            borderTop={0}
            borderColor={theme.palette.primary.main}
            justifyContent={"end"}
            alignItems={"center"}
            paddingX={2}
          >
            <Button
              variant={"contained"}
              disabled={shoppingCart.length < 1}
              onClick={() => handleCompletePOSSelection(customerStaying)}
              sx={{ height: "35px", width: "150px" }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          display={"flex"}
          alignItems={"start"}
          flexDirection={"column"}
          marginTop={1.5}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setStepper(1);
              setCustomerStaying(initialCustomerStaying);
              setShoppingCart([]);
            }}
            sx={{ marginX: "10%", marginBottom: 1.5 }}
          >
            <KeyboardReturn /> Back
          </Button>
          <PaymentForm
            paymentItem={[]}
            getPastTransactions={true}
            latestBookingStatus={"POS"}
            bookingId={customerStaying.bookingId}
            bookingGuest={{
              customerStayingId: customerStaying.customerStaying,
              customerStayingName: customerStaying.customerStayingName,
              guests: [
                {
                  guestId: customerStaying.customerStaying,
                  guestName: customerStaying.customerStayingName,
                  isMember: customerStaying.isMember,
                },
              ],
            }}
            width={"100%"}
            margin={"0"}
            height={"100%"}
            posItem={shoppingCart}
            posFeesList={posFeesList}
            isExpressBooking={false}
            handleCompletePOSPayment={handleCompletePOSPayment}
            isLoading={isLockerLoading || isShowerLoading}
          />
        </Box>
      )}
      <ScanBarcodeModal
        openScanBarcodeModal={openScanBarcodeModal}
        handleCloseScanBarcodeModal={handleCloseScanBarcodeModal}
        handleScanBarcode={handleScanBarcode}
      />
      <GuestAddAssignModal
        open={openAssignGuestModal}
        handleClose={handleCloseAssignGuestModal}
        handleSetCustomerStaying={handleSetCustomerStaying}
      />
    </Box>
  );
};

const CustomerFilterBox = ({
  data,
  emptyDate,
}: {
  data: ICustomerFilter;
  emptyDate?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Stack width={"100%"} direction={"row"} color={"primary"}>
      <Typography width={"44%"}>{data.customerName}</Typography>
      <div
        style={{
          borderLeft: `1px solid ${theme.palette.primary.main}`,
          width: "1%",
        }}
      />
      <Typography width={"14%"}>{data.bookingNo}</Typography>
      <div
        style={{
          borderLeft: `1px solid ${theme.palette.primary.main}`,
          width: "1%",
        }}
      />
      <Typography width={"19%"}>
        {data.bookingId
          ? format(parseISO(data.bookingMade.toString()), "yyyy-MM-dd HH:mm:ss")
          : ""}
      </Typography>
      <div
        style={{
          borderLeft: `1px solid ${theme.palette.primary.main}`,
          width: "1%",
        }}
      />
      <Typography width={"9%"}>{data.roomNo}</Typography>
      <div
        style={{
          borderLeft: `1px solid ${theme.palette.primary.main}`,
          width: "1%",
        }}
      />
      <Typography width={"10%"}>{data.roomType}</Typography>
    </Stack>
  );
};

const ScanBarcodeModal = (props: {
  openScanBarcodeModal: boolean;
  handleCloseScanBarcodeModal: VoidFunction;
  handleScanBarcode: (value: string) => void;
}) => {
  const theme = useTheme();

  const [barcodeField, setBarcodeField] = useState<string>("");
  return (
    <Modal
      open={props.openScanBarcodeModal}
      onClose={props.handleCloseScanBarcodeModal}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box
        width={"600px"}
        minWidth={"38em"}
        border={`3px solid ${theme.palette.primary.main}`}
        margin={"auto"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
        }}
      >
        <Box
          height={"40px"}
          paddingY={1}
          paddingX={2}
          sx={{
            backgroundColor: theme.palette.background.default,
          }}
          borderBottom={`3px solid ${theme.palette.primary.main}`}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Typography variant={"h3"} color={theme.palette.primary.main}>
            {"Scan Barcode"}
          </Typography>
          <IconButton onClick={props.handleCloseScanBarcodeModal}>
            <HighlightOffRoundedIcon
              sx={{
                color: theme.palette.primary.main,
                fontSize: "24px",
              }}
            />
          </IconButton>
        </Box>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            props.handleScanBarcode(barcodeField);
          }}
        >
          <Box
            display={"flex"}
            height={"150px"}
            bgcolor={"black"}
            justifyContent={"space-between"}
            alignItems={"center"}
            paddingX={2}
          >
            <Typography variant="h4" marginRight={3}>
              Barcode
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              value={barcodeField}
              onChange={(event) => setBarcodeField(event.target.value)}
            />
          </Box>
        </form>
        <Box
          display={"flex"}
          height={"50px"}
          bgcolor={theme.palette.background.paper}
          justifyContent={"end"}
          padding={1}
          borderTop={2}
          borderColor={theme.palette.primary.main}
        >
          <Button
            variant={"outlined"}
            onClick={props.handleCloseScanBarcodeModal}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default POSPage;
