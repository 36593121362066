import {
  Box,
  IconButton,
  Stack,
  MenuItem,
  Select,
  Typography,
  Checkbox,
  FormControlLabel,
  Switch,
  Radio,
  RadioGroup,
  FormControl,
  InputLabel,
  useTheme,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import Notification from "../../utils/notificationConfig";
import { SortByAlphaOutlined, SortTwoTone } from "@mui/icons-material";
import { bookingStatusEnum } from "../../constants/enums";

type Props = {
  platforms: { platformId: string; platformName: string }[];
  platformFilters: string[];
  handlePlatformFiltersOnChange: (
    platformFilter: string | string[],
    isChecked: boolean,
    isAll: boolean
  ) => void;

  roomStatusFilters: string[];
  handleRoomStatusFiltersOnChange: (
    roomStatusFilter: string,
    isChecked: boolean
  ) => void;

  isAscend: boolean;
  handleIsAscendOnChange: (isAscend: boolean) => void;

  sortByValue: string;
  handleSortByValueOnChange: (
    sortByValue: "room_code" | "availabiltiy"
  ) => void;

  handleGanttChartChangeOnClick: () => void;
  handleBookingStatusFilterChange: (bookingStatus: string) => void;
};

const GanttChartFilterBar = ({
  platforms,
  platformFilters,
  handlePlatformFiltersOnChange,
  roomStatusFilters,
  handleRoomStatusFiltersOnChange,
  isAscend,
  handleIsAscendOnChange,
  sortByValue,
  handleSortByValueOnChange,
  handleGanttChartChangeOnClick,
  handleBookingStatusFilterChange,
}: Props) => {
  const [isCheckedRoomStatus, setIsCheckedRoomStatus] = useState<{
    all: boolean;
    vacantCleaned: boolean;
    vacantDirty: boolean;
    occupiedCleaned: boolean;
    occupiedDirty: boolean;
    maintenance: boolean;
  }>({
    all: true,
    vacantCleaned: true,
    vacantDirty: true,
    occupiedCleaned: true,
    occupiedDirty: true,
    maintenance: true,
  });

  const theme = useTheme();

  const [isCheckedPlatform, setIsCheckedPlatform] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    const returned: { [key: string]: boolean } = {};
    platforms.forEach(({ platformName: platform }) => {
      returned[platform] = true;
    });
    returned["all"] = true;

    setIsCheckedPlatform(returned);
  }, [platforms]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleSortMenuClose = () => setAnchorEl(null);
  const handleSortMenuOpen = ({
    currentTarget,
  }: React.MouseEvent<HTMLElement>) => setAnchorEl(currentTarget);

  return (
    <Box
      sx={{
        height: "5em",
        width: "100%",
        backgroundColor: theme.palette.background.default,
        display: "flex",
        paddingY: "3.2em",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingLeft={3}
      >
        <FilterAltIcon fontSize={"medium"} />
        <Typography fontWeight={600} variant="h3">
          Gantt Chart
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        alignContent={"center"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        width={"80%"}
        sx={{
          height: "100%",
          borderRadius: "0.8em",
          position: "relative",
          marginLeft: 3,
          // backgroundColor: "#123533",
        }}
      >
        <Stack
          direction={"row"}
          padding={"0.3em"}
          height={"100%"}
          alignItems={"center"}
          color={"white"}
          ml={0}
          justifyContent={"space-between"}
          width={"85%"}
          paddingX={0}
        >
          <FormControl>
            <InputLabel variant="filled">Book Status</InputLabel>
            <Select
              variant="filled"
              label="Book Status"
              onChange={(event: any) => {
                handleBookingStatusFilterChange(event.target.value);
              }}
              sx={{
                width: "15em",
                ".MuiSelect-select": {
                  border: "1px solid #148978",
                },
                ".MuiSelect-icon": {
                  fill: "white",
                },
              }}
            >
              <MenuItem key={"All"} value={"All"}>
                All
              </MenuItem>
              {Object.values(bookingStatusEnum).map((enumValue, index) => (
                <MenuItem key={index} value={enumValue}>
                  {enumValue}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel variant="filled">Room Status</InputLabel>
            <Select
              variant="filled"
              id={"status-select-id"}
              value={roomStatusFilters}
              multiple
              renderValue={() => {
                let displayValue: string = "";
                if (isCheckedRoomStatus.all)
                  return displayValue.concat("All Status");
                displayValue = "";
                if (
                  !isCheckedRoomStatus.all &&
                  isCheckedRoomStatus.vacantCleaned
                )
                  displayValue += displayValue.concat("Vacant, Cleaned");
                if (
                  isCheckedRoomStatus.vacantCleaned &&
                  isCheckedRoomStatus.vacantDirty
                )
                  displayValue += displayValue.concat(" | ");
                if (isCheckedRoomStatus.vacantDirty)
                  displayValue += displayValue.concat("Vacant, Dirty");
                if (
                  isCheckedRoomStatus.vacantDirty &&
                  isCheckedRoomStatus.occupiedCleaned
                )
                  displayValue += displayValue.concat(" | ");
                if (isCheckedRoomStatus.occupiedCleaned)
                  displayValue += displayValue.concat("Occupied, Cleaned");
                if (
                  isCheckedRoomStatus.occupiedCleaned &&
                  isCheckedRoomStatus.occupiedDirty
                )
                  displayValue += displayValue.concat(" | ");
                if (isCheckedRoomStatus.occupiedDirty)
                  displayValue += displayValue.concat("Occupied, Dirty");
                if (
                  isCheckedRoomStatus.occupiedDirty &&
                  isCheckedRoomStatus.maintenance
                )
                  displayValue += displayValue.concat(" | ");
                if (isCheckedRoomStatus.maintenance)
                  displayValue += displayValue.concat("Maintenance");
                return displayValue;
              }}
              sx={{
                width: "15em",
                ".MuiSelect-select": {
                  border: "1px solid #148978",
                },
                ".MuiSelect-icon": {
                  fill: "white",
                },
              }}
            >
              <MenuItem value={"all"}>
                <FormControlLabel
                  label="All Status"
                  control={
                    <Checkbox
                      checked={isCheckedRoomStatus.all}
                      onChange={({ target: { checked } }) => {
                        setIsCheckedRoomStatus({
                          all: checked,
                          vacantCleaned: checked,
                          vacantDirty: checked,
                          occupiedCleaned: checked,
                          occupiedDirty: checked,
                          maintenance: checked,
                        });
                        handleRoomStatusFiltersOnChange("all", checked);
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={"vacant cleaned"}>
                <FormControlLabel
                  label="Vacant, Cleaned"
                  control={
                    <Checkbox
                      checked={isCheckedRoomStatus.vacantCleaned}
                      onChange={({ target: { checked } }) => {
                        setIsCheckedRoomStatus({
                          ...isCheckedRoomStatus,
                          vacantCleaned: checked,
                          all:
                            checked &&
                            isCheckedRoomStatus.vacantDirty &&
                            isCheckedRoomStatus.occupiedCleaned &&
                            isCheckedRoomStatus.occupiedDirty &&
                            isCheckedRoomStatus.maintenance,
                        });
                        handleRoomStatusFiltersOnChange(
                          "vacant, cleaned",
                          checked
                        );
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={"vacant dirty"}>
                <FormControlLabel
                  label="Vacant, Dirty"
                  control={
                    <Checkbox
                      checked={isCheckedRoomStatus.vacantDirty}
                      onChange={({ target: { checked } }) => {
                        setIsCheckedRoomStatus({
                          ...isCheckedRoomStatus,
                          vacantDirty: checked,
                          all:
                            isCheckedRoomStatus.vacantCleaned &&
                            checked &&
                            isCheckedRoomStatus.occupiedCleaned &&
                            isCheckedRoomStatus.occupiedDirty &&
                            isCheckedRoomStatus.maintenance,
                        });
                        handleRoomStatusFiltersOnChange(
                          "vacant, dirty",
                          checked
                        );
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={"occupied cleaned"}>
                <FormControlLabel
                  label="Occupied, Cleaned"
                  control={
                    <Checkbox
                      checked={isCheckedRoomStatus.occupiedCleaned}
                      onChange={({ target: { checked } }) => {
                        setIsCheckedRoomStatus({
                          ...isCheckedRoomStatus,
                          occupiedCleaned: checked,
                          all:
                            isCheckedRoomStatus.vacantCleaned &&
                            isCheckedRoomStatus.vacantDirty &&
                            isCheckedRoomStatus.occupiedDirty &&
                            isCheckedRoomStatus.maintenance &&
                            checked,
                        });
                        handleRoomStatusFiltersOnChange(
                          "occupied, cleaned",
                          checked
                        );
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={"occupied dirty"}>
                <FormControlLabel
                  label="Occupied, Dirty"
                  control={
                    <Checkbox
                      checked={isCheckedRoomStatus.occupiedDirty}
                      onChange={({ target: { checked } }) => {
                        setIsCheckedRoomStatus({
                          ...isCheckedRoomStatus,
                          occupiedDirty: checked,
                          all:
                            isCheckedRoomStatus.vacantCleaned &&
                            isCheckedRoomStatus.vacantDirty &&
                            isCheckedRoomStatus.occupiedCleaned &&
                            checked &&
                            isCheckedRoomStatus.maintenance,
                        });
                        handleRoomStatusFiltersOnChange(
                          "occupied, dirty",
                          checked
                        );
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={"maintenance"}>
                <FormControlLabel
                  label="Maintenance"
                  control={
                    <Checkbox
                      checked={isCheckedRoomStatus.maintenance}
                      onChange={({ target: { checked } }) => {
                        setIsCheckedRoomStatus({
                          ...isCheckedRoomStatus,
                          maintenance: checked,
                          all:
                            isCheckedRoomStatus.vacantCleaned &&
                            isCheckedRoomStatus.vacantDirty &&
                            isCheckedRoomStatus.occupiedCleaned &&
                            isCheckedRoomStatus.occupiedDirty &&
                            checked,
                        });
                        handleRoomStatusFiltersOnChange("maintenance", checked);
                      }}
                    />
                  }
                />
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel variant="filled">Platform</InputLabel>
            <Select
              variant="filled"
              id={"platform-select-id"}
              value={platformFilters}
              multiple
              renderValue={() => {
                let displayValue: string[] = ["All Platforms"];
                if (isCheckedPlatform.all) return displayValue.join(" | ");
                displayValue = [];
                displayValue = Object.keys(isCheckedPlatform).filter(
                  (key) => key !== "all" && isCheckedPlatform[key]
                );
                return displayValue.join(" | ");
              }}
              sx={{
                width: "15em",
                ".MuiSelect-select": {
                  border: "1px solid #148978",
                },
                ".MuiSelect-icon": {
                  fill: "white",
                },
              }}
            >
              <MenuItem value={"all"}>
                <FormControlLabel
                  label="All Platforms"
                  control={
                    <Checkbox
                      checked={isCheckedPlatform.all}
                      onChange={({ target: { checked } }) => {
                        setIsCheckedPlatform({
                          all: checked,
                          ...platforms.reduce(
                            (returned, platform) => ({
                              ...returned,
                              [platform.platformName]: checked,
                            }),
                            {}
                          ),
                        });
                        handlePlatformFiltersOnChange(
                          Object.keys(isCheckedPlatform),
                          checked,
                          true
                        );
                      }}
                    />
                  }
                />
              </MenuItem>
              {platforms.map((platform) => (
                <MenuItem
                  key={platform.platformId}
                  value={platform.platformName}
                >
                  <FormControlLabel
                    label={platform.platformName}
                    control={
                      <Checkbox
                        checked={isCheckedPlatform[platform.platformName]}
                        onChange={({ target: { checked } }) => {
                          const {
                            [platform.platformName]: _,
                            all,
                            ...rest
                          } = isCheckedPlatform;
                          setIsCheckedPlatform({
                            ...isCheckedPlatform,
                            [platform.platformName]: checked,
                            all:
                              Object.values(rest).every(
                                (value) => value === true
                              ) && checked
                                ? true
                                : false,
                          });
                          handlePlatformFiltersOnChange(
                            platform.platformName,
                            checked,
                            false
                          );
                        }}
                      />
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <SortTwoTone fontSize="large" sx={{ marginX: 0 }} />
          <FormControl>
            <InputLabel variant="filled">Sort By</InputLabel>
            <Select
              variant="filled"
              id={"sort-select-id"}
              value={"Sort"}
              renderValue={(value) => value}
              SelectDisplayProps={{
                onClick: handleSortMenuOpen,
              }}
              MenuProps={{
                keepMounted: true,
                anchorEl: anchorEl,
                open: Boolean(anchorEl),
                onClose: handleSortMenuClose,
                sx: {
                  ".MuiMenu-list": {
                    color: "black",
                  },
                },
              }}
              sx={{
                width: "15em",
                ".MuiSelect-select": {
                  border: "1px solid #148978",
                },
                ".MuiSelect-icon": {
                  fill: "white",
                },
              }}
            >
              {/**
               * this MenuItem is used to disable the warning of Select component
               * when the because the value in Select is used as a placeholder is not in the MenuItem list
               */}
              <MenuItem
                value={"Sort"}
                disabled
                sx={{
                  display: "none",
                }}
              >
                Sort
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  label="Descend | Ascend"
                  control={
                    <Switch
                      checked={isAscend}
                      onChange={({ target: { checked } }) =>
                        handleIsAscendOnChange(checked)
                      }
                    />
                  }
                />
              </MenuItem>
              <MenuItem>
                <RadioGroup
                  name={"sort-by"}
                  value={sortByValue}
                  onChange={({ target: { value } }) =>
                    handleSortByValueOnChange(
                      value as "room_code" | "availabiltiy"
                    )
                  }
                >
                  <FormControlLabel
                    value="room_code"
                    control={<Radio />}
                    label="Room No."
                  />
                  <FormControlLabel
                    value="availability"
                    control={<Radio />}
                    label="Availability."
                  />
                </RadioGroup>
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <IconButton
          sx={{
            height: "2em",
            // marginTop: "0.7em",
            color: "white",
            backgroundColor: "#195a56",
            borderRadius: "0.2em",
            marginLeft: "1em",
          }}
          onClick={() => {
            if (
              platformFilters.length === 0 &&
              roomStatusFilters.length === 0
            ) {
              Notification.failed(
                "Please select at least one platform and room status to filter."
              );
              return;
            } else if (platformFilters.length === 0) {
              Notification.failed(
                "Please select at least one platform to filter."
              );
              return;
            } else if (roomStatusFilters.length === 0) {
              Notification.failed(
                "Please select at least one room status to filter."
              );
              return;
            }
            handleGanttChartChangeOnClick();
          }}
        >
          <SearchIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default GanttChartFilterBar;
