import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import theme from "../../assets/theme/theme";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Add,
  AddCircleOutline,
  FilterAlt,
  Remove,
  RssFeed,
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosInstance from "../../constants/axiosConfig";
import { RoomBooking } from "../../pages/booking/interface";
import AddIcon from "@mui/icons-material/Add";
import Notification from "../../utils/notificationConfig";
import {
  IExistingCustomerRow,
  IIdType,
} from "../../models/guests/GuestsInterface";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import BASE_API from "../../constants/api";
import axios from "axios";
import { convertScannedDataToIGuestInfo } from "../../utils/convertScannedDatatoIGuestInfo";
import { useDebounce } from "../../hooks/useDebounce";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type Props = {
  openGuestBooking: boolean;
  handleGuestClose: () => void;
  roomBookings: RoomBooking[];
  bookingId: string;
  handleOpenAssignGuestModal: VoidFunction;
};

type ExtraGuest = {
  name: string;
  idNo: string;
  idType: string;
  booking?: string;
  extraGuestId?: string;
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  border: 2,
  borderColor: theme.palette.primary.main,
};

const modalStyle2 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  border: 2,
  borderColor: theme.palette.primary.main,
};

const GuestCapsule2 = ({
  //Guest Capsule for Extra Guest
  guest,
  onDelete,
}: {
  guest: ExtraGuest;
  onDelete: (guest: ExtraGuest) => void;
}) => {
  const handleDeleteClick = () => {
    onDelete(guest);
  };

  return (
    <Grid
      container
      border={2}
      borderColor={theme.palette.primary.main}
      minWidth={"100%"}
    >
      <Grid item md={3} sm={3} xs={3} sx={{ background: "#232323" }}></Grid>
      <Grid
        item
        md={9}
        sm={9}
        xs={9}
        sx={{
          borderLeft: 2,
          borderColor: theme.palette.primary.main,
          padding: 1.5,
          background: "black",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontWeight={"bold"}>{guest.idNo}</Typography>
          <IconButton onClick={handleDeleteClick} sx={{ padding: 0 }}>
            <DeleteIcon fontSize={"small"} sx={{ color: "red" }} />
          </IconButton>
        </Stack>
        <Typography fontWeight={"bold"}>{guest.name}</Typography>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography fontSize={"14px"} color={"error"} fontWeight={"bold"}>
            Incomplete Profile
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

type GuestCapsuleProps = {
  room: RoomBooking;
  memberTier: string; // Define the type of memberTier here, adjust it as per your data structure
};

const GuestCapsule: React.FC<GuestCapsuleProps> = ({ room, memberTier }) => {
  return (
    <Grid container border={2} borderColor={theme.palette.primary.main}>
      <Grid item md={3} sm={3} xs={3}></Grid>
      <Grid
        item
        md={9}
        sm={9}
        xs={9}
        sx={{
          borderLeft: 2,
          borderColor: theme.palette.primary.main,
          padding: 2,
          backgroundColor: "black",
        }}
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography fontWeight={"bold"}>
            {room.personInChargeCountry}
          </Typography>
        </Stack>
        <Typography variant="h3" sx={{ lineHeight: 1.5 }}>
          {room.personInCharge} {room.personInChargeLastname}
        </Typography>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography fontSize={"14px"} fontWeight={"bold"} color={"primary"}>
            Membership
          </Typography>
          <Typography fontWeight={"bold"}>{memberTier}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

function AddExtraGuestModal({
  openGuestBooking,
  handleGuestClose: handleGuestClose,
  roomBookings,
  bookingId,
  handleOpenAssignGuestModal,
}: Props) {
  const token = useSelector((state: RootState) => state.user.accessToken);
  const [isAddExtraGuestOpen, setIsAddExtraGuestOpen] = useState(false);
  const [addExtraGuestArray, setAddExtraGuestArray] = useState<ExtraGuest[]>(
    []
  );
  const [IcInputValue, setIcInputValue] = useState<string>("");
  const [idTypes, setIdTypes] = useState<IIdType[]>([]);
  const [isAddMoreButtonDisabled, setIsAddMoreButtonDisabled] = useState(true);
  const [isDoneButtonDisabled, setIsDoneButtonDisabled] = useState(true);
  const [validationError, setValidationError] = useState("");
  const [displayExtraGuestArray, setDisplayExtraGuestArray] = useState<
    ExtraGuest[]
  >([]);
  const [nameInputValue, setNameInputValue] = useState<string>("");
  const [isAddExistingButtonClicked, setIsAddExistingButtonClicked] =
    useState(false);
  const [pagination, setPagination] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({ pageIndex: 0, pageSize: 5 });

  const [existingCustomerList, setExistingCustomerList] = useState<
    IExistingCustomerRow[]
  >([]);

  const [idTypeList, setIdTypeList] = useState<IIdType[]>([]);

  const [selectedIdType, setSelectedIdType] = useState<string>("Passport");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchName, setSearchName] = useState<string>("");

  const handleAddExistingButton = () => {
    setIsAddExistingButtonClicked((prev) => !prev);
  };

  const handleOpenAddExtraGuest = () => {
    setIsAddExtraGuestOpen(true);
  };

  const handleCloseAddExtraGuest = () => {
    setIsAddExtraGuestOpen(false);
  };

  useEffect(() => {
    const fetchIdTypeData = async () => {
      try {
        const response = await axiosInstance.get("/guests/id-type/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setIdTypeList(response.data.data);
        }
      } catch (error) {
        Notification.failed("Failed to fetch id types");
      }
    };

    fetchIdTypeData();
  }, []);

  const [idInfoInput, setidInfoInput] = useState<string>("");
  const debouncedValue = useDebounce(idInfoInput, 500);

  const handleIdInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setidInfoInput(event.target.value);
  };

  const scannedData = convertScannedDataToIGuestInfo(debouncedValue);

  useEffect(() => {
    if (debouncedValue !== "" && scannedData.idNumber !== "") {
      axios
        .get(
          `${BASE_API}/guests/get_customer_by_id_no/?id_no=${scannedData.idNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          Notification.success("Guest Found");
          handleAddExtraGuestArray(
            response.data.firstname + " " + response.data.lastname,
            response.data.idNo,
            response.data.idType.idTypeId
          );
        })
        .catch((response) => {
          handleNameValueOnChange(
            scannedData.firstName + " " + scannedData.lastName
          );
          // setIcInputValue(response.data.idNo)
          handleIcValueOnChange(scannedData.idNumber);

          Notification.warning("Guest Not Found, Please Add New Guest");
        });
    }
  }, [scannedData.idNumber]);

  const handleChangeIdType = (event: string) => {
    setSelectedIdType(event);
  };

  const handleAddExtraGuestArray = (
    firstname: string,
    idNumber: string,
    idType: string
  ) => {
    const myKadIdType = idTypes.find((type) => type.idType === "MyKad");
    if (idType === myKadIdType?.idTypeId) {
      const malaysianICRegex = /^\d{6}\d{2}\d{4}$/;

      if (!malaysianICRegex.test(idNumber)) {
        Notification.failed("Please enter a proper Malaysian IC");
        return;
      }
    }
    setAddExtraGuestArray([
      ...addExtraGuestArray,
      {
        name: scannedData ? firstname : nameInputValue,
        idNo: scannedData ? idNumber : IcInputValue,
        idType: scannedData ? idType : selectedIdType,
        booking: bookingId,
      },
    ]);
    setIcInputValue("");
    setNameInputValue("");
  };

  const handleFetchExtraGuest = () => {
    axiosInstance
      .get(`/bookings/extra-guest/`, {
        params: {
          bookingId: bookingId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setDisplayExtraGuestArray(res.data);
        }
      })
      .catch((error) => {
        Notification.failed(error.toString());
      });
  };

  const handleSubmitExtraGuest = () => {
    const isGuestAlreadyAdded = addExtraGuestArray.some((item) =>
      displayExtraGuestArray.some((guest) => guest.idNo === item.idNo)
    );
    if (isGuestAlreadyAdded) {
      Notification.failed("Extra Guest has already been added.");
    } else {
      // If the guest is not in the displayExtraGuestArray state, post it
      const payload = {
        extraGuests: addExtraGuestArray,
        booking: bookingId,
      };

      axiosInstance
        .post("/bookings/extra-guest/", payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 201) {
            Notification.success("Guest Successfully Added!");
            // setDisplayExtraGuestArray([...displayExtraGuestArray, item]);
            handleFetchExtraGuest();
            setAddExtraGuestArray([]);
            handleCloseAddExtraGuest();
          }
        })
        .catch((error) => {
          if (error.response) {
            const errorsList = error.response.data;
            for (const errorKey in errorsList) {
              if (errorsList.hasOwnProperty(errorKey)) {
                Notification.failed(errorsList[errorKey][0]);
              }
            }
          }
        });
    }
    setAddExtraGuestArray([]);
    handleCloseAddExtraGuest();
  };

  const handleIcValueOnChange = (input: string) => {
    setIcInputValue(input);
  };

  const handleIcTypeValueOnChange = (input: string) => {
    setSelectedIdType(input);
  };

  const handleNameValueOnChange = (input: string) => {
    setNameInputValue(input);
  };

  const handleIcValueValidation = () => {
    if (IcInputValue.length > 15) {
      setValidationError("IC/Passport cannot be longer than 15 characters");
      setIsAddMoreButtonDisabled(true);
    } else {
      setValidationError("");
    }
  };

  const handleDeleteAddExtraGuest = (guest: ExtraGuest) => {
    const guestIndex = addExtraGuestArray.findIndex(
      (item) => item.idNo === guest.idNo
    );
    if (guestIndex !== -1) {
      const updatedExtraGuestArray = [...addExtraGuestArray];
      updatedExtraGuestArray.splice(guestIndex, 1);
      setAddExtraGuestArray(updatedExtraGuestArray);
    }
  };

  const handleDeleteExtraGuest = (guest: ExtraGuest) => {
    const guestIndex = displayExtraGuestArray.findIndex(
      (item) => item.extraGuestId === guest.extraGuestId
    );
    if (guestIndex !== -1) {
      if (guestIndex !== -1) {
        const extraGuestId = displayExtraGuestArray[guestIndex].extraGuestId;
        axiosInstance
          .delete(`/bookings/extra-guest/${extraGuestId}/`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            //when status code gets updated dont forget to add it
            const updatedExtraGuestArray = [...displayExtraGuestArray];
            updatedExtraGuestArray.splice(guestIndex, 1);
            setDisplayExtraGuestArray(updatedExtraGuestArray);
            Notification.success("Extra Guest Successfuly deleted");
          })
          .catch((error) => {
            Notification.failed(error.toString());
          });
      } else {
        Notification.failed("something wrong please try again later");
      }
    }
  };

  useEffect(() => {
    handleIcValueValidation();
    if (!validationError) {
      if (nameInputValue && IcInputValue) {
        setIsAddMoreButtonDisabled(false);
      } else {
        setIsAddMoreButtonDisabled(true);
      }
    }
  }, [nameInputValue, IcInputValue]);

  useEffect(() => {
    if (addExtraGuestArray.length > 0) {
      setIsDoneButtonDisabled(false);
    } else {
      setIsDoneButtonDisabled(true);
    }
  }, [addExtraGuestArray]);

  useEffect(() => {
    const fetchIdType = () => {
      axiosInstance
        .get(`${BASE_API}/guests/id-type`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIdTypes(response.data.data);
          setSelectedIdType(response.data.data[0].idTypeId);
        });
    };

    handleFetchExtraGuest();
    fetchIdType();
  }, []);

  const fetchExistingCustomer = () => {
    const apiUrl = `/guests/?name=${searchName}`;
    setIsLoading(true);

    axiosInstance
      .get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const existingCustomerRows = response.data.map((row: any) => {
          return {
            customerId: row.customerId,
            fullName: row.firstname + " " + row.lastname,
            icNo: row.idNo,
            memberId: row.memberId,
            memberTier: row.member.memberTier,
            memberCondition: row.memberCondition,
            isEmptyRow: false,
            idType: row.idType,
          };
        });
        setExistingCustomerList(existingCustomerRows);
      })
      .catch((response) => {
        Notification.failed(response.data.message);
      })
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    fetchExistingCustomer();
  }, [isAddExtraGuestOpen]);

  const AddExistingGuestToArray = (row: any) => {
    const newExtraGuest: ExtraGuest = {
      name: row.original.fullName,
      idNo: row.original.icNo.replace(/-/g, ""),
      idType: row.original.idType.idTypeId,
      booking: bookingId,
      extraGuestId: "",
    };

    setAddExtraGuestArray((prevValue) => {
      const isGuestAlreadyAdded = prevValue.some(
        (guest) => guest.idNo === newExtraGuest.idNo
      );

      if (!isGuestAlreadyAdded) {
        return [...prevValue, newExtraGuest];
      } else {
        Notification.failed("Extra Guest has been included");
        return prevValue;
      }
    });
  };

  const columns = useMemo<MRT_ColumnDef<IExistingCustomerRow>[]>(
    () => [
      {
        accessorKey: "fullName",
        header: "Name",
      },
      {
        accessorKey: "icNo",
        header: "ID Number",
      },
      {
        header: "Action",
        size: 50,
        Cell: ({ row }) => (
          <Button onClick={() => AddExistingGuestToArray(row)} variant={"text"}>
            Add
          </Button>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (scannedData.idNumber) {
      const scannedIdType = idTypes.find(
        (idType) =>
          idType.idType.toUpperCase() === scannedData.idType.toUpperCase()
      );
      setSelectedIdType(scannedIdType ? scannedIdType.idTypeId : "");
    }
  }, [idTypes, scannedData]);

  const customerTierMap: { [key: string]: string } = {};

  existingCustomerList.forEach((customer: any) => {
    customerTierMap[customer.customerId] = customer.memberTier;
  });

  return (
    <>
      {/* -------------------------------------------------------------------------- */
      /*                   Modal to display guest and extra guest                   */
      /* -------------------------------------------------------------------------- */}

      <Modal
        open={openGuestBooking}
        onClose={handleGuestClose}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          },
        }}
      >
        <Box sx={modalStyle} bgcolor={theme.palette.background.default}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
            sx={{ background: "#232323" }}
          >
            <Grid container spacing={0} sx={{ p: 0, width: "100%" }}>
              <Grid item xs={11}>
                <Stack
                  display={"flex"}
                  sx={{
                    p: 2,
                    border: 0,
                    borderColor: theme.palette.primary.main,
                    height: "50px",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h2" color={theme.palette.primary.main}>
                    Guest
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <Stack
                  display={"flex"}
                  sx={{
                    p: 1,
                    border: 0,
                    borderColor: theme.palette.primary.main,
                    height: "50px",
                    justifyContent: "center",
                  }}
                >
                  <IconButton onClick={handleGuestClose} sx={{ padding: 0 }}>
                    <HighlightOffIcon
                      fontSize="large"
                      sx={{ color: theme.palette.primary.main }}
                    />
                  </IconButton>
                </Stack>
              </Grid>
              <Grid item xs={9}>
                <Stack
                  sx={{
                    p: 2,
                    borderTop: 2,
                    borderColor: theme.palette.primary.main,
                    height: "50px",
                  }}
                >
                  <Typography variant="h3">Customer</Typography>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack
                  spacing={2}
                  sx={{
                    p: 2,
                    alignItems: "end",
                    borderTop: 2,
                    borderColor: theme.palette.primary.main,
                    height: "80px",
                  }}
                >
                  <Button
                    onClick={handleOpenAssignGuestModal}
                    sx={{
                      width: "90%",
                      borderRadius: 0,
                      color: "white",
                      border: 2,
                      borderColor: theme.palette.primary.main,
                    }}
                  >
                    <AddCircleOutline fontSize="large" />
                    Assign Guest
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack sx={{ height: "250px" }}>
                  <Grid container spacing={0}>
                    {roomBookings.map((room: RoomBooking, index) => {
                      const matchingCustomer = existingCustomerList.find(
                        (customer) =>
                          customer.customerId === room.personInChargeId
                      );
                      const memberTier = matchingCustomer
                        ? matchingCustomer.memberTier
                        : "N/A";
                      return (
                        <Grid key={index} item xs={4}>
                          <Stack p={2}>
                            <GuestCapsule room={room} memberTier={memberTier} />
                          </Stack>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={9}>
                <Stack
                  sx={{
                    p: 2,
                    borderTop: 2,
                    borderColor: theme.palette.primary.main,
                    height: "50px",
                  }}
                >
                  <Typography variant="h3">Extra Guest</Typography>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack
                  sx={{
                    p: 2,
                    alignItems: "end",
                    borderTop: 2,
                    borderColor: theme.palette.primary.main,
                    height: "80px",
                  }}
                >
                  <Button
                    onClick={handleOpenAddExtraGuest}
                    sx={{
                      width: "90%",
                      borderRadius: 0,
                      color: "white",
                      border: 2,
                      borderColor: theme.palette.primary.main,
                    }}
                  >
                    <AddCircleOutline fontSize="large" />
                    Add Extra Guest
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack sx={{ height: "250px" }}>
                  <Grid container spacing={0}>
                    {displayExtraGuestArray.map(
                      (extraGuest: ExtraGuest, index) => (
                        <Grid item key={index} xs={4}>
                          <Stack p={2}>
                            <GuestCapsule2
                              guest={extraGuest}
                              onDelete={handleDeleteExtraGuest}
                            />
                          </Stack>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Modal>

      {/* -------------------------------------------------------------------------- */
      /*                            add extra guest modal                           */
      /* -------------------------------------------------------------------------- */}

      <Modal
        open={isAddExtraGuestOpen}
        onClose={handleCloseAddExtraGuest}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          },
        }}
      >
        <Box sx={modalStyle2} bgcolor={theme.palette.background.default}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            paddingY={1}
            paddingX={2}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
            sx={{ background: "#232323" }}
          >
            <Typography variant={"h4"} color={theme.palette.primary.main}>
              Add Extra Guest
            </Typography>
            <IconButton onClick={handleCloseAddExtraGuest} sx={{ padding: 0 }}>
              <HighlightOffIcon
                fontSize="large"
                sx={{ color: theme.palette.primary.main }}
              />
            </IconButton>
          </Stack>

          <Stack width={"100%"} direction={"row"} height={"100%"}>
            <Box
              width={isAddExistingButtonClicked ? "50%" : "100%"}
              borderRight={isAddExistingButtonClicked ? 2 : 0}
              borderColor={theme.palette.primary.main}
            >
              <Stack
                display="flex"
                direction={"row"}
                borderBottom={2}
                borderColor={theme.palette.primary.main}
                height={isAddExistingButtonClicked ? "60%" : "40%"}
              >
                <Box
                  width={"70%"}
                  maxHeight={"50vh"}
                  minHeight="100%"
                  padding={2}
                  overflow={"auto"}
                  bgcolor={"black"}
                >
                  <Typography
                    fontSize={"0.9rem"}
                    fontWeight={700}
                    marginBottom={1}
                  >
                    Added Guest
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    flexWrap={"nowrap"}
                    sx={{ overflow: "auto" }}
                    direction={isAddExistingButtonClicked ? "column" : "row"}
                  >
                    {addExtraGuestArray.map((item: ExtraGuest, index) => {
                      return (
                        <Grid
                          item
                          key={index}
                          md={isAddExistingButtonClicked ? 12 : 6}
                          sm={12}
                          xs={12}
                          sx={{ flexShrink: 0 }}
                        >
                          <GuestCapsule2
                            guest={item}
                            onDelete={handleDeleteAddExtraGuest}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
                <ButtonBase
                  onClick={handleAddExistingButton}
                  sx={{
                    width: "30%",
                    height: "100%",
                    background: "#232323",
                    borderLeft: 2,
                    borderColor: theme.palette.primary.main,
                  }}
                >
                  <Stack padding={4} spacing={2}>
                    {isAddExistingButtonClicked ? (
                      <>
                        <Remove
                          sx={{ textAlign: "start", fontSize: "2.5rem" }}
                        />
                        <Typography variant={"h4"} textAlign={"start"}>
                          Collapse Existing Customer Panel
                        </Typography>
                      </>
                    ) : (
                      <>
                        <AddIcon
                          sx={{ textAlign: "start", fontSize: "2.5rem" }}
                        />
                        <Typography variant={"h4"} textAlign={"start"}>
                          Add Existing
                        </Typography>
                      </>
                    )}
                  </Stack>
                </ButtonBase>
              </Stack>

              <Stack>
                <Grid container paddingY={3} paddingX={2} spacing={2}>
                  <Grid
                    item
                    md={isAddExistingButtonClicked ? 12 : 6}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Stack direction={"row"} width={"95%"} marginBottom={1}>
                      <Box
                        display="flex"
                        width={"30%"}
                        height={"100%"}
                        alignItems={"center"}
                        justifyContent={"start"}
                        paddingX={1}
                      >
                        <Typography fontSize={"0.8rem"} fontWeight={700}>
                          ID
                        </Typography>
                      </Box>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        width={"100%"}
                        border={2}
                        borderColor={theme.palette.primary.main}
                        borderRadius="5px"
                      >
                        <Select
                          value={selectedIdType}
                          onChange={(e) =>
                            handleIcTypeValueOnChange(e.target.value)
                          }
                          sx={{
                            width: "200px",
                          }}
                          size="small"
                        >
                          {idTypes.map((idType, index) => (
                            <MenuItem key={index} value={idType.idTypeId}>
                              {idType.idType}
                            </MenuItem>
                          ))}
                        </Select>
                        <TextField
                          value={IcInputValue}
                          onChange={(e) =>
                            handleIcValueOnChange(e.target.value)
                          }
                          sx={{
                            width: "100%",
                            borderLeft: 1,
                            borderColor: theme.palette.primary.main,
                          }}
                          size="small"
                        />
                      </Box>
                    </Stack>
                    <Stack direction={"row"} width={"95%"}>
                      <Box
                        display="flex"
                        width={"30%"}
                        height={"100%"}
                        alignItems={"center"}
                        justifyContent={"start"}
                        paddingX={1}
                      >
                        <Typography fontSize={"0.8rem"} fontWeight={700}>
                          Name
                        </Typography>
                      </Box>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        width={"100%"}
                      >
                        <TextField
                          value={nameInputValue}
                          onChange={(e) =>
                            handleNameValueOnChange(
                              e.target.value.toUpperCase()
                            )
                          }
                          sx={{
                            width: "100%",
                            border: 2,
                            borderColor: theme.palette.primary.main,
                            borderRadius: "5px",
                          }}
                          size="small"
                        />
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {!isAddExistingButtonClicked ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        width={"100%"}
                      >
                        <Stack
                          direction={"row"}
                          width={"100%"}
                          paddingY={3}
                          justifyContent={"center"}
                          alignItems={"center"}
                          borderLeft={2}
                          borderRight={2}
                          borderColor={theme.palette.primary.main}
                          height={"30px"}
                        >
                          <RssFeed
                            sx={{
                              fontSize: "30px",
                              marginRight: "10px",
                            }}
                          />
                          <Typography>
                            Scan IC/Passport to add an Extra Guest
                          </Typography>
                        </Stack>
                        <TextField
                          multiline
                          id={""}
                          rows={5}
                          autoFocus
                          value={idInfoInput}
                          onChange={handleIdInputChange}
                          fullWidth
                          sx={{
                            border: "1px solid grey",
                            "& .MuiInputBase-root": {
                              padding: 0,
                            },
                            "& .MuiOutlinedInput-root": {
                              padding: 0,
                            },
                            height: "20px",
                            marginTop: "15px",
                          }}
                          inputProps={{
                            style: { padding: "10px" },
                          }}
                          placeholder="Please do not type anything here"
                        />
                      </Box>
                    ) : null}
                  </Grid>
                </Grid>
                <Stack
                  direction={"row"}
                  paddingBottom={2.5}
                  spacing={2}
                  justifyContent={"start"}
                  alignItems={"center"}
                  marginLeft={0}
                  border={0}
                  paddingX={2}
                >
                  <Button
                    disabled={isAddMoreButtonDisabled}
                    onClick={() =>
                      handleAddExtraGuestArray(
                        nameInputValue,
                        IcInputValue,
                        selectedIdType
                      )
                    }
                    sx={{
                      borderRadius: 0,
                      paddingY: 2.5,
                      paddingX: 8,
                      border: 2,
                      background: "#232323",
                      borderColor: theme.palette.primary.main,
                    }}
                  >
                    Add
                  </Button>
                  <Button
                    disabled={isDoneButtonDisabled}
                    onClick={handleSubmitExtraGuest}
                    sx={{
                      borderRadius: 0,
                      paddingY: 2.5,
                      paddingX: 8,
                      border: 2,
                      background: "#232323",
                      borderColor: theme.palette.primary.main,
                    }}
                  >
                    Done
                  </Button>
                </Stack>
              </Stack>
            </Box>
            {isAddExistingButtonClicked ? (
              <Box width={"50%"}>
                <Typography variant="h3" padding={2}>
                  Existing Guests
                </Typography>
                <Stack
                  direction={"row"}
                  paddingY={3}
                  paddingX={2}
                  borderTop={2}
                  borderColor={theme.palette.primary.main}
                >
                  <TextField
                    label="Search Here"
                    value={searchName}
                    onChange={(event) => setSearchName(event.target.value)}
                    variant="filled"
                    sx={{ width: "80%" }}
                  />
                  <Button
                    variant="contained"
                    onClick={fetchExistingCustomer}
                    sx={{ width: "15%", ml: 2, borderRadius: 2 }}
                  >
                    Submit
                  </Button>
                </Stack>

                <Stack direction={"row"}>
                  <Box
                    justifyContent={"center"}
                    alignItems={"center"}
                    display={"flex"}
                    sx={{
                      p: 2,
                      border: 1,
                      borderColor: "#15ffdc",
                      height: "82px",
                      width: "25%",
                    }}
                  >
                    <FilterAlt sx={{ fontSize: 18 }} />
                    <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                      Sorter
                    </Typography>
                  </Box>
                  <Box width={"75%"}>
                    <Stack
                      justifyContent={"start"}
                      alignItems={"center"}
                      display={"flex"}
                      direction={"row"}
                      sx={{
                        p: 2,
                        border: 1,
                        borderColor: "#15ffdc",
                        height: "40px",
                      }}
                    >
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        Sort By
                      </Typography>
                    </Stack>
                    <Stack
                      justifyContent={"start"}
                      alignItems={"center"}
                      display={"flex"}
                      direction={"row"}
                      sx={{
                        border: 1,
                        borderColor: "#15ffdc",
                        height: "42px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControl fullWidth variant="outlined">
                        <Select
                          // value={}
                          // onChange={}
                          displayEmpty
                          size="small"
                          sx={{
                            borderRadius: 0,
                            border: 1,
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.main,
                          }}
                        >
                          <MenuItem value="12345">12345</MenuItem>
                          <MenuItem value="67890">67890</MenuItem>
                          {/* Add more items as needed */}
                        </Select>
                      </FormControl>
                    </Stack>
                  </Box>
                </Stack>

                <MaterialReactTable
                  columns={columns}
                  data={existingCustomerList}
                  state={{ pagination, isLoading }}
                  onPaginationChange={setPagination}
                  enableTopToolbar={false}
                  muiTableHeadProps={{
                    sx: {
                      ".MuiTableHead-root": {
                        border: 2,
                        borderStyle: "solid",
                        borderColor: theme.palette.primary.main,
                      },
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      color: theme.palette.primary.main,
                      backgroundColor: "black",
                      ".MuiSvgIcon-root.MuiTableSortLabel-icon": {
                        color: theme.palette.primary.main,
                      },
                      borderStyle: "none",
                      borderBottomStyle: "solid",
                      borderBottomColor: theme.palette.primary.main,
                    },
                  }}
                  muiTableHeadCellColumnActionsButtonProps={{
                    sx: {
                      display: "none",
                    },
                  }}
                  muiTableBodyProps={{
                    sx: {
                      backgroundColor: theme.palette.background.default,
                      "& .MuiTypography-root": {
                        color: theme.palette.primary.main,
                      },
                    },
                  }}
                  muiTableBodyRowProps={({ staticRowIndex }) => ({
                    sx: {
                      backgroundColor:
                        staticRowIndex % 2 === 0
                          ? theme.palette.background.default
                          : "black",
                    },
                  })}
                  muiTableBodyCellProps={{
                    sx: {
                      color: "white",
                      borderBottomStyle: "solid",
                      borderBottomColor: theme.palette.primary.main,
                    },
                  }}
                  muiBottomToolbarProps={{
                    sx: {
                      backgroundColor: "black",
                    },
                  }}
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [-1],
                    sx: {
                      ".MuiTablePagination-displayedRows": {
                        color: theme.palette.primary.main,
                      },
                      ".MuiTablePagination-actions .MuiButtonBase-root.MuiIconButton-root .MuiSvgIcon-root":
                        {
                          color: theme.palette.primary.main,
                        },
                      ".MuiTablePagination-actions .MuiButtonBase-root.MuiIconButton-root.Mui-disabled .MuiSvgIcon-root":
                        {
                          color: theme.palette.primary.main,
                          opacity: 0.5,
                        },
                    },
                  }}
                  muiLinearProgressProps={{
                    sx: {
                      height: "10px",
                    },
                  }}
                />
              </Box>
            ) : null}
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
export default AddExtraGuestModal;
