import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IBooking } from "../../models/bookings/Booking";

// setFieldValue("bookingInfo.customerStayingId", row.original.customerId);
// setFieldValue("bookingInfo.customerStayingName", row.original.fullName);
// setFieldValue("bookingInfo.customerStayingIc", row.original.icNo);
// setFieldValue("bookingInfo.customerStayingMemberId", row.original.memberId);
// setFieldValue("bookingInfo.customerStayingMemberTier", row.original.memberTier);
// setFieldValue("bookingInfo.customerStayingMemberCondition", row.original.memberCondition);

// //Apply PIC Assignation to all room bookings
// for (let i = 0; i < roomBookingsState.length; i++) {
//   console.log(i)
//   setFieldValue(`roomBookings[${i}].person_in_charge_id`, row.original.customerId);
//   setFieldValue(`roomBookings[${i}].person_in_charge_id`, row.original.customerId);
//   setFieldValue(`roomBookings[${i}].person_in_charge_name`, row.original.fullName);
//   setFieldValue(`roomBookings[${i}].person_in_charge_ic`, row.original.icNo);
//   setFieldValue(`roomBookings[${i}].member_id`, row.original.memberId);
//   setFieldValue(`roomBookings[${i}].member_tier`, row.original.memberTier);
//   setFieldValue(`roomBookings[${i}].member_condition`, row.original.memberCondition);
// }

const getCheckOutInitial = () => {
  const checkOutDateInitial = new Date();

  checkOutDateInitial.setHours(checkOutDateInitial.getHours() + 3);

  return checkOutDateInitial;
};

//Create interface
type IExpressBookingPICAssignation = {
  customerStayingId: string;
  customerStayingName: string;
  customerStayingIc: string;
  customerStayingCountry: string;
  customerStayingMemberId: string;
  customerStayingMemberTier: string;
  customerStayingMemberCondition: string;
  customerStayingEmail: string;
  customerStayingPhone: string;
  rententionCount: number;
  counter?: number;
};
const initialState: IBooking = {
  bookingInfo: {
    id: "",
    checkInDate: new Date().toISOString(),
    checkOutDate: new Date(getCheckOutInitial()).toISOString(),
    duration: 3,
    adultPax: 0,
    childrenPax: 0,
    platform: "Walk In",
    platformId: "",
    otaRefNo: "",
    customerStayingIc: "",
    customerStayingId: "",
    customerStayingName: "",
    customerStayingMemberId: "",
    customerStayingMemberTier: "",
    customerStayingMemberCondition: "",
    customerStayingCountry: "",
    pin: "",
    rententionCount: 0,
  },
  roomBookings: [],
};
const bookingSlice = createSlice({
  name: "ExpressBooking",
  initialState,
  reducers: {
    SetExpressBooking: (state, action: PayloadAction<IBooking>) => {
      return action.payload;
    },
    // UpdatePIC(state, action: PayloadAction<IExpressBookingPICAssignation>) {
    // Only One PIC Assignation for Express Booking s
    //   state.bookingInfo.customerStayingId = action.payload.customerStayingId;
    //   state.bookingInfo.customerStayingName =
    //     action.payload.customerStayingName;
    //   state.bookingInfo.customerStayingIc = action.payload.customerStayingIc;
    //   state.bookingInfo.customerStayingMemberId =
    //     action.payload.customerStayingMemberId;
    //   state.bookingInfo.customerStayingMemberTier =
    //     action.payload.customerStayingMemberTier;
    //   state.bookingInfo.customerStayingMemberCondition =
    //     action.payload.customerStayingMemberCondition;

    EmptyExpressBookingRedux: (state) => {
      return initialState;
    },

    UpdatePIC(state, action: PayloadAction<IExpressBookingPICAssignation>) {
      // Only One PIC Assignation for Express Booking s
      const {
        customerStayingId,
        customerStayingName,
        customerStayingIc,
        customerStayingCountry,
        customerStayingMemberId,
        customerStayingMemberTier,
        customerStayingMemberCondition,
        customerStayingEmail,
        customerStayingPhone,
        rententionCount,
        counter,
      } = action.payload;

      state.bookingInfo.customerStayingId = customerStayingId;
      state.bookingInfo.customerStayingName = customerStayingName;
      state.bookingInfo.customerStayingIc = customerStayingIc;
      state.bookingInfo.customerStayingCountry = customerStayingCountry;

      state.bookingInfo.customerStayingMemberId = customerStayingMemberId;
      state.bookingInfo.customerStayingMemberTier = customerStayingMemberTier;
      state.bookingInfo.customerStayingMemberCondition =
        customerStayingMemberCondition;
      state.bookingInfo.customerStayingPhone = customerStayingPhone;
      state.bookingInfo.customerStayingEmail = customerStayingEmail;
      state.bookingInfo.rententionCount = rententionCount;

      if (counter === 0) {
        const newRoomBookings = state.roomBookings.map((roomBooking) => {
          const newRoomBooking = { ...roomBooking };
          newRoomBooking.person_in_charge_id = action.payload.customerStayingId;
          newRoomBooking.person_in_charge_name =
            action.payload.customerStayingName;
          newRoomBooking.person_in_charge_email =
            action.payload.customerStayingEmail;
          newRoomBooking.person_in_charge_phone =
            action.payload.customerStayingPhone;
          newRoomBooking.person_in_charge_ic = action.payload.customerStayingIc;
          newRoomBooking.person_in_charge_country =
            action.payload.customerStayingCountry;
          newRoomBooking.member_id = action.payload.customerStayingMemberId;
          newRoomBooking.member_tier = action.payload.customerStayingMemberTier;
          newRoomBooking.member_condition =
            action.payload.customerStayingMemberCondition;
          return newRoomBooking;
        });
        state.roomBookings = newRoomBookings;
      } else {
        if (counter) {
          const newRoomBookings = [...state.roomBookings];
          // for (let i = 0; i < newRoomBookings.length; i++) {
          //   const booking = newRoomBookings[i];
          //   if (!booking.person_in_charge_id) {
          //     booking.person_in_charge_id = customerStayingId;
          //     booking.person_in_charge_name = customerStayingName;
          //     booking.person_in_charge_ic = customerStayingIc;
          //     booking.member_id = customerStayingMemberId;
          //     booking.member_tier = customerStayingMemberTier;
          //     booking.member_condition = customerStayingMemberCondition;
          //     break;
          //   }
          // }
          newRoomBookings[counter].person_in_charge_id = customerStayingId;
          newRoomBookings[counter].person_in_charge_name = customerStayingName;
          newRoomBookings[counter].person_in_charge_ic = customerStayingIc;
          newRoomBookings[counter].person_in_charge_country =
            customerStayingCountry;
          newRoomBookings[counter].person_in_charge_email =
            customerStayingEmail;
          newRoomBookings[counter].person_in_charge_phone =
            customerStayingPhone;
          newRoomBookings[counter].member_id = customerStayingMemberId;
          newRoomBookings[counter].member_tier = customerStayingMemberTier;
          newRoomBookings[counter].member_condition =
            customerStayingMemberCondition;

          state.roomBookings = newRoomBookings;
        }
      }
    },

    DeleteCustomer(state) {
      state.bookingInfo.customerStayingId = "";
      state.bookingInfo.customerStayingName = "";
      state.bookingInfo.customerStayingIc = "";
      state.bookingInfo.customerStayingCountry = "";

      state.bookingInfo.customerStayingMemberId = "";
      state.bookingInfo.customerStayingMemberTier = "";
      state.bookingInfo.customerStayingMemberCondition = "";
      state.bookingInfo.customerStayingPhone = "";
      state.bookingInfo.customerStayingEmail = "";

      const newRoomBookings = state.roomBookings.map((roomBooking) => {
        const newRoomBooking = { ...roomBooking };
        newRoomBooking.person_in_charge_id = "";
        newRoomBooking.person_in_charge_name = "";
        newRoomBooking.person_in_charge_email = "";
        newRoomBooking.person_in_charge_phone = "";
        newRoomBooking.person_in_charge_ic = "";
        newRoomBooking.person_in_charge_country = "";
        newRoomBooking.member_id = "";
        newRoomBooking.member_tier = "";
        newRoomBooking.member_condition = "";
        return newRoomBooking;
      });
      state.roomBookings = newRoomBookings;
    },
  },
});

export const {
  SetExpressBooking,
  UpdatePIC,
  EmptyExpressBookingRedux,
  DeleteCustomer,
} = bookingSlice.actions;
export default bookingSlice.reducer;
