import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
// import ColorCode from '../../utils/colorCodeConfig'
// import { convertEachWordToCapital } from '../../utils/functions'
import { IBookingRowInfo, INewBookingRowInfo } from "../../types/ganttChart";
import BookingRow from "./BookingRow";
import NewBookingRow from "./NewBookingRow";
import { createNew } from "typescript";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

type Props = {
  measurements: {
    xAxisNumTicks: number;
    xAxisHeight: string;
    emPerHour: number;
    oddNumColor: string;
    evenNumColor: string;
    itemHeight: string;
    epochPerEM: number;
  };
  bookingRows: IBookingRowInfo[];
  displayHour: number;
  displayDate: Date;
  removeBookingPeriod: (rowIndex: number, periodIndex: number) => void;
  setBookingRows: React.Dispatch<React.SetStateAction<IBookingRowInfo[]>>;
  otaDirected: boolean;
  // addBookingPeriodToRow: (leftColumnItemIndex: number) => void;
};

const ChartBox = ({
  measurements: {
    xAxisNumTicks,
    xAxisHeight,
    emPerHour,
    oddNumColor,
    evenNumColor,
    itemHeight,
    epochPerEM,
  },
  bookingRows,
  displayHour,
  displayDate,
  removeBookingPeriod,
  setBookingRows,
  otaDirected,
}: Props) => {
  const [todayDateEpoch, setTodayDateEpoch] = useState<number>(
    otaDirected
      ? new Date(
          displayDate.getFullYear(),
          displayDate.getMonth(),
          displayDate.getDate(),
          displayHour,
          0
        ).getTime()
      : new Date(
          displayDate.getFullYear(),
          displayDate.getMonth(),
          displayDate.getDate(),
          displayHour,
          0
        ).getTime() -
          24 * 60 * 60 * 1000
  );

  useEffect(() => {
    setTodayDateEpoch(
      otaDirected
        ? new Date(
            displayDate.getFullYear(),
            displayDate.getMonth(),
            displayDate.getDate(),
            displayHour,
            0
          ).getTime()
        : new Date(
            displayDate.getFullYear(),
            displayDate.getMonth(),
            displayDate.getDate(),
            displayHour,
            0
          ).getTime() -
            24 * 60 * 60 * 1000
    );
  }, [displayDate, displayHour]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid
        container
        columns={{
          xs: xAxisNumTicks,
          sm: xAxisNumTicks,
          md: xAxisNumTicks,
          lg: xAxisNumTicks,
          xl: xAxisNumTicks,
        }}
        alignContent={"start"}
        sx={{
          width: `${xAxisNumTicks * emPerHour}em`,
          minHeight: "42em",
          paddingTop: xAxisHeight,
          backgroundImage: `repeating-linear-gradient(
                to right,
                ${displayHour % 2 === 0 ? oddNumColor : evenNumColor},
                ${
                  displayHour % 2 === 0 ? oddNumColor : evenNumColor
                } ${emPerHour}em,
                ${
                  displayHour % 2 === 0 ? evenNumColor : oddNumColor
                } ${emPerHour}em,
                ${displayHour % 2 === 0 ? evenNumColor : oddNumColor} ${
            2 * emPerHour
          }em
              )`,
          zIndex: 0,
        }}
      >
        {bookingRows.map(
          ({ roomId, bookingPeriods, roomStatus }, index) => {
            return (
              <BookingRow
                key={roomId}
                measurements={{
                  xAxisNumTicks,
                  emPerHour,
                  itemHeight,
                  epochPerEM,
                }}
                bookingPeriods={bookingPeriods}
                todayDateEpoch={todayDateEpoch}
                roomStatus={roomStatus}
                removeBookingPeriod={removeBookingPeriod}
                bookingIndex={index}
                roomId={roomId}
                setBookingRows={setBookingRows}
              />
            );
          }
          // if (createNewBookingCondition?.roomId === roomId) {
          //   return (
          //     <NewBookingRow
          //       measurements={{
          //         xAxisNumTicks,
          //         emPerHour,
          //         itemHeight,
          //         epochPerEM,
          //       }}
          //       createNewBookingCondition={createNewBookingCondition}
          //       todayDateEpoch={todayDateEpoch}
          //     />
          //   );
          // }
        )}
      </Grid>
    </DndProvider>
  );
};

export default ChartBox;
