import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Stack,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Typography,
  FormControl,
  InputLabel,
  useTheme,
} from "@mui/material";
import {
  ArrowDropDownIcon,
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import React, { useEffect, useState } from "react";
import { IFormattedRoomTypes } from "../../models/filterbar/Filterbar";
import { store } from "../../redux/store";

type Props = {
  roomTypes: IFormattedRoomTypes[];
  roomTypeFilters: string[];
  handleRoomTypeFiltersOnChange: (
    roomTypeToAdd: string | string[],
    isChecked: boolean,
    isAll: boolean
  ) => void;

  date: Date;
  handleDateOnChange: (date: Date) => void;

  duration: number;
  handleDurationOnChange: (duration: number) => void;

  roomZones: { roomZoneId: string; roomZone: string }[];
  roomZoneFilters: string[];
  handleRoomZoneFiltersOnChange: (
    roomTypeToAdd: string | string[],
    isChecked: boolean,
    isAll: boolean
  ) => void;

  bedType: string;
  handleBedTypeOnChange: (bedType: string) => void;

  quietZone: string;
  handleQuietZoneOnChange: (quietZone: string) => void;

  handleGanttChartChangeOnClick?: (() => void) | null;
  handleZoneMapChangeOnclick?: () => void;

  bookingId?: string;
  otaDirected?: boolean;
};

const FilterBar = ({
  roomTypes,
  roomTypeFilters,
  handleRoomTypeFiltersOnChange,
  date,
  handleDateOnChange,
  duration,
  handleDurationOnChange,
  roomZones,
  roomZoneFilters,
  handleRoomZoneFiltersOnChange,
  bedType,
  handleBedTypeOnChange,
  handleGanttChartChangeOnClick,
  handleZoneMapChangeOnclick,
  quietZone,
  handleQuietZoneOnChange,
  bookingId,
  otaDirected,
}: Props) => {
  const [isRoomTypeChecked, setIsRoomTypeChecked] = useState<{
    [key: string]: boolean;
  }>({});
  const [isRoomZoneChecked, setIsRoomZoneChecked] = useState<{
    [key: string]: boolean;
  }>({});

  const theme = useTheme();

  const lot = store.getState().user.lotId;

  const [listOfDuration, setListOfDuration] = useState<any[]>([]);

  useEffect(() => {
    if (Number(lot) === 2) {
      setListOfDuration([1, 3, 6, 12, 24]);
    } else {
      setListOfDuration([3, 6, 12, 24]);
    }
  }, [lot]);

  useEffect(() => {
    const roomTypesChecked: { [key: string]: boolean } = {};
    for (const { roomType } of roomTypes) {
      roomTypesChecked[roomType] = roomTypeFilters.includes(roomType)
        ? true
        : false;
    }
    roomTypesChecked["all"] = Object.keys(roomTypesChecked).every(
      (roomType) => roomTypesChecked[roomType]
    )
      ? true
      : false;
    setIsRoomTypeChecked(roomTypesChecked);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomTypes]);

  useEffect(() => {
    const roomZonesChecked: { [key: string]: boolean } = {};
    for (const { roomZone } of roomZones) {
      roomZonesChecked[roomZone] = roomZoneFilters.includes(roomZone)
        ? true
        : false;
    }
    roomZonesChecked["all"] = Object.keys(roomZonesChecked).every(
      (roomZone) => roomZonesChecked[roomZone]
    )
      ? true
      : false;
    setIsRoomZoneChecked(roomZonesChecked);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomZones]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        display={"flex"}
        // margin={"30px auto"}
        marginBottom={0}
        borderBottom="1px solid #ffffff4d"
        sx={{
          height: "6em",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"end"}
          alignContent={"space-between"}
          width={"100%"}
          sx={{
            backgroundColor: theme.palette.background.default,
            height: "100%",
            borderRadius: 0,
            position: "relative",
          }}
          spacing={1}
        >
          <Stack
            direction={"row"}
            position={"absolute"}
            height={"100%"}
            color={"white"}
            justifyContent={"center"}
            alignItems={"center"}
            left={"0"}
            width={"10%"}
          >
            <FilterAltIcon fontSize={"medium"} />
            <Typography fontWeight={600} variant="h3">
              Room
            </Typography>
          </Stack>
          <Stack
            display={"flex"}
            direction={"row"}
            height={"100%"}
            width={"90%"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            color={"white"}
            border={0}
            spacing={3}
          >
            <FormControl>
              <InputLabel variant="filled" id="room-type-select-label">
                Room Types
              </InputLabel>
              <Select
                variant="filled"
                labelId="room-type-select-label"
                label="Room Types"
                id={"room-type-select-id"}
                value={roomTypeFilters}
                multiple
                renderValue={() => {
                  let displayValue: string[] = ["All Types"];
                  if (isRoomTypeChecked.all) return displayValue.join(" | ");
                  displayValue = Object.keys(isRoomTypeChecked).filter(
                    (roomType) =>
                      roomType !== "all" && isRoomTypeChecked[roomType]
                  );
                  return displayValue.join(" | ");
                }}
                sx={{
                  ".MuiSelect-select": {
                    border: "1px solid #148978",
                  },
                  ".MuiSelect-icon": {
                    fill: "white",
                  },
                  width: "15em",
                }}
              >
                <MenuItem value={"all"}>
                  <FormControlLabel
                    label="All Room Types"
                    control={
                      <Checkbox
                        checked={isRoomTypeChecked.all}
                        onChange={({ target: { checked } }) => {
                          setIsRoomTypeChecked({
                            all: checked,
                            ...roomTypes.reduce(
                              (returned, roomType) => ({
                                ...returned,
                                [roomType.roomType]: checked,
                              }),
                              {}
                            ),
                          });
                          handleRoomTypeFiltersOnChange(
                            Object.keys(isRoomTypeChecked),
                            checked,
                            true
                          );
                        }}
                      />
                    }
                  />
                </MenuItem>
                {roomTypes.map(({ roomTypeId, roomType }) => (
                  <MenuItem key={roomTypeId} value={roomType}>
                    <FormControlLabel
                      label={roomType}
                      control={
                        <Checkbox
                          checked={isRoomTypeChecked[roomType]}
                          onChange={({ target: { checked } }) => {
                            const {
                              [roomType]: _,
                              all,
                              ...rest
                            } = isRoomTypeChecked;
                            setIsRoomTypeChecked({
                              ...isRoomTypeChecked,
                              [roomType]: checked,
                              all:
                                Object.values(rest).every(
                                  (value) => value === true
                                ) && checked
                                  ? true
                                  : false,
                            });
                            handleRoomTypeFiltersOnChange(
                              roomType,
                              checked,
                              false
                            );
                          }}
                        />
                      }
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <DatePicker
              label="Date"
              value={date}
              onChange={(e) => handleDateOnChange(e as Date)}
              format="dd MMM yyyy"
              readOnly={Boolean(bookingId) || otaDirected}
              slots={{
                openPickerIcon: ArrowDropDownIcon,
              }}
              slotProps={{
                layout: {
                  sx: {
                    ".MuiDateCalendar-root": {
                      color: theme.palette.primary.main,
                      borderRadius: 2,
                      borderWidth: 1,
                      borderColor: theme.palette.primary.main,
                      border: "1px solid",
                      backgroundColor: theme.palette.background.paper,
                    },
                    ".MuiButtonBase-root": {
                      color: theme.palette.primary.main,
                    },
                    ".MuiTypography-root": {
                      color: theme.palette.primary.main,
                    },
                    ".MuiPickersCalendarHeader-root": {
                      color: theme.palette.primary.main,
                    },
                    ".MuiPickersMonth-monthButton": {
                      color: theme.palette.primary.main,
                    },
                    ".MuiPickersCalendarHeader-label": {
                      color: theme.palette.primary.main,
                    },
                    ".Mui-selected": {
                      color: "black",
                    },
                    ".MuiPickersYear-yearButton": {
                      color: theme.palette.primary.main,
                    },
                  },
                },
                textField: {
                  variant: "filled",
                  inputProps: {
                    style: {
                      color: "white",
                      borderRadius: 0,
                      border: 2,
                    },
                  },
                  sx: {
                    width: "11.5em",
                    backgroundColor: "#232323",
                    border: "1px solid #148978",
                  },
                },
                openPickerButton: {
                  sx: {
                    color: "white",
                    marginTop: "1.8px",
                    zIndex: 1,
                  },
                },
                openPickerIcon: {
                  sx: {
                    width: "1em",
                    height: "1em",
                    zIndex: 1,
                  },
                },
              }}
            />

            <TimePicker
              label="Time"
              value={date}
              format={"hh:mm a"}
              ampm={true}
              onChange={(e) => handleDateOnChange(e as Date)}
              // viewRenderers={{
              //   hours: renderTimeViewClock,
              //   minutes: renderTimeViewClock,
              // }}
              readOnly={Boolean(bookingId) || otaDirected}
              slots={{
                openPickerIcon: ArrowDropDownIcon,
              }}
              slotProps={{
                textField: {
                  variant: "filled",
                  inputProps: {
                    style: {
                      borderRadius: 0,
                      border: 1,
                    },
                  },
                  sx: {
                    width: "9em",
                    borderRadius: 0,
                    backgroundColor: "#232323",
                    border: "1px solid #148978",
                  },
                },
                openPickerButton: {
                  sx: {
                    color: "white",
                    marginTop: "1.8px",
                    zIndex: 1,
                  },
                },
                openPickerIcon: {
                  sx: {
                    width: "1em",
                    height: "1em",
                    zIndex: 1,
                  },
                },
              }}
            />

            <FormControl>
              <InputLabel variant="filled" id="duration-select-label">
                Duration
              </InputLabel>
              <Select
                variant="filled"
                labelId="duration-select-label"
                label="Duration"
                value={duration}
                onChange={({ target: { value } }) =>
                  handleDurationOnChange(value as number)
                }
                readOnly={Boolean(bookingId)}
                sx={{
                  width: "8em",
                  ".MuiSelect-select": {
                    border: "1px solid #148978",
                  },
                  ".MuiSelect-icon": {
                    fill: "white",
                  },
                }}
              >
                {listOfDuration.map((duration, index) => (
                  <MenuItem key={index} value={duration}>
                    {duration} hours
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel variant="filled" id="zone-select-label">
                Zone
              </InputLabel>
              <Select
                labelId="zone-select-label"
                variant="filled"
                label="Zone"
                id={"room-type-select-id"}
                value={roomZoneFilters}
                multiple
                renderValue={() => {
                  let displayValue: string[] = ["All Zones"];
                  if (isRoomZoneChecked.all) return displayValue.join(" | ");
                  displayValue = Object.keys(isRoomZoneChecked).filter(
                    (roomType) =>
                      roomType !== "all" && isRoomZoneChecked[roomType]
                  );
                  return displayValue.join(" | ");
                }}
                sx={{
                  width: "7em",
                  ".MuiSelect-select": {
                    border: "1px solid #148978",
                  },
                  ".MuiSelect-icon": {
                    fill: "white",
                  },
                }}
              >
                <MenuItem value={"all"}>
                  <FormControlLabel
                    label="All Zones"
                    control={
                      <Checkbox
                        checked={isRoomZoneChecked.all}
                        onChange={({ target: { checked } }) => {
                          setIsRoomZoneChecked({
                            all: checked,
                            ...roomZones.reduce(
                              (returned, roomZone) => ({
                                ...returned,
                                [roomZone.roomZone]: checked,
                              }),
                              {}
                            ),
                          });
                          handleRoomZoneFiltersOnChange(
                            Object.keys(isRoomZoneChecked),
                            checked,
                            true
                          );
                        }}
                      />
                    }
                  />
                </MenuItem>
                {roomZones.map(({ roomZoneId, roomZone }) => (
                  <MenuItem key={roomZoneId} value={roomZone}>
                    <FormControlLabel
                      label={roomZone}
                      control={
                        <Checkbox
                          checked={isRoomZoneChecked[roomZone]}
                          onChange={({ target: { checked } }) => {
                            const {
                              [roomZone]: _,
                              all,
                              ...rest
                            } = isRoomZoneChecked;
                            setIsRoomZoneChecked({
                              ...isRoomZoneChecked,
                              [roomZone]: checked,
                              all:
                                Object.values(rest).every(
                                  (value) => value === true
                                ) && checked
                                  ? true
                                  : false,
                            });
                            handleRoomZoneFiltersOnChange(
                              roomZone,
                              checked,
                              false
                            );
                          }}
                        />
                      }
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel variant="filled" id="bed-select-label">
                Bed Level
              </InputLabel>
              <Select
                variant="filled"
                labelId="bed-select-label"
                id="bed-select"
                value={bedType}
                label="Bed Level"
                onChange={({ target: { value } }) =>
                  handleBedTypeOnChange(value)
                }
                sx={{
                  width: "10em",
                  color: "white",
                  ".MuiSelect-select": {
                    border: "1px solid #148978",
                  },
                  ".MuiSelect-icon": {
                    fill: "white",
                  },
                }}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"top"}>Top Bed</MenuItem>
                <MenuItem value={"bottom"}>Bottom Bed</MenuItem>
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel variant="filled" id="quiet-zone-select-label">
                Quiet Zone
              </InputLabel>
              <Select
                variant="filled"
                labelId="quiet-zone-select-label"
                id="quiet-zone-select"
                label="Quiet Zone"
                value={quietZone}
                onChange={({ target: { value } }) => {
                  handleQuietZoneOnChange(value);
                }}
                // value={bedType}
                // onChange={({ target: { value } }) =>
                //   handleBedTypeOnChange(value)
                // }
                sx={{
                  width: "7.8em",
                  ".MuiSelect-select": {
                    border: "1px solid #148978",
                  },
                  ".MuiSelect-icon": {
                    fill: "white",
                  },
                }}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"True"}>Yes</MenuItem>
                <MenuItem value={"False"}>No</MenuItem>
              </Select>
            </FormControl>
            <IconButton
              sx={{
                height: "2em",
                // marginTop: "0.7em",
                color: "white",
                backgroundColor: "#195a56",
                borderRadius: "0.2em",
              }}
              onClick={() => {
                if (handleGanttChartChangeOnClick)
                  handleGanttChartChangeOnClick();
                if (handleZoneMapChangeOnclick) handleZoneMapChangeOnclick();
              }}
            >
              <SearchIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
    </LocalizationProvider>
  );
};

export default React.memo(FilterBar);
