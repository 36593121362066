import {
  Stack,
  Grid,
  Typography,
  IconButton,
  Alert,
  Divider,
} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import theme from "../../assets/theme/theme";
import { useEffect, useState } from "react";

interface RoomSettingsProps {
  gracePeriods?: string | undefined;
  interval?: string | undefined;
  onRoomSettingsVisibilityChange: (isVisible: boolean) => void;
  settingsGracePeriodId?: number;
  settingsIntervalId?: number;
}
export default function RoomSettings({
  onRoomSettingsVisibilityChange,
  gracePeriods,
  interval,
  settingsGracePeriodId,
  settingsIntervalId,
}: RoomSettingsProps) {
  const alertMessage =
    "Overstay charges can be set from Room Management -\u003E Room Type -\u003E Edit";
  const [showRoomSettingsDetail, setShowRoomSettingsDetail] = useState(false);

  const handleButtonClick = () => {
    setShowRoomSettingsDetail(true);

    // Pass the boolean value to the parent component
    onRoomSettingsVisibilityChange(true);
  };

  const [gracePeriod, setGracePeriod] = useState<String>();
  const [intervals, setInterval] = useState<String>();

  useEffect(() => {
    setGracePeriod(gracePeriods);
    setInterval(interval);
  }, []);
  return (
    <Grid container spacing={2} padding={5}>
      {/**Room Setting Section */}
      <Grid item xs={12}>
        <Typography variant="h3">Room Settings</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider
          orientation="horizontal"
          flexItem
          sx={{
            borderBottomWidth: 3.5,
            borderColor: "#232323",
          }}
        />
      </Grid>
      <Grid item xs={3} px={2}>
        <Typography variant="h4" color={theme.palette.primary.main}>
          Grace Period
        </Typography>
      </Grid>
      <Grid item xs={8} px={2}>
        <Typography variant="h4">{gracePeriods}</Typography>
      </Grid>
      <Grid item xs={1} px={2}>
        <IconButton onClick={handleButtonClick}>
          <SettingsOutlinedIcon color="primary" />
        </IconButton>
      </Grid>
      <Grid item xs={3} px={2}>
        <Typography variant="h4" color={theme.palette.primary.main}>
          No Show Intv.
        </Typography>
      </Grid>
      <Grid item xs={9} px={2}>
        <Typography variant="h4">{interval}</Typography>
      </Grid>
      <Grid item xs={12} px={2} py={3}>
        <Alert severity="info" sx={{ color: "#014361" }}>
          {alertMessage}
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Divider
          orientation="horizontal"
          flexItem
          sx={{
            borderBottomWidth: 3.5,
            borderColor: "#232323",
          }}
        />
      </Grid>
    </Grid>
  );
}
