import { Box } from "@mui/system";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import OverviewColumn from "./OverviewColumn";
import TableColumn from "./TableColumn";
import { useEffect } from "react";

type Props<T extends Record<string, any>, S> = {
  title: string;
  tableItems: T[];
  overviewItems?: S[];
  handleAddItemModalOpen?: () => void;
  columns: MRT_ColumnDef<T>[];
  pagination: MRT_PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<MRT_PaginationState>>;
  OverviewColumnItem?: React.FC<{ item: S }>;
  activeOverview?: boolean;
  activeNum?: number;
  inactiveNum?: number;
  searchQuery: string;
  handleSearchQueryChange: (search: string) => void;
  fetchAllItems: () => void;
  activeStock?: number;
  inactiveStock?: number;
  onlyContent?: boolean;
};

/**
 * @template T types of table items and columns
 * @template S types of overview items
 */
const ManagementContentArea = <T extends Record<string, any>, S = void>({
  title,
  tableItems,
  overviewItems,
  handleAddItemModalOpen,
  columns,
  pagination,
  setPagination,
  OverviewColumnItem,
  activeOverview = false,
  activeNum,
  inactiveNum,
  searchQuery,
  handleSearchQueryChange,
  fetchAllItems,
  activeStock,
  inactiveStock,
  onlyContent,
}: Props<T, S>) => {
  return (
    <Box width={"100%"} height={"fit-content"} display={"flex"} marginTop={2}>
      {!onlyContent && (
        <OverviewColumn<S>
          items={overviewItems}
          OverviewColumnItem={OverviewColumnItem}
          activeOverview={activeOverview}
          activeNum={activeNum}
          inactiveNum={inactiveNum}
          activeStock={activeStock}
          inactiveStock={inactiveStock}
        />
      )}

      <TableColumn<T>
        title={title}
        items={tableItems}
        handleAddItemModalOpen={handleAddItemModalOpen}
        columns={columns}
        pagination={pagination}
        setPagination={setPagination}
        searchQuery={searchQuery}
        handleSearchQueryChange={handleSearchQueryChange}
        fetchAllItems={fetchAllItems}
        onlyContent={onlyContent}
      />
    </Box>
  );
};

export default ManagementContentArea;
