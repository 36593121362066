import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
  darken,
  useTheme,
} from "@mui/material";

// Logo
// import { ReactComponent as MySVGLogo } from "../../static/images/CapsuleTransitLogo.svg";
import React, { ReactNode, useEffect, useState } from "react";
import {
  default as SignatureCanvas,
  default as SignaturePad,
} from "react-signature-canvas";
import { ReactComponent as OnlyLogoSVG } from "../../assets/images/Logo.svg";
import { ReactComponent as BeQuietSVG } from "../../assets/images/consent/BeQuiet.svg";
import { ReactComponent as NoBigLuggageSVG } from "../../assets/images/consent/NoBigLuggage.svg";
import { ReactComponent as NoFoodSVG } from "../../assets/images/consent/NoFood.svg";
import { ReactComponent as NoSmokingSVG } from "../../assets/images/consent/NoSmoking.svg";

import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import theme from "../../assets/theme/theme";
import PhoneField from "../../components/input/PhoneField";
import BASE_API from "../../constants/api";
import axiosInstance from "../../constants/axiosConfig";
import { ICountry } from "../../models/guests/GuestsInterface";
import { store } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import "./signature.css";

const initialValue = {
  consentFormId: "",
  pin: "",
  process: "check-in",
  guestName: "",
  emailAddress: "",
  phoneNumber: "",
  prefix: "+60",
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth <= 768 ? "30%" : "50%",
  border: 2,
  borderColor: theme.palette.primary.main,
};

export default function ConsentFormPage() {
  const navigate = useNavigate();
  const token = store.getState().user.accessToken;
  let padRef = React.useRef<SignatureCanvas>(null);
  let padRefCheckout = React.useRef<SignatureCanvas>(null);
  const [openModal, setOpenModal] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [pin, setPin] = useState<string>("");
  const [pinError, setPinError] = useState<string>("");
  const [countries, setCountries] = useState<ICountry[]>([]);

  const [checkInSignatureImage, setCheckInSignatureImage] =
    useState<string>("");

  useEffect(() => {
    const fetchCountry = () => {
      axiosInstance
        .get(`${BASE_API}/guests/country`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const sortedCountry: ICountry[] = response.data.data
            .sort((prev: ICountry, curr: ICountry) =>
              prev.countryName.localeCompare(curr.countryName)
            )
            .sort((prev: ICountry, curr: ICountry) =>
              prev.favorite === curr.favorite ? 0 : prev.favorite ? -1 : 1
            );
          setCountries(sortedCountry);
        });
    };

    fetchCountry();
  }, []);

  const formik = useFormik({
    initialValues: initialValue,

    onSubmit: (values, { resetForm }) => {
      if (values.emailAddress === "") {
        Notification.failed("Please input email");
        return;
      }

      if (values.phoneNumber === "") {
        Notification.failed("Please input Phone Number");
        return;
      }

      if (values.guestName === "") {
        Notification.failed("Please input Guest Name");
        return;
      }

      const canvas = padRef.current?.getTrimmedCanvas();
      const canvasCheckout = padRefCheckout.current?.getTrimmedCanvas();
      const formData = new FormData();
      if (values.process === "check-out") {
        if (canvasCheckout) {
          if (padRefCheckout.current?.isEmpty()) {
            Notification.failed("Require Check Out Signature");
            return;
          }
          canvasCheckout.toBlob((blob) => {
            formData.append("checkout_signature", blob!, "consent.png");
          });
        }
      }

      if (canvas) {
        canvas.toBlob((blob) => {
          const apiUrl = "/bookings/v1/consent/submit/";

          if (values.process === "check-in" && padRef.current?.isEmpty()) {
            Notification.failed("Require Check In Signature");
            return;
          }

          formData.append("consent_form_id", values.consentFormId);
          formData.append("pin", values.pin);
          formData.append("name", values.guestName);
          formData.append(
            "phone_num",
            `${values.prefix} ${values.phoneNumber}`
          );
          formData.append("email", values.emailAddress);
          formData.append("checkin_signature", blob!, "consent.png");

          axiosInstance
            .post(apiUrl, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              if (values.process === "check-out") {
                navigate(`/feedback-form/${res.data.data.bookingId}`);
              }

              resetForm();
              padRef.current?.clear();
              padRefCheckout.current?.clear();
              Notification.success("Consent Successfully Submitted");

              window.location.reload();
            })
            .catch((res) => {
              Notification.failed(res.response.data.error);
            });
        });
      }
    },
  });

  const clearCanvas = () => {
    padRef.current?.clear();
  };

  const clearCheckoutCanvas = () => {
    padRefCheckout.current?.clear();
  };

  const validatePin = () => {
    let isValid = true;
    if (!pin) {
      setPinError("Please provide pin");
      isValid = false;
    } else {
      setPinError("");
    }
    return isValid;
  };

  const handlesubmitPIN = async () => {
    if (validatePin()) {
      setLoading(true);
      const apiUrl = `/bookings/v1/consent/submit-pin/ `;
      const formData = new FormData();
      formData.append("pin", pin);
      await axiosInstance
        .post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setOpenModal(false);
          if (response.data.data.checkinSignature) {
            formik.setFieldValue("process", "check-out");

            const img = new Image();

            // Set the source of the image to the decoded Base64 string
            img.src =
              "data:image/png;base64," + response.data.data.checkinSignature;
            img.onload = () => {
              // Clear existing signature if any
              padRef.current?.clear();

              // Draw the image on the canvas

              // padRef.current?.fromDataURL(img.src);
              setCheckInSignatureImage(img.src);
            };
          } else {
            formik.setFieldValue("process", "check-in");
          }
          formik.setFieldValue(
            "consentFormId",
            response.data.data.consentFormId
          );
          formik.setFieldValue("pin", response.data.data.pin);
          formik.setFieldValue("guestName", response.data.data.name);
          formik.setFieldValue("emailAddress", response.data.data.email);
          const prefixAndPhoneNumber = response.data.data.phoneNum.split(
            " ",
            2
          );
          formik.setFieldValue("prefix", prefixAndPhoneNumber[0]);
          formik.setFieldValue(
            "phoneNumber",
            prefixAndPhoneNumber.length > 1 ? prefixAndPhoneNumber[1] : ""
          );
          Notification.success("PIN Verified Successfully");
        })
        .catch((res: any) => {
          if (res.response.status === 400) {
            Notification.failed("In-correct pin");
            return;
          }

          if (res.response.status === 403) {
            Notification.info(res.response.data.message);
            return;
          }

          Notification.failed("Something wrong. Please try again later");
        });
      setLoading(false);
    }
  };

  return (
    <>
      <Modal open={openModal}>
        <Box sx={modalStyle} bgcolor={theme.palette.background.default}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
            sx={{ background: "#141414" }}
          >
            <Grid container spacing={0} sx={{ p: 0, width: "100%" }}>
              <Grid item xs={12}>
                <Stack
                  display={"flex"}
                  sx={{
                    p: 2,
                    border: 0,
                    borderColor: "#232323",
                    height: "50px",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: 12,
                        sm: 16,
                        md: 20,
                        lg: 24,
                      },
                    }}
                    fontWeight={"bold"}
                    color={theme.palette.primary.main}
                  >
                    Pin Submission Form
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  flexItem
                  sx={{
                    borderBottomWidth: 2.5,
                    borderColor: theme.palette.primary.main,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Stack
                  display={"flex"}
                  sx={{
                    pb: 3,
                    m: 3,
                    border: 0,
                    borderColor: theme.palette.primary.main,
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    inputProps={{ maxLength: 6 }}
                    size={"small"}
                    fullWidth
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                    placeholder="Enter pin"
                    error={!!pinError}
                    helperText={pinError}
                    sx={{
                      height: "5px",
                      padding: 0,
                      ".MuiInputBase-input": {
                        border: "none",
                        paddingY: "5px",
                        backgroundColor: "white",
                        color: "black",
                        "&:focus": {
                          borderColor: "black", // Change the focus border color to green
                        },
                      },
                      ".Mui-focused": {
                        borderColor: "black",
                      },
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  flexItem
                  sx={{
                    borderBottomWidth: 2.5,
                    borderColor: theme.palette.primary.main,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Stack
                  sx={{
                    p: 1,
                    alignItems: "end",
                  }}
                >
                  <Button variant="contained" onClick={handlesubmitPIN}>
                    {loading ? (
                      <CircularProgress color="success" size="1.5rem" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Modal>
      <CssBaseline
        enableColorScheme
        children={
          <>
            <Box
              width={"100vw"}
              minHeight={"100vh"}
              paddingY={2}
              paddingX={"50px"}
              bgcolor={"#fff"}
            >
              <form onSubmit={formik.handleSubmit}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    alignItems={"center"}
                    marginBottom={2}
                  >
                    <OnlyLogoSVG className="logo" width={60} height={60} />
                    <Typography variant="h1" color={"black"}>
                      Hotel Policy
                    </Typography>
                  </Stack>
                  <FormControl>
                    <Select
                      size="small"
                      value={formik.values.process}
                      name={"process"}
                      onChange={formik.handleChange}
                      sx={{
                        width: "120px",
                        ".MuiInputBase-input": {
                          border: "none",
                          paddingY: "5px",
                          backgroundColor: "white",
                          color: "black",
                          "&:focus": {
                            borderColor: "black", // Change the focus border color to green
                          },
                        },
                      }}
                    >
                      <MenuItem value={"check-in"}>Check In</MenuItem>
                      <MenuItem value={"check-out"}>Check Out</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Typography color={"black"}>
                  All guests are provided these items and are required to return
                  the following items to be returned upon check out:
                </Typography>
                <Stack direction={"row"} spacing={3} marginBottom={1}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          readOnly={true}
                          style={{ color: "black" }}
                        />
                      }
                      label={
                        <Typography variant="h4" color={"black"}>
                          Room Cards
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          readOnly={true}
                          style={{ color: "black" }}
                        />
                      }
                      label={
                        <Typography variant="h4" color={"black"}>
                          Towels
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          readOnly={true}
                          style={{ color: "black" }}
                        />
                      }
                      label={
                        <Typography variant="h4" color={"black"}>
                          Locker Keys
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          readOnly={true}
                          style={{ color: "black" }}
                        />
                      }
                      label={
                        <Typography variant="h4" color={"black"}>
                          Slippers
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Stack>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  justifyContent={"space-between"}
                  marginBottom={2}
                >
                  <ConsentRules
                    icon={<NoSmokingSVG />}
                    title={[
                      "No Smoking",
                      "Dilarang Merokok",
                      "禁止吸烟",
                      "禁煙",
                      "금연",
                      "Курение запрещено",
                    ]}
                  />

                  <ConsentRules
                    icon={<NoBigLuggageSVG />}
                    title={[
                      "No Big Luggage",
                      "Dilarang Beg Besar",
                      "禁止携带行李箱",
                      "大型の荷物禁止",
                      "대형 짐 금지",
                      "Нет большого багажа",
                    ]}
                  />
                  <ConsentRules
                    icon={<NoFoodSVG />}
                    title={[
                      "No Outside Food & Drinks",
                      "Dilarang Makanan & Minuman Luar",
                      "禁止外带食物及饮料",
                      "外部飲食物は持ち込み禁止",
                      "외부 음식 및 음료물 통제",
                      "Никакой внешней еды и напитков",
                    ]}
                  />
                  <ConsentRules
                    icon={<BeQuietSVG />}
                    title={[
                      "Be Quiet",
                      "Dilarang Bunyi Kuat",
                      "保持安静",
                      "大きな音禁止",
                      "큰 소리 금지",
                      "Будь спокоен",
                    ]}
                  />
                </Stack>
                <Stack direction={"row"} marginBottom={2}>
                  <Typography
                    sx={{ color: darken("#ffffff", 0.8), marginRight: "3px" }}
                  >
                    ** Guests are urged to stay updated on their own flight
                    times as
                  </Typography>
                  <Typography fontWeight={"bold"} color={"black"}>
                    the hotel will not be liable for any missed flights.
                  </Typography>
                </Stack>

                <Stack
                  direction={"column"}
                  width={"100%"}
                  marginTop={2}
                  marginBottom={2}
                  spacing={2}
                >
                  <ConsentTextField
                    title="PIN"
                    name={"pin"}
                    values={formik.values.pin}
                    handleChange={formik.handleChange}
                  />
                  <ConsentTextField
                    title="Guest Name"
                    name={"guestName"}
                    values={formik.values.guestName}
                    handleChange={formik.handleChange}
                  />
                  <ConsentTextField
                    title="Email Address"
                    name={"emailAddress"}
                    values={formik.values.emailAddress}
                    handleChange={formik.handleChange}
                  />
                  <Stack direction={"row"}>
                    <Box
                      display={"flex"}
                      borderColor={theme.palette.primary.main}
                      width={"18.5%"}
                      height={"35px"}
                      alignItems={"center"}
                      paddingX={2}
                    >
                      <Typography fontWeight={"bold"} color={"black"}>
                        Phone Number
                      </Typography>
                    </Box>
                    {countries && (
                      <>
                        <Autocomplete
                          size="small"
                          disableClearable
                          value={formik.values.prefix || ""}
                          onChange={(event: any, newValue: string | null) => {
                            formik.setFieldValue("prefix", newValue || "");
                          }}
                          forcePopupIcon={false}
                          options={Array.from(
                            new Set(
                              countries.map((country) => "+" + country.prefix)
                            )
                          )}
                          isOptionEqualToValue={(option, value) => option === value}
                          ListboxProps={{ style: { maxHeight: "200px" } }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                              }}
                              sx={{
                                input: { border: "none" },
                              }}
                            />
                          )}
                          sx={{
                            height: "0px",
                            width: "150px",
                            padding: 0,
                            ".MuiInputBase-input": {
                              border: "none",
                              paddingY: "5px",
                              backgroundColor: "white",
                              color: "black",
                              "&:focus": {
                                borderColor: "black", // Change the focus border color to green
                              },
                            },
                            ".Mui-focused": {
                              borderColor: "black",
                            },
                          }}
                        />
                        <PhoneField
                          value={formik.values.phoneNumber}
                          name="phoneNumber"
                          setFieldValue={(value) =>
                            formik.setFieldValue("phoneNumber", value)
                          }
                          sx={{
                            padding: 0,
                            ".MuiInputBase-input": {
                              border: "none",
                              backgroundColor: "white",
                              color: "black",
                              "&:focus": {
                                borderColor: "black", // Change the focus border color to green
                              },
                            },
                            ".Mui-focused": {
                              borderColor: "black",
                            },
                          }}
                        />
                      </>
                    )}
                    {/* <ConsentTextField
                      title="Phone Number"
                      name={"phoneNumber"}
                      values={formik.values.phoneNumber}
                      handleChange={formik.handleChange}
                    /> */}
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  justifyContent={"space-between"}
                  alignItems={"end"}
                >
                  <Stack direction={"row"} width={"60%"}>
                    <Box
                      flexDirection={"row"}
                      width={"80%"}
                      height={"100px"}
                      border={2}
                      borderRadius={"5px"}
                      borderColor={"black"}
                      position={"relative"}
                      sx={{
                        backgroundImage: `url(${checkInSignatureImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "60%",
                        backgroundPosition: "center",
                      }}
                    >
                      <Typography
                        color={"black"}
                        fontWeight={"bold"}
                        sx={{ top: 5, left: 10, position: "absolute" }}
                      >
                        Check In Signature:
                      </Typography>
                      <SignaturePad
                        ref={padRef}
                        canvasProps={{ className: "sigPad" }}
                        penColor={"black"}
                      />
                      <Typography
                        color={"black"}
                        fontWeight={"bold"}
                        sx={{ bottom: 5, right: 10, position: "absolute" }}
                      >
                        Tap To Sign
                      </Typography>
                    </Box>
                    {!checkInSignatureImage && (
                      <Button
                        variant="outlined"
                        onClick={clearCanvas}
                        sx={{
                          color: "black",
                          borderColor: "black",
                          backgroundColor: "white",
                        }}
                      >
                        Clear
                      </Button>
                    )}
                  </Stack>
                  {formik.values.process === "check-out" && (
                    <Stack direction={"row"} width={"60%"}>
                      <Box
                        flexDirection={"row"}
                        width={"80%"}
                        height={"100px"}
                        border={2}
                        borderRadius={"5px"}
                        borderColor={"black"}
                        position={"relative"}
                      >
                        <Typography
                          color={"black"}
                          fontWeight={"bold"}
                          sx={{ top: 5, left: 10, position: "absolute" }}
                        >
                          Check Out Signature:
                        </Typography>
                        <SignaturePad
                          ref={padRefCheckout}
                          canvasProps={{ className: "sigPad" }}
                          penColor={"black"}
                        />
                        <Typography
                          color={"black"}
                          fontWeight={"bold"}
                          sx={{ bottom: 5, right: 10, position: "absolute" }}
                        >
                          Tap To Sign
                        </Typography>
                      </Box>
                      <Button
                        variant="outlined"
                        onClick={clearCheckoutCanvas}
                        sx={{
                          color: "black",
                          borderColor: "black",
                          backgroundColor: "white",
                        }}
                      >
                        Clear
                      </Button>
                    </Stack>
                  )}
                  <Box display={"flex"} justifyContent={"end"} height={"50%"}>
                    <Button
                      variant="outlined"
                      type="submit"
                      sx={{ color: "white", borderColor: "white" }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Stack>
              </form>
            </Box>
          </>
        }
      />
    </>
  );
}

const ConsentTextField = ({
  title,
  name,
  values,
  handleChange,
}: {
  title: string;
  name: string;
  values: unknown;
  handleChange: any;
}) => {
  const theme = useTheme();
  return (
    <Stack direction={"row"} width={"100%"}>
      <Box
        display={"flex"}
        borderColor={theme.palette.primary.main}
        width={"15%"}
        height={"35px"}
        alignItems={"center"}
        paddingX={2}
      >
        <Typography fontWeight={"bold"} color={"black"}>
          {title}
        </Typography>
      </Box>
      <Box width={"85%"} height={"35px"} alignItems={"center"}>
        <TextField
          size={"small"}
          fullWidth
          name={name}
          value={values}
          onChange={handleChange}
          sx={{
            height: "5px",
            padding: 0,
            ".MuiInputBase-input": {
              border: "none",
              paddingY: "5px",
              backgroundColor: "white",
              color: "black",
              "&:focus": {
                borderColor: "black", // Change the focus border color to green
              },
            },
            ".Mui-focused": {
              borderColor: "black",
            },
          }}
        />
      </Box>
    </Stack>
  );
};

const ConsentRules = ({
  title,
  icon,
}: {
  title: string[];
  icon: ReactNode;
}) => {
  return (
    <Box width={"23%"} textAlign={"center"}>
      <Box
        display={"flex"}
        height={"150px"}
        justifyContent={"center"}
        alignItems={"center"}
        marginBottom={1}
      >
        {icon}
      </Box>
      {title.map((item, index) => {
        return (
          <Typography
            variant="h4"
            color={"black"}
            marginBottom={1.5}
            key={index}
          >
            {item}
          </Typography>
        );
      })}
    </Box>
  );
};
