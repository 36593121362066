import {
	Box,
	ButtonBase,
	CircularProgress,
	IconButton,
	Menu,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { ILockerRate, ILockers } from "../../models/lockers/LockerInterface";
import { useEffect, useMemo, useState } from "react";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { Add, Settings } from "@mui/icons-material";
import CustomTable from "../global/table/CustomTable";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import AddOrEditLockerRateModal from "./AddOrEditLockerRateModal";

const LockerRatesInitialValue: ILockerRate = {
	hoursOfUsage: 0,
	isLatest: true,
	lockerRate: "",
	lockerRateId: "",
	lot: "",
	tiers: 0,
};

const LockerRateTabContent = () => {
	const theme = useTheme();
	const token = useSelector((state: RootState) => state.user.accessToken);
	const [pagination, setPagination] = useState<MRT_PaginationState>({
		pageIndex: 0,
		pageSize: 5,
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [lockerRates, setLockerRates] = useState<ILockerRate[]>([]);

	const [selectedLockerRates, setSelectedLockerRates] = useState<ILockerRate>(
		LockerRatesInitialValue
	);

	const [openEditLockerRateModal, setOpenEditLockerRateModal] =
		useState<boolean>(false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpenEditLockerRateModal = () => {
		setOpenEditLockerRateModal(true);
	};
	const handleCloseEditLockerRateModal = () => {
		setOpenEditLockerRateModal(false);
		setSelectedLockerRates(LockerRatesInitialValue);
		setAnchorEl(null);
	};

	const getLockerRatesData = () => {
		setIsLoading(true);
		axiosInstance
			.get(`/lockers-rate`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
				const formattedLockerRates = res.data
					.slice()
					.sort(
						(a: ILockerRate, b: ILockerRate) => a.hoursOfUsage - b.hoursOfUsage
					)
					.sort(
						(a: ILockerRate, b: ILockerRate) =>
							Number(b.isLatest) - Number(a.isLatest)
					);
				setLockerRates(formattedLockerRates);
			})
			.catch((e) => {
				Notification.failed("something wrong, please try again");
			})
			.finally(() => setIsLoading(false));
	};

	const handleArchiveLockerRate = (is_latest: boolean) => {
		const apiUrl = "/lockers-rate/archive/";
		setIsLoading(true);
		const formData = {
			locker_rate_id: selectedLockerRates.lockerRateId,
			is_latest: !is_latest,
		};
		axiosInstance
			.put(apiUrl, formData, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
				Notification.success(res.data.message);

			})
			.finally(() => {
        getLockerRatesData();
				handleClose();

				setIsLoading(false);
			})
      .catch((res) => {
				Notification.failed(res.response.data.error);
			})
	};
	useEffect(() => {
		getLockerRatesData();
	}, [openEditLockerRateModal,]);

	const columns = useMemo<MRT_ColumnDef<ILockerRate>[]>(
		() => [
			{
				header: "Duration",
				Cell: ({ row }) => (
					<p
						style={{
							color: row.original.isLatest ? "white" : "grey",
						}}>
						{row.original.hoursOfUsage} hrs
					</p>
				),
			},
			{
				header: "Price (MYR)",
				Cell: ({ row }) => (
					<p
						style={{
							color: row.original.isLatest ? "white" : "grey",
						}}>
						{row.original.lockerRate}
					</p>
				),
			},
			{
				header: "Action",
				size: 50,
				Cell: ({ row }) => (
					<IconButton
						onClick={(event) => {
							setSelectedLockerRates(row.original);
							handleClick(event);
						}}
						sx={{
							height: "10px",
							width: "10px",
						}}>
						<Settings color="primary" />
					</IconButton>
				),
			},
		],
		[]
	);

	return (
		<>
			<Stack
				direction={"row"}
				width={"100%"}
				minHeight={"3.5rem"}
				justifyContent={"space-between"}
				alignItems={"center"}
				borderBottom={2}
				paddingLeft={2}
				borderColor={theme.palette.primary.main}
				sx={{ backgroundColor: theme.palette.background.default }}>
				<Stack
					direction={"row"}
					width={"100%"}
					height={"100%"}
					justifyContent={"space-between"}
					alignItems={"center"}>
					<Typography variant={"h3"} color={theme.palette.primary.main}>
						Locker Rates
					</Typography>
					<ButtonBase
						onClick={handleOpenEditLockerRateModal}
						sx={{
							height: "100%",
							width: "150px",
							borderLeft: 2,
							borderColor: theme.palette.primary.main,
						}}>
						<Stack
							direction={"row"}
							width={"100%"}
							height={"100%"}
							justifyContent={"center"}
							alignItems={"center"}>
							<Add color="primary" />
							<Typography variant="h4" color={"primary"}>
								Add Rate
							</Typography>
						</Stack>
					</ButtonBase>
				</Stack>
			</Stack>
			{isLoading ? (
				<Box
					display={"flex"}
					height={"50vh"}
					justifyContent={"center"}
					alignItems={"center"}>
					<CircularProgress />
				</Box>
			) : (
				<Box padding={2}>
					<CustomTable
						columns={columns}
						data={lockerRates}
						isLoading={isLoading}
						pagination={pagination}
						handlePaginationChange={setPagination}
						oddEvenBackground={false}
					/>
					<Menu
						id={"basic-menu"}
						open={open}
						anchorEl={anchorEl}
						onClose={() => {
							handleClose();
						}}
						sx={{
							"& .MuiPaper-root": {
								borderRadius: "0",
								width: "10rem",
								border: `3px solid ${theme.palette.primary.main}`,
								backgroundColor: "black",
							},
							"& .MuiPaper-root .MuiList-root": {
								backgroundColor: "black",
								padding: "0",
							},
						}}>
						<MenuItem
							onClick={handleOpenEditLockerRateModal}
							sx={{
								height: "2rem",
								borderBottom: `3px solid ${theme.palette.primary.main}`,
								color: theme.palette.primary.main,
								backgroundColor: "black",
								fontWeight: 600,
								"&:hover": {
									backgroundColor: theme.palette.primary.main,
									color: "black",
								},
							}}>
							Edit Info
						</MenuItem>
						<MenuItem
							onClick={() =>
								handleArchiveLockerRate(selectedLockerRates.isLatest)
							}
							sx={{
								height: "2rem",
								color: theme.palette.primary.main,
								backgroundColor: "black",
								fontWeight: 600,
								"&:hover": {
									backgroundColor: theme.palette.primary.main,
									color: "black",
								},
							}}>
							{selectedLockerRates.isLatest ? "Archived" : "Unarchived"}
						</MenuItem>
					</Menu>
				</Box>
			)}
			<AddOrEditLockerRateModal
				lockerRates={lockerRates}
				setLockerRates={setLockerRates}
				selectedLockerRate={selectedLockerRates}
				openEditLockerRateModal={openEditLockerRateModal}
				handleCloseEditLockerRateModal={handleCloseEditLockerRateModal}
			/>
		</>
	);
};

export default LockerRateTabContent;
