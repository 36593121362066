import {
  Backdrop,
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { INewLockerZones } from "../../models/lockers/LockerInterface";
import { useFormik } from "formik";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useEffect, useState } from "react";

interface IEditLockerModal {
  selectedLockerZone: INewLockerZones;
  openEditLockerZoneModal: boolean;
  handleCloseEditLockerZoneModal: VoidFunction;
  setLockerZones: React.Dispatch<React.SetStateAction<INewLockerZones[]>>;
}

const formikInitialValue: INewLockerZones = {
  details: "",
  isArchive: false,
  name: "",
  zoneId: "",
};

const AddOrEditLockerZoneModal = (props: IEditLockerModal) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);

  const [lockerZone, setLockerZone] = useState<INewLockerZones>(
    props.selectedLockerZone.zoneId
      ? props.selectedLockerZone
      : formikInitialValue
  );

  useEffect(() => {
    setLockerZone(props.selectedLockerZone);
  }, [props.selectedLockerZone]);

  const formik = useFormik({
    initialValues: lockerZone,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const apiUrl = "/lockers/zone/";

      const formData = {
        name: values.name.toUpperCase(),
        is_archive: values.isArchive,
      };

      if (props.selectedLockerZone.zoneId) {
        axiosInstance
          .put(apiUrl + props.selectedLockerZone.zoneId + "/", formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            Notification.success(res.data.message);
            props.handleCloseEditLockerZoneModal();
            props.setLockerZones((prevLockerZonez) => {
              const removedLocker = prevLockerZonez.find(
                (prevLockerZone) => prevLockerZone.zoneId === lockerZone.zoneId
              );

              if (removedLocker) {
                const newLockerZones = prevLockerZonez.filter(
                  (prevLockerZone) =>
                    prevLockerZone.zoneId !== lockerZone.zoneId
                );

                return [
                  {
                    ...values,
                  },
                  ...newLockerZones,
                ];
              }
              return prevLockerZonez;
            });
          })
          .catch((res) => {
            Notification.failed(res.response.data.error);
          });
        return;
      }
      axiosInstance
        .post(apiUrl, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          Notification.success(res.data.message);
          props.handleCloseEditLockerZoneModal();
          props.setLockerZones((prevLockerZone) => {
            const newLockerZone: INewLockerZones = {
              ...values,
              lockerCount: 0,
              zoneId: res.data.id,
            };
            return [newLockerZone, ...prevLockerZone];
          });
        })
        .catch((res) => {
          Notification.failed(res.response.data.error);
        });
    },
  });

  return (
    <Modal
      open={props.openEditLockerZoneModal}
      onClose={props.handleCloseEditLockerZoneModal}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box
        width={"600px"}
        border={`3px solid ${theme.palette.primary.main}`}
        margin={"auto"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
        }}
      >
        <Box
          height={"40px"}
          paddingY={1}
          paddingX={2}
          sx={{
            backgroundColor: theme.palette.background.default,
          }}
          borderBottom={`3px solid ${theme.palette.primary.main}`}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Typography variant={"h3"} color={theme.palette.primary.main}>
            {props.selectedLockerZone.zoneId
              ? "Edit Locker Zone"
              : "New Locker Zone"}
          </Typography>
          <IconButton onClick={() => props.handleCloseEditLockerZoneModal()}>
            <HighlightOffRoundedIcon
              sx={{
                color: theme.palette.primary.main,
                fontSize: "24px",
              }}
            />
          </IconButton>
        </Box>
        <Box padding={3}>
          <Typography variant="h2" marginBottom={2}>
            {props.selectedLockerZone.zoneId
              ? "Edit Locker Zone"
              : "New Locker Zone"}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Stack direction={"column"} spacing={2}>
              <Stack direction={"row"} width={"100%"} alignItems={"center"}>
                <Typography variant="h4" width={"30%"}>
                  Zone Name
                </Typography>
                <TextField
                  size="small"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  inputProps={{ style: { textTransform: "none" } }}
                  sx={{
                    border: 2,
                    borderColor: theme.palette.primary.main,
                    borderRadius: "5px",
                    width: "70%",
                  }}
                />
              </Stack>
              {formik.values.zoneId && (
                <Stack direction={"row"} width={"100%"} alignItems={"center"}>
                  <Typography variant="h4" width={"30%"}>
                    Is Active
                  </Typography>
                  <Switch
                    name="isArchive"
                    checked={!formik.values.isArchive}
                    onChange={(event) =>
                      formik.setFieldValue("isArchive", !event.target.checked)
                    }
                  />
                </Stack>
              )}
              <Box alignSelf={"end"}>
                <Button
                  type={"submit"}
                  variant="outlined"
                  sx={{ width: "120px" }}
                >
                  Save
                </Button>
              </Box>
            </Stack>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddOrEditLockerZoneModal;
