import { Grid, Typography, TextField, Stack, Box } from "@mui/material";
import React, { ReactNode } from "react";
import theme from "../../assets/theme/theme";

type Props = {
  label: string;
  children: ReactNode;
};

const ManagementModalFormChild = ({ label, children }: Props) => {
  return (
    <Stack direction={"row"} width={"100%"}>
      <Box
        display={"flex"}
        border={2}
        borderColor={theme.palette.primary.main}
        width={"230px"}
        alignItems={"center"}
        paddingX={1}
      >
        <Typography variant={"h4"} fontWeight={600}>
          {label}
        </Typography>
      </Box>
      <Box border={2} borderColor={theme.palette.primary.main} width={"100%"}>
        {children}
      </Box>
    </Stack>
  );
};

export default ManagementModalFormChild;
