import axiosInstance from "../constants/axiosConfig";

export type AnalyticsConfig = {
  lot_id: string;
  start_date: string;
  end_date: string;
};

export type RoomBookingPlatformResponse = {
  platform_name: string;
  total: number;
  color_tags: string;
};
export type RoomBookingRoomTypeResponse = {
  room_type_name: string;
  total: number;
};

export type RoomSalesByDateRangeResponse = {
  date: string;
  total: number;
};

export type RoomSales = {
  month_to_date_sales: number;
  average_sales: number;
  total_sales: number;
};

export type RoomSalesByDateRangeResponseV2 = {
  yesterday: RoomSales;
  today: RoomSales;
  tomorrow: RoomSales;
};

export const initialRoomSalesByDateRangeResponseV2: RoomSalesByDateRangeResponseV2 =
  {
    yesterday: {
      average_sales: 0,
      month_to_date_sales: 0,
      total_sales: 0,
    },
    today: {
      average_sales: 0,
      month_to_date_sales: 0,
      total_sales: 0,
    },
    tomorrow: {
      average_sales: 0,
      month_to_date_sales: 0,
      total_sales: 0,
    },
  };

export type RoomBookingRevenueResponse = {
  shower_revenue: string;
  locker_revenue: string;
  pos_revenue: string;
  room_revenue: string;
  lounge_revenue: string;
};

export type RoomBookingOccupancyResponse = {
  total_room: number;
  total_occupied_room: number;
  guest_stayed: number;
  stayed_total_revenue: number;
  no_show_guests: number;
  no_show_total: number;
  lounge_guests: number;
  lounge_revenue: number;
  details: {
    room_type_name: string; //
    color_code: string;
    total_room: number;
    total_room_booked: number;
    total_occupied_room: number;
  }[];
};

const parseConfigToQuery = (config: AnalyticsConfig) => {
  return `?lot_id=${config.lot_id}&start_date=${config.start_date}&end_date=${config.end_date}`;
};
export type ShiftSettingsTransactionSummaryResponse = {
  shiftsettingsId: string;
  accountId: string;
  name: string;
  shiftName: string;
  totalItemPrice: number;
  totalDiscount: number;
  totalTaxValue: number;
};

export function useShiftReportingAPI() {
  const GetShiftSettingsTransactionSummary = (
    config: AnalyticsConfig,
    token: string,
    callBackFn: (result: ShiftSettingsTransactionSummaryResponse[]) => void
  ) => {
    const query = parseConfigToQuery(config);

    const header = {
      Authorization: `Bearer ${token}`,
    };
    axiosInstance
      .get<ShiftSettingsTransactionSummaryResponse[]>(
        "queryservice/v1/shift-settings/transaction-summary/" + query,
        {
          headers: header,
        }
      )
      .then((result) => {
        callBackFn(result.data);
      })
      .catch((error) => {});
  };
  return { GetShiftSettingsTransactionSummary };
}

export default function useAnalyticsAPI() {
  const GetRoomBookingCountByRoomTypeName = (
    config: AnalyticsConfig,
    token: string,
    callBackFn: (result: RoomBookingRoomTypeResponse[]) => void
  ) => {
    const query = parseConfigToQuery(config);

    const header = {
      Authorization: `Bearer ${token}`,
    };
    axiosInstance
      .get<RoomBookingRoomTypeResponse[]>(
        "queryservice/v1/room-booking/room-booking-count-by-room-type-name/" +
          query,
        {
          headers: header,
        }
      )
      .then((result) => {
        callBackFn(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetRoomBookingCountByPlatform = (
    config: AnalyticsConfig,
    token: string,
    callbackFn: (result: RoomBookingPlatformResponse[]) => void
  ) => {
    const query = parseConfigToQuery(config);

    const header = {
      Authorization: `Bearer ${token}`,
    };
    axiosInstance
      .get<RoomBookingPlatformResponse[]>(
        "queryservice/v1/room-booking/room-booking-count-by-platform/" + query,
        {
          headers: header,
        }
      )
      .then((result) => {
        callbackFn(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetRoomSalesByDateRange = (
    config: AnalyticsConfig,
    token: string,
    callbackFn: (result: RoomSalesByDateRangeResponse[]) => void
  ) => {
    const query = parseConfigToQuery(config);

    axiosInstance
      .get<RoomSalesByDateRangeResponse[]>(
        "queryservice/v1/sales-summary/room-sales-summary-by-date/" + query,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        callbackFn(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetRoomBookingOccupancy = (
    config: AnalyticsConfig,
    token: string,
    callBackFn: (result: RoomBookingOccupancyResponse) => void
  ) => {
    const query = parseConfigToQuery(config);

    const header = {
      Authorization: `Bearer ${token}`,
    };
    axiosInstance
      .get<RoomBookingOccupancyResponse>(
        "queryservice/v1/room-booking/room-occupancy-count-revenue/" + query,
        {
          headers: header,
        }
      )
      .then((result) => {
        const data: any = result.data;
        callBackFn({
          ...data,
          no_show_total: parseFloat(data.no_show_revenue),
          stayed_total_revenue: parseFloat(data.stayed_total_revenue),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetRoomBookingRevenue = (
    config: AnalyticsConfig,
    token: string,
    callBackFn: (result: RoomBookingRevenueResponse) => void
  ) => {
    const query = parseConfigToQuery(config);

    const header = {
      Authorization: `Bearer ${token}`,
    };
    axiosInstance
      .get<RoomBookingRevenueResponse>(
        "queryservice/v1/room-booking/dashboard-revenue" + query,
        {
          headers: header,
        }
      )
      .then((result) => {
        callBackFn(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return {
    GetRoomBookingCountByPlatform,
    GetRoomBookingCountByRoomTypeName,
    GetRoomSalesByDateRange,
    GetRoomBookingRevenue,
    GetRoomBookingOccupancy,
  };
}
