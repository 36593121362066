import axiosInstance from "../constants/axiosConfig"


export default function useUpdate() {

    const updateExpiredBookingStatus = (token : string) => {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        axiosInstance.put(
            "/bookings/update-expired-booking/",
            {},
            config
        ).then(res => {
            if (res.status !== 200) {
                return false;
            }
        }).catch(e => {
            console.log(e);
            return false;
        })

        return true;
    }

    const updateOverstayBookingStatus = (token : string) => {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        axiosInstance.put(
            "/bookings/update-overstay-booking/",
            {},
            config
        ).then(res => {
            if (res.status !== 200) {
                return false;
            }
        }).catch(e => {
            console.log(e);
            return false;
        })

        return true;
    }

    const cancelExpiredWebBooking = (token : string) => {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        axiosInstance.put(
            "/bookings/cancel-website-booking/",
            {},
            config
        ).then(res => {
            if (res.status !== 200) {
                return false;
            }
        }).catch(e => {
            console.log(e);
            return false;
        })

        return true;
    }

    return {
        updateExpiredBookingStatus,
        updateOverstayBookingStatus,
        cancelExpiredWebBooking,
    }
}