import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table";
import React from "react";
import theme from "../../assets/theme/theme";
import { Stack, Typography } from "@mui/material";
import SearchOffIcon from "@mui/icons-material/SearchOff";

type Props<T extends Record<string, any>> = {
  items: T[];
  columns: MRT_ColumnDef<T>[];
  pagination: MRT_PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<MRT_PaginationState>>;
  searchQuery: string;
};

const TableContentArea = <T extends Record<string, any>>({
  items,
  columns,
  pagination,
  setPagination,
  searchQuery,
}: Props<T>) => {
  return (
    <MaterialReactTable
      columns={columns}
      data={items}
      state={{ pagination, globalFilter: searchQuery }}
      onPaginationChange={setPagination}
      enableTopToolbar={false}
      renderEmptyRowsFallback={(table) => (
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <SearchOffIcon
            sx={{
              color: theme.palette.background.default,
              fontSize: "10rem",
            }}
          />
          <Typography
            variant={"h2"}
            sx={{
              "&:last-child": {
                color: "#232323",
              }, //don't why need to be more specific for the css selector
            }}
          >
            No Data Found
          </Typography>
        </Stack>
      )}
      muiTableHeadProps={{
        sx: {
          ".MuiTableHead-root": {
            border: 2,
            borderStyle: "solid",
            borderColor: theme.palette.primary.main,
          },
        },
      }}
      muiTableHeadCellProps={{
        sx: {
          color: theme.palette.primary.main,
          backgroundColor: "black",
          ".MuiSvgIcon-root.MuiTableSortLabel-icon": {
            color: theme.palette.primary.main,
          },
          borderStyle: "none",
          borderBottomStyle: "solid",
          borderBottomColor: theme.palette.primary.main,
        },
      }}
      muiTableHeadCellColumnActionsButtonProps={{
        sx: {
          display: "none",
        },
      }}
      muiTableContainerProps={{
        sx: {
          backgroundColor: "black",
          // height: items.length !== 0 ? "33em" : "fit-content",
          // minHeight: items.length !== 0 ? "33em" : "fit-content",
        },
      }}
      muiTableBodyProps={{
        sx: {
          backgroundColor: "black",
          "& .MuiTypography-root": {
            color: theme.palette.primary.main,
          },
        },
      }}
      muiTableBodyRowProps={{
        sx: {
          backgroundColor: "black",
        },
        hover: false,
      }}
      muiTableBodyCellProps={{
        sx: {
          color: "white",
          borderBottomStyle: "solid",
          borderBottomColor: theme.palette.primary.main,
          paddingY: 0,
          height: "2.8em",
          minHeight: "2.8em",
        },
      }}
      muiBottomToolbarProps={{
        sx: {
          backgroundColor: "black",
          height: "3em",
          minHeight: "3em",
        },
      }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [-1],
        sx: {
          ".MuiTablePagination-displayedRows": {
            color: theme.palette.primary.main,
          },
          ".MuiTablePagination-actions .MuiButtonBase-root.MuiIconButton-root .MuiSvgIcon-root":
            {
              color: theme.palette.primary.main,
            },
          ".MuiTablePagination-actions .MuiButtonBase-root.MuiIconButton-root.Mui-disabled .MuiSvgIcon-root":
            {
              color: theme.palette.primary.main,
              opacity: 0.5,
            },
        },
      }}
    />
  );
};

export default TableContentArea;
