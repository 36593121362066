import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "black",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        },
      },
    },
    // input field
    MuiInputBase: {
      styleOverrides: {
        input: {
          textTransform: "uppercase",
          color: "#fff", // Text color
          backgroundColor: "#232323", // Input background color
          borderRadius: "4px",
          padding: "8px 12px",
          border: "1px solid rgba(255, 255, 255, 0.3)",
          "&:focus": {
            borderColor: "#fff", // Border color on focus
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "white",
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        outlined: {
          backgroundColor: "#232323",
          borderRadius: 0,
          fontWeight: "bold",
          fontSize: 14,
          letterSpacing: 1,
        },
        contained: {
          borderRadius: 0,
          fontWeight: "bold",
          fontSize: 14,
          letterSpacing: 1,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: "#232323",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: "#232323",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          height: "20px",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: "white",
        },
        track: {
          opacity: 0.2,
          backgroundColor: "#fff",
          "$checked$checked + &": {
            opacity: 0.7,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },

  palette: {
    background: {
      default: "#232323",
      paper: "#1B1B1B",
    },
    primary: {
      light: "#06D6A0",
      main: "#15FFDC",
    },
    secondary: {
      main: "#148978",
    },
    error: {
      main: "#FF4D56",
      dark: "#FF4F4F",
    },
    warning: {
      main: "#FF9F46",
    },
    info: {
      main: "#27B2FF",
    },
  },
  typography: {
    fontFamily: `'IBM Plex Sans', sans-serif;`,
    h6: {
      fontWeight: 500,
      fontSize: "0.750rem",
      color: "#fff",
    },
    h5: {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: "#fff",
    },
    h4: {
      fontSize: "1rem",
      fontWeight: 600,
      color: "#fff",
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: 600,
      color: "#fff",
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 700,
      color: "#fff",
    },
    h1: {
      fontSize: "2.125rem",
      fontWeight: 700,
      color: "#fff",
    },
    subtitle1: {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: "#212121",
    },
    subtitle2: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
    body1: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.334em",
      color: "#fff",
    },
    body2: {
      letterSpacing: "0em",
      fontWeight: 400,
      lineHeight: "1.5em",
    },
    button: {
      textTransform: "capitalize",
    },
  },
});

export default theme;
