import { useEffect, useState } from "react";
import useAnalyticsAPI, {
  RoomBookingOccupancyResponse,
  RoomBookingRevenueResponse,
} from "./useAnalyticsAPI";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

export interface Dataset {
  label?: string;
  data: number[];
  backgroundColor?: string[];
  borderColor?: string[];
  borderWidth?: number;
}

export type ChartDatasetConfig = {
  type?: string;
  data: {
    labels: string[];
    datasets: Dataset[];
  };
  options?: any; // You can define a more specific options interface if needed
};

export interface PieChartDatasetState {
  chartState: ChartDatasetConfig;
  isLoading: boolean;
}

export interface RevenueBarState {
  data: RoomBookingRevenueResponse;
  isLoading: boolean;
}

export interface OccupancySetState {
  data: RoomBookingOccupancyResponse;
  isLoading: boolean;
}

/**
 *  @description This hook is used to get the room booking statistics by platform
 * @param lotId
 * @param startDate
 * @param endDate
 * @returns
 */
export const useChartJSStatistics = (
  lotId: string,
  startDate: Date,
  endDate: Date
): PieChartDatasetState => {
  const { GetRoomBookingCountByPlatform } = useAnalyticsAPI();

  const token: string = useSelector(
    (state: RootState) => state.user.accessToken
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [chartState, setChartState] = useState<ChartDatasetConfig>({
    data: {
      labels: [],
      datasets: [
        {
          data: [],
        },
      ],
    },
  });
  useEffect(() => {
    GetRoomBookingCountByPlatform(
      {
        lot_id: lotId,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
      },
      token,
      (result) => {
        setChartState({
          data: {
            labels: result.map((item) => item.platform_name),
            datasets: [
              {
                backgroundColor: result.map((item) => item.color_tags),
                data: result.map((item) => item.total),
              },
            ],
          },
        });
        setIsLoading(false);
      }
    );
    return () => {};
  }, []);

  return {
    chartState,
    isLoading,
  };
};

/**
 * @description This hook is used to get the room booking statistics by room type the data is returned in a chart js dataset format
 * @param lotId
 * @param startDate
 * @param endDate
 * @returns
 */
export const useSalesByDateStatisticsChart = (
  lotId: string,
  startDate: Date,
  endDate: Date
): PieChartDatasetState => {
  const { GetRoomSalesByDateRange } = useAnalyticsAPI();

  const token: string = useSelector(
    (state: RootState) => state.user.accessToken
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [chartState, setChartState] = useState<ChartDatasetConfig>({
    data: {
      labels: [],
      datasets: [
        {
          data: [],
        },
      ],
    },
  });
  useEffect(() => {
    GetRoomSalesByDateRange(
      {
        lot_id: lotId,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
      },
      token,
      (result) => {
        setChartState({
          data: {
            labels: result.map((item) => item.date),
            datasets: [
              {
                label: "Sales",
                data: result.map((item) => item.total),
              },
            ],
          },
        });
        setIsLoading(false);
      }
    );
    return () => {};
  }, []);

  return {
    chartState,
    isLoading,
  };
};

/**
 *  @description This hook is used to get the room booking revenue statistics by platform
 * @param lotId
 * @param startDate
 * @param endDate
 * @returns
 */
export const useOccupancyStatistic = (
  lotId: string,
  startDate: Date,
  endDate: Date
): OccupancySetState => {
  const { GetRoomBookingOccupancy } = useAnalyticsAPI();

  const token: string = useSelector(
    (state: RootState) => state.user.accessToken
  );

  const initialValue: RoomBookingOccupancyResponse = {
    details: [],
    total_occupied_room: 0,
    total_room: 0,
    guest_stayed: 0,
    no_show_guests: 0,
    no_show_total: 0,
    stayed_total_revenue: 0,
    lounge_guests: 0,
    lounge_revenue: 0
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<RoomBookingOccupancyResponse>(initialValue);
  useEffect(() => {
    GetRoomBookingOccupancy(
      {
        lot_id: lotId,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
      },
      token,
      (result) => {
        setData(result);
        setIsLoading(false);
      }
    );
    return () => {};
  }, []);

  return {
    data,
    isLoading,
  };
};

/**
 *  @description This hook is used to get the room booking revenue statistics
 * @param lotId
 * @param startDate
 * @param endDate
 * @returns
 */
export const useRevenueStatistic = (
  lotId: string,
  startDate: Date,
  endDate: Date
): RevenueBarState => {
  const { GetRoomBookingRevenue } = useAnalyticsAPI();

  const token: string = useSelector(
    (state: RootState) => state.user.accessToken
  );

  const initialValue: RoomBookingRevenueResponse = {
    locker_revenue: "0",
    pos_revenue: "0",
    shower_revenue: "0",
    room_revenue: "0",
    lounge_revenue: "0"
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<RoomBookingRevenueResponse>(initialValue);
  useEffect(() => {
    GetRoomBookingRevenue(
      {
        lot_id: lotId,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
      },
      token,
      (result) => {
        setData(result);
        setIsLoading(false);
      }
    );
    return () => {};
  }, []);

  return {
    data,
    isLoading,
  };
};
