import {
  ButtonBase,
  Box,
  Stack,
  Typography,
  Divider,
  IconButton,
  darken,
} from "@mui/material";
import ColorCode from "../../utils/colorCodeConfig";
import { Delete } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { bookingStatusEnum } from "../../constants/enums";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { ItemTypes } from "../../constants/react-dnd/ItemType";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type Props = {
  measurements: {
    epochPerEM: number;
  };
  todayDateEpoch: number;
  checkInDatetime: string;
  checkOutDatetime: string;
  maxCheckOutDatetime: string;
  currentBookingStatus: string;
  customerFirstname: string;
  customerLastname?: string;
  bookingNo: string;
  bookingPlatform: string;
  roomStatus: string;
  removeBookingPeriod: (rowIndex: number, periodIndex: number) => void;
  index: number;
  bookingIndex: number;
  bookingId: string;
  roomBookingId: string;
};

const BookingRowItem = ({
  measurements: { epochPerEM },
  todayDateEpoch,
  checkInDatetime,
  checkOutDatetime,
  currentBookingStatus,
  customerFirstname,
  customerLastname,
  bookingNo,
  bookingPlatform,
  roomStatus,
  index,
  removeBookingPeriod,
  bookingIndex,
  bookingId,
  maxCheckOutDatetime,
  roomBookingId,
}: Props) => {
  const navigate = useNavigate();

  const role = useSelector((state: RootState) => state.user.role);

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: ItemTypes.BOOKING,
      item: { roomBookingId: roomBookingId },
      end(item, monitor) {},
      collect: (monitor: DragSourceMonitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [roomBookingId]
  );

  const handleBookingClick = (bookingId: string | undefined) => {
    if (currentBookingStatus === bookingStatusEnum.reservation) {
      return;
    }
    if (bookingId !== undefined) {
      const now = new Date();
      if (new Date(checkInDatetime) < now) {
        navigate(`/current-inhouse-guest/${bookingId}`);
      }
      if (new Date(checkInDatetime) > now) {
        navigate(`/upcoming-booking/${bookingId}`);
      }
    } else {
      return;
    }
  };

  if (
    currentBookingStatus === bookingStatusEnum.noShow &&
    bookingPlatform === "Walk In"
  ) {
    return <></>;
  }

  return (
    <>
      <ButtonBase
        ref={drag}
        onClick={() => handleBookingClick(bookingId)}
        // onClick={() => navigate(`/bookings/${bookingId}`)}
        sx={{
          fontSize: "14px", //font-size affect the em length of the element and its children
          position: "absolute",
          zIndex:
            currentBookingStatus === bookingStatusEnum.reservation ? 2 : 5,
          left: `${
            (new Date(checkInDatetime).getTime() - todayDateEpoch) / epochPerEM
          }em`,
        }}
      >
        <Box
          sx={{
            backgroundColor: ColorCode.bookingStatus(
              currentBookingStatus,
              roomStatus
            ),
            borderRadius: "1em",
            height: "3em",
            width: `${
              (new Date(checkOutDatetime).getTime() -
                new Date(checkInDatetime).getTime()) /
              epochPerEM
            }em`,
            opacity: opacity,
          }}
        >
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            height={"inherit"}
          >
            <Stack
              flexDirection={"column"}
              alignItems={"start"}
              width={"50%"}
              padding={"0.5em"}
            >
              <Typography
                variant="caption"
                fontWeight={600}
                align={"left"}
                width={"100%"}
                height={"50%"}
                display={"block"}
                noWrap
              >
                {customerFirstname} {customerLastname}
              </Typography>
              <Typography
                variant="caption"
                fontWeight={600}
                align={"left"}
                width={"100%"}
                height={"50%"}
                display={"block"}
                noWrap
              >
                {bookingNo ? (
                  bookingNo
                ) : currentBookingStatus === bookingStatusEnum.reservation ? (
                  "-"
                ) : (
                  <IconButton
                    onClick={() => removeBookingPeriod(bookingIndex, index)}
                    color="error"
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                )}
              </Typography>
            </Stack>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderRightWidth: 2 }}
            />
            <Stack
              flexDirection={"column"}
              alignItems={"start"}
              width={"50%"}
              padding={"0.5em"}
            >
              <Typography
                variant="caption"
                fontWeight={600}
                align={"right"}
                width={"100%"}
                height={"50%"}
                display={"block"}
                noWrap
              >
                {bookingPlatform}
              </Typography>
              <Typography
                variant="caption"
                fontWeight={600}
                align={"right"}
                width={"100%"}
                height={"50%"}
                display={"block"}
                noWrap
              >
                {currentBookingStatus}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </ButtonBase>
      {bookingId !== undefined && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 1,
            left: `${
              (new Date(checkInDatetime).getTime() - todayDateEpoch) /
              epochPerEM
            }em`,
            backgroundColor: darken(
              ColorCode.bookingStatus(currentBookingStatus, roomStatus)!,
              0.5
            ),
            borderRadius: "1em",
            height: "3em",
            width: `${
              (new Date(maxCheckOutDatetime).getTime() -
                new Date(checkInDatetime).getTime()) /
              epochPerEM
            }em`,
          }}
        ></Box>
      )}
    </>
  );
};

export default BookingRowItem;
