import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import axiosInstance from "../../constants/axiosConfig";
import { store } from "../../redux/store";
import CustomTable from "../../components/global/table/CustomTable";
import {
	Box,
	Button,
	Grid,
	IconButton,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import theme from "../../assets/theme/theme";
import { Edit, WindowSharp } from "@mui/icons-material";
import AddorEditCashierModal from "./AddorEditCashier";
import Notification from "../../utils/notificationConfig";
import ICashier from "../../models/payment/PaymentInterface";

const CashierManagementPage = () => {
	const token = store.getState().user.accessToken;
	const [isLoading, setIsLoading] = useState(false);
	const [pagination, setPagination] = useState<MRT_PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});
	const [cashierData, setCashierData] = useState<ICashier[]>([]);
	const [searchBar, setSearchBar] = useState<string>("");
	const [openCashierModal, setOpenCashierModal] = useState<boolean>(false);
	const [selectedCashier, setSelectedCashier] = useState<ICashier>();
	const handleSearchBarOnChange = (event: any) => {
		setSearchBar(event.target.value);
	};
	const handleOpenCashierModal = (value?: ICashier) => {
		setOpenCashierModal(true);
		setSelectedCashier(value);
	};
	const handleCloseCashierModal = () => {
		setOpenCashierModal(false);
	};
	const getCashier = () => {
		axiosInstance
			.get(`/cashierterminal/`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
                setCashierData(res.data.data.sort((a: { cashierTerminalId:string; }, b: { cashierTerminalId: string; }) => (a.cashierTerminalId > b.cashierTerminalId) ? 1 : -1));
			})
            .catch((res)=>{
                Notification.failed("retrieve failed")
            })

	};

	useEffect(() => {
		getCashier();
	}, []);

	const cashierColumn = useMemo<MRT_ColumnDef<ICashier>[]>(
		() => [
			{
				header: "Cashier Terminal",
				accessorKey: "cashierTerminal",
			},
			{
				header: "KC Number",
				accessorKey: "kcNumber",
			},
			{
				accessorKey: "lotNumber",
				header: "Lot",
			},
			{
				header: "Edit",
				accessorKey: "isArchive",
				Cell: ({ row }) => {
					return (
						<IconButton
							color="primary"
							onClick={(event) => handleOpenCashierModal(row.original)}>
							<Edit />
						</IconButton>
					);
				},
			},
		],
		[]
	);

	return (
		<>
			<Box
				width={"85%"}
				border={1}
				borderColor={theme.palette.primary.main}
				margin={"auto"}
				justifyContent={"space-between"}
				alignItems={"center"}
				sx={{
					backgroundColor: theme.palette.background.paper,
				}}>
				{!openCashierModal ? (
					<>
						<Grid
							container
							borderBottom={2}
							borderColor={theme.palette.primary.main}
							direction={"row"}
							alignItems={"center"}
							padding={2}
							>
							<Grid item xs={1}>
								{" "}
								<Typography>Search</Typography>
							</Grid>
							<Grid item xs={8}>
								{" "}
								<TextField
									fullWidth
									onChange={(e) => {
										handleSearchBarOnChange(e);
									}}>
									Search Bar
								</TextField>
							</Grid>
							<Grid item xs={1}></Grid>
							<Grid item xs={2}>
								{" "}
								<Button
									onClick={() => handleOpenCashierModal()}
									sx={{
										background: "black",
										border: 1,
										borderColor: theme.palette.primary.main,
										justifyContent: "flex-end",
									}}>
									Create New
								</Button>
							</Grid>
						</Grid>
						<CustomTable
							columns={cashierColumn}
							data={cashierData}
							isLoading={isLoading}
							handlePaginationChange={setPagination}
							pagination={pagination}
							rowCount={cashierData.length}
							oddEvenBackground={true}
							globalFilter={searchBar}
						/>
					</>
				) : (
					<>
						{" "}
						<AddorEditCashierModal
							selectedCashierData={selectedCashier}
							onClose={handleCloseCashierModal}
							setCashierTerminal={setCashierData}
						/>
					</>
				)}
			</Box>
		</>
	);
};

export default CashierManagementPage;
