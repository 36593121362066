import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  Grid,
  IconButton,
  CircularProgress,
} from "@mui/material";
import axiosInstance from "../../constants/axiosConfig";

// Utils
import Notification from "../../utils/notificationConfig";
import { filterTableData } from "../../utils/functions";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

// types and interfaces
import {
  ZoneInterface,
  ZoneInterfaceV2,
} from "../../models/rooms/roomsInterface";
import theme from "../../assets/theme/theme";

//icons
import DoNotDisturbOnTotalSilenceIcon from "@mui/icons-material/DoNotDisturbOnTotalSilence";
import SettingsIcon from "@mui/icons-material/Settings";
import { RoomZoneData } from "./RoomManagement";
import EditRoomZoneModal from "../../components/roomlist/EditRoomZone";
/* ---------------------------------- props --------------------------------- */
type Props = {
  zoneData: ZoneInterfaceV2[];
  setRoomZoneData: React.Dispatch<React.SetStateAction<ZoneInterfaceV2[]>>;
};

/* -------------------------------------------------------------------------- */
/*                         component to display zones                         */
/* -------------------------------------------------------------------------- */
const ZoneCapsule = ({
  zone,
  roomCount,
  roomType,
  handleOpenZoneEdit,
}: {
  zone: ZoneInterfaceV2;
  roomCount: number;
  roomType: string[];
  handleOpenZoneEdit: (zoneId: ZoneInterfaceV2) => void;
}) => {
  return (
    <Stack width={"100%"} height={"300px"} sx={{ background: "#141414" }}>
      <Stack
        height={"40%"}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        borderBottom={1}
        borderColor={"white"}
        p={2}
        sx={{ background: theme.palette.background.default }}
      >
        <Stack width={"fit-content"} justifyContent={"center"}>
          <Typography
            variant={"h2"}
            sx={{ color: zone.archived ? "white" : theme.palette.primary.main }}
          >
            {zone.zoneName}
          </Typography>
          <Typography variant={"h4"} textAlign={"start"} fontWeight={500}>
            Room Zone
          </Typography>
        </Stack>
        <Stack
          width={"fit-content"}
          direction={"row"}
          alignItems={"center"}
          spacing={2}
        >
          <DoNotDisturbOnTotalSilenceIcon fontSize={"large"} />
          <Stack>
            <Typography variant={"h4"}>Gate Name</Typography>
            <Typography variant={"h5"} textAlign={"end"}>
              Room Zone
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack height={"40%"} p={2}>
        <Stack
          height={"80%"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack width={"fit-content"}>
            <Typography variant={"h4"} sx={{ color: "grey" }}>
              Room
            </Typography>
            <Typography variant={"h3"}>{roomCount}</Typography>
          </Stack>
          <Stack width={"fit-content"}>
            <Typography variant={"h4"} sx={{ color: "grey" }}>
              Room Type
            </Typography>
            <Stack direction={"row"}>
              {roomType.map((item, index) => {
                if (index !== 0) {
                  return (
                    <Typography key={index} variant={"h3"}>
                      ,{item}
                    </Typography>
                  );
                } else {
                  return (
                    <Typography key={index} variant={"h3"}>
                      {item}
                    </Typography>
                  );
                }
              })}
            </Stack>
          </Stack>
          <Stack width={"fit-content"}>
            <Typography
              variant={"h4"}
              sx={{
                color: "grey",
              }}
            >
              Max Pax
            </Typography>
            <Typography variant={"h4"}>{zone.maxPax}</Typography>
          </Stack>
        </Stack>
        <Stack direction={"row"} justifyContent={"end"} sx={{ marginTop: 2 }}>
          <IconButton
            onClick={() => handleOpenZoneEdit(zone)}
            sx={{
              width: "4em",
              borderRadius: 0,
              background: theme.palette.background.default,
            }}
          >
            <SettingsIcon sx={{ color: "white", marginRight: 1 }} />
            <Typography variant={"h4"}>Edit</Typography>
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

const initialZoneData: ZoneInterfaceV2 = {
  zoneId: "",
  zoneName: "",
  archived: false,
};

export default function ZoneList({ zoneData, setRoomZoneData }: Props) {
  /* --------------------------- usestates and token -------------------------- */
  const token = useSelector((state: RootState) => state.user.accessToken);

  const [rows, setRows] = useState<ZoneInterfaceV2[]>([]);

  const [selectedZone, setSelectedZone] =
    useState<ZoneInterfaceV2>(initialZoneData);

  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [updateTrigger, setUpdateTrigger] = useState<boolean>(false);
  const [searchBarValue, setSearchBarValue] = useState<string>("");
  const [zoneFilterSelected, setZoneFilterSelected] = useState("None");
  const [zoneFilterValues, setZoneFilterValues] = useState<string[]>([]);
  const [filteredDataRows, setFilteredDataRows] = useState<ZoneInterfaceV2[]>(
    []
  );
  const [openEditZoneModal, setOpenEditZoneModal] = useState<boolean>(false);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleOpenZoneEdit = (zoneId: ZoneInterfaceV2) => {
    setSelectedZone(zoneId);
    setOpenEditZoneModal(true);
  };

  const handleCloseZoneEdit = () => {
    setSelectedZone(initialZoneData);
    setOpenEditZoneModal(false);
  };

  /* ------------------------------ data fetching ----------------------------- */
  const getPageData = async () => {
    setFetchLoading(true);
    await axiosInstance
      .get("/rooms/v1/zone/zone-management/", config)
      .then((res) => {
        if (res.status === 200) {
          const convertedData = res.data.data.map((data: any) => ({
            ...data,
            zone: data.zoneName,
            totalRoom: data.totalRoom,
            roomType: data.roomTypes.map(
              (roomType: any) => roomType.RoomTypeDetails
            ),
            maxPax: data.maxPax,
          }));
          setRows(convertedData);
          setRoomZoneData(convertedData);
          return;
        }
        Notification.failed("something wrong, please try again");
      })
      .catch((e) => {
        Notification.failed("something wrong, please try again");
      })
      .finally(() => {
        setFetchLoading(false);
        setUpdateTrigger(false);
      });
  };

  /* ------------------------ state handling functions ------------------------ */
  const handleChangeSearchValue = (event: string) => {
    setSearchBarValue(event);
  };

  const handleZoneFilterChange = (event: string) => {
    setZoneFilterSelected(event);
  };

  /* -------------------------- data filter functions ------------------------- */
  const filterRoomZoneData = (data: ZoneInterfaceV2[]): ZoneInterfaceV2[] => {
    if (zoneFilterSelected === "None") {
      return data;
    } else {
      const newData = data.filter(
        (item) => item.zoneName === zoneFilterSelected
      );
      return newData;
    }
  };

  useEffect(() => {
    const rawData = [...rows];
    const filteredData = filterRoomZoneData(rawData);
    const searchedData = filterTableData(filteredData, searchBarValue);
    setFilteredDataRows(searchedData);
  }, [searchBarValue, zoneFilterSelected]);

  /* ------------------------------- useEffects ------------------------------- */
  useEffect(() => {
    document.title = "Capsule Transit | Zone List";
    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateTrigger) {
      getPageData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTrigger]);

  useEffect(() => {
    const zoneUniqueValues = Array.from(
      new Set(rows.map((item) => item.zoneName))
    );
    setZoneFilterValues(zoneUniqueValues);
    setFilteredDataRows(rows);
  }, [rows]);

  /* --------------------------- migth delete later --------------------------- */
  // const handleChangePage = (event: unknown, newPage: number) => {
  //     setPage(newPage);
  // };

  return (
    <Box minHeight={"90vh"}>
      <Stack
        width={"100%"}
        height={"80vh"}
        direction={"row"}
        p={4}
        sx={{ overflowY: "auto" }}
      >
        {!fetchLoading ? (
          <Grid container width={"100%"} columnSpacing={3} rowSpacing={4}>
            {filteredDataRows.map((item, index) => {
              const matchingZoneData = zoneData.find(
                (item2) => item2.zoneName === item.zoneName
              );
              return (
                <Grid item key={index} xs={12} sm={6} md={6} lg={4} xl={4}>
                  <ZoneCapsule
                    zone={item}
                    roomCount={
                      matchingZoneData && matchingZoneData.totalRoom
                        ? matchingZoneData.totalRoom
                        : 0
                    }
                    roomType={
                      matchingZoneData && matchingZoneData.roomType
                        ? matchingZoneData.roomType
                        : []
                    }
                    handleOpenZoneEdit={handleOpenZoneEdit}
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Grid
            container
            width={"100%"}
            height={"20vh"}
            direction={"column"}
            justifyContent={"center"}
            alignContent={"center"}
            spacing={2}
          >
            <CircularProgress color="primary" />
          </Grid>
        )}
      </Stack>
      <EditRoomZoneModal
        handleEditRoomZoneModalClose={handleCloseZoneEdit}
        openEditRoomZoneModal={openEditZoneModal}
        selectedZone={selectedZone}
        title={"Edit Zone"}
      />
    </Box>
  );
}
