import { Grid, Typography, Divider, IconButton } from "@mui/material";
import theme from "../../assets/theme/theme";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DropdownsDetail from "./DropdownsDetail";
import { useEffect, useState } from "react";
import BASE_API from "../../constants/api";
import axiosInstance from "../../constants/axiosConfig";
import { ICountry } from "../../models/guests/GuestsInterface";
import { store } from "../../redux/store";

interface DropdownsProps {
	onDropdownVisibilityChange: (isVisible: boolean) => void;
}

export default function Dropdowns({
	onDropdownVisibilityChange,
}: DropdownsProps) {
	const token = store.getState().user.accessToken;

	const [showDropdownsDetail, setShowDropdownsDetail] = useState(false);
	const[pinnedCountry,setPinnedCountry]= useState<number>(0)
	const FetchCountryCount = () => {
		axiosInstance
		  .get(`${BASE_API}/guests/country/`, {
			headers: {
			  "Content-Type": "application/json",
			  Authorization: `Bearer ${token}`,
			},
		  })
		  .then((response) => {
			let favoriteCount = 0; // Initialize the favorite count
	  
			// Iterate over the response data to count favorites
			response.data.data.forEach((country: ICountry) => {
			  if (country.favorite) {
				favoriteCount++;
			  }
			});
	  
			// Set the favorite count using setPinnedCountry
			setPinnedCountry(favoriteCount-2);
		  });
	  };
	  useEffect(()=>{
		FetchCountryCount()
	  },[pinnedCountry])
	const handleButtonClick = () => {
		setShowDropdownsDetail(!showDropdownsDetail);

		// Pass the boolean value to the parent component
		onDropdownVisibilityChange(!showDropdownsDetail);
	};
	return (
		<div
			style={{
				width: "100%",
				overflow: "auto",
				display: "flex",
				flexDirection: "column",
				height: "100%",
			}}
		>
			<Grid container width={"100%"} p={5}>
				{/**Room Setting Section */}
				<Grid item xs={12}>
					<Typography variant="h3" marginBottom={2}>
						Dropdowns
					</Typography>
				</Grid>
				<Grid item xs={12} paddingBottom={4}>
					<Divider
						orientation="horizontal"
						flexItem
						sx={{
							borderBottomWidth: 3.5,
							borderColor: "#232323",
						}}
					/>
				</Grid>
				<Grid item xs={11} px={2} direction={"row"}>
					<Typography variant="h4">Country List</Typography>
				</Grid>
				<Grid item xs={1} px={2}>
					<IconButton onClick={handleButtonClick}>
						<SettingsOutlinedIcon color="primary" />
					</IconButton>
				</Grid>
				<Grid item xs={2} px={2}>
					{pinnedCountry} pinned
				</Grid>
			</Grid>
		</div>
	);
}
