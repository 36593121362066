import { CircularProgress, Grid, Grow, Tab, Tabs } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import theme from "../../assets/theme/theme";
import CheckInSlip from "./CheckInSlip";
import CheckInSlipDetail from "./CheckInSlipDetail";
import Dropdowns from "./Dropdowns";
import DropdownsDetail from "./DropdownsDetail";
import RoomSettings from "./RoomSettings";
import RoomSettingsDetail from "./RoomSettingsDetail";
import TaxCharges from "./TaxCharges";
import SpecialPaymentDetail from "./SpecialPaymentDetail";
import SpecialPayment from "./SpecialPayment";
import NewTaxChargesDetail from "./NewTaxChargesDetail";
import { store } from "../../redux/store";
import axiosInstance from "../../constants/axiosConfig";
import CompulsoryTax from "./CompulsoryTax";

interface Setting {
  settingsId: number;
  settingsName: string;
  settingsCategory: string;
  settingsDescription: string;
  creationDatetime: string;
  lot: number;
}
export default function PaymentSettings() {
  const [tabStepperValue, setTabStepperValue] = useState("Special Payment");

  const [isCompulsoryTaxVisible, setIsCompulsoryTaxVisible] = useState(false);
  const [isSpecialPaymentVisible, setIsSpecialPaymentVisible] = useState(false);
  const [isCreateAdditionalTaxVisible, setIsCreateAdditionalTaxVisible] =
    useState(false);

  const [selectedAdditionalTaxId, setSelectedAdditionalTaxId] =
    useState<number>();

  const [loading, setLoading] = useState<boolean>(false);
  const [tabComponents, setTabComponents] = useState<
    { name: string; component: JSX.Element }[]
  >([]);

  const handleCompulsoryTaxVisibilityChange = (
    isVisible: boolean | ((prevState: boolean) => boolean)
  ) => {
    setTabStepperValue("Tax & Charges");
    setIsCompulsoryTaxVisible(isVisible);
  };

  const handleSpecialPaymentVisibilityChange = (
    isVisible: boolean | ((prevState: boolean) => boolean),
    settingId?: number
  ) => {
    setTabStepperValue("Special Payment");
    setSelectedAdditionalTaxId(settingId);
    setIsSpecialPaymentVisible(isVisible);
  };

  const handleCreateAdditionalTaxVisibilityChange = (
    isVisible: boolean | ((prevState: boolean) => boolean),
    settingId?: number
  ) => {
    setTabStepperValue("Tax & Charges");
    setSelectedAdditionalTaxId(settingId);
    setIsCreateAdditionalTaxVisible(isVisible);
  };
  useEffect(() => {
    const sectionId = `section-${tabStepperValue}`;
    const sectionElement = document.getElementById(sectionId);

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [tabStepperValue]);

  const handleStepperChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setIsSpecialPaymentVisible(false);
    setIsCreateAdditionalTaxVisible(false);
    setIsCompulsoryTaxVisible(false);
    setTabStepperValue(newValue);
    moveTabToTop(newValue);
  };

  const accountId = store.getState().user.accountId;
  const token = store.getState().user.accessToken;
  const FetchSettings = () => {
    setLoading(true);
    axiosInstance
      .get(`/lot-settings/get-settings/${accountId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setSettingsData(response.data.data);

        const SpecialPaymentName = findSettingByName(
          SpecialPaymentCategory,
          response.data.data
        );

        const specialPaymentSettings = response.data.data.filter(
          (setting: Setting) =>
            setting.settingsCategory === SpecialPaymentCategory
        );

        const additionalTaxSettings = response.data.data.filter(
          (setting: Setting) =>
            setting.settingsCategory === AdditionalTaxCategory
        );

        setTabComponents([
          {
            name: "Special Payment",
            component: (
              <Grow
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                {...(true ? { timeout: 1000 } : {})}
              >
                <div id="section-Special Payment">
                  <SpecialPayment
                    SpecialPaymentCategory={specialPaymentSettings}
                    SpecialPaymentDescription={
                      SpecialPaymentName?.settingsDescription
                    }
                    SpecialPaymentName={SpecialPaymentName?.settingsName}
                    onSpecialPaymentVisibilityChange={
                      handleSpecialPaymentVisibilityChange
                    }
                  />
                </div>
              </Grow>
            ),
          },
          {
            name: "Tax & Charges",
            component: (
              <Grow
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                {...(true ? { timeout: 1000 } : {})}
              >
                <div id="section-Tax & Charges">
                  <TaxCharges
                    additionalTaxCategory={additionalTaxSettings}
                    onTaxChargesVisibilityChange={
                      handleCompulsoryTaxVisibilityChange
                    }
                    onAdditionalTaxVisiblityChange={
                      handleCreateAdditionalTaxVisibilityChange
                    }
                    ServiceChargesDesc={findServiceCharge?.settingsDescription}
                    ServiceChargesId={findServiceCharge?.settingsId}
                    ServiceName={findServiceCharge?.settingsName}
                    SSTDesc={findSST?.settingsDescription}
                    SSTId={findSST?.settingsId}
                    SSTName={findSST?.settingsName}
                  />
                </div>
              </Grow>
            ),
          },
        ]);
        moveTabToTop(tabStepperValue);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    FetchSettings();
  }, [
    isCompulsoryTaxVisible,
    isSpecialPaymentVisible,
    isCreateAdditionalTaxVisible,
  ]);

  const [settingsData, setSettingsData] = useState<Setting[]>([]);

  const findSettingByName = (Name: string, data: Setting[]) => {
    return data.find((setting) => setting.settingsName === Name);
  };

  const findSettingByCategory = (Name: string, data: Setting[]) => {
    return data.find((setting) => setting.settingsCategory === Name);
  };

  const findSettingById = (id: number, data: Setting[]) => {
    return data.find((setting) => setting.settingsId === id);
  };

  const SSTName = "Tax";
  const ServiceChargeName = "Service Charge";

  const findServiceCharge = findSettingByCategory(
    ServiceChargeName,
    settingsData
  );
  const findSST = findSettingByCategory(SSTName, settingsData);

  const SpecialPaymentCategory = "Special Payment";
  const AdditionalTaxCategory = "Additional Tax";

  const IdFinder = findSettingById(selectedAdditionalTaxId || 0, settingsData);

  const moveTabToTop = (tabName: string) => {
    const index = tabComponents.findIndex((item) => item.name === tabName);
    if (index !== -1) {
      const updatedComponents = [...tabComponents];
      const tabToMove = updatedComponents.splice(index, 1)[0];
      updatedComponents.unshift(tabToMove);
      setTabComponents(updatedComponents);
    }
  };

  return (
    <Stack width={"100%"} height={"70vh"}>
      <Grid container>
        <Grid
          item
          md={2}
          sm={2}
          xs={2}
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            minHeight: "100%",
          }}
        >
          <Stack
            direction={"column"}
            textAlign={"start"}
            padding={2}
            spacing={2}
            sx={{ backgroundColor: theme.palette.background.default }}
            height={"70vh"}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabStepperValue}
              onChange={handleStepperChange}
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{
                marginBottom: 5,
                "& .MuiTab-root": {
                  textTransform: "none",
                  fontWeight: 600,
                  minWidth: "8.1vw",
                  fontSize: "1rem",
                  alignContent: "start",
                  "&.Mui-selected": {
                    color: theme.palette.primary.main,
                  },
                  "&:not(.Mui-selected)": {
                    color: "white",
                    borderBottom: "1px solid #0a443c",
                  },
                },
              }}
            >
              <Tab
                value={"Special Payment"}
                label={"Special Payment"}
                disabled={loading}
              />
              <Tab
                value={"Tax & Charges"}
                label={"Tax & Charges"}
                disabled={loading}
              />
            </Tabs>
          </Stack>
        </Grid>

        <Grid item md={10} sm={10} xs={10}>
          {loading ? (
            <Stack
              direction={"column"}
              height={"100%"}
              width={"100%"}
              sx={{ backgroundColor: "#141414", overflow: "auto" }}
              maxHeight={"70vh"}
            >
              <Grid
                item
                md={12}
                display={"flex"}
                justifyContent={"center"}
                alignContent={"center"}
                m={10}
              >
                <CircularProgress />
              </Grid>
            </Stack>
          ) : (
            <Stack
              direction={"column"}
              height={"100%"}
              width={"100%"}
              sx={{ backgroundColor: "#141414", overflow: "auto" }}
              maxHeight={"70vh"}
            >
              {!isCompulsoryTaxVisible &&
                !isSpecialPaymentVisible &&
                !isCreateAdditionalTaxVisible &&
                settingsData && (
                  <>
                    {tabComponents.map((tab, index) => (
                      <div key={index}>{tab.component}</div>
                    ))}
                  </>
                )}
              {isCompulsoryTaxVisible && (
                <CompulsoryTax
                  onCompulsoryTaxVisibilityChange={
                    handleCompulsoryTaxVisibilityChange
                  }
                  ServiceChargesDesc={findServiceCharge?.settingsDescription}
                  ServiceChargesId={findServiceCharge?.settingsId}
                  ServiceName={findServiceCharge?.settingsName}
                  SSTDesc={findSST?.settingsDescription}
                  SSTId={findSST?.settingsId}
                  SSTName={findSST?.settingsName}
                />
              )}
              {isSpecialPaymentVisible && (
                <SpecialPaymentDetail
                  onSpecialPaymentVisibilityChange={
                    handleSpecialPaymentVisibilityChange
                  }
                  SpecialPaymentCategory={IdFinder?.settingsCategory}
                  SpecialPaymentDescription={IdFinder?.settingsDescription}
                  SpecialPaymentName={IdFinder?.settingsName}
                  SpecialPaymentId={selectedAdditionalTaxId}
                />
              )}
              {isCreateAdditionalTaxVisible && (
                <NewTaxChargesDetail
                  onTaxChargesVisibilityChange={
                    handleCreateAdditionalTaxVisibilityChange
                  }
                  TaxChargesId={selectedAdditionalTaxId}
                  TaxCharges={IdFinder?.settingsDescription}
                  TaxName={IdFinder?.settingsName}
                />
              )}
            </Stack>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
}
