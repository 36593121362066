import { FileDownload, FilterAlt, Search } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  ILedgerDetailed,
  ILedgerSummary,
  ILot,
  IPaymentOverview,
  ISalesTransaction,
} from "../../models/payment/PaymentInterface";
import axiosInstance from "../../constants/axiosConfig";
import BASE_API from "../../constants/api";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import CustomTable from "../../components/global/table/CustomTable";
import {
  camelToNormal,
  convertToReadableFormat,
  formatDocumentDateFormat,
  formatToPresentableNumber,
  stringDateToEpoch,
} from "../../utils/functions";
import {
  ArrowDropDownIcon,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { CSVLink } from "react-csv";
import XLSX from "sheetjs-style";
import { format } from "date-fns";
import { ReactComponent as ExcelIcon } from "../../assets/images/icons8-excel.svg";
import { ReactComponent as PDFIcon } from "../../assets/images/pdf-svgrepo-com.svg";
import Notification from "../../utils/notificationConfig";
import { IPlatform } from "../../models/bookings/Booking";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

interface IDynamicGrouping {
  id: string;
  name: string;
}

enum groupings {
  paymentMethod = "paymentMethod",
  paymentType = "paymentType",
  bookingPlatform = "bookingPlatform",
  roomType = "roomType",
  promoCode = "promoCode",
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const lotInitialValue: ILot = {
  airportCodeId: 0,
  lotDescription: "",
  lotId: 0,
  lotNumber: "",
};

const Ledgers = () => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);

  const currentDate = new Date();
  const currentMonthName = format(currentDate, "MMMM");

  const startOfDay = new Date(currentDate);
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date(currentDate);
  endOfDay.setHours(23, 59, 59, 999);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const transactionDetailedDataRef = useRef<any>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleCloseDropdown = () => setAnchorEl(null);
  const handleOpenDropdown = ({
    currentTarget,
  }: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(currentTarget);
  };

  const [reportType, setReportType] = useState<string>("sales");
  const [grouping, setGrouping] = useState<string>("paymentMethod");
  const [dynamicGrouping, setDynamicGrouping] = useState<IDynamicGrouping[]>(
    []
  );
  const [selectedDynamicGrouping, setSelectedDynamicGrouping] = useState<
    string[]
  >([]);

  const [transactions, setTransactions] = useState<ILedgerDetailed[]>([]);
  const [startDate, setStartDate] = useState<Date>(startOfDay);
  const [endDate, setEndDate] = useState<Date>(endOfDay);
  const [currentLot, setCurrentLot] = useState<ILot>(lotInitialValue);

  const [paymentOverview, setPaymentOverview] = useState<ILedgerSummary[]>([]);

  const convertBookingPaymentDate = (data: string) => {
    const arrayOfDates = data.split(", ");

    let convertedDates: string[] = [];

    for (let date of arrayOfDates) {
      if (date !== "None") {
        const convertedDate = format(new Date(date), "dd/MM/yyyy HH:mm");
        convertedDates.push(convertedDate);
      }
    }

    return convertedDates.join(", ");
  };

  const fetchTransactions = () => {
    setIsLoading(true);

    axiosInstance
      .get(`/transaction/get-ledger`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          reportType: reportType,
          startDateTime: startDate ? startDate : "",
          endDateTime: endDate ? endDate : "",
          groupBy: grouping,
          dynamicGrouping: selectedDynamicGrouping,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const dataList = res.data;
          const convertedData = dataList.detailedData
            .map((data: any) => [...data.transactions])
            .flat();

          setTransactions(
            convertedData.map((data: any) => ({
              ...data,
              sales: parseFloat(data.sales),
              tax: parseFloat(data.tax),
              promotion: parseFloat(data.promotion),
              adjustment: parseFloat(data.adjustment),
              total: parseFloat(data.total),
              transactionDate:
                data.transactionDate !== "-"
                  ? format(new Date(data.transactionDate), "dd/MM/yyyy HH:mm")
                  : "-",
              bookingDate: data.bookingDate
                ? format(new Date(data.bookingDate), "dd/MM/yyyy HH:mm")
                : "",
              checkInDate: data.checkInDate
                ? format(new Date(data.checkInDate), "dd/MM/yyyy HH:mm")
                : "",
              checkOutDate: data.checkOutDate
                ? format(new Date(data.checkOutDate), "dd/MM/yyyy HH:mm")
                : "",
            }))
          );

          console.log("TEST");

          setPaymentOverview(
            dataList.summaryData.map((data: any) => {
              const groupingMap: Record<string, string> = {
                [groupings.paymentMethod]: data.paymentMethodName,
                [groupings.paymentType]: data.paymentTypeName,
                [groupings.bookingPlatform]: data.bookingPlatform,
                [groupings.roomType]: data.roomTypeName,
                [groupings.promoCode]: data.promoCode,
              };
              return {
                name: groupingMap[grouping],
                total: parseFloat(data.total),
                count: dataList.detailedData.find((detailedData: any) => {
                  const detailedGroupingMap: Record<string, string> = {
                    [groupings.paymentMethod]: detailedData.paymentMethodName,
                    [groupings.paymentType]: detailedData.paymentTypeName,
                    [groupings.bookingPlatform]: detailedData.bookingPlatform,
                    [groupings.roomType]: detailedData.roomTypeName,
                    [groupings.promoCode]: detailedData.promoCode,
                  };
                  return (
                    groupingMap[grouping] === detailedGroupingMap[grouping]
                  );
                }).transactions.length,
              };
            })
          );
        }
      })
      .catch((e) => Notification.failed(e.response.detailedData.error))
      .finally(() => setIsLoading(false));
  };

  const fetchCurrentLot = () => {
    axiosInstance
      .get(`/lot/get-own-lot`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setCurrentLot(res.data);
          return;
        }
        Notification.failed(res.data.msg);
      })
      .catch((e) => {
        Notification.failed(e.response.data.msg);
      });
  };

  useEffect(() => {
    axiosInstance
      .get("/transaction/get-ledger-dynamic-filter", {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          filterType: grouping,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setDynamicGrouping(res.data.data);
          return;
        }
        Notification.failed(res.data.msg);
      })
      .catch((e) => {
        Notification.failed(e.response.data.msg);
      });
  }, [grouping, token]);

  useEffect(() => {
    fetchCurrentLot();
  }, []);

  // Transactions Sales Columns
  const totalTransaction = useMemo(
    () => transactions.reduce((acc, curr) => acc + Number(curr.total), 0),
    [transactions]
  );

  const totalSales = useMemo(
    () => transactions.reduce((acc, curr) => acc + Number(curr.sales), 0),
    [transactions]
  );

  const totalTax = useMemo(
    () => transactions.reduce((acc, curr) => acc + Number(curr.tax), 0),
    [transactions]
  );

  const totalPromotions = useMemo(
    () => transactions.reduce((acc, curr) => acc + Number(curr.promotion), 0),
    [transactions]
  );

  const totalAdjustments = useMemo(
    () => transactions.reduce((acc, curr) => acc + Number(curr.adjustment), 0),
    [transactions]
  );

  const transactionSalesColumns = useMemo<MRT_ColumnDef<ILedgerDetailed>[]>(
    () => [
      {
        header: "ID",
        Cell: ({ row }) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"200px"}
            height={"30px"}
            bgcolor={"black"}
            color={theme.palette.primary.main}
            border={1}
            borderRadius={1}
            borderColor={theme.palette.primary.main}
          >
            {row.original.bookingId}
          </Box>
        ),
      },
      {
        header: "Date",
        Cell: ({ row }) => (
          <Typography>{row.original.transactionDate}</Typography>
        ),
      },
      {
        header: "Payment Type",
        accessorKey: "paymentType",
      },
      {
        header: "Guest",
        accessorKey: "guest",
      },
      {
        header: "Platform",
        accessorKey: "bookingPlatform",
      },
      {
        header: "Total",
        Cell: ({ row }) => (
          <Typography>
            {row.original.total
              ? formatToPresentableNumber(row.original.total.toString())
              : "RM 0"}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Transactions:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalTransaction?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Sales",
        Cell: ({ row }) => (
          <Typography>
            {row.original.sales
              ? formatToPresentableNumber(row.original.sales.toString())
              : "RM 0"}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Sales:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalSales?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Tax",
        Cell: ({ row }) => (
          <Typography>
            {row.original.tax
              ? formatToPresentableNumber(row.original.tax.toString())
              : "RM 0"}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Tax:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalTax?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Promotions",
        Cell: ({ row }) => (
          <Typography>
            {row.original.promotion
              ? formatToPresentableNumber(row.original.promotion.toString())
              : "RM 0"}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Promotions:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalPromotions?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Adjustments",
        Cell: ({ row }) => (
          <Typography>
            {row.original.adjustment
              ? formatToPresentableNumber(row.original.adjustment.toString())
              : "RM 0"}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Adjustments:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalAdjustments?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Remarks",
        Cell: ({ row }) => (
          <Typography>
            {row.original.bookingRemarks ? row.original.bookingRemarks : "-"}
          </Typography>
        ),
      },
    ],
    [totalTransaction, totalSales, totalTax, totalPromotions, totalAdjustments]
  );

  const handleChangeReportType = (
    reportType: "sales" | "collections"
  ): void => {
    setReportType(reportType);
  };

  const handleGroupingChange = (grouping: string) => {
    setGrouping(grouping);
    setDynamicGrouping([]);
    setSelectedDynamicGrouping([]);
  };

  const handleChangeDynamicGrouping = (
    event: SelectChangeEvent<typeof selectedDynamicGrouping>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedDynamicGrouping(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleStartDateChange = (startDate: Date) => {
    setStartDate(startDate);
  };

  const handleEndDateChange = (endDate: Date) => {
    endDate.setHours(23, 59, 59, 999);
    setEndDate(endDate);
  };

  const fileNaming = () => {
    if (startDate && endDate && grouping.length > 0) {
      return `${reportType}_${formatDocumentDateFormat(
        startDate
      )}_to_${formatDocumentDateFormat(endDate)}_By${grouping.toString()}`;
    }

    if (grouping.length > 0 && !grouping.includes("transactionId")) {
      return `${reportType}_By${grouping.toString()}`;
    }
    /*By default empty grouping would be grouped up by transaction*/
    if (startDate && endDate) {
      return `${reportType}_${formatDocumentDateFormat(
        startDate
      )}_to_${formatDocumentDateFormat(endDate)}_ByTransactions`;
    }
    if (startDate || endDate) {
      return `${reportType}_${formatDocumentDateFormat(
        startDate
      )}${formatDocumentDateFormat(endDate)}_ByTransactions`;
    }
    if (startDate && endDate) {
      return `${reportType}_${formatDocumentDateFormat(
        startDate
      )}_to_${formatDocumentDateFormat(endDate)}_ByTransactions`;
    }
    if (startDate || endDate) {
      return `${reportType}_${formatDocumentDateFormat(
        startDate
      )}${formatDocumentDateFormat(endDate)}_ByTransactions`;
    }
    return `${reportType}_ByTransactions`;
    /*------------------------------------------------------------- */
  };

  //This is a function to remove headers that are empty and only return that is filled
  function cleanObject(obj: any) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  const handleXLSXDownload = () => {
    const dataToExport = transactions;

    const companyNameHeader = `Company Name: ${currentLot.lotDescription}`;
    const reportNameHeader = `Report Name: ${convertToReadableFormat(
      reportType
    )}`;
    const outletHeader = `Outlet: ${currentLot.lotNumber}`;
    const monthHeader = `Month: ${currentMonthName}`;
    const dateGenerated = `Date Generated: ${format(
      new Date(),
      "dd MMMM yyyy hh:mm"
    )}`;

    let Heading = [
      [companyNameHeader],
      [reportNameHeader],
      [outletHeader],
      [monthHeader],
      [dateGenerated],
    ];

    // const ws = XLSX.utils.json_to_sheet(dataToExport);

    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.sheet_add_json(ws, dataToExport, {
      origin: "A8",
    });

    const borderStyle = {
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };

    ws["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: 7 } },
      { s: { r: 3, c: 0 }, e: { r: 3, c: 7 } },
      { s: { r: 4, c: 0 }, e: { r: 4, c: 7 } },
    ];

    Object.keys(ws).forEach((cell) => {
      if (cell !== "!ref") {
        ws[cell].s = borderStyle;
      }
    });

    ws["A1"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A2"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A3"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A4"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A5"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${fileNaming()}.xlsx`);
  };

  const handleDownloadPDF = () => {
    let ref = transactionDetailedDataRef.current;
    html2canvas(ref)
      .then((canvas) => {
        const contentWidth = canvas.width * 0.8;
        const contentHeight = canvas.height * 0.8;
        const pdf = new jsPDF(contentWidth > contentHeight ? "l" : "p", "mm", [
          contentWidth,
          contentHeight,
        ]);
        pdf.addImage(
          canvas.toDataURL("image/png"),
          "PNG",
          0,
          0,
          contentWidth,
          contentHeight
        ); // Adjust the size as needed
        pdf.save(`${fileNaming()}.pdf`);
      })
      .catch((error) => {
        console.error("Error converting to canvas:", error);
      });
  };

  //File Download Menu Stuffs

  const [fileDownloadAnchorEl, setFileDownloadAnchorEl] =
    useState<null | HTMLElement>(null);
  const openFileDownloadMenu = Boolean(fileDownloadAnchorEl);
  const handleFileDownloadClick = (event: React.MouseEvent<HTMLElement>) => {
    setFileDownloadAnchorEl(event.currentTarget);
  };
  const handleFileDownloadClose = () => {
    setFileDownloadAnchorEl(null);
  };

  return (
    <Box
      display={"flex"}
      bgcolor={theme.palette.background.default}
      maxWidth={"90vw"}
      border={2}
      borderColor={theme.palette.primary.main}
    >
      <Stack direction={"row"} width={"100%"}>
        <Stack
          direction={"column"}
          width={"18%"}
          borderRight={2}
          borderColor={theme.palette.primary.main}
        >
          <Box
            display={"flex"}
            bgcolor={theme.palette.background.default}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
            height={"100px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="h2">Overview</Typography>
          </Box>

          <Stack height={"100%"} spacing={1}>
            {paymentOverview.map((overview) => (
              <Box display={"flex"} flexDirection={"column"} padding={1}>
                <Box>
                  <Typography fontWeight={600}>{overview.name}</Typography>
                  <Box
                    width={"100%"}
                    height={"30px"}
                    border={"2px solid white"}
                    display={"flex"}
                    marginTop={1}
                  >
                    <Box
                      width={"50%"}
                      display={"flex"}
                      alignItems={"center"}
                      sx={{
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        color={"black"}
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        fontWeight={600}
                      >
                        RM {overview.total}
                      </Typography>
                    </Box>
                    <Box
                      width={"50%"}
                      display={"flex"}
                      alignItems={"center"}
                      sx={{
                        backgroundColor: "black",
                      }}
                    >
                      <Typography
                        color={"white"}
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        fontWeight={600}
                      >
                        {overview.count}x
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Stack>
        </Stack>
        <Stack direction={"column"} width={"82%"}>
          <Accordion>
            <AccordionSummary>
              <Box
                display={"flex"}
                width={"100%"}
                height={"100px"}
                justifyContent={"space-between"}
                paddingX={2}
                alignItems={"center"}
                bgcolor={theme.palette.background.default}
                borderBottom={2}
                borderColor={theme.palette.primary.main}
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <FilterAlt sx={{ fontSize: "32px", color: "white" }} />
                  <Typography variant="h3">Filter</Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      fetchTransactions();
                    }}
                    sx={{ borderRadius: 1 }}
                  >
                    <Search />
                  </Button>
                  <Button
                    id="file-download-button"
                    aria-controls={
                      openFileDownloadMenu ? "file-download-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFileDownloadMenu ? "true" : undefined}
                    variant="contained"
                    onClick={handleFileDownloadClick}
                    sx={{ borderRadius: 1 }}
                  >
                    <FileDownload />
                  </Button>
                  <StyledMenu
                    id="file-download-menu"
                    MenuListProps={{
                      "aria-labelledby": "file-download-button",
                    }}
                    anchorEl={fileDownloadAnchorEl}
                    open={openFileDownloadMenu}
                    onClose={handleFileDownloadClose}
                  >
                    <MenuItem onClick={handleDownloadPDF} disableRipple>
                      <PDFIcon
                        width={"35px"}
                        height={"35px"}
                        style={{ marginRight: "10px" }}
                      />
                      PDF
                    </MenuItem>
                    <MenuItem onClick={handleXLSXDownload} disableRipple>
                      <ExcelIcon
                        width={"35px"}
                        height={"35px"}
                        style={{ marginRight: "10px" }}
                      />
                      Xlsx
                    </MenuItem>
                    <CSVLink
                      data={transactions}
                      filename={`${fileNaming()}.csv`}
                    >
                      <MenuItem onClick={handleFileDownloadClose} disableRipple>
                        <ExcelIcon
                          width={"35px"}
                          height={"35px"}
                          style={{ marginRight: "10px" }}
                        />
                        CSV
                      </MenuItem>
                    </CSVLink>
                  </StyledMenu>
                </Stack>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box display={"flex"} flexDirection={"column"}>
                <TransactionFilter
                  anchorEl={anchorEl}
                  reportType={reportType}
                  grouping={grouping}
                  dynamicGrouping={dynamicGrouping}
                  selectedDynamicGrouping={selectedDynamicGrouping}
                  handleChangeReportType={handleChangeReportType}
                  handleCloseDropdown={handleCloseDropdown}
                  handleGroupingChange={handleGroupingChange}
                  handleOpenDropdown={handleOpenDropdown}
                  handleChangeDynamicGrouping={handleChangeDynamicGrouping}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box display={"flex"} alignItems={"center"}>
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(e) => handleStartDateChange(e as Date)}
                      format="dd MMM yyyy"
                      slots={{
                        openPickerIcon: ArrowDropDownIcon,
                      }}
                      slotProps={{
                        layout: {
                          sx: {
                            ".MuiDateCalendar-root": {
                              color: theme.palette.primary.main,
                              borderRadius: 2,
                              borderWidth: 1,
                              borderColor: theme.palette.primary.main,
                              border: "1px solid",
                              backgroundColor: theme.palette.background.paper,
                            },
                            ".MuiButtonBase-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiTypography-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersCalendarHeader-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersMonth-monthButton": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersCalendarHeader-label": {
                              color: theme.palette.primary.main,
                            },
                            ".Mui-selected": {
                              color: "black",
                            },
                            ".MuiPickersYear-yearButton": {
                              color: theme.palette.primary.main,
                            },
                          },
                        },
                        textField: {
                          variant: "filled",
                          inputProps: {
                            style: {
                              color: "white",
                              borderRadius: 1,
                              border: 2,
                            },
                          },
                          sx: {
                            width: "150px",
                            backgroundColor: "#232323",
                            border: "1px solid #148978",
                            borderRadius: 1,
                          },
                        },
                        openPickerButton: {
                          sx: {
                            color: "white",
                            marginTop: "1.8px",
                            zIndex: 1,
                          },
                        },
                        openPickerIcon: {
                          sx: {
                            width: "1em",
                            height: "1em",
                            zIndex: 1,
                          },
                        },
                      }}
                    />
                    <Typography variant="h3" marginX={1}>
                      -
                    </Typography>
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(e) => handleEndDateChange(e as Date)}
                      format="dd MMM yyyy"
                      slots={{
                        openPickerIcon: ArrowDropDownIcon,
                      }}
                      slotProps={{
                        layout: {
                          sx: {
                            ".MuiDateCalendar-root": {
                              color: theme.palette.primary.main,
                              borderRadius: 2,
                              borderWidth: 1,
                              borderColor: theme.palette.primary.main,
                              border: "1px solid",
                              backgroundColor: theme.palette.background.paper,
                            },
                            ".MuiButtonBase-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiTypography-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersCalendarHeader-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersMonth-monthButton": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersCalendarHeader-label": {
                              color: theme.palette.primary.main,
                            },
                            ".Mui-selected": {
                              color: "black",
                            },
                            ".MuiPickersYear-yearButton": {
                              color: theme.palette.primary.main,
                            },
                          },
                        },
                        textField: {
                          variant: "filled",
                          inputProps: {
                            style: {
                              color: "white",
                              borderRadius: 1,
                              border: 2,
                            },
                          },
                          sx: {
                            width: "150px",
                            backgroundColor: "#232323",
                            border: "1px solid #148978",
                            borderRadius: 1,
                          },
                        },
                        openPickerButton: {
                          sx: {
                            color: "white",
                            marginTop: "1.8px",
                            zIndex: 1,
                          },
                        },
                        openPickerIcon: {
                          sx: {
                            width: "1em",
                            height: "1em",
                            zIndex: 1,
                          },
                        },
                      }}
                    />
                  </Box>
                </LocalizationProvider>
              </Box>
            </AccordionDetails>
          </Accordion>
          {isLoading ? (
            <Box
              display={"flex"}
              height={"500px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box height={"100%"} width={"100%"}>
              <>
                <CustomTable
                  key={"transactionSales"}
                  isLoading={isLoading}
                  columns={transactionSalesColumns}
                  data={transactions}
                  handlePaginationChange={setPagination}
                  pagination={pagination}
                  enableTopToolbar={false}
                  oddEvenBackground={true}
                />
                <div
                  ref={transactionDetailedDataRef}
                  style={{
                    position: "absolute",
                    left: "-9999px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box display={"flex"} flexDirection={"column"} padding={2}>
                    <Typography variant="h4" color={"black"}>
                      Company Name: {currentLot.lotDescription}
                    </Typography>
                    <Typography variant="h4" color={"black"}>
                      Report Name: {convertToReadableFormat(reportType)}{" "}
                    </Typography>
                    <Typography variant="h4" color={"black"}>
                      Outlet: {currentLot.lotNumber}
                    </Typography>
                    <Typography variant="h4" color={"black"}>
                      Month: {currentMonthName}
                    </Typography>
                    <Typography variant="h4" color={"black"}>
                      Date Generated: {format(new Date(), "dd MMMM yyyy hh:mm")}
                    </Typography>
                  </Box>
                  <CustomTable
                    key={"transactionPrintTable"}
                    isLoading={isLoading}
                    data={transactions}
                    columns={transactionSalesColumns}
                    enableTopToolbar={false}
                    rowCount={transactions.length}
                    pagination={{
                      pageIndex: 0,
                      pageSize: transactions.length,
                    }}
                    oddEvenBackground={true}
                  />
                </div>
              </>
            </Box>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default Ledgers;

const TransactionFilter = (props: {
  handleOpenDropdown: ({
    currentTarget,
  }: React.MouseEvent<HTMLElement>) => void;
  handleCloseDropdown: VoidFunction;
  anchorEl: HTMLElement | null;
  reportType: string;
  grouping: string;
  dynamicGrouping: IDynamicGrouping[];
  selectedDynamicGrouping: string[];
  handleChangeReportType: (reportType: "sales" | "collections") => void;
  handleGroupingChange: (grouping: string) => void;
  handleChangeDynamicGrouping: (event: SelectChangeEvent<string[]>) => void;
}) => {
  return (
    <Stack direction={"row"} width={"100%"} spacing={2} marginBottom={2}>
      <FormControl>
        <InputLabel variant="filled">Report Type</InputLabel>
        <Select
          variant="filled"
          value={"Report Type"}
          renderValue={(value) =>
            props.reportType ? camelToNormal(props.reportType) : value
          }
          SelectDisplayProps={{
            onClick: props.handleOpenDropdown,
          }}
          MenuProps={{
            keepMounted: true,
            sx: {
              ".MuiMenu-list": {
                color: "black",
              },
            },
          }}
          sx={{
            width: "150px",
            ".MuiSelect-select": {
              border: "1px solid #148978",
            },
            ".MuiSelect-icon": {
              fill: "white",
            },
          }}
        >
          <MenuItem
            value={"Report Type"}
            disabled
            sx={{
              display: "none",
            }}
          >
            Report Type
          </MenuItem>
          <MenuItem>
            <RadioGroup
              name={"report-type"}
              value={props.reportType}
              onChange={({ target: { value } }) =>
                props.handleChangeReportType(value as "sales" | "collections")
              }
            >
              <FormControlLabel
                value="sales"
                control={<Radio />}
                label="Sales"
              />
              <FormControlLabel
                value="collections"
                control={<Radio />}
                label="Collections"
              />
              <FormControlLabel
                value="sales_and_collections"
                control={<Radio />}
                label="Sales and Collection"
              />
            </RadioGroup>
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel variant="filled">Grouping</InputLabel>
        <Select
          variant="filled"
          value={"Grouping"}
          renderValue={(value) =>
            props.grouping ? camelToNormal(props.grouping) : value
          }
          SelectDisplayProps={{
            onClick: props.handleOpenDropdown,
          }}
          MenuProps={{
            keepMounted: true,
            sx: {
              ".MuiMenu-list": {
                color: "black",
              },
            },
          }}
          sx={{
            width: "150px",
            ".MuiSelect-select": {
              border: "1px solid #148978",
            },
            ".MuiSelect-icon": {
              fill: "white",
            },
          }}
        >
          <MenuItem
            value={"Grouping"}
            disabled
            sx={{
              display: "none",
            }}
          >
            Grouping
          </MenuItem>
          <MenuItem>
            <RadioGroup
              name={"grouping"}
              value={props.grouping}
              onChange={({ target: { value } }) =>
                props.handleGroupingChange(value)
              }
            >
              <FormControlLabel
                value={groupings.paymentMethod}
                control={<Radio />}
                label="Payment Method"
              />
              <FormControlLabel
                value={groupings.paymentType}
                control={<Radio />}
                label="Payment Type"
              />
              <FormControlLabel
                value={groupings.bookingPlatform}
                control={<Radio />}
                label="Booking Platform"
              />
              <FormControlLabel
                value={groupings.roomType}
                control={<Radio />}
                label="Room Type"
              />
              <FormControlLabel
                value={groupings.promoCode}
                control={<Radio />}
                label="Promo Code"
              />
            </RadioGroup>
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="filled">
        <InputLabel id="dynamic-grouping">Dynamic Grouping</InputLabel>
        <Select
          labelId="dynamic-grouping"
          id="dynamic-grouping"
          multiple
          value={props.selectedDynamicGrouping}
          onChange={props.handleChangeDynamicGrouping}
          renderValue={(selected) => selected.join(", ")}
          // MenuProps={MenuProps}
          MenuProps={{
            keepMounted: true,
            PaperProps: {
              style: {
                maxHeight: 300,
              },
            },
            sx: {
              ".MuiMenu-list": {
                color: "black",
              },
            },
          }}
          sx={{
            width: "200px",
            ".MuiSelect-select": {
              border: "1px solid #148978",
            },
            ".MuiSelect-icon": {
              fill: "white",
            },
          }}
        >
          {props.dynamicGrouping.map((grouping) => (
            <MenuItem key={grouping.id} value={grouping.name}>
              <Checkbox
                checked={props.selectedDynamicGrouping.includes(grouping.name)}
              />
              <ListItemText primary={grouping.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

const parsePlatformData = (fetchedPlatform: any[]): IPlatform[] => {
  if (fetchedPlatform) {
    return fetchedPlatform.map((item: any) => ({
      platformId: item.platform_id.toString(),
      platform: item.platform,
    }));
  } else {
    return [];
  }
};

function formatNumber(number: string) {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
