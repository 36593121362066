import ArrowLeftRoundedIcon from "@mui/icons-material/ArrowLeftRounded";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import axios from "axios";
import { Form, Formik, FormikErrors } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import theme from "../../assets/theme/theme";
import BASE_API from "../../constants/api";
import axiosInstance from "../../constants/axiosConfig";
import {
  IBooking,
  IBookingDraft,
  IGuestInfo,
} from "../../models/bookings/Booking";
import {
  ICountry,
  IGender,
  IIdType,
} from "../../models/guests/GuestsInterface";
import { store } from "../../redux/store";
import { calculateAge } from "../../utils/functions";
import Notification from "../../utils/notificationConfig";
import PhoneField from "../input/PhoneField";

type Props = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<IBooking>>;
  roomBookings: IBookingDraft[];
  onNewGuestAdded: (guest: IGuestInfo) => void;
  handleSetGuestFormToClose: () => void;
  hanldeNextButtonOnclick: () => void;
  isScanned: boolean;
  scannedData?: IGuestInfo;
};

const NewGuestForm = React.forwardRef(
  (
    {
      setFieldValue,
      handleSetGuestFormToClose,
      isScanned,
      onNewGuestAdded,
      roomBookings,
      hanldeNextButtonOnclick,
      scannedData,
    }: Props,
    hasGottenCustomerStayingIdRef: React.ForwardedRef<boolean>
  ) => {
    const token = store.getState().user.accessToken;
    const TableGridItems = styled(Grid)(() => ({}));
    const TableStyledLabel = styled(Typography)(() => ({
      padding: "0 20px",
    }));

    const [initialValues, setInitialValues] = useState<IGuestInfo>({
      id: "",
      firstName: scannedData ? scannedData.firstName : "",
      lastName: scannedData ? scannedData.lastName : "",
      gender: "",
      idType: "",
      idTypeName: scannedData ? scannedData.idType : "",
      idNumber: scannedData ? scannedData.idNumber : "",
      phone: "",
      email: "",
      country: "",
      countryPrefix: "+60",
    });

    const [idTypes, setIdTypes] = useState<IIdType[]>([]);
    const [genders, setGenders] = useState<IGender[]>([]);
    const [countries, setCountries] = useState<ICountry[]>([]);

    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    useEffect(() => {
      const fetchIdType = () => {
        axiosInstance
          .get(`${BASE_API}/guests/id-type`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setIdTypes(response.data.data);
          });
      };

      const fetchGender = () => {
        axiosInstance
          .get(`${BASE_API}/guests/gender`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setGenders(response.data.data);
          });
      };

      const fetchCountry = () => {
        axiosInstance
          .get(`${BASE_API}/guests/country`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const sortedCountry: ICountry[] = response.data.data
              .sort((prev: ICountry, curr: ICountry) =>
                prev.countryName.localeCompare(curr.countryName)
              )
              .sort((prev: ICountry, curr: ICountry) =>
                prev.favorite === curr.favorite ? 0 : prev.favorite ? -1 : 1
              );
            setCountries(sortedCountry);
          });
      };

      fetchIdType();
      fetchGender();
      fetchCountry();
    }, []);

    useEffect(() => {
      if (scannedData) {
        const scannedGendersId = genders.find(
          (gender) => gender.gender === scannedData.gender.toUpperCase()
        );
        const scannedIdType = idTypes.find(
          (idType) =>
            idType.idType.toUpperCase() === scannedData.idType.toUpperCase()
        );
        const scannedCountry = countries.find(
          (country) =>
            country.countryCode.toUpperCase() ===
            scannedData.country.toUpperCase()
        );
        setInitialValues((prevInitial) => ({
          ...prevInitial,
          idType: scannedIdType ? scannedIdType.idTypeId : "",
          gender: scannedGendersId ? scannedGendersId.genderId : "",
          country: scannedCountry ? scannedCountry.countryId : "",
        }));
      }
    }, [genders, idTypes, countries, scannedData]);

    const assignCustomer = (
      guestId: string,
      guestName: string,
      country: string,
      phone: string,
      email: string
    ) => {
      setFieldValue("bookingInfo.customerStayingId", guestId);
      setFieldValue("bookingInfo.customerStayingName", guestName);
      setFieldValue("bookingInfo.customerStayingCountry", country);
      setFieldValue(`bookingInfo.customerStayingPhone`, phone);
      setFieldValue(`bookingInfo.customerStayingEmail`, email);
      for (let i = 0; i < roomBookings.length; i++) {
        setFieldValue(`roomBookings.${i}.person_in_charge_id`, guestId);
        setFieldValue(`roomBookings.${i}.person_in_charge_name`, guestName);
        setFieldValue(`roomBookings.${i}.person_in_charge_country`, country);
        setFieldValue(`roomBookings.${i}.person_in_charge_phone`, phone);
        setFieldValue(`roomBookings.${i}.person_in_charge_email`, email);
      }
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          adultPax: Yup.number().positive("Number must be positive"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setSubmitLoading(true);
          if (values.idTypeName === "MyKad") {
            const malaysianICRegex = /^\d{6}\d{2}\d{4}$/;

            if (!malaysianICRegex.test(values.idNumber)) {
              setSubmitLoading(false);
              Notification.failed("Please enter a proper Malaysian IC");
              return;
            }
            const year = Number(values.idNumber.substring(0, 2));
            const month = Number(values.idNumber.substring(2, 4)) - 1;
            const day = Number(values.idNumber.substring(4, 6));

            const currentYear = new Date().getFullYear();

            const baseYear = year > currentYear % 100 ? 1900 : 2000;

            const birthDate = new Date(baseYear + year, month, day);

            if (calculateAge(birthDate) < 18) {
              setSubmitLoading(false);
              Notification.failed("Guest assigned is below 18 years old");
              return;
            }
          }

          const formData = new FormData();
          formData.append("firstname", values.firstName.toUpperCase());
          formData.append("lastname", values.lastName.toUpperCase());
          formData.append("gender", values.gender);
          formData.append("idType", values.idType);
          formData.append("idNo", values.idNumber);
          formData.append(
            "phoneNumber",
            values.phone ? values.countryPrefix + " " + values.phone : ""
          );
          formData.append("email", values.email.toUpperCase());
          formData.append("country", values.country);
          formData.append("member", "e365f156-ef76-45fc-97f7-ba9533244679"); // member id for none member

          axios
            .post(`${BASE_API}/guests/`, formData, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              const data = response.data;
              Notification.success(data.message);
              if (
                hasGottenCustomerStayingIdRef &&
                typeof hasGottenCustomerStayingIdRef !== "function" &&
                !hasGottenCustomerStayingIdRef.current
              ) {
                assignCustomer(
                  data.guestid,
                  data.guestName,
                  data.guestCountry,
                  values.phone,
                  values.email
                );

                // setFieldValue("bookingInfo.customerStayingId", data.guestid);
                // setFieldValue(
                //   "bookingInfo.customerStayingName",
                //   data.guestName
                // );
                // setFieldValue(
                //   "bookingInfo.customerStayingCountry",
                //   data.guestCountry
                // );
                // setFieldValue(`bookingInfo.customerStayingPhone`, values.phone);
                // setFieldValue(`bookingInfo.customerStayingEmail`, values.email);
                // for (let i = 0; i < roomBookings.length; i++) {
                //   setFieldValue(
                //     `roomBookings.${i}.person_in_charge_id`,
                //     data.guestid
                //   );
                //   setFieldValue(
                //     `roomBookings.${i}.person_in_charge_name`,
                //     data.guestName
                //   );
                //   setFieldValue(
                //     `roomBookings.${i}.person_in_charge_country`,
                //     data.guestCountry
                //   );
                //   setFieldValue(
                //     `roomBookings.${i}.person_in_charge_phone`,
                //     values.phone
                //   );
                //   setFieldValue(
                //     `roomBookings.${i}.person_in_charge_email`,
                //     values.email
                //   );
                // }
                hasGottenCustomerStayingIdRef.current = true;
                hanldeNextButtonOnclick();
                return;
              }
            })
            .catch((res) => {
              const errorsList = res.response.data;
              for (const errorKey in errorsList) {
                if (errorsList.hasOwnProperty(errorKey)) {
                  Notification.failed(errorsList[errorKey][0]);
                }
              }
            })
            .finally(() => {
              setSubmitLoading(false);
            });
          // .finally(() => {
          //   if (
          //     hasAnIcJustScannedRef &&
          //     typeof hasAnIcJustScannedRef !== "function"
          //   )
          //     hasAnIcJustScannedRef.current = false;
          // });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          values,
          touched,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ marginTop: "30px" }}
              position={"relative"}
            >
              <Stack
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <IconButton
                    onClick={() => handleSetGuestFormToClose()}
                    sx={{
                      paddingLeft: "0px",
                    }}
                  >
                    <ArrowLeftRoundedIcon
                      sx={{
                        color: "white",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                  <Typography variant="h2" sx={{ textAlign: "start" }}>
                    New Guest
                  </Typography>
                </Stack>
                <Grid
                  container
                  sx={{
                    border: 1,
                    borderColor: theme.palette.primary.main,
                    width: "1300px",
                  }}
                  columns={15}
                >
                  <TableGridItems
                    item
                    md={2}
                    sm={2}
                    xs={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TableStyledLabel>First Name</TableStyledLabel>
                  </TableGridItems>
                  <TableGridItems item md={5.5} sm={5.5} xs={5.5}>
                    <TextField
                      size={"small"}
                      value={values.firstName}
                      aria-readonly={true}
                      disabled={isScanned}
                      onChange={({ target: { value } }) =>
                        setFieldValue("firstName", value)
                      }
                      sx={{
                        color: "white",
                        width: "100%",
                        borderLeft: 3,
                        borderRight: 3,
                        borderColor: theme.palette.primary.main,
                      }}
                      inputProps={{
                        style: {
                          borderRadius: 0,
                        },
                      }}
                      InputProps={{
                        endAdornment: isScanned && (
                          <InputAdornment position={"end"}>
                            <DoNotDisturbIcon
                              sx={{
                                color: "white",
                              }}
                            />
                          </InputAdornment>
                        ),
                        sx: {
                          paddingRight: isScanned ? "7px" : undefined,
                        },
                      }}
                    />
                  </TableGridItems>
                  <TableGridItems
                    item
                    md={2}
                    sm={2}
                    xs={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TableStyledLabel>Last Name</TableStyledLabel>
                  </TableGridItems>
                  <TableGridItems item md={5.5} sm={5.5} xs={5.5}>
                    <TextField
                      size={"small"}
                      value={values.lastName}
                      aria-readonly={true}
                      disabled={isScanned}
                      onChange={({ target: { value } }) =>
                        setFieldValue("lastName", value)
                      }
                      sx={{
                        color: "white",
                        width: "100%",
                        borderLeft: 3,
                        borderColor: theme.palette.primary.main,
                      }}
                      inputProps={{
                        style: {
                          borderRadius: 0,
                        },
                      }}
                      InputProps={{
                        endAdornment: isScanned && (
                          <InputAdornment position={"end"}>
                            <DoNotDisturbIcon
                              sx={{
                                color: "white",
                              }}
                            />
                          </InputAdornment>
                        ),
                        sx: {
                          paddingRight: isScanned ? "7px" : undefined,
                        },
                      }}
                    />
                  </TableGridItems>
                </Grid>
                <Grid
                  container
                  sx={{ border: 1, borderColor: theme.palette.primary.main }}
                  columns={15}
                >
                  <TableGridItems
                    item
                    md={2}
                    sm={2}
                    xs={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TableStyledLabel>Gender</TableStyledLabel>
                  </TableGridItems>
                  <TableGridItems item md={3} sm={3} xs={3}>
                    <Select
                      size={"small"}
                      name="gender"
                      value={values.gender}
                      onBlur={handleBlur}
                      disabled={isScanned}
                      sx={{
                        width: "100%",
                        borderLeft: 3,
                        borderRight: 3,
                        borderColor: theme.palette.primary.main,
                      }}
                      onChange={({ target: { value } }) =>
                        setFieldValue("gender", value)
                      }
                    >
                      {genders.map((gender) => (
                        <MenuItem value={gender.genderId}>
                          {gender.gender}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableGridItems>
                  <TableGridItems
                    item
                    md={2}
                    sm={2}
                    xs={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TableStyledLabel>ID Type</TableStyledLabel>
                  </TableGridItems>
                  <TableGridItems item md={3} sm={3} xs={3}>
                    <Select
                      size={"small"}
                      name={"idType"}
                      onBlur={handleBlur}
                      value={values.idType}
                      disabled={isScanned}
                      sx={{
                        width: "100%",
                        borderLeft: 3,
                        borderRight: 3,
                        borderColor: theme.palette.primary.main,
                      }}
                      onChange={({ target: { value } }) => {
                        setFieldValue("idType", value);
                        const selectedIdTypeIndex = idTypes.findIndex(
                          (type) => type.idTypeId === value
                        );
                        setFieldValue(
                          "idTypeName",
                          idTypes[selectedIdTypeIndex].idType
                        );
                      }}
                    >
                      {idTypes.map((idType) => (
                        <MenuItem value={idType.idTypeId}>
                          {idType.idType}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableGridItems>
                  <TableGridItems
                    item
                    md={2}
                    sm={2}
                    xs={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TableStyledLabel>ID Number</TableStyledLabel>
                  </TableGridItems>
                  <TableGridItems item md={3} sm={3} xs={3}>
                    <TextField
                      size={"small"}
                      value={values.idNumber}
                      aria-readonly={true}
                      disabled={isScanned}
                      onChange={({ target: { value } }) =>
                        setFieldValue("idNumber", value)
                      }
                      sx={{
                        color: "white",
                        width: "100%",
                        borderLeft: 3,
                        borderColor: theme.palette.primary.main,
                      }}
                      inputProps={{
                        style: {
                          borderRadius: 0,
                          textTransform: "none",
                        },
                      }}
                      InputProps={{
                        endAdornment: isScanned && (
                          <InputAdornment position={"end"}>
                            <DoNotDisturbIcon
                              sx={{
                                color: "white",
                              }}
                            />
                          </InputAdornment>
                        ),
                        sx: {
                          paddingRight: isScanned ? "7px" : undefined,
                        },
                      }}
                    />
                  </TableGridItems>
                </Grid>
                <Grid
                  container
                  sx={{ border: 1, borderColor: theme.palette.primary.main }}
                  columns={15}
                >
                  <TableGridItems
                    item
                    md={2}
                    sm={2}
                    xs={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TableStyledLabel>Phone</TableStyledLabel>
                  </TableGridItems>
                  <TableGridItems item md={3} sm={3} xs={3}>
                    <Stack width={"100%"} height={"100%"} direction={"row"}>
                      <Autocomplete
                        size="small"
                        disableClearable
                        value={values.countryPrefix || ""}
                        onChange={(event: any, newValue: string | null) => {
                          setFieldValue("countryPrefix", newValue || "");
                        }}
                        forcePopupIcon={false}
                        options={Array.from(
                          new Set(
                            countries.map((country) => "+" + country.prefix)
                          )
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        ListboxProps={{ style: { maxHeight: "200px" } }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                            }}
                            sx={{
                              input: { border: "none" },
                            }}
                          />
                        )}
                        sx={{
                          color: "white",
                          bgcolor: theme.palette.background.default,
                          width: "35%",
                          borderLeft: 3,
                          borderColor: theme.palette.primary.main,
                        }}
                      />
                      <PhoneField
                        value={values.phone}
                        name="phone"
                        setFieldValue={(value) => setFieldValue("phone", value)}
                        inputProps={{ sx: { borderRadius: 0 } }}
                        sx={{
                          color: "white",
                          width: "65%",
                          borderLeft: 3,
                          borderRight: 3,
                          borderColor: theme.palette.primary.main,
                        }}
                      />
                    </Stack>
                  </TableGridItems>
                  <TableGridItems
                    item
                    md={2}
                    sm={2}
                    xs={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TableStyledLabel>Email</TableStyledLabel>
                  </TableGridItems>
                  <TableGridItems item md={3} sm={3} xs={3}>
                    <TextField
                      size={"small"}
                      value={values.email}
                      sx={{
                        color: "white",
                        width: "100%",
                        borderLeft: 3,
                        borderRight: 3,
                        borderColor: theme.palette.primary.main,
                      }}
                      onChange={({ target: { value } }) =>
                        setFieldValue("email", value)
                      }
                    />
                  </TableGridItems>
                  <TableGridItems
                    item
                    md={2}
                    sm={2}
                    xs={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TableStyledLabel>Country</TableStyledLabel>
                  </TableGridItems>
                  <TableGridItems item md={3} sm={3} xs={3}>
                    <Select
                      size={"small"}
                      value={values.country}
                      onChange={({ target: { value } }) => {
                        setFieldValue("country", value);

                        const selectedCountry = countries.find(
                          (country) => country.countryId === value
                        );
                        if (selectedCountry) {
                          setFieldValue(
                            "countryPrefix",
                            "+" + selectedCountry.prefix
                          );
                        }
                      }}
                      sx={{
                        width: "100%",
                        borderLeft: 3,
                        borderRight: 3,
                        borderColor: theme.palette.primary.main,
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                    >
                      {countries.map((country) => (
                        <MenuItem value={country.countryId}>
                          {country.countryName}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableGridItems>
                </Grid>
              </Stack>
              <Button
                type={"submit"}
                sx={{
                  color: "white",
                  width: "12%",
                  height: "60px",
                  backgroundColor: "#232323",
                  border: 1,
                  borderColor: theme.palette.primary.main,
                  borderRadius: 0,
                  position: "absolute",
                  top: "250px",
                }}
                disabled={submitLoading}
              >
                Next
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    );
  }
);

export default NewGuestForm;
