import { Grid, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import GeneralSettings from "../../components/generalsettings/GeneralSettings";
import PaymentSettings from "../../components/generalsettings/PaymentSettings";
import PermissionSettings from "../../components/generalsettings/permissionTab/PermissionSettings";
import BookingSource from "../../components/generalsettings/BookingSource";

function Settings() {
  const theme = useTheme();

  const [tabStepperValue, setTabStepperValue] = useState(0);

  const handleStepperChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabStepperValue(newValue);
  };
  return (
    <Stack width={"90%"}>
      <Typography variant="h1" marginBottom={2}>
        General Settings
      </Typography>
      <Tabs
        value={tabStepperValue}
        onChange={handleStepperChange}
        sx={{
          marginBottom: 5,
          "& .MuiTab-root": {
            minWidth: "29.1vw",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "1.3rem",
            "&.Mui-selected": {
              color: theme.palette.primary.main,
            },
            "&:not(.Mui-selected)": {
              color: "white",
              borderBottom: "1px solid #0a443c",
              
            },
          },
        }}
      >
        <Tab value={0} label={"GENERAL"} />
        <Tab value={1} label={"PAYMENT"} />
        <Tab value={2} label={"PERMISSION"} />

      </Tabs>
      <Grid container width={"100%"}>
        <Grid item md={12} sm={12} xs={12}>
          <Stack direction={"column"} height={"100%"}>
            {tabStepperValue === 0 ? (
              <GeneralSettings />
            ) : tabStepperValue === 1 ? (
              <PaymentSettings />
            ) : tabStepperValue === 2 ? (
              <PermissionSettings />
            ): null}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default Settings;
