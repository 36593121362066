import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
  Stack,
  Avatar,
  useTheme,
  Box,
  ButtonBase,
  Collapse,
  Fade,
  Skeleton,
} from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import LogoutIcon from "@mui/icons-material/Logout";

import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

// Logo
import { ReactComponent as MySVGLogo } from "../../static/images/CapsuleTransitLogo.svg";

// hooks
import useAuthentication from "../../hooks/useAuthentication";

// Constant
import { drawerWidth } from "../../constants/sidebarConfig";

// utils
import { convertToCapitalFirstLetter } from "../../utils/functions";

// redux
import { useSelector } from "react-redux";
import { RootState, store } from "../../redux/store";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import BASE_API from "../../constants/api";
import axiosInstance from "../../constants/axiosConfig";
import { TeamCashInterface } from "../../models/payment/PaymentInterface";
import theme from "../../assets/theme/theme";
import EditOwnAccountModal from "./EditOwnAccountModal";
import { CurrentShift } from "../../models/accounts/ShiftInterface";
import CashierSelectionModal from "../payment/CashierSelectionModal";
import Notification from "../../utils/notificationConfig";
import { PointOfSale } from "@mui/icons-material";

// Types and Interfaces
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
interface HeaderProps {
  open: boolean;
  handleDrawerOpen: () => void;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const currentShiftInitialValue: CurrentShift = {
  endPersonalCashAmount: "",
  endShiftDatetime: "",
  shiftId: "",
  startPersonalCashAmount: "",
  startShiftDatetime: "",
};

export default function Header(props: HeaderProps) {
  const user = useSelector((state: RootState) => state.user);
  const accountId = store.getState().user.accountId;
  const token = store.getState().user.accessToken;
  const [teamCashData, setTeamCashData] = useState<TeamCashInterface[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [checked, setChecked] = React.useState(false);
  const [openEditAccountModal, setOpenEditAccountModal] =
    useState<boolean>(false);
  const [selectedCashierId, setSelectedCashierId] = useState<number>(0);
  const [currentShift, setCurrentShift] = useState<CurrentShift>(
    currentShiftInitialValue
  );
  const [openChangeCashierModal, setOpenChangeCashierModal] =
    useState<boolean>(false);

  const handleCloseChangeCashier = () => {
    setOpenChangeCashierModal(false);
  };

  const handleOpenChangeCashier = () => {
    setOpenChangeCashierModal(true);
  };

  const themes = useTheme();

  const { logout } = useAuthentication();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleOpenEditAccountModal = () => {
    setOpenEditAccountModal(true);
  };

  const handleCloseEditAccountModal = () => {
    setOpenEditAccountModal(false);
  };

  const handleUponSelectCashier = (cashierId: number = 0) => {
    setSelectedCashierId(cashierId);
  };

  const handleChangeCashier = async (): Promise<void> => {
    const apiUrl = "/accounts/shift/change-cashier/";

    const formData = {
      shift_id: currentShift.shiftId,
      new_cashier_terminal_id: selectedCashierId,
    };

    try {
      await axiosInstance.put(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      Notification.success("Cashier Changed");
    } catch (e) {
      return Promise.reject(e); // Reject the promise if an error occurs
    }
  };

  useEffect(() => {
    if (!accountId || accountId === "") return;
    const fetchCurrentShift = () => {
      const apiUrl = `${BASE_API}/accounts/shift/${accountId}/current-shift/`;
      axiosInstance
        .get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const data = res.data.data;
          setCurrentShift({
            shiftId: data.shift_id,
            startShiftDatetime: data.start_shift_datetime,
            endPersonalCashAmount: data.end_personal_cash_amount,
            endShiftDatetime: data.end_shift_datetime,
            startPersonalCashAmount: data.start_personal_cash_amount,
          });
          localStorage.setItem("defaultCashier", data.cashier_name);
        })
        .catch((res) => {});
    };
    fetchCurrentShift();
  }, []);

  useEffect(() => {
    if (!token) return;
    const fetchTeamCash = async () => {
      setLoading(true);
      await axiosInstance
        .get(`${BASE_API}/accounts/shift/cash-amount/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setTeamCashData(response.data.data);
          setLoading(false);
        });
    };
    fetchTeamCash();
  }, [token]);

  return (
    <AppBar position="fixed" open={props.open}>
      <Fade in={!props.open}>
        <Box
          height={"100vh"}
          width={"10px"}
          sx={{
            backgroundColor: themes.palette.primary.main,
            position: "absolute",
          }}
        >
          <ButtonBase
            onClick={props.handleDrawerOpen}
            sx={{ width: "50px", height: "100px", marginTop: "120px" }}
          >
            <Box
              display="flex"
              width={"50px"}
              height={"100px"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                backgroundColor: themes.palette.primary.main,
                position: "absolute",
                clipPath: "polygon(0% 0%,80% 10%,80% 89%,0% 100%)",
              }}
            >
              <KeyboardArrowRight />
            </Box>
          </ButtonBase>
        </Box>
      </Fade>
      <Toolbar
        sx={{
          backgroundColor: themes.palette.background.default, // Set alpha to 0 for full transparency
          overflow: "auto",
        }}
      >
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Box
              sx={{ p: 1, mr: 1 }}
              display={"inline-flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h5" fontWeight={"bold"}>
                Team Cash
              </Typography>
            </Box>

            {loading && (
              <>
                <Box
                  sx={{
                    background: "#2f2f2f",
                    p: 1,
                    mr: 1,
                    width: "100px",
                  }}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Skeleton
                    animation="wave"
                    height={20}
                    width="100%"
                    sx={{ background: theme.palette.primary.light }}
                  />
                </Box>
                <Box
                  sx={{
                    background: "#2f2f2f",
                    p: 1,
                    mr: 1,
                    width: "100px",
                  }}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Skeleton
                    animation="wave"
                    height={20}
                    width="100%"
                    sx={{ background: theme.palette.primary.light }}
                  />
                </Box>
                <Box
                  sx={{
                    background: "#2f2f2f",
                    p: 1,
                    mr: 1,
                    width: "100px",
                  }}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Skeleton
                    animation="wave"
                    height={20}
                    width="100%"
                    sx={{ background: theme.palette.primary.light }}
                  />
                </Box>
              </>
            )}

            <Collapse
              orientation="horizontal"
              in={checked}
              collapsedSize={!loading ? "550px" : "0px"}
            >
              <Box
                sx={{
                  p: 1,
                  mr: 1,
                  width: "max-content",
                }}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                {teamCashData
                  .filter(
                    (data) => Number(data.total_cash).toFixed(2) !== "0.00"
                  )
                  .sort((a, b) => a.staff.name.localeCompare(b.staff.name))
                  .map((item, i) => (
                    <React.Fragment key={i}>
                      <Box
                        sx={{
                          background: "#2f2f2f",
                          p: 1,
                          mr: 1,
                          width: "max-content",
                        }}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={"bold"}
                          sx={{
                            mr: "10px !important",
                            textTransform: "capitalize",
                          }}
                        >
                          {item.staff.name}
                        </Typography>
                        <Typography
                          variant="h5"
                          fontWeight={"bold"}
                          color="yellow"
                        >
                          RM {Number(item.total_cash).toFixed(2)}
                        </Typography>
                      </Box>
                    </React.Fragment>
                  ))}
              </Box>
            </Collapse>
            <IconButton onClick={handleChange}>
              {checked ? (
                <ArrowBackIosNewIcon color="primary" />
              ) : (
                <ArrowForwardIosIcon color="primary" />
              )}
            </IconButton>
          </Stack>

          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {/* <Stack alignItems={"center"} sx={{ marginRight: "15px" }}>
              <NotificationsActiveIcon
                sx={{ fontSize: "1.5rem", color: "white" }}
              />
            </Stack> */}
            <Stack
              alignItems={"center"}
              flexDirection={"row"}
              sx={{ cursor: "pointer" }}
              onClick={handleClick}
            >
              <Avatar
                sx={{
                  marginRight: "10px",
                  width: "2rem",
                  height: "2rem",
                  bgcolor: theme.palette.primary.light,
                  color: "white",
                  borderColor: "white",
                  border: 2,
                }}
              >
                {convertToCapitalFirstLetter(user.username)}
              </Avatar>
              <Typography>{user.username}</Typography>
              <KeyboardArrowDownIcon sx={{ color: "white" }} />
            </Stack>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleOpenEditAccountModal();
                }}
                disableRipple
              >
                <EditIcon
                  sx={{ color: `${theme.palette.primary.main} !important` }}
                />
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleOpenChangeCashier();
                }}
                disableRipple
              >
                <PointOfSale
                  sx={{ color: `${theme.palette.primary.main} !important` }}
                />
                Change Cashier
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  logout();
                }}
                disableRipple
              >
                <LogoutIcon
                  sx={{ color: `${theme.palette.primary.main} !important` }}
                />
                Logout
              </MenuItem>
            </StyledMenu>
          </Stack>
        </Stack>
      </Toolbar>
      <EditOwnAccountModal
        handleClose={handleCloseEditAccountModal}
        open={openEditAccountModal}
      />
      <CashierSelectionModal
        handleUponSelectCashier={handleUponSelectCashier}
        onSubmit={handleChangeCashier}
        open={openChangeCashierModal}
        handleClose={handleCloseChangeCashier}
      />
    </AppBar>
  );
}
