import { Box, Stack, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";


export default function NotFound() {

    const navigate = useNavigate();

    return (
        <Stack sx={{
            backgroundColor: '#101426',
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Typography variant="h1" sx={{ color: 'white', fontSize: '10rem' }}>
                404
            </Typography>
            <Typography sx={{ color: 'white', fontSize: '3rem' }}>
                PAGE NOT FOUND
            </Typography>
            <Box sx={{  marginTop: '6rem' }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        navigate("/")
                    }}
                >Home</Button>
            </Box>
        </Stack>
    )
}