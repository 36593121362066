import { Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import theme from "../../assets/theme/theme";
import { useState } from "react";
interface Setting {
	settingsId: number;
	settingsName: string;
	settingsCategory: string;
	settingsDescription: string;
	creationDatetime: string;
	lot: number;
}
interface TaxChargesProps {
	additionalTaxCategory: Setting[];
	ServiceChargesDesc?: string | undefined;
	onTaxChargesVisibilityChange: (isVisible: boolean) => void;
	onAdditionalTaxVisiblityChange: (isVisible: boolean,settingsId?:number) => void;
	ServiceChargesId?: number;
	ServiceName?: string | undefined;
	SSTDesc?: string | undefined;
	SSTId?: number;
	SSTName?: string | undefined;
}

export default function TaxCharges({
	additionalTaxCategory,
	ServiceChargesDesc,
	onTaxChargesVisibilityChange,
	onAdditionalTaxVisiblityChange,
	ServiceChargesId,
	ServiceName,
	SSTDesc,
	SSTId,
	SSTName,
}: TaxChargesProps) {
	const [TaxCharges, setTaxChargesDetail] = useState(false);
	const [CreateAdditionalTax, setCreateAdditionalTax] = useState(false);

	const handleCreateAdditionalTaxVisibilityChange = (
		settingId?: number
	) => {
		console.log('SettingId:', settingId);
		setCreateAdditionalTax(true);
		onAdditionalTaxVisiblityChange(true,settingId);
	};
	const handleButtonClick = () => {
		setTaxChargesDetail(true);

		onTaxChargesVisibilityChange(true);
	};

	return (
		<>
			<Grid container padding={5} spacing={2}>
				<Grid item xs={10}>
					<Typography variant="h3">Tax & Charges</Typography>
				</Grid>
				<Grid item>
					<Button
						variant="outlined"
						onClick={() => handleCreateAdditionalTaxVisibilityChange(undefined)}
					>
						Create New
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Divider
						orientation="horizontal"
						flexItem
						sx={{
							borderBottomWidth: 3.5,
							borderColor: "#232323",
						}}
					/>
				</Grid>
				<Grid item xs={2} >
					<Typography variant="h4">Compulsory</Typography>
				</Grid>
				<Grid item xs={9}>
					{/* <Typography variant="h4">{wifiName}</Typography> */}
				</Grid>
				<Grid item xs={1} >
					<IconButton onClick={handleButtonClick}>
						<SettingsOutlinedIcon color="primary" />
					</IconButton>
				</Grid>
				<Grid item xs={2}>
					<Typography color={theme.palette.primary.main}>
						Service Charge
					</Typography>
				</Grid>
				<Grid item xs={10}>
					<Typography>{ServiceChargesDesc}</Typography>
				</Grid>
				<Grid item xs={2}>
					<Typography color={theme.palette.primary.main}>SST</Typography>
				</Grid>
				<Grid item xs={10}>
					<Typography>{SSTDesc}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Divider
						orientation="horizontal"
						flexItem
						sx={{
							borderBottomWidth: 3.5,
							borderColor: "#232323",
						}}
					/>
				</Grid>
				<Grid item xs={12} px={2}>
					<Typography variant="h4">Additional Tax</Typography>
				</Grid>
				{additionalTaxCategory.map((additionalCategorySetting) => (
					<Grid
						container
						key={additionalCategorySetting.settingsId}
						py={2}
						paddingLeft={2}
						spacing={2}
					>
						<Grid item xs={2}>
							<Typography color={theme.palette.primary.main}>
								{additionalCategorySetting.settingsName}
							</Typography>
						</Grid>
						<Grid item xs={9}>
							<Typography>
								{additionalCategorySetting.settingsDescription}%
							</Typography>
						</Grid>
						<Grid item xs={1}>
							<IconButton  onClick={() => handleCreateAdditionalTaxVisibilityChange(additionalCategorySetting.settingsId)}>
								<SettingsOutlinedIcon color="primary" />
							</IconButton>
						</Grid>
					</Grid>
				))}
			</Grid>
		</>
	);
}
