import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Modal,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  RoomTypeInterfaceV2,
  ZoneInterface,
} from "../../models/rooms/roomsInterface";
import ManagementModalHeader from "../management/ManagementModalHeader";
import Stack from "@mui/material/Stack";
import { store } from "../../redux/store";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";

type Props = {
  title: string;
  selectedRoomType?: RoomTypeInterfaceV2;
  handleEditRoomTypeModalClose: () => void;
  handleEditRoomTypeModalOpen: boolean;
  setRoomTypes: React.Dispatch<React.SetStateAction<RoomTypeInterfaceV2[]>>;
  archiveFilterValue?: boolean;
};

const EditRoomTypeModal = ({
  title,
  selectedRoomType,
  handleEditRoomTypeModalClose,
  handleEditRoomTypeModalOpen,
  setRoomTypes,
  archiveFilterValue,
}: Props) => {
  const theme = useTheme();
  const token = store.getState().user.accessToken;

  const [currentSection, setCurrentSection] = useState("General");

  const [loading, setLoading] = useState<boolean>(false);
  const [roomTypeName, setRoomTypeName] = useState<string>("");
  const [roomOldTypeName, setOldRoomTypeName] = useState<string>("");
  const [abbreviation, setAbbreviation] = useState<string>("");
  const [roomTypeColor, setRoomTypeColor] = useState<string>("");
  const [maxpax, setMaxpax] = useState<string>("");
  const [isRoomTypeArchived, setIsRoomTypeArchived] = useState<boolean>(true);
  const [selectedZone, setSelectedZone] = useState<string>("");
  const [zoneData, setZoneData] = useState<ZoneInterface[]>([]);

  ///Set Errors
  const [roomTypeNameError, setRoomTypeNameError] = useState<string>("");
  const [abbreviationError, setAbbreviationError] = useState<string>("");
  const [roomTypeColorError, setRoomTypeColorError] = useState<string>("");
  const [maxpaxError, setMaxpaxError] = useState<string>("");
  const [zoneError, setZoneError] = useState<string>("");

  const validateForm = () => {
    let isValid = true;
    if (!roomTypeName.trim()) {
      setRoomTypeNameError("Please enter room type");
      isValid = false;
    } else {
      setRoomTypeNameError("");
    }
    if (!abbreviation.trim()) {
      setAbbreviationError("Please enter abbreviation");
      isValid = false;
    } else {
      setAbbreviationError("");
    }
    if (!roomTypeColor.trim()) {
      setRoomTypeColorError("Please enter color");
      isValid = false;
    } else {
      setRoomTypeColorError("");
    }
    if (!maxpax.trim()) {
      setMaxpaxError("Please enter maxpax");
      isValid = false;
    } else {
      setMaxpaxError("");
    }
    if (!selectedZone.trim()) {
      setZoneError("Please select zone");
      isValid = false;
    } else {
      setZoneError("");
    }
    return isValid;
  };

  useEffect(() => {
    if (selectedRoomType) {
      setRoomTypeName(selectedRoomType.typeName);
      setOldRoomTypeName(selectedRoomType.typeName);
      setAbbreviation(selectedRoomType.RoomTypeDetails);
      setRoomTypeColor(selectedRoomType.colorTags);
      setIsRoomTypeArchived(selectedRoomType.archived);
      setMaxpax(selectedRoomType.maxPax.toString());
    }
  }, [selectedRoomType]);

  const createRoomType = async () => {
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    const payload = {
      typeName: roomTypeName,
      abbreviation: abbreviation,
      colorTag: roomTypeColor,
      maxPax: maxpax,
      zoneId: selectedZone,
    };
    await axiosInstance
      .post(`/rooms/v1/types/`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success("Room Type created successfully");
        setRoomTypes((prevValue) => {
          const selectedRoomZone = zoneData.filter(
            (data) => data.zoneId === selectedZone
          )[0];

          const tempRoomZoneValue = {
            zoneId: selectedRoomZone.zoneId,
            zoneName: selectedRoomZone.zoneName,
            archived: false,
            RoomZoneDetails: "",
            lotId: 0,
          };

          const newRoomType: RoomTypeInterfaceV2 = {
            typeId: "",
            typeName: roomTypeName,
            roomzone: tempRoomZoneValue,
            colorTags: roomTypeColor,
            RoomTypeDetails: abbreviation,
            maxPax: Number(maxpax),
            archived: false,
            roomZoneArray: [tempRoomZoneValue],
          };
          return [newRoomType, ...prevValue];
        });
        handleEditRoomTypeModalClose();
      })
      .catch((error) => {
        Notification.failed(`Error creating room type: ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editRoomType = async () => {
    setLoading(true);
    const payload = {
      typeName: roomOldTypeName,
      newTypeName: roomTypeName,
      abbreviation: abbreviation,
      colorTag: roomTypeColor,
      maxPax: maxpax,
    };
    await axiosInstance
      .put(`/rooms/v1/types/edit-with-name/`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success("Room Type updated successfully");
        setRoomTypes((prevValue) => {
          if (selectedRoomType) {
            const removedRoomType = prevValue.find(
              (roomType) => roomType.typeId === selectedRoomType.typeId
            );

            if (removedRoomType) {
              const newRoomType = prevValue.filter(
                (roomType) => roomType.typeId !== selectedRoomType.typeId
              );

              return [
                {
                  ...selectedRoomType,
                  typeName: roomTypeName,
                  colorTags: roomTypeColor,
                  abbreviation: abbreviation,
                  RoomTypeDetails: abbreviation,
                },
                ...newRoomType,
              ];
            }
          }
          return prevValue;
        });
        handleEditRoomTypeModalClose();
      })
      .catch((error) => {
        Notification.failed(`Error updating room type: ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeRoomTypeStatus = async (status: boolean) => {
    setLoading(true);
    setIsRoomTypeArchived(status);
    const payload = {
      roomType: selectedRoomType?.typeId,
      archive: status,
    };
    await axiosInstance
      .patch(`/rooms/v1/types/archive/ `, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success(response.data.message);
        setRoomTypes((prevValue) => {
          if (selectedRoomType) {
            if (archiveFilterValue === status) {
              const excludedRoomTypes = prevValue.filter(
                (roomType) => roomType.typeId !== selectedRoomType?.typeId
              );
              return excludedRoomTypes;
            } else {
              return [selectedRoomType, ...prevValue];
            }
          } else {
            return prevValue;
          }
        });
      })
      .catch((error) => {
        setIsRoomTypeArchived(false);
        Notification.failed(`Error updating zone: ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchAllZones = async () => {
      await axiosInstance
        .get(`rooms/zone/get-all-zone`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setZoneData(res.data.data);
          }
        });
    };

    fetchAllZones();
  }, [token]);

  return (
    <Modal
      open={handleEditRoomTypeModalOpen}
      onClose={handleEditRoomTypeModalClose}
    >
      <Box
        width={"800px"}
        maxHeight={"100vh"}
        border={`1.5px solid ${theme.palette.primary.main}`}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
          borderRadius: 1,
        }}
      >
        <ManagementModalHeader
          title={title}
          handleModalClose={handleEditRoomTypeModalClose}
        />

        <Grid container xs={12}>
          <Grid item xs={2}>
            <Box
              height={"100%"}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
              bgcolor={"#232323"}
              p={1}
            >
              <Stack direction={"column"} width={"100%"} spacing={2}>
                <Button
                  onClick={() => setCurrentSection("General")}
                  style={{ justifyContent: "flex-start" }}
                  sx={{ width: "100%" }}
                >
                  General
                </Button>
              </Stack>
            </Box>
          </Grid>
          <Grid container xs={10} height={"100%"}>
            {currentSection === "General" ? (
              <>
                <Box padding={2} margin={2} width={"100%"}>
                  <Typography variant="h2" marginBottom={2}>
                    General
                  </Typography>
                  <Stack direction={"column"} spacing={2} width={"100%"}>
                    <Grid container>
                      <Grid item xs={2} mt={1}>
                        <Typography variant="h4">Room Type</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          value={roomTypeName}
                          onChange={(e) => setRoomTypeName(e.target.value)}
                          error={!!roomTypeNameError}
                          helperText={roomTypeNameError}
                          sx={{
                            border: 2,
                            borderColor: theme.palette.primary.main,
                            borderRadius: "5px",
                            marginRight: 2,
                            width: "90%",
                          }}
                          inputProps={{
                            style: { textTransform: "none" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={2} mt={1}>
                        <Typography variant="h4">Abbreviation</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          size="small"
                          error={!!abbreviationError}
                          helperText={abbreviationError}
                          value={abbreviation}
                          onChange={(e) => setAbbreviation(e.target.value)}
                          sx={{
                            border: 2,
                            borderColor: theme.palette.primary.main,
                            borderRadius: "5px",
                            marginRight: 2,
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={2} mt={4}>
                        <Typography variant="h4">Color</Typography>
                      </Grid>
                      <Grid item xs={10} mt={3}>
                        <TextField
                          size="small"
                          error={!!roomTypeColorError}
                          helperText={roomTypeColorError}
                          fullWidth
                          value={roomTypeColor}
                          onChange={(e) => setRoomTypeColor(e.target.value)}
                          sx={{
                            border: 2,
                            borderColor: theme.palette.primary.main,
                            borderRadius: "5px",
                          }}
                        />
                      </Grid>
                      {!selectedRoomType ? (
                        <>
                          <Grid item xs={2} mt={4}>
                            <Typography variant="h4">Zone</Typography>
                          </Grid>
                          <Grid item xs={10} mt={4}>
                            <TextField
                              select
                              error={!!zoneError}
                              helperText={zoneError}
                              fullWidth
                              label="Select Zone"
                              value={selectedZone}
                              onChange={(e) => setSelectedZone(e.target.value)}
                              size="small"
                            >
                              {zoneData.map((e: ZoneInterface, i) => {
                                return (
                                  <MenuItem key={i} value={e.zoneId}>
                                    {e.zoneName}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}

                      <Grid item xs={2} mt={4}>
                        <Typography variant="h4">Max Pax</Typography>
                      </Grid>
                      <Grid item xs={10} mt={3}>
                        <TextField
                          size="small"
                          error={!!maxpaxError}
                          helperText={maxpaxError}
                          fullWidth
                          value={maxpax}
                          onChange={(e) => setMaxpax(e.target.value)}
                          sx={{
                            border: 2,
                            borderColor: theme.palette.primary.main,
                            borderRadius: "5px",
                          }}
                        />
                      </Grid>

                      {selectedRoomType ? (
                        <Stack
                          direction={"row"}
                          spacing={5}
                          alignItems="center"
                          marginTop={2}
                        >
                          <Typography variant="h4" width={"40%"}>
                            Archive
                          </Typography>
                          <Switch
                            checked={isRoomTypeArchived}
                            onChange={({ target: { checked } }) =>
                              changeRoomTypeStatus(checked)
                            }
                          ></Switch>
                        </Stack>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Stack>
                </Box>
              </>
            ) : (
              <>11</>
            )}
          </Grid>
        </Grid>

        <Box
          display={"flex"}
          justifyContent="flex-end"
          alignItems="center"
          width={"100%"}
          border={`1.5px solid ${theme.palette.primary.main}`}
        >
          <Button
            variant="outlined"
            onClick={selectedRoomType ? editRoomType : createRoomType}
            disabled={loading}
          >
            {!loading ? (
              "Save"
            ) : (
              <CircularProgress color="primary" size="20px" />
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditRoomTypeModal;
