import {
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  darken,
} from "@mui/material";
import BookingInfoRooms from "../ganttchart/BookingInfoRooms";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import theme from "../../assets/theme/theme";
import { styled } from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import NewGuestForm from "./NewGuestForm";
import {
  IBooking,
  IBookingDraft,
  IBookingInfo,
  IGuestInfo,
  IStepper,
} from "../../models/bookings/Booking";
import { FormikErrors } from "formik";
import ExisitingCustomerTableModal from "./ExisitingCustomerTableModal";
import axios from "axios";
import BASE_API from "../../constants/api";
import { Delete, KeyboardReturn } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState, store } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import { useDebounce } from "../../hooks/useDebounce";
import { convertScannedDataToIGuestInfo } from "../../utils/convertScannedDatatoIGuestInfo";

import { checkUnderAge } from "../../utils/functions";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import SearchIcon from "@mui/icons-material/Search";

type Props = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<IBooking>>;
  handleChangeStep: (step: IStepper) => void;
  roomBookingArray: IBookingDraft[];
  initialValues: IBookingInfo;
  picError: boolean;
  handleOnSubmit: (values: IBooking) => void;
  isSubmitting: boolean;
};

const itemMarginX = 70;
const itemWidth = 450;

const Carousel = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "activeIndex",
})<{ activeIndex: number; limit: number }>(({ theme, activeIndex, limit }) => ({
  paddingTop: "20px",
  paddingBottom: "20px",
  position: "absolute",
  display: "flex",
  flexDirection: "row",
  width: "fit-content",
  left: `calc(50% - ${itemWidth / 2 + itemMarginX}px - ${
    (activeIndex > limit ? activeIndex - 1 : activeIndex) *
    (itemWidth + 2 * itemMarginX)
  }px)`,
  transition: "left 0.5s ease-in-out",
}));

const PICAssignation = ({
  setFieldValue,
  handleChangeStep,
  roomBookingArray,
  initialValues,
  picError,
  handleOnSubmit,
  isSubmitting,
}: Props) => {
  const token = useSelector((state: RootState) => state.user.accessToken);
  const accountId = useSelector((state: RootState) => state.user.accountId);

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const hanldeNextButtonOnclick = () => {
    setActiveIndex((prevActiveIndex) => prevActiveIndex + 1);
    if (isNewGuestFormOpen) {
      setNewIsGuestForm(false);
    }
  };

  // useEffect(() => {
  //   if (activeIndex > roomBookingArray.length - 1)
  //     handleOnSubmit({
  //       bookingInfo: initialValues,
  //       roomBookings: roomBookingArray,
  //     });
  // }, [activeIndex, roomBookingArray]);

  const [isNewGuestFormOpen, setNewIsGuestForm] = useState<boolean>(false);
  const handleSetGuestFormToOpen = () => {
    setNewIsGuestForm(true);
  };
  const handleSetGuestFormToClose = () => {
    setNewIsGuestForm(false);
  };

  const [isExistingGuestTableOpen, setIsExistingGuestTableOpen] =
    useState<boolean>(false);
  const handleExistingGuestTableOnOpen = () => {
    setIsExistingGuestTableOpen(true);
  };
  const handleExistingGuestTableOnClose = () => {
    setIsExistingGuestTableOpen(false);
  };

  const hasAnIcJustScannedRef = useRef<boolean>(false);
  const hasGottenCustomerStayingIdRef = useRef<boolean>(false);

  const dispatch = store.dispatch;
  const [referchCount, setRefetchCount] = useState<number>(0);
  const [idInfoInput, setidInfoInput] = useState<string>("");
  const debouncedValue = useDebounce(idInfoInput, 500);

  const handleIdInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setidInfoInput(event.target.value);
  };

  const scannedData = convertScannedDataToIGuestInfo(debouncedValue);

  useEffect(() => {
    if (debouncedValue !== "" && scannedData.idNumber !== "") {
      if (
        scannedData.dateOfBirth &&
        checkUnderAge(scannedData.dateOfBirth, "scan")
      ) {
        Notification.failed("Guest assigned is below 18 years old");
        return;
      }

      axios
        .get(
          `${BASE_API}/guests/get_customer_by_id_no/?id_no=${scannedData.idNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (
            hasGottenCustomerStayingIdRef &&
            typeof hasGottenCustomerStayingIdRef !== "function" &&
            !hasGottenCustomerStayingIdRef.current
          ) {
            setFieldValue(
              "bookingInfo.customerStayingId",
              response.data.customerId
            );
            setFieldValue(
              "bookingInfo.customerStayingName",
              response.data.firstname + " " + response.data.lastname
            );
            setFieldValue("bookingInfo.customerStayingIc", response.data.idNo);
            setFieldValue(
              "bookingInfo.customerStayingCountry",
              response.data.country.countryName
            );
            setFieldValue(
              "bookingInfo.customerStayingMemberId",
              response.data.member.memberId
            );
            setFieldValue(
              "bookingInfo.customerStayingPhone",
              response.data.phoneNumber
            );
            setFieldValue(
              "bookingInfo.customerStayingEmail",
              response.data.email
            );
            setFieldValue(
              "bookingInfo.customerStayingMemberTier",
              response.data.member.memberTier
            );
            setFieldValue(
              "bookingInfo.customerStayingMemberCondition",
              response.data.member.memberCondition
            );
            for (let i = 0; i < roomBookingArray.length; i++) {
              setFieldValue(
                `roomBookings.${i}.person_in_charge_id`,
                response.data.customerId
              );
              setFieldValue(
                `roomBookings.${i}.person_in_charge_name`,
                response.data.firstname + " " + response.data.lastname
              );
              setFieldValue(
                `roomBookings.${i}.person_in_charge_ic`,
                response.data.idNo
              );
              setFieldValue(
                `roomBookings.${i}.person_in_charge_phone`,
                response.data.phoneNumber
              );
              setFieldValue(
                `roomBookings.${i}.person_in_charge_email`,
                response.data.email
              );
              setFieldValue(
                `roomBookings.${i}.person_in_charge_country`,
                response.data.country.countryName
              );
              setFieldValue(
                `roomBookings.${i}.member_id`,
                response.data.member.memberId
              );
              setFieldValue(
                `roomBookings.${i}.member_tier`,
                response.data.member.memberTier
              );
              setFieldValue(
                `roomBookings.${i}.member_condition`,
                response.data.member.memberCondition
              );
            }
            hasGottenCustomerStayingIdRef.current = true;
            Notification.success("Guest Found");
            hanldeNextButtonOnclick();
            return;
          }
          setFieldValue(
            `roomBookings.${activeIndex}.person_in_charge_id`,
            response.data.customerId
          );
          setFieldValue(
            `roomBookings.${activeIndex}.person_in_charge_name`,
            response.data.firstname + " " + response.data.lastname
          );
          setFieldValue(
            `roomBookings.${activeIndex}.person_in_charge_ic`,
            response.data.idNo
          );
          setFieldValue(
            `roomBookings.${activeIndex}.person_in_charge_country`,
            response.data.country.countryName
          );
          setFieldValue(
            `roomBookings.${activeIndex}.member_id`,
            response.data.member.memberId
          );
          setFieldValue(
            `roomBookings.${activeIndex}.member_tier`,
            response.data.member.memberTier
          );
          setFieldValue(
            `roomBookings.${activeIndex}.member_condition`,
            response.data.member.memberCondition
          );
          Notification.success("Guest Found");
          hanldeNextButtonOnclick();
        })
        .catch((err) => {
          console.log(err);
          // Notification.warning(err.response.data.error);
          Notification.warning("Guest Not Found, Please Add New Guest");
          handleSetGuestFormToOpen();
        });
    }
  }, [scannedData.idNumber]);
  /*
   * *This function should be called after scanning IC
   */
  const getCustomerStayingId = async () => {
    if (hasGottenCustomerStayingIdRef.current)
      await axios
        .get(`${BASE_API}/guests/`, {
          params: {
            customer_staying_ic: initialValues.customerStayingIc,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => {
          const { customerId, firstname, lastname, idNo } = data;
          setFieldValue("bookingInfo.customer_staying_id", customerId);
          setFieldValue(
            "bookingInfo.customer_staying_name",
            firstname + " " + lastname
          );
          setFieldValue("bookingInfo.customer_staying_ic", idNo);
          for (let i = 0; i < roomBookingArray.length; i++) {
            setFieldValue(`roomBookings.${i}.person_in_charge_id`, customerId);
            setFieldValue(
              `roomBookings.${i}.person_in_charge_name`,
              firstname + " " + lastname
            );
            setFieldValue(`roomBookings.${i}.person_in_charge_ic`, idNo);
          }
          hasGottenCustomerStayingIdRef.current = true;
        });
  };

  // const handleGetPIN = () => {
  //   const apiUrl = `/bookings/v1/consent/get-pin/?accountId=${accountId}`;

  //   axiosInstance
  //     .get(apiUrl, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       setFieldValue("bookingInfo.pin", response.data.pin);
  //       Notification.success("PIN Successfully Retrieved");
  //     });
  // };

  const handleDeletePIC = (picID: string) => {
    const indices: number[] = roomBookingArray.reduce(
      (acc: number[], roomBooking, index) => {
        if (roomBooking.person_in_charge_id === picID) {
          acc.push(index);
        }
        return acc;
      },
      []
    );

    indices.forEach((indice) => {
      setFieldValue(
        `roomBookings.${indice}.person_in_charge_id`,
        initialValues.customerStayingId
      );
      setFieldValue(
        `roomBookings.${indice}.person_in_charge_name`,
        initialValues.customerStayingName
      );
      setFieldValue(
        `roomBookings.${indice}.person_in_charge_ic`,
        initialValues.customerStayingIc
      );
      setFieldValue(
        `roomBookings.${indice}.person_in_charge_phone`,
        initialValues.customerStayingPhone
      );
      setFieldValue(
        `roomBookings.${indice}.person_in_charge_email`,
        initialValues.customerStayingEmail
      );
      setFieldValue(
        `roomBookings.${indice}.person_in_charge_country`,
        initialValues.customerStayingCountry
      );
      setFieldValue(
        `roomBookings.${indice}.member_id`,
        initialValues.customerStayingMemberId
      );
      setFieldValue(
        `roomBookings.${indice}.member_tier`,
        initialValues.customerStayingMemberTier
      );
      setFieldValue(
        `roomBookings.${indice}.member_condition`,
        initialValues.customerStayingMemberCondition
      );
    });
    setActiveIndex(indices[0]);
  };

  const handleDeleteCustomerStaying = () => {
    const indices: number[] = roomBookingArray.reduce(
      (acc: number[], roomBooking, index) => {
        if (
          roomBooking.person_in_charge_id === initialValues.customerStayingId
        ) {
          acc.push(index);
        }
        return acc;
      },
      []
    );

    const personInCharges = roomBookingArray.filter(
      (roomBooking) =>
        roomBooking.person_in_charge_id !== initialValues.customerStayingId
    );

    if (personInCharges.length > 0) {
      setFieldValue(
        "bookingInfo.customerStayingId",
        personInCharges[0].person_in_charge_id
      );
      setFieldValue(
        "bookingInfo.customerStayingName",
        personInCharges[0].person_in_charge_name
      );
      setFieldValue(
        "bookingInfo.customerStayingIc",
        personInCharges[0].person_in_charge_ic
      );
      setFieldValue(
        "bookingInfo.customerStayingPhone",
        personInCharges[0].person_in_charge_phone
      );
      setFieldValue(
        "bookingInfo.customerStayingEmail",
        personInCharges[0].person_in_charge_email
      );
      setFieldValue(
        "bookingInfo.customerStayingMemberId",
        personInCharges[0].member_id
      );
      setFieldValue(
        "bookingInfo.customerStayingMemberTier",
        personInCharges[0].member_tier
      );
      setFieldValue(
        "bookingInfo.customerStayingMemberCondition",
        personInCharges[0].member_condition
      );
      setFieldValue(
        "bookingInfo.customerStayingCountry",
        personInCharges[0].person_in_charge_country
      );
      indices.forEach((indice) => {
        setFieldValue(
          `roomBookings.${indice}.person_in_charge_id`,
          personInCharges[0].person_in_charge_id
        );
        setFieldValue(
          `roomBookings.${indice}.person_in_charge_name`,
          personInCharges[0].person_in_charge_name
        );
        setFieldValue(
          `roomBookings.${indice}.person_in_charge_ic`,
          personInCharges[0].person_in_charge_ic
        );
        setFieldValue(
          `roomBookings.${indice}.person_in_charge_phone`,
          personInCharges[0].person_in_charge_phone
        );
        setFieldValue(
          `roomBookings.${indice}.person_in_charge_email`,
          personInCharges[0].person_in_charge_email
        );
        setFieldValue(
          `roomBookings.${indice}.person_in_charge_country`,
          personInCharges[0].person_in_charge_country
        );
        setFieldValue(
          `roomBookings.${indice}.member_id`,
          personInCharges[0].member_id
        );
        setFieldValue(
          `roomBookings.${indice}.member_tier`,
          personInCharges[0].member_tier
        );
        setFieldValue(
          `roomBookings.${indice}.member_condition`,
          personInCharges[0].member_condition
        );
      });
    } else {
      setFieldValue("bookingInfo.customerStayingId", "");
      setFieldValue("bookingInfo.customerStayingName", "");
      setFieldValue("bookingInfo.customerStayingIc", "");
      setFieldValue("bookingInfo.customerStayingPhone", "");
      setFieldValue("bookingInfo.customerStayingEmail", "");
      setFieldValue("bookingInfo.customerStayingMemberId", "");
      setFieldValue("bookingInfo.customerStayingMemberTier", "");
      setFieldValue("bookingInfo.customerStayingMemberCondition", "");
      setFieldValue("bookingInfo.customerStayingCountry", "");
      indices.forEach((indice) => {
        setFieldValue(`roomBookings.${indice}.person_in_charge_id`, "");
        setFieldValue(`roomBookings.${indice}.person_in_charge_name`, "");
        setFieldValue(`roomBookings.${indice}.person_in_charge_ic`, "");
      });
      hasGottenCustomerStayingIdRef.current = false;
    }

    setActiveIndex(indices[0]);
  };

  return (
    <>
      <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
        <Button
          variant="outlined"
          onClick={() => handleChangeStep({ step: 1, text: "PIC" })}
          sx={{ marginX: "10%", marginTop: 1 }}
        >
          <KeyboardReturn /> Back
        </Button>
        {/* {activePIN ? (
          <Box
            display={"flex"}
            border={1}
            justifyContent={"center"}
            alignItems={"center"}
            borderColor={theme.palette.secondary.main}
            bgcolor={theme.palette.background.default}
            sx={{ marginX: "10%", marginTop: 1, paddingX: 1 }}
          >
            <Typography variant="h4" marginRight={1}>
              PIN :
            </Typography>
            {activePIN
              .split("")
              .map((value, index) =>
                index !== activePIN.length - 1 ? (
                  <Typography variant="h4"> {value}.</Typography>
                ) : (
                  <Typography variant="h4">{value}</Typography>
                )
              )}
          </Box>
        ) : (
          <Button
            variant="outlined"
            onClick={handleGetPIN}
            sx={{ marginX: "10%", marginTop: 1 }}
          >
            Generate PIN
          </Button>
        )} */}
      </Stack>
      <Box
        color={"white"}
        marginTop={"30px"}
        paddingX={"10%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Stack alignItems={"center"} direction={"column"} width={"100%"}>
          <Typography variant={"h2"} width={"100%"} justifyContent={"start"}>
            Add Guest
          </Typography>
          <Typography
            variant={"h5"}
            width={"100%"}
            justifyContent={"start"}
            mb={2}
          >
            Assign a person in charge to the highlighted room
          </Typography>
          <Box
            border={"solid"}
            borderColor={theme.palette.primary.main}
            height={"180px"}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            position={"relative"}
            overflow={"hidden"}
          >
            <Carousel
              activeIndex={activeIndex}
              limit={roomBookingArray.length - 1}
            >
              {roomBookingArray.map((roomBooking, index: number) => (
                <ButtonBase
                  key={index}
                  onClick={() => setActiveIndex(index)}
                  sx={{
                    opacity: activeIndex === index ? 1 : 0.5,
                    width: `${itemWidth}px`,
                    marginX: `${itemMarginX}px`,
                  }}
                >
                  <BookingInfoRooms
                    roomBookings={roomBooking}
                    customerStayingName={initialValues.customerStayingName}
                  />
                </ButtonBase>
              ))}
            </Carousel>
          </Box>
          <Grid
            item
            md={12}
            sx={{
              borderLeftStyle: "solid",
              borderLeftColor: theme.palette.primary.main,
              borderLeftWidth: "3px",
              borderRightStyle: "solid",
              borderRightColor: theme.palette.primary.main,
              borderRightWidth: "3px",
              width: "100%",
            }}
          >
            {!isNewGuestFormOpen && (
              <>
                <Box
                  justifyContent={"space-between"}
                  flexDirection="row"
                  alignItems="center"
                  width={"100%"}
                >
                  <Stack
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"end"}
                    direction={"row"}
                    height={!initialValues.customerStayingId ? "50px" : "50px"}
                    marginBottom={1}
                    paddingX={2.5}
                  >
                    <Stack direction={"row"}>
                      <RssFeedIcon
                        sx={{
                          fontSize: !initialValues.customerStayingId
                            ? "30px"
                            : "30px",
                          marginRight: "5px",
                        }}
                      />
                      <Typography
                        variant={!initialValues.customerStayingId ? "h4" : "h4"}
                        mt={0.85}
                      >
                        Scan IC / Passport to assign a main Guest of this room
                      </Typography>
                    </Stack>

                    <ButtonGroup variant="outlined">
                      <Button
                        startIcon={<PersonAddAlt1Icon />}
                        type={"button"}
                        onClick={() => handleSetGuestFormToOpen()}
                        // disabled={
                        //   activeIndex === roomBookingCount ? true : false
                        // }
                        disabled={activeIndex === roomBookingArray.length}
                        sx={{
                          color: "white",
                          border: `solid 2px ${theme.palette.primary.main}`,
                          borderRadius: 2,
                          width: "175px",
                          "&:disabled": {
                            backgroundColor: darken("#fff", 0.8),
                            color: darken("#fff", 0.3),
                            border: `solid 1px ${theme.palette.primary.main}`,
                            cursor: "not-allowed",
                          },
                        }}
                      >
                        New Guest
                      </Button>
                      <Button
                        startIcon={<SearchIcon />}
                        type={"button"}
                        onClick={() => handleExistingGuestTableOnOpen()}
                        // disabled={
                        //   activeIndex === roomBookingCount ? true : false
                        // }
                        disabled={activeIndex === roomBookingArray.length}
                        sx={{
                          color: "white",
                          border: `solid 2px ${theme.palette.primary.main}`,
                          borderRadius: 2,
                          width: "175px",
                          "&:disabled": {
                            backgroundColor: darken("#fff", 0.8),
                            color: darken("#fff", 0.3),
                            border: `solid 1px ${theme.palette.primary.main}`,
                            cursor: "not-allowed",
                          },
                        }}
                      >
                        Search Guest
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </Box>
              </>
            )}
          </Grid>
        </Stack>
        <TextField
          multiline
          id={""}
          rows={8}
          autoFocus
          value={idInfoInput}
          onChange={handleIdInputChange}
          fullWidth
          sx={{
            border: "1px solid grey",
            "& .MuiInputBase-root": {
              padding: 0,
            },
            "& .MuiOutlinedInput-root": {
              padding: 0,
            },
          }}
          inputProps={{
            style: { padding: "10px" },
          }}
          placeholder="Please do not type anything here"
        />
        {!isNewGuestFormOpen && initialValues.customerStayingId && (
          <Stack
            justifyContent={"center"}
            alignItems={"start"}
            direction={"column"}
            width={"1150px"}
            height={"225px"}
            mt={"30px"}
          >
            <Typography variant={"h3"} mb={"30px"}>
              Guest List
            </Typography>
            <Box
              sx={{
                borderStyle: "solid",
                borderColor: theme.palette.primary.main,
                borderWidth: "3px",
                width: "inherit",
                height: "100%",
                paddingY: "20px",
                display: "flex",
                justifyContent: "start",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  height: "5px",
                },
              }}
            >
              {initialValues.customerStayingId && (
                <Stack
                  direction={"row"}
                  sx={{
                    borderStyle: "solid",
                    borderColor: theme.palette.primary.main,
                    borderWidth: "3px",
                    width: "350px",
                    height: "100%",
                    marginX: "20px",
                  }}
                >
                  <Box
                    sx={{
                      borderRightStyle: "solid",
                      borderColor: theme.palette.primary.main,
                      borderWidth: "3px",
                      height: "100%",
                      aspectRatio: "1/1",
                    }}
                  ></Box>
                  <Stack
                    justifyContent={"space-between"}
                    alignItems={"start"}
                    direction={"column"}
                    height={"100%"}
                    width={"350px"}
                    padding={"10px"}
                  >
                    <Stack
                      width={"100%"}
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography variant="h4">
                        {initialValues.customerStayingCountry}
                      </Typography>
                      <IconButton
                        onClick={handleDeleteCustomerStaying}
                        style={{ padding: 0 }}
                      >
                        <Delete fontSize="small" color="error" />
                      </IconButton>
                    </Stack>
                    <Typography variant={"h3"}>
                      {initialValues.customerStayingName}
                    </Typography>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      width={"100%"}
                    >
                      {/* {initialValues.customerStayingMemberTier &&
                      initialValues.customerStayingMemberTier !== "None" ? (
                        <Typography
                          fontSize={"14px"}
                          fontWeight={"bold"}
                          color={"primary"}
                        >
                          Membership
                        </Typography>
                      ) : (
                        <Typography
                          fontSize={"14px"}
                          color={"error"}
                          fontWeight={"bold"}
                        >
                          Incomplete Profile
                        </Typography>
                      )} */}
                      {!initialValues.customerStayingPhone ||
                      !initialValues.customerStayingEmail ? (
                        <Typography
                          fontSize="14px"
                          color="error"
                          fontWeight="bold"
                        >
                          Incomplete Profile
                        </Typography>
                      ) : initialValues.customerStayingMemberTier === "None" ? (
                        <Typography
                          fontSize="14px"
                          color="error"
                          fontWeight="bold"
                        >
                          None Member
                        </Typography>
                      ) : (
                        <Typography
                          fontSize="14px"
                          fontWeight="bold"
                          color="primary"
                        >
                          Membership
                        </Typography>
                      )}
                      <Typography>
                        {initialValues.customerStayingMemberCondition !== "None"
                          ? initialValues.customerStayingMemberTier
                          : "--"}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      width={"100%"}
                    >
                      <Typography
                        fontSize="14px"
                        fontWeight="bold"
                        color="white"
                      >
                        Retention Count
                      </Typography>
                      <Typography
                        fontSize="18px"
                        fontWeight="bold"
                        color="primary"
                      >
                        {initialValues.rententionCount}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              )}
              {initialValues.customerStayingId &&
                roomBookingArray.some(
                  (roomBooking) => roomBooking.person_in_charge_id
                ) &&
                roomBookingArray
                  .filter(
                    //to make sure that pic and customer staying name only appear once
                    (roomBooking, _, array) =>
                      array.some(
                        ({ person_in_charge_id }) =>
                          person_in_charge_id !==
                          roomBooking.person_in_charge_id
                      ) &&
                      roomBooking.person_in_charge_id !==
                        initialValues.customerStayingId
                  )
                  .map(
                    ({
                      person_in_charge_id,
                      person_in_charge_name,
                      member_condition,
                      member_tier,
                      person_in_charge_country,
                      person_in_charge_email,
                      person_in_charge_phone,
                      rentention_count,
                    }) => (
                      <Stack
                        key={person_in_charge_id}
                        direction={"row"}
                        sx={{
                          borderStyle: "solid",
                          borderColor: theme.palette.primary.main,
                          borderWidth: "3px",
                          width: "350px",
                          height: "100%",
                          marginX: "20px",
                        }}
                      >
                        <Box
                          sx={{
                            borderRightStyle: "solid",
                            borderColor: theme.palette.primary.main,
                            borderWidth: "3px",
                            height: "100%",
                            aspectRatio: "1/1",
                          }}
                        ></Box>
                        <Stack
                          justifyContent={"space-between"}
                          alignItems={"start"}
                          direction={"column"}
                          height={"100%"}
                          width={"350px"}
                          padding={"10px"}
                        >
                          <Stack
                            width={"100%"}
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Typography variant="h4">
                              {person_in_charge_country}
                            </Typography>
                            <IconButton
                              onClick={() =>
                                handleDeletePIC(person_in_charge_id)
                              }
                              style={{ padding: 0 }}
                            >
                              <Delete fontSize="small" color="error" />
                            </IconButton>
                          </Stack>
                          <Typography variant={"h3"}>
                            {person_in_charge_name}
                          </Typography>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            width={"100%"}
                          >
                            {!person_in_charge_phone ||
                            !person_in_charge_email ? (
                              <Typography
                                fontSize="14px"
                                color="error"
                                fontWeight="bold"
                              >
                                Incomplete Profile
                              </Typography>
                            ) : member_tier === "None" ? (
                              <Typography
                                fontSize="14px"
                                color="error"
                                fontWeight="bold"
                              >
                                None Member
                              </Typography>
                            ) : (
                              <Typography
                                fontSize="14px"
                                fontWeight="bold"
                                color="primary"
                              >
                                Membership
                              </Typography>
                            )}
                            <Typography>
                              {member_condition && member_condition !== "None"
                                ? member_tier
                                : "--"}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            width={"100%"}
                          >
                            <Typography
                              fontSize="14px"
                              fontWeight="bold"
                              color="white"
                            >
                              Retention Count
                            </Typography>
                            <Typography
                              fontSize="18px"
                              fontWeight="bold"
                              color="primary"
                            >
                              {rentention_count}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    )
                  )}
            </Box>
            {picError && (
              <Typography>
                Customer staying must be Guest of at least one room
              </Typography>
            )}
          </Stack>
        )}

        <Button
          variant="outlined"
          onClick={() => {
            handleOnSubmit({
              bookingInfo: initialValues,
              roomBookings: roomBookingArray,
            });
          }}
          disabled={isSubmitting}
          sx={{
            width: "12%",
            marginTop: 3,
            height: "60px",
            backgroundColor: "#232323",
            border: 1,
            borderColor: theme.palette.primary.main,
            borderRadius: 0,
          }}
        >
          {isSubmitting ? <CircularProgress /> : "Submit"}
        </Button>
        {/* {!isNewGuestFormOpen && (
          <>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width={"100%"}
            >
              <Stack
                justifyContent={"center"}
                alignItems={"end"}
                direction={"row"}
                height={!initialValues.customerStayingId ? "80px" : "50px"}
                marginBottom={2}
              >
                <RssFeedIcon
                  sx={{
                    fontSize: !initialValues.customerStayingId
                      ? "50px"
                      : "30px",
                    marginRight: "10px",
                  }}
                />
                <Typography
                  variant={!initialValues.customerStayingId ? "h2" : "h3"}
                >
                  Scan IC / Passport to assign Guest of this room
                </Typography>
              </Stack>
              <TextField
                multiline
                id={""}
                rows={8}
                autoFocus
                value={idInfoInput}
                onChange={handleIdInputChange}
                fullWidth
                sx={{
                  border: "1px solid grey",
                  "& .MuiInputBase-root": {
                    padding: 0,
                  },
                  "& .MuiOutlinedInput-root": {
                    padding: 0,
                  },
                }}
                inputProps={{
                  style: { padding: "10px" },
                }}
                placeholder="Please do not type anything here"
              />
            </Box>

            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              marginTop={"30px"}
            >
              <Typography variant={"h4"} fontWeight={400} marginBottom={"10px"}>
                Manual Operation
              </Typography>
              <Stack
                justifyContent={"space-between"}
                alignItems={"center"}
                direction={"row"}
              >
                <Button
                  type={"button"}
                  onClick={() => handleSetGuestFormToOpen()}
                  disabled={activeIndex === roomBookingArray.length}
                  sx={{
                    color: "white",
                    border: `solid 3px ${theme.palette.primary.main}`,
                    width: "200px",
                    marginRight: "10px",
                    "&:disabled": {
                      backgroundColor: darken("#fff", 0.8),
                      color: darken("#fff", 0.3),
                      border: `solid 2px ${theme.palette.primary.main}`,
                      cursor: "not-allowed",
                    },
                  }}
                >
                  Add New Guest
                </Button>
                <Button
                  type={"button"}
                  onClick={() => handleExistingGuestTableOnOpen()}
                  disabled={activeIndex === roomBookingArray.length}
                  sx={{
                    color: "white",
                    border: `solid 3px ${theme.palette.primary.main}`,
                    width: "200px",
                    marginLeft: "10px",
                    "&:disabled": {
                      backgroundColor: darken("#fff", 0.8),
                      color: darken("#fff", 0.3),
                      border: `solid 2px ${theme.palette.primary.main}`,
                      cursor: "not-allowed",
                    },
                  }}
                >
                  Search Previous Guest
                </Button>
              </Stack>
            </Stack>
            <Button
              variant="outlined"
              onClick={() => {
                handleOnSubmit({
                  bookingInfo: initialValues,
                  roomBookings: roomBookingArray,
                });
              }}
              sx={{
                width: "12%",
                marginTop: 3,
                height: "60px",
                backgroundColor: "#232323",
                border: 1,
                borderColor: theme.palette.primary.main,
                borderRadius: 0,
              }}
            >
              {isSubmitting ? <CircularProgress /> : "Submit"}
            </Button>
          </>
        )} */}
        {isNewGuestFormOpen && (
          <NewGuestForm
            ref={hasGottenCustomerStayingIdRef}
            setFieldValue={setFieldValue}
            onNewGuestAdded={(guest: IGuestInfo) => {
              setRefetchCount((prev) => prev + 1);
            }}
            handleSetGuestFormToClose={handleSetGuestFormToClose}
            hanldeNextButtonOnclick={hanldeNextButtonOnclick}
            isScanned={hasAnIcJustScannedRef.current}
            roomBookings={roomBookingArray}
            scannedData={scannedData}
          />
        )}
      </Box>
      {
        <ExisitingCustomerTableModal
          ref={hasGottenCustomerStayingIdRef}
          isExistingGuestTableOpen={isExistingGuestTableOpen}
          handleSetExistingGuestTableToClose={handleExistingGuestTableOnClose}
          setFieldValue={setFieldValue}
          refetchCount={referchCount}
          activeIndex={activeIndex}
          roomBookings={roomBookingArray}
          hanldeNextButtonOnclick={hanldeNextButtonOnclick}
        />
      }
    </>
  );
};

export default PICAssignation;
