import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import axiosInstance from "../../../constants/axiosConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import Notification from "../../../utils/notificationConfig";
import { ShiftSettings } from "../../../models/accounts/ShiftInterface";
import { Settings } from "@mui/icons-material";
import AddOrEditShift from "./AddOrEditshift";

const initialShiftData: ShiftSettings = {
  endHour: "",
  shiftName: "",
  shiftSettingsId: "",
  startHour: "",
  isArchived: false,
};

const ShiftTab = () => {
  const theme = useTheme();
  const user = useSelector((state: RootState) => state.user);
  const config = {
    headers: { Authorization: `Bearer ${user.accessToken}` },
  };
  const [isAddEditShiftOpen, setIsAddEditShiftOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [shifts, setShifts] = useState<ShiftSettings[]>([]);
  const [selectedShift, setSelectedShift] =
    useState<ShiftSettings>(initialShiftData);

  const getPageData = () => {
    setIsLoading(true);
    axiosInstance
      .get("/accounts/shift-settings", config)
      .then((res) => {
        if (res.status === 200) {
          setShifts(
            res.data.data.map((data: any) => ({
              ...data,
              shiftSettingsId: data.shiftsettingsId,
            }))
          );
          return;
        }
      })
      .catch((e) => {
        Notification.failed(e.response.data.msg);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPageData();
  }, []);

  const handleAddEditShiftOpen = (shift?: ShiftSettings) => {
    setIsAddEditShiftOpen(true);
    if (shift) setSelectedShift(shift);
  };
  const handleAddEditShiftClose = () => {
    setIsAddEditShiftOpen(false);
    setSelectedShift(initialShiftData);
  };

  return (
    <Box padding={5}>
      {!isAddEditShiftOpen ? (
        <>
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"space-between"}
            marginBottom={1}
          >
            <Typography variant="h2">Shifts</Typography>
            <Button
              variant={"contained"}
              onClick={() => handleAddEditShiftOpen()}
              sx={{
                width: "150px",
                backgroundColor: theme.palette.background.paper,
                color: "white",
                border: 1,
                borderColor: theme.palette.primary.main,
              }}
            >
              Create New
            </Button>
          </Stack>
          <Stack direction={"column"} spacing={1}>
            {shifts.map((shift) => (
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
                bgcolor={theme.palette.background.default}
                padding={2}
              >
                <Stack direction={"column"}>
                  <Typography
                    variant="h4"
                    color={shift.isArchived ? "grey" : "white"}
                  >
                    {shift.shiftName} SHIFT
                  </Typography>
                  <Typography
                    variant="h5"
                    color={shift.isArchived ? "grey" : "white"}
                  >
                    {shift.startHour} : {shift.endHour}
                  </Typography>
                </Stack>
                <IconButton onClick={() => handleAddEditShiftOpen(shift)}>
                  <Settings color="primary" />
                </IconButton>
              </Box>
            ))}
          </Stack>
        </>
      ) : (
        <AddOrEditShift
          handleBack={handleAddEditShiftClose}
          selectedShift={selectedShift}
          setShifts={setShifts}
        />
      )}
    </Box>
  );
};

export default ShiftTab;
