import { Typography, Box } from '@mui/material'
import WorkIcon from '@mui/icons-material/Work';
import { IMerch } from '../../types/merch';
import React from 'react'

type Props = {
  item: IMerch;
}

const MerchOverviewColumnItem = ({
  item: {
    merchId,
    itemName,
    sum,
  }
}: Props) => {
  return (
    <Box
      marginTop={1}
      key={merchId}
    >
      <Typography
        fontWeight={600}
      >
        {itemName}
      </Typography>
      <Box
        width={"100%"}
        height={"30px"}
        border={"2px solid white"}
        display={"flex"}
        marginTop={1}
      >
        <Box
          width={"50%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <WorkIcon />
        </Box>
        <Box
          width={"50%"}
          display={"flex"}
          alignItems={"center"}
          sx={{
            backgroundColor: "white",
          }}
        >
          <Typography
            color={"black"}
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            fontWeight={600}
          >
            {sum}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default MerchOverviewColumnItem