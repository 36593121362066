import {
  Box,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
  darken,
  useTheme,
  Grid,
  CircularProgress,
} from "@mui/material";

// Logo
// import { ReactComponent as MySVGLogo } from "../../static/images/CapsuleTransitLogo.svg";
import { ReactComponent as OnlyLogoSVG } from "../../assets/images/Logo.svg";
import { ReactComponent as NoFoodSVG } from "../../assets/images/consent/NoFood.svg";
import { ReactComponent as NoSmokingSVG } from "../../assets/images/consent/NoSmoking.svg";
import { ReactComponent as NoBigLuggageSVG } from "../../assets/images/consent/NoBigLuggage.svg";
import { ReactComponent as BeQuietSVG } from "../../assets/images/consent/BeQuiet.svg";
import { ReactNode, useEffect, useState } from "react";
import React from "react";
import SignatureCanvas from "react-signature-canvas";

import "../../pages/consent/signature.css";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import { store } from "../../redux/store";

type Props = {
  componentRef: React.RefObject<HTMLDivElement>;
  bookingId: string;
};

export default function ConsentFormPrint({ componentRef, bookingId }: Props) {
  const token = store.getState().user.accessToken;

  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [status, setStatus] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const [imageUrlCheckInSign, setImageUrlCheckInSign] = useState<string | null>(
    null
  );
  const [imageUrlCheckOutSign, setImageUrlCheckOutSign] = useState<
    string | null
  >(null);

  useEffect(() => {
    const getConsentFormData = async () => {
      setLoading(true);
      axiosInstance
        .get(`bookings/v1/consent/get-consent-by-booking/${bookingId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const urls: string[] = [];
            Notification.success("Consent Successfully Retrieved");
            setName(res.data.data.name);
            setEmail(res.data.data.email);
            setPhone(res.data.data.phoneNum);
            const blobCheckIn = base64ToBlob(
              res.data.data.consentCheckin,
              "consent.png"
            );
            const urlCheckIn = URL.createObjectURL(blobCheckIn);
            setImageUrlCheckInSign(urlCheckIn);
            urls.push(urlCheckIn);

            const blobCheckOut = base64ToBlob(
              res.data.data.consentCheckout,
              "consent.png"
            );
            const urlCheckOut = URL.createObjectURL(blobCheckOut);
            setImageUrlCheckOutSign(urlCheckOut);
            urls.push(urlCheckOut);

            setImageUrls(urls);
            setStatus(true);

            // Cleanup URLs when component is unmounted
            return () => {
              urls.forEach((url) => URL.revokeObjectURL(url));
            };
          }
        })
        .catch((e) => {
          console.log(e);
          Notification.failed("Not found");
        })
        .finally(() => setLoading(false));
    };
    getConsentFormData();
  }, [token, bookingId]);

  function base64ToBlob(base64String: string, contentType: string = ""): Blob {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: contentType });
  }

  return (
    <>
      <CssBaseline />
      {loading ? (
        <CircularProgress />
      ) : status ? (
        <Box
          width={"100%"}
          height={"100%"}
          paddingY={2}
          paddingX={"50px"}
          bgcolor={"#fff"}
          ref={componentRef}
        >
          <form>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                marginBottom={2}
              >
                <OnlyLogoSVG className="logo" width={40} height={40} />
                <Typography variant="h3" color={"black"}>
                  Hotel Policy
                </Typography>
              </Stack>
              <Typography variant="h3" color={"black"}>
                Room no.
              </Typography>
            </Stack>
            <Typography color={"black"} variant="h6">
              All guests are provided these items and are required to return the
              following items to be returned upon check out:
            </Typography>
            <Stack direction={"row"} spacing={3} marginBottom={1}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      readOnly={true}
                      style={{ color: "black" }}
                    />
                  }
                  label={
                    <Typography variant="h4" color={"black"}>
                      Room Cards
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      readOnly={true}
                      style={{ color: "black" }}
                    />
                  }
                  label={
                    <Typography variant="h4" color={"black"}>
                      Towels
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      readOnly={true}
                      style={{ color: "black" }}
                    />
                  }
                  label={
                    <Typography variant="h4" color={"black"}>
                      Locker Keys
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      readOnly={true}
                      style={{ color: "black" }}
                    />
                  }
                  label={
                    <Typography variant="h4" color={"black"}>
                      Slippers
                    </Typography>
                  }
                />
              </FormGroup>
            </Stack>
            <Stack
              direction={"row"}
              width={"100%"}
              justifyContent={"space-between"}
              marginBottom={2}
            >
              <ConsentRules
                icon={<NoSmokingSVG />}
                title={[
                  "No Smoking",
                  "Dilarang Merokok",
                  "禁止吸烟",
                  "禁煙",
                  "금연",
                ]}
              />

              <ConsentRules
                icon={<NoBigLuggageSVG />}
                title={[
                  "No Big Luggage",
                  "Dilarang Beg Besar",
                  "禁止携带行李箱",
                  "大型の荷物禁止",
                  "대형 짐 금지",
                ]}
              />
              <ConsentRules
                icon={<NoFoodSVG />}
                title={[
                  "No Outside Food & Drinks",
                  "Dilarang Makanan & Minuman Luar",
                  "禁止外带食物及饮料",
                  "外部飲食物は持ち込み禁止",
                  "외부 음식 및 음료물 통제",
                ]}
              />
              <ConsentRules
                icon={<BeQuietSVG />}
                title={[
                  "Be Quiet",
                  "Dilarang Bunyi Kuat",
                  "保持安静",
                  "大きな音禁止",
                  "큰 소리 금지",
                ]}
              />
            </Stack>
            <Stack direction={"row"} marginBottom={2}>
              <Typography
                sx={{ color: darken("#ffffff", 0.8), marginRight: "3px" }}
                variant="h6"
              >
                ** Guests are urged to stay updated on their own flight times as
              </Typography>
              <Typography fontWeight={"bold"} color={"black"} variant="h6">
                the hotel will not be liable for any missed flights.
              </Typography>
            </Stack>

            <Stack
              direction={"column"}
              width={"100%"}
              marginTop={2}
              marginBottom={2}
              spacing={2}
            >
              <ConsentTextField
                title="Guest Name"
                name={"guestName"}
                values={name}
              />
              <ConsentTextField
                title="Email Address"
                name={"emailAddress"}
                values={email}
              />
              <ConsentTextField
                title="Phone Number"
                name={"phoneNumber"}
                values={phone}
              />
            </Stack>
            <Stack
              direction={"row"}
              width={"100%"}
              justifyContent={"space-between"}
              alignItems={"end"}
            >
              <Stack direction={"row"} width={"100%"}>
                <Box
                  flexDirection={"row"}
                  width={"80%"}
                  height={"100px"}
                  border={2}
                  borderRadius={"5px"}
                  borderColor={"black"}
                  //position={"relative"}
                  sx={{
                    backgroundImage: `url(${imageUrlCheckInSign})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "60%",
                    backgroundPosition: "center",
                  }}
                >
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        color={"black"}
                        fontWeight={"bold"}
                        sx={{ top: 15, left: 10 }}
                      >
                        Check In Signature:
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
              <Stack direction={"row"} width={"100%"}>
                <Box
                  flexDirection={"row"}
                  width={"80%"}
                  height={"100px"}
                  border={2}
                  borderRadius={"5px"}
                  borderColor={"black"}
                  sx={{
                    backgroundImage: `url(${imageUrlCheckOutSign})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "60%",
                    backgroundPosition: "center",
                  }}
                >
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        color={"black"}
                        fontWeight={"bold"}
                        sx={{ top: 15, left: 10 }}
                      >
                        Check Out Signature:
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={6}>
                      {imageUrlCheckOutSign && (
                        <img src={imageUrlCheckOutSign} alt="" width={100} />
                      )}
                    </Grid> */}
                  </Grid>
                </Box>
              </Stack>
            </Stack>
          </form>
        </Box>
      ) : (
        <Typography>Not found</Typography>
      )}
    </>
  );
}

const ConsentTextField = ({
  title,
  name,
  values,
}: {
  title: string;
  name: string;
  values: unknown;
}) => {
  const theme = useTheme();
  return (
    <Stack direction={"row"} width={"100%"}>
      <Box
        display={"flex"}
        borderColor={theme.palette.primary.main}
        width={"30%"}
        height={"35px"}
        alignItems={"center"}
        paddingX={2}
      >
        <Typography fontWeight={"bold"} color={"black"}>
          {title}
        </Typography>
      </Box>
      <Box width={"70%"} height={"35px"} alignItems={"center"}>
        <TextField
          size={"small"}
          fullWidth
          name={name}
          value={values}
          sx={{
            height: "5px",
            padding: 0,
            ".MuiInputBase-input": {
              border: "none",
              paddingY: "5px",
              backgroundColor: "white",
              color: "black",
              "&:focus": {
                borderColor: "black", // Change the focus border color to green
              },
            },
            ".Mui-focused": {
              borderColor: "black",
            },
          }}
        />
      </Box>
    </Stack>
  );
};

const ConsentRules = ({
  title,
  icon,
}: {
  title: string[];
  icon: ReactNode;
}) => {
  return (
    <Box width={"25%"} textAlign={"center"}>
      <Box
        display={"flex"}
        height={"150px"}
        justifyContent={"center"}
        alignItems={"center"}
        marginBottom={1}
      >
        {icon}
      </Box>
      {title.map((item, index) => {
        return (
          <Typography
            variant="h6"
            fontWeight={"bold"}
            color={"black"}
            marginBottom={0.5}
            key={index}
          >
            {item}
          </Typography>
        );
      })}
    </Box>
  );
};
