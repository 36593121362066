import React from "react";
import { ReactComponent as OnlyLogoSVG } from "../../assets/images/Logo.svg";
import { CssBaseline, Box, Stack, Typography, Divider } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import { RoomBooking } from "../../pages/booking/interface";
import { format } from "date-fns";
import { IWifi } from "./CheckinSlipPrintModal";
import {ReactComponent as CheckInIcon} from "../../assets/images/checkInSlip/checkin.svg"
import {ReactComponent as CheckOutIcon} from "../../assets/images/checkInSlip/checkout.svg"
import {ReactComponent as HumanIcon} from "../../assets/images/checkInSlip/human.svg"
import {ReactComponent as TimeIcon} from "../../assets/images/checkInSlip/time.svg"
import {ReactComponent as WifiIcon} from "../../assets/images/checkInSlip/wifi.svg"


type Props = {
	value: RoomBooking;
	bookingNo: string;
	duration: number;
	checkInDatetime: string;
	checkOutDatetime: string;
	lockerCode: string;
	wifiCreds: IWifi;
};

function CheckinSlip({
	value,
	bookingNo,
	duration,
	checkInDatetime,
	checkOutDatetime,
	lockerCode,
	wifiCreds,
}: Props) {
	return (
		<>
			<CssBaseline />
			<Box
				style={{
					width: "78mm", // Set custom width in millimeters
					height: "100%", // Set custom height in millimeters
				}}
				paddingY={2}
				paddingX={"25px"}
				bgcolor={"#fff"}
				display={"flex"}
				justifyContent={"center"}>
				<Stack direction={"column"} width={"100%"}>
					<Stack
						direction={"row"}
						spacing={2}
						alignItems={"center"}
						marginBottom={2}
						justifyContent={"center"}>
						<OnlyLogoSVG width={30} height={30} />
						<Typography variant="h2" color={"black"}>
							CapsuleTransit
						</Typography>
					</Stack>
					<Divider
						orientation="horizontal"
						flexItem
						sx={{
							borderBottomWidth: 2.5,
							borderColor: "black",
						}}
					/>
					<Stack direction={"row"} marginBottom={1} spacing={3} py={1} px={2}>
						<WifiIcon fontSize="large" sx={{ color: "black" }} style={{ width: '40px', height: '40px' }}/>
						<Stack direction={"column"}>
							<Typography variant="h6" color={"black"} fontWeight={"bold"}>
								WIFI | {wifiCreds.name}
							</Typography>
							<Typography variant="h6" color={"black"} fontWeight={"bold"}>
								PASSWORD | <i>{wifiCreds.password}</i>
							</Typography>
						</Stack>
					</Stack>
					<Divider
						orientation="horizontal"
						flexItem
						sx={{
							borderBottomWidth: 2.5,
							borderColor: "black",
							marginBottom: 2,
						}}
					/>
					<Stack
						direction={"row"}
						marginBottom={1}
						justifyContent={"center"}
						spacing={3}>
						<Stack direction={"column"} marginBottom={2}>
							<Typography variant="h5" color={"black"} fontWeight={"bold"}>
								GATE
							</Typography>
							<Typography variant="h2" color={"black"} fontWeight={"bold"}>
								{value.roomZone.charAt(value.roomZone.length - 1)}
							</Typography>
						</Stack>

						<Divider
							orientation="vertical"
							flexItem
							sx={{
								borderRightWidth: 2.5,
								borderRightColor: "black",
								height: "75%",
							}}
						/>
						<Stack direction={"column"} marginBottom={2}>
							<Typography variant="h5" color={"black"} fontWeight={"bold"}>
								ROOM
							</Typography>
							<Typography variant="h2" color={"black"} fontWeight={"bold"}>
								{value.roomCode}
							</Typography>
						</Stack>
						<Divider
							orientation="vertical"
							flexItem
							sx={{
								borderRightWidth: 2.5,
								borderColor: "black",
								height: "75%",
							}}
						/>
						<Stack direction={"column"} marginBottom={2}>
							<Typography variant="h5" color={"black"} fontWeight={"bold"}>
								LOCKER
							</Typography>
							<Typography variant="h2" color={"black"} fontWeight={"bold"}>
								{lockerCode}
							</Typography>
						</Stack>
					</Stack>
					<Divider
						orientation="horizontal"
						flexItem
						sx={{
							borderBottomWidth: 2.5,
							borderColor: "black",
						}}
					/>
					<Stack
						direction={"row"}
						marginBottom={1}
						justifyContent={"center"}
						spacing={1}
						width={"100%"}
						px={2}
						py={1}
            alignItems={"center"}>
						<Box>
							<CheckInIcon fontSize="large" sx={{ color: "black" }} style={{ width: '40px', height: '40px' }}/>
						</Box>
						<Stack direction={"column"} marginBottom={2}>
							<Typography variant="h6" color={"black"} fontWeight={"bold"}>
								CHECK-IN
							</Typography>
							<Typography variant="h5" color={"black"} fontWeight={"bold"}>
								{checkInDatetime !== "-"
									? format(new Date(checkInDatetime), "h:mm a")
									: checkInDatetime}
							</Typography>
							<Typography variant="h6" color={"black"} fontWeight={"bold"}>
								{checkInDatetime !== "-"
									? format(new Date(checkInDatetime), "dd/MM/yyyy")
									: checkInDatetime}
							</Typography>
						</Stack>
						<Divider
							orientation="vertical"
							flexItem
							sx={{
								borderRightWidth: 2.5,
								borderRightColor: "black",
								height: "100%",
							}}
						/>
						<Box>
							<CheckOutIcon fontSize="large" sx={{ color: "black" }} style={{ width: '40px', height: '40px' }}/>
						</Box>
						<Stack direction={"column"} marginBottom={2}>
							<Typography variant="h6" color={"black"} fontWeight={"bold"}>
								CHECK-OUT
							</Typography>
							<Typography variant="h5" color={"black"} fontWeight={"bold"}>
								{checkOutDatetime !== "-"
									? format(new Date(checkOutDatetime), "h:mm a")
									: checkOutDatetime}
							</Typography>
							<Typography variant="h6" color={"black"} fontWeight={"bold"}>
								{checkOutDatetime !== "-"
									? format(new Date(checkOutDatetime), "dd/MM/yyyy")
									: checkOutDatetime}
							</Typography>
						</Stack>
					</Stack>
					<Divider
						orientation="horizontal"
						flexItem
						sx={{
							borderBottomWidth: 2.5,
							borderColor: "black",
						}}
					/>
					<Stack direction={"row"} marginBottom={1} spacing={3} py={1} px={2}>
						<HumanIcon fontSize="large" sx={{ color: "black" }}  style={{ width: '40px', height: '40px' }}/>
						<Typography variant="h3" color={"black"} fontWeight={"bold"}>
							{value.personInCharge + " " + value.personInChargeLastname}
						</Typography>
					</Stack>
					<Divider
						orientation="horizontal"
						flexItem
						sx={{
							borderBottomWidth: 2.5,
							borderColor: "black",
						}}
					/>
					<Stack direction={"row"} marginBottom={1} spacing={3} py={1} px={2} alignItems={"center"}>
						<TimeIcon fontSize="large" sx={{ color: "black" }} style={{ width: '40px', height: '40px' }}/>
						<Typography variant="h3" color={"black"} fontWeight={"bold"}>
							{duration} Hours
						</Typography>
					</Stack>

					<Divider
						orientation="horizontal"
						flexItem
						sx={{
							borderBottomWidth: 2.5,
							borderColor: "black",
						}}
					/>
					<Stack
						direction={"column"}
						marginBottom={2}
						spacing={0}
						py={1}
						alignItems={"center"}>
						<Typography variant="h6" color={"black"} border={"solid"} px={2}>
							{bookingNo}
						</Typography>
					</Stack>
				</Stack>
			</Box>
		</>
	);
}

export default CheckinSlip;
