import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ManagementModalHeader from "../management/ManagementModalHeader";
import { BookingResult, RoomBooking } from "../../pages/booking/interface";
import RoomBox from "../global/RoomBox";
import { ArrowForward, Delete, Edit, Person } from "@mui/icons-material";
import { useEffect, useState } from "react";
import GuestAddAssignModal from "../guests/GuestAddAssignModal";
import { IExistingCustomerRow } from "../../models/guests/GuestsInterface";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import { store } from "../../redux/store";

const AssignGuestModal = (props: {
  open: boolean;
  handleCloseModal: VoidFunction;
  roomBookings: RoomBooking[];
  bookingId: string;
  customerBooked?: string;
  setGuestFolioData: React.Dispatch<
    React.SetStateAction<BookingResult | undefined>
  >;
}) => {
  const theme = useTheme();
  const token = store.getState().user.accessToken;

  const [roomBookingEdit, setRoomBookingEdit] = useState<RoomBooking[]>(
    props.roomBookings
  );

  const [roomBookingIndex, setRoomBookingIndex] = useState<number>(0);

  const [openAddOrAssignGuestModal, setOpenAddOrAssignGuestModal] =
    useState<boolean>(false);

  const handleOpenAddOrAssignGuestModal = (index: number) => {
    setOpenAddOrAssignGuestModal(true);
    setRoomBookingIndex(index);
  };
  const handleCloseAddOrAssignGuestModal = () => {
    setOpenAddOrAssignGuestModal(false);
  };

  useEffect(() => {
    const unassignedRoom = props.roomBookings.filter(
      (roomBooking) => !roomBooking.personInChargeId
    );
    if (unassignedRoom.length === props.roomBookings.length) {
      handleOpenAddOrAssignGuestModal(roomBookingIndex);
    }
  }, [props.roomBookings]);

  const handleAssignGuestToRoom = (customer: IExistingCustomerRow) => {
    handleCloseAddOrAssignGuestModal();
    setRoomBookingEdit((prevValue) => {
      const gotAssignedRoom =
        prevValue.filter((value) => value.personInCharge !== null).length === 0;

      if (gotAssignedRoom) {
        const assignToAllRoom = prevValue.map((value) => ({
          ...value,
          personInChargeId: customer.customerId,
          personInCharge: customer.fullName.split(" ")[0],
          personInChargeLastname: customer.fullName.split(" ")[1],
          personInChargeCountry: customer.countryCode,
        }));

        return assignToAllRoom;
      }

      const selectedRoom = prevValue[roomBookingIndex];

      const filteredRoomBooking = [
        ...prevValue.slice(0, roomBookingIndex),
        ...prevValue.slice(roomBookingIndex + 1),
      ];

      const modifiedRoom: RoomBooking = {
        ...selectedRoom,
        personInChargeId: customer.customerId,
        personInCharge: customer.fullName.split(" ")[0],
        personInChargeLastname: customer.fullName.split(" ")[1],
        personInChargeCountry: customer.countryCode,
      };

      let newRoomBooking: RoomBooking[] = filteredRoomBooking;

      newRoomBooking.splice(roomBookingIndex, 0, modifiedRoom);

      return newRoomBooking;
    });
  };

  const handleSubmit = () => {
    const apiUrl = "/bookings/assign-guest/";

    const formData = {
      bookingId: props.bookingId,
      roomGuest: roomBookingEdit.map((roomBooking) => ({
        picId: roomBooking.personInChargeId,
        roomBookingId: roomBooking.roomBookingId,
      })),
    };

    axiosInstance
      .post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        Notification.success(res.data.message);
        props.setGuestFolioData((prevValue) => {
          if (!prevValue) {
            return prevValue;
          }
          return {
            ...prevValue,
            roomBookings: roomBookingEdit,
            customerStaying: res.data.data.customerStayingId,
            customerBooked: res.data.data.name,
          };
        });
      })
      .catch((res) => res.response.data);
  };
  return (
    <>
      <Modal open={props.open} onClose={props.handleCloseModal}>
        <Box
          width={"700px"}
          maxHeight={"600px"}
          border={`3px solid ${theme.palette.primary.main}`}
          alignItems={"center"}
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "black",
            borderRadius: 1,
          }}
        >
          <ManagementModalHeader
            title={"Assign Guest"}
            handleModalClose={props.handleCloseModal}
          />
          <Box
            display={"flex"}
            flexDirection={"column"}
            bgcolor={"black"}
            justifyContent={"start"}
            alignItems={"center"}
            paddingX={4}
            paddingY={4}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              width={"100%"}
              marginBottom={2}
            >
              <Typography variant="h3">Rooms: </Typography>
              <Typography variant="h3">Guest:</Typography>
            </Stack>
            {roomBookingEdit.map((roomBooking, index) => (
              <Stack
                direction={"row"}
                width={"100%"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  width={"45%"}
                  alignItems={"center"}
                >
                  <RoomBox
                    colorCode={
                      roomBooking.roomTypeColorTags
                        ? roomBooking.roomTypeColorTags
                        : "#ffffff"
                    }
                    roomCode={roomBooking.roomCode}
                    roomDetails={
                      roomBooking.roomTypeDetails
                        ? roomBooking.roomTypeDetails
                        : ""
                    }
                    roomZone={roomBooking.roomZone}
                  />
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <ArrowForward color="secondary" sx={{ fontSize: "36px" }} />
                </Box>
                {roomBooking.personInChargeId ? (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"45%"}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                    >
                      <Person
                        color="primary"
                        sx={{ fontSize: "36px", marginRight: 1 }}
                      />
                      <Typography variant="h4">
                        {roomBooking.personInCharge}{" "}
                        {roomBooking.personInChargeLastname}
                      </Typography>
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                    >
                      <IconButton
                        onClick={() => handleOpenAddOrAssignGuestModal(index)}
                      >
                        <Edit color="primary" />
                      </IconButton>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"45%"}
                  >
                    <Person
                      color="primary"
                      sx={{ fontSize: "36px", marginRight: 1 }}
                    />
                    <Button
                      onClick={() => handleOpenAddOrAssignGuestModal(index)}
                      variant="contained"
                    >
                      Assign
                    </Button>
                  </Box>
                )}
              </Stack>
            ))}
          </Box>
          <Box
            display={"flex"}
            height={"50px"}
            bgcolor={theme.palette.background.paper}
            justifyContent={"end"}
            padding={1}
            borderTop={2}
            borderColor={theme.palette.primary.main}
          >
            <Button variant={"outlined"} onClick={handleSubmit}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
      <GuestAddAssignModal
        open={openAddOrAssignGuestModal}
        existingName={props.customerBooked}
        handleClose={handleCloseAddOrAssignGuestModal}
        handleSetCustomerStaying={handleAssignGuestToRoom}
      />
    </>
  );
};

export default AssignGuestModal;
