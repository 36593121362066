import { InputBaseComponentProps } from "@mui/material";
import TextField from "@mui/material/TextField";
import { SxProps, Theme } from "@mui/material/styles";
import { ChangeEvent } from "react";
import { toNumberString } from "../../utils/functions";
import Notification from "../../utils/notificationConfig";

interface PhoneFieldProps {
  value: string;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (value: string) => void;
  inputProps?: InputBaseComponentProps;
  sx?: SxProps<Theme>;
}

const PhoneField = ({
  value,
  name,
  onChange,
  setFieldValue,
  inputProps,
  sx,
}: PhoneFieldProps) => (
  <TextField
    value={value}
    name={name}
    onChange={(event: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);

      const newValue = toNumberString(event.target.value);
      newValue !== event.target.value &&
        Notification.failed("You can only enter numbers.");
      setFieldValue(newValue);
    }}
    size="small"
    fullWidth
    inputProps={inputProps}
    sx={sx}
  />
);

export default PhoneField;
