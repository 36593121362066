import {
  ArrowBack,
  ArrowForward,
  Done,
  HighlightOff,
  WarningAmber,
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  ButtonBase,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.default",
  border: 1,
  borderColor: "primary.main",
  boxShadow: 24,
};

type Props = {
  isRoomDirty: boolean;
  handleCheckRoomDirtyClose: () => void;
  handleCheckInBooking: () => void;
};

const CheckRoomDirty = ({
  isRoomDirty,
  handleCheckRoomDirtyClose,
  handleCheckInBooking,
}: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <>
      <Modal
        open={isRoomDirty}
        onClose={handleCheckRoomDirtyClose}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(69, 89, 167, 0.4)",
            },
          },
        }}
      >
        <Box sx={style}>
          <Box
            display={"flex"}
            height={"40px"}
            alignItems={"center"}
            paddingX={1}
            borderBottom={1}
            borderColor={theme.palette.primary.main}
          >
            <Stack
              width={"100%"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h4" color={"primary"}>
                Warning
              </Typography>
              <IconButton color="primary" onClick={handleCheckRoomDirtyClose}>
                <HighlightOff />
              </IconButton>
            </Stack>
          </Box>

          <>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              height={"230px"}
              paddingX={"30px"}
              sx={{ backgroundColor: "black" }}
            >
              <WarningAmber
                color="primary"
                sx={{ fontSize: "50px", marginRight: "20px" }}
              />
              <Stack
                display={"flex"}
                justifyContent={"center"}
                alignItems={"start"}
                width={"100%"}
              >
                <Typography variant="h3">
                  This room selected is under{" "}
                  <span style={{ color: "red" }}>dirty status</span>.
                </Typography>
                <Typography variant="h5">
                  Please assign cleaner to dirty room before proceed to check in
                </Typography>
              </Stack>
            </Box>

            <Stack direction={"row"} width={"100%"} height={"110px"}>
              <ButtonBase
                href={`/bookings/check-in-out-list`}
                sx={{
                  width: "50%",
                  height: "100%",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                  height={"100%"}
                  borderTop={1}
                  borderRight={1}
                  borderColor={theme.palette.primary.main}
                  paddingX={1}
                >
                  <ArrowBack sx={{ fontSize: "50px" }} />
                  <Typography variant="h4" mr={2}>
                    Check In Later
                  </Typography>
                </Box>
              </ButtonBase>
              <ButtonBase
                onClick={handleCheckInBooking}
                sx={{
                  width: "50%",
                  height: "100%",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                  height={"100%"}
                  borderTop={1}
                  borderColor={theme.palette.primary.main}
                  paddingX={1}
                >
                  <ArrowForward sx={{ fontSize: "50px" }} />
                  <Typography variant="h4" mr={2}>
                    I'm Aware, Proceed to Check In
                  </Typography>
                </Box>
              </ButtonBase>
            </Stack>
          </>
        </Box>
      </Modal>
    </>
  );
};
export default CheckRoomDirty;
