import { Box, Grid, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import theme from "../../assets/theme/theme";

type Props = {
  dateValue: string;
  handleBlur: (e: any) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  fieldName: string;
  label: string;
  lastChild?: boolean;
};

const ManagementModalFormDatePicker = ({
  dateValue,
  handleBlur,
  setFieldValue,
  fieldName,
  label,
  lastChild = false,
}: Props) => {
  return (
    <Stack direction={"row"} width={"100%"}>
      <Box
        display={"flex"}
        border={2}
        borderColor={theme.palette.primary.main}
        width={"230px"}
        alignItems={"center"}
        paddingX={1}
      >
        <Typography variant={"h4"} fontWeight={600}>
          {label}
        </Typography>
      </Box>
      <Box border={2} borderColor={theme.palette.primary.main} width={"100%"}>
        <DatePicker
          value={dateValue ? new Date(dateValue) : new Date()}
          onChange={(value) =>
            value &&
            setFieldValue(
              fieldName,
              `${value.getFullYear()}-${
                value.getMonth() + 1
              }-${value.getDate()}`
            )
          }
          format={"dd-MM-yyyy"}
          sx={{
            width: "100%",
          }}
          slotProps={{
            textField: {
              name: fieldName,
              onBlur: handleBlur,
              InputProps: {
                sx: {
                  height: "3em",
                  paddingY: "0",
                  paddingX: "1.5em",
                  backgroundColor: theme.palette.background.default,
                  border: "none",
                  borderRadius: "0",

                  "& .MuiInputAdornment-root .MuiIconButton-root .MuiSvgIcon-root":
                    {
                      color: "white",
                    },
                },
              },
              inputProps: {
                style: {
                  height: "3em",
                  padding: "0",
                  border: "none",
                  borderRadius: "0",
                  fontWeight: 600,
                },
              },
            },
            openPickerButton: {
              sx: {
                color: "white",
                marginTop: "1.8px",
                zIndex: 1,
              },
            },
            openPickerIcon: {
              sx: {
                width: "1em",
                height: "1em",
                zIndex: 1,
              },
            },
            layout: {
              sx: {
                ".MuiDateCalendar-root": {
                  color: theme.palette.primary.main,
                  borderRadius: 2,
                  borderWidth: 1,
                  borderColor: theme.palette.primary.main,
                  border: "1px solid",
                  backgroundColor: theme.palette.background.paper,
                },
                ".MuiButtonBase-root": {
                  color: theme.palette.primary.main,
                },
                ".MuiTypography-root": {
                  color: theme.palette.primary.main,
                },
                ".MuiPickersCalendarHeader-root": {
                  color: theme.palette.primary.main,
                },
                ".MuiPickersMonth-monthButton": {
                  color: theme.palette.primary.main,
                },
                ".MuiPickersCalendarHeader-label": {
                  color: theme.palette.primary.main,
                },
                ".Mui-selected": {
                  color: "black",
                },
                ".MuiPickersYear-yearButton": {
                  color: theme.palette.primary.main,
                },
              },
            },
          }}
        />
      </Box>
    </Stack>
  );
};

export default ManagementModalFormDatePicker;
