import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";

interface LoadableCheckboxProps extends CheckboxProps {
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => Promise<void>;
}

const LoadableCheckbox = (props: LoadableCheckboxProps) => {
  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) return <CircularProgress />;

  const { onChange, ...rest } = props;
  return (
    <Checkbox
      {...rest}
      onChange={
        onChange &&
        (async (...args) => {
          setIsLoading(true);
          await onChange(...args);
          setIsLoading(false);
        })
      }
    />
  );
};

export default LoadableCheckbox;
