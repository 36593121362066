import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import ManagementModalHeader from "../management/ManagementModalHeader";
import theme from "../../assets/theme/theme";
import { ChangeEvent, useEffect, useState } from "react";
import axiosInstance from "../../constants/axiosConfig";
import { RootState } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import { useSelector } from "react-redux";
import { IPayments } from "../../models/payment/PaymentInterface";
import { transactionStatusEnum } from "../../constants/enums";
import { Article } from "@mui/icons-material";

type Props = {
  data: IPayments;
  isPaymentDetailModalOpen: boolean;
  handleClosePaymentDetailsModal: () => void;
};

const PaymentViewDetailsModal = ({
  data,
  isPaymentDetailModalOpen,
  handleClosePaymentDetailsModal,
}: Props) => {
  const token = useSelector((state: RootState) => state.user.accessToken);

  const [loading, setLoading] = useState<boolean>(false);

  //   const handleOpenPaymentTypeModal = (data: IPayments) => {
  //     setIsPaymentTypeModal(data);
  //   };
  //   const handleClosePaymentTypeModal = () => {
  //     setIsPaymentTypeModal(null);
  //   };

  const [remarks, setRemarks] = useState<string>("");
  const [reference, setReference] = useState<string>(""); // another name: Approval Code
  const [pan, setPan] = useState<string>("");
  const [guestGiven, setGuestGiven] = useState<number>(0);
  const [guestChange, setGuestChange] = useState<number>(0);

  useEffect(() => {
    setReference(data.paymentReference!);
    setRemarks(data.paymentRemarks!);
    setPan(data.pan ? data.pan : "");
    setGuestGiven(data.guestGiven ? parseFloat(data.guestGiven) : 0);
    setGuestChange(data.guestChange ? parseFloat(data.guestChange) : 0);
  }, [
    data.paymentReference,
    data.paymentRemarks,
    data.pan,
    data.guestGiven,
    data.guestChange,
  ]);

  const setReferenceAndRemarks = async () => {
    setLoading(true);
    const payload = {
      transactionId: data.transactionId,
      pan: pan,
      guestGiven: guestGiven,
      guestChange: guestChange,
      paymentRemarks: remarks,
      paymentReference: reference,
    };
    await axiosInstance
      .patch(`/transaction/payment-details/`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success(response.data.message);
        handleClosePaymentDetailsModal();
      })
      .catch((error) => {
        Notification.failed(`Error updating zone: ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCheckRefundForm = () => {
    setLoading(true);
    axiosInstance
      .get(`/transaction/refund-detail/${data.transactionId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        window.open(
          response.data.data.refundFormUrl,
          "_blank",
          "noopener,noreferrer"
        );
      })
      .catch((error) => {
        Notification.failed(`Error updating zone: ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      open={isPaymentDetailModalOpen}
      onClose={handleClosePaymentDetailsModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box
        width={"600px"}
        minWidth={"38em"}
        border={`3px solid ${theme.palette.primary.main}`}
        margin={"auto"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
        }}
      >
        <ManagementModalHeader
          title="Payment Details"
          handleModalClose={handleClosePaymentDetailsModal}
        />
        <Grid
          container
          spacing={2}
          padding={5}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xs={4}>
            <Typography>Cashier</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              value={data.cashierName}
              sx={{
                "& input": { backgroundColor: "transparent" },
                "& fieldset": { borderColor: "transparent !important" },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>Payment Type</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              value={data.paymentTypeName}
              sx={{
                "& input": { backgroundColor: "transparent" },
                "& fieldset": { borderColor: "transparent !important" },
              }}
            />
          </Grid>
          {data.paymentTypeName === "Cash" && (
            <div>
              <Grid
                container
                spacing={2}
                padding={5}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Grid item xs={4}>
                  <Typography>Guest Given</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    label="Guest Given"
                    value={guestGiven}
                    type="number"
                    onChange={(e) => setGuestGiven(parseFloat(e.target.value))}
                    sx={{ width: "50%" }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography>Guest Change</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    label="Guest Change"
                    type="number"
                    value={guestChange}
                    onChange={(e) => setGuestChange(parseFloat(e.target.value))}
                    sx={{ width: "50%" }}
                  />
                </Grid>
              </Grid>
            </div>
          )}

          <Grid item xs={4}>
            <Typography>Last 4 Digit</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              label="Last 4 Digit"
              fullWidth
              value={pan}
              onChange={(e) => setPan(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>Approval Code</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              label="Approval Code"
              fullWidth
              value={reference}
              onChange={(e) => setReference(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>Payment Remarks</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              label="Remarks"
              fullWidth
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Grid>
          {data.status === transactionStatusEnum.refund && (
            <Grid item xs={2}>
              <Button onClick={handleCheckRefundForm} disabled={loading}>
                {loading ? (
                  <CircularProgress color="primary" size={"24px"} />
                ) : (
                  <Article />
                )}
              </Button>
            </Grid>
          )}
          <Grid
            item
            xs={data.status === transactionStatusEnum.refund ? 8 : 10}
          />
          <Grid item xs={2}>
            <Button
              variant="contained"
              onClick={setReferenceAndRemarks}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress color="primary" size={"24px"} />
              ) : (
                "Save"
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default PaymentViewDetailsModal;
