import React, { useEffect, useState } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// icons
import HomeIcon from "@mui/icons-material/Home";
import ComputerIcon from "@mui/icons-material/Computer";
import DryCleaningIcon from "@mui/icons-material/DryCleaning";
import PersonIcon from "@mui/icons-material/Person";
import BarChartIcon from "@mui/icons-material/BarChart";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import BuildIcon from "@mui/icons-material/Build";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ShowerIcon from "@mui/icons-material/Shower";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import ChecklistIcon from "@mui/icons-material/Checklist";
import StorefrontIcon from "@mui/icons-material/Storefront";
import SellIcon from "@mui/icons-material/Sell";

// types and Interface
import {
  SidebarItemInterface,
  SidebarItemChildInterface,
} from "../../../models/sidebar/sidebarMenu";
import { EmptyExpressBookingRedux } from "../../../redux/booking/expressbooking";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
interface SidebarItemProps extends SidebarItemInterface {
  index: string;
  activeItem: string;
  handleActiveItem: (key: string) => void;
}

function iconComponents(key: string) {
  switch (key) {
    case "home":
      return <HomeIcon />;
    case "computer":
      return <ComputerIcon />;
    case "clean":
      return <DryCleaningIcon />;
    case "person":
      return <PersonIcon />;
    case "chart":
      return <BarChartIcon />;
    case "setup":
      return <PhonelinkSetupIcon />;
    case "tool":
      return <BuildIcon />;
    case "group":
      return <PeopleAltIcon />;
    case "adminlock":
      return <LockPersonIcon />;
    case "room":
      return <MeetingRoomIcon />;
    case "lockopen":
      return <LockOpenIcon />;
    case "shower":
      return <ShowerIcon />;
    case "dollar":
      return <AttachMoneyIcon />;
    case "phone":
      return <SmartphoneIcon />;
    case "checklist":
      return <ChecklistIcon />;
    case "merchandise":
      return <StorefrontIcon />;
    case "promotion":
      return <SellIcon />;
    default:
      return <QuestionMarkIcon />;
  }
}

export default function SidebarItem(props: SidebarItemProps) {
  const IconComponent = iconComponents(props.icon);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const themes = useTheme();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeChild, setActiveChild] = useState<number>(-1);

  const userPermission = useSelector(
    (state: RootState) => state.user.permissions
  );

  let calculatedHeight = props.dropdownItem
    ? props.dropdownItem.length * 50
    : 0;

  const handleItemClick = () => {
    if (!props.haveDropdown && props.url) {
      dispatch(EmptyExpressBookingRedux());
      navigate(`${props.url}`);
      return;
    }
    props.handleActiveItem(props.index);
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (props.activeItem !== props.index) {
      setIsOpen(false);
      setActiveChild(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeItem]);

  return (
    <ListItem
      disablePadding
      sx={{ display: "block", position: "relative", backgroundColor: "black" }}
    >
      <ListItemButton
        sx={{
          "&:hover": {
            backgroundColor: themes.palette.primary.light,
            transition: "all 0.3s",
          },
          backgroundColor: `${isOpen ? themes.palette.primary.main : ""}`,
        }}
        onClick={handleItemClick}
      >
        {props.haveDropdown ? (
          <>
            <ListItemIcon sx={{ color: isOpen ? "black" : "white" }}>
              {IconComponent}
            </ListItemIcon>
            <ListItemText
              primary={props.text}
              primaryTypographyProps={{
                color: isOpen ? "black" : "white",
                variant: "h4",
              }}
            />
            <ListItemIcon
              sx={{ color: isOpen ? "black" : "white", marginRight: "-24px" }}
            >
              {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </ListItemIcon>
          </>
        ) : (
          <>
            <ListItemIcon sx={{ color: "white" }}>{IconComponent}</ListItemIcon>
            <ListItemText
              primary={props.text}
              primaryTypographyProps={{
                color: isOpen ? "black" : "white",
                variant: "h4",
              }}
            />
          </>
        )}
      </ListItemButton>
      {props.haveDropdown && props.dropdownItem && (
        <List
          disablePadding
          sx={{
            height: `${isOpen ? calculatedHeight : 0}px`,
            overflow: "hidden",
            backgroundColor: themes.palette.background.default,
            transition: "all 0.3s",
          }}
        >
          {props.dropdownItem.map((e: SidebarItemChildInterface, i) => {
            let haveAccess: boolean = true;

            if (e.visiblePermission) {
              for (let item of e.visiblePermission) {
                if (userPermission.includes(item)) {
                  haveAccess = true;
                  break;
                }
                haveAccess = false;
              }
            }
            if (!haveAccess) {
              return (
                <div key={i} style={{ display: "none" }}></div>
              );
            }
            return (
              <React.Fragment key={i}>
                <ListItemButton
                  sx={{
                    "&:hover": {
                      backgroundColor: themes.palette.primary.light,
                      transition: "all 0.3s",
                    },
                    backgroundColor: `${
                      e.background
                        ? e.background
                        : i === activeChild
                        ? themes.palette.primary.light
                        : "transparent"
                    }`,
                  }}
                  onClick={() => {
                    setActiveChild(i);
                    if (i !== activeChild) {
                      dispatch(EmptyExpressBookingRedux());
                    }
                    navigate(`${e.url}`);
                  }}
                >
                  <ListItemIcon sx={{ color: "white" }}>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={e.text} sx={{ color: "white" }} />
                </ListItemButton>
              </React.Fragment>
            );
          })}
        </List>
      )}
    </ListItem>
  );
}
