import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

// Pages
import NotFound from "./pages/NotFound";
import AccountList from "./pages/accounts/AccountList";
import CreateAccount from "./pages/accounts/CreateAccount";
import EditAccount from "./pages/accounts/EditAccount";
import FloorPlan from "./pages/floorplan/FloorPlan";
import Guest from './pages/guests/MembershipPage';
import HomePage from "./pages/homepage/HomePage";
import Login from "./pages/login/Login";
import RoleList from "./pages/roles/RoleList";
import RoomZone from "./pages/rooms/RoomZone";

// Components
import CommonComponents from './components/global/CommonComponents';
import PermissionPortectedRoutes from './components/global/PermissionProtectedRoutes';
import PortectedRoutes from './components/global/ProtectedRoutes';

// Toastify
import { ToastContainer } from "react-toastify";
import FeedbackFormNew from "./components/guests/FeedbackFormPrintModal";
import UnderMaintenance from "./pages/UnderMaintenance";
import ExpressBookingPage from "./pages/booking/ExpressBookingPage";
import GanttChartBookingInfo from "./pages/booking/GanttChartBookingInfo";
import GanttChartSection from "./pages/booking/GanttChartSection";
import NewCheckinList from "./pages/booking/NewCheckInList";
import NewCheckOutList from "./pages/booking/NewCheckOutList";
import CashierManagementPage from "./pages/cashier/CashierManagementPage";
import ConsentFormPage from "./pages/consent/ConsentFormPage";
import FeedbackStatistic from "./pages/feedback/FeedbackStatistic";
import GuestTrackingBookings from "./pages/guest-tracking/GuestTrackingBookings";
import GuestTrackingPage from "./pages/guest-tracking/GuestTrackingPage";
import GuestFolioModified from "./pages/guests/GuestFolioPageModified";
import PaymentScreen from "./pages/guests/PaymentScreen";
import Ledgers from "./pages/ledgers/Ledgers";
import UpcomingLockers from "./pages/lockers/LockerBookings";
import NewLockerPage from "./pages/lockers/NewLockerPage";
import MerchManagementPage from "./pages/merchs/MerchManagementPage";
import OTALoggingPage from "./pages/ota/OTALoggingPage";
import OTAReservationPage from "./pages/ota/OTAReservationPage";
import PaymentTerminal from "./pages/payment/PaymentTerminal";
import POSHistoryPage from "./pages/pos/POSHistoryPage";
import POSPage from "./pages/pos/POSPage";
import PromotionManagementPage from "./pages/promotions/PromotionManagementPage";
import PromotionUsagePage from "./pages/promotions/PromotionUsagePage";
import AdvancedReport from "./pages/reports/AdvancedReport";
import EndShiftReport from "./pages/reports/EndShiftReport";
import EndShiftReportDetail from "./pages/reports/EndShiftReportDetail";
import TransactionListPage from "./pages/reports/TransactionListPage";
import ZReport from "./pages/reports/ZReport";
import RoomManagement from "./pages/rooms/RoomManagement";
import GeneralSettings from "./pages/settings/Settings";
import HouseKeepingPage from "./pages/supervisor/HouseKeepingPage";
import MembershipPage from './pages/guests/MembershipPage';

export default function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/consent-form" element={<ConsentFormPage />} />
          <Route path="/feedback-form" element={<FeedbackFormNew />} />
          <Route
            path="/feedback-form/:bookingId"
            element={<FeedbackFormNew />}
          />

          <Route element={<CommonComponents />}>
            <Route element={<PortectedRoutes />}>
              <Route path="/maintenance" element={<UnderMaintenance />} />
              <Route path="/payment-terminal" element={<PaymentTerminal />} />

              <Route
                element={
                  <PermissionPortectedRoutes
                    authorizedPermissions={['DASHBOARD']}
                  />
                }
              >
                <Route path="/" element={<HomePage />} />
              </Route>

              <Route
                element={
                  <PermissionPortectedRoutes
                    authorizedPermissions={['BOOKINGS']}
                  />
                }
              >
                <Route path="/upcoming-booking" element={<NewCheckinList />} />
                <Route
                  path="/express-booking"
                  element={<ExpressBookingPage />}
                />

                <Route
                  path="/upcoming-booking/:bookingId"
                  element={<GuestFolioModified />}
                />
                <Route
                  path="/upcoming-booking/:bookingId/payment"
                  element={<PaymentScreen />}
                />

                <Route
                  path="/current-inhouse-guest/:bookingId"
                  element={<GuestFolioModified />}
                />
                <Route
                  path="/current-inhouse-guest/:bookingId/payment"
                  element={<PaymentScreen />}
                />
                <Route
                  path="/room-list"
                  element={<RoomZone key={'room-list'} />}
                />
                <Route
                  path="/room-list/chart"
                  element={<GanttChartSection otaDirected={false} />}
                />
                <Route
                  path="/room-list/walk-in"
                  element={<GanttChartBookingInfo />}
                />

                <Route
                  path="/add-room/:bookingId"
                  element={<RoomZone key={'add-room'} />}
                />
              </Route>

              <Route
                element={
                  <PermissionPortectedRoutes authorizedPermissions={['OTA']} />
                }
              >
                <Route
                  path="/ota-reservation"
                  element={<OTAReservationPage />}
                />
              </Route>

              <Route path="/ota-logging" element={<OTALoggingPage />} />

              <Route
                element={
                  <PermissionPortectedRoutes
                    authorizedPermissions={['GUEST']}
                  />
                }
              >
                <Route
                  path="/current-inhouse-guest"
                  element={<NewCheckOutList />}
                />
                <Route
                  path="/feedback-statistic"
                  element={<FeedbackStatistic />}
                />
                <Route path="/guest-tracking" element={<GuestTrackingPage />} />
                <Route
                  path="/guest-tracking/:customerId"
                  element={<GuestTrackingBookings />}
                />
                <Route
                  path="/guest-tracking/:customerId/:bookingId"
                  element={<GuestFolioModified />}
                />
                <Route
                  path="/guest-tracking/:customerId/:bookingId/payment"
                  element={<PaymentScreen />}
                />
              </Route>

              {/* <Route
                element={
                  <PermissionPortectedRoutes
                    authorizedPermissions={["HOTELPOLICY"]}
                  />
                }
              ></Route> */}

              <Route
                element={
                  <PermissionPortectedRoutes
                    authorizedPermissions={['MERCH']}
                  />
                }
              >
                <Route path="/merchs" element={<MerchManagementPage />} />
              </Route>

              <Route
                element={
                  <PermissionPortectedRoutes
                    authorizedPermissions={['PROMO']}
                  />
                }
              >
                <Route
                  path="/promotions"
                  element={<PromotionManagementPage />}
                />
              </Route>

              <Route
                element={
                  <PermissionPortectedRoutes
                    authorizedPermissions={['PROMOUSAGE']}
                  />
                }
              >
                <Route
                  path="/promotions/usage"
                  element={<PromotionUsagePage />}
                />
              </Route>

              <Route
                element={
                  <PermissionPortectedRoutes authorizedPermissions={['POS']} />
                }
              >
                <Route path="/pos" element={<POSPage />} />
                <Route path="/pos/history" element={<POSHistoryPage />} />
                <Route path="/pos/pos-bookings" element={<UpcomingLockers />} />
                <Route
                  path="/pos/pos-bookings/:bookingId"
                  element={<GuestFolioModified />}
                />
                <Route
                  path="/pos/pos-bookings/:bookingId/payment"
                  element={<PaymentScreen />}
                />
              </Route>

              <Route
                element={
                  <PermissionPortectedRoutes
                    authorizedPermissions={['HOUSEKEEPING']}
                  />
                }
              >
                <Route path="/housekeeping" element={<HouseKeepingPage />} />
              </Route>

              <Route
                element={
                  <PermissionPortectedRoutes
                    authorizedPermissions={['ENDSHIFT']}
                  />
                }
              >
                <Route path="/shiftreport" element={<EndShiftReport />} />
                <Route
                  path="/shiftreport/:id/:name"
                  element={<EndShiftReportDetail />}
                />
              </Route>

              <Route
                element={
                  <PermissionPortectedRoutes authorizedPermissions={['ROOM']} />
                }
              >
                <Route path="/room-management" element={<RoomManagement />} />
                <Route path="/room/floor-plan" element={<FloorPlan />} />
              </Route>

              <Route
                element={
                  <PermissionPortectedRoutes
                    authorizedPermissions={['LOCKER']}
                  />
                }
              >
                {/* <Route path="/lockers" element={<LockerPage />} /> */}
                <Route path="/lockers" element={<NewLockerPage />} />
              </Route>

              <Route
                element={
                  <PermissionPortectedRoutes
                    authorizedPermissions={['SYSTEMSETTINGS']}
                  />
                }
              >
                <Route path="/role-list" element={<RoleList />} />
                {/* <Route path="/room/type-list" element={<TypeList />} /> */}
                {/* <Route path="/room/zone-list" element={<ZoneList />} /> */}

                <Route path="/accounts/admin-list" element={<AccountList />} />
                <Route path="/accounts/create" element={<CreateAccount />} />
                <Route path="/accounts/edit/:id" element={<EditAccount />} />

                <Route path="/general-settings" element={<GeneralSettings />} />

                <Route
                  path="/cashier-management"
                  element={<CashierManagementPage />}
                />
                <Route
                  path="/cashier-management"
                  element={<CashierManagementPage />}
                />
                <Route path="/membership" element={<MembershipPage />} />
              </Route>

              <Route
                element={
                  <PermissionPortectedRoutes
                    authorizedPermissions={['REPORTS']}
                  />
                }
              >
                <Route path="/sales" element={<TransactionListPage />} />
                <Route path="/z-report" element={<ZReport />} />
                <Route path="/ledgers" element={<Ledgers />} />
                <Route path="/advanced-report" element={<AdvancedReport />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}
