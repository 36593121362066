import {
	Box,
	Button,
	Grid,
	Modal,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import theme from "../../assets/theme/theme";
import { useEffect, useState } from "react";
import ManagementModalHeader from "../management/ManagementModalHeader";
import axiosInstance from "../../constants/axiosConfig";
import {
	IBooking,
	IBookingDraft,
	IBookingInfo,
} from "../../models/bookings/Booking";
import { store } from "../../redux/store";
import Notification from "../../utils/notificationConfig";

type Props = {
	isOpen: boolean;
	handleCloseModal: () => void;
	setCustomerBooked?: React.Dispatch<React.SetStateAction<string>>;
	setStepper?: React.Dispatch<React.SetStateAction<number>>;
	roomBookingInfo?: IBookingInfo;
	roomBookingArray?: IBookingDraft[];
	setBookingId: React.Dispatch<React.SetStateAction<string>>;
};

interface RoomBookingData {
	roomId: string;
	details: string;
}

const CustomerBookedModal = (props: Props) => {
	const token = store.getState().user.accessToken;

	const [customerBooked, setCustomerBooked] = useState<string>("");

	const roomBookingData: RoomBookingData[] =
		props.roomBookingArray?.map((booking) => ({
			roomId: booking.room_id,
			details: booking.room_type_details,
		})) ?? [];
	const handleSubmitRegister = () => {
		const formData = {
			checkInDatetime: props.roomBookingInfo?.checkInDate,
			checkOutDatetime: props.roomBookingInfo?.checkOutDate,
			adult: props.roomBookingInfo?.adultPax,
			child: props.roomBookingInfo?.childrenPax,
			platformId: props.roomBookingInfo?.platformId,
			otaCode: props.roomBookingInfo?.otaRefNo,
			details: "",
			customerBooked: customerBooked,
			rooms: roomBookingData,
		};

		axiosInstance
			.post("/bookings/register-no-pic/", formData, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
				props.setBookingId(res.data.data.bookingId);
				props.handleCloseModal();
				props.setStepper?.(3);
				Notification.success("Submitted Successfully");
			})
			.catch((error) => {
				let errorMessage = "An error occurred while processing your request.";

				if (error.response.data.message.customerBooked) {
					errorMessage =
						error.response.data.message.customerBooked[0] || errorMessage;
				} else if (error.response.data.message) {
					errorMessage = error.response.data.message;
				}

				Notification.failed(errorMessage);
			});
	};

	return (
		<Modal open={props.isOpen} onClose={props.handleCloseModal}>
			<Box
				width={"700px"}
				maxHeight={"600px"}
				border={`3px solid ${theme.palette.primary.main}`}
				sx={{
					position: "absolute" as "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					backgroundColor: "black",
					borderRadius: 1,
				}}>
				<ManagementModalHeader
					title="Customer Booked"
					handleModalClose={props.handleCloseModal}
				/>
				<Box
					display={"flex"}
					height={"150px"}
					bgcolor={"black"}
					justifyContent={"start"}
					alignItems={"center"}
					paddingX={2}>
					<Typography
						variant="h5"
						style={{ whiteSpace: "nowrap", marginRight: "10px" }}>
						Customer booked name
					</Typography>
					<TextField
						id="customer-booked"
						label="Customer Booked"
						variant="outlined"
						size="small"
						fullWidth
						value={customerBooked}
						onChange={(event) => setCustomerBooked(event.target.value)}
					/>
				</Box>

				<Box
					display={"flex"}
					height={"50px"}
					bgcolor={theme.palette.background.paper}
					justifyContent={"end"}
					padding={1}
					borderTop={2}
					borderColor={theme.palette.primary.main}>
					<Stack direction={"row"} spacing={1}>
						<Button variant={"outlined"} onClick={props.handleCloseModal}>
							Cancel
						</Button>
						<Button
							variant="outlined"
							onClick={() => {
								handleSubmitRegister();
								props.setCustomerBooked?.(customerBooked);
							}}>
							Submit
						</Button>
					</Stack>
				</Box>
			</Box>
		</Modal>
	);
};

export default CustomerBookedModal;
