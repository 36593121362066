import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { useEffect, useState } from "react";
import theme from "../../assets/theme/theme";
import LuggageIcon from "@mui/icons-material/Luggage";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddIcon from "@mui/icons-material/Add";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import CustomDataGrid from "../global/grid/CustomDataGrid";
import Notification from "../../utils/notificationConfig";
import axiosInstance from "../../constants/axiosConfig";
import { BookingResult } from "../../pages/booking/interface";
import { filterTableData } from "../../utils/functions";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Delete } from "@mui/icons-material";
import { format } from "date-fns";

type Props = {
  isAddLockerModalOpen: boolean;
  handleCloseAddLockerModal: () => void;
  guestFolioData: BookingResult;
};

type ILocker = {
  id: string;
  details: string;
  lockerCode: string;
  zone: string;
  roomZone: string;
  roomCode: string;
};

type ILockerZone = {
  details: string;
  isArchive: boolean;
  name: string;
  zoneId: string;
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "68%",
  height: "90%",
  bgcolor: "black",
  border: 2,
  borderColor: theme.palette.primary.main,
};

function AddLockerModal({
  isAddLockerModalOpen,
  handleCloseAddLockerModal,
  guestFolioData,
}: Props) {
  const token = useSelector((state: RootState) => state.user.accessToken);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [allLockerData, setAllLockerData] = useState<any>([]);
  const [displayLockerData, setDisplayLockerData] = useState<any>([]);
  const [tableLockerData, setTableLockerData] = useState<any>([]);
  const [selectedLocker, setSelectedLocker] = useState<ILocker[]>([]);
  const [roomAssignation, setRoomAssignation] = useState(0);
  const [searchBarValue, setSearchBarValue] = useState<string>("");
  const [allLockerZone, setAllLockerZone] = useState<ILockerZone[]>([]);
  const [filterValue, setFilterValue] = useState("All");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const removeLocker = (index: number) => {
    setSelectedLocker((prevLockers) => {
      if (index >= 0 && index < prevLockers.length) {
        const updatedItems = [
          ...prevLockers.slice(0, index),
          ...prevLockers.slice(index + 1),
        ];
        return updatedItems;
      } else {
        console.error("Invalid index provided for removal.");
        return prevLockers;
      }
    });
  };

  const handleEnterSearchKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (searchBarValue) {
      if (event.key === "Enter") {
        if (filterValue === "All") {
          setDisplayLockerData(
            displayLockerData.filter(
              (item: any) =>
                item.code.toLowerCase() === searchBarValue.toLowerCase()
            )
          );
        } else {
          setDisplayLockerData(
            displayLockerData.filter(
              (item: any) =>
                item.code.toLowerCase() === searchBarValue.toLowerCase() &&
                item.zone.name === filterValue
            )
          );
        }
      }
    } else {
      if (event.key === "Enter") {
        if (filterValue === "All") {
          setDisplayLockerData(allLockerData);
        } else {
          setDisplayLockerData(
            allLockerData.filter((item: any) => item.zone.name === filterValue)
          );
        }
      }
    }
  };

  const handleSearchValueOnChange = (value: string) => {
    setSearchBarValue(value);
  };

  const handleClearAll = () => {
    setRoomAssignation(0);
    setSelectedLocker([]);
  };

  const handleSelectRoom = (index: number) => {
    setRoomAssignation(index);
  };

  const handleFilterOnChange = (e: any) => {
    setFilterValue(e.target.value);
  };

  const registerAddLocker = () => {
    if (selectedLocker.length > 0) {
      const roomThatHasLocker = guestFolioData.lockerBookings.map(
        (locker) => locker.roomCode
      );
      const assignRoomLocker = selectedLocker.map((locker) => locker.roomCode);
      const combinedRooms = Array.from(
        new Set([...roomThatHasLocker, ...assignRoomLocker])
      );

      const bookingRooms = guestFolioData.roomBookings.map(
        (roomBooking) => roomBooking.roomCode
      );
      if (JSON.stringify(combinedRooms) !== JSON.stringify(bookingRooms)) {
        Notification.failed(
          "Caught you lacking, buddy. You forgot to assign a room with a locker."
        );
        return;
      }

      const lockerEndRent = new Date(
        guestFolioData.bookingStatus[0].checkInDatetime
      );
      lockerEndRent.setHours(lockerEndRent.getHours() + 3);
      const payload = {
        startRent: guestFolioData.bookingStatus[0].checkInDatetime,
        endRent: lockerEndRent.toISOString(),
        bookingId: guestFolioData.bookingId,
        lockers: selectedLocker.map((item) => {
          return {
            lockerId: item.id,
            roomCode: item.roomCode,
          };
        }),
      };
      axiosInstance
        .post("/bookings/v1/locker/", payload, config)
        .then((res) => {
          if (res.status === 200) {
            Notification.success("lockers added successfully");
          }
        })
        .catch((error) => {
          Notification.failed(error.toString());
        })
        .finally(() => {
          handleCloseAddLockerModal();
        });
    }
  };

  const getAllLockerZone = () => {
    axiosInstance
      .get("/lockers/zone", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const activeLocker = res.data.filter(
            (data: ILockerZone) => data.isArchive !== true
          );
          setAllLockerZone(activeLocker);
        }
      })
      .catch((error) => {
        Notification.failed(error.toString());
      });
  };

  const fetchLockerData = () => {
    setIsLoading(true);
    axiosInstance
      .get("/lockers/get-all-lockers", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setAllLockerData(res.data.data);
        }
      })
      .catch((error) => {
        Notification.failed("something Wrong, please try again later.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchLockerData();
    getAllLockerZone();
  }, []);

  useEffect(() => {
    setDisplayLockerData(allLockerData);
  }, [allLockerData]);

  useEffect(() => {
    setTableLockerData(displayLockerData);
  }, [displayLockerData]);

  useEffect(() => {
    if (filterValue === "All") {
      setDisplayLockerData(allLockerData);
    } else {
      setDisplayLockerData(
        allLockerData.filter((item: any) => item.zone.name === filterValue)
      );
    }
  }, [filterValue]);

  const handleAddOnClick = (params: any) => {
    if (guestFolioData.roomBookings) {
      if (!selectedLocker.some((locker) => locker.id === params.row.id)) {
        params.row.roomCode =
          guestFolioData.roomBookings[roomAssignation]?.roomCode ?? "";
        params.row.roomZone =
          guestFolioData.roomBookings[roomAssignation]?.roomZone ?? "";
        setSelectedLocker([...selectedLocker, params.row]);
        // const roomOrder = roomAssignation + 1;
        // if (roomOrder < guestFolioData.roomBookings.length) {
        //   setRoomAssignation(roomOrder);
        // } else {
        //   setRoomAssignation(0);
        // }
      } else {
        Notification.failed("Please choose another locker");
      }
    }
  };

  const dataGridColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "id",
      hideable: false,
    },
    {
      field: "lockerCode",
      headerName: "Locker ID",
      flex: 1,
    },
    {
      field: "zone",
      headerName: "Zone",
      flex: 1,
    },
    {
      field: "details",
      headerName: "Remarks",
      flex: 1,
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (params: any) => (
        <IconButton
          onClick={() => {
            handleAddOnClick(params);
          }}
        >
          <AddIcon sx={{ color: theme.palette.primary.main }} />
        </IconButton>
      ),
    },
  ];

  const AddedLockers = (
    lockerCode: string,
    lockerZone: string,
    roomCode: string,
    roomZone: string,
    index: number,
    removeLocker: (index: number) => void
  ) => {
    return (
      <>
        <Grid container width={"100%"} border={2} borderColor={"white"}>
          <Grid
            item
            md={3}
            xs={3}
            sm={3}
            borderRight={2}
            borderColor={"white"}
            padding={"1.3em"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LuggageIcon fontSize={"large"} />
          </Grid>
          <Grid
            item
            md={7.5}
            sm={7.5}
            xs={7.5}
            padding={"0.3em"}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
            >
              <Typography fontSize={"1rem"}>
                {lockerCode}, {lockerZone}
              </Typography>
              <Typography>
                {roomCode}, {roomZone}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            md={1.5}
            sm={1.5}
            xs={1.5}
            padding={"0.3em"}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton onClick={() => removeLocker(index)}>
              <Delete color="error" />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const getRowClassName = (params: GridRowParams) => {
    return params.row.id % 2 === 0 ? "even" : "odd";
  };

  const SelectedRooms = (
    roomCode: string,
    roomZone: string,
    roomType: string,
    maxPax: string | undefined,
    checkIn: string | null,
    checkOut: string | null,
    borderColor: string | undefined,
    selectedRoom: number,
    index: number,
    handleChangeRoom: (index: number) => void
  ) => {
    return (
      <ButtonBase onClick={() => handleChangeRoom(index)}>
        <Grid
          container
          width={"100%"}
          border={selectedRoom === index ? 3 : 2}
          borderColor={borderColor}
        >
          <Grid
            item
            md={3}
            xs={3}
            sm={3}
            borderRight={2}
            borderColor={borderColor}
            padding={"1.3em"}
            sx={{
              display: "flex",
              bgcolor: selectedRoom === index ? borderColor : "",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"h3"}
              color={selectedRoom === index ? "black" : "white"}
            >
              {roomType[0]}
            </Typography>
          </Grid>
          <Grid
            item
            md={9}
            sm={9}
            xs={9}
            padding={"0.3em"}
            sx={{
              display: "flex",
              bgcolor: selectedRoom === index ? borderColor : "",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                fontSize={"1rem"}
                color={selectedRoom === index ? "black" : "white"}
              >
                {roomCode}, {roomZone}
              </Typography>
              <Typography color={selectedRoom === index ? "black" : "white"}>
                {maxPax}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                fontSize={"0.8rem"}
                color={selectedRoom === index ? "black" : "white"}
              >
                {checkIn ? format(new Date(checkIn), "dd/MM/yyyy HH:mm") : ""}
              </Typography>
              <Typography
                fontSize={"0.8rem"}
                textAlign={"end"}
                color={selectedRoom === index ? "black" : "white"}
              >
                {checkOut ? format(new Date(checkOut), "dd/MM/yyyy HH:mm") : ""}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </ButtonBase>
    );
  };
  return (
    <Modal
      open={isAddLockerModalOpen}
      onClose={handleCloseAddLockerModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box sx={modalStyle}>
        <Stack
          height={"7%"}
          borderBottom={2}
          borderColor={theme.palette.primary.main}
          direction={"row"}
          paddingX={"1em"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ background: "#232323" }}
        >
          <Typography variant={"h3"} color={theme.palette.primary.main}>
            Locker Add
          </Typography>
          <IconButton sx={{ padding: 0 }} onClick={handleCloseAddLockerModal}>
            <HighlightOffIcon
              fontSize={"medium"}
              sx={{ color: theme.palette.primary.main }}
            />
          </IconButton>
        </Stack>
        <Grid container height={"83%"} display={"flex"}>
          <Grid
            item
            md={4}
            sm={6}
            xs={6}
            borderRight={2}
            borderColor={theme.palette.primary.main}
            sx={{ height: "100%", flexBasis: "auto", overflowY: "auto" }}
          >
            <Stack
              height={"40%"}
              borderBottom={1}
              borderColor={theme.palette.primary.main}
              padding={"1em"}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant={"h4"}>Rooms</Typography>
                <Typography variant={"h5"} textAlign={"end"}>
                  {guestFolioData.bookingNo}
                </Typography>
              </Stack>
              <Stack
                marginTop={2}
                maxHeight={"100%"}
                spacing={2}
                sx={{ overflowY: "auto" }}
              >
                {guestFolioData.roomBookings?.map((item, index) => {
                  return SelectedRooms(
                    item.roomCode,
                    item.roomZone,
                    item.roomType,
                    item.roomTypeDetails,
                    item.actualCheckinDateTime,
                    item.actualCheckoutDateTime,
                    item.roomTypeColorTags,
                    roomAssignation,
                    index,
                    handleSelectRoom
                  );
                })}
              </Stack>
            </Stack>
            <Stack height={"60%"} padding={"1em"}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant={"h4"}>Added Lockers</Typography>
                <Button
                  onClick={handleClearAll}
                  sx={{ color: theme.palette.primary.main }}
                >
                  Clear All
                </Button>
              </Stack>
              <Stack
                justifyContent={"flex-start"}
                spacing={2}
                sx={{ overflowY: "auto" }}
              >
                {selectedLocker.map((item, index) => {
                  return AddedLockers(
                    item.lockerCode,
                    item.zone,
                    item.roomCode,
                    item.roomZone,
                    index,
                    removeLocker
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={8} sm={6} xs={6} height={"93%"}>
            <Stack
              height={"9%"}
              borderBottom={2}
              direction={"row"}
              paddingX={"1em"}
              alignItems={"center"}
              borderColor={theme.palette.primary.main}
              sx={{ backgroundColor: "#232323" }}
            >
              <Typography variant={"h3"} color={theme.palette.primary.main}>
                Locker
              </Typography>
            </Stack>
            <Stack
              height={"18%"}
              borderBottom={2}
              borderColor={theme.palette.primary.main}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <TextField
                size={"small"}
                onChange={(e) => {
                  const newRow = filterTableData(
                    displayLockerData,
                    e.target.value
                  );
                  if (
                    newRow.length === 1 &&
                    newRow[0].locker_id === e.target.value.toLowerCase()
                  ) {
                    handleAddOnClick({
                      row: {
                        details: newRow[0].details,
                        lockerCode: newRow[0].code,
                        id: newRow[0].locker_id,
                        roomCode: "",
                        roomZone: "",
                        zone: newRow[0].zone.name,
                      },
                    });
                  } else {
                    setTableLockerData(newRow);
                  }
                }}
                disabled={isLoading}
                placeholder={"Enter locker code"}
                variant={"outlined"}
                sx={{
                  "& .MuiInputBase-input": {
                    borderRadius: 0,
                    border: 2,
                    borderColor: theme.palette.primary.main,
                  },
                  width: "90%",
                }}
              />
            </Stack>
            <Stack
              height={"15%"}
              borderBottom={2}
              borderColor={theme.palette.primary.main}
            >
              <Grid container width={"100%"} height={"100%"}>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sm={3}
                  paddingX={2}
                  borderRight={2}
                  borderColor={theme.palette.primary.main}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FilterAltIcon sx={{ color: "white", fontSize: "2rem" }} />
                  <Typography variant={"h4"} color={"white"}>
                    Filter
                  </Typography>
                </Grid>
                <Grid item md={9} sm={9} xs={9}>
                  <Stack
                    width={"100%"}
                    height={"50%"}
                    direction={"row"}
                    alignItems={"center"}
                    paddingX={2}
                    borderBottom={2}
                    borderColor={theme.palette.primary.main}
                  >
                    <Typography variant={"h5"}>Zone</Typography>
                  </Stack>
                  <Stack width={"100%"} height={"50%"}>
                    <Select
                      size={"small"}
                      value={filterValue}
                      onChange={(e) => handleFilterOnChange(e)}
                      disabled={isLoading}
                      sx={{
                        "& .MuiSelect-select": {
                          width: "100%",
                          borderRadius: 0,
                          border: 0,
                          borderColor: theme.palette.primary.main,
                        },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 0,
                        },
                      }}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {allLockerZone.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            <Stack
              direction={"row"}
              height={"64%"}
              padding={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                <CustomDataGrid
                  rows={tableLockerData.map((item: any) => {
                    return {
                      id: item.locker_id,
                      lockerCode: item.code,
                      zone: item.zone.details,
                      details: item.details,
                    };
                  })}
                  columns={dataGridColumns}
                  columnVisibilityModel={{
                    id: false,
                  }}
                  getRowClassName={getRowClassName}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
        <Stack
          width={"100%"}
          height={"10%"}
          borderTop={2}
          borderColor={theme.palette.primary.main}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          paddingX={3}
          sx={{ background: "#232323" }}
        >
          <Button
            onClick={registerAddLocker}
            sx={{
              paddingX: 3,
              paddingY: 1,
              backgroundColor: theme.palette.primary.main,
              color: "black",
              fontWeight: 700,
            }}
          >
            Continue
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

export default AddLockerModal;
