import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import PageTitle from "../../components/global/PageTitle";
import theme from "../../assets/theme/theme";
import { Bar, Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import Notification from "../../utils/notificationConfig";
import axiosInstance from "../../constants/axiosConfig";
import { store } from "../../redux/store";
import { FeedbackDataInterface } from "../booking/interface";
import StarIcon from "@mui/icons-material/Star";
import {
  ArrowDropDownIcon,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { filterTableData, stringDateToEpoch } from "../../utils/functions";

interface Booking {
  bookingNumber: number;
  guestName: string;
  rating: number;
  bookingDescription: string;
  selectedRoomNumber: number;
}

interface TableProps {
  bookings: Booking[];
}
interface IChartData {
  emptyFeedback: string;
  filledFeedback: string;
  chartData: [
    {
      date: string;
      averageRate: string;
    }
  ];
}
const FeedbackStatistic: React.FC = () => {
  const [bookings, setBookings] = useState<Booking[]>([
    {
      bookingNumber: 1001,
      guestName: "John Doe",
      rating: 4.5,
      bookingDescription: "Family vacation",
      selectedRoomNumber: 102,
    },
    {
      bookingNumber: 1002,
      guestName: "Jane Smith",
      rating: 3.8,
      bookingDescription: "Business trip",
      selectedRoomNumber: 215,
    },
    {
      bookingNumber: 1003,
      guestName: "Robert Johnson",
      rating: 4.2,
      bookingDescription: "Anniversary celebration",
      selectedRoomNumber: 301,
    },
    {
      bookingNumber: 1004,
      guestName: "Emily White",
      rating: 5.0,
      bookingDescription: "Honeymoon getaway",
      selectedRoomNumber: 502,
    },
    {
      bookingNumber: 1005,
      guestName: "Michael Brown",
      rating: 4.7,
      bookingDescription: "Weekend retreat",
      selectedRoomNumber: 401,
    },
    // Add more booking data as needed
  ]);
  const token = store.getState().user.accessToken;
  const [datePeriod, setDatePeriod] = useState<string>("daily");
  const [rating, setRating] = useState<string>("");
  const [chartData, setChartData] = useState<IChartData>();
  const [feedbackData, setFeedbackData] = useState<FeedbackDataInterface[]>([]);
  const [feedbackFilteredData, setFeedbackFilteredData] = useState<
    FeedbackDataInterface[]
  >([]);
  const [searchBarValue, setSearchBarValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>();
  useEffect(() => {
    axiosInstance
      .get(`/bookings/feedback/graph`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          dateFilter: startDate ? stringDateToEpoch(startDate.toString()) : "",
          period: datePeriod,
        },
      })
      .then((res) => {
        setChartData(res.data.data);
      })
      .catch((res) => Notification.failed("Something wrong"));
  }, [datePeriod, startDate, token]);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`bookings/feedback`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          rating: rating,
          dateFilter: startDate ? stringDateToEpoch(startDate.toString()) : "",
          period: datePeriod,
        },
      })
      .then((res) => {
        setFeedbackData(res.data.data);
        setFeedbackFilteredData(res.data.data);
      })
      .catch((res) => {
        Notification.failed("Something wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [rating, datePeriod, startDate, token]);

  const showPieChartData = {
    labels: ["Completed Feedback", "Pending Feedback"],
    datasets: [
      {
        data: [chartData?.filledFeedback, chartData?.emptyFeedback],
        backgroundColor: ["#4CAF50", "#2196F3"],
      },
    ],
  };

  const labels = Array.from({ length: 5 }, (_, index) => {
    if (chartData?.chartData[index]) {
      return chartData.chartData[index].date;
    } else {
      return "No Data";
    }
  });
  const averageRate = chartData?.chartData
    .slice(0, 5)
    .map((dataPoint) => dataPoint.averageRate);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Average Rating",
        data: averageRate,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const handleStartDateChange = (startDate: Date) => {
    setStartDate(startDate);
  };

  const [selectedRoomType, setSelectedRoomType] = useState<string>("standard");

  const handleRoomTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = event.target.value as string;
    setSelectedRoomType(value);
  };

  useEffect(() => {
    const unfilteredData = [...feedbackData];

    const searchedData = filterTableData(unfilteredData, searchBarValue);

    setFeedbackFilteredData(searchedData);
  }, [searchBarValue, feedbackData]);

  return (
    <Box padding={5}>
      <PageTitle title="Guest Feedback" />

      <Stack spacing={2}>
        <Box
          bgcolor={"#141414"}
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            direction={"row"}
            spacing={2}
            padding={2}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Typography>Feedback</Typography>
            <TextField
              sx={{ width: 350 }}
              label={"Search"}
              value={searchBarValue}
              onChange={(e) => setSearchBarValue(e.target.value)}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box display={"flex"} alignItems={"center"}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(e) => handleStartDateChange(e as Date)}
                  format="dd MMM yyyy"
                  slots={{
                    openPickerIcon: ArrowDropDownIcon,
                  }}
                  slotProps={{
                    layout: {
                      sx: {
                        ".MuiDateCalendar-root": {
                          color: theme.palette.primary.main,
                          borderRadius: 2,
                          borderWidth: 1,
                          borderColor: theme.palette.primary.main,
                          border: "1px solid",
                          backgroundColor: theme.palette.background.paper,
                        },
                        ".MuiButtonBase-root": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiTypography-root": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiPickersCalendarHeader-root": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiPickersMonth-monthButton": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiPickersCalendarHeader-label": {
                          color: theme.palette.primary.main,
                        },
                        ".Mui-selected": {
                          color: "black",
                        },
                        ".MuiPickersYear-yearButton": {
                          color: theme.palette.primary.main,
                        },
                      },
                    },
                    textField: {
                      variant: "filled",
                      inputProps: {
                        style: {
                          color: "white",
                          borderRadius: 1,
                          border: 2,
                        },
                      },
                      sx: {
                        width: "150px",
                        backgroundColor: "#232323",
                        border: "1px solid #148978",
                        borderRadius: 1,
                      },
                    },
                    openPickerButton: {
                      sx: {
                        color: "white",
                        marginTop: "1.8px",
                        zIndex: 1,
                      },
                    },
                    openPickerIcon: {
                      sx: {
                        width: "1em",
                        height: "1em",
                        zIndex: 1,
                      },
                    },
                  }}
                />
              </Box>
            </LocalizationProvider>
            <FormControl sx={{ width: "30%" }}>
              <InputLabel id="periodLabel">Period</InputLabel>
              <Select
                labelId="periodLabel"
                id="period"
                value={datePeriod}
                onChange={(event) => setDatePeriod(event.target.value)}
                label="Select Period"
              >
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
              </Select>
            </FormControl>{" "}
            <FormControl sx={{ width: "10%" }}>
              <InputLabel id="ratingLabel">Rating</InputLabel>
              <Select
                labelId="ratingLabel"
                id="rating"
                value={rating}
                onChange={(event) => setRating(event.target.value)}
                label="Select Rating"
              >
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((rating) => (
                  <MenuItem key={rating} value={rating}>
                    {rating}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>{" "}
          </Stack>
        </Box>

        <Box bgcolor={"#141414"}>
          <Accordion sx={{ backgroundColor: "#141414" }} defaultExpanded>
            <AccordionSummary>
              <Typography variant="h2">Graphs</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction={"row"}>
                <Box width={"50%"}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>Feedback Ratio</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {" "}
                      <Pie data={showPieChartData} />
                    </Grid>
                    <Grid item xs={6}>
                      {" "}
                    </Grid>
                  </Grid>
                </Box>
                <Box width={"50%"}>
                  <Typography>Rating</Typography>
                  <Bar data={data}></Bar>
                </Box>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          border={`1px solid ${theme.palette.primary.main}`}
          borderRadius={"10px"}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: theme.palette.primary.main,
                      borderBottom: `2px solid ${theme.palette.primary.main}`,
                    }}
                  >
                    Booking Number
                  </TableCell>
                  <TableCell
                    style={{
                      color: theme.palette.primary.main,
                      borderBottom: `2px solid ${theme.palette.primary.main}`,
                    }}
                  >
                    Guest Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: theme.palette.primary.main,
                      borderBottom: `2px solid ${theme.palette.primary.main}`,
                    }}
                  >
                    Rating
                  </TableCell>
                  <TableCell
                    style={{
                      color: theme.palette.primary.main,
                      borderBottom: `2px solid ${theme.palette.primary.main}`,
                    }}
                  >
                    Booking Description
                  </TableCell>
                  <TableCell
                    style={{
                      color: theme.palette.primary.main,
                      borderBottom: `2px solid ${theme.palette.primary.main}`,
                    }}
                  >
                    Selected Room Number
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Box
                        sx={{
                          height: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {feedbackFilteredData.map((booking) => (
                      <TableRow key={booking.bookingId}>
                        <TableCell
                          style={{
                            color: "white",
                            borderBottom: `2px solid ${theme.palette.secondary.main}`,
                          }}
                        >
                          {booking.bookingNo}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "white",
                            borderBottom: `2px solid ${theme.palette.secondary.main}`,
                          }}
                        >
                          {booking.customerName}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "white",
                            borderBottom: `2px solid ${theme.palette.secondary.main}`,
                          }}
                        >
                          {booking.rating}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "white",
                            borderBottom: `2px solid ${theme.palette.secondary.main}`,
                          }}
                        >
                          {booking.feedback}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "white",
                            borderBottom: `2px solid ${theme.palette.secondary.main}`,
                          }}
                        >
                          {booking.roomSelected}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Stack>
    </Box>
  );
};

export default FeedbackStatistic;
