import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// types and interfaces
interface ConfirmationModalProps {
    title : string;
    text? : string;
    open : boolean;
    onClose : (stat : boolean) => void;
    onAgree : () => void;
}



export default function ConfirmationModal(props : ConfirmationModalProps) {

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setIsOpen(true);
    }

    const handleClose = () => {
        setIsOpen(false);
        props.onClose(false);
    }

    useEffect(() => {
        if (props.open) {
            handleOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open])

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.text ? props.text : ""}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={() => {
                        props.onAgree();
                        setIsOpen(false);
                        props.onClose(false);
                    }} 
                    autoFocus
                >
                    Yes
                </Button>
                <Button 
                    onClick={handleClose}
                    variant='contained'
                >No</Button>
            </DialogActions>
        </Dialog>
    )
}