import { Add, Remove } from "@mui/icons-material";
import { Box, ButtonBase, Stack, Typography, useTheme } from "@mui/material";
import { IMerch } from "../../types/merch";
import { IPaymentItem } from "../../models/payment/PaymentInterface";
import { useEffect, useState } from "react";
import Notification from "../../utils/notificationConfig";

interface IPOSItemProps {
  merch: IMerch;
  shoppingCart: IPaymentItem[];
  handleAddToShoppingCart: (propsItem: IMerch, newQuantity: number) => void;
}

const POSItem = (props: IPOSItemProps) => {
  const theme = useTheme();

  const [quantity, setQuantity] = useState<number>(0);

  const lookUpQuantity = () => {
    const selectedItem = props.shoppingCart.find(
      (item) => item.itemId === props.merch.merchId
    );

    if (selectedItem) {
      return setQuantity(selectedItem.quantity ? selectedItem.quantity : 0);
    } else {
      return setQuantity(0);
    }
  };

  useEffect(() => {
    lookUpQuantity();
  }, [props.shoppingCart]);

  return (
    <Box width={"200px"}>
      <Box
        height={"100px"}
        bgcolor={theme.palette.background.paper}
        border={1}
        padding={1}
      >
        <Stack
          direction={"column"}
          justifyContent={"space-between"}
          height={"100%"}
        >
          <Typography variant="h4">{props.merch.itemName}</Typography>
          <Stack direction={"column"} spacing={1}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography width={"80px"}>Non-Guest</Typography>
              <Typography color={"primary"}>MYR</Typography>
              <Typography color={"primary"}>
                {Number(props.merch.nonGuestSum).toFixed(2)}
              </Typography>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography width={"80px"}>Guest</Typography>
              <Typography color={"primary"}>MYR</Typography>
              <Typography color={"primary"}>
                {props.merch.sum?.toFixed(2)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Stack direction={"row"} width={"100%"}>
        <ButtonBase
          onClick={() => {
            if (quantity > 0) {
              props.handleAddToShoppingCart(props.merch, quantity - 1);
            }
          }}
          sx={{ width: "25%" }}
        >
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            border={1}
            borderTop={0}
            sx={{ aspectRatio: 1 / 1 }}
          >
            <Remove />
          </Box>
        </ButtonBase>
        <Box
          display={"flex"}
          width={"50%"}
          justifyContent={"center"}
          alignItems={"center"}
          borderBottom={1}
          bgcolor={theme.palette.background.default}
        >
          {quantity}
        </Box>
        <ButtonBase
          onClick={() => {
            if (
              quantity <
              props.merch.stockInQuantity - props.merch.stockOutQuantity
            ) {
              props.handleAddToShoppingCart(props.merch, quantity + 1);
            } else {
              Notification.warning(`No more stock for ${props.merch.itemName}`);
            }
          }}
          sx={{ width: "25%" }}
        >
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            border={1}
            borderTop={0}
            sx={{ aspectRatio: 1 / 1 }}
          >
            <Add />
          </Box>
        </ButtonBase>
      </Stack>
    </Box>
  );
};

export default POSItem;
