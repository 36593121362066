import {
  Box,
  useTheme,
  Table,
  TableBody,
  TableContainer,
  Paper,
  TablePagination,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Grid,
} from "@mui/material";
import { useState, useEffect } from "react";
import HouseKeepingFilterBar from "./HouseKeepingFilterBar";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { StyledTableCell, StyledTableRow } from "../../constants/tableStyle";
import TableLoading from "../global/loading/TableLoading";
import axiosInstance from "../../constants/axiosConfig";
import { store } from "../../redux/store";
import BASE_API from "../../constants/api";
import { HouseKeepingStatusDataInterface } from "../../models/supervisor/supervisorInterface";
import Notification from "../../utils/notificationConfig";
import { ZoneInterface } from "../../models/rooms/roomsInterface";
import { filterTableData } from "../../utils/functions";
import { format } from "date-fns";

function secondsToHHMMSS(totalSeconds: number): string {
  let hours: number | string = Math.floor(totalSeconds / 3600);
  let minutes: number | string = Math.floor((totalSeconds - hours * 3600) / 60);
  let seconds: number | string = totalSeconds - hours * 3600 - minutes * 60;

  // Padding the values to ensure they are two digits
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return hours + ":" + minutes + ":" + seconds;
}

const HouseKeepingStatus = () => {
  const theme = useTheme();
  const token = store.getState().user.accessToken;
  const showSortByFilter = false;
  const filterTitle = "Status";

  const [filterExpanded, setFilterExpanded] = useState<boolean>(false);

  const [searchValueFilter, setSearchValueFilter] = useState<string>("");
  const [roomStatusFilter, setRoomStatusFilter] = useState<string[]>([]);
  const [zoneFilter, setZoneFilter] = useState<string[]>([]);
  const [zoneData, setZoneData] = useState<ZoneInterface[]>([]);
  const [sortBy, setSortBy] = useState<string>("");

  const [rows, setRows] = useState<HouseKeepingStatusDataInterface[]>([]);
  const [page, setPage] = useState(0);
  const [filteredRows, setFilteredRows] = useState<
    HouseKeepingStatusDataInterface[]
  >([]);
  const [roomStatus, setRoomStatus] = useState<string[]>([]);

  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  /* ------------------------- state change functions ------------------------- */
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    const fetchGetAllRoomStatus = () => {
      axiosInstance
        .get(`${BASE_API}/rooms/get-all-room-status/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setRoomStatus(response.data.data);
        });
    };
    fetchGetAllRoomStatus();
  }, [token]);

  useEffect(() => {
    const fetchHousekeepingStatus = async () => {
      setFetchLoading(true);
      await axiosInstance
        .get(`rooms/v1/rooms/?archived=false&withTime=true`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setRows(res.data);
            setFilteredRows(res.data);
          }
        });
      setFetchLoading(false);
    };

    fetchHousekeepingStatus();
  }, [token]);

  useEffect(() => {
    const fetchAllZones = async () => {
      await axiosInstance
        .get(`rooms/zone/get-all-zone`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setZoneData(res.data.data);
          }
        });
    };

    fetchAllZones();
  }, [token]);

  const handleFilterSubmit = async () => {
    const filteredZonesObject = zoneData.filter((item) =>
      zoneFilter.includes(item.zoneName)
    );
    const zoneIds = filteredZonesObject.map((item) => item.zoneId).join(":");
    setFetchLoading(true);
    await axiosInstance
      .get(`rooms/v1/rooms/?archived=false&withTime=true`, {
        params: {
          zoneId: zoneIds,
          roomStatus: roomStatusFilter.join(":"),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setRows(res.data);
          setFilteredRows(res.data);
          setPage(0);
        }
      });
    setFetchLoading(false);
  };

  const changeRoomStatus = async (room_id: string, new_status: string) => {
    const body = { room_id, new_status };
    await axiosInstance
      .put(`/rooms/change-room-status/`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          Notification.success(res.data.message);
        }
      });
  };

  const changeRoomRemarks = async (room_id: string, new_remarks: string) => {
    const body = { room_id, new_remarks };
    await axiosInstance
      .put(`/rooms/change-room-remarks/`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          Notification.success(res.data.message);
        }
      });
  };

  const updateState = (data: HouseKeepingStatusDataInterface) => (e: any) => {
    const newArray = filteredRows.map((item, i) => {
      if (data.roomId === item.roomId) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setFilteredRows(newArray);
  };

  const updateStatusState = (data: HouseKeepingStatusDataInterface, e: any) => {
    const newArray = filteredRows.map((item, i) => {
      if (data.roomId === item.roomId) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setFilteredRows(newArray);
  };

  useEffect(() => {
    setPage(0);
    const newRow = filterTableData(rows, searchValueFilter);
    setFilteredRows(newRow);
  }, [searchValueFilter, rows]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <HouseKeepingFilterBar
        filterExpanded={filterExpanded}
        setFilterExpanded={setFilterExpanded}
        searchValue={searchValueFilter}
        roomStatus={roomStatusFilter}
        zone={zoneFilter}
        sortBy={sortBy}
        setSearchValue={setSearchValueFilter}
        setRoomStatus={setRoomStatusFilter}
        setZone={setZoneFilter}
        setSortBy={setSortBy}
        handleSubmit={handleFilterSubmit}
        showSortByFilter={showSortByFilter}
        filterTitle={filterTitle}
      />
      <TableContainer
        component={Paper}
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          background: "black",
          my: 2,
        }}
      >
        <Table
          sx={{
            width: "95%",
            borderCollapse: "separate",
            borderSpacing: "0px 8px",
            alignSelf: "center",
          }}
          aria-label="simple table"
        >
          {fetchLoading ? (
            <TableLoading />
          ) : (
            <>
              {filteredRows.length > 0 && (
                <TableBody
                  sx={{ width: "80%", minHeight: "80%", overflowY: "auto" }}
                >
                  {filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow
                        key={row.roomId}
                        sx={{
                          my: 2,
                        }}
                      >
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{
                            backgroundColor: "#232323",
                            borderLeft: 5,
                            borderLeftColor: row.colorCode,
                            borderBottomColor: "#232323",
                            borderRadius: "5px 5px 5px 5px",
                          }}
                        >
                          <Stack
                            direction={{ lg: "row", sm: "column" }}
                            width={"100%"}
                            margin={"auto"}
                          >
                            <Stack
                              width={{ lg: "4%", sm: "50%" }}
                              paddingY={1}
                              marginX={2}
                              alignItems={"center"}
                              border={3}
                              borderColor={row.colorCode}
                            >
                              <Typography>{row.roomTypeDetails}</Typography>
                            </Stack>
                            <Stack
                              sx={{
                                width: {
                                  xs: "100%",
                                  lg: "20%",
                                },
                              }}
                              paddingY={1}
                              alignItems={"center"}
                              direction={"row"}
                              justifyContent={"space-between"}
                            >
                              <Typography variant="h3">
                                {row.roomCode}, {row.zone}
                              </Typography>
                              <CleaningServicesIcon fontSize="medium" />
                            </Stack>
                            <Stack
                              sx={{
                                width: {
                                  xs: "100%",
                                  lg: "40%",
                                },
                              }}
                            >
                              <TextField
                                variant="outlined"
                                sx={{
                                  height: "3em",
                                  mx: {
                                    xs: 0,
                                    lg: 2.5,
                                  },
                                  input: {
                                    background: "black",
                                    textTransform: "none",
                                  },
                                }}
                                name="remarks"
                                value={row.remarks}
                                onChange={updateState(row)}
                                InputProps={{
                                  endAdornment: (
                                    <Stack
                                      direction={"row"}
                                      width={{ lg: "15%", sm: "25%" }}
                                      border={1}
                                      borderColor={theme.palette.primary.main}
                                      borderRadius={2}
                                      alignItems={"center"}
                                      justifyContent={"center"}
                                    >
                                      <Button
                                        onClick={() =>
                                          changeRoomRemarks(
                                            row.roomId,
                                            row.remarks
                                          )
                                        }
                                        sx={{
                                          paddingX: "5px",
                                          paddingY: "3px",
                                        }}
                                      >
                                        <EditNoteIcon
                                          fontSize="medium"
                                          sx={{
                                            color: theme.palette.primary.main,
                                          }}
                                        />
                                        <Typography
                                          variant="h5"
                                          color={theme.palette.primary.main}
                                        >
                                          Save
                                        </Typography>
                                      </Button>
                                    </Stack>
                                  ),
                                  sx: {
                                    height: "3em",
                                    paddingRight: "1",
                                    background: "black",
                                    borderColor: theme.palette.primary.main,
                                  },
                                }}
                                inputProps={{
                                  style: {
                                    height: "3em",
                                    padding: "0 1em",
                                    border: "none",
                                  },
                                  placeholder: "Add any remarks here",
                                }}
                              />
                            </Stack>
                            <Stack
                              sx={{
                                width: {
                                  xs: "100%",
                                  lg: "25%",
                                },
                                marginTop: {
                                  xs: 1.5,
                                  lg: 0,
                                },
                              }}
                            >
                              <Select
                                size={"small"}
                                sx={{
                                  border: 2,
                                  borderRadius: "5px",
                                  width: "100%",
                                }}
                                name="status"
                                value={row.status}
                                onChange={(e) => {
                                  updateStatusState(row, e);
                                  changeRoomStatus(row.roomId, e.target.value);
                                }}
                              >
                                {roomStatus.map((status, i) => (
                                  <MenuItem key={i} value={status}>
                                    {status}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Stack>
                            <Stack
                              sx={{
                                width: {
                                  xs: "100%",
                                  lg: "5%",
                                },
                                marginTop: {
                                  xs: 1.5,
                                  lg: 0,
                                },
                              }}
                              justifyContent={"center"}
                            >
                              <Typography
                                variant="h5"
                                color={theme.palette.primary.main}
                              >
                                {secondsToHHMMSS(row.lastUpdatedSecond)}
                              </Typography>
                            </Stack>
                          </Stack>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              )}
              {filteredRows.length === 0 && (
                <TableBody
                  sx={{
                    width: "80%",
                    minHeight: "80%",
                    overflowY: "auto",
                  }}
                >
                  <StyledTableRow>
                    <StyledTableCell>Data not available</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              )}
            </>
          )}
        </Table>
        <TablePagination
          sx={{
            color: "white",
            backgroundColor: theme.palette.background.default,
            my: 2,
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default HouseKeepingStatus;
