import {
  Grid,
  Typography,
  TextField,
  Stack,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
} from "@mui/material";
import React from "react";
import theme from "../../assets/theme/theme";

type Props = {
  label: string;
  name: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  checked: boolean;
};

const ManagementFormSwitchButton = ({
  label,
  name,
  handleChange,
  checked,
}: Props) => {
  return (
    <Stack direction="row" width={"100%"}>
      <Stack
        sx={{
          width: "232px",
          border: `3px solid ${theme.palette.primary.main}`,
          padding: "0.5rem    ",
          height: "3em",
        }}
      >
        <Typography variant={"h4"} fontWeight={600}>
          {label}
        </Typography>
      </Stack>
      <Stack
        sx={{
          height: "3em",
          width: "100%",
          //flexWrap: "nowrap",
          border: `3px solid ${theme.palette.primary.main}`,
          borderLeft: "none",
          paddingX: "1.5em",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Switch
          sx={{
            //width: "100%",
            height: "3em",
            flexWrap: "nowrap",
            // border: `3px solid ${theme.palette.primary.main}`,
            // borderLeft: "none",
            //paddingX: "1.5em",
            // backgroundColor: theme.palette.background.default,
          }}
          name={name}
          checked={checked}
          onChange={handleChange}
        />
      </Stack>
    </Stack>
  );
};

export default ManagementFormSwitchButton;
