import {
  Backdrop,
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { ILockerRate } from "../../models/lockers/LockerInterface";
import { useFormik } from "formik";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import ManagementModalFormNumberField from "../management/ManagementModalFormNumberField";
import { convertTo2Decimals } from "../../utils/paymentFunction";

interface IAddOrEditLockerRateModal {
  selectedLockerRate: ILockerRate;
  openEditLockerRateModal: boolean;
  handleCloseEditLockerRateModal: VoidFunction;
  lockerRates: ILockerRate[];
  setLockerRates: React.Dispatch<React.SetStateAction<ILockerRate[]>>;
}

const formikInitialValue: ILockerRate = {
  hoursOfUsage: 0,
  isLatest: true,
  lockerRate: "",
  lockerRateId: "",
  lot: "",
  tiers: 0,
};

const AddOrEditLockerRateModal = (props: IAddOrEditLockerRateModal) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);

  const [lockerRate, setLockerRate] = useState<ILockerRate>(
    props.selectedLockerRate.lockerRateId
      ? props.selectedLockerRate
      : formikInitialValue
  );

  const formik = useFormik({
    initialValues: lockerRate,
    enableReinitialize: true,

    onSubmit: (values, { resetForm }) => {
      if (!checkIfDurationIsAvailable()) {
        Notification.failed(
          "Duration is duplicated, please select another Duration"
        );
        return;
      }
      const apiUrl = "/lockers-rate/";
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }

      const formData = {
        hours_of_usage: values.hoursOfUsage,
        locker_rate: values.lockerRate,
      };

      if (props.selectedLockerRate.lockerRateId) {
        axiosInstance
          .put(apiUrl + props.selectedLockerRate.lockerRateId + "/", formData, config)
          .then((res) => {
            Notification.success(res.data.message);
            props.handleCloseEditLockerRateModal();
            props.setLockerRates((prevLockerRate) => {
              const removedLockerRates = prevLockerRate.find(
                (lockerRates) =>
                  lockerRate.lockerRateId === lockerRates.lockerRateId
              );

              if (removedLockerRates) {
                const newLockerRates = prevLockerRate.filter(
                  (lockerRates) =>
                    lockerRate.lockerRateId !== lockerRates.lockerRateId
                );

                return [
                  {
                    ...values,
                    lockerRate: Number(values.lockerRate).toFixed(2),
                  },
                  ...newLockerRates,
                ];
              }
              return prevLockerRate;
            });
          })
          .catch((res) => {
            Notification.failed(res.response.data.error);
          });
        return;
      }
      axiosInstance
        .post(apiUrl, formData, config)
        .then((res) => {
          Notification.success(res.data.message);
          props.handleCloseEditLockerRateModal();
          props.setLockerRates((prevLockerRate) => {
            const newLockerZone: ILockerRate = {
              ...values,
              lockerRate: Number(values.lockerRate).toFixed(2),
              lockerRateId: res.data.id,
            };
            return [newLockerZone, ...prevLockerRate];
          });
        })
        .catch((res) => {
          Notification.failed(res.response.data.error);
        });
    },
  });

  useEffect(() => {
    setLockerRate(props.selectedLockerRate);
  }, [props.selectedLockerRate]);

  const checkIfDurationIsAvailable = () => {
    const isAvailable =
      props.lockerRates.filter(
        (lockerRate) =>
          lockerRate.hoursOfUsage === formik.values.hoursOfUsage &&
          lockerRate.isLatest
      ).length === 0 ||
      formik.values.hoursOfUsage === props.selectedLockerRate.hoursOfUsage;

    return isAvailable;
  };

  return (
    <Modal
      open={props.openEditLockerRateModal}
      onClose={props.handleCloseEditLockerRateModal}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box
        width={"600px"}
        minWidth={"38em"}
        border={`3px solid ${theme.palette.primary.main}`}
        margin={"auto"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
        }}
      >
        <Box
          height={"40px"}
          paddingY={1}
          paddingX={2}
          sx={{
            backgroundColor: theme.palette.background.default,
          }}
          borderBottom={`3px solid ${theme.palette.primary.main}`}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Typography variant={"h3"} color={theme.palette.primary.main}>
            {props.selectedLockerRate.lockerRateId
              ? "Edit Locker Rate"
              : "New Locker Rate"}
          </Typography>
          <IconButton onClick={() => props.handleCloseEditLockerRateModal()}>
            <HighlightOffRoundedIcon
              sx={{
                color: theme.palette.primary.main,
                fontSize: "24px",
              }}
            />
          </IconButton>
        </Box>
        <Box padding={3}>
          <Typography variant="h2" marginBottom={2}>
            {props.selectedLockerRate.lockerRateId
              ? "Edit Locker Rate"
              : "New Locker Rate"}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Stack direction={"column"} spacing={2}>
              <ManagementModalFormNumberField
                label={"Price (MYR)"}
                value={Number(formik.values.lockerRate)}
                name={"lockerRate"}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                textFieldType={"number"}
                startAdornment={
                  <Typography fontWeight={600} paddingLeft={"1.5em"}>
                    MYR
                  </Typography>
                }
                setFieldValue={formik.setFieldValue}
              />
              <ManagementModalFormNumberField
                label={"Duration (Hour)"}
                value={Number(formik.values.hoursOfUsage)}
                name={"hoursOfUsage"}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                textFieldType={"number"}
                borderColor={
                  checkIfDurationIsAvailable()
                    ? theme.palette.primary.main
                    : "red"
                }
                setFieldValue={formik.setFieldValue}
              />
              {!checkIfDurationIsAvailable() && (
                <Box alignSelf={"end"}>
                  <Typography color={"error"}>Duration Duplicated.</Typography>
                </Box>
              )}
              <Box alignSelf={"end"}>
                <Button
                  type={"submit"}
                  variant="outlined"
                  sx={{ width: "120px" }}
                >
                  Save
                </Button>
              </Box>
            </Stack>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddOrEditLockerRateModal;
