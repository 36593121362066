import { Menu, MenuItem } from "@mui/material";
import React from "react";
import theme from "../../assets/theme/theme";
import { IIndividualPromo, IPromotion } from "../../types/promotion";
import { IMerch } from "../../types/merch";

type Props = {
  isActionMenuOpen: boolean;
  handleActionMenuClose: () => void;
  handleEditInfoModalOpen: () => void;
  handleArchiveMerch: () => void;
  selectedPromotion?: IPromotion;
  selectedMerch?: IMerch;
  selectedIndividualPromotion?: IIndividualPromo;
};

const ManagementActionMenu = React.forwardRef<HTMLButtonElement | null, Props>(
  (
    {
      isActionMenuOpen,
      handleActionMenuClose,
      handleEditInfoModalOpen,
      handleArchiveMerch,
      selectedPromotion,
      selectedMerch,
      selectedIndividualPromotion,
    },
    actionMenuAnchorElRef
  ) => {
    return (
      <Menu
        open={isActionMenuOpen}
        onClose={handleActionMenuClose}
        anchorEl={
          actionMenuAnchorElRef && typeof actionMenuAnchorElRef !== "function"
            ? actionMenuAnchorElRef.current
            : null
        }
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "0",
            width: "10rem",
            border: `3px solid ${theme.palette.primary.main}`,
            backgroundColor: "black",
          },
          "& .MuiPaper-root .MuiList-root": {
            backgroundColor: "black",
            padding: "0",
          },
        }}
      >
        <MenuItem
          onClick={() => handleEditInfoModalOpen()}
          sx={{
            height: "2rem",
            borderBottom: `3px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
            backgroundColor: "black",
            fontWeight: 600,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
              color: "black",
            },
          }}
        >
          Edit Info
        </MenuItem>
        {selectedPromotion?.archived ||
        selectedMerch?.isArchived ||
        selectedIndividualPromotion?.archived ? (
          <MenuItem
            onClick={() => handleArchiveMerch()}
            sx={{
              height: "2rem",
              color: theme.palette.primary.main,
              backgroundColor: "black",
              fontWeight: 600,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
                color: "black",
              },
            }}
          >
            Unarchive
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => handleArchiveMerch()}
            sx={{
              height: "2rem",
              color: theme.palette.primary.main,
              backgroundColor: "black",
              fontWeight: 600,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
                color: "black",
              },
            }}
          >
            Archive
          </MenuItem>
        )}
      </Menu>
    );
  }
);

export default ManagementActionMenu;
