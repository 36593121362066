import axios from "axios";
import { useEffect, useState } from "react";
import BASE_API from "../constants/api";

export type roomIdsParams = string[];

//Generate 5 random digits
const rng = () =>
  Math.floor(Math.random() * 100000)
    .toString()
    .padStart(5, "0");

/**
 * REACT HOOKS TO LOCK ROOM BOOKING SESSION
 * @param roomIds
 * @param authToken
 */
const useRoomBookingSessionLock = (
  roomIds: roomIdsParams,
  authToken?: string
) => {
  // const [roomIds, setRoomIds] = useState<string[]>([]);
  const [token, setToken] = useState<string>(rng());

  const req = () =>
    axios({
      method: "post",
      url: `${BASE_API}/bookings/roomsessionlock/`,
      data: {
        roomIds: roomIds,
        token: token,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  useEffect(() => {
    req();
    const roomSessionInterval = setInterval(() => {
      req()
        .then((res) => {
          if (res.data.status === "OK") {
            console.log("Room session lock success");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1000 * 30);

    return () => {
      clearInterval(roomSessionInterval);
    };
  });
};
export default useRoomBookingSessionLock;
