import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../assets/theme/theme";
import { KeyboardArrowDown } from "@mui/icons-material";

import { ReactComponent as ArrowDownIcon } from "../../assets/images/DownArrow.svg";

type Props = {
  measurements: {
    xAxisNumTicks: number;
    xAxisHeight: string;
    emPerHour: number;
    oddNumColor: string;
    evenNumColor: string;
    epochPerEM: number;
  };
  displayDate: Date;
  displayHour: number;
  clockHours: number[];
  arrowDownRef: React.MutableRefObject<HTMLDivElement | null>;
  otaDirected: boolean;
};

const TimeAxis = React.forwardRef(
  (
    {
      measurements: {
        xAxisNumTicks,
        xAxisHeight,
        emPerHour,
        oddNumColor,
        evenNumColor,
        epochPerEM,
      },
      displayDate,
      displayHour,
      clockHours,
      arrowDownRef,
      otaDirected,
    }: Props,
    hourAxisRef: React.ForwardedRef<HTMLDivElement | null>
  ) => {
    const [todayDateEpoch, setTodayDateEpoch] = useState<number>(
      new Date(
        displayDate.getFullYear(),
        displayDate.getMonth(),
        displayDate.getDate(),
        displayHour,
        0
      ).getTime() -
        24 * 60 * 60 * 1000
    );

    useEffect(() => {
      setTodayDateEpoch(
        new Date(
          displayDate.getFullYear(),
          displayDate.getMonth(),
          displayDate.getDate(),
          displayHour,
          0
        ).getTime() -
          24 * 60 * 60 * 1000
      );
    }, [displayDate, displayHour]);

    return (
      <>
        <Grid
          container
          ref={hourAxisRef}
          columns={{
            xs: xAxisNumTicks,
            sm: xAxisNumTicks,
            md: xAxisNumTicks,
            lg: xAxisNumTicks,
            xl: xAxisNumTicks,
          }}
          alignContent={"start"}
          position={"absolute"}
          sx={{
            width: `${xAxisNumTicks * emPerHour}em`,
            height: xAxisHeight,
            zIndex: 50,
            backgroundImage: `repeating-linear-gradient(
                  to right,
                  ${displayHour % 2 === 0 ? oddNumColor : evenNumColor},
                  ${
                    displayHour % 2 === 0 ? oddNumColor : evenNumColor
                  } ${emPerHour}em,
                  ${
                    displayHour % 2 === 0 ? evenNumColor : oddNumColor
                  } ${emPerHour}em,
                  ${displayHour % 2 === 0 ? evenNumColor : oddNumColor} ${
              2 * emPerHour
            }em
                )`,
          }}
        >
          {clockHours.map((hour) => (
            <Grid
              id={`hour${hour + displayHour}`}
              key={hour + displayHour}
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                backgroundColor:
                  (hour + displayHour) % 2 === 0 ? oddNumColor : evenNumColor,
                width: `${emPerHour}em`,
                height: xAxisHeight,
                left: `${(hour - displayHour) * emPerHour}em`,
                borderBottom: `3px solid ${theme.palette.primary.main}`,
              }}
            >
              <Stack direction={"column"} justifyContent={"center"}>
                <Typography
                  sx={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "0.9em",
                    fontWeight: 600,
                  }}
                >
                  {(hour + displayHour) % 24 > 12
                    ? ((hour + displayHour) % 24) - 12
                    : (hour + displayHour) % 24 === 0
                    ? 12
                    : (hour + displayHour) % 24}
                </Typography>
                <Typography
                  sx={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "0.9em",
                    fontWeight: 600,
                  }}
                >
                  {(hour + displayHour) % 24 >= 12 ? "pm" : "am"}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>

        {!otaDirected && (
          <Box
            width={"1px"}
            height={"100%"}
            position={"absolute"}
            left={`${
              (new Date(displayDate).getTime() - todayDateEpoch) / epochPerEM
            }em`}
            bgcolor={"white"}
            marginTop={"1px"}
            zIndex={94}
          >
            <ArrowDownIcon
              ref={arrowDownRef}
              width={50}
              height={50}
              style={{ position: "absolute", right: -24 }}
            />
          </Box>
        )}
      </>
    );
  }
);

export default React.memo(TimeAxis);
