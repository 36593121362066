import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { removeModalOpen, setModalOpen } from "../../redux/modal/modalSlice";
import { Backdrop } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

// types and Interfaces
interface ModalPopperProps {
  popUpComponent: JSX.Element;
  text: string;
  variant?: string;
  endIcons?: JSX.Element;
}

export default function ModalPopper(props: ModalPopperProps) {
  const dispatch = useDispatch();
  const modalOpen = useSelector((state: RootState) => state.modal.modalOpen);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setIsOpen(true);
    dispatch(setModalOpen(props.text));
  };
  const handleClose = () => {
    setIsOpen(false);
    dispatch(removeModalOpen());
  };

  useEffect(() => {
    if (!modalOpen) {
      setIsOpen(false);
    }
  }, [modalOpen]);

  return (
    <>
      <Button
        onClick={handleOpen}
        variant={
          props.variant === "contained"
            ? "contained"
            : props.variant === "outlined"
            ? "outlined"
            : "text"
        }
        endIcon={props.endIcons}
      >
        {props.text}
      </Button>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          },
        }}
      >
        <Box sx={style}>{props.popUpComponent}</Box>
      </Modal>
    </>
  );
}
