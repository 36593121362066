import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Done } from "@mui/icons-material";
import { StyledTableCell, StyledTableRow } from "../../constants/tableStyle";
import theme from "../../assets/theme/theme";
import { useEffect, useState } from "react";
import CreateTier from "./CreateTier";
import axiosInstance from "../../constants/axiosConfig";
import { store } from "../../redux/store";
import {
  IColumnName,
  RoomRateTierConfiguration,
  Tiers,
} from "../../models/rooms/roomsInterface";
import Notification from "../../utils/notificationConfig";

interface GroupedData {
  hoursOfStay: number;
  rows: RoomRateTierConfiguration[];
  roomTypeNames: IColumnName[];
}

const TierConfiguration = () => {
  const token = store.getState().user.accessToken;
  const lot = store.getState().user.lotId;
  const [showCreateTierSection, setShowCreateTierSection] =
    useState<boolean>(false);
  const [tiers, setTiers] = useState<Tiers[]>([]);
  const [selectedTier, setSelectedTier] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [rateloading, setRateLoading] = useState<boolean>(false);

  const [groupedData1, setGroupedData] = useState<GroupedData[]>([]);

  const [columns, setColumns] = useState<IColumnName[]>([]);

  // Concatenate the existing data with the generated placeholder data

  function transformData(data: RoomRateTierConfiguration[]): GroupedData[] {
    const groupedData: { [key: number]: RoomRateTierConfiguration[] } = {};

    const tier = tiers.find((item) => item.tiersId === selectedTier);

    // Define the available hours of stay you want to retrieve
    let requestedHoursOfStay: number[] = []; // Requesting data for 3, 6, 12, and 24 hours

    if (Number(lot) === 2) {
      requestedHoursOfStay = [1, 3, 6, 12, 24];
    } else {
      requestedHoursOfStay = [3, 6, 12, 24];
    }

    // Filter out the hours of stay that are already present in the existing data
    const missingHoursOfStay = requestedHoursOfStay.filter(
      (hours) => !data.some((item) => item.hoursOfStay === hours)
    );

    const emptyData: RoomRateTierConfiguration[] = [];

    columns.forEach((roomTypeName) => {
      missingHoursOfStay.forEach((hours) => {
        // Check if data for this room type name and hours of stay combination already exists
        if (
          !data.some(
            (item) =>
              item.roomTypeName === roomTypeName.typeName &&
              item.hoursOfStay === hours
          )
        ) {
          emptyData.push({
            roomTypeName: roomTypeName.typeName,
            roomTypeColor: "#FFFFFF", // Assuming white color for "Not Set"
            hoursOfStay: hours,
            roomRate: "Not Set",
            isLatest: true,
            roomRateId: "",
            roomType: "",
            tiers: tier,
            showTextfield: false,
          });
        }
      });
    });

    const combinedData: RoomRateTierConfiguration[] = data.concat(emptyData);

    combinedData.forEach((item) => {
      const {
        roomTypeName,
        roomTypeColor,
        hoursOfStay,
        roomRate,
        isLatest,
        roomRateId,
        roomType,
        tiers,
      } = item;

      if (!groupedData[hoursOfStay]) {
        groupedData[hoursOfStay] = [];
      }
      groupedData[hoursOfStay].push({
        roomTypeName,
        roomTypeColor,
        hoursOfStay,
        roomRate,
        isLatest,
        roomRateId,
        roomType,
        tiers,
        showTextfield: false,
      });
    });

    console.log("selected Tier", groupedData);

    // Ensure all room types are present for each hoursOfStay group
    for (const hoursOfStay in groupedData) {
      columns.forEach((roomTypeName) => {
        if (
          !groupedData[parseInt(hoursOfStay)].some(
            (row) => row.roomTypeName === roomTypeName.typeName
          )
        ) {
          groupedData[parseInt(hoursOfStay)].push({
            roomTypeName: roomTypeName.typeName,
            roomTypeColor: "#FFFFFF", // Assuming white color for "Not Set"
            hoursOfStay: parseInt(hoursOfStay),
            roomRate: "Not Set",
            isLatest: true,
            roomRateId: "",
            roomType: "",
            tiers: tier,
            showTextfield: false,
          });
        }
      });
      groupedData[parseInt(hoursOfStay)].sort((a, b) =>
        a.roomTypeName.localeCompare(b.roomTypeName)
      );
    }

    const transformedData: GroupedData[] = [];
    for (const hoursOfStay in groupedData) {
      const rows = groupedData[parseInt(hoursOfStay)];
      transformedData.push({
        hoursOfStay: parseInt(hoursOfStay),
        rows,
        roomTypeNames: columns.slice(),
      });
    }

    return transformedData;
  }

  useEffect(() => {
    const fetchAllActiveTiers = async () => {
      setLoading(true);
      await axiosInstance
        .get(`/rooms/rate?tierId=${selectedTier}&latest=true`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setGroupedData(transformData(res.data));
          }
        });
      setLoading(false);
    };

    if (selectedTier) fetchAllActiveTiers();
  }, [token, selectedTier]);

  const fetchAllTiers = async (token: string) => {
    await axiosInstance
      .get(`/rooms/tiers?withAccountLot=true`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setTiers(res.data.data);
        }
      });
  };

  const fetchAllRoomTypesColumns = async (token: string) => {
    await axiosInstance
      .get(`/rooms/v1/types/type-cols/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setColumns(
            res.data.data.sort((a: IColumnName, b: IColumnName) =>
              a.typeName.localeCompare(b.typeName)
            )
          );
        }
      });
  };

  useEffect(() => {
    fetchAllTiers(token);
    fetchAllRoomTypesColumns(token);
  }, [token]);

  const handleRoomRateChange = (
    hoursOfStay: number,
    roomTypeName: string,
    newRate: string
  ) => {
    setGroupedData((prevData) => {
      // Create a new array by mapping through the previous data
      return prevData.map((group) => {
        // Find the group with the matching hoursOfStay
        if (group.hoursOfStay === hoursOfStay) {
          // Create a new rows array with the updated roomRate for the specific roomTypeName
          const updatedRows = group.rows.map((room) => {
            if (room.roomTypeName === roomTypeName) {
              return { ...room, roomRate: newRate };
            }
            return room;
          });
          // Return the updated group object
          return { ...group, rows: updatedRows };
        }
        return group;
      });
    });
  };

  const toggleTextField = (
    hoursOfStay: number,
    roomTypeName: string,
    handleOpen: boolean
  ) => {
    setGroupedData((prevData) => {
      if (handleOpen) {
        const alreadyTrue = prevData.some((group) => {
          return group.rows.some((room) => room.showTextfield);
        });

        if (alreadyTrue) {
          Notification.info("Please save first textfield first");
          return prevData; // Do not modify data
        }
      }
      return prevData.map((group) => {
        if (group.hoursOfStay === hoursOfStay) {
          return {
            ...group,
            rows: group.rows.map((room) => {
              if (room.roomTypeName === roomTypeName) {
                return {
                  ...room,
                  showTextfield: !room.showTextfield, // Toggle the value
                };
              }
              return room;
            }),
          };
        }
        return group;
      });
    });
  };

  const changeRoomRate = async (data: RoomRateTierConfiguration) => {
    setRateLoading(true);
    const payload = {
      newRate: data.roomRate !== "" ? parseFloat(data.roomRate).toFixed(2) : null,
      hoursOfStay: data.hoursOfStay,
      roomTypeName: data.roomTypeName,
      tiersId: data.tiers?.tiersId,
    };
    await axiosInstance
      .patch(`/rooms/rate/change-rate/`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success(response.data.message);
      })
      .catch((error) => {
        Notification.failed(`Error updating zone: ${error}`);
      })
      .finally(() => {
        setRateLoading(false);
      });
  };

  return (
    <>
      {!showCreateTierSection ? (
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h2">Tier Configuration</Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              size="large"
              onClick={() => setShowCreateTierSection(true)}
            >
              Create Tier
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                pt: 5,
                mb: 5,
                borderBottomWidth: 3.5,
                borderColor: "#232323",
              }}
            />
          </Grid>
          <Grid item xs={3} pt={2}>
            <Typography variant="h3">Select Tier</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              select
              title="Tiers"
              label="Tiers"
              size="medium"
              fullWidth
              value={selectedTier}
              onChange={(e) => setSelectedTier(e.target.value)}
            >
              {tiers.map((option) => (
                <MenuItem key={option.tiersId} value={option.tiersId}>
                  {option.tier}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={1} pt={1} pl={2}>
            <IconButton color="primary" onClick={() => fetchAllTiers(token)}>
              <RefreshIcon color="primary" />
            </IconButton>
          </Grid>
          {!loading ? (
            <Grid item xs={12}>
              <TableContainer
                component={Paper}
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  mt: 5,
                }}
              >
                <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>
                        <Typography variant="h3" color={"#15ffdc"}>
                          Duration
                        </Typography>
                      </StyledTableCell>
                      {columns.map((row, index) => (
                        <StyledTableCell key={index}>
                          <Typography variant="h3" color={row.colorTag}>
                            {row.typeName}
                          </Typography>
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>

                  <TableBody
                    sx={{ width: "80%", minHeight: "80%", overflowY: "auto" }}
                  >
                    {groupedData1.map((item, index) => (
                      <TableRow sx={{ backgroundColor: "black" }} key={index}>
                        <StyledTableCell
                          sx={{
                            border: 1,
                            borderColor: theme.palette.primary.main,
                          }}
                        >
                          <Typography variant="h4">
                            {item.hoursOfStay}
                          </Typography>
                        </StyledTableCell>
                        {item.rows.map((row, i) => (
                          <StyledTableCell
                            sx={{
                              border: 1,
                              borderColor: theme.palette.primary.main,
                            }}
                            key={i}
                          >
                            {!row.showTextfield ? (
                              <Tooltip title="Click to edit">
                                <Stack
                                  direction={"row"}
                                  justifyContent={"space-between"}
                                  onClick={() => {
                                    toggleTextField(
                                      item.hoursOfStay,
                                      row.roomTypeName,
                                      true
                                    );
                                  }}
                                  sx={{ cursor: "pointer" }}
                                >
                                  {row.roomRateId || (row.roomRate !== "Not Set" && row.roomRate !== "") ? (
                                    <>
                                      <Typography
                                        variant="h4"
                                        color={theme.palette.primary.main}
                                      >
                                        MYR
                                      </Typography>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {row.roomRate === "Not Set" ? (
                                    <Typography
                                      variant="h4"
                                      justifyContent={"center !important"}
                                    >
                                      {row.roomRate}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      variant="h4"
                                      justifyContent={"center !important"}
                                    >
                                      {row.roomRate !== "" ? parseFloat(row.roomRate).toFixed(2) : "Not Set"}
                                    </Typography>
                                  )}
                                </Stack>
                              </Tooltip>
                            ) : (
                              <Stack display={"flex"} direction={"row"}>
                                <TextField
                                  value={row.roomRate}
                                  size="small"
                                  fullWidth
                                  type="number"
                                  sx={{ width: { xs: 100, sm: 200 } }}
                                  placeholder="Enter Rate"
                                  onChange={(e) =>
                                    handleRoomRateChange(
                                      item.hoursOfStay,
                                      row.roomTypeName,
                                      e.target.value
                                    )
                                  }
                                />
                                {!rateloading ? (
                                  <>
                                    <IconButton
                                      sx={{
                                        alignSelf: "center",
                                      }}
                                      onClick={async () => {
                                        if (row.roomRate === "Not Set") {
                                          Notification.info(
                                            "Please provide rate"
                                          );
                                          return;
                                        }
                                        await changeRoomRate(row);
                                        toggleTextField(
                                          item.hoursOfStay,
                                          row.roomTypeName,
                                          false
                                        );
                                      }}
                                    >
                                      <Done color="primary" />
                                    </IconButton>
                                    <IconButton
                                      sx={{
                                        alignSelf: "center",
                                      }}
                                      onClick={() => {
                                        toggleTextField(
                                          item.hoursOfStay,
                                          row.roomTypeName,
                                          false
                                        );
                                      }}
                                    >
                                      <CloseIcon sx={{ color: "red" }} />
                                    </IconButton>
                                  </>
                                ) : (
                                  <CircularProgress
                                    color="primary"
                                    sx={{ fontSize: "1rem", padding: 1 }}
                                  />
                                )}
                              </Stack>
                            )}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 10,
              }}
            >
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      ) : (
        <CreateTier
          setShowCreateTierSection={() => setShowCreateTierSection(false)}
        />
      )}
    </>
  );
};

export default TierConfiguration;
