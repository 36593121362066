import { configureStore } from "@reduxjs/toolkit";

// Slice
import userReducer from "./authentication/userSlice";
import modalSlice from "./modal/modalSlice";
import bookingReducer from "./booking/bookingSlice";
import expressbookingReducer from "./booking/expressbooking";

export const store = configureStore({
  reducer: {
    user: userReducer,
    modal: modalSlice,
    booking: bookingReducer,
    expressbooking :expressbookingReducer, 
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
