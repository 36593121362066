import {
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PrintIcon from "@mui/icons-material/Print";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import theme from "../../assets/theme/theme";
import CheckinSlip from "./CheckinSlip";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import ConsentFormPrint from "./ConsentFormPrint";

type Props = {
  handleDocumentModalClose: () => void;
  handleBack: () => void;
  guestId: string;
  bookingId: string;
};

export default function ConsentFormPrintModal({
  handleDocumentModalClose,
  handleBack,
  guestId,
  bookingId,
}: Props) {
  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current!,
  });
  return (
    <Grid
      container
      spacing={0}
      sx={{ p: 0, width: "100%", height: "auto", maxBlockSize: "700px" }}
    >
      <Grid item xs={11}>
        <Stack
          display={"flex"}
          sx={{
            p: 2,
            border: 0,
            borderColor: "#232323",
            height: "50px",
            justifyContent: "center",
          }}
        >
          <Typography variant="h2" color={theme.palette.primary.main}>
            Documents
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={1}>
        <Stack
          display={"flex"}
          sx={{
            p: 1,
            border: 0,
            borderColor: theme.palette.primary.main,
            height: "50px",
            justifyContent: "center",
          }}
        >
          <IconButton onClick={handleDocumentModalClose} sx={{ padding: 0 }}>
            <HighlightOffIcon fontSize="large" sx={{ color: "#ff4d56" }} />
          </IconButton>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Divider
          orientation="horizontal"
          variant="fullWidth"
          flexItem
          sx={{
            borderBottomWidth: 2.5,
            borderColor: theme.palette.primary.main,
          }}
        />
      </Grid>

      <Grid item xs={2} p={2}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
          sx={{ m: 1 }}
        >
          <Typography variant="button">{"Back"}</Typography>
        </Button>
      </Grid>
      <Grid
        item
        xs={10}
        p={2}
        container
        direction={"row"}
        alignContent={"end"}
        justifyContent={"end"}
      >
        {/* <Button
          startIcon={<FileDownloadIcon />}
          variant="outlined"
          color="primary"
          sx={{ m: 1 }}
        >
          <Typography variant="button">Export</Typography>
        </Button> */}
        <Button
          startIcon={<PrintIcon />}
          variant="outlined"
          color="primary"
          sx={{ m: 1 }}
          onClick={handlePrint}
        >
          <Typography variant="button">Print</Typography>
        </Button>
      </Grid>

      <Grid
        container
        direction={"column"}
        alignContent={"center"}
        justifyContent={"center"}
        item
        xs={12}
        sx={{ backgroundColor: "#232323", justifyContent: "center", p: 2 }}
        style={{
          width: "100%",
        }}
      >
        <ConsentFormPrint componentRef={componentRef} bookingId={bookingId} />
      </Grid>
    </Grid>
  );
}
