import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import theme from "../../assets/theme/theme";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import { BookingResult, ShowerBooking } from "../../pages/booking/interface";
import { ArrowForward, Delete, Key } from "@mui/icons-material";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

type Props = {
  isScanCardModalOpen: boolean;
  handleCloseScanCardModal: VoidFunction;
  checkOutDate: Date;
  bookingId: string;
  setGuestFolioData: Dispatch<SetStateAction<BookingResult | undefined>>;
  bookingNo: string;
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "55%",
  bgcolor: "black",
  border: 2,
  borderColor: theme.palette.primary.main,
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
};

function ScanCardModal({
  isScanCardModalOpen,
  handleCloseScanCardModal,
  checkOutDate,
  bookingId,
  setGuestFolioData,
  bookingNo,
}: Props) {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const [isCardScanned, setIsCardScanned] = useState(false);
  const [scannedCard, setScannedCard] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCardScanProcess = () => {
    setIsLoading(true);

    const apiUrl = `/bookings/v1/shower/`;

    const formData = {
      startRent: new Date(),
      endRent: checkOutDate,
      bookingId: bookingId,
      showerIds: [scannedCard],
    };

    axiosInstance
      .post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsCardScanned(true);
        Notification.success("Card Successfully Assigned");

        const tempShower: ShowerBooking = {
          booking: bookingId,
          shower: scannedCard,
          showerBookingId: "",
          showerEndRentDatetime: checkOutDate.toString(),
          showerStartRentDatetime: "",
          status: "",
          sum: "",
        };

        setGuestFolioData((prevState) => ({
          ...prevState!,
          showerBookings: [...prevState!.showerBookings, tempShower],
        }));
      })
      .catch((res) => {
        Notification.failed("Something wrong with the Card");
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      open={isScanCardModalOpen}
      onClose={handleCloseScanCardModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box sx={modalStyle}>
        <Stack
          height={"2.5rem"}
          direction={"row"}
          alignItems={"center"}
          paddingX={"1rem"}
          justifyContent={"space-between"}
          borderBottom={2}
          borderColor={theme.palette.primary.main}
          sx={{ background: "#232323" }}
        >
          <Typography
            variant={"h3"}
            color={theme.palette.primary.main}
            fontWeight={"700"}
            fontSize={"1.1rem"}
          >
            Scan Card
          </Typography>
          <IconButton onClick={handleCloseScanCardModal}>
            <HighlightOffIcon
              fontSize={"medium"}
              sx={{ color: theme.palette.primary.main }}
            />
          </IconButton>
        </Stack>
        {isCardScanned ? (
          <>
            <Stack
              width={"100%"}
              height={"150px"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={2}
            >
              <Box
                width={"150px"}
                height={"40px"}
                bgcolor={"white"}
                padding={"1px"}
              >
                <Stack
                  direction={"row"}
                  height={"100%"}
                  width={"100%"}
                  alignItems={"center"}
                  spacing={1}
                >
                  <Box
                    display={"flex"}
                    width={"40px"}
                    height={"100%"}
                    bgcolor={"black"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Box
                      display={"flex"}
                      height={"15px"}
                      borderRadius={"5px"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{ backgroundColor: "white", aspectRatio: 3 / 2 }}
                    >
                      <Key sx={{ color: "black", fontSize: "16px" }} />
                    </Box>
                  </Box>
                  <Typography color={"black"} variant="h4">
                    Card
                  </Typography>
                </Stack>
              </Box>
              <Stack direction={"row"} spacing={1}>
                <Typography variant="h3">
                  New Card Found. Assigned to
                </Typography>
                <Typography variant="h3" color={"secondary"}>
                  {bookingNo}
                </Typography>
                <Typography variant="h3">successfully.</Typography>
              </Stack>
              <Divider
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  width: "600px",
                }}
              />
            </Stack>
            <Stack
              direction={"row"}
              width={"100%"}
              height={"110px"}
              sx={{ backgroundColor: theme.palette.background.default }}
            >
              <ButtonBase
                href={`/bookings/check-in-out-list`}
                sx={{
                  width: "50%",
                  height: "100%",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                  height={"100%"}
                  borderTop={1}
                  borderRight={1}
                  borderColor={theme.palette.primary.main}
                  paddingX={1}
                >
                  <Delete sx={{ fontSize: "50px" }} />
                  <Typography variant="h4">Unbind This Card</Typography>
                </Box>
              </ButtonBase>
              <ButtonBase
                onClick={() => {
                  setIsCardScanned(false);
                  setScannedCard("");
                }}
                sx={{
                  width: "50%",
                  height: "100%",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                  height={"100%"}
                  borderTop={1}
                  borderColor={theme.palette.primary.main}
                  paddingX={1}
                >
                  <ArrowForward sx={{ fontSize: "50px" }} />
                  <Typography variant="h4">Scan Another Card</Typography>
                </Box>
              </ButtonBase>
            </Stack>
          </>
        ) : (
          <Stack
            width={"100%"}
            height={"300px"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
          >
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              direction={"row"}
              spacing={2}
            >
              <RssFeedIcon fontSize={"large"} />
              <Typography variant={"h3"} fontSize={"1.3rem"}>
                Scan Access Card to Perform action or Fill in Text Field Below
              </Typography>
            </Stack>
            <TextField
              variant="outlined"
              value={scannedCard}
              onChange={(event) => setScannedCard(event.target.value)}
              sx={{ width: "300px" }}
            />
            <Button
              variant="outlined"
              onClick={handleCardScanProcess}
              disabled={scannedCard === ""}
            >
              {isLoading ? <CircularProgress /> : "Submit"}
            </Button>
          </Stack>
        )}
      </Box>
    </Modal>
  );
}

export default ScanCardModal;
