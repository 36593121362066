import {
  Modal,
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Backdrop,
  Button,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { Formik, Form } from 'formik';
import theme from '../../assets/theme/theme';
import { store } from '../../redux/store';
import Notification from '../../utils/notificationConfig';
import ManagementModalFormButton from '../management/ManagementModalFormButton';
import ManagementModalFormTextField from '../management/ManagementModalFormTextField';
import ManagementModalFormDatePicker from '../management/ManagementModalFormDatePicker';
import ManagementModalHeader from '../management/ManagementModalHeader';
import { CalendarIcon, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { IIndividualPromo } from '../../types/promotion';
import { useEffect, useState } from 'react';
import ManagementModalRadioButton from '../management/ManagementModalRadioButton';
import ManagementModalFormNumberField from '../management/ManagementModalFormNumberField';
import axiosInstance from '../../constants/axiosConfig';

type Props = {
  title: string;
  type: 'add' | 'edit';
  isPromotionModalOpen: boolean;
  handlePromotionModalClose: () => void;
  initialValues?: IIndividualPromo;
  fetchAllPromotions: () => Promise<void>;
};

const defaultInitialPromotionValues: IIndividualPromo = {
  individualPromoId: '',
  promoCode: '',
  promoStartDatetime: new Date().toString(),
  promoEndDatetime: new Date().toString(),
  seats: 0,
  stackable: false,
  archived: false,
  details: '',
  hoursToHit: 0,
  isMilestone: false,
  maxCapped: '',
  detailedPromo: {
    unit: '%',
    value: '0',
  },
};

const RewardsModal = ({
  title,
  type,
  isPromotionModalOpen,
  handlePromotionModalClose,
  initialValues,
  fetchAllPromotions,
}: Props) => {
  const [selectedPromotionValues, setSelectedPromotionValues] =
    useState<IIndividualPromo>(defaultInitialPromotionValues);

  const [overwrite, setOverwrite] = useState<boolean>(false);

  const [openOverwriteModal, setOpenOverwriteModal] = useState<boolean>(false);

  const handleSelectedPromotionObjChange = (promotion: IIndividualPromo) => {
    setSelectedPromotionValues(promotion);
  };

  useEffect(() => {
    if (!initialValues || type === 'add') return;
    handleSelectedPromotionObjChange({
      ...initialValues,
      detailedPromo: {
        // *parse the details string to get the unit and value
        unit: initialValues.details.includes('%') ? '%' : 'RM',
        value: initialValues.details.includes('%')
          ? Number(initialValues.details.replace('%', '')).toString()
          : parseFloat(initialValues.details).toFixed(2).toString(),
      },
    });
  }, [initialValues, type]);

  return (
    <Modal
      open={isPromotionModalOpen}
      onClose={() => handlePromotionModalClose()}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
    >
      <Box
        width={'600px'}
        height={'650px'}
        border={`3px solid ${theme.palette.primary.main}`}
        position={'absolute'}
        margin={'auto'}
        sx={{
          inset: '0',
          backgroundColor: 'black',
        }}
      >
        <ManagementModalHeader
          title={title}
          handleModalClose={handlePromotionModalClose}
        />

        <Formik
          enableReinitialize
          initialValues={selectedPromotionValues}
          onSubmit={async (data) => {
            const { detailedPromo, ...rest } = data;
            const promoStart = new Date(rest.promoStartDatetime);
            const promoEnd = new Date(rest.promoEndDatetime);
            promoEnd.setHours(23, 59, 59, 999);

            if (promoEnd.getTime() < promoStart.getTime()) {
              Notification.failed('Promo end date must be after start date');
              return;
            }

            const formData = {
              ...rest,
              details:
                detailedPromo && detailedPromo.unit === 'RM'
                  ? `${detailedPromo.value}`
                  : detailedPromo
                  ? `${detailedPromo.value}${detailedPromo.unit}`
                  : '',
              promoStartDatetime: new Date(
                promoStart.getFullYear(),
                promoStart.getMonth(),
                promoStart.getDate()
              ),
              promoEndDatetime: promoEnd,
              overwrite: overwrite,
            };

            const token = store.getState().user.accessToken;

            if (type === 'add') {
              await axiosInstance
                .post(`/promotions/individual-promo/create/`, formData, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((res: AxiosResponse) => {
                  Notification.success(res.data.message);
                  fetchAllPromotions();
                  handlePromotionModalClose();

                  setOpenOverwriteModal(false);
                  setOverwrite(false);
                })
                .catch((err: any) => {
                  Notification.failed(err.response.data.message);
                  if (
                    err.response.data.message ===
                    'The date range overlaps with an existing promo'
                  ) {
                    setOpenOverwriteModal(true);
                  }
                });

              return;
            }

            if (type === 'edit') {
              await axiosInstance
                .put(`/promotions/individual-promo/edit/`, formData, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((res: AxiosResponse) => {
                  Notification.success(res.data.message);
                  fetchAllPromotions();
                  // fetchAllMerch(); use material react table crud feature to add the item to the table in the frontend instead of get it again in the backend
                  handlePromotionModalClose();

                  setOpenOverwriteModal(false);
                  setOverwrite(false);
                })
                .catch((err: any) => {
                  Notification.failed(err.response.data.message);
                  if (
                    err.response.data.message ===
                    'The date range overlaps with an existing promo'
                  ) {
                    setOpenOverwriteModal(true);
                  }
                });

              return;
            }
          }}
        >
          {({
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            setFieldValue,
          }) => (
            <Form
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Grid container paddingX={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginY={3}>
                  <Typography variant={'h2'}>
                    {type === 'add'
                      ? 'New Individual Promotion'
                      : 'Edit Individual Promotion'}
                  </Typography>
                </Grid>
                <ManagementModalFormTextField
                  label={'Promo Code'}
                  value={values.promoCode}
                  name={'promoCode'}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  maxLength={20}
                />
                {values.detailedPromo && (
                  <ManagementModalFormTextField
                    label={'Discount Amt'}
                    value={values.detailedPromo.value}
                    name={'detailedPromo.value'}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    textFieldType={'number'}
                    startAdornment={
                      <Typography fontWeight={600} paddingLeft={'1.5em'}>
                        {values.detailedPromo.unit}
                      </Typography>
                    }
                    endAdornment={
                      <RadioGroup
                        sx={{ flexWrap: 'nowrap' }}
                        row
                        value={
                          values.detailedPromo.unit.includes('%') ? '%' : 'RM'
                        }
                        name={'detailedPromo.unit'}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={'%'}
                          control={
                            <Radio size={'small'} sx={{ color: 'white' }} />
                          }
                          label={'%'}
                        />
                        <FormControlLabel
                          value={'RM'}
                          control={
                            <Radio size={'small'} sx={{ color: 'white' }} />
                          }
                          label={'MYR'}
                        />
                      </RadioGroup>
                    }
                  />
                )}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <ManagementModalFormDatePicker
                    dateValue={values.promoStartDatetime}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    fieldName={'promoStartDatetime'}
                    label={'Start Date'}
                  />
                  <ManagementModalFormDatePicker
                    dateValue={values.promoEndDatetime}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    fieldName={'promoEndDatetime'}
                    label={'End Date'}
                  />
                </LocalizationProvider>
                <ManagementModalFormNumberField<IIndividualPromo>
                  label={'Seat'}
                  value={values.seats ? values.seats : 0}
                  name={'seats'}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  textFieldType={'number'}
                  startAdornment={''}
                  setFieldValue={setFieldValue}
                />
                <ManagementModalFormTextField
                  label={'Max Cap'}
                  value={values.maxCapped}
                  name={'maxCapped'}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  textFieldType="number"
                  startAdornment={
                    <Typography fontWeight={600} paddingLeft={'1.5em'}>
                      {'RM'}
                    </Typography>
                  }
                />
                <ManagementModalRadioButton
                  label={'Milestone'}
                  RadioGroupValue={values.isMilestone}
                  name={'isMilestone'}
                  RadioLabel1={'Milestone'}
                  RadioLabel2={'Non-Milestone'}
                  RadioValue1={true}
                  RadioValue2={false}
                  handleChange={(value) => {
                    const boolean = value.target.value === 'true';
                    setFieldValue('isMilestone', boolean);
                    if (boolean === false) {
                      setFieldValue('hoursToHit', 0);
                    }
                  }}
                />
                {values.isMilestone && values.promoCode && (
                  <ManagementModalFormTextField
                    label={'Hours to Hit'}
                    value={values.hoursToHit}
                    name={'hoursToHit'}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    textFieldType="number"
                  />
                )}
                <ManagementModalRadioButton
                  label={'Stackable'}
                  RadioGroupValue={values.stackable}
                  name={'stackable'}
                  RadioLabel1={'Stackable'}
                  RadioLabel2={'Non-Stackable'}
                  RadioValue1={true}
                  RadioValue2={false}
                  handleChange={handleChange}
                />
                <ManagementModalFormButton<IIndividualPromo>
                  type={type}
                  isSubmitting={isSubmitting}
                  values={values}
                  initialValues={defaultInitialPromotionValues}
                />
              </Grid>
              <Modal
                open={openOverwriteModal}
                onClose={() => setOpenOverwriteModal(false)}
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    sx: {
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    },
                  },
                }}
              >
                <Box
                  width={'600px'}
                  border={2}
                  borderColor={theme.palette.primary.main}
                  sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: theme.palette.background.default,
                  }}
                >
                  <Box
                    display={'flex'}
                    width={'100%'}
                    height={'40px'}
                    paddingX={2}
                    borderBottom={2}
                    borderColor={theme.palette.primary.main}
                    alignItems={'center'}
                  >
                    <Typography variant="h3" color={'primary'}>
                      Room Actions
                    </Typography>
                  </Box>
                  <Box
                    display={'flex'}
                    height={'150px'}
                    bgcolor={'black'}
                    justifyContent={'start'}
                    alignItems={'center'}
                    paddingX={2}
                  >
                    <CalendarIcon
                      fontSize="large"
                      color="primary"
                      sx={{ marginRight: 3 }}
                    />
                    <Stack spacing={1}>
                      <Typography variant="h4">
                        The date range clashes with current active Milestone
                        promo.
                      </Typography>
                      <Typography variant="h4">
                        Set newly create promo as active Milestone promo?
                      </Typography>
                    </Stack>
                  </Box>
                  <Box
                    display={'flex'}
                    height={'50px'}
                    bgcolor={theme.palette.background.paper}
                    justifyContent={'end'}
                    padding={1}
                    borderTop={2}
                    borderColor={theme.palette.primary.main}
                  >
                    <Stack direction={'row'} spacing={1}>
                      <Button
                        variant={'outlined'}
                        onClick={() => {
                          setOpenOverwriteModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant={'outlined'}
                        onClick={() => {
                          setOverwrite(true);
                          handleSubmit();
                        }}
                      >
                        Confirm
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Modal>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default RewardsModal;
