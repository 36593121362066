import { Box, Divider, Typography } from '@mui/material';
import PercentSharpIcon from '@mui/icons-material/PercentSharp';
import React from 'react'
import theme from '../../assets/theme/theme';

type Props = {
  item: {
    promotionId: string;
    promoCode: string;
    seats: number;
    usedSeats: number;
  }
}

const PromotionUsageOverviewItem = ({
  item: {
    promotionId,
    promoCode,
    seats,
    usedSeats,
  },
}: Props) => {
  return (
    <Box
      marginTop={1}
      key={promotionId}
    >
      <Typography
        fontWeight={600}
      >
        {promoCode}
      </Typography>
      <Box
        width={"100%"}
        height={"30px"}
        border={"2px solid white"}
        display={"flex"}
        marginTop={1}
      >
        <Box
          width={"33%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <PercentSharpIcon />
        </Box>
        <Box
          width={"33%"}
          display={"flex"}
          alignItems={"center"}
          sx={{
            backgroundColor: "white",
          }}
        >
          <Typography
            color={"black"}
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            fontWeight={600}
          >
            {seats}
          </Typography>
        </Box>
        <Box
          width={"33%"}
          display={"flex"}
          alignItems={"center"}
          sx={{
            backgroundColor: "black",
          }}
        >
          <Typography
            color={"white"}
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            fontWeight={600}
          >
            {usedSeats}
          </Typography>
        </Box>
      </Box>
      
      <Divider
          orientation={"horizontal"}
          flexItem
          sx={{
            backgroundColor: theme.palette.primary.main,
            borderWidth: "2px",
            marginY: 2,
          }}
        />
    </Box>
  )
}

export default PromotionUsageOverviewItem