import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "black",
    color: theme.palette.primary.main,
    fontSize: "1.2rem",
    fontWeight: 700,
    textAlign: "center",
    borderBottom: 1,
    borderColor: theme.palette.primary.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.footer}`]: {
    height: "1em",
  },
  borderColor: theme.palette.primary.main,
  padding: 10,
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "black",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "black",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  padding: 0,
}));
