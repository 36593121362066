import { Box, Stack, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

export default function UnderMaintenance() {
  const navigate = useNavigate();

  return (
    <Box
      justifyContent={"center"}
      alignItems={"center"}
      display={"flex"}
      height={"90vh"}
      flexDirection={"column"}
    >
      <HourglassBottomIcon sx={{ fontSize: "120px", color: "white" }} />
      <Typography sx={{ color: "white", fontSize: "3rem" }}>
        Page In Progress
      </Typography>
      <Box sx={{ marginTop: "6rem" }}>
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            navigate("/");
          }}
        >
          Home
        </Button>
      </Box>
    </Box>
  );
}
