import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import {
  ArrowDropDownIcon,
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState } from "react";
import GanttChartSection from "../../pages/booking/GanttChartSection";
import { KeyboardReturn } from "@mui/icons-material";
import { IOTACompletedRooms } from "../../models/ota/OTAInterfaces";

interface OTAOptimiseProps {
  handleNextStep: (roomTypeResponse?: IOTACompletedRooms, date?: Date) => void;
}

const OTAOptimise = (props: OTAOptimiseProps) => {
  const theme = useTheme();

  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 2);
  tomorrowDate.setHours(0, 0, 0, 0);

  const [dateFilter, setDateFilter] = useState<Date>(tomorrowDate);
  const [dateSelected, setDateSelected] = useState<boolean>(false);

  const handleDateOnChange = (date: Date): void => {
    setDateFilter(date);
  };

  if (!dateSelected) {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        height={"300px"}
        bgcolor={theme.palette.background.default}
        padding={2}
      >
        <Stack
          direction={"row"}
          height={"30px"}
          alignItems={"center"}
          spacing={2}
          marginBottom={1}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"100%"}
            bgcolor={"white"}
            sx={{ aspectRatio: 1 / 1 }}
          >
            <Typography variant="h3" color={"black"}>
              1
            </Typography>
          </Box>
          <Typography variant="h2">Date & Time</Typography>
        </Stack>
        <Typography variant="h4" fontWeight={"normal"} marginBottom={2}>
          Select Date & Time of OTA reservation.
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={4}
            marginBottom={1}
          >
            <Box width={"100px"}>
              <Typography variant="h4">Date</Typography>
            </Box>
            <DatePicker
              value={dateFilter}
              onChange={(e) => handleDateOnChange(e as Date)}
              format="dd MMM yyyy"
              minDate={tomorrowDate}
              slots={{
                openPickerIcon: ArrowDropDownIcon,
              }}
              slotProps={{
                layout: {
                  sx: {
                    ".MuiDateCalendar-root": {
                      color: theme.palette.primary.main,
                      borderRadius: 2,
                      borderWidth: 1,
                      borderColor: theme.palette.primary.main,
                      border: "1px solid",
                      backgroundColor: theme.palette.background.paper,
                    },
                    ".MuiButtonBase-root": {
                      color: theme.palette.primary.main,
                    },
                    ".MuiTypography-root": {
                      color: theme.palette.primary.main,
                    },
                    ".MuiPickersCalendarHeader-root": {
                      color: theme.palette.primary.main,
                    },
                    ".MuiPickersMonth-monthButton": {
                      color: theme.palette.primary.main,
                    },
                    ".MuiPickersCalendarHeader-label": {
                      color: theme.palette.primary.main,
                    },
                    ".Mui-selected": {
                      color: "black",
                    },
                    ".MuiPickersYear-yearButton": {
                      color: theme.palette.primary.main,
                    },
                  },
                },
                textField: {
                  variant: "standard",
                  inputProps: {
                    style: {
                      color: "white",
                      borderRadius: 0,
                      border: 2,

                      height: "20px",
                    },
                  },
                  sx: {
                    width: "500px",
                    border: 2,
                    borderColor: theme.palette.secondary.main,
                    borderRadius: "5px",
                  },
                },
                openPickerButton: {
                  sx: {
                    color: "white",
                    marginTop: "1.8px",
                    marginRight: "3px",
                    zIndex: 1,
                  },
                },
                openPickerIcon: {
                  sx: {
                    width: "1em",
                    height: "1em",
                    zIndex: 1,
                  },
                },
              }}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={4}>
            <Box width={"100px"}>
              <Typography variant="h4">Time</Typography>
            </Box>
            <TimePicker
              value={dateFilter}
              format={"hh:mm a"}
              ampm={true}
              onChange={(e) => handleDateOnChange(e as Date)}
              slots={{
                openPickerIcon: ArrowDropDownIcon,
              }}
              slotProps={{
                textField: {
                  variant: "standard",
                  inputProps: {
                    style: {
                      borderRadius: 1,
                      border: 1,
                      height: "20px",
                    },
                  },
                  sx: {
                    width: "500px",
                    border: 2,
                    borderColor: theme.palette.secondary.main,
                    borderRadius: "5px",
                  },
                },
                openPickerButton: {
                  sx: {
                    color: "white",
                    marginTop: "1.8px",
                    marginRight: "3px",
                    zIndex: 1,
                  },
                },
                openPickerIcon: {
                  sx: {
                    width: "1em",
                    height: "1em",
                    zIndex: 1,
                  },
                },
              }}
            />
          </Stack>
        </LocalizationProvider>
        <Box
          display={"flex"}
          justifyContent={"end"}
          alignItems={"end"}
          height={"100%"}
        >
          <Button
            variant="outlined"
            onClick={() => setDateSelected(true)}
            sx={{ height: "50px", width: "160px" }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    );
  }
  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setDateSelected(false)}
        sx={{ marginBottom: 1 }}
      >
        <KeyboardReturn /> Back
      </Button>
      <GanttChartSection
        date={dateFilter}
        otaDirected={true}
        nextFunction={props.handleNextStep}
      />
    </>
  );
};

export default OTAOptimise;
