import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../assets/theme/theme";
import Notification from "../../utils/notificationConfig";

//Icons
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LuggageIcon from "@mui/icons-material/Luggage";
import EastIcon from "@mui/icons-material/East";
import axiosInstance from "../../constants/axiosConfig";
import { LockerBooking } from "../../pages/booking/interface";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type Props = {
  isExtendModalOpen: boolean;
  handleCloseExtendModal: () => void;
  bookingID: string;
  lockerBookings: LockerBooking;
  roomZone: string;
  lockerZone: string;
};

const LockerContainer = (
  lockerCode: string,
  lockerZone: string,
  roomCode: string,
  roomZone: string,
  checkIn: string,
  checkOut: string
) => {
  return (
    <>
      <Grid
        container
        border={4}
        borderColor={theme.palette.primary.main}
        flexWrap={"nowrap"}
        height={"auto"}
        width={"auto"}
      >
        <Grid
          item
          padding={4}
          borderRight={4}
          borderColor={theme.palette.primary.main}
          md={3}
          sm={3}
          xs={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LuggageIcon fontSize={"large"} sx={{ color: "white" }} />
        </Grid>
        <Grid
          item
          md={9}
          xs={9}
          sm={9}
          padding={1}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack justifyContent={"flex-start"}>
            <Typography>
              {lockerCode}, {lockerZone}
            </Typography>
            <Typography>Check In</Typography>
            <Typography fontSize={"0.8rem"}>{checkIn}</Typography>
          </Stack>
          <Stack justifyContent={"flex-end"}>
            <Typography textAlign={"end"}>
              {roomCode}, {roomZone}
            </Typography>
            <Typography textAlign={"end"}>Check Out</Typography>
            <Typography textAlign={"end"} fontSize={"0.8rem"}>
              {checkOut}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  border: 2,
  borderColor: theme.palette.primary.main,
  background: "black",
};

function LockerExtendModal({
  isExtendModalOpen,
  handleCloseExtendModal,
  bookingID,
  lockerBookings,
  roomZone,
  lockerZone,
}: Props) {
  const token = useSelector((state: RootState) => state.user.accessToken);
  const [extendCount, setExtendCount] = useState<number>(1);
  const [displayEndTime, setDisplayEndTime] = useState<string>(
    lockerBookings.lockerEndRentDatetime
  );

  const dateFormat = {
    year: "numeric" as const,
    month: "short" as const,
    day: "numeric" as const,
  };
  const timeFormat = {
    hour: "2-digit" as const,
    minute: "2-digit" as const,
    hour12: false,
  };

  const convertDateTimeFormat = (fullDate: string) => {
    const unformattedDate = new Date(fullDate);
    const onlyDate = unformattedDate.toLocaleDateString(undefined, dateFormat);
    const onlyTime = unformattedDate.toLocaleTimeString(undefined, timeFormat);
    return `${onlyDate}, ${onlyTime}`;
  };

  const getDateOnly = (time: string) => {
    let formattedTime = new Date(time);
    const day = formattedTime.getDate().toString().padStart(2, "0");
    const monthNumber = formattedTime.getMonth();
    const year = formattedTime.getFullYear();
    const formattedDate = `${day}/${monthNumber}/${year}`;
    return formattedDate;
  };

  const getTimeOnly = (fullDate: string) => {
    const unformattedDate = new Date(fullDate);
    const onlyTime = unformattedDate.toLocaleTimeString(undefined, timeFormat);
    return `${onlyTime}`;
  };

  const formattedStartTime = convertDateTimeFormat(
    lockerBookings.lockerStartRentDatetime
  );
  const formattedEndTime = convertDateTimeFormat(
    lockerBookings.lockerEndRentDatetime
  );

  const handleIncrementExtend = () => {
    setExtendCount(extendCount + 1);
    const initialDate = new Date(displayEndTime);
    initialDate.setHours(initialDate.getHours() + 1);
    const updatedDateTime = initialDate.toString();
    setDisplayEndTime(updatedDateTime);
  };

  const handleDecrementExtend = () => {
    let decrementedCount = extendCount - 1;
    if (decrementedCount < 0) {
      return;
    }
    const initialDate = new Date(displayEndTime);
    initialDate.setHours(initialDate.getHours() - 1);
    const updatedDateTime = initialDate.toString();
    setDisplayEndTime(updatedDateTime);
    setExtendCount(decrementedCount);
  };

  const handleExtendLocker = () => {
    if (bookingID) {
      const payload = {
        lockerBookingId: lockerBookings.lockerBookingId,
        newEndRent: new Date(displayEndTime),
      };
      axiosInstance
        .put("/bookings/v1/locker/locker-extend/", payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            Notification.success("locker successfully extended");
          }
        })
        .catch((error) => {
          Notification.failed("something wrong, please try again later");
        });
    }
  };

  useEffect(() => {
    setExtendCount(0);
    setDisplayEndTime(lockerBookings.lockerEndRentDatetime);
  }, [isExtendModalOpen]);

  return (
    <Modal
      open={isExtendModalOpen}
      onClose={handleCloseExtendModal}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box sx={modalStyle} position={"absolute"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          height={"20%"}
          borderBottom={2}
          alignItems={"center"}
          borderColor={theme.palette.primary.main}
          padding={1}
          sx={{ background: "#232323" }}
        >
          <Typography variant={"h4"} color={theme.palette.primary.main}>
            Locker Extend
          </Typography>
          <IconButton onClick={handleCloseExtendModal}>
            <HighlightOffIcon
              fontSize={"medium"}
              sx={{ color: theme.palette.primary.main }}
            />
          </IconButton>
        </Stack>
        <Grid container height={"80%"}>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            padding={4}
            sx={{ borderBottom: 1, borderColor: theme.palette.primary.main }}
          >
            <Typography>B-1234567</Typography>
            {LockerContainer(
              lockerBookings.locker.code,
              lockerZone,
              lockerBookings.roomCode,
              roomZone,
              formattedStartTime,
              formattedEndTime
            )}
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            paddingY={2}
            paddingX={3}
            height={"100%"}
            sx={{ borderLeft: 2, borderColor: theme.palette.primary.main }}
          >
            <Grid container spacing={2}>
              <Grid item md={3} sm={3} xs={3}>
                <Typography marginBottom={3}>Start Time</Typography>
                <Typography>
                  {getDateOnly(lockerBookings.lockerStartRentDatetime)}
                </Typography>
                <Typography>
                  {getTimeOnly(lockerBookings.lockerStartRentDatetime)}
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                sm={3}
                xs={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <EastIcon
                  sx={{
                    color: "white",
                    margin: 0,
                    padding: 0,
                    fontSize: "3rem",
                  }}
                />
              </Grid>
              <Grid item md={3} sm={3} xs={3}>
                <Typography textAlign={"end"} marginBottom={3}>
                  End Time
                </Typography>
                <Typography
                  textAlign={"end"}
                  sx={{ textDecoration: "line-through" }}
                >
                  {getDateOnly(lockerBookings.lockerStartRentDatetime)}
                </Typography>
                <Typography
                  textAlign={"end"}
                  sx={{ textDecoration: "line-through" }}
                >
                  {getTimeOnly(lockerBookings.lockerEndRentDatetime)}
                </Typography>
              </Grid>
              <Grid item md={3} sm={3} xs={3}>
                <Typography textAlign={"end"} marginBottom={3}>
                  New End Time
                </Typography>
                <Typography textAlign={"end"}>
                  {getDateOnly(displayEndTime)}
                </Typography>
                <Typography textAlign={"end"}>
                  {getTimeOnly(displayEndTime)}
                </Typography>
              </Grid>
            </Grid>
            <Stack
              paddingLeft={3}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              height={"24%"}
              marginTop={2}
              sx={{ background: theme.palette.primary.main }}
            >
              <Typography color={"#000"} fontWeight={700}>
                Extends
              </Typography>
              <Stack
                height={"100%"}
                direction={"row"}
                sx={{ background: "#232323" }}
              >
                <Button
                  onClick={handleDecrementExtend}
                  sx={{
                    border: 1,
                    borderColor: theme.palette.primary.main,
                    borderRadius: 0,
                  }}
                >
                  -
                </Button>
                <Box
                  width={"6em"}
                  border={1}
                  borderColor={theme.palette.primary.main}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {extendCount} hr
                </Box>
                <Button
                  onClick={handleIncrementExtend}
                  sx={{
                    border: 1,
                    borderColor: theme.palette.primary.main,
                    borderRadius: 0,
                  }}
                >
                  +
                </Button>
              </Stack>
            </Stack>
            <Stack justifyContent={"flex-end"} direction={"row"} marginTop={1}>
              <Button
                onClick={handleExtendLocker}
                sx={{
                  backgroundColor: "#232323",
                  border: 1,
                  borderColor: theme.palette.primary.main,
                }}
              >
                Save
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default LockerExtendModal;
