import {
  Stack,
  Grid,
  Typography,
  IconButton,
  Alert,
  Divider,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import theme from "../../assets/theme/theme";
import ManagementModalFormNumberField from "../management/ManagementModalFormNumberField";
import { Formik, useFormik } from "formik";
import { number } from "yup";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import { store } from "../../redux/store";

interface RoomSettingsProps {
  gracePeriods?: string | undefined;
  interval?: string | undefined;
  onRoomSettingsVisibilityChange: (isVisible: boolean) => void;
  settingsGracePeriodId?: number;
  settingsIntervalId?: number;
}
export default function RoomSettingsDetail({
  onRoomSettingsVisibilityChange,
  gracePeriods,
  interval,

  settingsGracePeriodId,
  settingsIntervalId,
}: RoomSettingsProps) {
  const [showRoomSettingsDetail, setShowRoomSettingsDetail] = useState(false);

  const handleButtonClick = () => {
    setShowRoomSettingsDetail(false);

    // Pass the boolean value to the parent component
    onRoomSettingsVisibilityChange(false);
  };

  const [gracePeriod, setGracePeriod] = useState<number>(0);
  const [showIntv, setShowIntv] = useState<number>(0);

  interface IRoomSettingsDetail {
    gracePeriod: number;
    showIntv: number;
  }

  const formikInitialValue: IRoomSettingsDetail = {
    gracePeriod: Number(gracePeriods),
    showIntv: Number(interval),
  };

  useEffect(() => {
    setGracePeriod(Number(gracePeriods));
    setShowIntv(Number(interval));
  }, []);

  const token = store.getState().user.accessToken;

  const submitGracePeriodDetail = (gp: number) => {
    const payload = {
      settingsId: String(settingsGracePeriodId),
      newDescription: String(gp),
    };
    axiosInstance
      .put(`/lot-settings/edit-settings-description/`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success("Settings updated successfully");
        console.log("Settings updated successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error updating settings:", error);
      });
  };

  const submitIntervalDetail = (intv: number) => {
    const payload = {
      settingsId: String(settingsIntervalId),
      newDescription: String(intv),
    };
    axiosInstance
      .put(`/lot-settings/edit-settings-description/`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success("Settings updated successfully");
        console.log("Settings updated successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error updating settings:", error);
      });
  };
  const handleSubmit = (values: IRoomSettingsDetail) => {
    submitGracePeriodDetail(values.gracePeriod);
    submitIntervalDetail(values.showIntv);
  };
  return (
    <Grid container width={"100%"} padding={5} spacing={2}>
      {/**Room Setting Section */}
      <Grid item xs={1}>
        <Button onClick={handleButtonClick}>Back</Button>
      </Grid>
      <Grid item xs={11}>
        <Typography variant="h3" marginBottom={3}>
          Room Settings Details
        </Typography>
      </Grid>

      <Formik
        initialValues={formikInitialValue}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          handleSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid item xs={12} px={2}>
              <ManagementModalFormNumberField
                label="Grace Period"
                value={values.gracePeriod}
                name={"gracePeriod"}
                handleChange={handleChange}
                handleBlur={handleBlur}
                textFieldType={"number"}
                endAdornment={
                  <Typography fontWeight={600} paddingLeft={"5em"}>
                    mins
                  </Typography>
                }
                setFieldValue={setFieldValue}
              />
            </Grid>

            <Grid item xs={12} px={2}>
              <ManagementModalFormNumberField
                label="No Show intv."
                value={values.showIntv}
                name={"showIntv"}
                handleChange={handleChange}
                handleBlur={handleBlur}
                textFieldType={"number"}
                endAdornment={
                  <Typography fontWeight={600} paddingLeft={"5px"}>
                    mins
                  </Typography>
                }
                setFieldValue={setFieldValue}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider
                orientation="horizontal"
                flexItem
                sx={{
                  borderBottomWidth: 3.5,
                  borderColor: "#232323",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="flex-end"
              alignItems="flex-end"
              py={2}
              padding={1}
            >
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
    </Grid>
  );
}
