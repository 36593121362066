import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'



export interface UserState {
    accountId: string;
    name: string;
    username: string;
    email: string;
    role: string;
    permissions: string[];
    accessToken: string;
    lotId:string;
}

const initialState : UserState = {
    accountId: "",
    username: "",
    name: "",
    email: "",
    role: "",
    permissions: [],
    accessToken: "",    
    lotId:"",

}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserData: (state, action : PayloadAction<UserState>) => {
            state.accessToken = action.payload.accessToken;
            state.accountId = action.payload.accountId;
            state.email = action.payload.email;
            state.role = action.payload.role;
            state.username = action.payload.username;
            state.permissions = action.payload.permissions;
            state.name = action.payload.name;
            state.lotId=action.payload.lotId
        },
        removeUserData: (state) => {
            state.accessToken = "";
            state.accountId = "";
            state.email = "";
            state.role = "";
            state.username = "";
            state.permissions = [];
            state.name = "";
            state.lotId="";
        },
    }
})

export const { setUserData, removeUserData } = userSlice.actions;
export default userSlice.reducer;