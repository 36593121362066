export const durationOptions = [
  { time: 3, label: "3 Hours" },
  { time: 6, label: "6 Hours" },
  { time: 12, label: "12 Hours" },
  { time: 24, label: "24 Hours" },
];

export const durationOptionsV2 = [
  { time: 1, label: "1 Hour" },
  { time: 3, label: "3 Hours" },
  { time: 6, label: "6 Hours" },
  { time: 12, label: "12 Hours" },
  { time: 24, label: "24 Hours" },
];
