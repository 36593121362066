import { ArrowBackIos } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { FormikErrors, useFormik } from "formik";
import axiosInstance from "../../constants/axiosConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import { SketchPicker } from "react-color";
import { SetStateAction, useState } from "react";

interface IBookingSource {
  bookingSource: string;
  platformId: string;
  isOta: boolean;
  archived: boolean;
  colorTags: string;
  canLatePayment: boolean;
  otaCode: string;
}

const initialBookingSource: IBookingSource = {
  bookingSource: "",
  platformId: "",
  isOta: false,
  archived: false,
  colorTags: "#FFFFFF",
  canLatePayment: false,
  otaCode: "",
};

const CreateBookingSource = (props: {
  handleCloseCreateBookingSource: VoidFunction;
  setPlatformData: React.Dispatch<SetStateAction<IBookingSource[]>>;
}) => {
  const token = useSelector((state: RootState) => state.user.accessToken);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const formik = useFormik({
    initialValues: initialBookingSource,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      const apiUrl = "/bookings/v1/platforms/create-platform/";

      const formData = {
        platform: values.isOta
          ? "OTA - " + values.bookingSource
          : values.bookingSource,
        colorTags: values.colorTags,
        canLatePayment: values.canLatePayment,
        otaCode: values.otaCode,
        isOta: values.isOta,
      };

      axiosInstance
        .post(apiUrl, formData, config)
        .then((res) => {
          Notification.success(res.data.message);
          props.handleCloseCreateBookingSource();
          props.setPlatformData((prevPlatform) => {
            const newPlatform: IBookingSource = {
              ...values,
              bookingSource: values.isOta
                ? "OTA - " + values.bookingSource
                : values.bookingSource,
              platformId: res.data.data,
            };
            return [newPlatform, ...prevPlatform];
          });
        })
        .catch((res) => {
          Notification.failed(res.response.data.msg);
        })
        .finally(() => actions.setSubmitting(false));
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack width={"100%"} spacing={5}>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Button onClick={props.handleCloseCreateBookingSource}>
            <ArrowBackIos fontSize="small" />
            BACK
          </Button>
          <Typography variant="h3">Create Booking Source</Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={3}>
          <Typography variant="h4" width={"180px"}>
            Booking Source
          </Typography>
          <TextField
            fullWidth
            variant="standard"
            name="bookingSource"
            value={formik.values.bookingSource}
            onChange={formik.handleChange}
            inputProps={{
              style: { textTransform: "none" },
            }}
          />
        </Stack>

        <Stack direction={"row"} alignItems={"center"} spacing={3}>
          <Typography variant="h4" width={"180px"}>
            Color Tags
          </Typography>
          <ColorTagCell
            data={formik.values.colorTags}
            setFieldValue={formik.setFieldValue}
          />
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={3}>
          <Typography variant="h4" width={"180px"}>
            Late Payment?
          </Typography>
          <Switch
            checked={formik.values.canLatePayment}
            onChange={(event) => {
              if (event.target.checked) {
                formik.setFieldValue("canLatePayment", true);
              } else {
                formik.setFieldValue("canLatePayment", false);
              }
            }}
          />
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={3}>
          <Typography variant="h4" width={"180px"}>
            Is OTA
          </Typography>
          <Switch
            checked={formik.values.isOta}
            onChange={(event) => {
              if (event.target.checked) {
                formik.setFieldValue("isOta", true);
              } else {
                formik.setFieldValue("isOta", false);
                formik.setFieldValue("otaCode", "");
              }
            }}
          />
        </Stack>
        {formik.values.isOta && (
          <Stack direction={"row"} alignItems={"center"} spacing={3}>
            <Typography variant="h4" width={"180px"}>
              OTA Code
            </Typography>
            <TextField
              fullWidth
              variant="standard"
              name="otaCode"
              value={formik.values.otaCode}
              onChange={formik.handleChange}
              inputProps={{
                style: { textTransform: "none" },
              }}
            />
          </Stack>
        )}
        <Box display={"flex"} justifyContent={"end"}>
          <Button variant="outlined" type="submit" sx={{ width: "80px" }}>
            {formik.isSubmitting ? (
              <CircularProgress size={24} color="primary" />
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

const ColorTagCell = (props: {
  data: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IBookingSource>>;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [color, setColor] = useState(props.data);

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleChange = (newColor: { hex: any }) => {
    setColor(newColor.hex);
    props.setFieldValue("colorTags", newColor.hex);
  };

  return (
    <>
      <Box
        width={65}
        height={30}
        bgcolor={color}
        border={1}
        borderColor="grey.500"
        onClick={handleOpen}
      ></Box>
      <Modal open={showModal} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxWidth: 400,
            minWidth: 200,
            borderRadius: 4,
          }}
        >
          <SketchPicker color={color} onChange={handleChange} />
          <Button onClick={handleClose}>Close</Button>
        </Box>
      </Modal>
    </>
  );
};

export default CreateBookingSource;
