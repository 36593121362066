import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_VisibilityState,
} from "material-react-table";
import { Dispatch, SetStateAction } from "react";
import theme from "../../../assets/theme/theme";

interface CustomTablePaginationProps<T extends object> {
  data: T[];
  columns: MRT_ColumnDef<T>[];
  isLoading: boolean;
  pagination?: MRT_PaginationState;
  handlePaginationChange?: Dispatch<SetStateAction<MRT_PaginationState>>;
  rowCount?: number;
  enableRowSelection?: boolean;
  enableTopToolbar?: boolean;
  rowSelection?: MRT_RowSelectionState;
  onRowSelect?: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
  columnVisibility?: MRT_VisibilityState;
  oddEvenBackground?: boolean;
  globalFilter?: string;
  backgroundColor?: string;
  showColumnFilters?: boolean;
  manualPagination?: boolean;
}

const CustomTable = <T extends object>({
  data,
  columns,
  isLoading,
  pagination,
  handlePaginationChange,
  rowCount,
  enableRowSelection,
  enableTopToolbar,
  onRowSelect,
  rowSelection,
  columnVisibility,
  oddEvenBackground,
  globalFilter,
  backgroundColor,
  showColumnFilters,
  manualPagination,
}: CustomTablePaginationProps<T>) => {
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      state={{
        pagination,
        isLoading,
        ...(rowSelection && { rowSelection }),
        columnVisibility: columnVisibility ? columnVisibility : undefined,
        showColumnFilters: showColumnFilters ? showColumnFilters : undefined,
        globalFilter,
      }}
      manualPagination={manualPagination}
      onPaginationChange={handlePaginationChange}
      enableTopToolbar={enableTopToolbar || false}
      enableRowSelection={enableRowSelection || false}
      rowCount={rowCount}
      onRowSelectionChange={onRowSelect ? onRowSelect : undefined}
      muiTableHeadProps={{
        sx: {
          ".MuiTableHead-root": {
            border: 2,
            borderStyle: "solid",
            borderColor: theme.palette.primary.main,
          },
        },
      }}
      muiTableHeadCellProps={{
        sx: {
          color: theme.palette.primary.main,
          backgroundColor: backgroundColor ? backgroundColor : "black",
          ".MuiSvgIcon-root.MuiTableSortLabel-icon": {
            color: theme.palette.primary.main,
          },
          borderStyle: "none",
          borderBottomStyle: "solid",
          borderBottomColor: theme.palette.primary.main,
        },
      }}
      muiTableHeadCellColumnActionsButtonProps={{
        sx: {
          display: "none",
        },
      }}
      muiTableBodyProps={{
        sx: {
          backgroundColor: backgroundColor
            ? backgroundColor
            : theme.palette.background.default,
          "& .MuiTypography-root": {
            color: theme.palette.primary.main,
          },
        },
      }}
      muiTableBodyRowProps={({ staticRowIndex }) => ({
        sx: {
          backgroundColor:
            staticRowIndex % 2 === 0 && oddEvenBackground
              ? theme.palette.background.default
              : backgroundColor
              ? backgroundColor
              : "black",
        },
      })}
      muiTableBodyCellProps={{
        sx: {
          color: "white",
          borderBottomStyle: "solid",
          borderBottomColor: theme.palette.primary.main,
        },
      }}
      muiBottomToolbarProps={{
        sx: {
          backgroundColor: backgroundColor ? backgroundColor : "black",
        },
      }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [-1],
        sx: {
          ".MuiTablePagination-displayedRows": {
            color: theme.palette.primary.main,
          },
          ".MuiTablePagination-actions .MuiButtonBase-root.MuiIconButton-root .MuiSvgIcon-root":
            {
              color: theme.palette.primary.main,
            },
          ".MuiTablePagination-actions .MuiButtonBase-root.MuiIconButton-root.Mui-disabled .MuiSvgIcon-root":
            {
              color: theme.palette.primary.main,
              opacity: 0.5,
            },
        },
      }}
      muiLinearProgressProps={{
        sx: {
          height: "10px",
        },
      }}
      muiSelectCheckboxProps={{
        sx: {
          color: theme.palette.primary.main,
        },
      }}
      muiSelectAllCheckboxProps={{
        sx: {
          color: theme.palette.primary.main,
        },
      }}
      muiTablePaperProps={{
        sx: {
          color: theme.palette.background.paper,
        },
      }}
    />
  );
};
export default CustomTable;
