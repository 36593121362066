import { Box, ButtonBase, Grid, Typography, useTheme } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BASE_API from "../../constants/api";
import axiosInstance from "../../constants/axiosConfig";
import { extraCharges, transactionCategoryEnum } from "../../constants/enums";
import { ILockerRate } from "../../models/lockers/LockerInterface";
import {
  ILotSetting,
  IPaymentGuests,
  IPaymentItem,
  IPaymentMethod,
  IPayments,
  IRoomRates,
} from "../../models/payment/PaymentInterface";
import { BookingResult } from "../../pages/booking/interface";
import { EmptyExpressBookingRedux } from "../../redux/booking/expressbooking";
import { RootState } from "../../redux/store";
import { IMerch } from "../../types/merch";
import { IPromotion } from "../../types/promotion";
import { extractNumbersFromString } from "../../utils/functions";
import Notification from "../../utils/notificationConfig";
import {
  calculateTax,
  convertTo2Decimals,
  financeformat,
} from "../../utils/paymentFunction";
import POAForm from "./POAForm";
import PaymentBox from "./PaymentBox";
import PaymentBoxUnsaved from "./PaymentBoxUnsaved";
import PaymentCompletedBox from "./PaymentCompletedBox";
import PaymentTypeForm from "./PaymentTypeForm";

function updatePaymentItemsPrice(
  paymentItems: IPaymentItem[],
  roomRates: IRoomRates[],
  lockerRates: ILockerRate[],
  showerRate: IMerch[],
  duration?: number
): IPaymentItem[] {
  return paymentItems.map((paymentItem) => {
    if (paymentItem.category === transactionCategoryEnum.roomSales) {
      return updateRoomPaymentItem(paymentItem, roomRates, duration);
    } else if (paymentItem.category === transactionCategoryEnum.lockerSales) {
      return updateLockerPaymentItem(paymentItem, lockerRates);
    } else if (paymentItem.category === transactionCategoryEnum.showerSales) {
      return updateShowerPaymentItem(paymentItem, showerRate);
    }

    // Return the original payment item for other categories
    return paymentItem;
  });
}

function updateRoomPaymentItem(
  paymentItem: IPaymentItem,
  roomRates: IRoomRates[],
  duration?: number
): IPaymentItem {
  if (duration === undefined) {
    return paymentItem; // If duration is not provided, return original payment item
  }

  const matchingRoomRate = roomRates.find(
    (rate) =>
      rate.roomTypeName === paymentItem.itemType &&
      rate.hoursOfStay === duration
  );

  if (matchingRoomRate) {
    // If a matching room rate is found, update the price in the payment item
    return {
      ...paymentItem,
      price: matchingRoomRate.roomRate,
      duration: duration,
    };
  }

  // If no matching room rate is found, return the original payment item
  return paymentItem;
}

function updateLockerPaymentItem(
  paymentItem: IPaymentItem,
  lockerRates: ILockerRate[]
): IPaymentItem {
  const matchingLockerRate = lockerRates.find(
    (rate) => rate.hoursOfUsage === paymentItem.duration
  );

  if (matchingLockerRate) {
    // If a matching locker rate is found, update the price in the payment item
    return {
      ...paymentItem,
      price: parseFloat(matchingLockerRate.lockerRate),
    };
  }

  // If no matching locker rate is found, return the original payment item
  return paymentItem;
}

function updateShowerPaymentItem(
  paymentItem: IPaymentItem,
  showerRate: IMerch[]
): IPaymentItem {
  // If a matching locker rate is found, update the price in the payment item
  if (showerRate.length > 0) {
    return {
      ...paymentItem,
      price: showerRate[0].sum,
    };
  }

  // If no matching locker rate is found, return the original payment item
  return paymentItem;
}
const PaymentForm = ({
  paymentItem,
  bookingGuest,
  bookingId,
  getPastTransactions,
  width,
  margin,
  height,
  newItem,
  posItem,
  posFeesList,
  isExpressBooking,
  refetchMerch,
  getTransactionCompleted,
  handleCompletePOSPayment,
  latestBookingStatus,
  payment,
  sorting,
  searchQuery,
  isCityLedger,
  isLoading,
  bookingData,
  paymentType,
  handleDeleteRefresh,
  handleSetPaymentFormReady,
}: {
  paymentItem: IPaymentItem[];
  bookingGuest: IPaymentGuests;
  bookingId: string;
  getPastTransactions: boolean;
  width?: string;
  margin?: string;
  height?: string;
  newItem?: IPaymentItem[];
  posItem?: IPaymentItem[];
  posFeesList?: ILotSetting[];
  isExpressBooking: boolean;
  refetchMerch?: boolean;
  getTransactionCompleted?: React.Dispatch<React.SetStateAction<IPayments[]>>;
  handleCompletePOSPayment?: VoidFunction;
  latestBookingStatus: string;
  payment?: IPayments[];
  sorting?: string;
  searchQuery?: string;
  isCityLedger?: boolean;
  isLoading?: boolean;
  bookingData?: BookingResult;
  paymentType?: string;
  handleDeleteRefresh?: (paymentItem: IPaymentItem) => void;
  handleSetPaymentFormReady?: (value: boolean) => void;
}) => {
  const theme = useTheme();
  const accountId = useSelector((state: RootState) => state.user.accountId);
  const lotId = useSelector((state: RootState) => state.user.lotId);
  const dispatch = useDispatch();

  const [availableRoom, setAvailableRoom] =
    useState<IPaymentItem[]>(paymentItem);

  useEffect(() => {
    setAvailableRoom(paymentItem);
  }, [paymentItem]);

  const [openPOAModal, setOpenPOAModal] = useState<boolean>(false);
  const [openPaymentModal, setOpenPaymentModal] = useState<IPayments | null>(
    null
  );

  //Used to trigger refetch transactions, Need to change after better solution is found
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [roomRates, setRoomRates] = useState<IRoomRates[]>([]);
  const [lockerRates, setLockerRates] = useState<ILockerRate[]>([]);
  const [showerRate, setShowerRate] = useState<IMerch[]>([]);

  const isChannelCollect = (transaction: IPayments) =>
    transaction.paymentTypeName === "Channel Collect" ||
    transaction.paymentRemarks?.toLowerCase().includes("channel collect") ||
    false;

  const handlePaymentModalOpen = (data: IPayments) => {
    setOpenPaymentModal(data);
  };

  const handlePaymentModalClose = () => {
    setOpenPaymentModal(null);
    handleRefetch();
  };

  const handlePOAModalOpen = () => {
    setOpenPOAModal(true);
  };

  const handlePOAModalClose = () => {
    setOpenPOAModal(false);
  };

  const [poaData, setPOAData] = useState<IPayments[]>([
    {
      invoiceNo: "1",
      transactionStatus: "Pending Payment",
      customer: bookingGuest.customerStayingId,
      sum: 0,
      amountPaid: 0,
      paymentType: "Pending",
      debitAmount: 0,
      creditAmount: 0,
      isRoomBooking: true,
      items: availableRoom.map((item) => ({
        itemId: item.itemId,
        itemName: item.itemName,
        itemType: item.itemType,
        category: item.category,
        quantity: item.quantity ? item.quantity : 1,
        price: 0,
        duration: item.duration,
      })),
      splitBill: false,
    },
  ]);

  const [promotions, setPromotions] = useState<IPromotion[]>([]);

  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [transactionsCompleted, setTransactionsCompleted] = useState<
    IPayments[]
  >([]);
  const token = useSelector((state: RootState) => state.user.accessToken);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [feesList, setFeesList] = useState<ILotSetting[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [loadingTransactions, setLoadingTransactions] =
    useState<boolean>(false);

  useEffect(() => {
    if (!handleSetPaymentFormReady) return;
    if (!loading && !fetchLoading && !loadingTransactions) {
      handleSetPaymentFormReady(true);
    } else {
      handleSetPaymentFormReady(false);
    }
  }, [loading, fetchLoading, loadingTransactions, handleSetPaymentFormReady]);

  //Get Service Charges
  useEffect(() => {
    const fetchFees = async () => {
      setLoading(true);
      await axiosInstance
        .get(`/lot-settings/get-settings/${accountId}`, config)
        .then((res) => {
          setFeesList(res.data.data);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchFees();
  }, [accountId]);

  //Get Payment Methods
  useEffect(() => {
    const fetchPaymentMethod = () => {
      setFetchLoading(true);
      axiosInstance
        .get("/payment/method", config)
        .then((res) => {
          if (res.status === 200) {
            setPaymentMethods(res.data);
            return;
          }
          Notification.failed(res.data.msg);
        })
        .catch((e) => {
          Notification.failed(e.response.data.msg);
        })
        .finally(() => {
          setFetchLoading(false);
        });
    };

    fetchPaymentMethod();
  }, [token]);

  //Get Transactions Made related to the booking
  const fetchTransactions = () => {
    if (!bookingId) return;
    setLoadingTransactions(true);
    axiosInstance
      .get(`/transaction/get-by-booking-id/${bookingId}/`, config)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            const dataTransaction: IPayments[] = res.data.map((data: any) => ({
              transactionId: data.transactionId,
              invoiceNo: data.invoiceNo,
              transactionStatus: data.transactionStatus,
              sum: parseFloat(data.sum),
              amountPaid: parseFloat(data.debitAmount),
              status: data.transactionStatus,
              paymentType: data.paymentType,
              customer: data.customer,
              transactionDatetime: data.transactionDatetime,
              creditAmount: parseFloat(data.creditAmount),
              debitAmount: parseFloat(data.debitAmount),
              items: data.items.map((item: any) => ({
                ...item,
                price:
                  typeof item.price === "string"
                    ? parseFloat(item.price)
                    : item.price,
              })),
              isRoomBooking: data.isRoomBooking,
              serviceChargeAmount: parseFloat(data.serviceChargeAmount),
              taxAmount: parseFloat(data.taxAmount),
              promotionAmount: parseFloat(data.promotionAmount),
              paymentReference: data.paymentReference,
              paymentRemarks: data.paymentRemarks,
              paymentTypeName: data.paymentType.paymentType,
              pan: data.pan ? data.pan : null,
              guestGiven: data.guestGiven ? data.guestGiven : null,
              guestChange: data.guestChange ? data.guestChange : null,
              cashierName: data.cashierName ? data.cashierName : "",
            }));

            // const sortedTransaction = dataTransaction.sort(
            // 	(a: IPayments, b: IPayments) => {
            // 		if (
            // 			a.transactionStatus === "Pending Payment" &&
            // 			b.transactionStatus !== "Pending Payment"
            // 		) {
            // 			return -1;
            // 		} else if (
            // 			a.transactionStatus !== "Pending Payment" &&
            // 			b.transactionStatus === "Pending Payment"
            // 		) {
            // 			return 1;
            // 		}
            // 		return 0;
            // 	}
            // );

            const filteredTransaction =
              isCityLedger === undefined
                ? dataTransaction
                : dataTransaction.filter(
                    (transaction) =>
                      !isCityLedger || isChannelCollect(transaction)
                  );

            const filteredTransactions = filteredTransaction.filter(
              ({ transactionStatus }) =>
                // Include all transactions
                !payment ||
                payment.length === 0 ||
                // Filter by status
                transactionStatus == payment[0].transactionStatus
            );

            const sortedTransactions = filteredTransactions
              .slice()
              .sort((a, b) => {
                const dateA = new Date(a.transactionDatetime || "").getTime();
                const dateB = new Date(b.transactionDatetime || "").getTime();

                return sorting == "ascending" ? dateA - dateB : dateB - dateA;
              });
            setTransactionsCompleted(sortedTransactions);
            if (getTransactionCompleted) {
              getTransactionCompleted(sortedTransactions);
            }
          }
        }
      })
      .finally(() => setLoadingTransactions(false));
  };
  useEffect(() => {
    if (!openPaymentModal && getPastTransactions) {
      fetchTransactions();
      dispatch(EmptyExpressBookingRedux());
    }
  }, [openPaymentModal, refetchMerch, payment]);

  // useEffect(() => {
  //   let timerId: NodeJS.Timeout;

  //   const delayedFetch = () => {
  //     // Fetch transactions after a delay of 1000 milliseconds (1 second)
  //     timerId = setTimeout(() => {
  //       fetchTransactions();
  //       dispatch(EmptyExpressBookingRedux());
  //     }, 500);
  //   };

  //   // Start the timer when searchQuery changes
  //   if (searchQuery !== "") {
  //     delayedFetch();
  //   }

  //   // Cleanup function to clear the timer when searchQuery changes
  //   return () => {
  //     clearTimeout(timerId);
  //   };
  // }, [searchQuery]);

  const handleRefetch = () => {
    fetchTransactions();
  };

  //Get Rates
  useEffect(() => {
    const fetchRates = () => {
      if (!bookingId) return;
      const apiUrlParams = new URLSearchParams();
      apiUrlParams.append("withAccountsLot", "true");
      apiUrlParams.append("specificPlatformTierBookingId", bookingId);
      axiosInstance
        .get(`/rooms/rate/?${apiUrlParams.toString()}`, config)
        .then((res) => {
          const updatedRoomRates = res.data.map((rate: any) => ({
            ...rate,
            roomRate: parseFloat(rate.roomRate),
          }));

          setRoomRates(updatedRoomRates);
        })
        .catch((res) => res.response.data.error);
    };
    fetchRates();
  }, []);

  useEffect(() => {
    const fetchLockerRates = () => {
      axiosInstance
        .get(`/merch/`, {
          params: {
            no_archived: true,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => {
          const data = res.data.merchs.filter(
            (data: IMerch) => data.itemDetails === "Locker"
          );

          const convertedData: ILockerRate[] = data.map((data: any) => ({
            lockerRateID: "",
            tiers: "",
            hoursOfUsage: extractNumbersFromString(data.itemName)[0],
            lockerRate: parseFloat(data.sum),
            isLatest: true,
            lot: lotId,
          }));

          setLockerRates(convertedData);
        })
        .catch((err: AxiosError) => {
          Notification.failed(err.message);
        });
    };
    const fetchShowerRates = () => {
      axiosInstance
        .get(`/merch/`, {
          params: {
            no_archived: true,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => {
          const data = res.data.merchs
            .filter((data: IMerch) => data.itemDetails === "Shower")
            .map((data: any) => ({
              ...data,
              sum: parseFloat(data.sum),
            }));

          setShowerRate(data);
        })
        .catch((err: AxiosError) => {
          Notification.failed(err.message);
        });
    };
    fetchLockerRates();
    fetchShowerRates();
  }, []);

  //Filter Available Item
  useEffect(() => {
    const roomInTransactionCompleted = transactionsCompleted.flatMap(
      (transactions) => transactions.items.map((item) => item.itemName)
    );

    setAvailableRoom(
      availableRoom.filter(
        (item) => !roomInTransactionCompleted.includes(item.itemName)
      )
    );
  }, [transactionsCompleted]);

  ///Get All promotions for the purpose to delete items from POA
  useEffect(() => {
    const apiUrl = `${BASE_API}/promotions/`;
    let promotion: IPromotion[] = [];
    let individualPromotion: IPromotion[] = [];
    axiosInstance
      .get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        promotion = response.data.promotions.filter(
          (promotion: IPromotion) => promotion.archived === false
        );
      });

    const apiUrl1 = `/promotions/individual-promo/list`;
    axiosInstance
      .get(apiUrl1, {
        params: {
          customerId: bookingGuest.customerStayingId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status === "failed") {
          return;
        }
        individualPromotion = response.data.data.map((data: any) => ({
          ...data,
          promotionId: data.individualPromoId,
          visibleAtWebsite: true,
        }));
      });

    setPromotions([...promotion, ...individualPromotion]);
  }, [token]);

  useEffect(() => {
    const updatedPOAData = poaData
      .map((poa) => {
        const updatedItems = poa.items.filter((poaItem) => {
          return !transactionsCompleted.some((transaction) =>
            transaction.items.some(
              (transactionItem) =>
                transactionItem.itemName === poaItem.itemName &&
                transactionItem.itemType !== extraCharges.tax &&
                transactionItem.itemType !== extraCharges.serviceCharge
            )
          );
        });

        return {
          ...poa,
          items: updatedItems,
        };
      })
      .filter((poa) => {
        // Filter out items with exactly two items, if both having type extraCharges.tax and extraCharges.serviceCharge, then remove the POA
        if (poa.items.length === 2) {
          const isTaxServiceChargeCombo = poa.items.every(
            (poaItem) =>
              poaItem.itemType === extraCharges.tax ||
              poaItem.itemType === extraCharges.serviceCharge
          );
          return !isTaxServiceChargeCombo;
        }

        // Keep items with more than two items
        return poa.items.length > 0;
      });

    setPOAData(updatedPOAData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableRoom, transactionsCompleted]);

  useEffect(() => {
    const serviceChargePercentage = feesList.find(
      (fee) => fee.settingsCategory === extraCharges.serviceCharge
    );
    const SSTPercentage = feesList.find(
      (fee) => fee.settingsCategory === extraCharges.tax
    );

    //Create POA if there are no POA and Have New Item
    if (poaData.length === 0 && newItem && newItem.length > 0) {
      const newItemSum = newItem?.reduce((acc, curr) => {
        if (curr.quantity) {
          acc = acc + curr.price * curr.quantity;
        } else {
          acc = acc + curr.price;
        }
        return acc;
      }, 0);
      const serviceCharge =
        (newItemSum *
          parseFloat(
            serviceChargePercentage?.settingsDescription.replace("%", "")!
          )) /
        100;

      const sstCharge =
        (newItemSum *
          parseFloat(SSTPercentage?.settingsDescription.replace("%", "")!)) /
        100;

      const totalDebit = convertTo2Decimals(
        newItemSum + serviceCharge + sstCharge
      );

      setPOAData([
        {
          ...poaData[0],
          items: [
            ...newItem,
            {
              itemId: serviceChargePercentage?.settingsId!,
              itemName: serviceChargePercentage?.settingsName!,
              itemType: serviceChargePercentage?.settingsCategory!,
              price: serviceCharge,
              category: transactionCategoryEnum.serviceCharge,
            },
            {
              itemId: SSTPercentage?.settingsId!,
              itemName: SSTPercentage?.settingsName!,
              itemType: SSTPercentage?.settingsCategory!,
              price: sstCharge,
              category: transactionCategoryEnum.tax,
            },
          ],
          sum: newItemSum,
          debitAmount: totalDebit,
          creditAmount: totalDebit,
          customer: bookingGuest.customerStayingId,
        },
      ]);
      return;
    }

    //Change Quantity
    if (poaData.length > 0 && newItem && newItem.length > 0) {
      const updatedPOAData = [...poaData];

      const paymentToUpdate = updatedPOAData.find((payment) =>
        payment.items.some((item) => item.itemId === newItem[0].itemId)
      );

      const serviceCharge =
        (newItem[0].price *
          parseFloat(
            serviceChargePercentage?.settingsDescription.replace("%", "")!
          )) /
        100;

      const sstCharge =
        (newItem[0].price *
          parseFloat(SSTPercentage?.settingsDescription.replace("%", "")!)) /
        100;

      if (paymentToUpdate && newItem[0].quantity) {
        const itemToUpdate = paymentToUpdate.items.find(
          (item) => item.itemId === newItem[0].itemId
        );

        if (itemToUpdate && itemToUpdate.quantity) {
          itemToUpdate.quantity = itemToUpdate.quantity + 1;
        }
        paymentToUpdate.sum = convertTo2Decimals(
          paymentToUpdate.sum + newItem[0].price
        );
        paymentToUpdate.debitAmount = convertTo2Decimals(
          paymentToUpdate.debitAmount +
            newItem[0].price +
            serviceCharge +
            sstCharge
        );
        paymentToUpdate.creditAmount = convertTo2Decimals(
          paymentToUpdate.creditAmount +
            newItem[0].price +
            serviceCharge +
            sstCharge
        );
      } else {
        updatedPOAData[0].items.unshift(...newItem);
        updatedPOAData[0].debitAmount = convertTo2Decimals(
          updatedPOAData[0].debitAmount +
            newItem[0].price +
            serviceCharge +
            sstCharge
        );
        updatedPOAData[0].creditAmount = convertTo2Decimals(
          updatedPOAData[0].creditAmount +
            newItem[0].price +
            serviceCharge +
            sstCharge
        );
      }

      const taxItem = updatedPOAData[0].items.find(
        (item) => item.itemType === extraCharges.tax
      );
      const serviceChargeItem = updatedPOAData[0].items.find(
        (item) => item.itemType === extraCharges.serviceCharge
      );

      if (taxItem) {
        taxItem.price = convertTo2Decimals(taxItem.price + sstCharge);
      }

      if (serviceChargeItem) {
        serviceChargeItem.price = convertTo2Decimals(
          serviceChargeItem.price + serviceCharge
        );
      }

      setPOAData(updatedPOAData);
    }
  }, [newItem]);

  useEffect(() => {
    if (posItem && posFeesList) {
      const serviceChargePercentage = posFeesList.find(
        (fee) => fee.settingsCategory === extraCharges.serviceCharge
      );
      const SSTPercentage = posFeesList.find(
        (fee) => fee.settingsCategory === extraCharges.tax
      );

      const adjustmentSum = posItem
        .filter((item) => ["Adjustment"].includes(item.itemName))
        .reduce((acc, curr) => (acc = acc + curr.price), 0);

      const posSum = posItem
        .filter((item) => !["Adjustment"].includes(item.itemName))
        .reduce((acc, curr) => {
          if (curr.quantity) {
            acc = acc + curr.price * curr.quantity;
          } else {
            acc = acc + curr.price;
          }
          return acc;
        }, 0);

      const serviceCharge =
        (posSum *
          parseFloat(
            serviceChargePercentage?.settingsDescription.replace("%", "")!
          )) /
        100;

      const sstCharge =
        (posSum *
          parseFloat(SSTPercentage?.settingsDescription.replace("%", "")!)) /
        100;

      const totalDebit = convertTo2Decimals(
        posSum + serviceCharge + sstCharge + adjustmentSum
      );

      setPOAData([
        {
          ...poaData[0],
          items: [
            ...posItem,
            {
              itemId: serviceChargePercentage?.settingsId!,
              itemName:
                serviceChargePercentage?.settingsName! +
                " " +
                serviceChargePercentage?.settingsDescription,
              itemType: serviceChargePercentage?.settingsCategory!,
              category: transactionCategoryEnum.serviceCharge,
              price: serviceCharge,
            },
            {
              itemId: SSTPercentage?.settingsId!,
              itemName:
                SSTPercentage?.settingsName! +
                " " +
                SSTPercentage?.settingsDescription,
              itemType: SSTPercentage?.settingsCategory!,
              category: transactionCategoryEnum.tax,
              price: sstCharge,
            },
          ],
          sum: posSum,
          debitAmount: totalDebit,
          creditAmount: totalDebit,
          customer: bookingGuest.customerStayingId,
        },
      ]);
    }
  }, [posItem, posFeesList]);

  useEffect(() => {
    if (
      roomRates &&
      lockerRates &&
      showerRate &&
      poaData.length > 0 &&
      poaData[0].items[0] &&
      !newItem &&
      !openPOAModal &&
      !posItem
    ) {
      //Validation for room transfer, If amount of room is the same as transaction room, then no need to generate a new POA (Room Transfer Bug Fix)
      if (transactionsCompleted.length > 0) {
        const amountOfRooms = transactionsCompleted
          .filter((transaction) => transaction.isRoomBooking)
          .map((transaction) =>
            transaction.items
              .filter(
                (item) =>
                  item.itemType !== extraCharges.tax &&
                  item.itemType !== extraCharges.serviceCharge
              )
              .map((item) => item.itemName)
          ).length;

        if (amountOfRooms === paymentItem.length) {
          setAvailableRoom([]);
          setPOAData([]);
          return;
        }
      }
      //-------------------------------------------------Remove items that are already in transaction completed--------------------------------------------------//
      const roomInTransactionCompleted = transactionsCompleted.flatMap(
        (transactions) => transactions.items.map((item) => item.itemName)
      );

      const processPOA = poaData.map((poaData) => ({
        ...poaData,
        items: poaData.items.filter(
          (item) => !roomInTransactionCompleted.includes(item.itemName)
        ),
      }));

      //---------------------------------------------------------------------------------------------------------------------------------------------------------//

      const itemTypeArray: string[] = processPOA
        .map((payment) => payment.items.map((item) => item.itemType))
        .flat();

      if (
        processPOA[0].items.length > 0 &&
        processPOA.filter((poa) => poa.isRoomBooking).length > 0 &&
        availableRoom.length >= 0
      ) {
        const duration =
          availableRoom.length > 0
            ? availableRoom[0].duration
            : processPOA
                .filter((poa) => poa.isRoomBooking)[0]
                .items.filter(
                  (item) => item.category === transactionCategoryEnum.roomSales
                )[0].duration;

        if (duration) {
          const roomPrices = roomRates.filter(
            (rate) =>
              itemTypeArray.includes(rate.roomTypeName) &&
              rate.hoursOfStay === duration
          );

          const lockerPrices = lockerRates.filter(
            (rate) => rate.lot === lotId && rate.hoursOfUsage === duration
          );

          const updatedPoaData = processPOA.map((poa) => {
            let updatedItems = updatePaymentItemsPrice(
              poa.items,
              roomPrices,
              lockerPrices,
              showerRate,
              duration
            );

            let sum = updatedItems
              .filter((item) => !["Adjustment"].includes(item.itemName))
              .map((item) => item.price)
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
              );
            const sumBeforeTax = updatedItems
              .filter(
                (item) =>
                  item.itemType !== extraCharges.serviceCharge &&
                  item.itemType !== extraCharges.tax &&
                  item.category !== transactionCategoryEnum.rounding
              )
              .map((item) => item.price)
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
              );

            if (sum > 0 && feesList.length > 0) {
              //Use Reusable Function to Calculate Tax
              ({ sum, updatedItems } = calculateTax(
                sum,
                updatedItems,
                feesList
              ));
            }

            return {
              ...poa,
              sum: sumBeforeTax,
              creditAmount: sum,
              debitAmount: sum,
              items: updatedItems,
            };
          });

          setPOAData(updatedPoaData);
        }
      }
    }
  }, [
    roomRates,
    transactionsCompleted,
    feesList,
    newItem,
    openPOAModal,
    availableRoom,
    posItem,
    lockerRates,
    showerRate,
  ]);

  const handleAddPOA = (
    poaPaymentItems: IPaymentItem[],
    customerId: string
  ) => {
    let updatedPOAData: IPayments[] = [];

    if (poaData[0] && !poaData[0].splitBill) {
      updatedPOAData = [
        {
          invoiceNo: "2",
          customer: customerId,
          transactionStatus: "Pending Payment",
          sum: 0,
          amountPaid: 0,
          paymentType: "Pending",
          debitAmount: 0,
          creditAmount: 0,
          isRoomBooking:
            poaPaymentItems.filter(
              (item) => item.category === transactionCategoryEnum.roomSales
            ).length > 0,
          items: [
            ...poaPaymentItems.map((item) => ({
              itemId: item.itemId,
              itemName: item.itemName,
              itemType: item.itemType,
              category: item.category,
              quantity: 1,
              price: 0,
              duration: item.duration,
            })),
          ],
          splitBill: true,
        },
      ];
    } else {
      updatedPOAData = [
        ...poaData,
        {
          invoiceNo: "2",
          customer: customerId,
          transactionStatus: "Pending Payment",
          amountPaid: 0,
          sum: 0,
          paymentType: "Pending",
          debitAmount: 0,
          creditAmount: 0,
          isRoomBooking:
            poaPaymentItems.filter(
              (item) => item.category === transactionCategoryEnum.roomSales
            ).length > 0,
          items: poaPaymentItems.map((item) => ({
            itemId: item.itemId,
            itemName: item.itemName,
            itemType: item.itemType,
            category: item.category,
            quantity: 1,
            price: 0,
            duration: item.duration,
          })),
          splitBill: true,
        },
      ];
    }

    setPOAData(updatedPOAData);
    setAvailableRoom(
      availableRoom.filter((item) => !poaPaymentItems.includes(item))
    );
  };

  const handleAddPromotion = (
    promotion: IPromotion,
    poaIndex: number,
    saved: boolean
  ) => {
    // Use destructuring assignment to get the specific POA and remove it from the array
    let [poppedPOA] = poaData.splice(poaIndex, 1);

    let exactIndex = poaIndex;

    if (saved) {
      [poppedPOA] = transactionsCompleted
        .filter(
          (transaction) =>
            transaction.invoiceNo
              .toLowerCase()
              .includes(searchQuery?.toLowerCase() || "") &&
            // If isCityLedger is undefined, include all transactions
            (isCityLedger === undefined ||
              isCityLedger === isChannelCollect(transaction))
        )
        .splice(poaIndex, 1);

      exactIndex = transactionsCompleted.indexOf(poppedPOA);
    }

    // Filter out items with item types "Service Charge" or extraCharges.tax

    let updatedItems = poppedPOA.items.filter(
      (item) =>
        item.category !== transactionCategoryEnum.serviceCharge &&
        item.category !== transactionCategoryEnum.tax &&
        item.category !== transactionCategoryEnum.rounding
    );

    if (promotion.promoCode === "Rounding") {
      updatedItems = poppedPOA.items;
    }

    // Calculate the promotion deduction
    let promotionDeduct = 0;
    let promotionRounding = 0;

    if (promotion.details.includes("%")) {
      const promotionDetail = parseFloat(promotion.details);
      const promotionSum =
        (updatedItems.reduce((acc, curr) => {
          if (curr.quantity) {
            acc = acc + curr.price * curr.quantity;
          } else {
            acc = acc + curr.price;
          }
          return acc;
        }, 0) *
          promotionDetail) /
        100;
      promotionDeduct = financeformat(promotionSum).value;

      promotionRounding = financeformat(promotionSum).rounding;
    } else {
      promotionDeduct = parseFloat(promotion.details);
      if (
        poppedPOA.creditAmount < promotionDeduct &&
        promotion.promoCode !== "Rounding"
      ) {
        promotionDeduct = poppedPOA.sum;
        promotionRounding = 0;
      }
    }

    if (promotion.promoCode === "Adjustment") {
      updatedItems.push({
        itemId: promotion.promotionId,
        itemName: promotion.promoCode,
        itemType: "Adjustment",
        category: transactionCategoryEnum.adjustmentSales,
        // price: -promotionDeduct,
        price: promotionDeduct,
      });
    } else if (promotion.promoCode === "Rounding") {
      updatedItems.push({
        itemId: promotion.promotionId,
        itemName: promotion.promoCode,
        itemType: "Rounding",
        category: transactionCategoryEnum.rounding,
        // price: -promotionDeduct,
        price: promotionDeduct,
      });
    } else {
      // Add the new promotion item to the updated items array
      updatedItems.push({
        itemId: promotion.promotionId,
        itemName: promotion.promoCode,
        itemType: "Promotion",
        category: transactionCategoryEnum.promotion,
        // price: -promotionDeduct,
        price: -promotionDeduct + promotionRounding,
      });
    }

    const sumBeforeTax = updatedItems
      .filter((item) => !["Rounding"].includes(item.itemName))
      .reduce((acc, curr) => {
        if (curr.quantity) {
          return (acc = acc + curr.price * curr.quantity);
        }
        return (acc = acc + curr.price);
      }, 0);
    let afterTaxCalculation = calculateTax(
      sumBeforeTax,
      updatedItems,
      feesList
    );

    if (promotion.promoCode === "Rounding") {
      afterTaxCalculation = {
        sum: sumBeforeTax + promotionDeduct,
        updatedItems: updatedItems,
      };
    }

    const sum = updatedItems.reduce((acc, curr) => {
      if (
        curr.category === transactionCategoryEnum.lockerSales ||
        curr.category === transactionCategoryEnum.merchSales ||
        curr.category === transactionCategoryEnum.roomSales ||
        curr.category === transactionCategoryEnum.showerSales ||
        (curr.category === transactionCategoryEnum.adjustmentSales &&
          curr.itemName !== "Adjustment")
      ) {
        if (curr.quantity) {
          return (acc = acc + curr.price * curr.quantity);
        } else {
          return (acc = acc + curr.price);
        }
      } else {
        return acc;
      }
    }, 0);

    // Create an updated POA object with the modified items array
    const updatedPOA = {
      ...poppedPOA,
      sum: sum,
      debitAmount: afterTaxCalculation.sum,
      creditAmount: afterTaxCalculation.sum,
      items: afterTaxCalculation.updatedItems,
    };

    if (saved) {
      transactionsCompleted.splice(exactIndex, 1, updatedPOA);
      setTransactionsCompleted([...transactionsCompleted]);
    } else {
      // Insert the updated POA back into the poaData array
      poaData.splice(poaIndex, 0, updatedPOA);
      // Use setPOAData to trigger a state update
      setPOAData([...poaData]);
    }
  };

  const handleRemovePromotion = (
    poaIndex: number,
    promotionItemId: string,
    saved: boolean
  ) => {
    // Remove the specific POA from the array and store it in poppedPOA
    const poppedPOAArray = poaData.splice(poaIndex, 1);
    const poppedPOA = poppedPOAArray.length > 0 ? poppedPOAArray[0] : null;

    if (!poppedPOA) {
      // Handle the case where poppedPOA is null or undefined
      Notification.failed("Invalid POA object");
      return; // Exit the function early
    }

    if (saved) {
      // Remove the specific POA from transactionsCompleted if saved
      const poppedTransactionArray = transactionsCompleted.splice(poaIndex, 1);
      const poppedTransaction =
        poppedTransactionArray.length > 0 ? poppedTransactionArray[0] : null;

      if (!poppedTransaction) {
        // Handle the case where poppedTransaction is null or undefined
        Notification.failed("Invalid transaction object");
        return; // Exit the function early
      }
    }

    if (!poppedPOA || !poppedPOA.items) {
      // Handle the case where poppedPOA or its items array is undefined
      Notification.failed("Invalid POA object or items array");
      return; // Exit the function early
    }

    // Filter out the promotion item from the items array
    const updatedItems = poppedPOA.items.filter(
      (item) =>
        item.itemId !== promotionItemId &&
        item.category !== transactionCategoryEnum.serviceCharge &&
        item.category !== transactionCategoryEnum.tax &&
        item.category !== transactionCategoryEnum.rounding
    );

    // Calculate the sum before tax for the updated items
    const sumBeforeTax = updatedItems
      .filter((item) => !["Rounding"].includes(item.itemName))
      .reduce((acc, curr) => {
        if (curr.quantity) {
          return acc + curr.price * curr.quantity;
        }
        return acc + curr.price;
      }, 0);

    // Recalculate tax and update debit and credit amounts if necessary
    const afterTaxCalculation = calculateTax(
      sumBeforeTax,
      updatedItems,
      feesList
    );

    const sum = updatedItems.reduce((acc, curr) => {
      if (
        curr.category === transactionCategoryEnum.lockerSales ||
        curr.category === transactionCategoryEnum.merchSales ||
        curr.category === transactionCategoryEnum.roomSales ||
        curr.category === transactionCategoryEnum.showerSales ||
        (curr.category === transactionCategoryEnum.adjustmentSales &&
          curr.itemName !== "Adjustment")
      ) {
        if (curr.quantity) {
          return (acc = acc + curr.price * curr.quantity);
        } else {
          return (acc = acc + curr.price);
        }
      } else {
        return acc;
      }
    }, 0);

    // Create an updated POA object with the modified items array
    const updatedPOA = {
      ...poppedPOA,
      sum: sum,
      debitAmount: afterTaxCalculation.sum,
      creditAmount: afterTaxCalculation.sum, // Update this as necessary based on your requirements
      items: afterTaxCalculation.updatedItems,
    };
    if (saved) {
      transactionsCompleted.splice(poaIndex, 0, updatedPOA);
      setTransactionsCompleted([...transactionsCompleted]);
    } else {
      // Insert the updated POA back into the poaData array
      poaData.splice(poaIndex, 0, updatedPOA);
      // Use setPOAData to trigger a state update
      setPOAData([...poaData]);
    }
  };

  const handleDeleteBookingItem = (
    paymentItem: IPaymentItem,
    poppedPOA: IPayments | null,
    index: number
  ) => {
    const apiUrl = `/transaction/delete-items/`;

    const formData = {
      bookingId: bookingId,
      category: paymentItem.category,
      itemName:
        paymentItem.category === transactionCategoryEnum.roomSales ||
        paymentItem.category === transactionCategoryEnum.lockerSales
          ? paymentItem.itemName
          : "",
      count:
        paymentItem.category === transactionCategoryEnum.showerSales
          ? paymentItem.quantity
          : 0,
    };

    setIsDeleting(true);

    axiosInstance
      .put(apiUrl, formData, config)
      .then((response) => {
        Notification.success("Item has been deleted");
        if (poppedPOA) {
          let updatedItems = poppedPOA.items.filter(
            (item) =>
              item.itemId !== paymentItem.itemId &&
              item.category !== transactionCategoryEnum.serviceCharge &&
              item.category !== transactionCategoryEnum.tax
          );

          const sumBeforeTax = updatedItems
            .filter((item) => !["Adjustment"].includes(item.itemName))
            .reduce((acc, curr) => {
              if (curr.quantity) {
                return acc + curr.price * curr.quantity;
              }
              return acc + curr.price;
            }, 0);

          // Recalculate tax and update debit and credit amounts if necessary
          const afterTaxCalculation = calculateTax(
            sumBeforeTax,
            updatedItems,
            feesList
          );

          // Create an updated POA object with the modified items array
          const updatedPOA = {
            ...poppedPOA,
            sum: sumBeforeTax,
            debitAmount: afterTaxCalculation.sum,
            creditAmount: afterTaxCalculation.sum, // Update this as necessary based on your requirements
            items: updatedItems,
          };

          if (handleDeleteRefresh) {
            handleDeleteRefresh(paymentItem);
          }

          // Insert the updated POA back into the poaData array
          poaData.splice(index, 0, updatedPOA);
          // Use setPOAData to trigger a state update
          setPOAData([...poaData]);
        }
      })
      .catch((response) => console.log(response.response.data))
      .finally(() => setIsDeleting(false));
  };

  const handleRemoveItem = (
    index: number,
    paymentItem: IPaymentItem,
    saved: boolean
  ) => {
    // Remove the specific POA from the array and store it in poppedPOA
    const poppedPOAArray = poaData.splice(index, 1);
    const poppedPOA = poppedPOAArray.length > 0 ? poppedPOAArray[0] : null;

    if (
      paymentItem.category === transactionCategoryEnum.roomSales ||
      paymentItem.category === transactionCategoryEnum.lockerSales ||
      paymentItem.category === transactionCategoryEnum.showerSales
    ) {
      handleDeleteBookingItem(paymentItem, poppedPOA, index);
      return;
    }

    if (!poppedPOA) {
      // Handle the case where poppedPOA is null or undefined
      Notification.failed("Invalid POA object");
      return; // Exit the function early
    }

    if (saved) {
      // Remove the specific POA from transactionsCompleted if saved
      const poppedTransactionArray = transactionsCompleted.splice(index, 1);
      const poppedTransaction =
        poppedTransactionArray.length > 0 ? poppedTransactionArray[0] : null;

      if (!poppedTransaction) {
        // Handle the case where poppedTransaction is null or undefined
        Notification.failed("Invalid transaction object");
        return; // Exit the function early
      }
    }

    if (!poppedPOA || !poppedPOA.items) {
      // Handle the case where poppedPOA or its items array is undefined
      Notification.failed("Invalid POA object or items array");
      return; // Exit the function early
    }

    // Filter out the promotion item from the items array
    let updatedItems = poppedPOA.items.filter(
      (item) =>
        item.itemId !== paymentItem.itemId &&
        item.category !== transactionCategoryEnum.serviceCharge &&
        item.category !== transactionCategoryEnum.tax
    );

    const promotionIndex = updatedItems.findIndex(
      (item) => item.category === transactionCategoryEnum.promotion
    );

    if (promotionIndex > -1) {
      const afterPromotionItems = updatedItems.filter(
        (item) => item.category !== transactionCategoryEnum.promotion
      );
      const promotionItem = promotions.find(
        (item) => item.promotionId === updatedItems[promotionIndex].itemId
      );
      if (promotionItem) {
        //Check if Promotion exists in List and Calculate the promotion deduction
        let promotionDeduct = 0;
        let promotionRounding = 0;

        if (promotionItem.details.includes("%")) {
          const promotionDetail = parseFloat(promotionItem.details);
          const promotionSum =
            (afterPromotionItems.reduce((acc, curr) => {
              if (curr.quantity) {
                acc = acc + curr.price * curr.quantity;
              } else {
                acc = acc + curr.price;
              }
              return acc;
            }, 0) *
              promotionDetail) /
            100;
          promotionDeduct = financeformat(promotionSum).value;

          promotionRounding = financeformat(promotionSum).rounding;
        } else {
          promotionDeduct = parseFloat(promotionItem.details);
          if (poppedPOA.creditAmount < promotionDeduct) {
            promotionDeduct = poppedPOA.sum;
            promotionRounding = 0;
          }
        }

        afterPromotionItems.push({
          itemId: promotionItem.promotionId,
          itemName: promotionItem.promoCode,
          itemType: "Promotion",
          category: transactionCategoryEnum.promotion,
          // price: -promotionDeduct,
          price: -promotionDeduct + promotionRounding,
        });

        updatedItems = afterPromotionItems;
      }
    }

    // Calculate the sum before tax for the updated items
    const sumBeforeTax = updatedItems
      .filter((item) => !["Rounding"].includes(item.itemName))
      .reduce((acc, curr) => {
        if (curr.quantity) {
          return acc + curr.price * curr.quantity;
        }
        return acc + curr.price;
      }, 0);

    // Recalculate tax and update debit and credit amounts if necessary
    const afterTaxCalculation = calculateTax(
      sumBeforeTax,
      updatedItems,
      feesList
    );

    // Create an updated POA object with the modified items array
    const updatedPOA = {
      ...poppedPOA,
      sum: sumBeforeTax,
      debitAmount: afterTaxCalculation.sum,
      creditAmount: afterTaxCalculation.sum, // Update this as necessary based on your requirements
      items: updatedItems,
    };

    if (saved) {
      transactionsCompleted.splice(index, 0, updatedPOA);
      setTransactionsCompleted([...transactionsCompleted]);
    } else {
      // Insert the updated POA back into the poaData array
      poaData.splice(index, 0, updatedPOA);
      // Use setPOAData to trigger a state update
      setPOAData([...poaData]);
    }
  };

  const handleLookupGuestName = (id: string) => {
    const guest = bookingGuest.guests.find((guest) => guest.guestId === id);
    if (guest) {
      return { guestName: guest.guestName, isMember: guest.isMember };
    }
    return { guestName: "Guest", isMember: false };
  };

  const checkItemsLength = (data: IPayments) => {
    const isOnlyOneItem = data.items.filter(
      (item) =>
        item.category === transactionCategoryEnum.lockerSales ||
        item.category === transactionCategoryEnum.merchSales ||
        item.category === transactionCategoryEnum.roomSales ||
        item.category === transactionCategoryEnum.showerSales
    );

    if (isOnlyOneItem.length === 1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box
      width={width || "90%"}
      height={height || "90%"}
      margin={margin || "30px auto"}
      border={2}
      borderColor={theme.palette.primary.main}
      paddingY={2}
      paddingX={4}
      sx={{
        overflowY: "auto",
        minHeight: "500px",
      }}
    >
      <Grid container spacing={2} alignItems={"center"} sx={{ width: "100%" }}>
        {availableRoom.length > 0 && poaData && (
          <Grid
            item
            md={4}
            sm={4}
            xs={4}
            sx={{
              height: "650px",
            }}
          >
            <ButtonBase
              onClick={handlePOAModalOpen}
              sx={{
                height: "100%",
                width: "100%",
                border: "2px dashed #000",
                borderColor: "#232323",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant={"h3"} color={theme.palette.primary.main}>
                  Add POA +
                </Typography>
              </Box>
            </ButtonBase>
          </Grid>
        )}
        {poaData.map((data, index) => (
          <Grid
            key={data.invoiceNo}
            item
            md={4}
            sm={4}
            xs={4}
            sx={{
              height: "650px",
            }}
          >
            {isCityLedger && isCityLedger !== undefined ? (
              <></>
            ) : (
              <>
                <PaymentBoxUnsaved
                  data={data}
                  paymentMethods={paymentMethods}
                  bookingId={bookingId}
                  guest={handleLookupGuestName(data.customer)}
                  handlePaymentModalOpen={handlePaymentModalOpen}
                  handleRefetch={handleRefetch}
                  handleAddPromotion={handleAddPromotion}
                  index={index}
                  isExpressBooking={isExpressBooking}
                  disableSave={
                    data.items.filter(
                      (item) =>
                        item.itemType === extraCharges.tax ||
                        item.itemType === extraCharges.serviceCharge
                    ).length < 1 || (posItem ?? []).length > 0
                  }
                  handleRemoveItem={handleRemoveItem}
                  handleRemovePromotion={handleRemovePromotion}
                  isLoading={isLoading}
                  loading={loading}
                  isDeleting={isDeleting}
                  itemLength={checkItemsLength(data)}
                  guestId={bookingGuest.customerStayingId}
                />
              </>
            )}
          </Grid>
        ))}
        {transactionsCompleted
          .filter(
            (transaction) =>
              transaction.invoiceNo
                .toLowerCase()
                .includes(searchQuery?.toLowerCase() || "") &&
              // If isCityLedger is undefined, include all transactions
              (isCityLedger === undefined ||
                isCityLedger === isChannelCollect(transaction))
          )
          .map((data, index) => (
            <Grid
              key={index}
              item
              md={4}
              sm={4}
              xs={4}
              sx={{ height: "650px" }}
            >
              {data.transactionStatus === "Pending Payment" ? (
                <PaymentBox
                  data={data}
                  guest={handleLookupGuestName(data.customer)}
                  handlePaymentModalOpen={handlePaymentModalOpen}
                  index={index}
                  handleAddPromotion={handleAddPromotion}
                  latestBookingStatus={latestBookingStatus}
                  setTransactionCompleted={setTransactionsCompleted}
                  fetchLoading={fetchLoading}
                  guestId={bookingGuest.customerStayingId}
                />
              ) : (
                <PaymentCompletedBox
                  data={data}
                  latestBookingStatus={latestBookingStatus}
                  setTransactionCompleted={setTransactionsCompleted}
                  bookingData={bookingData}
                />
              )}
            </Grid>
          ))}
      </Grid>
      <POAForm
        openPOAModal={openPOAModal}
        handlePOAModalClose={handlePOAModalClose}
        availableItem={availableRoom}
        handleAddPOA={handleAddPOA}
        guests={bookingGuest}
      />
      {openPaymentModal && (
        <PaymentTypeForm
          handlePaymentModalClose={handlePaymentModalClose}
          payment={openPaymentModal}
          paymentMethods={paymentMethods}
          bookingId={bookingId}
          isExpressBooking={isExpressBooking}
          isPOSGuestPayment={
            !bookingId && bookingGuest.customerStayingId === "Non-Guest"
          }
          handleCompletePOSPayment={handleCompletePOSPayment}
        />
      )}
    </Box>
  );
};

export default PaymentForm;
