import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  CssBaseline,
  Dialog,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as OnlyLogoSVG } from "../../assets/images/Logo.svg";
import { useFormik } from "formik";
import { BookingResult, RoomBooking } from "../../pages/booking/interface";
import { SetStateAction, useEffect, useState } from "react";
import axiosInstance from "../../constants/axiosConfig";
import { store } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import { useNavigate, useParams } from "react-router-dom";
import ManagementModalHeader from "../management/ManagementModalHeader";

const ratingBar = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const initialValues = {
  rating: "",
  feedback: "",
};

const FeedbackFormNew = () => {
  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: (values) => {},
  });
  const { bookingId } = useParams();

  const [bookingNo, setBookingNo] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [rating, setRating] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");
  const handleRatingPress = (value: string) => {
    formik.setFieldValue("rating", value);
    setRating(value);
  };
  const [bookingData, setBookingData] = useState<BookingResult>();

  const [openContinueModal, setOpenContinueModal] = useState(false);

  const handleOpenContinueModal = () => {
    setOpenContinueModal(true);
  };

  const handleCloseContinueModal = () => {
    setOpenContinueModal(false);
    navigate("/consent-form/");
  };

  const handleConfirmContinueModal = () => {
    setOpenContinueModal(false); // Close the modal after confirmation
    window.location.reload();
  };

  const [bookingNumberModal, setBookingNumberModal] = useState<boolean>(
    !bookingId
  );

  const [roomBooking, setRoomBooking] = useState<RoomBooking[]>([]);

  const handleFetchBookingData = () => {
    if (bookingId) {
      const apiUrl = `/bookings/guest-portofolio/${bookingId}`;
      const config = {};
      setIsLoading(true);
      axiosInstance
        .get(apiUrl, config)
        .then((response) => {
          setBookingNumberModal(false);

          const customerBookedData = response.data.customerFirstname
            ? response.data.customerFirstname +
              " " +
              response.data.customerLastname
            : response.data.customerBooked;
          setBookingData({
            ...response.data,
            customerBooked: customerBookedData,
          });
          setRoomBooking(response.data.roomBookings);
        })
        .catch((res) => {
          Notification.failed("Booking ID Not Found");
          window.location.reload();
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (bookingNo) {
      const apiUrl = `/bookings/v1/room-booking/${bookingNo}`;
      axiosInstance
        .get(apiUrl)
        .then((response) => {
          setBookingNumberModal(false);

          setRoomBooking(response.data.data);
          console.log("print", response.data.data);
        })
        .catch((error) => {
          Notification.failed(
            "Booking Number Not Found, Please Enter the Booking Number"
          );
          window.location.reload();
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      Notification.info("Please Enter Booking Number");
      setIsLoading(false); // No bookingId or bookingNo provided
      setBookingNumberModal(true);
    }
  };

  useEffect(() => {
    if (roomBooking.length === 1 && checkedRooms.length === 0) {
      setCheckedRooms(prev => [...prev, roomBooking[0].roomCode]);
    }
  }, [roomBooking])

  useEffect(() => {
    handleFetchBookingData();
  }, [bookingNumberModal]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [checkedRooms, setCheckedRooms] = useState<string[]>([]);

  const handleOpenDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget as HTMLElement);
  };
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleRoomSelect = (roomCode: string) => {
    setCheckedRooms((prevSelectedRooms) => {
      // Toggle the selection state of the room code
      if (prevSelectedRooms.includes(roomCode)) {
        // Room code is already selected, remove it
        return prevSelectedRooms.filter(
          (selectedRoom) => selectedRoom !== roomCode
        );
      } else {
        // Room code is not selected, add it
        return [...prevSelectedRooms, roomCode];
      }
    });
  };
  const handleChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setFeedback(event.target.value);
  };
  const submitFeedbackForm = () => {
    if (checkedRooms.length === 0 && roomBooking.length > 0) {
      Notification.warning("Please select room");
      return;
    }
    const apiUrl = "/bookings/feedback-form/";
    const payload = {
      bookingNo: bookingData ? bookingData?.bookingNo : bookingNo,
      rating: rating,
      feedback: feedback,
      roomSelected: checkedRooms.join(","),
    };
    axiosInstance
      .post(apiUrl, payload)
      .then((response) => {
        Notification.success("Submitted Succssfully");
        handleOpenContinueModal();
      })
      .catch((res) => {
        if (res.response.status === 400) {
          try {
            const resMessageList : string[] = res.response.data.message.feedback
            resMessageList.forEach((item : string) => {
              Notification.warning(item);
            });
            return;
          } catch(e) {
            
          }
        }
        Notification.failed(res.message);
      });
  };
  const handleSubmit = (bookingNo: string) => {
    setBookingNo(bookingNo);
    handleFetchBookingData();
  };
  useEffect(() => {
    console.log("roomBooking", roomBooking);
  }, [bookingNo, bookingNumberModal]);
  return (
    <>
      <>
        <Modal open={bookingNumberModal}>
          <Stack
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              maxWidth: 400,
            }}
            spacing={2}
          >
            <Typography variant="h3" gutterBottom>
              Enter Booking Number
            </Typography>
            <TextField
              margin="normal"
              value={bookingNo}
              onChange={(e) => setBookingNo(e.target.value)}
            />
            <Button
              onClick={() => handleSubmit(bookingNo)}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </Stack>
        </Modal>
        <CssBaseline />
        <Box
          width={"100vw"}
          minHeight={"100vh"}
          paddingY={4}
          paddingX={"50px"}
          bgcolor={"#fff"}
        >
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            marginBottom={4}
          >
            <OnlyLogoSVG className="logo" width={60} height={60} />
            <Typography variant="h1" color={"black"}>
              Feedback Form
            </Typography>
          </Stack>
          <Typography variant="h2" color={"black"} marginBottom={4}>
            Thanks for staying with us!
          </Typography>
          <Typography variant="h3" color={"black"} marginBottom={2}>
            1. How likely is that you would recommend this service to a friend
            or colleague?
          </Typography>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            marginBottom={1}
          >
            <Typography variant="h4" color={"black"}>
              Not Likely
            </Typography>
            <Typography variant="h4" color={"black"}>
              Extremely Likely
            </Typography>
          </Stack>
          <Grid
            container
            columns={{ xs: 11, sm: 11, md: 11, lg: 11, xl: 11 }}
            marginBottom={3}
          >
            {ratingBar.map((ratings) => (
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <ButtonBase
                  onClick={() => {
                    handleRatingPress(ratings);
                  }}
                  sx={{ width: "100%" }}
                >
                  <Box
                    display={"flex"}
                    height={"50px"}
                    width={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    border={1}
                    bgcolor={
                      formik.values.rating === ratings ? "black" : "white"
                    }
                    borderColor={
                      formik.values.rating === ratings ? "white" : "black"
                    }
                  >
                    <Typography
                      color={
                        formik.values.rating === ratings ? "white" : "black"
                      }
                      variant="h4"
                    >
                      {ratings}
                    </Typography>
                  </Box>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>
          <Typography variant="h3" color={"black"} marginBottom={2}>
            2. Is there anything you would like to highlight to the management?
          </Typography>
          <TextField
            size="small"
            multiline
            rows={14}
            fullWidth
            value={feedback}
            onChange={handleChange}
            sx={{
              marginBottom: 3,
              ".MuiInputBase-input": {
                color: "black",
                backgroundColor: "white",
                "&:focus": {
                  borderColor: "#fff", // Border color on focus
                },
              },
            }}
          />
          <Typography variant="h4" color={"black"} marginBottom={1}>
            This feedback applies to Room {checkedRooms.join(", ")}
          </Typography>
          {roomBooking.length > 1 && (
            <Button
              onClick={handleOpenDropdown}
              variant="outlined"
              sx={{
                color: "black",
                borderColor: "black",
                backgroundColor: "white",
              }}
            >
              Select Room
            </Button>
          )}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseDropdown}
          >
            {bookingData ? (
              <>
                {" "}
                {bookingData?.roomBookings.map((room) => (
                  <MenuItem
                    key={room.roomCode}
                    onClick={() => handleRoomSelect(room.roomCode)}
                  >
                    <Checkbox
                      checked={checkedRooms.includes(room.roomCode)}
                      color="primary"
                    />
                    <ListItemText primary={room.roomCode} />
                  </MenuItem>
                ))}
              </>
            ) : (
              <>
                {roomBooking?.map((room) => (
                  <MenuItem
                    key={room.roomCode}
                    onClick={() => handleRoomSelect(room.roomCode)}
                  >
                    <Checkbox
                      checked={checkedRooms.includes(room.roomCode)}
                      color="primary"
                    />
                    <ListItemText primary={room.roomCode} />
                  </MenuItem>
                ))}
              </>
            )}
          </Menu>
          <Box display={"flex"} justifyContent={"end"} height={"30"}>
            <Button
              variant="outlined"
              type="submit"
              sx={{ color: "white", borderColor: "white" }}
              onClick={submitFeedbackForm}
            >
              Submit
            </Button>
          </Box>
          <ConfirmationModal
            open={openContinueModal}
            onClose={handleCloseContinueModal}
            onConfirm={handleConfirmContinueModal}
            title={"Continue Feedback"}
            message={""}
          />
        </Box>
      </>
    </>
  );
};

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
}

const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
}: ConfirmationModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          maxWidth: 400,
        }}
      >
        <Typography variant="h3" gutterBottom id="confirmation-modal-title">
          {title}
        </Typography>
        <Typography gutterBottom id="confirmation-modal-description">
          {message}
        </Typography>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
           <Button onClick={onConfirm} color="secondary">
            add another feedback
          </Button>
          <Button onClick={onClose} variant="contained" color="primary">
            Finish
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FeedbackFormNew;
